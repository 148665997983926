import DatePicker from "react-datepicker";
import SelectCity from "../Common/SelectCity";
import SelectModerator from "../Common/SelectModerator";
import LcsClients from "../Common/LcsClients";

const PerformanceFilter = props => {
  const {
    filters,
    showErrorMsg,
    isButtonDisabled,
    getSearchResult,
    handleChange,
    isAdmin
  } = props;
  const { start_date, end_date, city_id, moderator_id, client_id } = filters;
  const handleSearch = e => {
    e.preventDefault();
    if (!start_date) {
      showErrorMsg("Please enter start date");
      return;
    }
    if (!end_date) {
      showErrorMsg("Please enter end date");
      return;
    }
    if (start_date > end_date) {
      showErrorMsg("Please enter start date is less than end date");
      return;
    }
    getSearchResult();
  };
  return (
    <div className="job_seeker">
      <div className="gap-20"></div>
      <form autoComplete="off" onSubmit={handleSearch} className="search">
        <div className="row">
          {isAdmin ? (
            <>
              <div className="col-md-2">
                <SelectModerator
                  filedName="moderator_id"
                  value={moderator_id}
                  onChange={e => {
                    handleChange("moderator_id", e.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                <SelectCity
                  filedName="city_id"
                  value={city_id}
                  onChange={e => {
                    handleChange("city_id", e.target.value);
                  }}
                />
              </div>
            </>
          ) : null}

          <div className="col-md-2">
            <LcsClients
              listType="select"
              clientId={client_id}
              onChange={e => {
                handleChange("client_id", e.target.value);
              }}
            />
          </div>
          <div className="col-md-5">
            <div className="date-lable padding15">Date From: </div>
            <div className="date-field">
              <DatePicker
                selected={start_date}
                onChange={e => {
                  handleChange("start_date", e);
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                maxDate={end_date}
              />
            </div>
            <div className="date-lable">To: </div>
            <div className="date-field">
              <DatePicker
                selected={end_date}
                onChange={e => {
                  handleChange("end_date", e);
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                maxDate={new Date()}
                minDate={start_date}
              />
            </div>
          </div>
          <div className={isAdmin ? "col-md-1" : "col-md-5"}>
            <div className="float-right">
              {isButtonDisabled && (
                <button className="btn btn-primary disable" disabled>
                  Searching ...
                </button>
              )}
              {!isButtonDisabled && (
                <button className="btn btn-primary" onClick={handleSearch}>
                  Search
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="clr"></div>
      <div className="clr gap-20"></div>
    </div>
  );
};
export default PerformanceFilter;
