import { Sheet, SheetTrigger, SheetContent } from "../ui/sheet";
import { Button } from "../ui/button";
import { MenuIcon, Bolt } from "lucide-react";
import { Link } from "react-router-dom";
import { JOBS, LFMS } from "../../routes/RouteConstants";
import "../../global.css";
import LeftNav from "./LeftNav";
import NoAccess from "../../components/Common/NoAccess";
import { isLmsUser } from "../../services/utilsV2";
import { useState } from "react";

export default function LfmsLayout(props) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <div className="flex flex-col  lg:flex-row items-start md:overflow-hidden w-screen h-screen bg-background lfms-root">
      <aside className="hidden lg:flex flex-col bg-background border-r h-full w-[15rem]   justify-between  ">
        <div>
          <Link
            to={LFMS}
            className="flex items-center gap-2  mb-3 border-b p-5"
          >
            <Bolt className="w-6 h-6" />
            <span className=" font-semibold">Lead Management</span>
          </Link>
          <LeftNav />
        </div>
        <div className="flex items-center space-x-3 justify-between   p-3 py-6 w-full mt-3 border-t">
          <a href={JOBS}>
            <img src="/logo.svg" className=" w-[52px]" />
          </a>
          <a href={JOBS} className="  font-semibold">
            CRM V2
          </a>
        </div>
      </aside>
      <div className=" flex flex-col lg:hidden">
        <header className="flex border-b w-screen p-3">
          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                onClick={handleOpen}
                size="icon"
                className="lg:hidden"
              >
                <MenuIcon className="h-6 w-6" />
                <span className="sr-only">Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" isOpen={isOpen} handleClose={handleClose}>
              <Link
                to={LFMS}
                className="flex items-center gap-2  mb-3 border-b p-3"
              >
                <Bolt className="w-6 h-6" />
                <span className="">Lead Management</span>
              </Link>
              <LeftNav
                aClass="flex items-center gap-2 text-base font-medium hover:bg-accent hover:text-accent-foreground rounded-md px-3 py-2"
                navClass="flex flex-col gap-1 mt-3"
                handleClose={handleClose}
              />
            </SheetContent>
          </Sheet>
        </header>
      </div>
      {isLmsUser() ? (
        props.children
      ) : (
        <div className="w-screen lg:w-[calc(100vw-15rem)] p-4">
          <NoAccess />
        </div>
      )}
    </div>
  );
}
