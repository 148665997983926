import React, { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import {
  formattedRequestType,
  isCRMAdmin,
  redirectURL
} from "../../services/utils";
import NoAccess from "../Common/NoAccess";
import HeadingRow from "../Common/HeadingRow";
import PopupMessage from "../PopupMessage";
import { VIEW_RESTRICT_VALUE } from "../../routes/RouteConstants";
import {
  getRestrictionDetail,
  revertRecruiterRestriction
} from "../../services/restricValueService";
import RequestOrgList from "./RequestOrgList";
import ModalWrapper from "../Common/ModalWrapper";

const ViewSingleRequest = () => {
  const isViewPage = isCRMAdmin();
  const [popupMessageObj, setPopupMessageObj] = useState({ showMsg: false });
  const [requestData, setRequestData] = useState({
    requestName: "",
    requestStatus: "",
    actionToTake: "",
    tableData: []
  });
  const [showModal, setShowModal] = useState(false);

  const search = window.location.search;
  const queryParams = new URLSearchParams(search);
  const requestId = queryParams.get("request_id");

  useEffect(() => {
    getRequestData();
  }, []);

  const getRequestData = async () => {
    try {
      const data = await getRestrictionDetail({ request_id: requestId });
      const {
        request_name = "",
        request_status = "",
        action_to_take = "",
        request_data = []
      } = data;

      if (data) {
        setRequestData({
          requestName: request_name,
          requestStatus: request_status,
          tableData: request_data,
          actionToTake: formattedRequestType(action_to_take)
        });
      }
    } catch (error) {
      console.error(error);
      setPopupMessageObj({
        msgType: "error",
        msg: "Something went wrong",
        showMsg: true
      });
    }
  };

  const onReverse = e => {
    e.preventDefault();
    setShowModal(true);
  };

  const onReverseRequest = async () => {
    const dataObj = {
      request_id: requestId
    };
    try {
      const data = await revertRecruiterRestriction(dataObj);
      setShowModal(false);

      redirectURL(VIEW_RESTRICT_VALUE);

      setPopupMessageObj({
        msgType: "success",
        msg: `Value delivery successfully reverted for ${tableData.length} organisations`,
        showMsg: true
      });
    } catch (error) {
      console.error(error);
      setPopupMessageObj({
        msgType: "error",
        msg: "Something went wrong",
        showMsg: true
      });
    }
  };

  const handleModalSave = () => {
    onReverseRequest();
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const { requestName, requestStatus, actionToTake, tableData } = requestData;

  return (
    <DocumentTitle title={"Job Hai CRM - View All Requests"}>
      <div>
        <HeadingRow
          heading="Restrict value delivery - View Request"
          goBackURL={VIEW_RESTRICT_VALUE}
        />

        <PopupMessage
          data={popupMessageObj}
          click={() => setPopupMessageObj({ showMsg: false })}
        />

        <div className="clr gap-20" />

        {isViewPage ? (
          <>
            <div className="whitebox">
              <div className="clr gap-20" />

              <div class="single-request-container">
                <b>Request id - {requestId}</b>
                <b>Request name - {requestName}</b>
                <b>Action to be taken - {actionToTake}</b>
                <b>Current status - {requestStatus}</b>

                <button
                  className="btn btn-primary marginleft"
                  onClick={onReverse}
                  disabled={requestStatus === "REVERSED"}
                >
                  Reverse
                </button>
              </div>
              <div className="clr gap-20" />
              <div className="clr gap-20" />

              <RequestOrgList dataList={tableData} />

              <ModalWrapper
                primaryCTA="Confirm"
                showModal={showModal}
                handleSave={handleModalSave}
                closeModal={handleModalClose}
              >
                <div>
                  Value delivery will be re-enabled for {tableData.length}{" "}
                  organisations
                </div>
              </ModalWrapper>
            </div>
          </>
        ) : (
          <NoAccess />
        )}
      </div>
    </DocumentTitle>
  );
};

export default ViewSingleRequest;
