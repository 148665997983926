import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import CustomePagination from "../../Common/Pagination";
import { DYNAMIC_DAG } from "../../../routes/RouteConstants";
import { findChipValue } from "../../../services/utils";

const DagBuilderList = ({
  dataList,
  count,
  itemsCountPerPage,
  onPageChange,
  activePage
}) => {
  const columns = [
    {
      text: "Id",
      dataField: "id",
      sort: true
    },
    {
      text: "Source",
      dataField: "source",
      sort: true
    },
    {
      text: "ACtive",
      dataField: "is_active",
      sort: true,
      formatter: (cellContent, row) => {
        return findChipValue(row?.is_active) || "NA";
      }
    },
    {
      text: "Destination Table Name",
      dataField: "destination_table_name",
      sort: true
    },
    {
      text: "Created At",
      dataField: "createdAt",
      sort: true,
      formatter: (cellContent, row) => {
        return moment(row.createdAt).format("DD-MM-YYYY");
      }
    },
    {
      text: "Updated At",
      dataField: "updatedAt",
      sort: true,
      formatter: (cellContent, row) => {
        return moment(row.updatedAt).format("DD-MM-YYYY");
      }
    },
    {
      text: "#",
      dataField: "a",
      formatter: (cellContent, row) => {
        return (
          <div className="campaignAction">
            <a
              href={`${DYNAMIC_DAG}/${row?.id}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Edit this campaign"
            >
              <i className="fas fa-pencil-alt" />
            </a>
          </div>
        );
      }
    }
  ];
  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  return (
    <div className="dragbuilder-list">
      <BootstrapTable
        keyField="campaign_id"
        data={dataList}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
      />
      <CustomePagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={count}
        showPagination={count > dataList.length}
        onPageChange={onPageChange}
      />
      <div className="clr gap-20" />
    </div>
  );
};

export default DagBuilderList;
