import React from "react";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { checkPermission } from "../../services/utils";
import {
  LIVE_JOBS,
  AGENT_LEADS,
  MANUAL_JD_AUDITING,
  FIX_DUPLICATE_PROFILE,
  CRAWLED_RECRUITER,
  VIEW_AUTO_JOB_POSTING
} from "../../routes/RouteConstants";
import NoAccess from "../Common/NoAccess";

const JobsDashboard = () => {
  const cleaning = checkPermission("Cleaning / Calling");
  const qa = checkPermission("QA");
  const liveJobs = checkPermission("Live Jobs");
  const duplicateProfile = checkPermission("Fix Duplicate Profile");
  const agentLeads = checkPermission("Agent Leads");
  const editedJobs = checkPermission("Edited Jobs");
  const isManualJD = checkPermission("Manual JD Auditing");
  const isAutoJobPosting = checkPermission("Auto Job Posting");
  const showCrawledJobs = cleaning || qa;
  const showOrganicJobs = cleaning || qa;
  const showAgentJobs = cleaning || qa;
  let classCC = qa ? "col-md-4" : "col-md-8";
  const isViewPage =
    cleaning ||
    qa ||
    liveJobs ||
    duplicateProfile ||
    agentLeads ||
    editedJobs ||
    isManualJD ||
    isAutoJobPosting;

  if (!isViewPage) return <NoAccess />;

  return (
    <DocumentTitle title={"Job Hai CRM - Jobs Dashboard"}>
      <div className="dashboard">
        <div className="clr gap-20"></div>
        <div className="pageHeading row">
          <h2>Jobs</h2>
        </div>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
        <div className="clr gap-20"></div>
        <div className="whiteCard">
          <div className="container-fluid">
            {showCrawledJobs ? (
              <div className="row marginBottom20">
                <div className="col-md-4">
                  <div className="box2">Crawled Jobs</div>
                </div>
                {cleaning && (
                  <div className={classCC}>
                    <div className="box">
                      <Link to={`${CRAWLED_RECRUITER}/CLEANER`}>
                        Cleaning / Calling
                      </Link>
                    </div>
                  </div>
                )}
                {qa && (
                  <div className="col-md-4">
                    <div className="box">
                      <Link to={`${CRAWLED_RECRUITER}/QA`}>QA</Link>
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {showOrganicJobs ? (
              <div className="row marginBottom20">
                <div className="col-md-4">
                  <div className="box2">Organic Jobs</div>
                </div>
                {cleaning && (
                  <div className={classCC}>
                    <div className="box">
                      <Link to="/jobs/organic/CLEANER">Cleaning / Calling</Link>
                    </div>
                  </div>
                )}
                {qa && (
                  <div className="col-md-4">
                    <div className="box">
                      <Link to="/jobs/organic/QA">QA</Link>
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {showAgentJobs ? (
              <div className="row marginBottom20">
                <div className="col-md-4">
                  <div className="box2">Agent Jobs</div>
                </div>
                {cleaning && (
                  <div className={classCC}>
                    <div className="box">
                      <Link to="/jobs/agent/CLEANER">Cleaning / Calling</Link>
                    </div>
                  </div>
                )}
                {qa && (
                  <div className="col-md-4">
                    <div className="box">
                      <Link to="/jobs/agent/QA">QA</Link>
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            <div className="row">
              {liveJobs && (
                <div className="col-md-4 marginBottom20">
                  <div className="box">
                    <Link to={LIVE_JOBS}>Live Jobs</Link>
                  </div>
                </div>
              )}
              {duplicateProfile && (
                <div className="col-md-4 marginBottom20">
                  <div className="box">
                    <Link to={FIX_DUPLICATE_PROFILE}>
                      Fix Duplicate Profile
                    </Link>
                  </div>
                </div>
              )}
              {agentLeads && (
                <div className="col-md-4 marginBottom20">
                  <div className="box">
                    <Link to={AGENT_LEADS}>Agent Leads</Link>
                  </div>
                </div>
              )}
              {editedJobs && (
                <div className="col-md-4 marginBottom20">
                  <div className="box">
                    <Link to="/jobs/edited/QA">Edited Jobs</Link>
                  </div>
                </div>
              )}
              {isManualJD && (
                <div className="col-md-4 marginBottom20">
                  <div className="box">
                    <Link to={MANUAL_JD_AUDITING}>Manual JD Auditing</Link>
                  </div>
                </div>
              )}
              {isAutoJobPosting ? (
                <div className="col-md-4 marginBottom20">
                  <div className="box">
                    <a href={VIEW_AUTO_JOB_POSTING}>Auto Job Posting</a>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default JobsDashboard;
