import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import { styles, initiateOrder } from "../CreditWidget/helper";
import { getFormattedValue } from "../../services/utils";
import OrderIdCreationForm from "./OrderIdCreationForm";
import {
  MAX_PAID_CREDITS,
  MAX_DISCOUNT,
  MIN_CREDITS
} from "../../services/constant";
import ConfirmationCTA from "./ConfirmationCTA";
import AfterSuccessCTA from "./AfterSuccessCTA";

const STATES = {
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  ERROR: "ERROR",
  PRELIMINARY: "PRELIMINARY",
  FINAL: "FINAL",
  SUCCESS: "SUCCESS"
};

function OrderIdGeneration({ classes, recId }) {
  const [currentState, setCurrentState] = useState(STATES.PRELIMINARY);
  const [fieldItems, setFieldItems] = useState({
    credits: "",
    discount: "",
    validity: ""
  });
  const [postingState, setPostingState] = useState(STATES.FETCHED);
  const [errorMessage, setErrorMessage] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const [orderId, setOrderId] = useState("");

  const { credits, discount, validity } = fieldItems;

  async function onSave(st) {
    setErrorMessage("");
    if (st === STATES.PRELIMINARY) {
      if (handleFormValidation()) {
        return;
      }
      setCurrentState(STATES.FINAL);
    }
    if (st === STATES.FINAL) {
      setPostingState(STATES.FETCHING);
      const obj = { ...fieldItems, recruiterId: recId };
      try {
        const res = await initiateOrder(obj);
        const { orderId = "" } = res;
        setPostingState(STATES.SUCCESS);
        setOrderId(orderId);
        resetFieldItems();
      } catch (error) {
        setPostingState(STATES.ERROR);

        setCurrentState(STATES.PRELIMINARY);
        resetFieldItems();
      }
    }
  }
  function onCancel() {
    setCurrentState(STATES.PRELIMINARY);
    setPostingState(STATES.FETCHED);
    resetFieldItems();
  }

  function resetFieldItems() {
    setFieldItems({ credits: "", discount: "", validity: "" });
  }

  function copyOrderId() {
    try {
      navigator.clipboard.writeText(orderId);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  function handleChange({ target: { name, value } }) {
    if (name === "credits") {
      value = getFormattedValue(value, true, true);
    }
    if (name === "discount") {
      value = getFormattedValue(value, true);
    } else {
      value = getFormattedValue(value);
    }
    setFieldItems(prevValues => ({ ...prevValues, [name]: value }));
  }

  function handleFormValidation() {
    if (
      credits &&
      (credits < MIN_CREDITS ||
        credits > MAX_PAID_CREDITS ||
        credits % MIN_CREDITS !== 0)
    ) {
      setErrorMessage("Invalid credits");
      return true;
    }
    if (discount && discount > MAX_DISCOUNT) {
      setErrorMessage("Invalid discount");
      return true;
    }
  }
  return (
    <>
      <div className="heading">Order Id Generator</div>
      {currentState === STATES.PRELIMINARY ? (
        <OrderIdCreationForm
          fieldItems={fieldItems}
          onChange={handleChange}
          errorMessage={errorMessage}
          onSave={onSave}
        />
      ) : (
        <>
          Order for {credits} will be generated at {discount}% discount with
          validity {validity} days
          {postingState === STATES.SUCCESS ? (
            <div className={classes.successState}>
              Order Id:
              <span>
                {orderId}
                <i className="fa-solid fa-copy" onClick={copyOrderId} />
              </span>
              {copySuccess ? (
                <span className="text-success"> Copied </span>
              ) : null}
            </div>
          ) : null}
          {postingState === STATES.ERROR ? (
            <div className={classes.errorState}>
              <i className="fa fa-x" aria-hidden="true" />
              <span>&nbsp; Unable to save</span>
            </div>
          ) : null}
          {postingState === STATES.SUCCESS ? (
            <AfterSuccessCTA
              onCancel={() => onCancel()}
              label="Create another order id"
            />
          ) : (
            <ConfirmationCTA
              disabled={postingState === STATES.FETCHING}
              onSave={() => onSave(STATES.FINAL)}
              onCancel={() => onCancel()}
            />
          )}
        </>
      )}
    </>
  );
}

export default withStyles(styles)(React.memo(OrderIdGeneration));
