import React from "react";

function Bucket(props) {
  const { pathname = "", sectionData = {} } = props;
  const {
    new_count = 0,
    re_attempt_count = 0,
    dropout_count = 0,
    followup_count = 0,
    doc_chase_count = 0
  } = sectionData;
  return (
    <div className="row rec-bucket">
      <div className="box">
        <a
          href={`${pathname}?bucket=new`}
          target="_blank"
          rel="noopener noreferrer"
        >
          New ({new_count})
        </a>
      </div>
      <div className="box">
        <a
          href={`${pathname}?bucket=re_attempt`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Re-Attempt ({re_attempt_count})
        </a>
      </div>
      <div className="box">
        <a
          href={`${pathname}?bucket=follow_up`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Follow Up ({followup_count})
        </a>
      </div>
      <div className="box">
        <a
          href={`${pathname}?bucket=doc_chase`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Doc Chase ({doc_chase_count})
        </a>
      </div>
      <div className="box">
        <a
          href={`${pathname}?bucket=dropout`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Dropout Cases ({dropout_count})
        </a>
      </div>
    </div>
  );
}

export default Bucket;
