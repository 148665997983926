import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import SelectCity from "../../Common/SelectCity";
import MetaData from "../../../constants/MetaData";
import { getFormattedValue, isNullOrEmpty } from "../../../services/utils";
import { getModeratorList } from "../../../services/metadataApiServices";
import ReAssignCatLeads from "./ReAssignCatLeads";

const CrawledRecruiterFilter = ({
  bucketId,
  searchRecruiter,
  isButtonDisabled,
  selectedRecruiters,
  callBack,
  isCatAdmin
}) => {
  const [state, setState] = useState({
    source: "",
    recruiter_id: "",
    phone: "",
    start_date: !bucketId ? new Date() : "",
    end_date: "",
    city_id: "",
    verification_status: "",
    is_email_verified: "",
    document_status: "",
    recruiter_calling_status: "",
    recruiter_consent: "",
    doc_upload_end_date: "",
    doc_upload_start_date: "",
    crawled_recruiter_call_feedback: "",
    crawled_recruiter_calling_status: "",
    follow_up_date: bucketId === "follow_up" ? new Date() : "",
    assign_date: "",
    attempt_date:
      bucketId === "re_attempt" || bucketId === "doc_chase" ? new Date() : "",
    attempt_made: "",
    job_posted: "",
    cohort: "",
    jml_in_ninety_days: "",
    new_organation: "",
    moderator_id: "",
    is_only_reassignment_leads: ""
  });
  const [moderatorList, setModeratorList] = useState([]);
  useEffect(() => {
    if (isCatAdmin) {
      const fetchModeratorList = async () => {
        try {
          const response = await getModeratorList("is_cat_tab");
          setModeratorList(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchModeratorList();
    }
  }, [isCatAdmin]);

  const handleChange = event => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: getFormattedValue(value)
    }));
  };

  const handleChangeNumber = event => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: getFormattedValue(value, true, true)
    }));
  };
  const handleDateChange = (name, value) => {
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = e => {
    e.preventDefault();

    const formattedState = { ...state };

    // Format specific fields
    const fieldsToFormat = [
      "is_email_verified",
      "recruiter_consent",
      "start_date",
      "end_date",
      "doc_upload_end_date",
      "doc_upload_start_date",
      "follow_up_date",
      "dropout_date",
      "assign_date",
      "attempt_date",
      "job_posted",
      "jml_in_ninety_days"
    ];

    fieldsToFormat.forEach(field => {
      if (formattedState[field]) {
        switch (field) {
          case "is_email_verified":
          case "recruiter_consent":
            formattedState[field] = !!Number(formattedState[field]);
            break;
          case "start_date":
          case "end_date":
          case "doc_upload_end_date":
          case "doc_upload_start_date":
          case "follow_up_date":
          case "dropout_date":
          case "assign_date":
          case "attempt_date":
            formattedState[field] = moment(formattedState[field]).format(
              "YYYY-MM-DD"
            );
            break;
          case "job_posted":
            formattedState[field] = Number(formattedState[field]);
            break;
          case "jml_in_ninety_days":
            formattedState[field] = formattedState[field]
              ? !!Number(formattedState[field])
              : undefined;
            break;
          default:
            break;
        }
      }
    });

    // Remove empty fields
    Object.keys(formattedState).forEach(key => {
      if (isNullOrEmpty(formattedState[key])) {
        delete formattedState[key];
      }
    });
    searchRecruiter(formattedState);
  };

  const sourceList = MetaData.crawlSourceList;
  const recCallingStatus = MetaData.recruiterCallingStatus;
  const isAssignDateFilter =
    bucketId === "re_attempt" ||
    bucketId === "follow_up" ||
    bucketId === "doc_chase";
  const today = new Date();
  return (
    <div className="job_seeker">
      <div className="gap-20" />
      <form autoComplete="off" onSubmit={handleSubmit} className="search">
        {!!bucketId ? (
          <div className="row">
            {isAssignDateFilter ? (
              <>
                <div className="col-md-3">
                  <div className="date-lable padding15">Assigned Date</div>
                  <div className="date-field">
                    <DatePicker
                      selected={state.assign_date}
                      onChange={date => handleDateChange("assign_date", date)}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      maxDate={today}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="date-lable padding15">
                    {bucketId === "follow_up" ? "Follow Up " : "Attempt "}
                    Date
                  </div>
                  <div className="date-field">
                    {bucketId === "follow_up" ? (
                      <DatePicker
                        selected={state.follow_up_date}
                        onChange={date =>
                          handleDateChange("follow_up_date", date)
                        }
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        maxDate={today}
                      />
                    ) : (
                      <DatePicker
                        selected={state.attempt_date}
                        onChange={date =>
                          handleDateChange("attempt_date", date)
                        }
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        maxDate={today}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="date-lable padding15">Attempt Made</div>
                  <div className="date-field" style={{ position: "relative" }}>
                    <b
                      className="downArrow"
                      style={{
                        padding: "3px",
                        marginTop: "10px",
                        right: "20px",
                        zIndex: 10
                      }}
                    />
                    <select
                      className="form-control"
                      name="attempt_made"
                      value={state.attempt_made}
                      onChange={handleChange}
                    >
                      <option value="">All</option>
                      <option value="Attempt_1">Attempt 1</option>
                      <option value="Attempt_2">Attempt 2</option>
                      {bucketId !== "follow_up" ? (
                        <option value="Attempt_3">Attempt 3</option>
                      ) : null}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-md-4">
                <div className="date-lable padding15">
                  {bucketId === "dropout" ? "Dropout " : "Assigned "}
                  Date:
                </div>
                <div className="date-field">
                  <DatePicker
                    selected={state.assign_date}
                    onChange={date => handleDateChange("assign_date", date)}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                    maxDate={today}
                  />
                </div>
              </div>
            )}
            <div className="col-md-3">
              {isButtonDisabled && (
                <button className="btn btn-primary disable" disabled>
                  SEARCHING ...
                </button>
              )}
              {!isButtonDisabled && (
                <button className="btn btn-primary" onClick={handleSubmit}>
                  SEARCH
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="recruiter_id"
                  placeholder="Recruiter ID"
                  value={state.recruiter_id}
                  onChange={handleChangeNumber}
                  maxLength="10"
                />
              </div>
            </div>
            <div className="col-md-3">
              <input
                className="form-control"
                type="text"
                name="phone"
                placeholder="Recruiter Phone no"
                value={state.phone}
                onChange={handleChangeNumber}
                maxLength="10"
              />
            </div>
            <div className="col-md-2">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="source"
                value={state.source}
                onChange={handleChange}
              >
                <option value="">Select Source</option>
                {sourceList.map((option, index) => (
                  <option value={option.value} key={"cle" + index}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <SelectCity
                filedName={"city_id"}
                value={state.city_id}
                onChange={handleChange}
                isDelhiNcr={true}
              />
            </div>
            <div className="col-md-2">
              <b className="downArrow" />
              <select
                className="form-control"
                name="is_email_verified"
                value={state.is_email_verified}
                onChange={handleChange}
              >
                <option value={null}>Select Email Status</option>
                <option value={1}>Verified</option>
                <option value={0}>Not Verified</option>
              </select>
            </div>
            <div className="col-md-3">
              <b className="downArrow" />
              <select
                className="form-control"
                name="recruiter_calling_status"
                value={state.recruiter_calling_status}
                onChange={handleChange}
              >
                <option value="">Select Rec Calling Status</option>
                {recCallingStatus.map(option => (
                  <option
                    value={option.value}
                    key={`rec-cal-status${option.value}`}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <b className="downArrow" />
              <select
                className="form-control"
                name="crawled_recruiter_calling_status"
                value={state.crawled_recruiter_calling_status}
                onChange={handleChange}
              >
                <option value="">Select Crawled - Rec Calling Status</option>
                {recCallingStatus.map(option => (
                  <option
                    value={option.value}
                    key={`carawled-cal-status${option.value}`}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow" />
                <select
                  className="form-control"
                  name="verification_status"
                  value={state.verification_status}
                  onChange={handleChange}
                >
                  <option value="">Select Rec Status</option>
                  {MetaData.recruiterStatus.map(option => (
                    <option value={option.value} key={`recS${option.value}`}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <b className="downArrow" />
              <select
                className="form-control"
                name="crawled_recruiter_call_feedback"
                value={state.crawled_recruiter_call_feedback}
                onChange={handleChange}
              >
                <option value="">Select Interested</option>
                <option value="INTERESTED">Yes</option>
                <option value="NOT_INTERESTED">No</option>
              </select>
            </div>
            <div className="col-md-2">
              <b className="downArrow" />
              <select
                className="form-control"
                name="recruiter_consent"
                value={state.recruiter_consent}
                onChange={handleChange}
              >
                <option value="">Select Consent</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
            </div>
            <div className="col-md-6">
              <div className="date-lable padding15">Date From: </div>
              <div className="date-field">
                <DatePicker
                  selected={state.start_date}
                  onChange={date => handleDateChange("start_date", date)}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  maxDate={today}
                />
              </div>
              <div className="date-lable">To: </div>
              <div className="date-field">
                <DatePicker
                  selected={state.end_date}
                  onChange={date => handleDateChange("end_date", date)}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  maxDate={today}
                  minDate={state.start_date}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow" />
                <select
                  className="form-control"
                  name="document_status"
                  value={state.document_status}
                  onChange={handleChange}
                >
                  <option value="">Select Doc Status</option>
                  <option value="no_document">No Document</option>
                  <option value="under_review">Under Review</option>
                  <option value="verified">Verified</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <b className="downArrow" />
              <select
                className="form-control"
                name="job_posted"
                value={state.job_posted}
                onChange={handleChange}
              >
                <option value="">Job Posted</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
            </div>
            <div className="col-md-2">
              <b className="downArrow" />
              <select
                className="form-control"
                name="cohort"
                value={state.cohort}
                onChange={handleChange}
              >
                <option value="">Lead Type</option>
                {MetaData.crawledLeadType.map(option => (
                  <option value={option.value} key={`recS${option.value}`}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow" />
                <select
                  className="form-control"
                  name="jml_in_ninety_days"
                  value={state.jml_in_ninety_days}
                  onChange={handleChange}
                >
                  <option value="">JML within 90 Days</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </div>
            </div>
            {isCatAdmin ? (
              <div className="col-md-2">
                <b className="downArrow" />
                <select
                  className="form-control"
                  name="moderator_id"
                  value={state.moderator_id}
                  onChange={handleChange}
                >
                  <option value="">Moderator</option>
                  {moderatorList.map(item => (
                    <option
                      value={item.moderator_id}
                      key={`moderator${item.moderator_id}`}
                    >
                      {item.moderator_name}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
            {/* <div className="col-md-2">
              <b className="downArrow" />
              <select
                className="form-control"
                name="new_organation"
                value={state.new_organation}
                onChange={handleChange}
              >
                <option value="">New Organization</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
            </div> */}
            <div className="col-md-6">
              <div className="date-lable padding15">Doc Upload Start Date:</div>
              <div className="date-field">
                <DatePicker
                  selected={state.doc_upload_start_date}
                  onChange={date =>
                    handleDateChange("doc_upload_start_date", date)
                  }
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  maxDate={today}
                />
              </div>
              <div className="date-lable">Doc Upload End Date:</div>
              <div className="date-field">
                <DatePicker
                  selected={state.doc_upload_end_date}
                  onChange={date =>
                    handleDateChange("endoc_upload_end_dated_date", date)
                  }
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  maxDate={today}
                  minDate={state.doc_upload_start_date}
                />
              </div>
            </div>
            {isCatAdmin ? (
              <div className="col-md-2">
                <b className="downArrow" />
                <select
                  className="form-control"
                  name="is_only_reassignment_leads"
                  value={state.is_only_reassignment_leads}
                  onChange={handleChange}
                >
                  <option value="">Reassigned Leads</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </div>
            ) : null}
            <div className={isCatAdmin ? "col-md-12" : "col-md-12"}>
              <div className="float-right">
                <ReAssignCatLeads
                  selectedRecruiters={selectedRecruiters}
                  moderatorList={moderatorList}
                  moderatorId={state.moderator_id}
                  callBack={callBack}
                />
                {isButtonDisabled && (
                  <button
                    className="btn btn-primary disable marginleft"
                    disabled
                  >
                    SEARCHING ...
                  </button>
                )}
                {!isButtonDisabled && (
                  <button
                    className="btn btn-primary marginleft"
                    onClick={handleSubmit}
                  >
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </form>
      <div className="clr gap-20" />
    </div>
  );
};
export default CrawledRecruiterFilter;
