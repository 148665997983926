import React from "react";
import moment from "moment";

import {
  recruiterCallingStatus,
  recruiterCallingSubStatusConnected,
  recruiterCallingSubStatusNotConnected
} from "../../../constants/MetaData";

const CrawlCallingStatus = ({
  item,
  handleChange,
  initailRecList,
  bucketId
}) => {
  const {
    crawled_recruiter_calling_status,
    recruiter_calling_status,
    crawled_recruiter_calling_sub_dispositions,
    crawled_recruiter_call_feedback,
    already_verified_phone,
    follow_up_date,
    recruiter_id,
    last_crawled_recruiter_calling_status,
    last_crawled_recruiter_calling_sub_dispositions,
    last_crawled_recruiter_call_feedback,
    call_connected_other_reason,
    call_not_connected_other_reason
  } = item;
  const currentRecList = Array.from(initailRecList).filter(
    rec => rec.recruiter_id === recruiter_id
  );

  const old_recruiter_calling_status = currentRecList.length
    ? currentRecList[0].recruiter_calling_status
    : "";

  let recCallingStatus = recruiterCallingStatus;
  let newObj = [{ label: "New", value: "NEW", isDisabled: true }];
  let attempt1 = [
    {
      label: "Attempt 1 – Connected",
      value: "ATTEMPT_CONNECTED_1",
      isDisabled: false
    },
    {
      label: "Attempt 1 – Not Connected",
      value: "ATTEMPT_NOT_CONNECTED_1",
      isDisabled: false
    }
  ];
  let attempt2 = [
    {
      label: "Attempt 2 – Connected",
      value: "ATTEMPT_CONNECTED_2",
      isDisabled: false
    },
    {
      label: "Attempt 2 – Not Connected",
      value: "ATTEMPT_NOT_CONNECTED_2",
      isDisabled: false
    }
  ];
  let attempt3 = [
    {
      label: "Attempt 3  – Connected",
      value: "ATTEMPT_CONNECTED_3",
      isDisabled: false
    },
    {
      label: "Attempt 3 – Not Connected",
      value: "ATTEMPT_NOT_CONNECTED_3",
      isDisabled: false
    }
  ];

  let attemptOneActive = attempt1.map(item => ({
    ...item,
    isDisabled: true
  }));
  let attemptTwoActive = attempt2.map(item => ({
    ...item,
    isDisabled: true
  }));

  if (bucketId === "follow_up") {
    recCallingStatus = [...newObj, ...attempt1, ...attempt2];
  }
  if (bucketId === "new") {
    recCallingStatus = [...newObj, ...attempt1];
  }

  /* Handle Recruiter Calling status List */

  if (!old_recruiter_calling_status || old_recruiter_calling_status === "NEW") {
    recCallingStatus = [...newObj, ...attempt1];
  }
  if (
    old_recruiter_calling_status === "ATTEMPT_NOT_CONNECTED_1" ||
    old_recruiter_calling_status === "ATTEMPT_CONNECTED_1"
  ) {
    recCallingStatus = [...newObj, ...attemptOneActive, ...attempt2];
  }
  if (
    old_recruiter_calling_status === "ATTEMPT_NOT_CONNECTED_2" ||
    old_recruiter_calling_status === "ATTEMPT_CONNECTED_2"
  ) {
    if (bucketId === "follow_up") {
      recCallingStatus = [...newObj, ...attemptOneActive, ...attempt2];
    } else {
      recCallingStatus = [
        ...newObj,
        ...attemptOneActive,
        ...attemptTwoActive,
        ...attempt3
      ];
    }
  }
  if (
    old_recruiter_calling_status === "ATTEMPT_NOT_CONNECTED_3" ||
    old_recruiter_calling_status === "ATTEMPT_CONNECTED_3"
  ) {
    recCallingStatus = [
      ...newObj,
      ...attemptOneActive,
      ...attemptTwoActive,
      ...attempt3
    ];
  }
  /* End */

  const callConnected =
    recruiter_calling_status &&
    recruiter_calling_status.startsWith("ATTEMPT_CONNECTED");
  const callNotConnected =
    recruiter_calling_status &&
    recruiter_calling_status.startsWith("ATTEMPT_NOT_CONNECTED");

  const recCallingSubStatus = callConnected
    ? recruiterCallingSubStatusConnected
    : callNotConnected
    ? recruiterCallingSubStatusNotConnected
    : [];
  return (
    <>
      {last_crawled_recruiter_calling_status ? (
        <div className="list-lable">
          Last Rec Calling Status: {last_crawled_recruiter_calling_status}
        </div>
      ) : null}
      {last_crawled_recruiter_calling_sub_dispositions ? (
        <div className="list-lable">
          Last Calling Sub Status:{" "}
          {last_crawled_recruiter_calling_sub_dispositions}
        </div>
      ) : null}
      {last_crawled_recruiter_call_feedback ? (
        <div className="list-lable">
          Last Interest Status: {last_crawled_recruiter_call_feedback}
        </div>
      ) : null}
      {crawled_recruiter_calling_status ? (
        <>
          <div className="list-lable">Crawled - Rec Calling Status</div>
          <select
            className="selectBox selectBox2"
            name="crawled_recruiter_calling_status"
            value={
              crawled_recruiter_calling_status
                ? crawled_recruiter_calling_status
                : ""
            }
            disabled
          >
            {recruiterCallingStatus.map(option => (
              <option
                value={option.value}
                key={`carawled-cal-status${option.value}`}
              >
                {option.label}
              </option>
            ))}
          </select>
          <br />
        </>
      ) : null}
      <div className="list-lable">Rec Calling Status -</div>
      <select
        className="selectBox selectBox2"
        name="recruiter_calling_status"
        value={recruiter_calling_status ? recruiter_calling_status : ""}
        onChange={e => handleChange(recruiter_id, e)}
      >
        {!recruiter_calling_status ? (
          <option value={""} disabled>
            Select
          </option>
        ) : null}
        {recCallingStatus.map(option => (
          <option
            value={option.value}
            key={`rec-cal-status${option.value}`}
            disabled={option.isDisabled}
          >
            {option.label}
          </option>
        ))}
      </select>
      {recCallingSubStatus.length ? (
        <>
          <div className="list-lable">Calling Sub Status -</div>
          <select
            className="selectBox selectBox2"
            name="crawled_recruiter_calling_sub_dispositions"
            value={
              crawled_recruiter_calling_sub_dispositions
                ? crawled_recruiter_calling_sub_dispositions
                : ""
            }
            onChange={e => handleChange(recruiter_id, e)}
          >
            <option value={""}>Select</option>
            {recCallingSubStatus.map(option => (
              <option
                value={option.value}
                key={`rec-cal-sub-status${option.value}`}
              >
                {option.label}
              </option>
            ))}
          </select>
          {crawled_recruiter_calling_sub_dispositions === "CONNECTED_OTHER" ||
          crawled_recruiter_calling_sub_dispositions ===
            "NOT_CONNECTED_OTHER" ? (
            <input
              className="form-control"
              type="text"
              name="call_connected_other_reason"
              placeholder="Enter other call status"
              value={
                call_connected_other_reason ||
                call_not_connected_other_reason ||
                ""
              }
              onChange={e => handleChange(recruiter_id, e)}
              maxLength="255"
            />
          ) : null}
        </>
      ) : null}
      <br /> <div className="list-lable">Is Interested</div>
      <select
        className="selectBox selectBox2"
        name="crawled_recruiter_call_feedback"
        value={
          crawled_recruiter_call_feedback ? crawled_recruiter_call_feedback : ""
        }
        onChange={e => handleChange(recruiter_id, e)}
      >
        <option value="">Select</option>
        <option value="INTERESTED">Yes</option>
        <option value="NOT_INTERESTED">No</option>
      </select>
      <br /> <div className="list-lable">Follow Up Date/Time</div>
      <input
        className="form-control"
        type="datetime-local"
        name="follow_up_date"
        placeholder="Follow Up Date"
        value={
          follow_up_date
            ? moment(follow_up_date).format("YYYY-MM-DD HH:mm")
            : ""
        }
        onChange={e => handleChange(recruiter_id, e)}
        min={moment().format("YYYY-MM-DD HH:mm")}
      />
      <div className="list-lable">If Already Verified</div>
      <input
        className="form-control"
        type="text"
        name="already_verified_phone"
        placeholder="Recruiter verified phone no"
        value={already_verified_phone}
        onChange={e => handleChange(recruiter_id, e)}
        maxLength="10"
      />
    </>
  );
};
export default CrawlCallingStatus;
