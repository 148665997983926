import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

const CatList = props => {
  const { dataList = [], totalData, catSection } = props;
  const {
    is_active = 0,
    data_touched = 0,
    connected = 0,
    interested = 0,
    first_job_posted = 0,
    documents_uploaded = 0,
    onboarded = 0,
    yes_for_online_jobs = 0,
    data_assigned = 0,
    email_verified = 0,
    data_requested = 0
  } = totalData;

  dataList.map(d => {
    d.moderatorName = (
      <>
        {d.moderator_name} {d.isAbsent ? <span className="isAbsent" /> : null}
      </>
    );
    d.isActive = d.is_active ? "Yes" : "No";
    return d;
  });

  const columns = [
    {
      text: "Moderator",
      dataField: "moderatorName",
      sort: true
    },
    {
      text: "Is Active",
      dataField: "isActive",
      sort: true
    },
    {
      text: "Data Touched",
      dataField: "data_touched",
      sort: true
    },
    {
      text: "Connected",
      dataField: "connected",
      sort: true
    },
    {
      text: "Interested",
      dataField: "interested",
      sort: true
    },
    {
      text: "First Job Posted",
      dataField: "first_job_posted",
      sort: true
    },
    {
      text: "Doc Uploaded",
      dataField: "documents_uploaded",
      sort: true
    },
    {
      text: "Onboarded",
      dataField: "onboarded",
      sort: true
    },
    {
      text: "Consent Yes (Online Jobs)",
      dataField: "yes_for_online_jobs"
    }
  ];

  // Conditionally add "Data Assigned" column
  if (catSection === "FRESH") {
    columns.splice(
      2,
      0,
      {
        text: "Requested Volume",
        dataField: "data_requested",
        sort: true
      },
      {
        text: "Data Assigned",
        dataField: "data_assigned",
        sort: true
      }
    );
    columns.splice(7, 0, {
      text: "Email Verified",
      dataField: "email_verified",
      sort: true
    });
  }

  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  return (
    <>
      <BootstrapTable
        keyField="moderatorName"
        data={dataList}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
      />
      <div className="custom-pagination">
        <table className="table table-hover table-condensed borderless">
          <thead>
            <tr className="listHeding">
              <th>Total</th>
              <th>{is_active}</th>
              {catSection === "FRESH" && (
                <>
                  <th>{data_requested}</th> <th>{data_assigned}</th>
                </>
              )}
              <th>{data_touched}</th>
              <th>{connected}</th>
              <th>{interested}</th>
              <th>{first_job_posted}</th>
              {catSection === "FRESH" && <th>{email_verified}</th>}
              <th>{documents_uploaded}</th>
              <th>{onboarded}</th>
              <th>{yes_for_online_jobs}</th>
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
};

export default CatList;
