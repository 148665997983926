import React, { useState, useEffect } from "react";
import moment from "moment";
import { getLcsRemarks } from "../../services/metadataApiServices";
import LoadingSpinner from "../LoadingSpinner";
import BootstrapTable from "react-bootstrap-table-next";
import { getBoostLogs } from "./helper";

const BoostLogs = ({ jobId }) => {
  const [viewLog, setViewLog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (viewLog) {
      setLoading(true);
      getBoostLogs(jobId)
        .then(response => {
          setDataList(response);
          setLoading(false);
        })
        .catch(error => {
          console.log("error", error);
          setLoading(false);
        });
    }
  }, [viewLog, jobId]);

  const toggleViewRemarks = () => {
    setViewLog(prevState => !prevState);
  };

  const columns = [
    {
      text: "#",
      dataField: "srNo",
      formatter: (cellContent, row, rowIndex) => rowIndex + 1 // Add 1 to rowIndex to start from 1
    },

    {
      text: "Date & Time",
      dataField: "createdAt",
      formatter: (cellContent, row) => {
        return moment(row.createdAt).isValid()
          ? moment(row.createdAt).format("DD-MM-YYYY h:mm")
          : "";
      }
    },
    {
      text: "Moderator Name",
      dataField: "name"
    },
    {
      text: "Applications​",
      dataField: "applicant_count"
    },
    {
      text: "Recos",
      dataField: "recommendations_count"
    },
    {
      text: "Score",
      dataField: "boost_level"
    },
    {
      text: "Source",
      dataField: "source"
    }
  ];
  const NoDataIndication = () => (
    <span className="red">No boost log found</span>
  );
  return (
    <div className="viewLogContainer">
      <ul className={viewLog ? "active" : null}>
        <li onClick={toggleViewRemarks} className={viewLog ? "active" : null}>
          Boost Logs
          <img
            src={viewLog ? "/images/arrow_up.svg" : "/images/arrow_down.svg"}
            alt="arrow"
            width="10"
            height="6"
            className="down-arrow"
          />
        </li>
      </ul>
      {viewLog && (
        <div className="view-history">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {dataList.length ? (
                <BootstrapTable
                  keyField="lead_id"
                  data={dataList}
                  columns={columns}
                  hover
                  condensed
                  bordered={false}
                  rowClasses="listvalue"
                  headerClasses="listHeding"
                  classes="borderless"
                />
              ) : (
                <NoDataIndication />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BoostLogs;
