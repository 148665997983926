export const campaignDetailsTab = "CAMPAIGN";
export const questionsTab = "QUESTIONS";
export const walkinTab = "WALKIN";
export const leadsClassificationTab = "LEADS_CLASSIFICATION";

export const maxOptionsAllowed = 6;
export const minOptionsReq = 2;
export const maxQuestionsAllowed = 15;
export const minQuestionReq = 1;
export const customOptionMinReq = 0;
export const customOptionMaxReq = 100;
