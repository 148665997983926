import React, { useState, useEffect } from "react";
import moment from "moment";
import { getLcsRemarks } from "../../services/metadataApiServices";
import LoadingSpinner from "../LoadingSpinner";
import BootstrapTable from "react-bootstrap-table-next";

const ViewLcsRemarks = ({ leadId }) => {
  const [viewRemark, setViewRemark] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (viewRemark) {
      setLoading(true);
      getLcsRemarks(leadId)
        .then(res => {
          setDataList(res);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  }, [viewRemark, leadId]);

  const toggleViewRemarks = () => {
    setViewRemark(prevState => !prevState);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "srNo",
      formatter: (cellContent, row, rowIndex) => rowIndex + 1 // Add 1 to rowIndex to start from 1
    },
    {
      text: "Remarks",
      dataField: "remarks"
    },
    {
      text: "Action At",
      dataField: "actioned_at",
      formatter: (cellContent, row) => {
        return moment(row.actioned_at).isValid()
          ? moment(row.actioned_at).format("DD-MM-YYYY h:mm")
          : "";
      }
    },
    {
      text: "Moderator Name",
      dataField: "action_by"
    }
  ];
  const NoDataIndication = () => <div className="spinner">No data found</div>;
  return (
    <div className="viewLogContainer">
      <ul className={viewRemark ? "active" : null}>
        <li
          onClick={toggleViewRemarks}
          className={viewRemark ? "active" : null}
        >
          View Remarks
          <img
            src={viewRemark ? "/images/arrow_up.svg" : "/images/arrow_down.svg"}
            alt="arrow"
            width="10"
            height="6"
            className="down-arrow"
          />
        </li>
      </ul>
      {viewRemark && (
        <div className="view-history">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <BootstrapTable
              keyField="lead_id"
              data={dataList}
              columns={columns}
              hover
              condensed
              bordered={false}
              rowClasses="listvalue"
              headerClasses="listHeding"
              classes="borderless"
              noDataIndication={() => <NoDataIndication />}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ViewLcsRemarks;
