import React, { useMemo, useCallback } from "react";
import { getFormattedValue } from "../../../services/utils";

const VerifiedModeratorList = ({ onChange, moderatorList }) => {
  const total = useMemo(() => moderatorList.length, [moderatorList]);
  const totalAbsent = useMemo(
    () => moderatorList.filter(item => item.is_absent).length,
    [moderatorList]
  );
  const totalActive = useMemo(
    () => moderatorList.filter(item => item.is_active).length,
    [moderatorList]
  );
  const totalVerifiedWeightage = useMemo(
    () =>
      moderatorList.reduce(
        (n, { verified_weightage }) => n + verified_weightage,
        0
      ),
    [moderatorList]
  );
  const totalSuspectedWeightage = useMemo(
    () =>
      moderatorList.reduce(
        (n, { suspected_weightage }) => n + suspected_weightage,
        0
      ),
    [moderatorList]
  );

  const handleInputChange = useCallback(
    (id, value, type) => {
      onChange(id, getFormattedValue(value, true), type);
    },
    [onChange]
  );

  const handleCheckboxChange = useCallback(
    (id, value, type) => {
      onChange(id, value, type);
    },
    [onChange]
  );

  return (
    <div className="moderator-list">
      <table className="table table-hover borderless">
        <thead>
          <tr className="listHeding">
            <th>ID</th>
            <th>Moderator Name</th>
            <th>Suspicious weightage %</th>
            <th>Verify weightage %</th>
            <th>Is Active</th>
            <th>Is Absent</th>
          </tr>
        </thead>
        <tbody>
          {moderatorList.map(item => (
            <tr className="listvalue" key={`moderatorlist${item.moderator_id}`}>
              <td>{item.moderator_id}</td>
              <td>{item.moderator_name}</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={item.suspected_weightage}
                  name="suspected_weightage"
                  onChange={e =>
                    handleInputChange(
                      item.moderator_id,
                      e.target.value,
                      "suspected_weightage"
                    )
                  }
                  maxLength="3"
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={item.verified_weightage}
                  name="verified_weightage"
                  onChange={e =>
                    handleInputChange(
                      item.moderator_id,
                      e.target.value,
                      "verified_weightage"
                    )
                  }
                  maxLength="3"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={item.is_active}
                  onChange={e =>
                    handleCheckboxChange(
                      item.moderator_id,
                      e.target.checked,
                      "is_active"
                    )
                  }
                />
              </td>
              <td valign="top">
                <input
                  type="checkbox"
                  checked={item.is_absent}
                  onChange={e =>
                    handleCheckboxChange(
                      item.moderator_id,
                      e.target.checked,
                      "is_absent"
                    )
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="custom-pagination">
        <table className="table table-hover table-condensed borderless">
          <thead>
            <tr className="listHeding">
              <th>Total</th>
              <th>{total}</th>
              <th>{totalSuspectedWeightage}</th>
              <th>{totalVerifiedWeightage}</th>
              <th>{totalActive}</th>
              <th>{totalAbsent}</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default VerifiedModeratorList;
