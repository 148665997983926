import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

const AdminPannelFilter = ({
  statusMessage,
  searchReport,
  isButtonDisabled,
  tabType
}) => {
  const [sdate, setSDate] = useState(new Date());
  const [edate, setEDate] = useState(new Date());
  const [lead_type, setLeadType] = useState("ALL");

  const updateMessage = (msg, flag, isReload = false, flagUpdate = false) => {
    statusMessage(msg, flag, isReload, flagUpdate);
  };

  const handleSearch = e => {
    e.preventDefault();
    let obj = { lead_type };
    if (tabType === "OVERALL") {
      if (!sdate) {
        updateMessage("Please enter start date", 1);
        return;
      }
      if (!edate) {
        updateMessage("Please enter end date", 1);
        return;
      }
      if (sdate > edate) {
        updateMessage("Please enter start date is less than end date", 1);
        return;
      }
      obj["start_date"] = sdate
        ? moment(sdate).format("YYYY-MM-DD")
        : undefined;
      obj["end_date"] = edate ? moment(edate).format("YYYY-MM-DD") : undefined;
    }

    searchReport(obj);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "lead_type") {
      setLeadType(value);
    }
  };

  // Get today's date
  const today = new Date();
  const dateBefore90Days = new Date(today);
  dateBefore90Days.setDate(today.getDate() - 90);

  return (
    <div className="job_seeker">
      <div className="gap-20"></div>
      <form autoComplete="off" onSubmit={handleSearch} className="search">
        <div className="row">
          <div className="col-md-2">
            <b
              className="downArrow"
              style={{ padding: "3px", marginTop: "13px" }}
            />
            <select
              className="form-control"
              name="lead_type"
              value={lead_type}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select Lead Type
              </option>
              <option value="FIRST_TIME_LEAD">First Time Lead</option>
              <option value="NEW_JOB_LEAD">New Job Lead</option>
              <option value="ALL">All</option>
            </select>
          </div>
          {tabType === "OVERALL" ? (
            <div className="col-md-5">
              <div className="date-lable padding15">Date From: </div>
              <div className="date-field">
                <DatePicker
                  selected={sdate}
                  onChange={date => setSDate(date)}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  maxDate={edate || new Date()}
                  minDate={dateBefore90Days}
                />
              </div>
              <div className="date-lable">To: </div>
              <div className="date-field">
                <DatePicker
                  selected={edate}
                  onChange={date => setEDate(date)}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  maxDate={new Date()}
                  minDate={sdate}
                />
              </div>
            </div>
          ) : null}

          <div className="col-md-2">
            <div className="float-right">
              {isButtonDisabled && (
                <button className="btn btn-primary disable" disabled>
                  Searching ...
                </button>
              )}
              {!isButtonDisabled && (
                <button className="btn btn-primary" onClick={handleSearch}>
                  Search
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="clr"></div>
      <div className="clr gap-20"></div>
    </div>
  );
};

export default AdminPannelFilter;
