import { useState } from "react";
import { checkPermission } from "../../../services/utils";
import NoAccess from "../../Common/NoAccess";
import { TOOLS } from "../../../routes/RouteConstants";
import SqlQuery from "./SqlQuery";
import SqlHistory from "./SqlHistory";
import HeadingRow from "../../Common/HeadingRow";

const WorkBench = () => {
  const [showHistory, setShowHistory] = useState(false);
  const [historyKey, setHistoryKey] = useState(0);
  function viewHistory() {
    setShowHistory(true);
    // Trigger a re-render by changing the key
    setHistoryKey(prevKey => prevKey + 1);
  }
  const isAccess = checkPermission("Workbench");

  return (
    <div className="workbench">
      <div className="gap-20" />
      <HeadingRow heading="Workbench" goBackURL={TOOLS} />
      <div className="gap-20" />
      <div className="whiteCard">
        <div className="gap-20" />
        <div className="container-fluid">
          {isAccess ? (
            <>
              <SqlQuery viewHistory={viewHistory} />
              {showHistory ? <SqlHistory key={historyKey} /> : null}
            </>
          ) : (
            <NoAccess />
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkBench;
