import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious
} from "../ui/pagination";

const LmfsPagination = ({
  handlePagination,
  totalCount,
  activePage,
  itemsPerPage = 10
}) => {
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const generatePaginationItems = () => {
    let paginationItems = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push(
        <PaginationItem key={i}>
          <PaginationLink
            isActive={i === activePage}
            onClick={i === activePage ? null : () => handlePagination(i)}
            href="#"
            className={i === activePage ? "pointer-events-none" : ""}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return paginationItems;
  };

  const getStartingResultIndex = () => (activePage - 1) * itemsPerPage + 1;
  const getEndingResultIndex = () =>
    Math.min(activePage * itemsPerPage, totalCount);
  const showPagination = totalPages > 1;
  return (
    <Pagination className={"border-t justify-between p-4 px-3"}>
      {showPagination ? (
        <div className="flex">
          <PaginationContent>
            {activePage > 1 && (
              <PaginationItem>
                <PaginationPrevious
                  onClick={() => handlePagination(activePage - 1)}
                  href="#"
                />
              </PaginationItem>
            )}
            {generatePaginationItems()}
            {activePage < totalPages && (
              <PaginationItem>
                <PaginationNext
                  onClick={() => handlePagination(activePage + 1)}
                  href="#"
                />
              </PaginationItem>
            )}
          </PaginationContent>{" "}
        </div>
      ) : null}
      <div className="ml-auto">
        Showing {getStartingResultIndex()} to {getEndingResultIndex()} of{" "}
        {totalCount} Results
      </div>
    </Pagination>
  );
};

export default LmfsPagination;
