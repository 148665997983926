const REPORTS = ["RECRUITER", "JOB_SEEKER"];
const INTERVALS = ["MONTH", "WEEK", "DAY"];

const RECRUITER = {
  MONTH: [
    "RC_REACH",
    "RC_REACH2",
    "RC_ACTIVATION",
    "RC_ENGAGEMENT",
    "RC_RETENTION",
    "RC_NPS_SCORE",
    "RC_JOB_CLOSE_FEEDBACK"
  ],
  WEEK: [
    "RC_REACH",
    "RC_REACH2",
    "RC_ACTIVATION",
    "RC_ENGAGEMENT",
    "RC_NPS_SCORE",
    "RC_JOB_CLOSE_FEEDBACK"
  ],
  DAY: ["RC_REACH", "RC_REACH2", "RC_ACTIVATION", "RC_ENGAGEMENT"]
};

const JOB_SEEKER = {
  MONTH: [
    "JS_REACH",
    "JS_GROWTH",
    "JS_ACTIVATION",
    "JS_ENGAGEMENT",
    "JS_RETENTION",
    "JS_FEEDBACK",
    "JS_CATEGORIES",
    "JS_STICKINESS",
    "JS_VALUE_DELIVERY",
    "JS_REPORTS"
  ],
  WEEK: [
    "JS_REACH",
    "JS_GROWTH",
    "JS_ACTIVATION",
    "JS_ENGAGEMENT",
    "JS_FEEDBACK",
    "JS_CATEGORIES",
    "JS_VALUE_DELIVERY",
    "JS_REPORTS",
    "JS_RETENTION"
  ],
  DAY: [
    "JS_REACH",
    "JS_GROWTH",
    "JS_ACTIVATION",
    "JS_ENGAGEMENT",
    "JS_RETENTION"
  ]
};

const VERSIONS = {
  RC_REACH: [1],
  RC_REACH2: [1],
  RC_ACTIVATION: [1],
  RC_ENGAGEMENT: [1],
  RC_RETENTION: [1],
  RC_JOB_CLOSE_FEEDBACK: [1],
  RC_NPS_SCORE: [1],

  JS_REACH: [1],
  JS_GROWTH: [1],
  JS_ACTIVATION: [1],
  JS_ENGAGEMENT: [1],
  JS_RETENTION: [1],
  JS_FEEDBACK: [1],
  JS_CATEGORIES: [1],
  JS_STICKINESS: [1],
  JS_VALUE_DELIVERY: [1],
  JS_REPORTS: [1]
};

module.exports = {
  REPORTS,
  INTERVALS,
  RECRUITER,
  JOB_SEEKER,
  VERSIONS
};
