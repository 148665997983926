import React, { useRef } from "react";
import {
  Button,
  Chip,
  Box,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import { useController } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import LcsFormErrorHook from "../../customHooks/LcsFormErrorHook";

const RenderSelectedValues = props => {
  const { openCalendar, selectedDates, setValue, name, disabled } = props;

  const removeDate = index => {
    const selectedDatesTemp = [...selectedDates];
    selectedDatesTemp.splice(index, 1);
    setValue(name, selectedDatesTemp);
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={openCalendar}
        className="mb-1"
        disabled={disabled}
      >
        <i className="fa-solid fa-calendar-days" />
      </Button>
      <Box className="flex flex-wrap">
        {selectedDates.map((date, index) => (
          <Chip
            key={index}
            label={
              typeof date === "object" ? date?.format?.("MMMM D YYYY") : date
            }
            onDelete={() => removeDate(index)}
            className="m-1"
          />
        ))}
      </Box>
    </>
  );
};

const MultiDatePicker = props => {
  const {
    control,
    name,
    label,
    fullWidth = true,
    error,
    formControlClasses,
    classes,
    setValue,
    calendarPosition = "bottom-start",
    disabled = false,
    rules = {},
    validate,
    ...rest
  } = props;

  const {
    field: { value: selectedDates = [], onChange },
    fieldState
  } = useController({
    name,
    control,
    rules: {
      ...rules,
      validate
    }
  });

  const inputRef = useRef(null);

  LcsFormErrorHook({
    fieldState,
    inputRef
  });

  return (
    <FormControl
      fullWidth={fullWidth}
      error={!!fieldState.error || !!error}
      classes={formControlClasses}
      ref={inputRef}
    >
      <label htmlFor={name}>{label}</label>
      <DatePicker
        multiple
        value={selectedDates}
        onChange={onChange}
        calendarPosition={calendarPosition}
        disabled={disabled}
        render={
          <RenderSelectedValues
            selectedDates={selectedDates}
            setValue={setValue}
            name={name}
            disabled={disabled}
          />
        }
        {...rest}
      />
      {fieldState.error && (
        <FormHelperText>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default MultiDatePicker;
