import { useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import {
  checkPermission,
  getCustomErrorMessage,
  getFormattedValue
} from "../../../services/utils";
import Label from "../../Common/Form/Label";
import CustomTextField from "../../Common/Form/CustomTextField";
import JobHai from "../../../api/JobHai";
import DynamicTable from "../../Common/DynamicTable";
import Heading from "../../Common/Form/Heading";
import { logEvents } from "../../../services/eventsLogs";
import { WORKBENCH_DOWNLOAD_CLICK } from "../../../services/eventsLogs/eventConstants";

const SqlQuery = props => {
  const { viewHistory } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [fieldItems, setFiledItems] = useState({
    query: "",
    offset: 0,
    limit: 5
  });
  const [sqlResult, setQueryResult] = useState([]);
  const [isDownload, setDownload] = useState(false);
  const [queryId, setQueryId] = useState(false);
  function getQuery() {
    const dataObj = {
      query: fieldItems.query,
      offset: Number(fieldItems.offset),
      limit: Number(fieldItems.limit)
    };
    setDownload(false);
    if (!fieldItems.query) {
      setErrorMessage("Please write valid sql query");
      return;
    }
    if (fieldItems.offset.length === 0) {
      setErrorMessage("Please enter valid offset");
      return;
    }
    setErrorMessage("");
    setLoading(true);
    JobHai.post("workbench/query", dataObj)
      .then(response => {
        const {
          result = [],
          error = "",
          query_id = ""
        } = response.data.data || {};
        if (error) setErrorMessage(error);
        else setQueryResult(result);
        if (query_id) setQueryId(query_id);
        setLoading(false);
      })
      .catch(error => {
        let msg = getCustomErrorMessage(error);
        setErrorMessage(msg);
        setLoading(false);
      });
  }

  function handleChange({ target: { name, value } }) {
    setDownload(false);
    if (name === "offset") value = getFormattedValue(value, true);
    setFiledItems(prevValues => ({ ...prevValues, [name]: value }));
  }

  function downloadData() {
    setDownload(true);
    logEvents(WORKBENCH_DOWNLOAD_CLICK, { query_id: queryId });
  }
  const csvFilename = `crm-query-download-${moment(new Date()).format(
    "YYYY-MM-DD-hh_mm_ss"
  )}.csv`;
  const allowDownlonoad = checkPermission("WorkbenchDownload");
  const limitList = allowDownlonoad
    ? [1, 5, 10, 20, 50, 100, 500, 1000, 5000, 10000, 20000, 50000]
    : [1, 5, 10, 20, 50, 100, 500];
  const { query, offset, limit } = fieldItems;
  return (
    <>
      <div className="formRow">
        <div className="fullWidth form-row">
          <div className="col-md-10">
            <Heading heading="Write your Query Here and Submit to get result" />
            <span className="text-danger">{errorMessage}</span>
          </div>
          <div className="col-md-2">
            <div className="col-md-12">
              <div className="float-right download_link" onClick={viewHistory}>
                <b>View Query From History</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="fullWidth">
          <Label lable="Query" />
          <CustomTextField
            value={query}
            onChange={handleChange}
            name="query"
            textArea={true}
            rows="4"
            placeholder="Write here your query and submit to get result"
          />
        </div>
      </div>
      <div className="formRow">
        <div className="left form-row">
          <div className="col-md-4">
            <Label lable="Query Offset" />
            <CustomTextField
              name="offset"
              value={offset}
              onChange={handleChange}
              placeholder="Enter offset Ex. 0 or or 2 etc"
              maxLength="2"
            />
          </div>
          <div className="col-md-4">
            <Label lable="Query Limit" />
            <div className="selectBox">
              <select
                name="limit"
                value={limit}
                onChange={handleChange}
                className={"select"}
              >
                <option value="" disabled>
                  Select query limit
                </option>
                {limitList.map(option => (
                  <option value={option} key={`recB${option}`}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="right form-row">
          <div className="col-md-12">
            <div className="float-right">
              {isLoading ? (
                <button className="btn btn-primary" disabled>
                  Fetching ...
                </button>
              ) : (
                <button className="btn btn-primary" onClick={getQuery}>
                  Submit
                </button>
              )}
              {sqlResult.length && allowDownlonoad ? (
                <span className="marginleft">
                  <button className="btn btn-primary" onClick={downloadData}>
                    <i className="fas fa-download" aria-hidden="true" />
                    Download
                  </button>
                  {isDownload ? (
                    <CSVLink
                      data={sqlResult}
                      filename={csvFilename}
                      className="primary"
                      target="_blank"
                    >
                      Export Data
                    </CSVLink>
                  ) : null}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {sqlResult.length ? (
        <div className="queryResult">
          <DynamicTable data={sqlResult} />
        </div>
      ) : null}
    </>
  );
};

export default SqlQuery;
