import React from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import PaginationFactory from "react-bootstrap-table2-paginator";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import JobLink from "./JobLink";
import { getErrorMessage } from "../../services/utils";
import { AGENT_LEADS } from "../../routes/RouteConstants";
class AgentLeads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "QA",
      leads: [],
      isLoading: true,
      msgData: { msg: "", showMsg: false, msgType: "error" }
    };
  }

  handleClosePopup() {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  }

  componentDidMount() {
    JobHai.get("/v2/get-agent-leads")
      .then(response => {
        let leads = response.data.data;
        this.setState({ leads: leads, isLoading: false });
      })
      .catch(
        function (error) {
          let msg = getErrorMessage(error);
          this.setState({
            isLoading: false,
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
        }.bind(this)
      );
  }

  render() {
    const leadStatusOptions = {
      NEW: "New",
      Qualified: "Qualified",
      "UnQualified - Consultant": "UnQualified - Consultant",
      "UnQualified - Fake": "UnQualified - Fake",
      "UnQualified - Others": "UnQualified - Others"
    };
    const leads = this.state.leads;
    const totalJobs = leads.length;

    leads.map(d => {
      d.edit_link = (
        <JobLink url={`${AGENT_LEADS}/${d.lead_id}`} id={d.lead_id} />
      );
      d.createdAt = moment(d.createdAt).format("DD-MMM-YYYY, hh:mm A");
      d.agent_name = d.agent_name ? d.agent_name + " (" + d.phone + ")" : "NA";
      d.status = d.status ? d.status : "NEW";
      return d;
    });
    const columns = [
      {
        text: "",
        dataField: "_status",
        classes: "statusCircle",
        formatter: (cellContent, row) => {
          if (row.status === "Qualified") {
            return <b className="green" />;
          }
          if (row.status === "NEW") {
            return <b className="yellow" />;
          }
          return <b className="red" />;
        }
      },
      {
        text: "LEAD ID",
        dataField: "lead_id",
        sort: true
      },
      {
        text: "COMPANY NAME",
        dataField: "company_name",
        sort: true
      },
      {
        text: "RECRUITER NAME",
        dataField: "recruiter_name",
        sort: true
      },
      {
        text: "AGENT NAME",
        dataField: "agent_name",
        sort: true
      },
      {
        text: "POST DATE",
        dataField: "createdAt"
      },
      {
        text: "LEAD STATUS",
        dataField: "status",
        classes: "capitalize",
        formatter: cell => leadStatusOptions[cell],
        filter: selectFilter({
          options: leadStatusOptions,
          defaultValue: "NEW"
        })
      },
      {
        text: "",
        dataField: "edit_link"
      }
    ];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );

    const pagination_options = {
      paginationSize: 4,
      pageStartIndex: 0,
      showTotal: true,
      paginationTotalRenderer: customTotal,
      hideSizePerPage: true,
      sizePerPageList: [
        {
          text: "30",
          value: 30
        },
        {
          text: "50",
          value: 50
        },
        {
          text: "All",
          value: leads
        }
      ] // A numeric array is also available. the purpose of above example is custom the text
    };
    const NoDataIndication = () => <div className="spinner">No Data Found</div>;
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <>
          <div className="clr gap-20"></div>
          <div className="clr">
            <PopupMessage
              key={"msg"}
              data={this.state.msgData}
              click={this.handleClosePopup}
            />
            <div className="pageHeading row">
              <h2>
                Agent Leads
                <div className="float-right">
                  <a href={"/jobs/"}>Go Back</a>
                </div>
              </h2>
            </div>
            <div className="clr"></div>
            <div className="row sortingWrap">
              <span>
                <b></b>ALL ({totalJobs})
              </span>
            </div>
            <div className="clr"></div>
            <div className="whitebox leads">
              <BootstrapTable
                keyField="lead_id"
                data={leads}
                columns={columns}
                hover
                condensed
                bordered={false}
                loading={true}
                rowClasses="listvalue"
                headerClasses="listHeding"
                classes="borderless"
                pagination={PaginationFactory(pagination_options)}
                filter={filterFactory()}
                noDataIndication={() => <NoDataIndication />}
              />
            </div>
          </div>
        </>
      );
    }
  }
}

export default AgentLeads;
