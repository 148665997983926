import React from "react";
import DocumentTitle from "react-document-title";
import { checkPermission, isCRMAdmin } from "../../services/utils";
import {
  RECUITERS,
  RAISE_RESTRICT_VALUE,
  VIEW_RESTRICT_VALUE
} from "../../routes/RouteConstants";
import NoAccess from "../Common/NoAccess";
import HeadingRow from "../Common/HeadingRow";

const RestrictValueDelivery = () => {
  const isViewPage = checkPermission("Restrict Value");
  return (
    <DocumentTitle title={"Job Hai CRM - Restrict Value Delivery"}>
      <div className="dashboard">
        <div className="clr gap-20" />
        <HeadingRow heading="Restrict value delivery" goBackURL={RECUITERS} />
        <div className="clr gap-20" />
        <div className="whiteCard">
          {isViewPage ? (
            <div className="container-fluid">
              <div className="clr gap-20"></div>
              <div className="row marginBottom20">
                <div className="col-md-4">
                  <div className="box">
                    <a href={RAISE_RESTRICT_VALUE}>Raise Request</a>
                  </div>
                </div>
                {isCRMAdmin() ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={VIEW_RESTRICT_VALUE}>View All Requests</a>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <NoAccess />
          )}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default RestrictValueDelivery;
