import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import LoadingSpinner from "../LoadingSpinner";

const AssignList = props => {
  const { dataList = [], isLoading, sourceList } = props;
  dataList.map((d, index) => {
    d.uniqueId = `${d.name}_${index}`;
    const findCrawlSource = sourceList.filter(item => item.value === d.source);
    const sourceData = findCrawlSource.length
      ? findCrawlSource[0].label
      : d.source;
    d.sourceData = sourceData;
    return d;
  });
  const columns = [
    {
      text: "Moderator",
      dataField: "name"
    },
    { text: "City", dataField: "city" },
    { text: "Source", dataField: "sourceData" },
    {
      text: "Total Assign Jobs",
      dataField: "total"
    },
    {
      text: "Otp Verified",
      dataField: "otp_verified"
    },
    {
      text: "No Doc",
      dataField: "no_document"
    },
    {
      text: "Doc Rejected",
      dataField: "doc_rejected"
    },
    {
      text: "Doc Under Review",
      dataField: "doc_under_review"
    },
    {
      text: "Doc Verified",
      dataField: "doc_verified"
    },
    {
      text: "Email Verified",
      dataField: "email_verified"
    },
    {
      text: "Doc & Email Verified",
      dataField: "doc_email_verified"
    },
    {
      text: "Black Listed",
      dataField: "blacklisted"
    },
    {
      text: "Gray Listed",
      dataField: "graylisted"
    },
    {
      text: "JML",
      dataField: "JML"
    }
  ];
  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  return (
    <div className="whiteCard">
      <div className="container-fluid">
        <h2>Assign Report (Today)</h2>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <BootstrapTable
            keyField="uniqueId"
            data={dataList}
            columns={columns}
            hover
            condensed
            bordered={false}
            loading={true}
            rowClasses="listvalue"
            headerClasses="listHeding"
            classes="borderless"
            noDataIndication={() => <NoDataIndication />}
          />
        )}
      </div>
    </div>
  );
};

export default AssignList;
