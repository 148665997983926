import React, { useState, useEffect } from "react";
import differenceBy from "lodash/differenceBy";
import MetaData from "../../constants/MetaData";
import AutoSearch from "./Form/AutoSearch";
import { commonTextFieldLimit } from "../../services/constant";
import MultiSelect from "./Form/MultiSelect";
import {
  getCityData,
  getCrmCityData
} from "../../services/metadataApiServices";
import { getErrorMessage } from "../../services/utils";

const SelectCity = ({
  allCity = false,
  value,
  hideNCRCity = false,
  label = "",
  isDelhiNcr = false,
  isMulti = false,
  onChange,
  isDisabled = false,
  filedName
}) => {
  const [selectedCityName, setSelectedCityName] = useState("");
  const [cityList, setCityList] = useState([]);

  const fetchCityDataAndUpdate = async fetchFn => {
    try {
      const response = await fetchFn();
      setCityList(response);
      getCityName(response);
    } catch (error) {
      console.log("Error", getErrorMessage(error));
      setCityList([]);
    }
  };

  useEffect(() => {
    if (allCity) {
      fetchCityDataAndUpdate(getCityData);
    } else {
      fetchCityDataAndUpdate(getCrmCityData);
    }
  }, []);

  const getCityName = list => {
    const ncrCityList = MetaData.ncrCityList;
    const newCityList = hideNCRCity
      ? differenceBy(list, ncrCityList, "_id")
      : list;
    const selectedCity = newCityList.filter(item => item._id === value);
    const cityName = selectedCity.length ? selectedCity[0].label : value;
    setSelectedCityName(cityName);
  };

  const handleCityChange = obj => {
    const { id = "" } = obj;
    const event = {
      target: {
        value: id,
        name: filedName
      }
    };
    onChange(event);
  };

  const getSelectedInput = searchInput => {
    if (!searchInput) {
      const event = {
        target: {
          value: "",
          name: filedName
        }
      };
      onChange(event);
    }
  };

  const ncrCityList = MetaData.ncrCityList;
  let newCityList = hideNCRCity
    ? differenceBy(cityList, ncrCityList, "_id")
    : cityList;

  if (isDelhiNcr) {
    newCityList = [...newCityList, { _id: "DELHI_NCR", label: "Delhi NCR" }];
  }

  const optionsList = newCityList.map(item => ({
    [isMulti ? "value" : "id"]: item._id,
    label: item.label
  }));

  let selectedList = [];
  if (isMulti) {
    selectedList = Array.isArray(value) ? value : [];
  }

  return isMulti ? (
    <MultiSelect
      value={selectedList}
      onChange={onChange}
      optionList={optionsList}
      placeholder={"Select City"}
    />
  ) : (
    <>
      {label && <label>{label}</label>}
      <AutoSearch
        dataList={optionsList}
        getSelectedValue={handleCityChange}
        getSelectedInput={getSelectedInput}
        placeholder={"Select City"}
        variant="outlined"
        id="search-city"
        error={false}
        searchValue={selectedCityName}
        defaultSearch={selectedCityName}
        searchType={"array"}
        isArrow={true}
        maxLength={commonTextFieldLimit}
        isDisable={isDisabled}
      />
    </>
  );
};

export default SelectCity;
