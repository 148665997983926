import BootstrapTable from "react-bootstrap-table-next";
import CustomePagination from "../Common/Pagination";
import moment from "moment";
import { getEditPageURL } from "../../services/utils";

const columns = recId => {
  return [
    {
      text: "Job Id",
      dataField: "jobId",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <a
            href={getEditPageURL("VERIFIED", row.jobId, recId)}
            target="_blank"
            rel="noreferrer"
            className=""
          >
            {row.jobId}
          </a>
        );
      }
    },
    {
      text: "Company Name",
      dataField: "companyName",
      sort: true
    },
    {
      text: "Job Title",
      dataField: "jobTitle",
      sort: true
    },
    {
      text: "Job Role",
      dataField: "jobRole"
    },
    {
      text: "Job Posting Date",
      dataField: "jobPostDate",
      formatter: (cellContent, row) => {
        return moment(row.jobPostDate).isValid()
          ? moment(row.jobPostDate).format("DD-MM-YYYY h:mm")
          : "";
      }
    },
    {
      text: "Credit Reserved",
      dataField: "creditReserved"
    },
    {
      text: "Status",
      dataField: "jobStatus"
    },
    {
      text: "Date Made Live/Rejected",
      dataField: "liveRejectedDate",
      sort: true,
      formatter: (cellContent, row) => {
        return moment(row.liveRejectedDate).isValid()
          ? moment(row.liveRejectedDate).format("DD-MM-YYYY h:mm")
          : "";
      }
    }
  ];
};

function CreditReserved({ data, fetchData }) {
  const tableData = data?.data;
  if (data?.state === "IDLE") return "";

  if (data?.state === "FAILED")
    return data?.errorMsg || "Error in loading data";
  if (data?.state === "LOADING") return "Loading reserved credit data";
  const recId = tableData?.jobsData?.[0]?.recruiterId;
  return (
    <div className={"whitebox"}>
      <BootstrapTable
        keyField="moderator_id"
        data={tableData.jobsData}
        columns={columns(recId)}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <div>No data</div>}
      />
      <CustomePagination
        activePage={tableData?.page}
        itemsCountPerPage={10}
        totalItemsCount={tableData?.totalCount}
        onPageChange={pageNo => fetchData({ page: pageNo })}
        showPagination={true}
        showLimitedRow={true}
      />
      <div className="clr gap-20" />
    </div>
  );
}

export default CreditReserved;
