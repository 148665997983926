import Select from "react-select";
const MultiSelect = props => {
  const {
    onChange,
    value = "",
    optionList,
    placeholder = "Select",
    isMulti = true,
    isDisabled = false
  } = props;
  return (
    <>
      <Select
        value={value}
        onChange={onChange}
        options={optionList}
        isMulti={isMulti}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
    </>
  );
};
export default MultiSelect;
