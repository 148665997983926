const ApplicationsCountSummery = ({ applicantList }) => {
  function countJobsByPlanAndStatus(jobPlan, status) {
    return applicantList.reduce((count, applicant) => {
      if (applicant.jobPlan === jobPlan && applicant.status === status) {
        count++;
      }
      return count;
    }, 0);
  }
  const totalBaiscJobs = applicantList.filter(
    a => a.jobPlan === "BASIC"
  ).length;
  const totalPremiumJobs = applicantList.filter(
    a => a.jobPlan === "PREMIUM"
  ).length;

  const basicLiveJobs = countJobsByPlanAndStatus("BASIC", "LIVE");
  const basicInQAJobs = countJobsByPlanAndStatus("BASIC", "IN QA");
  const basicPendingJobs = countJobsByPlanAndStatus("BASIC", "PENDING");
  const basicExpiredJobs = countJobsByPlanAndStatus("BASIC", "EXPIRED");
  const basicRejectedJobs = countJobsByPlanAndStatus("BASIC", "REJECTED");

  const premiumLiveJobs = countJobsByPlanAndStatus("PREMIUM", "LIVE");
  const premiumInQAJobs = countJobsByPlanAndStatus("PREMIUM", "IN QA");
  const premiumPendingJobs = countJobsByPlanAndStatus("PREMIUM", "PENDING");
  const premiumExpiredJobs = countJobsByPlanAndStatus("PREMIUM", "EXPIRED");
  const premiumRejectedJobs = countJobsByPlanAndStatus("PREMIUM", "REJECTED");

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <span>
            <b></b>BASIC ({totalBaiscJobs})
          </span>
          <span>
            <b className="green" />
            LIVE ({basicLiveJobs})
          </span>
          <span>
            <b className="blue" />
            PENDING ({basicPendingJobs})
          </span>
          <span>
            <b className="yellow" />
            IN QA Jobs ({basicInQAJobs})
          </span>
          <span>
            <b style={{ backgroundColor: "#3405d9" }} />
            EXPIRED ({basicExpiredJobs})
          </span>
          <span>
            <b className="red" />
            REJECTED ({basicRejectedJobs})
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <span>
            <b />
            PREMIUM ({totalPremiumJobs})
          </span>
          <span>
            <b className="green" />
            LIVE ({premiumLiveJobs})
          </span>
          <span>
            <b className="blue" />
            PENDING ({premiumPendingJobs})
          </span>
          <span>
            <b className="yellow" />
            IN QA Jobs ({premiumInQAJobs})
          </span>
          <span>
            <b style={{ backgroundColor: "#3405d9" }} />
            EXPIRED ({premiumExpiredJobs})
          </span>
          <span>
            <b className="red" />
            REJECTED ({premiumRejectedJobs})
          </span>
        </div>
      </div>
    </>
  );
};

export default ApplicationsCountSummery;
