export const linkMaps = {
  callConnected: { next: "pitchCompleted" },
  pitchCompleted: { next: "interested", prev: "callConnected" },
  interested: { next: "paymentLinkGenerated", prev: "pitchCompleted" },
  paymentLinkGenerated: { prev: "interested" }
};
export const getTimes = adjustedTime => {
  let hour = adjustedTime.format("hh");
  let minute = adjustedTime.format("mm");
  let ampm = adjustedTime.format("A");
  return { hour, minute, ampm };
};
export const defaultStates = {
  new: {
    callConnected: undefined,
    pitchCompleted: undefined,
    interested: undefined,
    paymentLinkGenerated: undefined
  },
  cold: {
    pitchCompleted: undefined,
    interested: undefined,
    paymentLinkGenerated: undefined
  },
  warm: {
    pitchCompleted: "Yes",
    interested: "Yes",
    paymentLinkGenerated: undefined
  },
  hot: {
    pitchCompleted: "Yes",
    interested: "Yes",
    paymentLinkGenerated: "Yes"
  }
};

export function checkDisabledValue(key, bucket, currentBindings) {
  const prev = linkMaps[key].prev;
  const next = linkMaps[key].next;
  if (bucket === "new") {
    if (currentBindings[prev] === "No" || currentBindings[prev] == undefined)
      return true;
  }
  if (bucket === "cold") {
    if (currentBindings[prev] === "No" || currentBindings[prev] == undefined)
      return true;
  }
  if (bucket === "warm") {
    if (key === "pitchCompleted") {
      return true;
    }
    if (key == "interested") {
      if (
        currentBindings[linkMaps[prev].prev] === "No" ||
        currentBindings[linkMaps[prev].prev] == undefined
      )
        return true;
    }
    if (key === "paymentLinkGenerated") {
      const prevKey = currentBindings["interested"];
      const firstKey = currentBindings["callConnected"];
      if (
        prevKey === "No" ||
        prevKey == undefined ||
        firstKey == "No" ||
        firstKey == undefined
      )
        return true;
    }
  }
  if (bucket === "hot") {
    if (key == "interested") {
      if (
        currentBindings[linkMaps[prev].prev] === "No" ||
        currentBindings[linkMaps[prev].prev] == undefined
      )
        return true;
    }
    if (key === "pitchCompleted" || key == "paymentLinkGenerated") {
      return true;
    }
  }
}

export function checkSelectedValue(key, bucket, currentBindings) {
  if (bucket === "new" || bucket === "cold") {
    if (currentBindings[key] == "Yes") return "Yes";
    if (currentBindings[key] == "No") return "No";
  }
  if (bucket === "warm") {
    if (currentBindings[key]) {
      return currentBindings[key];
    }
    if (key === "pitchCompleted" || key === "interested") return "Yes";
  }
  if (bucket === "hot") {
    if (currentBindings[key]) {
      return currentBindings[key];
    }
    if (
      key === "pitchCompleted" ||
      key === "interested" ||
      key === "paymentLinkGenerated"
    ) {
      return true;
    }
  }
}

export const getStatusMaps = ({ bucket, currentBindings }) => [
  {
    name: "callConnected",
    view: "Call Connected",
    id: "1",
    disabled: false,
    selected: checkSelectedValue("callConnected", bucket, currentBindings)
  },
  {
    name: "pitchCompleted",
    view: "Pitch Completed",
    id: "2",
    disabled: checkDisabledValue("pitchCompleted", bucket, currentBindings),
    selected: checkSelectedValue("pitchCompleted", bucket, currentBindings)
  },
  {
    name: "interested",
    view: "Interested",
    id: "3",
    disabled: checkDisabledValue("interested", bucket, currentBindings),
    selected: checkSelectedValue("interested", bucket, currentBindings)
  },
  {
    name: "paymentLinkGenerated",
    view: "Payment Link Generated",
    id: "4",
    disabled: checkDisabledValue(
      "paymentLinkGenerated",
      bucket,
      currentBindings
    ),
    selected: checkSelectedValue(
      "paymentLinkGenerated",
      bucket,
      currentBindings
    )
  }
];
