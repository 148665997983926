import React, { PureComponent } from "react";
import Pagination from "react-js-pagination";
import { isCRMAdmin } from "../../services/utils";

class CustomePagination extends PureComponent {
  render() {
    const {
      activePage,
      itemsCountPerPage,
      totalItemsCount,
      pageRange = 5,
      onPageChange,
      showPagination,
      countInfo = false,
      getPageUrl,
      showLimitedRow = false
    } = this.props;
    let countString = "";
    const totalCount =
      !isCRMAdmin() && showLimitedRow && totalItemsCount > 500
        ? 500
        : totalItemsCount;
    const showNonAdminCopy =
      !isCRMAdmin() &&
      showLimitedRow &&
      totalItemsCount > 500 &&
      activePage * itemsCountPerPage === 500;

    let limit =
      totalItemsCount > itemsCountPerPage ? itemsCountPerPage : totalItemsCount;
    countString = ` Showing ${
      totalItemsCount ? activePage : 0
    } to ${limit} of ${totalItemsCount} Results`;
    if (activePage > 1) {
      let limit =
        activePage * itemsCountPerPage > totalItemsCount
          ? totalItemsCount
          : activePage * itemsCountPerPage;
      countString = ` Showing ${
        (activePage - 1) * itemsCountPerPage + 1
      } to ${limit} of ${totalItemsCount} Results`;
    }
    return (
      <div className="custom-pagination">
        <div className="pagination-count">
          {countInfo ? countInfo : countString}
          {showNonAdminCopy
            ? ` (To access the remaining rows, contact admin) `
            : null}
        </div>
        {showPagination ? (
          <Pagination
            hideDisabled
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalCount}
            pageRangeDisplayed={pageRange}
            onChange={onPageChange}
            firstPageText={" << "}
            lastPageText={" >> "}
            prevPageText={" < "}
            nextPageText={" > "}
            innerClass="pagination"
            activeClass="active"
            linkClass="page-link"
            getPageUrl={getPageUrl}
          />
        ) : null}
      </div>
    );
  }
}

export default CustomePagination;
