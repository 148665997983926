import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import JobHai from "../../api/JobHai";

const LeadReAssign = props => {
  const [isReAssign, setReAssignModal] = useState(false);
  const [selectedModerator, setSelectedModerator] = useState("");

  const {
    selectedLead = [],
    getUpdatedLeads,
    leadModeratorList = [],
    btnLabel = "Reassign Lead",
    moderatorId = ""
  } = props;
  function openModel() {
    if (moderatorId) {
      if (
        window.confirm(
          `Are you sure you want to assign the lead to ${
            Number(moderatorId) ? "yourself" : "unassign"
          }?`
        )
      ) {
        callReAssignAPI(moderatorId);
      }
    } else {
      setReAssignModal(true);
    }
  }

  function closeModal() {
    setReAssignModal(false);
  }

  function handleSave() {
    let alertCopy = "";
    if (selectedModerator) {
      const selectedMod = leadModeratorList.filter(
        item => item.moderator_id == selectedModerator
      );
      if (selectedMod.length)
        alertCopy = `Are you sure if you want to reassign the selected leads to ${selectedMod[0].moderator_name}`;
      else
        alertCopy = `Are you sure if you want to unassign the selected leads`;
    }
    if (window.confirm(alertCopy)) {
      callReAssignAPI(selectedModerator);
    }
  }

  const callReAssignAPI = selectedModerator => {
    const moderatorId =
      selectedModerator === "unassign" ? null : selectedModerator;
    const obj = {
      where: { lead_user_id: { _in: selectedLead } },
      set: {
        moderator_id: moderatorId
      }
    };
    JobHai.post("/enterprise-admin/lcs-leads", obj)
      .then(response => {
        if (getUpdatedLeads) getUpdatedLeads(moderatorId);
        setReAssignModal(false);
        setSelectedModerator("");
      })
      .catch(error => {
        console.log("Error: ", error);
      });
  };

  const renderModal = () => {
    return (
      <Modal show={true}>
        <Modal.Body>
          <div className="clr">
            <b>
              Please select moderator to reassign selected leads <br />
            </b>
          </div>
          <div className="gap-20" />
          <div className="clr">
            <select
              className="form-control"
              name="moderator_id"
              value={selectedModerator}
              onChange={e => setSelectedModerator(e.target.value)}
            >
              <option value="">Select Moderator</option>
              {leadModeratorList.map(item => {
                return (
                  <option value={item.moderator_id}>
                    {item.moderator_name}
                  </option>
                );
              })}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={handleSave}
            disabled={!selectedModerator}
          >
            Save
          </button>
          <button className="btn btn-secondary" onClick={closeModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {selectedLead.length ? (
        <button className="btn btn-primary" onClick={openModel}>
          {btnLabel}
        </button>
      ) : null}
      {isReAssign ? renderModal() : null}
    </>
  );
};
export default LeadReAssign;
