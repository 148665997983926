import React from "react";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import {
  getErrorMessage,
  checkPermission,
  isValidUrl,
  getFormattedValue
} from "../../../services/utils";
import { TOOLS } from "../../../routes/RouteConstants";
import { logEvents } from "../../../services/eventsLogs";
import { URL_SHORTENER } from "../../../services/eventsLogs/eventConstants";
import NoAccess from "../../Common/NoAccess";

class URLShortener extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonDisabled: false,
      job_id: "",
      job_url: "",
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      utm_content: "",
      utm_term: "",
      short_url: "",
      copySuccess: false,
      errorMsg: "",
      msgData: { msg: "", showMsg: false, msgType: "" }
    };
  }

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    value = value.replace(" ", "");
    if (name === "job_id") {
      value = getFormattedValue(value, true, true);
    }
    if (name !== "job_url" && name !== "job_id") {
      let regexp = /^[a-zA-Z0-9-_]+$/;
      if (!regexp.test(value)) {
        value = value.substring(0, value.length - 1);
      }
    }
    this.setState({ [name]: value });
  };

  createShortURL = () => {
    const {
      job_id,
      job_url,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term
    } = this.state;
    this.setState({ short_url: "", errorMsg: "" });
    if (!job_id && !job_url) {
      this.setState({
        errorMsg: "Please enter job id or job url"
      });
      return;
    }
    if (job_id && job_url) {
      this.setState({
        errorMsg: "You cannot enter both job id and job url"
      });
      return;
    }
    if (job_url && !isValidUrl(job_url)) {
      this.setState({
        errorMsg: "Please enter valid url"
      });
      return;
    } else {
      if (job_url) {
        let domain = new URL(job_url);
        if (domain.hostname !== "www.jobhai.com") {
          this.setState({
            errorMsg: "Please enter valid Job Hai url"
          });
          return;
        }
      }
    }

    const dataObj = {
      job_id: job_id ? job_id : undefined,
      job_url: job_url ? job_url : undefined,
      utm_source: utm_source ? utm_source : null,
      utm_medium: utm_medium ? utm_medium : null,
      utm_campaign: utm_campaign ? utm_campaign : null,
      utm_content: utm_content ? utm_content : null,
      utm_term: utm_term ? utm_term : null
    };
    this.setState({ isButtonDisabled: true });
    JobHai.post("get-short-link", dataObj)
      .then(response => {
        let data = response.data.data;
        this.setState({
          isButtonDisabled: false,
          short_url: data,
          msgData: { msg: "", showMsg: false, msgType: "error" }
        });
        logEvents(URL_SHORTENER, { ...dataObj, short_url: data });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isButtonDisabled: false,
          short_url: "",
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
      });
  };

  copyLink = () => {
    const { short_url } = this.state;
    try {
      navigator.clipboard.writeText(short_url);
      this.setState({
        copySuccess: true
      });
      setTimeout(() => {
        this.setState({
          copySuccess: false
        });
      }, 1000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  render() {
    const {
      isButtonDisabled,
      msgData,
      job_id,
      job_url,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
      short_url,
      copySuccess,
      errorMsg
    } = this.state;
    const isAccess = checkPermission("Job URL Shortener");
    return (
      <div className="edit-form">
        <div className="gap-20" />
        <PopupMessage data={msgData} click={this.handleClosePopup} />
        <div className="pageHeading row">
          <h2>
            Job URL Shortener
            <div className="float-right">
              <a href={TOOLS}>Go Back</a>
            </div>
          </h2>
        </div>
        <div className="gap-20" />
        <div className="whiteCard">
          <div className="gap-20" />
          <div className="container-fluid">
            {isAccess ? (
              <>
                <div className="form-row">
                  <div className="col-md-2">
                    <input
                      type="text"
                      className="form-control"
                      name="job_id"
                      value={job_id}
                      onChange={this.handleChange}
                      placeholder="Enter job id"
                      maxLength="8"
                      onPaste={e => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={e => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </div>
                  <div className="col-md-1 d-flex justify-content-center">
                    <label>OR</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      name="job_url"
                      value={job_url}
                      onChange={this.handleChange}
                      placeholder="Enter job url"
                      maxLength="200"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-3">
                    <div className="form-row">
                      <input
                        type="text"
                        className="form-control"
                        name="utm_source"
                        value={utm_source}
                        onChange={this.handleChange}
                        placeholder="Enter UTM Source"
                        maxLength="30"
                      />
                    </div>
                    <div className="form-row">
                      <input
                        type="text"
                        className="form-control"
                        name="utm_medium"
                        value={utm_medium}
                        onChange={this.handleChange}
                        placeholder="Enter UTM Medium"
                        maxLength="30"
                      />
                    </div>
                    <div className="form-row">
                      <input
                        type="text"
                        className="form-control"
                        name="utm_campaign"
                        value={utm_campaign}
                        onChange={this.handleChange}
                        placeholder="Enter UTM Compaign"
                        maxLength="30"
                      />
                    </div>
                    <div className="form-row">
                      <input
                        type="text"
                        className="form-control"
                        name="utm_content"
                        value={utm_content}
                        onChange={this.handleChange}
                        placeholder="Enter UTM Content"
                        maxLength="30"
                      />
                    </div>
                    <div className="form-row">
                      <input
                        type="text"
                        className="form-control"
                        name="utm_term"
                        value={utm_term}
                        onChange={this.handleChange}
                        placeholder="Enter UTM Term"
                        maxLength="30"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-3">
                    {isButtonDisabled ? (
                      <button className="btn btn-primary disable" disabled>
                        Creating Short URL ...
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={this.createShortURL}
                      >
                        Create Short URL
                      </button>
                    )}
                    <div className="text-danger">{errorMsg}</div>
                  </div>
                  {short_url ? (
                    <div className="col-md-6">
                      <span className="urlcopyBox">
                        {short_url}
                        <a
                          href={short_url}
                          target="_blank"
                          title="Open in new tab"
                          rel="noreferrer"
                        >
                          <i className="fa-solid fa-up-right-from-square" />
                        </a>
                      </span>
                      <button
                        className="bittonLink"
                        onClick={this.copyLink}
                        disabled={copySuccess}
                        title="Copy"
                      >
                        <i className="fa-solid fa-copy" />
                      </button>
                      {copySuccess ? (
                        <span className="text-success"> Copied </span>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default URLShortener;
