import React from "react";
import Autosuggest from "react-autosuggest";
import JobHai from "../../api/JobHai";
import { getErrorMessage } from "../../services/utils";

class AutoSearch extends React.Component {
  state = {
    inputValue: "",
    suggestions: [],
    isFocus: false,
    clienList: []
  };

  onSuggestionSelected = (event, { suggestionValue }) => {
    //let ref = this.searchRef.current;
  };

  onClearSearch = () => {
    this.setState({ inputValue: "" });
    this.props.handleCompChange("");
  };

  renderInputComponent = inputProps => {
    const { inputValue } = this.state;
    const { ...other } = inputProps;
    let clearButton;
    if (inputValue.length >= 1) {
      clearButton = (
        <button
          type="button"
          className="close clearIcon"
          aria-label="Close"
          onClick={this.onClearSearch}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      );
    }
    return (
      <div className="job_seeker">
        <b className="downArrow"></b>
        <input
          type="text"
          value={inputValue}
          placeholder="Hiring Company Name"
          className="form-control"
          autoComplete="off"
          id="search"
          {...other}
        />
        <div className="close-icon-container">{clearButton}</div>
      </div>
    );
  };

  shouldRenderSuggestions = value => {
    return true;
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    if (value.length > 2) {
      JobHai.get(`/hiring-company-name?suggestion=${value}`)
        .then(
          function (response) {
            let data = response.data.data;
            this.setState({
              suggestions: data
            });
          }.bind(this)
        )
        .catch(
          function (error) {
            let msg = getErrorMessage(error);
            this.setState({
              isButtonDisabled: false,
              msgData: { msg: msg, showMsg: true, msgType: "error" }
            });
            window.scrollTo(0, 0);
          }.bind(this)
        );
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = name => (event, { newValue }) => {
    const regex = /^[a-zA-Z0-9-_ ]+$/;
    if (newValue && regex.test(newValue)) {
      this.setState({
        inputValue: newValue
      });
    } else if (!newValue.length) {
      this.setState({
        inputValue: newValue
      });
    }
    this.props.handleCompChange(newValue);
  };

  getSuggestionValue = suggestion => {
    this.props.handleHiringCompanyList(suggestion);
    //return suggestion.client_name;
    return this.state.inputValue;
  };

  renderSuggestion = suggestion => {
    return <div className="search-list-items">{suggestion.client_name}</div>;
  };

  render() {
    const { suggestions, inputValue } = this.state;
    const { hiringCompList } = this.props;
    const suggestionList = [];
    let tempObj = [];
    suggestions.map(el => {
      tempObj = hiringCompList.filter(f => {
        return f.client_id === el.client_id;
      });
      if (tempObj.length === 0) suggestionList.push(el);
      return true;
    });
    if (inputValue) {
      const selIndex = suggestionList.findIndex(
        obj => obj.client_name === inputValue.trim()
      );
      suggestionList.push(...suggestionList.splice(0, selIndex));
    }

    const inputProps = {
      value: inputValue, // usually comes from the application state
      onChange: this.handleChange("inputValue"), // called every time the input value changes
      type: "text",
      placeholder: "Hiring Company Name",
      className: "form-control"
    };

    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: suggestionList,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
      shouldRenderSuggestions: this.shouldRenderSuggestions,
      onSuggestionSelected: this.onSuggestionSelected,
      focusInputOnSuggestionClick: false
    };

    return (
      <Autosuggest
        {...autosuggestProps}
        inputProps={inputProps}
        theme={{
          container: "auto_container",
          suggestionsContainerOpen: "suggestionsContainerOpen",
          suggestionsList: "suggestionsList",
          suggestion: "suggestion"
        }}
      />
    );
  }
}

export default AutoSearch;
