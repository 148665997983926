import { useEffect } from "react";
import { scrollToElementUsingRef } from "../services/utils";
import useLcsFormSubmitState from "../zustandService/useLcsFormSubmitState";

const LcsFormErrorHook = props => {
  const { inputRef } = props;
  const { formState, updateFormState } = useLcsFormSubmitState(state => ({
    formState: state.formState,
    updateFormState: state.updateFormState
  }));
  useEffect(() => {
    const { fieldState } = props;
    if (fieldState.error?.message && formState === "ERROR") {
      scrollToElementUsingRef(inputRef);
      updateFormState("PENDING");
    }
  }, [formState]);
};

export default LcsFormErrorHook;
