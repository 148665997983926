import React, { useState } from "react";
import SelectCity from "../Common/SelectCity";
import JobHai from "../../api/JobHai";
import { getErrorMessage, getFormattedValue } from "../../services/utils";
import MetaData from "../../constants/MetaData";

const AddCrawlData = props => {
  const [formItems, handleFormItems] = useState({
    moderatorId: "",
    no_jobs: "",
    city_id: "",
    source: "BRAVO",
    category_id: "",
    cohort: "FIRST_TIME_LEAD"
  });
  const [isBtnDisabled, handleBtnClick] = useState(false);
  const [errorMsg, handleError] = useState("");
  function handleChange(event) {
    let {
      target: { name, value }
    } = event;
    let tempObj = { ...formItems };
    if (name === "no_jobs") value = getFormattedValue(value, true, true);
    tempObj[name] = value;
    handleFormItems(tempObj);
    handleError("");
  }
  function startCrawling() {
    const { no_jobs, moderatorId, city_id, source, category_id, cohort } =
      formItems;
    const { getStats } = props;
    if (!source) {
      handleError("Please select source");
      return;
    }
    if (!moderatorId) {
      handleError("Please select moderator name");
      return;
    }
    if (!no_jobs) {
      handleError("Please enter number of jobs");
      return;
    }
    if (no_jobs >= 41) {
      handleError("Please enter number less than or equal to 40");
      return;
    }
    if (!city_id && !category_id) {
      handleError("Please select city or category");
      return;
    }
    if (!cohort) {
      handleError("Please select lead type");
      return;
    }
    handleBtnClick(true);
    JobHai.post(`/insert-new-leads`, {
      moderator_id: moderatorId,
      city_id: city_id ? city_id : null,
      count: no_jobs,
      source,
      category_id: category_id ? category_id : null,
      cohort
    })
      .then(response => {
        getStats();
        handleBtnClick(false);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        handleError(msg);
        handleBtnClick(false);
      });
  }
  const { moderatorId, no_jobs, city_id, source, category_id, cohort } =
    formItems;
  const { moderatorList = [], sourceList, catList } = props;
  return (
    <div className="whiteCard search">
      <div className="container-fluid recruiter-search">
        <h2>Add Crawling</h2>
        <div className="form-row">
          <div className="col-md-2">
            <b className="downArrow" />
            <select
              className="form-control"
              name="moderatorId"
              value={moderatorId}
              onChange={handleChange}
            >
              <option value="">Select Moderator</option>
              {moderatorList.map(option => (
                <option
                  value={option.moderator_id}
                  key={`mod${option.moderator_id}`}
                >
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 crawl">
            <input
              type="text"
              className="form-control"
              name="no_jobs"
              value={no_jobs}
              onChange={handleChange}
              placeholder="Enter number of jobs assign"
              maxLength="2"
            />
          </div>
          <div className="col-md-2">
            <SelectCity
              filedName={"city_id"}
              value={city_id}
              onChange={handleChange}
              label={false}
              isDelhiNcr={true}
              hideNCRCity={true}
            />
          </div>
          <div className="col-md-2">
            <select
              className="form-control"
              name="category_id"
              value={category_id}
              onChange={handleChange}
            >
              <option value="" className="option-selected">
                Select Category
              </option>
              {catList.map(option => (
                <option value={option.id} key={"cat" + option.id}>
                  {option.title}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <b className="downArrow" />
            <select
              className="form-control"
              name="source"
              value={source}
              onChange={handleChange}
            >
              <option value="">Select Source</option>
              {sourceList.map((option, index) => (
                <option value={option.value} key={"cle" + index}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <b className="downArrow" />
            <select
              className="form-control"
              name="cohort"
              value={cohort}
              onChange={handleChange}
            >
              <option value="">Lead Type</option>
              {MetaData.crawledLeadType.map(option => (
                <option value={option.value} key={`recS${option.value}`}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <button
              onClick={startCrawling}
              className="btn btn-primary"
              disabled={isBtnDisabled}
            >
              {isBtnDisabled ? `Processing ...` : "Start Crawling"}
            </button>{" "}
            {errorMsg ? <span className="text-danger">{errorMsg}</span> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCrawlData;
