export const setLocalStorage = (key, value) => {
  if (key) localStorage.setItem(key, value);
};

export const getLocalStorage = key => {
  if (localStorage.getItem(key)) {
    return localStorage.getItem(key);
  } else {
    return null;
  }
};
export const removeLocalStorage = key => {
  if (key) localStorage.removeItem(key);
};
