import { useState } from "react";

const CopyQuery = ({ query }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  function copyQuery(query) {
    try {
      navigator.clipboard.writeText(query);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }
  return (
    <>
      {query}
      {copySuccess ? (
        <span className="text-success"> Copied </span>
      ) : (
        <span
          className="copyquery"
          onClick={() => copyQuery(query)}
          title="Copy"
        >
          <i className="fa-solid fa-copy" />
        </span>
      )}
    </>
  );
};

export default CopyQuery;
