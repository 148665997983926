import React, { useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import SelectCity from "../../Common/SelectCity";
import { crawlSourceList } from "../../../constants/MetaData";
import { getFormattedValue } from "../../../services/utils";

const AutoJobPostSearch = ({
  isButtonDisabled,
  cat,
  statusMessage,
  searchData
}) => {
  const [formState, setFormState] = useState({
    isLoading: true,
    job_title: "",
    recruiter_name: "",
    phone: "",
    email: "",
    company_name: "",
    category_id: "",
    job_city_id: "",
    source: "",
    edate: "",
    sdate: "",
    msgData: { msg: "", showMsg: false, msgType: "error" }
  });

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "job_city_id") {
      setFormState({
        ...formState,
        [name]: value
      });
    } else {
      setFormState({ ...formState, [name]: value });
    }
  };

  const searchJobs = e => {
    e.preventDefault();
    const {
      job_title,
      recruiter_name,
      phone,
      email,
      company_name,
      category_id,
      job_city_id,
      source,
      sdate,
      edate
    } = formState;

    const obj = {
      job_category: category_id,
      job_title,
      recruiter_name,
      city: job_city_id,
      mobile_no: phone,
      email_id: email,
      company_name,
      date_from: sdate ? moment(sdate).format("YYYY-MM-DD") : "",
      date_to: edate ? moment(edate).format("YYYY-MM-DD") : "",
      source
    };

    const filteredObj = Object.keys(obj).reduce((acc, key) => {
      if (obj[key] !== "") {
        acc[key] = obj[key];
      }
      return acc;
    }, {});

    searchData(filteredObj);
  };

  const onPhoneChange = event => {
    let { value } = event.target;
    value = getFormattedValue(value, true, true);

    setFormState({
      ...formState,
      phone: value
    });
  };

  const jobCategories = cat || [];
  return (
    <div className="job_seeker">
      <div className="gap-20"></div>
      <div className="gap-20"></div>
      <form autoComplete="off" onSubmit={searchJobs} className="search">
        <div className="row">
          <div className="col-md-2">
            <b className="downArrow"></b>
            <select
              className="form-control"
              name="category_id"
              value={formState.category_id}
              onChange={handleChange}
            >
              <option value="" className="option-selected">
                Select Job Category
              </option>
              {jobCategories.map(option => (
                <option value={option.id} key={"cat" + option.id}>
                  {option.title}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="job_title"
                placeholder="Job Title"
                value={formState.job_title}
                onChange={handleChange}
                maxLength={50}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="recruiter_name"
                placeholder="Recruiter Name"
                value={formState.recruiter_name}
                onChange={handleChange}
                maxLength={50}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="phone"
                placeholder="Recruiter Mobile no"
                value={formState.phone}
                onChange={onPhoneChange}
                maxLength={10}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="email"
                placeholder="Recruiter Email ID"
                value={formState.email}
                onChange={handleChange}
                maxLength={30}
              />
            </div>
          </div>
          <div className="col-md2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="company_name"
                value={formState.company_name}
                placeholder="Company Name"
                onChange={handleChange}
                maxLength={50}
              />
            </div>
          </div>
          <div className="col-md-2">
            <SelectCity
              filedName={"job_city_id"}
              value={formState.job_city_id}
              onChange={handleChange}
            />
          </div>
          <div className="from-col">Date From</div>
          <div className="col-md-2">
            <DatePicker
              selected={formState.sdate}
              onChange={value => {
                setFormState({ ...formState, sdate: value });
              }}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </div>
          <div className="col-md-1 to-col">To</div>
          <div className="col-md-2">
            <DatePicker
              selected={formState.edate}
              onChange={value => {
                setFormState({ ...formState, edate: value });
              }}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="source"
                onChange={handleChange}
                value={formState.source}
              >
                <option value="" className="option-selected">
                  Select Source
                </option>
                {crawlSourceList.map(source => (
                  <option value={source.label}>{source.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="float-right">
              {isButtonDisabled ? (
                <button className="btn btn-primary disable" disabled>
                  SEARCHING ...
                </button>
              ) : (
                <button className="btn btn-primary" type="submit">
                  SEARCH
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="clr"></div>
      <div className="clr gap-20"></div>
    </div>
  );
};

export default AutoJobPostSearch;
