import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import cx from "classnames";
import {
  getCatSectionVariableData,
  saveCatSectionVariableData
} from "../../../services/metadataApiServices";
import SelectDropdown from "../../ReactSelect";
import classes from "./catAssignmentVarForm.module.css";
import { CircularProgress } from "@material-ui/core";
import PopupMessage from "../../PopupMessage";
import { cityCohorts } from "../../../services/constant";

const onFormSubmit = ({ data, apiMethod, cityList, categoryList }) => {
  for (let key in data) {
    const prevValues = cityCohorts.hasOwnProperty(key)
      ? cityList
      : categoryList;
    const uniqueIdKey = cityCohorts.hasOwnProperty(key) ? "_id" : "id";
    const formIdSet = new Set(data[key].map(item => item[uniqueIdKey]));
    const selectedValues = prevValues.filter(item => item.cat_cohort[key]);
    const combinedData = [
      ...formIdSet,
      ...selectedValues.map(item => item[uniqueIdKey])
    ];
    const combinedDataSet = new Set(combinedData);
    let apiPayload = [];
    combinedDataSet.forEach(item => {
      apiPayload.push({
        [uniqueIdKey]: item,
        isEnabled: formIdSet.has(item)
      });
    });
    data[key] = apiPayload;
  }
  apiMethod(data);
};

const ParentForm = props => {
  const { cityList, categoryList, getApiData, saveApiData } = props;
  const defaultSelectedCities = {};
  for (const [key] of Object.entries(cityCohorts)) {
    defaultSelectedCities[key] = cityList.filter(item => item.cat_cohort[key]);
  }
  const selectedTopCategories = categoryList.filter(
    item => item.cat_cohort.TOP_CATEGORY
  );
  const callSaveApi = async data => {
    await saveApiData(data);
    getApiData();
  };
  const onSubmit = data => {
    onFormSubmit({
      data,
      cityList,
      categoryList,
      apiMethod: callSaveApi
    });
  };
  const method = useForm({
    defaultValues: {
      TOP_CATEGORY: selectedTopCategories,
      ...defaultSelectedCities
    }
  });
  const { handleSubmit } = method;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CityGroup cityList={cityList} method={method} />
        <div className={classes.divider} />
        <CategoryGroup categoryList={categoryList} method={method} />
        <button
          className={cx("btn btn-primary", classes.submitBtnClass)}
          type="submit"
        >
          Submit
        </button>
      </form>
    </>
  );
};

const CityGroup = props => {
  const { cityList, method } = props;
  const { control, watch, trigger } = method;
  const cityCohortCitiesIds = Object.keys(cityCohorts);
  const cityCohortState = watch();
  useEffect(() => {
    trigger();
  }, [cityCohortState]);
  const getCommonElements = id => {
    const selectedCitiesForCohort = cityCohortState[id];
    const selectedCitiesForCohortSet = new Set(
      selectedCitiesForCohort.map(obj => obj._id)
    );
    let commonElements = new Set();
    for (const [key, value] of Object.entries(cityCohortState)) {
      if (key === id) continue;
      for (let item of value) {
        if (selectedCitiesForCohortSet.has(item._id)) {
          commonElements.add(item.label);
        }
      }
    }
    return [...commonElements];
  };

  const validateCities = (id, value) => {
    if (!value || value.length === 0) {
      return "Field is required";
    }
    const commonElements = getCommonElements(id);
    if (commonElements?.length) {
      return `These cities are already present in other cohorts (${commonElements.join(
        ","
      )})`;
    }
    if (id === "TOP_10_CITIES") {
      const elementsRequired = 10;
      if (value.length !== elementsRequired) {
        return `Field must contain ${elementsRequired} elements`;
      }
    }
    return true;
  };

  return (
    <div>
      <h5>City Group Selection</h5>
      <div className={classes.formElementsCnt}>
        {cityCohortCitiesIds.map(id => (
          <SelectDropdown
            key={id}
            fullWidth={false}
            name={id}
            label={cityCohorts[id].label}
            control={control}
            options={cityList}
            closeMenuOnSelect={false}
            validateArray={value => validateCities(id, value)}
            isMulti
            isSearchable
            customClass={classes.dropdownCnt}
            helpText={
              cityCohortState[id]?.length
                ? `${cityCohortState[id]?.length} Cities selected`
                : null
            }
          />
        ))}
      </div>
    </div>
  );
};

const CategoryGroup = props => {
  const { categoryList, method } = props;

  const validateCategories = value => {
    if (!value || value.length === 0) {
      return "Field is required";
    }
  };
  const { control, watch } = method;
  const categoryWatcher = watch("TOP_CATEGORY");
  return (
    <div>
      <h5>Category Group Selection</h5>
      <div className={classes.formElementsCnt}>
        <SelectDropdown
          fullWidth={false}
          name="TOP_CATEGORY"
          label="Select categories"
          control={control}
          options={categoryList}
          closeMenuOnSelect={false}
          labelKey={"title"}
          idKey={"id"}
          validateArray={validateCategories}
          isMulti
          isSearchable
          customClass={classes.dropdownCnt}
          helpText={
            categoryWatcher?.length
              ? `${categoryWatcher?.length} Categories selected`
              : null
          }
        />
      </div>
    </div>
  );
};

const CatAssignmentVarForm = () => {
  const [cityList, setCityList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [showLoader, setLoader] = useState(true);
  const [popupMessageObj, setPopupMessageObj] = useState({ showMsg: false });
  const saveApiData = async data => {
    try {
      setLoader(true);
      await saveCatSectionVariableData(data);
      setLoader(false);
      setPopupMessageObj({
        msgType: "success",
        msg: "Details have been saved successfully",
        showMsg: true
      });
    } catch (error) {
      console.error(error);
      setPopupMessageObj({
        msgType: "error",
        msg: "Error in saving the Data",
        showMsg: true
      });
      setLoader(false);
    }
  };

  const getApiData = async () => {
    try {
      setLoader(true);
      const data = await getCatSectionVariableData();
      setCategoryList(Object.values(data.categories));
      setCityList(Object.values(data.cities));
      setLoader(false);
    } catch (error) {
      console.error(error);
      setPopupMessageObj({
        msgType: "error",
        msg: "Something went wrong",
        showMsg: true
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    getApiData();
  }, []);
  return (
    <>
      {showLoader ? (
        <div className={classes.loaderCnt}>
          <CircularProgress
            classes={{ root: classes.loaderStyles }}
            color="inherit"
          />
        </div>
      ) : (
        <>
          <PopupMessage
            data={popupMessageObj}
            click={() => setPopupMessageObj({ showMsg: false })}
          />
          <ParentForm
            cityList={cityList}
            getApiData={getApiData}
            saveApiData={saveApiData}
            categoryList={categoryList}
          />
        </>
      )}
    </>
  );
};

export default CatAssignmentVarForm;
