import React from "react";

function PlayVideo(props) {
  const { video_id } = props;
  const ytURL = `https://www.youtube.com/watch?v=${video_id}`;
  return (
    <a
      href={ytURL}
      className="primary"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/images/playBtn.svg" alt="playBtn" width="30" height="21" />
    </a>
  );
}
export default PlayVideo;
