import cx from "classnames";
import { withStyles } from "@material-ui/styles";
import { styles } from "../CreditWidget/helper";
import MetaData from "../../constants/MetaData";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import CustomSelect from "../Common/Form/CustomSelect";

const OrderIdCreationForm = props => {
  const creditsValidityList = MetaData.creditsValidityList;
  const { fieldItems, errorMessage, onChange, onSave, classes } = props;
  const { credits, discount, validity } = fieldItems;
  const disabled = !credits || !discount || !validity;
  const basicPrice = credits * 1;
  const discountPrice = discount
    ? basicPrice - (basicPrice * discount) / 100
    : basicPrice;
  const gst = (discountPrice * 18) / 100;
  const netPayable = parseFloat(discountPrice + gst).toFixed(2);
  return (
    <>
      <div className="formRow row">
        <div className="col-md-4">
          <Label lable="No. of Credit" />
          <CustomTextField
            name="credits"
            value={credits}
            onChange={onChange}
            maxLength={6}
            placeholder="Credit"
          />
        </div>
        <div className="col-md-4">
          <Label lable="Discount %" />
          <CustomTextField
            name="discount"
            value={discount}
            onChange={onChange}
            maxLength={2}
            placeholder="Discount"
          />
        </div>
        <div className="col-md-4">
          <Label lable="Validity" />
          <CustomSelect
            name="validity"
            value={validity}
            onChange={onChange}
            optionsList={creditsValidityList}
          />
        </div>
      </div>
      <div
        className={cx(
          "flex",
          "buttonContainer",
          "center",
          credits ? "spaceBetween" : null
        )}
      >
        {credits && (
          <span>
            Basic price: {discountPrice} || Net payable: {netPayable}
          </span>
        )}
        <input
          type="button"
          onClick={() => onSave("PRELIMINARY")}
          value="Save"
          className={cx("submitBtn", "btns", "btn1", "flex", "justifyCenter")}
          disabled={disabled}
        />
      </div>
      {errorMessage && (
        <div className={classes.errorState}>
          <i class="fa fa-x" aria-hidden="true" />
          <span>&nbsp; {errorMessage}</span>
        </div>
      )}
    </>
  );
};
export default withStyles(styles)(OrderIdCreationForm);
