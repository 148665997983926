import React, { useState, useEffect } from "react";
import {
  getErrorMessage,
  getCustomErrorMessage,
  findBooleanState,
  isNullOrEmpty,
  isNoneCallingSelected,
  isAnyCallingConnected,
  isDriverCategory,
  checkPermission,
  checkLCSKeyExist,
  validateLCSDynamicFields,
  getModeratorName
} from "../../services/utils";
import JobHai from "../../api/JobHai";
import SaveChanges from "../JobsNew/SaveChanges";
import UpdateLeadsDetailsV2 from "./UpdateLeadsDetailsV2";
import UpdateLeadsSkeleton from "./UpdateLeadsSkeleton";
import { getCookie } from "../../services/cookie";
import { getLCSLeadMetadata } from "../../services/metadataApiServices";

const UpdateLeadsFormV2 = ({ leadId, statusMessage, clientId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [leadData, setLeadData] = useState({});
  const [leadMetadata, setLeadMetadata] = useState({
    formMetadata: [],
    sendWhatsapp: false,
    callingAttemts: 5,
    walkinCallingAttempts: 5,
    notInterestedInterviewReasonList: []
  });
  const [isQesAnsLoading, setIsQesAnsLoading] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [leadQuesAns, setLeadQuesAns] = useState([]);
  const [notInterestedReasons, setNotInterestedReasons] = useState([]);
  const [initailWallkingCallAttempt, setInitailWallkingCallAttempt] =
    useState(0);
  const [assignTo, setAssignTo] = useState("");
  const [formFields] = useState({
    gender: { options: ["Any", "Male", "Female"] },
    availibility: {
      options: [
        { id: "Yes", label: "Yes" },
        { id: "No", label: "No" }
      ]
    }
  });

  useEffect(() => {
    getLCSLead();
    getLCSQualifyingResponse();
  }, []);

  const getLCSLead = () => {
    JobHai.get(`../crm/lcs-lead/${leadId}`)
      .then(response => {
        const data = response.data.data;
        setInitialState(data);
        const { is_interview_date, is_reconciliation_lead, is_d_1_date } = data;
        getLCSLeadMetadataInfo({
          isInterviewDate: is_interview_date,
          isReconciliationLead: is_reconciliation_lead,
          isD1Date: is_d_1_date
        });
      })
      .catch(error => handleApiError(error, true));
  };

  const getLCSLeadMetadataInfo = ({
    isInterviewDate,
    isReconciliationLead,
    isD1Date
  }) => {
    getLCSLeadMetadata(clientId)
      .then(res => {
        const {
          form = [],
          call_attempts = 5,
          not_interested_reason = {},
          interview_date_not_interested_reason = {},
          reconciliation_not_interested_reason = {},
          d_1_not_interested_reason = {},
          send_whatsapp = false,
          walkin_calling_attempts = 5
        } = res;
        let options = [];
        if (isInterviewDate) {
          options = interview_date_not_interested_reason?.options || [];
        } else if (isReconciliationLead) {
          options = reconciliation_not_interested_reason?.options || [];
        } else if (isD1Date) {
          options = d_1_not_interested_reason?.options || [];
        }
        const notInteresetOptions = not_interested_reason?.options || [];
        setLeadMetadata({
          formMetadata: form,
          sendWhatsapp: send_whatsapp,
          callingAttemts: call_attempts,
          walkinCallingAttempts: walkin_calling_attempts,
          notInterestedInterviewReasonList: options
        });
        setNotInterestedReasons(notInteresetOptions);
      })
      .catch(error => {
        handleApiError(error, true);
      });
  };

  const getLCSQualifyingResponse = () => {
    JobHai.get(`../crm/lcs-qualifying-response/${leadId}`)
      .then(response => {
        setLeadQuesAns(response.data.data);
        setIsQesAnsLoading(false);
      })
      .catch(error => handleApiError(error, false));
  };

  const setInitialState = responseData => {
    const {
      lead_campaign_attributes = "",
      moderator_id = "",
      is_interview_date,
      is_reconciliation_lead,
      interview_day_not_interested_reason,
      interview_day_not_interested_reason_id,
      reconcilation_not_interested_reason,
      reconcilation_not_interested_reason_id,
      is_d_1_date,
      user_interview_intent_d_1,
      user_interview_intent_d_0,
      user_interview_intent_reconciliation,
      walkin_calling_attempt,
      moderator
    } = responseData;
    const leadData = responseData || [];
    if (lead_campaign_attributes) {
      const leadAttributes = JSON.parse(lead_campaign_attributes);
      leadData.experience = leadAttributes.experience;
      leadData.type_of_driver = leadAttributes.type_of_driver;
      leadData.other_type_of_driver = leadAttributes.other_type_of_driver;
    }

    let notInterestedReason = "";
    let notInterestedReasonId = "";
    let userInterviewIntent = "";
    let showUserInterviewIntent = false;
    if (is_interview_date) {
      notInterestedReason = interview_day_not_interested_reason;
      notInterestedReasonId = interview_day_not_interested_reason_id;
      userInterviewIntent = user_interview_intent_d_0;
      showUserInterviewIntent = true;
    } else if (is_d_1_date) {
      notInterestedReason = interview_day_not_interested_reason;
      notInterestedReasonId = interview_day_not_interested_reason_id;
      userInterviewIntent = user_interview_intent_d_1;
      showUserInterviewIntent = true;
    } else if (is_reconciliation_lead) {
      notInterestedReason = reconcilation_not_interested_reason;
      notInterestedReasonId = reconcilation_not_interested_reason_id;
      userInterviewIntent = user_interview_intent_reconciliation;
      showUserInterviewIntent = true;
    }
    leadData.notInterestedReason = notInterestedReason;
    leadData.notInterestedReasonId = notInterestedReasonId;
    leadData.userInterviewIntent = userInterviewIntent;
    leadData.showUserInterviewIntent = showUserInterviewIntent;

    const loginModeratorId = getCookie("moderator_id");
    const loginProxyModeratorId = getCookie("proxy_moderator_id");
    const loginId = loginProxyModeratorId || loginModeratorId;
    let disabledSave = Number(moderator_id) !== Number(loginId);
    if (checkPermission("EnterpriseLeadsAdmin")) {
      disabledSave = false;
    }
    setLeadData(leadData);
    setAssignTo(moderator || "NA");
    setIsSaveDisabled(disabledSave);
    setIsLoading(false);
    const walkinCallingAttempt = !isNullOrEmpty(walkin_calling_attempt)
      ? walkin_calling_attempt
      : {};
    const totalCount = Object.keys(walkinCallingAttempt).length;
    setInitailWallkingCallAttempt(totalCount);
  };

  const handleApiError = (error, setLoading) => {
    const errorMessage = getErrorMessage(error);
    setErrorMessage(errorMessage);
    if (setLoading) setIsLoading(false);
    else setIsQesAnsLoading(false);
  };

  const checkValidation = () => {
    const {
      category_id,
      experience,
      type_of_driver,
      lead_calling_first_status,
      age,
      city_id,
      locality_id
    } = leadData;

    /* Validation */

    if (lead_calling_first_status === "NEW") {
      setErrorMessage("Please select calling status");
      return true;
    }
    const formData = leadMetadata.formMetadata;
    if (isNullOrEmpty(city_id)) {
      setErrorMessage("Please select city");
      return true;
    }
    if (isNullOrEmpty(locality_id)) {
      setErrorMessage("Please select locality");
      return true;
    }

    if (checkLCSKeyExist(formData, "age")) {
      if (!isNullOrEmpty(age) && age < 14) {
        setErrorMessage("Please enter valid age");
        return true;
      }
    }

    /*Form Validation*/
    const validationError = validateLCSDynamicFields(formData, leadData);
    if (validationError) {
      setErrorMessage(validationError);
      return true;
    }
    /*end */
    if (isDriverCategory(category_id)) {
      if (!experience) {
        setErrorMessage("Please select years of experience");
        return true;
      } else if (!type_of_driver) {
        setErrorMessage("Please select Type of Driver (CV/PV)");
        return true;
      }
    }

    /* End Validation */
  };
  const handleFormValidation = () => {
    const {
      lead_status,
      not_interested_reason,
      not_interested_reason_id,
      follow_up_date,
      source = "",
      lead_source = "",
      full_name = "",
      gender = "",
      city_id = "",
      locality_id = ""
    } = leadData;
    const isReferral = source === "CRM" && lead_source === "REFERRAL";

    if (isReferral) {
      if (!full_name) {
        setErrorMessage("Please enter full name");
        return true;
      }
      if (!gender) {
        setErrorMessage("Please select gender");
        return true;
      }
      if (isNullOrEmpty(city_id)) {
        setErrorMessage("Please select city");
        return true;
      }
      if (isNullOrEmpty(locality_id)) {
        setErrorMessage("Please select locality");
        return true;
      }
    } else {
      if (!isNoneCallingSelected(leadData)) {
        setErrorMessage("Please select calling attempt");
        return true;
      }

      if (isAnyCallingConnected(leadData) && !lead_status) {
        setErrorMessage("Please select lead status");
        return true;
      }
    }
    if (lead_status === "Interested") {
      return checkValidation();
    }
    if (
      lead_status === "Not_Interested" &&
      isNullOrEmpty(not_interested_reason_id)
    ) {
      setErrorMessage("Please select reason");
      return true;
    }
    if (
      lead_status === "Not_Interested" &&
      not_interested_reason_id == 0 &&
      isNullOrEmpty(not_interested_reason)
    ) {
      setErrorMessage("Please enter other reason");
      return true;
    }
    if (lead_status === "Follow_up" && isNullOrEmpty(follow_up_date)) {
      setErrorMessage("Please select follow up date");
      return true;
    }
  };

  const saveLead = async () => {
    const isError = handleFormValidation(); // To Validate Form Feilds
    if (isError) {
      return;
    }

    setIsSaveDisabled(true);

    let lead_campaign_attributes = {};
    if (isDriverCategory(leadData.category_id)) {
      lead_campaign_attributes = JSON.stringify({
        experience: leadData.experience,
        type_of_driver: leadData.type_of_driver,
        other_type_of_driver: leadData.other_type_of_driver
      });
    } else {
      lead_campaign_attributes = undefined;
    }
    // console.log(leadData.locality_id);
    let dataObj = {
      full_name: leadData.full_name,
      lead_type: leadData.lead_type,
      gender: leadData.gender,
      lead_user_id: leadId,
      category_id: leadData.category_id,
      city_id: leadData.city_id ? leadData.city_id : null,
      locality_id: leadData.locality_id ? leadData.locality_id : null,
      age: leadData.age,
      lead_campaign_attributes,
      current_salary: leadData.current_salary,
      concern: leadData.concern,
      other_concern: leadData.other_concern,
      remarks: leadData.remarks,
      lead_status: leadData.lead_status ? leadData.lead_status : null,
      lead_calling_first_status: leadData.lead_calling_first_status,
      lead_calling_second_status: leadData.lead_calling_second_status,
      lead_calling_third_status: leadData.lead_calling_third_status,
      lead_calling_fourth_status: leadData.lead_calling_fourth_status,
      lead_calling_fifth_status: leadData.lead_calling_fifth_status
    };

    const {
      lead_status,
      follow_up_date,
      is_interview_date = false,
      is_reconciliation_lead = false,
      notInterestedReason = "",
      not_interested_reason_id,
      not_interested_reason,
      notInterestedReasonId,
      showUserInterviewIntent,
      userInterviewIntent,
      is_d_1_date,
      user_interview_time,
      walkin_calling_attempt
    } = leadData;
    if (lead_status === "Not_Interested") {
      dataObj.not_interested_reason =
        not_interested_reason_id == 0 ? not_interested_reason : null;
      dataObj.not_interested_reason_id = not_interested_reason_id;
    } else if (lead_status === "Interested" && showUserInterviewIntent) {
      const reason = notInterestedReasonId == 0 ? notInterestedReason : null;
      const userIntent = findBooleanState(userInterviewIntent);
      if (is_interview_date) {
        // For interview date - D0
        dataObj.interview_day_not_interested_reason = reason;
        dataObj.interview_day_not_interested_reason_id = notInterestedReasonId;
        dataObj.user_interview_intent_d_0 = userIntent;
      } else if (is_reconciliation_lead) {
        // For reconciliation lead
        dataObj.reconcilation_not_interested_reason = reason;
        dataObj.reconcilation_not_interested_reason_id = notInterestedReasonId;
        dataObj.user_interview_intent_reconciliation = userIntent;
      } else if (is_d_1_date) {
        // For interview date - D1
        dataObj.interview_day_not_interested_reason = reason;
        dataObj.interview_day_not_interested_reason_id = notInterestedReasonId;
        dataObj.user_interview_intent_d_1 = userIntent;
      }
      dataObj.user_interview_time = userIntent ? user_interview_time : null;
      dataObj.walkin_calling_attempt = walkin_calling_attempt;
    } else if (lead_status === "Follow_up") {
      dataObj.follow_up_date = follow_up_date;
    }
    /* dynamic fields*/
    const formData = leadMetadata.formMetadata;
    formData.forEach(meta => {
      if (meta.field === "chip") {
        // dataObj[meta.name] = findBooleanState(leadData[meta.name]);
        // TODO Will handle for other chip label
        // Currently working for Yes/No
        dataObj[meta.name] = findBooleanState(leadData[meta.name]);
      } else if (meta.name === "workshop_availability") {
        dataObj["workshop_availability"] = findBooleanState(
          leadData.workshop_availability
        );
        dataObj["workshop_date"] = leadData.workshop_date;
      } else {
        dataObj[meta.name] = leadData[meta.name] ? leadData[meta.name] : null;
      }
    });
    setErrorMessage("");

    JobHai.post(`/lcs-lead/${leadId}`, dataObj)
      .then(response => {
        setTimeout(() => {
          setIsSaveDisabled(false);
          setIsSaved(true);
        }, 500);
        updateMessage("Information saved successfully");
      })
      .catch(error => {
        let msg = getCustomErrorMessage(error);
        setErrorMessage(msg);
        setIsSaveDisabled(false);
      });
  };

  const onEditInfo = () => {
    setIsSaved(false);
    statusMessage("");
  };

  const updateMessage = (msg, flag) => {
    statusMessage(msg, flag);
  };

  const getFieldData = (fieldName, value) => {
    setErrorMessage("");
    setLeadData(leadData => ({
      ...leadData,
      [fieldName]: value
    }));
  };

  const handleModeratorId = moderatorId => {
    // updated moderator_id on leads data
    getFieldData("moderator_id", moderatorId);
    const loginModeratorId = getCookie("moderator_id");
    const loginProxyModeratorId = getCookie("proxy_moderator_id");
    const loginId = loginProxyModeratorId || loginModeratorId;
    let disabledSave = Number(moderatorId) !== Number(loginId);
    setIsSaveDisabled(disabledSave);
    let moderator = "NA";
    if (Number(moderatorId) === Number(loginId)) {
      moderator = leadData.moderator || getModeratorName();
    }
    setAssignTo(moderator);
  };
  return (
    <div className="rowContainer">
      <div className="containerCard leadForm">
        {isLoading ? (
          <UpdateLeadsSkeleton />
        ) : (
          <>
            <div className={"company-details"} id="update-leads-form">
              <UpdateLeadsDetailsV2
                leadData={leadData}
                leadMetadata={leadMetadata}
                formFields={formFields}
                getFieldData={getFieldData}
                leadQuesAns={leadQuesAns}
                isSaved={isSaved}
                isQesAnsLoading={isQesAnsLoading}
                notInterestedReasons={notInterestedReasons}
                initailWallkingCallAttempt={initailWallkingCallAttempt}
                handleModeratorId={handleModeratorId}
              />
            </div>
            <SaveChanges
              onClick={isSaved ? onEditInfo : saveLead}
              errorMessage={errorMessage}
              disabled={isSaveDisabled}
              ctaText={isSaved ? "Edit information" : "Save Changes"}
              isSaved={isSaveDisabled}
              assignTo={assignTo}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UpdateLeadsFormV2;
