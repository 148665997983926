import { withStyles } from "@material-ui/styles";
import cx from "classnames";
import { styles } from "./helper";
function ConfirmationCTA({ disabled, onSave, onCancel }) {
  return (
    <div className={cx("flex", "center", "spaceBetween", "margintop")}>
      <b>Please confirm</b>
      <div className={cx("flex buttonContainer")}>
        <input
          type="button"
          onClick={onSave}
          value="Save"
          className={cx("submitBtn", "btns", "btn1", "flex", "justifyCenter")}
          disabled={disabled}
        />
        <input
          type="button"
          onClick={onCancel}
          value="Cancel"
          className={cx("submitBtn", "btns", "btn2", "flex", "justifyCenter")}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(ConfirmationCTA);
