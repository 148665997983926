import React from "react";
import { getModeratorName } from "../../services/utils";

const Header = () => {
  const moderatorName = getModeratorName();
  return (
    <div className="header">
      <div className="col-md-4">
        <img
          src="/images/recruiter-new.svg"
          alt="logo"
          width="59"
          height="34"
        />
      </div>
      <div className="col-md-8 text-right">
        <div className="moderatorName">
          {moderatorName}
          <img
            src="/images/arrow_down.svg"
            alt="arrow"
            width="10"
            height="6"
            className="down-arrow"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
