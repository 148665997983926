import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    position: "absolute",
    border: "1px solid #e9ecf5",
    boxShadow: "1px 8px 14px rgba(0, 84, 153, 0.12)",
    padding: "16px 20px",
    borderRadius: "8px",
    width: "248px",
    height: "100px",
    right: "-28px",
    top: "-117px",
    zIndex: 1,
    background: "#ffffff"
  },
  tipClass: {
    height: "14px",
    width: "18px",
    position: "absolute",
    zIndex: 2,
    bottom: "17px",
    right: "-3px"
  }
});

const ToolTip = props => {
  const {
    children,
    classes,
    tipUrl = "/images/tip.svg",
    showTip = true
  } = props;

  return (
    <>
      <div className={classes.root}>{children}</div>
      {showTip && (
        <img
          src={tipUrl}
          className={classes.tipClass}
          height={18}
          width={18}
          alt="tip"
        />
      )}
    </>
  );
};

export default withStyles(styles)(ToolTip);
