import { useEffect, useState } from "react";
import LcsCampaignDetails from "./lcsCampaignDetails";
import LcsCampaignQuestions from "../lcsCampaignQuestions";
import { getValueFromURL } from "../../services/utils";
import Modal from "react-bootstrap/Modal";
import useLcsCampaignEditPermission from "../../zustandService/useLcsCampaignEditPermission";
import useLcsCampaignDetails from "../../zustandService/useLcsCampaignDetails";
import { useParams } from "react-router-dom";
import LcsWalkinCampaignDetails from "./LcsWalkinCampaignDetails";
import { leadsClassificationTab } from "./lcsCampaignConstants";
import LeadsClassificationTab from "./lcsLeadClassificationTab";

function handleBeforeUnload(e) {
  e.preventDefault();
  e.returnValue = "";
}

const CampaignCreationV2 = ({ activeTab = "CAMPAIGN" }) => {
  const [showDuplicateCampaignEdu, setShowDuplicateCampaignEdu] =
    useState(false);

  const { id: campaignId } = useParams();
  const { updateEditPermission } = useLcsCampaignEditPermission(state => ({
    updateEditPermission: state.updateEditPermission
  }));

  const { campaignData } = useLcsCampaignDetails(state => ({
    campaignData: state.campaignData
  }));

  const { campaign_status: campaignStatus = "", id: campaignIdFromApi } =
    campaignData;
  const duplicateCampaignId = getValueFromURL("duplicateCampaignId");

  useEffect(() => {
    if (campaignStatus === "ACTIVE" && campaignId == campaignIdFromApi) {
      // == used due to different types
      updateEditPermission(false);
    }
    // Setup before unload handler
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [duplicateCampaignId, campaignStatus, updateEditPermission]);

  const removeBeforeUnloadHandler = () => {
    window.removeEventListener("beforeunload", handleBeforeUnload, false);
  };

  const handleDuplicateEduClose = () => {
    setShowDuplicateCampaignEdu(false);
  };

  return (
    <div className="company-details">
      <Modal show={showDuplicateCampaignEdu} onHide={handleDuplicateEduClose}>
        <Modal.Body>
          <div className="alert alert-success" role="alert">
            The campaign details have been successfully saved.
          </div>
          <div className="my-4"></div>
          <b>Remember to save the questionnaire to finish.</b>
          <div className="clr" />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleDuplicateEduClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {activeTab === "CAMPAIGN" && (
        <LcsCampaignDetails
          removeBeforeUnloadHandler={removeBeforeUnloadHandler}
        />
      )}
      {campaignId && activeTab === "QUESTIONS" && (
        <div>
          <LcsCampaignQuestions
            removeBeforeUnloadHandler={removeBeforeUnloadHandler}
          />
        </div>
      )}
      {campaignId && activeTab === "WALKIN" && (
        <div>
          <LcsWalkinCampaignDetails
            removeBeforeUnloadHandler={removeBeforeUnloadHandler}
          />
        </div>
      )}
      {campaignId && activeTab === leadsClassificationTab ? (
        <div>
          <LeadsClassificationTab />
        </div>
      ) : null}
      <div className="gap-20" />
    </div>
  );
};

export default CampaignCreationV2;
