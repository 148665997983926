import classes from "./lcsCampaignCreation.module.css";
import MaterialDropdown from "../materialDropDown";
import MaterialInputTextField from "../materialInputTextField";
import MaterialSwitch from "../materialSwitch";
import { Checkbox, Radio } from "@material-ui/core";
import useLcsCampaignDetails from "../../zustandService/useLcsCampaignDetails";
const Options = props => {
  const {
    questionWatcher,
    questionIndex,
    lang,
    readOnly,
    isDefaultLang,
    update,
    defaultOptionObj,
    nextQuestionIdOptions,
    control,
    optionKey,
    minOptionsReq,
    maxOptionsAllowed
  } = props;

  const {
    question_id: questionId = null,
    question_category: questionCategory = "",
    is_active: isQuestinActive = true
  } = questionWatcher;

  const nextQueIdFilteredOptions = nextQuestionIdOptions.filter(item => {
    if (item.index === null) return item;
    if (item.index > questionWatcher.priority_order) return item;
  });

  const { campaignData } = useLcsCampaignDetails(state => ({
    campaignData: state.campaignData
  }));

  const { is_conditional: isConditional = false } = campaignData;

  let numberOfActiveOptions = 0;
  questionWatcher[optionKey]?.forEach(option => {
    numberOfActiveOptions += Number(!!option.is_active);
  });

  const optionRemoveClick = optionIndex => {
    const obj = JSON.parse(JSON.stringify(questionWatcher));
    obj[optionKey].splice(optionIndex, 1);
    update(questionIndex, obj);
  };

  const addOptionClick = () => {
    const obj = JSON.parse(JSON.stringify(questionWatcher));
    const options = [...obj[optionKey]];
    options.push({
      ...defaultOptionObj,
      option_id: null,
      option_label: {
        [lang]: `Option ${questionWatcher[optionKey].length + 1}`
      }
    });
    obj[optionKey] = options;
    update(questionIndex, obj);
  };
  const isWalkinQuestion =
    questionCategory === "interview_date" ||
    questionCategory === "interview_center";
  const optionMaxLength = isWalkinQuestion ? 40 : 16;

  const onOptionActiveStateChange = e => {
    if (e.target.value === "true") {
      alert("Be Careful!! This might impact the lead classification rules");
    }
  };

  const isOptionActiveSwitchDisabled = item =>
    (!item.is_active && numberOfActiveOptions === maxOptionsAllowed) ||
    (item.is_active && numberOfActiveOptions === minOptionsReq);

  const validateNextQuestionIds = value => {
    if (value === 0) {
      // -1:submit form, 0:no option selected
      return "Please select an option";
    }
    return true;
  };

  return (
    <>
      {questionWatcher[optionKey]?.map((item, index) => {
        return (
          <div key={index} className={classes.optionField}>
            {questionWatcher.question_type === "single_chip" ? (
              <Radio checked={false} />
            ) : (
              <Checkbox checked={false} />
            )}
            <MaterialInputTextField
              name={`questions.${questionIndex}.${optionKey}.${index}.option_label.${lang}`}
              label="Option"
              control={control}
              rules={{
                required:
                  isQuestinActive && item.is_active
                    ? "Option is required"
                    : false,
                maxLength:
                  isQuestinActive && item.is_active
                    ? {
                        message: `Max ${optionMaxLength} characters allowed`,
                        value: optionMaxLength
                      }
                    : undefined // No maxLength validation if not active
              }}
              formControlClasses={{
                root: classes.formControlClassOptions
              }}
              fullWidth={false}
              defaultValue={item.option_label?.[lang]}
              readOnly={readOnly}
            />
            {!!questionId && isConditional ? (
              <MaterialDropdown
                name={`questions.${questionIndex}.${optionKey}.${index}.next_question_id`}
                label="Select Next Question"
                control={control}
                rules={{ required: "Please select an option" }}
                options={nextQueIdFilteredOptions}
                fullWidth={false}
                formControlClasses={{
                  root: classes.formNextQuestionId
                }}
                disabled={!isDefaultLang}
                defaultValue={null}
                placeholder={"Submit Form"}
                validate={validateNextQuestionIds}
              />
            ) : null}
            {isDefaultLang ? (
              <MaterialSwitch
                name={`questions.${questionIndex}.${optionKey}.${index}.is_lead_success`}
                label="Lead Success"
                control={control}
                defaultChecked={item.is_lead_success ?? false}
                formControlClasses={{ root: classes.formSwitch }}
                size={"small"}
              />
            ) : null}
            {isDefaultLang && !!questionId ? (
              <MaterialSwitch
                name={`questions.${questionIndex}.${optionKey}.${index}.is_active`}
                label="Active"
                control={control}
                defaultChecked={item.is_active ?? true}
                formControlClasses={{ root: classes.formSwitch }}
                size={"small"}
                customOnChangeHandler={onOptionActiveStateChange}
                disabled={isOptionActiveSwitchDisabled(item)}
              />
            ) : null}
            {isDefaultLang &&
            numberOfActiveOptions > minOptionsReq &&
            !questionId ? (
              <i
                onClick={() => optionRemoveClick(index)}
                className="fa-solid fa-x"
              />
            ) : null}
          </div>
        );
      })}
      {isDefaultLang && numberOfActiveOptions < maxOptionsAllowed ? (
        <div onClick={() => addOptionClick()} className={classes.addOption}>
          Add option
        </div>
      ) : null}
    </>
  );
};
export default Options;
