import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import ReportsConstants from "./ReportsConstants";

class DashboardReports extends React.Component {
  state = {
    isButtonDisabled: false,
    reportType: "",
    interval: "",
    dateFrom: "",
    dateTo: "",
    versionList: [],
    selectedReports: [],
    msgData: { msg: "", showMsg: false, msgType: "error" },
    reportURL: ""
  };

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  componentDidUpdate(prevProps, prevState) {
    const { reportType, interval } = this.state;
    if (reportType !== prevState.reportType) {
      this.setVersion();
    }
    if (interval !== prevState.interval) {
      this.setVersion();
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  setVersion = () => {
    const data = [];
    const { reportType, interval } = this.state;
    if (reportType && interval) {
      const reports = ReportsConstants[reportType][interval];
      for (let i in reports) {
        const rep = {};
        rep.label = reports[i];
        rep.value = reports[i];
        rep.checked = false;
        rep.children = [];
        for (let j in ReportsConstants.VERSIONS[reports[i]]) {
          const child = {};
          child.label = `${reports[i]} - v${
            ReportsConstants.VERSIONS[reports[i]][j]
          }`;
          child.value = `${reports[i]}-v${
            ReportsConstants.VERSIONS[reports[i]][j]
          }`;
          child.checked = false;
          rep.children.push(child);
        }
        data.push(rep);
      }
    }
    this.setState({ versionList: data });
  };

  updatedVersionList = currentNode => {
    const data = this.state.versionList;
    for (let i in data) {
      if (data[i].label === currentNode.value.split("-v")[0]) {
        data[i]["expanded"] = true;
        if (currentNode._depth) {
          if (!currentNode.checked) {
            data[i].checked = currentNode.checked;
          }
          for (let j in data[i].children) {
            if (data[i].children[j].label === currentNode.label) {
              data[i].children[j].checked = currentNode.checked;
              break;
            }
          }
        } else {
          data[i].checked = currentNode.checked;
          if (currentNode.checked) {
            for (let j in data[i].children) {
              data[i].children[j].checked = true;
            }
          }
        }
        break;
      }
    }
    return data;
  };

  onChange = (currentNode, selectedNodes) => {
    const selReports = [];
    for (let i in selectedNodes) {
      if (
        ReportsConstants[this.state.reportType][this.state.interval].includes(
          selectedNodes[i].value
        )
      ) {
        for (let j in ReportsConstants.VERSIONS[selectedNodes[i].value]) {
          const rep = {};
          rep["name"] = selectedNodes[i].value;
          rep["version"] = ReportsConstants.VERSIONS[selectedNodes[i].value][j];
          selReports.push(rep);
        }
      } else {
        const rep = {};
        rep["name"] = selectedNodes[i].value.split("-v")[0];
        rep["version"] = selectedNodes[i].value.split("-v")[1];
        selReports.push(rep);
      }
    }
    this.setState({ selectedReports: selReports });
    this.setState({ versionList: this.updatedVersionList(currentNode) });
  };

  fetchReports = obj => {
    const { reportType, interval, start_date, end_date, reports } = obj;
    if (reportType && interval && start_date && end_date && reports) {
      let url = `/dashboard-reports?interval=${interval}&start_date=${start_date}&end_date=${end_date}&reports=`;
      url += JSON.stringify(reports);
      JobHai.get(url)
        .then(response => {
          const reportURL = response.data.data.url;
          let dwnld = document.createElement("a");
          dwnld.href = reportURL;
          document.body.append(dwnld);
          dwnld.click();
          this.setState({
            reportURL: reportURL,
            isButtonDisabled: false
          });
        })
        .catch(
          function (error) {
            let msg = error.response ? error.response.data.error : error;
            this.setState({
              isButtonDisabled: false,
              msgData: { msg: msg, showMsg: true, msgType: "error" }
            });
          }.bind(this)
        );
    } else {
      this.setState({
        msgData: {
          msg: "All fields are required",
          showMsg: true,
          msgType: "error"
        },
        isButtonDisabled: false
      });
    }
  };

  handleFetchReports = e => {
    this.setState({ isButtonDisabled: true });
    const { reportType, interval, dateFrom, dateTo, selectedReports } =
      this.state;
    e.preventDefault();
    const obj = {
      reportType,
      interval,
      start_date: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : "",
      end_date: dateTo ? moment(dateTo).format("YYYY-MM-DD") : "",
      reports: selectedReports
    };
    this.fetchReports(obj);
  };

  render() {
    const { isButtonDisabled, msgData, reportURL } = this.state;
    return (
      <>
        <PopupMessage data={msgData} click={this.handleClosePopup} />

        <div className="reports">
          <div className="clr gap-20"></div>
          <div className="pageHeading row">
            <h2>
              Dashboard Reports
              <div className="float-right">
                <a href={"/analytics"}>Go Back</a>
              </div>
            </h2>
          </div>
          <div className="clr gap-20"></div>
          <div className="clr gap-20"></div>
          <div className="job_seeker">
            <form
              autoComplete="off"
              onSubmit={this.handleFetchReports}
              className="search"
            >
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <b className="downArrow"></b>
                    <select
                      className="form-control"
                      name="reportType"
                      value={this.state.reportType}
                      onChange={this.handleChange}
                    >
                      <option value="">Report Type</option>
                      {ReportsConstants.REPORTS.map((item, index) => {
                        return (
                          <option value={item.value} key={`rt-${index}`}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <b className="downArrow"></b>
                    <select
                      className="form-control"
                      name="interval"
                      value={this.state.interval}
                      onChange={this.handleChange}
                    >
                      <option value="">Interval Type</option>
                      {ReportsConstants.INTERVALS.map((item, index) => {
                        return (
                          <option value={item.value} key={`in-${index}`}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="row">
                    <div className="from-col">Date From</div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <DatePicker
                          selected={this.state.dateFrom}
                          onChange={value => {
                            this.setState({ dateFrom: value });
                          }}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-1 to-col">To</div>
                    <div className="col-md-4">
                      <DatePicker
                        selected={this.state.dateTo}
                        onChange={value => {
                          this.setState({ dateTo: value });
                        }}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-9">
                  <DropdownTreeSelect
                    data={this.state.versionList}
                    texts={{ placeholder: "Select Reports" }}
                    onChange={this.onChange}
                    onAction={this.onAction}
                    onNodeToggle={this.onNodeToggle}
                  />
                </div>
                <div className="col-md-3">
                  <div className="float-right">
                    {isButtonDisabled ? (
                      <button className="btn btn-primary disable" disabled>
                        Fetching ...
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={this.handleFetchReports}
                      >
                        Get Reports
                      </button>
                    )}
                  </div>
                  {reportURL ? <a href={reportURL}>Download Report</a> : null}
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default DashboardReports;
