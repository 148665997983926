import React from "react";
import moment from "moment";
import MetaData from "../../constants/MetaData";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import { getFormattedValue } from "../../services/utils";
import MultiSelect from "../Common/Form/MultiSelect";

class JobSeekerSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: "",
      name: "",
      email: "",
      phone: "",
      active_days: "",
      role_category: [],
      status: "",
      minExp: "",
      maxExp: "",
      total_exp: "",
      minSal: "",
      maxSal: "",
      distance: "",
      gender: "",
      qualification: "",
      source_city_id: "",
      source_locality_id: "",
      audio: "",
      csv_filename:
        "job-seeker-" +
        moment(new Date()).format("YYYY-MM-DD-hh_mm_ss") +
        ".csv",
      msgData: { msg: "", showMsg: false, msgType: "error" },
      query: "",
      isAppFilter: false,
      isResume: "",
      mobile_data_carrier: "",
      mobile_manufacture: "",
      mobile_model: "",
      android_os: "",
      app_version: ""
    };
  }

  handleChange = event => {
    if (event.target.name === "source_city_id") {
      this.setState({ source_locality_id: "" });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  handleNumberChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: getFormattedValue(value, true, true) });
  };

  changeQualification = params => {
    this.setState({ qualification: params });
  };

  changeCategory = params => {
    this.setState({ role_category: params });
  };
  updateMessage = (msg, flag) => {
    this.props.statusMessage(msg, flag);
  };

  searchJobSeeker = e => {
    e.preventDefault();
    const {
      role_category,
      uid,
      name,
      phone,
      email,
      distance,
      source_city_id,
      source_locality_id,
      status,
      minExp,
      maxExp,
      total_exp,
      minSal,
      maxSal,
      gender,
      active_days,
      qualification,
      audio,
      isResume,
      mobile_data_carrier,
      mobile_manufacture,
      mobile_model,
      android_os,
      app_version
    } = this.state;
    let roleCategory = [];
    if (role_category) roleCategory = role_category.map(option => option.value);

    const obj = {
      uid,
      name,
      email,
      phone,
      city_id: Number(distance) > 0 ? "" : source_city_id,
      locality_id: Number(distance) > 0 ? "" : source_locality_id,
      status,
      minExpCategory: minExp,
      maxExpCategory: maxExp,
      total_exp,
      minSal,
      maxSal,
      distance: Number(distance) > 0 ? distance : "",
      source_locality_id: distance ? source_locality_id : "",
      gender: gender ? gender.toLowerCase() : gender,
      active_days,
      qualification: qualification ? Number(qualification) : "",
      audio,
      isResume,
      mobile_data_carrier,
      mobile_manufacture,
      mobile_model,
      android_os,
      app_version
    };
    if ((!Number(obj.phone) && obj.phone.length > 0) || obj.phone.length > 11) {
      this.updateMessage("Please enter valid mobile number.", 1);
      window.scrollTo(0, 0);
      return;
    }
    if (obj.distance.length > 0 && obj.source_locality_id.length === 0) {
      this.updateMessage("Please select city and location and distance.", 1);
      window.scrollTo(0, 0);
      return;
    }

    if (
      (obj.minExpCategory.length > 0 && roleCategory.length === 0) ||
      (obj.maxExpCategory.length > 0 && roleCategory.length === 0)
    ) {
      this.updateMessage("Please select role to filter by relevant exp.", 1);
      window.scrollTo(0, 0);
      return;
    }
    if (roleCategory.length > 0) obj.role_category = roleCategory;
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        delete obj[key];
      }
    });
    this.props.searchJobSeeker(obj);
  };

  handleAppFilter = () => {
    this.setState(prevState => ({
      isAppFilter: !prevState.isAppFilter
    }));
  };
  render() {
    const { isButtonDisabled, categories = [], query = "" } = this.props;
    const categoryArray = [];
    categories.map(item =>
      categoryArray.push({
        label: item.title,
        value: item.id
      })
    );
    const {
      isAppFilter,
      uid,
      phone,
      name,
      email,
      gender,
      status,
      minSal,
      maxSal,
      active_days,
      role_category,
      source_city_id,
      source_locality_id,
      audio,
      qualification,
      isResume,
      mobile_data_carrier,
      mobile_manufacture,
      mobile_model,
      android_os,
      app_version
    } = this.state;
    let arrQualification = MetaData.qualifications;
    return (
      <div className="job_seeker">
        <form
          autoComplete="off"
          onSubmit={this.searchJobSeeker}
          className="search"
        >
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="uid"
                  value={uid}
                  placeholder="User Id"
                  onChange={this.handleNumberChange}
                  maxLength="10"
                />
              </div>
            </div>
            <div className="col-md-2">
              <input
                className="form-control"
                type="text"
                name="phone"
                value={phone}
                placeholder="Mobile Number"
                onChange={this.handleNumberChange}
                maxLength="10"
              />
            </div>
            <div className="col-md-2">
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Name"
                onChange={this.handleChange}
                value={name}
              />
            </div>
            <div className="col-md-2">
              <input
                className="form-control"
                type="text"
                name="email"
                value={email}
                placeholder="Email"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="gender"
                onChange={this.handleChange}
                value={gender}
              >
                <option value="" className="option-selected">
                  Select Gender
                </option>
                {MetaData.gender.map(option => (
                  <option value={option} key={"g" + option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="status"
                value={status}
                onChange={this.handleChange}
              >
                <option value="" className="option-selected">
                  Select Status
                </option>
                {MetaData.user_status.map(option => (
                  <option value={option.toLowerCase()} key={"g" + option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-2">
              <input
                type="text"
                className="form-control"
                placeholder="Min Salary"
                name="minSal"
                value={minSal}
                onChange={this.handleNumberChange}
                maxLength="8"
              />
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Max Salary"
                  name="maxSal"
                  value={maxSal}
                  onChange={this.handleNumberChange}
                  maxLength="8"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="active_days"
                  value={active_days}
                  placeholder="Last Active Days"
                  onChange={this.handleNumberChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="qualification"
                  onChange={this.handleChange}
                  value={qualification}
                >
                  <option value="" className="option-selected">
                    Select Qualification
                  </option>
                  {arrQualification.map(option => (
                    <option value={option.id} key={"q" + option.id}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <SelectCity
                filedName={"source_city_id"}
                value={source_city_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-2">
              <SelectLocality
                filedName={"source_locality_id"}
                value={source_locality_id}
                cityId={source_city_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-4 multi-select">
              <div className="form-group">
                <MultiSelect
                  value={role_category}
                  onChange={this.changeCategory}
                  optionList={categoryArray}
                  placeholder={"Select Role"}
                />
              </div>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="audio"
                onChange={this.handleChange}
                value={audio}
              >
                <option value="">Select Audio</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="isResume"
                onChange={this.handleChange}
                value={isResume}
              >
                <option value="">Select Resume</option>
                <option value="1">Uploaded</option>
                <option value="0">Not Uploaded</option>
              </select>
            </div>
            {isAppFilter ? (
              <>
                <div className="col-md-2">
                  <input
                    className="form-control"
                    type="text"
                    name="mobile_data_carrier"
                    placeholder="Mobile Data Carrier"
                    onChange={this.handleChange}
                    value={mobile_data_carrier}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    className="form-control"
                    type="text"
                    name="mobile_manufacture"
                    placeholder="Mobile Manufacturer"
                    onChange={this.handleChange}
                    value={mobile_manufacture}
                  />
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="mobile_model"
                      placeholder="Mobile Model"
                      onChange={this.handleChange}
                      value={mobile_model}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <input
                    className="form-control"
                    type="text"
                    name="android_os"
                    placeholder="Android OS"
                    onChange={this.handleChange}
                    value={android_os}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    className="form-control"
                    type="text"
                    name="app_version"
                    placeholder="App Version"
                    onChange={this.handleChange}
                    value={app_version}
                  />
                </div>
              </>
            ) : null}

            <div className={isAppFilter ? "col-md-6" : "col-md-4"}>
              <div className="form-group float-right">
                <div className="download_link" onClick={this.handleAppFilter}>
                  {isAppFilter ? "Hide" : "Show"} App Filter
                </div>
                {isButtonDisabled ? (
                  <button className="btn btn-primary disable" disabled>
                    SEARCHING ...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={this.searchJobSeeker}
                  >
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            {query && (
              <div className="col-md-12 query">
                <>
                  <b>
                    Paste the below query on Metabase for results.
                    <br />
                  </b>
                  {query}
                </>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}
export default JobSeekerSearch;
