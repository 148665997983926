import React from "react";
import Select from "react-select";
import JobHai from "../../api/JobHai";
import MetaData from "../../constants/MetaData";
import PopupMessage from "../PopupMessage";
import LoadingSpinner from "../LoadingSpinner";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import { getErrorMessage, checkPermission } from "../../services/utils";
import { RECUITERS_LEADS } from "../../routes/RouteConstants";
import withRouter from "../../services/withRouter";
import { getCrmMetaData } from "../../services/metadataApiServices";

class LeadsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      industryType: [],
      moderator: [],
      msgData: { msg: "", showMsg: false, msgType: "error" },
      leadId: "",
      companyId: "",
      recruiterId: "",
      actiontype: "add",
      leadForm: {
        company_name: "",
        company_address: "",
        company_city_id: "",
        company_locality_id: "",
        company_type: "",
        company_website: "",
        company_contact_info: "",
        industry_type: "",
        full_name: "",
        email: "",
        phone: "",
        designation: "",
        sourced_from: "",
        job_link: "",
        acquisition_status: "NOT_ACQUIRED",
        call_status: "",
        category_id: "",
        communication_status: "",
        remarks: ""
      },
      roleCategory: []
    };
  }

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  componentDidMount() {
    const { params = {} } = this.props;
    const leadId = params.lead_id ? params.lead_id : "";
    this.crmMetaData();
    let crmTags = {};
    if (localStorage.getItem("crmTags")) {
      crmTags = JSON.parse(localStorage.getItem("crmTags"));
      if (crmTags.industries && crmTags.industries.length) {
        this.setState({
          industryType: crmTags.industries
        });
      } else {
        this.getIndustry();
      }
    } else {
      this.getIndustry();
    }

    if (leadId) {
      this.setState({ leadId: leadId, actiontype: "edit" });
      this.getSingleLead(leadId);
    }
  }

  handleChange = event => {
    const leadData = this.state.leadForm;
    let name = event.target.name;
    let value = event.target.value;
    if (name === "company_city_id") {
      leadData.company_locality_id = "";
    }
    if (name === "phone" || name === "company_contact_info") {
      value = value.replace(/\D/, "");
    }
    leadData[name] = value;
    this.setState({ leadForm: leadData });
  };

  changeCategory = params => {
    this.setState({ roleCategory: params });
  };

  crmMetaData = async () => {
    try {
      const response = await getCrmMetaData();
      const { categories = [] } = response;
      this.setState({
        categories
      });
    } catch (error) {
      const msg = getErrorMessage(error);
      this.setState({
        isLoading: false,
        msgData: { msg: msg, showMsg: true, msgType: "error" }
      });
    }
  };

  getIndustry = () => {
    JobHai.get("../metadata/tags")
      .then(metadata => {
        let data = metadata.data.data;
        localStorage.setItem("crmTags", JSON.stringify(data));
        this.setState({
          industryType: data.industries ? data.industries : []
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isLoading: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
      });
  };

  getSingleLead = leadId => {
    JobHai.get(`/leads/get-one?lead_id=${leadId}`)
      .then(response => {
        let responseData = response.data.data;
        let recruiterData = responseData.AppRecruiter;
        const companyData = recruiterData.AppCompany;

        let roleCategory = [];
        recruiterData.Job_Categories.map(item => {
          return roleCategory.push({
            value: item.id
          });
        });

        let tempCat = [];
        recruiterData.Job_Categories.map((item, index) => {
          tempCat = this.state.categories.filter(a => a.id === item.id);
          if (tempCat[0]) {
            roleCategory[index].label = tempCat[0].title;
          }
          return roleCategory;
        });
        let leadForm = {
          company_name: companyData.company_name,
          company_address: companyData.company_address,
          company_city_id: companyData.city_id,
          company_locality_id: companyData.locality_id,
          company_type: companyData.company_type,
          company_website: companyData.company_website,
          company_contact_info: companyData.company_contact_info,
          industry_type: companyData.industry_type,
          full_name: recruiterData.full_name,
          email: recruiterData.email,
          phone: recruiterData.phone,
          designation: recruiterData.designation,
          sourced_from: responseData.sourced_from,
          job_link: responseData.job_link,
          acquisition_status: responseData.acquisition_status,
          call_status: responseData.call_status,
          //  category_id: categoryId,
          communication_status: responseData.communication_status,
          remarks: recruiterData.remarks
        };
        this.setState({
          isLoading: false,
          leadForm: leadForm,
          companyId: companyData.company_id,
          recruiterId: recruiterData.recruiter_id,
          moderator: responseData.Moderator ? responseData.Moderator : [],
          roleCategory: roleCategory
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isLoading: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
      });
  };

  ValidateEmail = email => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  saveLeads = e => {
    e.preventDefault();
    const leadData = this.state.leadForm;
    const { leadId, actiontype } = this.state;
    if (leadData.company_name === "") {
      this.setState({
        msgData: {
          msg: "Please enter company name",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scroll(0, 0);
      return;
    }
    if (leadData.company_type === "") {
      this.setState({
        msgData: {
          msg: "Please select company type",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scroll(0, 0);
      return;
    }
    if (
      leadData.company_contact_info &&
      leadData.company_contact_info.length < 10
    ) {
      this.setState({
        msgData: {
          msg: "Please enter valid contact number ",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scroll(0, 0);
      return;
    }

    if (leadData.phone && leadData.phone.length < 10) {
      this.setState({
        msgData: {
          msg: "Please enter valid recruiter phone number",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scroll(0, 0);
      return;
    }

    if (
      leadData.email &&
      leadData.email !== "" &&
      !this.ValidateEmail(leadData.email)
    ) {
      this.setState({
        msgData: {
          msg: "Please enter valid email id",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scroll(0, 0);
      return;
    }

    if (!leadData.phone && !leadData.company_contact_info && !leadData.email) {
      this.setState({
        msgData: {
          msg: "Please enter Contact Number  or Recruiter Email or Recruiter Number",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scroll(0, 0);
      return;
    }

    if (leadData.sourced_from === "") {
      this.setState({
        msgData: {
          msg: "Please select source from",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scroll(0, 0);
      return;
    }
    if (leadData.call_status === "") {
      this.setState({
        msgData: {
          msg: "Please select call status",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scroll(0, 0);
      return;
    }

    if (leadId) leadData.lead_id = leadId;
    //  leadData.hiring_in = leadData.category_id ? leadData.category_id : [];
    if (this.state.roleCategory) {
      const roleCategory = this.state.roleCategory.map(option => option.value);
      leadData.hiring_in = roleCategory;
    } else {
      leadData.hiring_in = [];
    }

    delete leadData.category_id;
    Object.keys(leadData).forEach(function (key) {
      if (leadData[key] === "") {
        leadData[key] = null;
      }
    });

    let url = "leads/save";
    JobHai.post(url, leadData)
      .then(response => {
        let data = response.data.data;
        this.setState({
          msgData: {
            msg:
              actiontype === "add"
                ? "Leads has been created"
                : "Leads has been saved",
            showMsg: true,
            msgType: "success"
          }
        });

        if (actiontype === "add" && data.lead_id) {
          setTimeout(function () {
            window.location = `${RECUITERS_LEADS}/${data.lead_id}`;
          }, 500);
        }
        window.scroll(0, 0);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          msgData: {
            msg: msg,
            showMsg: true,
            msgType: "error"
          }
        });
        window.scroll(0, 0);
      });
  };

  render() {
    const {
      categories,
      industryType,
      leadForm,
      leadId,
      companyId,
      recruiterId,
      moderator,
      actiontype,
      isLoading,
      roleCategory
    } = this.state;

    //  const catList = categories;
    const sourceFrom = MetaData.sourced_from;
    let userJsonString = JSON.parse(localStorage.getItem("userData"));
    const moderatorName =
      actiontype === "add"
        ? userJsonString.name
        : moderator.name
        ? moderator.name
        : "";

    let catList = [];

    categories.map(item =>
      catList.push({
        label: item.title,
        value: item.id
      })
    );
    // let selectedCategory = [];
    let selectedCategory = roleCategory;
    const isAllowEdit = !checkPermission("Leads", true);
    if (isLoading) {
      return <LoadingSpinner key="loading" />;
    } else {
      return (
        <div className="edit-form leadform">
          <form autoComplete="off">
            <div className="clr gap-20"></div>
            <div className="clr gap-20"></div>
            <PopupMessage
              data={this.state.msgData}
              click={this.handleClosePopup}
            />
            <div className="pageHeading row">
              <h2>
                {leadId ? "Edit" : "Add"} Lead
                <div className="float-right">
                  <a href={`${RECUITERS_LEADS}/add`}>
                    <i className="fa fa-plus" aria-hidden="true"></i> Add New
                    Lead
                  </a>
                  <span className="sperator">|</span>
                  <a href={RECUITERS_LEADS}>Go Back</a>
                </div>
              </h2>
            </div>
            <div className="gap-10"> </div>
            <div className="whiteCard">
              <div className="container-fluid"></div>
              <h2>
                Company Details
                {companyId ? <span>Company Id - {companyId} </span> : null}
              </h2>
              <div className="gap-10"></div>
              <div className="form-row">
                <div className="col-md-3">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="company_name"
                    value={leadForm.company_name}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <label>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="company_address"
                    value={leadForm.company_address}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <SelectCity
                    label="City"
                    filedName={"company_city_id"}
                    value={leadForm.company_city_id}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <SelectLocality
                    label="Locality"
                    filedName={"company_locality_id"}
                    value={leadForm.company_locality_id}
                    cityId={leadForm.company_city_id}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-3">
                  <label>Company Type</label>
                  <b className="downArrow"></b>
                  <select
                    className="form-control"
                    name="company_type"
                    value={leadForm.company_type}
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>
                    {MetaData.company_type.map(option => (
                      <option value={option} key={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <label>Contact Number </label>
                  <input
                    type="text"
                    className="form-control"
                    name="company_contact_info"
                    value={leadForm.company_contact_info}
                    onChange={this.handleChange}
                    maxLength="12"
                  />
                </div>
                <div className="col-md-3">
                  <label>Website</label>
                  <input
                    type="text"
                    className="form-control"
                    name="company_website"
                    value={leadForm.company_website}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <label>Industry Type</label>
                  <b className="downArrow"></b>
                  <select
                    className="form-control"
                    name="industry_type"
                    value={leadForm.industry_type}
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>
                    {industryType.map(item => (
                      <option value={item.id} key={`it${item.id}`}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="gap-20"></div>
              <div>
                <h2>
                  Recruiter Details
                  {recruiterId ? (
                    <span>Recruiter Id - {recruiterId} </span>
                  ) : null}
                </h2>
              </div>
              <div className="gap-10"></div>
              <div className="form-row">
                <div className="col-md-3">
                  <label>Recruiter Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="full_name"
                    value={leadForm.full_name}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <label>Recruiter Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={leadForm.email}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="col-md-3">
                  <label>Recruiter Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    value={leadForm.phone}
                    maxLength="10"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <label>Recruiter Designation</label>
                  <input
                    type="text"
                    className="form-control"
                    name="designation"
                    value={leadForm.designation}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-6" multi-select>
                  <label>Select Categories</label>
                  <Select
                    value={selectedCategory}
                    onChange={this.changeCategory}
                    options={catList}
                    isMulti
                    placeholder={"Select Categories"}
                  />
                </div>
                <div className="col-md-6">
                  <label>Remarks</label>
                  <textarea
                    name="remarks"
                    value={leadForm.remarks}
                    onChange={this.handleChange}
                    className="form-control"
                    rows="2"
                  />
                </div>
              </div>

              <div className="gap-20"></div>
              <div>
                <h2>
                  About Lead
                  {leadId ? <span>Lead Id - {leadId} </span> : null}
                </h2>
              </div>
              <div className="gap-10"></div>
              <div className="form-row">
                <div className="col-md-3">
                  <label>Sourced from</label>
                  <b className="downArrow"></b>
                  <select
                    className="form-control"
                    name="sourced_from"
                    value={leadForm.sourced_from}
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>
                    {sourceFrom.map(option => (
                      <option value={option} key={`sourcef${option}`}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <label>Job Link</label>
                  <input
                    type="text"
                    className="form-control"
                    name="job_link"
                    value={leadForm.job_link}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <label>Sourced by</label>
                  <input
                    type="text"
                    className="form-control"
                    value={moderatorName}
                    disabled
                  />
                </div>
                {actiontype === "edit" ? (
                  <div className="col-md-3">
                    <label>Acquisition Status</label>
                    <select
                      className="form-control"
                      name="acquisition_status"
                      value={leadForm.acquisition_status}
                      onChange={this.handleChange}
                      disabled={true}
                    >
                      <option value="">Select</option>
                      <option value="ACQUIRED">Acquired</option>
                      <option value="NOT_ACQUIRED">Not Acquired</option>
                    </select>
                  </div>
                ) : null}
                <div className="col-md-3">
                  <label>Call Status</label>
                  <b className="downArrow"></b>
                  <select
                    className="form-control"
                    name="call_status"
                    value={leadForm.call_status}
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>
                    <option value="CONNECTED">Connected</option>
                    <option value="NOT_CONNECTED">Not Connected</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <label>Communication</label>
                  <b className="downArrow"></b>
                  <select
                    className="form-control"
                    name="communication_status"
                    value={leadForm.communication_status}
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>
                    <option value="SENT">Sent</option>
                    <option value="NOT_SENT">Not Sent</option>
                  </select>
                </div>
              </div>

              <div className="gap-10"></div>
              <div className="row">
                <div className="col-md-12 txt-align-right">
                  <button
                    onClick={this.saveLeads}
                    className="bttn"
                    disabled={isAllowEdit}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default withRouter(LeadsForm);
