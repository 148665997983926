import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import JobLink from "./JobLink";
import CustomePagination from "../Common/Pagination";
import { COMPANY_EDIT_PAGE } from "../../routes/RouteConstants";

function ActionLink(props) {
  return (
    <div className="edit_link">
      <JobLink url={props.editUrl} id={props.id} />
    </div>
  );
}

const CrawledJobs = props => {
  const {
    jobs = [],
    role,
    activePage,
    itemsCountPerPage,
    totalItemsCount,
    onPageChange,
    page
  } = props;

  jobs.map(d => {
    const {
      job_id,
      recruiter_id,
      crm_cleaning_status,
      crm_calling_status,
      crm_qa_status
    } = d;
    const editUrl = `${COMPANY_EDIT_PAGE}/${page}/${role}/${job_id}?recId=${recruiter_id}`;

    d.edit_link = <ActionLink key={job_id} editUrl={editUrl} id={job_id} />;
    d.crmCleaningStatus =
      crm_cleaning_status != null ? crm_cleaning_status : "NEW";
    d.crmCallingStatus =
      crm_calling_status != null ? crm_calling_status : "NEW";
    d.crmQaStatus = crm_qa_status != null ? crm_qa_status : "NEW";
    return d;
  });

  const totalJobs = Object.keys(jobs).length;
  const cleanJobs = Object.keys(
    jobs.filter(a => a.crm_status === "CLEANED")
  ).length;
  const inProgressJobs = Object.keys(
    jobs.filter(a => a.crm_status === "IN_PROGRESS")
  ).length;
  const newJobs = Object.keys(jobs.filter(a => a.crm_status === "NEW")).length;
  const crmRejectedJobs = Object.keys(
    jobs.filter(a => a.crm_status === "REJECTED")
  ).length;

  const columns = [
    {
      text: "",
      dataField: "_status",
      classes: "statusCircle",
      formatter: (cellContent, row) => {
        if (row.crm_status === "NEW") {
          return <b className="blue"></b>;
        }

        if (row.crm_status === "CLEANED") {
          return <b className="green"></b>;
        }
        if (row.crm_status === "IN_PROGRESS") {
          return <b className="yellow"></b>;
        }
        return <b className="red"></b>;
      }
    },
    {
      text: "JOB ID",
      dataField: "job_id",
      sort: true
    },
    {
      text: "COMPANY NAME",
      dataField: "company_name",
      sort: true
    },
    {
      text: "Role Category",
      dataField: "role_category",
      sort: true
    },
    {
      text: "JOB TITLE",
      dataField: "job_title"
    },
    {
      text: "STATUS",
      dataField: "job_status",
      sort: true
    },
    {
      text: "Cleaning Status",
      dataField: "crmCleaningStatus",
      classes: "capitalize"
    },
    {
      text: "Calling Status",
      dataField: "crmCallingStatus",
      classes: "capitalize"
    },
    {
      text: "QA Status",
      dataField: "crmQaStatus",
      defaultValue: "NEW",
      classes: "capitalize"
    },
    {
      text: "",
      dataField: "edit_link"
    }
  ];
  const NoDataIndication = () => <div className="spinner">No Jobs Found</div>;
  return (
    <>
      <div className="clr"></div>
      <div className="row sortingWrap">
        <span>
          <b></b>ALL ({totalJobs})
        </span>
        <span>
          <b className="yellow"></b>IN PROGRESS ({inProgressJobs})
        </span>
        <span>
          <b className="green"></b>CLEANED ({cleanJobs})
        </span>
        <span>
          <b className="blue"></b>NEW ({newJobs})
        </span>
        <span>
          <b className="red"></b>CRM REJECTED ({crmRejectedJobs})
        </span>
      </div>
      <div className="clr"></div>
      <div className="whitebox jobs">
        <BootstrapTable
          keyField="job_id"
          data={jobs}
          columns={columns}
          hover
          condensed
          bordered={false}
          loading={true}
          rowClasses="listvalue"
          headerClasses="listHeding"
          classes="borderless"
          noDataIndication={() => <NoDataIndication />}
        />
        <CustomePagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onPageChange={onPageChange}
          showPagination={totalItemsCount > 0 ? true : false}
        />
        <div className="clr gap-20"></div>
      </div>
    </>
  );
};
export default CrawledJobs;
