import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationFactory from "react-bootstrap-table2-paginator";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import withRouter from "../../services/withRouter";
import PremiumTag from "../Common/PremiumTag";
import MetaData from "../../constants/MetaData";
import {
  getErrorMessage,
  getEditPageURL,
  getFormattedValue,
  isNullOrEmpty
} from "../../services/utils";
import {
  RECRUITER_JS_DETAILS,
  RECUITERS_LIST
} from "../../routes/RouteConstants";
import HeadingRow from "../Common/HeadingRow";
import ApplicationsCountSummery from "./ApplicationsCountSummery";

const Applications = ({ params = {} }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get("type");
  const [applicantList, setApplicantList] = useState([]);
  const [initailApplicantList, setInitalApplicantList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [msgData, setMsgData] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });
  const rec_id = params.id || "";
  const [status, setStatus] = useState(type);
  const [jobId, setJobId] = useState("");
  const [is_consultant_job, setIsConsultantJob] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [jobPlan, setJobPlan] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getRecJobs();
  }, []);

  const getRecJobs = async () => {
    let url = `/view-recruiter-jobs?recruiter_id=${Number(rec_id)}${
      jobId ? `&job_id=${Number(jobId)}` : ""
    }`;
    if (jobPlan) url += `&jobPlan=${jobPlan}`;
    try {
      const response = await JobHai.get(url);
      const data = response.data.data.jobs;
      setInitalApplicantList(data);
      const filteredData = status
        ? handleApplicantList({
            name: "status",
            value: status,
            dataList: data
          })
        : data;
      setApplicantList(filteredData);
      setTotalCount(data.length);
      setIsLoading(false);
      setIsButtonDisabled(false);
    } catch (error) {
      const msg = getErrorMessage(error);
      setMsgData({ msg, showMsg: true, msgType: "error" });
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const handleApplicantList = data => {
    const { name, value, dataList } = data;
    return dataList.filter(item => item[name] === value);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "jobId") setJobId(getFormattedValue(value, true, true));
    else if (name === "status") setStatus(value);
    else if (name === "is_consultant_job") setIsConsultantJob(value);
    else if (name === "jobPlan") setJobPlan(value);
  };

  const changeStatusFilter = event => {
    const { name, value } = event.target;
    const filterData = !isNullOrEmpty(value)
      ? handleApplicantList({ name, value, dataList: initailApplicantList })
      : applicantList;
    setStatus(value);
    setApplicantList(filterData);
  };

  const searchRecJobs = () => {
    getRecJobs();
    setIsButtonDisabled(true);
  };

  const handleClosePopup = () => {
    setMsgData({ msg: "", showMsg: false });
  };

  const columns = [
    {
      text: "Job ID",
      dataField: "job_id",
      formatter: (rowContent, row) => {
        const editUrl = getEditPageURL(
          row.verification_status,
          row.job_id,
          rec_id,
          "QA"
        );
        return (
          <>
            <a
              href={`${editUrl}&returnURL=applicant`}
              target={"_blank"}
              rel="noreferrer"
            >
              {rowContent}
            </a>
            <PremiumTag jobPlan={row.jobPlan} />
          </>
        );
      }
    },
    { text: "Company Name", dataField: "company_name" },
    { text: "Job Title", dataField: "job_title", sort: true },
    { text: "Role", dataField: "role", sort: true },
    { text: "Status", dataField: "status", sort: true },
    { text: "Openings", dataField: "no_of_openings", sort: true },
    { text: "Unique JS", dataField: "uniq_user_count", sort: true },
    {
      text: "Applications (Applies & Calls)",
      dataField: "applies_count",
      sort: true,
      formatter: (rowContent, row) => {
        return Number(row.js_call_count) + Number(row.applies_count);
      }
    },
    { text: "Reco", dataField: "reco_count", sort: true },
    { text: "Called", dataField: "r_call_count", sort: true },
    { text: "Shortlisted", dataField: "shortlist_count", sort: true },
    { text: "Rejected", dataField: "reject_count", sort: true },
    {
      text: "",
      dataField: "view_js",
      formatter: (rowContent, row) => {
        return (
          <>
            <a
              href={`${RECRUITER_JS_DETAILS}/${row.job_id}`}
              target={"_blank"}
              title="View Job Seeker"
              rel="noreferrer"
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </a>
          </>
        );
      }
    }
  ];

  const pagination_options = {
    paginationSize: 4,
    pageStartIndex: 0,
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    ),
    hideSizePerPage: true,
    sizePerPageList: [
      { text: "30", value: 30 },
      { text: "50", value: 50 },
      { text: "All", value: totalCount }
    ]
  };

  const statusOptions = [
    { val: "", label: "Select Status" },
    { val: "LIVE", label: "Live" },
    { val: "IN QA", label: "In QA" },
    { val: "PENDING", label: "Pending" },
    { val: "EXPIRED", label: "Expired" },
    { val: "REJECTED", label: "Rejected" }
  ];

  const NoDataIndication = () => <div className="spinner">No Data Found</div>;

  if (isLoading) {
    return <LoadingSpinner key="loading" />;
  }
  return (
    <div className="job_seeker">
      <div className="clr gap-20" />
      <PopupMessage data={msgData} key="popup" click={handleClosePopup} />
      <HeadingRow heading="Applications" goBackURL={RECUITERS_LIST} />
      <div className="row">
        <div className="col-md-1">
          <div className="form-group">
            <b className="downArrow" />
            <select
              className="form-control"
              name="status"
              onChange={changeStatusFilter}
              value={status}
            >
              {statusOptions.map(option => (
                <option value={option.val} key={option.val}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <b className="downArrow" />
            <select
              className="form-control"
              name="is_consultant_job"
              value={is_consultant_job}
              onChange={changeStatusFilter}
            >
              <option value="">Consultant Job</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="jobId"
              placeholder="Job Id"
              value={jobId}
              onChange={handleChange}
              maxLength={10}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <b
              className="downArrow"
              style={{ padding: "3px", marginTop: "13px" }}
            />
            <select
              className="form-control"
              name="jobPlan"
              value={jobPlan}
              onChange={handleChange}
            >
              <option value="">Select Job Type</option>
              {MetaData.premiumJobType.map(option => (
                <option value={option.value} key={`premium${option}`}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-1">
          <div className="float-right">
            <button
              className="btn btn-primary"
              onClick={searchRecJobs}
              disabled={isButtonDisabled}
            >
              SEARCH
            </button>
          </div>
        </div>
        <div className="col-md-5 sortingWrap">
          <ApplicationsCountSummery applicantList={initailApplicantList} />
        </div>
      </div>
      <div className="clr gap-20" />
      <div className="whitebox rec-job-list">
        <BootstrapTable
          key="applicant"
          keyField="serialNo"
          data={applicantList}
          columns={columns}
          hover
          condensed
          bordered={false}
          loading={true}
          rowClasses="listvalue"
          headerClasses="listHeding"
          classes="borderless"
          pagination={PaginationFactory(pagination_options)}
          noDataIndication={() => <NoDataIndication />}
        />
        <div className="clr gap-20" />
      </div>
    </div>
  );
};

export default withRouter(Applications);
