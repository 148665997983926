import React, { useEffect, useMemo, useState } from "react";
import classes from "./lcsWalkinCampaignDetails.module.css";
import Heading from "../../Common/Form/Heading";
import { useFieldArray } from "react-hook-form";
import MaterialInputTextField from "../../materialInputTextField";
import { Button } from "@material-ui/core";
import TabsList from "../../TabsList";
import useLcsCampaignDetails from "../../../zustandService/useLcsCampaignDetails";

const InterviewStepsLang = props => {
  const { method, lang } = props;
  const { control, watch } = method;
  const {
    fields: interviewSteps,
    append,
    remove
  } = useFieldArray({
    name: `interview_steps.${lang}.list`,
    control,
    rules: { minLength: 1, maxLength: 5 }
  });
  return (
    <>
      {interviewSteps.map((item, index) => (
        <React.Fragment key={item.id}>
          <h2 className={"subHeading mt-4"}>
            Interview step {index + 1}
            <i
              onClick={() => remove(index)}
              className="fa-regular fa-circle-xmark cursor-pointer mx-1"
            />
          </h2>
          <div className={classes.fieldsGridClass}>
            <MaterialInputTextField
              name={`interview_steps.${lang}.list.${index}.heading`}
              label="Title"
              control={control}
              rules={{
                maxLength: {
                  value: 32,
                  message: "Maximum length is 32 characters"
                }
              }}
              fullWidth={false}
            />
            <MaterialInputTextField
              name={`interview_steps.${lang}.list.${index}.body`}
              label="Description"
              control={control}
              rules={{
                maxLength: {
                  value: 100,
                  message: "Maximum length is 100 characters"
                }
              }}
              fullWidth={false}
            />
          </div>
        </React.Fragment>
      ))}
      {interviewSteps.length < 5 ? (
        <div className="flex justify-end mt-3">
          <Button
            variant="contained"
            color="primary"
            onClick={e => {
              e.preventDefault();
              append({
                heading: "",
                body: ""
              });
            }}
          >
            Add
            <i className="fa-solid fa-plus mx-1" />
          </Button>
        </div>
      ) : null}
    </>
  );
};

const InterviewSteps = props => {
  const { method } = props;
  const { campaignData, lcsMetaData, formError } = useLcsCampaignDetails(
    state => ({
      campaignData: state.campaignData,
      lcsMetaData: state.lcsMetaData,
      formError: state.formError
    })
  );

  useEffect(() => {
    if (!!formError.walkin.interview_steps) {
      const langList = Object.keys(formError.walkin.interview_steps);
      if (langList.length > 0) setActiveTab(langList[0]);
    }
  }, [formError]);
  const { allowed_languages: allowedLangs, default_language: defaultLang } =
    campaignData;
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (defaultLang) {
      setActiveTab(defaultLang);
    }
  }, [defaultLang]);

  const { language: languageList } = lcsMetaData;
  const tabList = useMemo(() => {
    const tabList = languageList.filter(item => {
      if (allowedLangs.includes(item.id)) {
        return item;
      }
    });
    return tabList.sort((a, b) => {
      if (a.id === defaultLang) {
        return -1;
      }
      if (b.id === defaultLang) {
        return 1;
      }
    });
  }, [allowedLangs]);
  return (
    <>
      <Heading heading={"Interview Steps"} />
      <TabsList
        tabList={tabList}
        labelId="label"
        isSelected={activeTab}
        onClick={id => setActiveTab(id)}
      />
      {activeTab ? (
        <>
          {tabList.map((item, i) => (
            <div key={i} className={activeTab !== item.id ? "hidden" : ""}>
              <InterviewStepsLang
                key={item.id}
                lang={item.id}
                method={method}
              />
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};
export default InterviewSteps;
