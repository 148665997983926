import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { crmFeaturesList } from "../../constants/ModeratorAccessConstants";
import Heading from "../Common/Form/Heading";
import Chips from "../Common/Chips";
import { capitalizeFirstLetter, getErrorMessage } from "../../services/utils";
import JobHai from "../../api/JobHai";

const AccessPopup = ({ data, activeAccess, handleClose, callBack }) => {
  const { id, name } = data;
  const [selectedItems, setSelectedItems] = useState(activeAccess);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChips = (event, fieldName, item) => {
    const { id } = item;
    const isSelected = selectedItems.some(
      selectedItem => selectedItem.id === id
    );

    if (isSelected) {
      setSelectedItems(
        selectedItems.filter(selectedItem => selectedItem.id !== id)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleSave = () => {
    if (!selectedItems.length) {
      setError("Please select atleast one feature");
      return;
    }
    const updatedAccessJson = selectedItems.map(({ id, access_level }) => ({
      label: id,
      access_level
    }));
    JobHai.post("update-moderator-access-list", {
      moderator_id: id,
      access_json: updatedAccessJson
    })
      .then(response => {
        setSuccess("Data Saved");
        if (callBack) callBack();
        handleClose();
        setTimeout(() => setSuccess(null), 3000); // Clear success message after 3 seconds
      })
      .catch(error => {
        setError(getErrorMessage(error));
      });
  };

  return (
    <Modal
      show={true}
      size="lg"
      contentClassName="access-modal"
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Update <i>{capitalizeFirstLetter(name)}</i> Access
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="gap-20" />
        {crmFeaturesList.map(category => (
          <React.Fragment key={category.id}>
            <Heading heading={category.label} />
            <Chips
              chips={category.options}
              activeChips={selectedItems}
              onClick={handleChips}
              fieldName="moderator_access"
            />
            <div className="seperator" />
          </React.Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer>
        {error && <span className="text-danger">Error: {error}</span>}
        {success && <span className="text-success">{success}</span>}
        <button className="btn btn-primary" onClick={handleSave}>
          Save
        </button>
        <button className="btn btn-secondary" onClick={handleClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccessPopup;
