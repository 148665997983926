import React from "react";

class ClientChips extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  handleOnChange = clientId => {
    this.props.onClick(clientId);
  };

  render() {
    const { list = [] } = this.props;
    return (
      <>
        {list.map(item => (
          <div key={item.client_id} className="selected-client">
            <div className={"clinet-name"}>{item.client_name} </div>
            <button
              type="button"
              className="close closeIcon"
              aria-label="Close"
              onClick={() => this.handleOnChange(item.client_id)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ))}
      </>
    );
  }
}

export default ClientChips;
