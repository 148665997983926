import React from "react";

const Label = props => {
  const {
    lable = "",
    isOptional = false,
    isEdited = false,
    recStatus = false,
    optionalTitle = "optional"
  } = props;
  return (
    <label className="label">
      {lable}
      {isOptional ? <span className="optional">({optionalTitle})</span> : null}
      {isEdited ? <span className="editedField">Edited</span> : null}
      {recStatus ? recStatus : null}
    </label>
  );
};

export default Label;
