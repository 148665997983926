function DynamicTable(props) {
  const data = props.data || [];
  // Generate table headers dynamically from keys
  const tableHeaders = Object.keys(data[0]).map(key => (
    <th key={key}>{key}</th>
  ));

  // Generate table rows with corresponding values
  const tableRows = data.map((rowData, index) => (
    <tr className="listvalue" key={index}>
      {Object.values(rowData).map((value, innerIndex) => (
        <td key={innerIndex}>{value}</td>
      ))}
    </tr>
  ));

  return (
    <div className="react-bootstrap-table  admin-pannel">
      <table className="table table-hover table-condensed borderless reportedTable">
        <thead>
          <tr className="listHeding">{tableHeaders}</tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  );
}

export default DynamicTable;
