import useLeadAssignmentStore from "../../zustandService/useLeadAssignmentStore";
import { Button } from "../ui/button";

export default function SubmitButton(props) {
  const { onClick } = props;
  const {
    assignmentModeratorHelperText,
    moderatorConfigHelperText,
    salesCohortHelperText
  } = useLeadAssignmentStore(state => ({
    assignmentModeratorHelperText: state.assignmentModeratorHelperText,
    moderatorConfigHelperText: state.moderatorConfigHelperText,
    salesCohortHelperText: state.salesCohortHelperText
  }));
  const message =
    assignmentModeratorHelperText.text ||
    moderatorConfigHelperText.text ||
    salesCohortHelperText.text;
  const messageType =
    assignmentModeratorHelperText.type ||
    moderatorConfigHelperText.type ||
    salesCohortHelperText.type;
  return (
    <div className="ml-auto flex relative -top-8 justify-end items-center">
      {message ? (
        <span
          className={`${
            messageType === "ERROR" ? "text-red-500" : "text-emerald-500"
          } mx-1`}
        >
          {message}
        </span>
      ) : null}
      <Button onClick={onClick}>Submit</Button>
    </div>
  );
}
