const parseLine = line => {
  const result = [];
  let inQuotes = false;
  let value = "";

  for (let char of line) {
    if (char === '"' && !inQuotes) {
      // Start of quoted field
      inQuotes = true;
    } else if (char === '"' && inQuotes) {
      // End of quoted field
      inQuotes = false;
    } else if (char === "," && !inQuotes) {
      // Field delimiter found outside quotes
      result.push(value.trim());
      value = "";
    } else {
      // Regular character, part of the field
      value += char;
    }
  }
  // Push the final value
  result.push(value.trim());

  return result;
};

export const parseCSV = text => {
  const requiredHeaders = [
    { name: "user_id", type: "number" },
    { name: "shared_with_campaign_id", type: "number" },
    { name: "shared_with_kam", type: "number" },
    { name: "campaign_id", type: "number" },
    { name: "lead_user_id", type: "number" },
    { name: "upload_campaign_id", type: "number" },
    { name: "latest_share_timestamp", type: "timestamp" }
  ];

  const isTimestamp = value => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // yyyy-mm-dd
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/; // yyyy-mm-dd hh:mm:ss
    return dateRegex.test(value) || dateTimeRegex.test(value);
  };

  const isNumber = value => !isNaN(Number(value));

  try {
    // Split lines and remove empty ones
    const lines = text.split("\n").filter(line => line.trim() !== "");

    // Detect the delimiter (tab or comma)
    const delimiter = lines[0].includes("\t") ? "\t" : ",";

    // Extract and normalize headers
    let headers = lines[0].split(delimiter).map(h =>
      h
        .trim()
        .replace(/(^"|"$)/g, "")
        .toLowerCase()
    ); // Normalize by trimming, removing quotes, and converting to lowercase

    for (const { name } of requiredHeaders) {
      if (!headers.includes(name.toLowerCase())) {
        throw new Error(`Missing required header: ${name}`);
      }
    }

    const data = lines.slice(1).map((line, lineIndex) => {
      const values = line.split(delimiter).map(value => value.trim());
      const entry = headers.reduce((acc, header, index) => {
        let value = values[index] ? values[index].replace(/(^"|"$)/g, "") : "";

        // Check if the value matches the expected type and is not empty/null
        const headerRule = requiredHeaders.find(h => h.name === header);
        if (headerRule) {
          if (value === "" || value === null) {
            throw new Error(
              `Empty or null value at line ${
                lineIndex + 2
              } for required header "${header}"`
            );
          }
          if (headerRule.type === "number" && !isNumber(value)) {
            throw new Error(
              `Invalid number at line ${
                lineIndex + 2
              } for header "${header}": ${value}`
            );
          }
          if (headerRule.type === "timestamp" && !isTimestamp(value)) {
            throw new Error(
              `Invalid timestamp at line ${
                lineIndex + 2
              } for header "${header}": ${value}`
            );
          }
        }

        acc[header] = value;
        return acc;
      }, {});
      return entry;
    });

    return cleanedData(data);
  } catch (error) {
    console.error("Error parsing CSV:", error.message);
    return { error: error.message };
  }
};

const cleanedData = rawData => {
  return rawData.map(item => {
    const cleanedItem = {};

    for (let key in item) {
      // Clean up keys and values by removing leading/trailing quotes
      const cleanedKey = key.replace(/(^\"|\"$)/g, "");
      let cleanedValue = item[key].replace(/(^\"|\"$)/g, "");

      // Convert certain values to their appropriate types
      if (cleanedValue === "true") cleanedValue = true;
      else if (cleanedValue === "false") cleanedValue = false;
      else if (cleanedValue === "") cleanedValue = null;
      else if (!isNaN(cleanedValue)) cleanedValue = Number(cleanedValue);

      cleanedItem[cleanedKey] = cleanedValue;
    }

    return cleanedItem;
  });
};
