import React from "react";
import DatePicker from "react-datepicker";
import EditedIcons from "../Jobs//EditedIcons";
import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";

const JobLiveDate = props => {
  const handleDateChange = (fieldName, value) => {
    props.getFieldData(fieldName, value);
  };

  const { editedFields = [], hiringStartDate, hiringEndDate } = props;

  return (
    <>
      <Heading heading="Job Live Date" />
      <div className="formRow">
        <div className="fullWidth">
          <div className="dateItem">
            <Label lable="Start Date" />
            <EditedIcons
              editedFields={editedFields}
              fieldName={"hiring_start_date"}
            />
            <DatePicker
              selected={Date.parse(hiringStartDate)}
              onChange={value => handleDateChange("hiring_start_date", value)}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <div className="dateItem dateTo">To</div>
          <div className="dateItem">
            <Label lable="End Date" />
            <EditedIcons
              editedFields={editedFields}
              fieldName={"hiring_end_date"}
            />
            <DatePicker
              selected={Date.parse(hiringEndDate)}
              onChange={value => handleDateChange("hiring_end_date", value)}
              dateFormat="yyyy-MM-dd"
              minDate={new Date()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobLiveDate;
