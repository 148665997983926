import React, { PureComponent } from "react";
import Countdown, { zeroPad } from "react-countdown";
import moment from "moment";

class CountdownContainer extends PureComponent {
  state = {
    isRed: false
  };
  renderer = ({ days, hours, minutes, seconds }) => (
    <span>
      {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}{" "}
    </span>
  );

  onCompleteTimer = () => {
    this.setState({ isRed: true });
  };

  getDeadline = deadline => {
    let crm_deadline = new Date();
    let hour = crm_deadline.getHours();
    let minute = crm_deadline.getMinutes();
    let day = crm_deadline.getDay();

    let isWorkingDay = false;

    if (day !== 0) {
      isWorkingDay = true;
    }

    if (hour * 60 + minute >= 930 && isWorkingDay) {
      let time = moment.duration("15:30:00");
      return moment(deadline).subtract(time);
      //  return deadline;
    } else {
      return deadline;
    }
  };

  render() {
    const { date, deadline, deadTimeStamp, isFeaze } = this.props;
    const { isRed } = this.state;
    let isNegative = deadline ? deadline.substr(0, 1) : "";
    let isNew = false;
    if (deadline) {
      let time = deadline.split(":");
      isNew = time[0] === "00" ? true : false;
    }
    let curDate = new Date();
    let hour = curDate.getHours();
    let minutes = curDate.getMinutes();
    let timerStart = hour * 60 + minutes; // Timer will start after 9:30 am 570 min
    let timerEnd = hour * 60 + minutes; // Timer will stop after 6:30 pm  1110 min
    let isTimerStop = timerStart > 570 && timerEnd < 1111 ? false : true;
    return deadline ? (
      <>
        {date}
        <span
          className={`timer ${
            isFeaze ? "blue" : isRed || isNegative === "-" ? "red" : "green"
          }`}
        >
          {isFeaze || isTimerStop ? (
            deadline
          ) : (
            <>
              {isRed || isNegative === "-" ? "-" : ""}
              <Countdown
                date={
                  isNegative === "-" || isNew
                    ? deadTimeStamp
                    : this.getDeadline(deadTimeStamp)
                }
                onComplete={this.onCompleteTimer}
                renderer={this.renderer}
                overtime={true}
              />
            </>
          )}
        </span>
      </>
    ) : (
      date
    );
  }
}

export default CountdownContainer;
