import React from "react";
import MetaData from "../../../constants/MetaData";

const RecBucket = props => {
  const { pathname, sectionData } = props;
  return (
    <div className="row rec-bucket rec-bucket-center">
      {MetaData.UnverifiedBucketList.map(item => (
        <div className="box" key={`bucket-${item.id}`}>
          <a
            href={`${pathname}?bucket=${item.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.label} (
            {sectionData[item.value] ? sectionData[item.value] : "0"})
          </a>
        </div>
      ))}
    </div>
  );
};

export default RecBucket;
