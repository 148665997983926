import React from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationFactory from "react-bootstrap-table2-paginator";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import { getErrorMessage } from "../../services/utils";
import withRouter from "../../services/withRouter";

class ReportedJobSeeker extends React.Component {
  constructor(props) {
    super(props);
    const { params = {} } = this.props;
    this.state = {
      job_applied: [],
      job_applied_temp: [],
      isLoading: true,
      uid: params.uid || "",
      msgData: { msg: "", showMsg: false, msgType: "error" }
    };
  }

  ubBlockUser = e => {
    e.preventDefault();
    const obj = {
      user_id: this.state.uid,
      status: "verified"
    };
    if (window.confirm("Are you sure you want to unblock this user?")) {
      JobHai.post("/job_seeker/update/", obj)
        .then(response => {
          this.setState({
            msgData: {
              msg: "User has been unblocked",
              showMsg: true,
              msgType: "success"
            }
          });
          window.scrollTo(0, 0);
        })
        .catch(error => {
          let msg = getErrorMessage(error);
          this.setState({
            msgData: {
              msg: msg,
              showMsg: true,
              msgType: "error"
            }
          });
          window.scrollTo(0, 0);
        });
    }
  };

  blockUser = e => {
    e.preventDefault();
    const obj = {
      user_id: this.state.uid,
      status: "blocked"
    };
    if (window.confirm("Are you sure you want to block this user?")) {
      JobHai.post("/job_seeker/update/", obj)
        .then(response => {
          this.setState({
            msgData: {
              msg: "User has been blocked",
              showMsg: true,
              msgType: "success"
            }
          });
          window.scrollTo(0, 0);
        })
        .catch(error => {
          let msg = getErrorMessage(error);
          this.setState({
            msgData: {
              msg: msg,
              showMsg: true,
              msgType: "error"
            }
          });
          window.scrollTo(0, 0);
        });
    }
  };

  async componentDidMount() {
    let url = "/job_seeker/reported?uid=" + this.state.uid;
    let response = await JobHai.get(url);
    let data = response.data.data;
    //   console.log(data)
    this.setState({
      job_applied: data,
      job_applied_temp: data,
      isLoading: false
    });
  }

  render() {
    const jobApplied = this.state.job_applied;
    jobApplied.map(d => {
      let reason = Number(d.report_reason_text);
      if (reason === 1) reason = "Fake Candidate";
      if (reason === 2) reason = "Incorrect Profile Details";
      if (reason === 3) reason = "Others";
      d.report_reason_text = reason;
      return d;
    });
    jobApplied.map((d, index) => {
      d.serialNo = index + 1;
      return d;
    });
    const columns = [
      {
        text: "#",
        dataField: "serialNo",
        sort: true
      },
      {
        text: "COMPANY NAME",
        dataField: "company_name",
        sort: true
      },
      {
        text: "RECRUITER NAME",
        dataField: "full_name",
        sort: true
      },
      {
        text: "RECRUITER NUMBER",
        dataField: "phone",
        sort: true
      },

      {
        text: "REPORTED ON",
        dataField: "updatedAt"
      },
      {
        text: "REASON",
        dataField: "report_reason_text"
      }
    ];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );

    const pagination_options = {
      paginationSize: 4,
      pageStartIndex: 0,
      showTotal: true,
      paginationTotalRenderer: customTotal,
      hideSizePerPage: true,
      sizePerPageList: [
        {
          text: "30",
          value: 30
        },
        {
          text: "50",
          value: 50
        },
        {
          text: "All",
          value: jobApplied.length
        }
      ] // A numeric array is also available. the purpose of above example is custom the text
    };
    const defaultSorted = [
      {
        dataField: "name",
        order: "asc"
      }
    ];

    if (this.state.isLoading) {
      return <LoadingSpinner key="loading" />;
    } else {
      return (
        <div className="job_seeker">
          <div className="clr gap-20"></div>
          <div className="clr gap-20"></div>
          <PopupMessage
            data={this.state.msgData}
            key="popup"
            click={this.handleClosePopup}
          />
          <div className="pageHeading row">
            <h2>
              Reported By Recruiter
              <div className="float-right">
                <Link to={"/job-seeker/"}>Back</Link>
              </div>
            </h2>
          </div>
          <div className="clr"></div>
          <div className="clr gap-20"></div>

          <div className="whitebox">
            <BootstrapTable
              key="seeker"
              keyField="serialNo"
              data={jobApplied}
              columns={columns}
              hover
              condensed
              bordered={false}
              loading={true}
              rowClasses="listvalue"
              headerClasses="listHeding"
              classes="borderless"
              pagination={PaginationFactory(pagination_options)}
              defaultSorted={defaultSorted}
            />
            <div className="clr"></div>
            <div className="clr gap-20"></div>

            <div className="row">
              <div className="col-md-12 ">
                <div className="bttn float-right">
                  <Link onClick={this.blockUser} to="#">
                    Block
                  </Link>
                </div>
                <div className="bttn-inactive float-right">
                  <Link onClick={this.ubBlockUser} to="#">
                    Unblock
                  </Link>
                </div>
                <div className="bttn-inactive float-right">
                  <Link to={"/job-seeker/"}>Back</Link>
                </div>
              </div>
            </div>
            <div className="clr"></div>
            <div className="clr gap-20"></div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(ReportedJobSeeker);
