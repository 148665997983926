import { useState } from "react";
import UploadFiles from "../Common/Form/UploadFiles";
import { uploadSharedLeads } from "../../services/enterpriseLeads";
import LoadingSpinner from "../LoadingSpinner";
import { parseCSV } from "../../services/csvParserUtils";

const UploadSharedLeads = ({ isError = "" }) => {
  const [error, setError] = useState(null || isError);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = event => {
    const { type = "", files = [] } = event.target;
    setError(null);

    if (type === "file" && files.length) {
      const file = files[0];
      const fileSize = file.size;
      const fileName = file.name;
      const fileTypeRegex = /(.*?)\.(csv)$/;

      if (!fileName.match(fileTypeRegex)) {
        setError("Please upload a CSV file.");
      } else if (fileSize > 2097152) {
        setError("Please upload a file size below 2MB.");
      } else {
        const reader = new FileReader();

        reader.onload = () => {
          const csvText = reader.result;
          const csvData = parseCSV(csvText);
          if (csvData?.error) {
            setError(csvData?.error);
          } else {
            updateLeadStatus(csvData);
          }
        };

        reader.onerror = () => {
          setError("Failed to read file. Please try again.");
        };

        reader.readAsText(file);
      }
    }
  };

  const updateLeadStatus = async data => {
    setLoading(true);
    try {
      await uploadSharedLeads(data);
      setSuccess("Data has been updated");
      setLoading(false);
      setTimeout(() => {
        setSuccess(null);
      }, 2000);
    } catch (error) {
      setLoading(false);
      setError(error);
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  };

  return (
    <div className="uploadCSV">
      {error && <div className="text-danger">{error}</div>}
      {success && <div className="text-success">{success}</div>}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <UploadFiles
          handleFileUpload={handleFileUpload}
          fieldLabel="Upload Leads Shared CSV"
        />
      )}
    </div>
  );
};
export default UploadSharedLeads;
