import React from "react";
import DocumentTitle from "react-document-title";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import LoadingSpinner from "../../LoadingSpinner";
import OperationTrackingFilter from "./OperationTrackingFilter";

class OperationTrackingReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msgData: { msg: "", showMsg: false, msgType: "error" },
      isLoading: false,
      isButtonDisabled: false,
      reportLink: ""
    };
  }

  getReport = filters => {
    const { start_date, end_date, report_type } = filters;
    const url = report_type;
    this.setState({ isButtonDisabled: true });

    JobHai.post(url, {
      startDate: start_date ? start_date : undefined,
      endDate: end_date ? end_date : undefined
    })
      .then(response => {
        const reportLink = response.data.data;
        console.log(response.data);

        this.setState({
          reportLink,
          isLoading: false,
          isButtonDisabled: false,
          msgData: { msg: "", showMsg: false, msgType: "" }
        });
      })
      .catch(
        function (error) {
          let msg = error.response ? error.response.data.error : error;
          this.setState({
            isLoading: false,
            isButtonDisabled: false,
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
        }.bind(this)
      );
  };

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      }
    });
  };

  render() {
    const { msgData, isLoading, reportLink, isButtonDisabled } = this.state;
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <DocumentTitle title={"Job Hai CRM - Analytic"}>
          <div className="dashboard">
            <div className="clr gap-20" />
            <PopupMessage data={msgData} click={this.handleClosePopup} />
            <div className="pageHeading row">
              <h2>
                Operation Tracking Report
                <div className="float-right">
                  <a href={"/analytics"}>Go Back</a>
                </div>
              </h2>
            </div>

            <div className="clr gap-20"></div>

            <div className="clr"></div>
            <OperationTrackingFilter
              getReport={this.getReport}
              statusMessage={this.statusMessage}
              isButtonDisabled={isButtonDisabled}
              reportLink={reportLink}
            />
          </div>
        </DocumentTitle>
      );
    }
  }
}

export default OperationTrackingReport;
