import React from "react";
import Heading from "../Common/Form/Heading";
import JobCallAttempt from "./JobCallAttempt";

const JobCalling = props => {
  const { callStatusValue, recName, recPhone, handleCallStatus, isNewCity } =
    props;
  return (
    <>
      <Heading heading="Job Calling" isNewCity={isNewCity} />
      <div className="recInfo">{`${recName} @ ${recPhone}`}</div>
      <JobCallAttempt
        callStatusValue={callStatusValue}
        fieldName="calling_status"
        handleCallStatus={handleCallStatus}
      />
    </>
  );
};
export default JobCalling;
