import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawerPaper: {
    ...theme.typography.defaultFont,
    height: "100%",
    borderRadius: "20px 0px 0px 20px",
    padding: "30px",
    width: "30%"
  },
  close: { margin: "-15px", display: "flex", justifyContent: "flex-end" }
});

class CustomDrawer extends React.Component {
  render() {
    const {
      classes,
      isOpen,
      toggleDrawer,
      isCloseButton,
      children,
      anchor = "right",
      customClass = ""
    } = this.props;
    return (
      <div className={classes.root}>
        <Drawer
          variant="temporary"
          anchor={anchor}
          open={isOpen}
          onClose={toggleDrawer}
          classes={{
            paper: customClass
              ? cx(classes.drawerPaper, customClass)
              : classes.drawerPaper
          }}
        >
          {isCloseButton && (
            <div className={classes.close}>
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={toggleDrawer}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          {children}
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(CustomDrawer);
