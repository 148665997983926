import { SparkleIcon } from "lucide-react";
import PageHeader from "../pageHeader";
import { Button } from "../../ui/button";
import { Link } from "react-router-dom";
import { LFMS_ADD_LEAD } from "../../../routes/RouteConstants";

const LeadHeader = () => {
  return (
    <div className="sticky top-0 z-50 bg-white ">
      <PageHeader
        icons={<SparkleIcon />}
        heading="Leads"
        widget={
          <div className="flex items-center space-x-3">
            <Link to={LFMS_ADD_LEAD}>
              <Button size="sm" className="">
                Add Lead
              </Button>
            </Link>
          </div>
        }
      />
    </div>
  );
};
export default LeadHeader;
