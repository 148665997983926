import SelectDropdown from "../../ReactSelect";
import classes from "./lcsCampaignDetails.module.css";
const CampaignFilters = props => {
  const {
    name,
    label,
    control,
    dataList,
    preSelectedDataList,
    closeMenuOnSelect = false,
    rules,
    itemId = "id",
    itemLabel = "label",
    allNaOption = [],
    allNaId = null,
    keepAllNaDefaultValue = false
  } = props;

  const handleAllSelection = ({ field }) => {
    const isAllSelected = field.some(item => item.value == allNaId);
    if (isAllSelected) return allNaOption;
    return field;
  };
  let options = allNaOption;
  if (dataList.length) {
    options = [
      ...options,
      ...dataList.map(item => ({
        value: item[itemId],
        label: item[itemLabel]
      }))
    ];
  }
  const defaultDataList = options.filter(
    item => preSelectedDataList?.indexOf(item.value) !== -1
  );
  return (
    <div className={classes.questionTitleCnt}>
      <SelectDropdown
        name={name}
        label={label}
        control={control}
        options={options}
        defaultValue={
          defaultDataList?.length === 0 && keepAllNaDefaultValue
            ? allNaOption
            : defaultDataList
        }
        closeMenuOnSelect={closeMenuOnSelect}
        rules={rules}
        isMulti
        isSearchable
        idKey={"value"}
        labelKey={"label"}
        callBack={field => {
          return handleAllSelection({
            field
          });
        }}
      />
    </div>
  );
};
export default CampaignFilters;
