import React from "react";
import { useFieldArray } from "react-hook-form";
import classes from "./lcsCampaignDetails.module.css";
import cx from "classnames";
import MaterialInputTextField from "../../materialInputTextField";
import MaterialDropdown from "../../materialDropDown";

const QualificationFormV2 = ({
  control,
  languageList,
  name,
  watch,
  trigger,
  setError
}) => {
  const {
    fields: qualificationFormFields,
    append,
    update
  } = useFieldArray({
    name,
    control
  });
  const qualificationFormWatcher = watch(name);
  const onAddMoreBanner = () => {
    append({
      language: "",
      banner_title: "",
      header: "Company Benefits",
      content: [""]
    }); // Add a new empty banner item
  };

  const removeClientBenefits = (targetObjIndex, clientBenefitIndex) => {
    if (targetObjIndex !== -1) {
      const targetObj = JSON.parse(
        JSON.stringify(qualificationFormWatcher[targetObjIndex])
      );
      targetObj.content.splice(clientBenefitIndex, 1);
      update(targetObjIndex, targetObj);
    }
  };

  const addClientBenefits = targetObjIndex => {
    if (targetObjIndex !== -1) {
      const targetObj = JSON.parse(
        JSON.stringify(qualificationFormWatcher[targetObjIndex])
      );
      targetObj.content.push("");
      update(targetObjIndex, targetObj);
    }
  };

  const validateClientBenefitsHeading = (value, targetObjIndex) => {
    if (targetObjIndex !== -1) {
      const targetObj = JSON.parse(
        JSON.stringify(qualificationFormWatcher[targetObjIndex])
      );
      if (!targetObj.header) {
        if (targetObj.content.findIndex(item => item !== "") !== -1) {
          return `Client Benefits Heading is required as Client Benefits Content is present`;
        }
      }
    }
    return true;
  };

  const validateClientBenefitsContent = (value, targetObjIndex) => {
    trigger(`${name}.${targetObjIndex}.header`);
    if (targetObjIndex !== -1) {
      const targetObj = JSON.parse(
        JSON.stringify(qualificationFormWatcher[targetObjIndex])
      );
      if (targetObj.header) {
        if (targetObj.content.findIndex(item => item !== "") === -1) {
          return `Client Benefits Content is required as Client Benefits Heading is present`;
        }
      }
    }
    return true;
  };
  const showAddMore = qualificationFormFields.length < languageList.length;
  return (
    <div className={classes.fullWidth}>
      <div className={cx(classes.questioncnt, classes.qualFormCnt)}>
        {qualificationFormFields.map((item, index) => (
          <div key={item.id}>
            <div className={classes.titleCnt}>
              <MaterialDropdown
                name={`${name}.${index}.language`}
                label="Language"
                control={control}
                rules={{ required: "Language is required" }}
                options={languageList}
                fullWidth={false}
                formControlClasses={{
                  root: classes.campaignTypeFormOption
                }}
                defaultValue={item.language}
              />
              <MaterialInputTextField
                name={`${name}.${index}.bannerTitle`}
                label="Form Title"
                control={control}
                formControlClasses={{ root: classes.formControlDate }}
                rules={{
                  required: "Form Title is required",
                  maxLength: { value: 74, message: "Max 74 character allowed" }
                }}
                fullWidth={false}
                placeholder="Enter main heading of the form"
              />
            </div>
            <h6 className="mb-3 font-semibold">
              Enter Client Benefit Details -
            </h6>
            <MaterialInputTextField
              name={`${name}.${index}.header`}
              label="Client Benefits Heading"
              control={control}
              formControlClasses={{ root: classes.formControlDate }}
              rules={{
                maxLength: { value: 24, message: "Max 24 character allowed" }
              }}
              fullWidth={false}
              placeholder="Ex. Company Benefits"
              validateArray={value =>
                validateClientBenefitsHeading(value, index)
              }
            />
            <div className={classes.contentCnt}>
              {item.content.map((contentItem, contentIndex) => (
                <div key={contentIndex} className={classes.contentFieldCnt}>
                  <MaterialInputTextField
                    name={`${name}.${index}.content.${contentIndex}`}
                    label={`Point ${contentIndex + 1}`}
                    control={control}
                    formControlClasses={{ root: classes.formControlDate }}
                    fullWidth={false}
                    rules={{
                      maxLength: {
                        value: 54,
                        message: "Max 54 character allowed"
                      }
                    }}
                    validateArray={value =>
                      validateClientBenefitsContent(value, index)
                    }
                    placeholder="Ex. Enjoy PF benefits"
                  />
                  {item.content.length > 1 ? (
                    <i
                      onClick={() => removeClientBenefits(index, contentIndex)}
                      className="fa-solid fa-x"
                    />
                  ) : null}
                </div>
              ))}
              {item.content.length < 3 ? (
                <div
                  onClick={() => addClientBenefits(index)}
                  className={classes.addBenefits}
                >
                  Add More Client Benefits
                </div>
              ) : null}
            </div>
          </div>
        ))}
        {showAddMore ? (
          <div className={classes.addMoreLangCnt}>
            <div
              type="button"
              className={classes.addMore}
              onClick={onAddMoreBanner}
            >
              Add More Languages
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default QualificationFormV2;
