import React from "react";
import { Link } from "react-router-dom";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import ViewCompanyDetails from "./ViewCompanyDetails";
import { getErrorMessage, checkPermission } from "../../services/utils";

class SearchDuplicateCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      company_contact_info: "",
      job_id: "",
      showCompanyInfo: false,
      msgData: { msg: "", showMsg: false, msgType: "error" },
      companyData: [],
      activeTab: "isProfile",
      isAllowEdit: true
    };
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  componentDidMount() {
    const isAllowEdit = !checkPermission("Fix Duplicate Profile", true);
    this.setState({ isAllowEdit });
  }

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      }
    });
    window.scrollTo(0, 0);
  };

  searchCompany = e => {
    e.preventDefault();
    const { phone, company_contact_info, activeTab, recruiterEmail } =
      this.state;

    if (
      !Number(phone) &&
      !Number(company_contact_info) &&
      activeTab === "isProfile"
    ) {
      this.statusMessage("Please provide valid number.", true);
      return;
    }
    let obj = {};
    if (activeTab === "isProfile") {
      obj = {
        phone,
        company_contact_info
      };
    } else {
      obj = {
        email: recruiterEmail
      };
    }

    JobHai.post("/v2/find-recruiter", obj)
      .then(
        function (response) {
          let data = response.data.data;
          if (data.length > 0) {
            this.setState({
              companyData: activeTab === "isProfile" ? [data[0]] : data,
              showCompanyInfo: true,
              msgData: { msg: "", showMsg: false, msgType: "success" }
            });
          } else {
            this.statusMessage("No Company/Recruiter found.", true);
          }
          window.scrollTo(0, 0);
        }.bind(this)
      )
      .catch(
        function (error) {
          let msg = getErrorMessage(error);
          this.setState({
            showCompanyInfo: false
          });
          this.statusMessage(msg, true);
          window.scrollTo(0, 0);
        }.bind(this)
      );
  };

  updateCompanyID = e => {
    e.preventDefault();
    const { job_id, companyData, phone, company_contact_info } = this.state;
    if (!Number(job_id)) {
      this.statusMessage("Please provide valid job id.", true);
      return;
    }
    const recruiterId =
      companyData.length && companyData[0].recruiter_id
        ? companyData[0].recruiter_id
        : "";
    const obj = {
      recruiter_id: recruiterId,
      job_id,
      phone,
      company_contact_info
    };
    JobHai.post("/v2/change-recruiter", obj)
      .then(
        function (response) {
          // handle success
          this.statusMessage(
            "Job id has been assign to the selected recruiter",
            false
          );
        }.bind(this)
      )
      .catch(
        function (error) {
          let msg = getErrorMessage(error);
          this.statusMessage(msg, true);
        }.bind(this)
      );
  };

  onTabClicked = (tab = "isProfile") => {
    this.setState({ activeTab: tab });
    this.setState({ companyData: [], showCompanyInfo: false });
    if (tab === "isProfile") {
      this.setState({ recruiterEmail: "" });
    } else {
      this.setState({ company_contact_info: "", phone: "" });
    }
  };

  renderProfileSearch = () => {
    const { phone, company_contact_info } = this.state;
    return (
      <>
        <h2>Search Company/Recruiter</h2>
        <div className="gap-10"></div>
        <form noValidate autoComplete="off" className="search">
          <div className="form-row">
            <div className="col-md-3">
              <label>Recruiter Number</label>
              <input
                type="text"
                className="form-control"
                name="phone"
                value={phone}
                onChange={event =>
                  this.setState({
                    phone: event.target.value.replace(/\D/, "")
                  })
                }
                maxLength="10"
              />
            </div>
            <div className="col-md-1 text-center">
              <label>&nbsp;</label>
              <div>OR</div>
            </div>
            <div className="col-md-3">
              <label>Company Contact Number</label>
              <input
                type="text"
                className="form-control"
                name="company_contact_info"
                value={company_contact_info}
                onChange={this.handleChange}
              />
            </div>
            <div className=" col-md-2">
              <label>&nbsp;</label>
              <div>
                <button
                  onClick={this.searchCompany}
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  renderEmailSearch = () => {
    const { recruiterEmail } = this.state;
    return (
      <>
        <h2>Search Recruiter</h2>
        <div className="gap-10"></div>
        <form noValidate autoComplete="off" className="search">
          <div className="form-row">
            <div className="col-md-3">
              <label>Recruiter Email</label>
              <input
                type="text"
                className="form-control"
                name="recruiterEmail"
                value={recruiterEmail}
                onChange={this.handleChange}
                maxLength="50"
              />
            </div>
            <div className=" col-md-2">
              <label>&nbsp;</label>
              <div>
                <button
                  onClick={this.searchCompany}
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  renderJobIdUpdate = () => {
    const { companyData = [], job_id, isAllowEdit } = this.state;
    return (
      <div className="whiteCard">
        <div className="container-fluid">
          <h2>
            Update Job Id with This Company{" "}
            <span>
              Company ID - {companyData.length ? companyData[0].company_id : ""}
            </span>
          </h2>
          <div className="gap-10"></div>
          <form noValidate autoComplete="off" className="search">
            <div className="form-row">
              <div className="col-md-2">
                <label>Job ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="job_id"
                  value={job_id}
                  onChange={event =>
                    this.setState({
                      job_id: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
              <div className="col-md-2">
                <label>&nbsp;</label>
                <div>
                  <button
                    onClick={this.updateCompanyID}
                    className="btn btn-primary"
                    disabled={isAllowEdit}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  render() {
    const { companyData, msgData, showCompanyInfo, activeTab, isAllowEdit } =
      this.state;
    return (
      <div className="edit-form">
        <div className="clr gap-20"></div>
        <PopupMessage data={msgData} click={this.handleClosePopup} />
        <div className="pageHeading row">
          <h2>
            Fix Duplicate Profile
            <div className="crm-tab">
              <ul className="nav">
                <li
                  className="nav-item"
                  onClick={() => this.onTabClicked("isProfile")}
                >
                  <span
                    className={
                      activeTab === "isProfile" ? "nav-link active" : "nav-link"
                    }
                  >
                    Fix Profile
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => this.onTabClicked("isEmail")}
                >
                  <span
                    className={
                      activeTab === "isEmail" ? "nav-link active" : "nav-link"
                    }
                  >
                    Fix Email Id
                  </span>
                </li>
              </ul>
            </div>
            <div className="float-right">
              <Link to={"/jobs/"}>Go Back</Link>
            </div>
          </h2>
        </div>
        <div className="whiteCard">
          <div className="container-fluid">
            {activeTab === "isProfile"
              ? this.renderProfileSearch()
              : this.renderEmailSearch()}
          </div>
        </div>
        <div className="gap-20"></div>
        {showCompanyInfo && (
          <div>
            {activeTab === "isProfile" ? this.renderJobIdUpdate() : null}
            <div className="gap-20"></div>
            {companyData.map(item => {
              return (
                <div key={`recList${item.recruiter_id}`}>
                  <ViewCompanyDetails
                    companyData={item}
                    isEmailVerify={activeTab === "isEmail"}
                    statusMessage={this.statusMessage}
                    isAllowEdit={isAllowEdit}
                  />
                  <div className="gap-10"></div>
                </div>
              );
            })}
          </div>
        )}
        <div className="gap-10"></div>
      </div>
    );
  }
}
export default SearchDuplicateCompany;
