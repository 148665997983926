import BootstrapTable from "react-bootstrap-table-next";
import CustomePagination from "../Common/Pagination";

import moment from "moment";
import { getEditPageURL, getErrorMessage } from "../../services/utils";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../../componentsV2/ui/dialog";
import { Button } from "../../componentsV2/ui/button";
import { Label } from "../../componentsV2/ui/label";
import ReactDatePicker from "react-datepicker";
import JobHai from "../../api/JobHai";
import { forwardRef } from "react";

const updateTransaction = async body => {
  const response = await JobHai.post(`/transactions/transaction-update`, {
    ...body
  });

  let data = response.data.data;

  return data;
};

const columns = onSubmit => [
  {
    text: "Transaction Type",
    dataField: "transactionLabel",
    formatter: (cellContent, row) => {
      const jobId = row?.jobId;
      const documentUrl = row?.documentUrl;
      const recrutierId = row?.recrutierId;
      const remark = row?.remark;
      const editURL = getEditPageURL("VERIFIED", jobId, recrutierId);
      return jobId ? (
        row?.transactionLabel
      ) : (
        <>
          {row?.transactionLabel}{" "}
          {documentUrl ? (
            <a
              href={documentUrl}
              target="_blank"
              rel="noreferrer"
              title={remark}
            >
              <i className="fa fa-eye" />
            </a>
          ) : null}
        </>
      );
    }
  },
  {
    text: "Credits",
    dataField: "credits",
    sort: true
  },
  {
    text: "Status",
    dataField: "status",
    sort: true
  },
  {
    text: "Created At",
    dataField: "transactionDate"
  },
  {
    text: "Last Updated",
    dataField: "lastUpdated",
    formatter: (cellContent, row) => {
      return moment(row.lastUpdated).format("DD-MM-YYYY h:mm");
    }
  },
  {
    text: "Closing Balance",
    dataField: "closingBalance"
  },
  {
    text: "Expiry On",
    dataField: "expirationDate",
    formatter: (cellContent, row) => {
      const isValidDate = moment(row.expirationDate).isValid();

      if (!isValidDate) return "";

      if (isValidDate && row.isExpiryModifiable) {
        return (
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="outline">
                {moment(row.expirationDate).format("DD-MM-YYYY")}
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[550px]">
              <DialogHeader>
                <DialogTitle className="text-2xl font-bold mb-6">
                  Extend Transaction Validity
                </DialogTitle>
              </DialogHeader>
              <div className="grid gap-6 py-4">
                <div className="flex items-center justify-between">
                  <Label
                    htmlFor="name"
                    className="text-sm font-medium text-gray-500"
                  >
                    Transaction Id
                  </Label>
                  <span className="text-lg font-semibold">
                    {row.transactionId}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <Label
                    htmlFor="username"
                    className="text-sm font-medium text-gray-500"
                  >
                    Order Id
                  </Label>
                  <span className="text-lg font-semibold">
                    {row.orderId || "NA"}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <Label
                    htmlFor="username"
                    className="text-sm font-medium text-gray-500"
                  >
                    Available Credits
                  </Label>
                  <span className="text-lg font-semibold">
                    {row.netCredits??"NA"}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <Label
                    htmlFor="username"
                    className="text-sm font-medium text-gray-500"
                  >
                    Transaction Type
                  </Label>
                  <span className="text-lg font-semibold">
                    {row.transactionLabel}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <Label
                    htmlFor="username"
                    className="text-sm font-medium text-gray-500"
                  >
                    Current Expiry
                  </Label>
                  <span className="text-lg font-semibold">
                    {moment(row.expirationDate).format("DD MMMM YYYY")}
                  </span>
                </div>
                <div className="flex items-start justify-between">
                  <Label
                    htmlFor="username"
                    className="text-sm font-medium text-gray-500"
                  >
                    Extended Date
                  </Label>
                  <span>
                    <ExtendedDateComponent onSubmit={onSubmit} row={row} />
                  </span>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        );
      }
      return moment(row.expirationDate).format("DD-MM-YYYY");
    }
  },
  {
    text: "Basic Value",
    dataField: "amount",
    sort: true
  }
];

function TransactionHistoryTable({ data, fetchData }) {
  const onSubmit = async (date, body) => {
    try {
      await updateTransaction({
        transactionId: body.transactionId,
        recruiterId: body.recrutierId,
        newExpiryDate: moment(date).format("YYYY-MM-DD")
      });
      alert("Updated Transaction");
      fetchData({ pageNo: 1 });
    } catch (e) {
      alert(getErrorMessage(e));
    }
  };

  const tableData = data?.data;
  if (data?.state === "IDLE") return "";

  if (data?.state === "FAILED")
    return data?.errorMsg || "Error in loading transactions data";
  if (data?.state === "LOADING") return "Loading transactions data";
  return (
    <div className={"whitebox"}>
      <BootstrapTable
        keyField="moderator_id"
        data={tableData?.history || []}
        columns={columns(onSubmit)}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <div>No data</div>}
      />
      <CustomePagination
        activePage={tableData?.page}
        itemsCountPerPage={10}
        totalItemsCount={tableData?.totalCount}
        onPageChange={pageNo => fetchData({ page: pageNo })}
        showPagination={true}
        showLimitedRow={true}
      />
      <div className="clr gap-20" />
    </div>
  );
}

export default TransactionHistoryTable;
const ExampleCustomInput = forwardRef(({ value, onClick, className }, ref) => (
  <button className={className} onClick={onClick} ref={ref}>
    {value}
  </button>
));
const ExtendedDateComponent = ({ onSubmit, row }) => {
  const [modalState, setModalState] = useState(false);
  return (
    <>
      <ReactDatePicker
        className="form-control"
        minDate={new Date()}
        name="extendedDate"
        selected={modalState || ""}
        onChange={val => {
          setModalState(val);
        }}
        autoFocus={false}
        customInput={<ExampleCustomInput className="example-custom-input" />}
      />

      <DialogFooter>
        <Button className="mt-3" onClick={() => onSubmit(modalState, row)}>
          Save changes
        </Button>
      </DialogFooter>
    </>
  );
};
