import moment from "moment";
import { getFieldLabel } from "../../../services/utils";
import { crawledLeadType } from "../../../constants/MetaData";

const CrawlCompanyName = ({ item }) => {
  const {
    crawled_lead_type,
    company_name = "",
    total_crawl_job_count,
    first_crawl_date
  } = item;
  const leadStatus = getFieldLabel(
    crawledLeadType,
    crawled_lead_type,
    "value",
    "label"
  );
  return (
    <>
      {company_name}
      {first_crawl_date ? (
        <span className="date-time">
          <br />
          Crawled On: <b>{moment(first_crawl_date).format("YYYY-MM-DD")}</b>
        </span>
      ) : null}
      {/* {item?.last_crawl_date &&
      item?.crawled_lead_type !== "FIRST_TIME_LEAD" ? (
        <span className="date-time">
          <br />
          Last Crawled On:{" "}
          <b>{moment(item?.last_crawl_date).format("YYYY-MM-DD")}</b>
        </span>
      ) : null} */}
      {item?.job_post_date ? (
        <span className="date-time">
          <br />
          Job Post:
          <b> {moment(item?.job_post_date).format("YYYY-MM-DD")}</b>
        </span>
      ) : null}
      {item?.jml_date ? (
        <span className="date-time">
          <br />
          JML:
          <b> {moment(item?.jml_date).format("YYYY-MM-DD")}</b>
        </span>
      ) : null}
      {leadStatus ? (
        <span className="date-time">
          <br />
          Lead Type:
          <b> {leadStatus}</b>
        </span>
      ) : null}
      {total_crawl_job_count ? (
        <span className="date-time">
          <br />
          Total Crawled Job Count:
          <b>{total_crawl_job_count}</b>
        </span>
      ) : null}
    </>
  );
};
export default CrawlCompanyName;
