import React, { useMemo } from "react";

const tabList = [
  {
    label: "Non-Reviewed Companies",
    value: false,
    isSubTab: true,
    subTab: [
      { label: "Verify", value: "VERIFIED" },
      { label: "Suspicious", value: "SUSPECTED" }
    ]
  },
  {
    label: "Reviewed Companies",
    value: true,
    isSubTab: true,
    subTab: [
      { label: "Verify", value: "VERIFIED" },
      { label: "Suspicious", value: "SUSPECTED" }
    ]
  }
];

const VerifiedJobSubTab = ({ selectedTab = {}, onClick, page = "organic" }) => {
  const { isReviewedCompany = true, suspected = "SUSPECTED" } = selectedTab;

  const subTabList = useMemo(() => {
    return (
      tabList.find(item => item.value === isReviewedCompany && item.isSubTab)
        ?.subTab || []
    );
  }, [isReviewedCompany]);

  return (
    <div className="tabs">
      {page === "organic" ? (
        <div className="tab-list">
          {tabList.map(item => (
            <span
              className={`unClickedChip ${
                isReviewedCompany === item.value ? "clickedChip" : ""
              }`}
              onClick={() => onClick(item.value, "SUSPECTED")}
              key={item.label}
            >
              {item.label}
            </span>
          ))}
        </div>
      ) : null}
      <div className="tab-list subtab">
        {subTabList.map(item => (
          <span
            className={`unClickedChip ${
              suspected === item.value ? "clickedChip" : ""
            }`}
            onClick={() => onClick(isReviewedCompany, item.value)}
            key={item.label}
          >
            {item.label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default VerifiedJobSubTab;
