import React, { useMemo } from "react";
import MetaData from "../../../constants/MetaData";

const AdminPannelTabs = props => {
  const { handleClick, isSelected, isNewCity, handleSubTab, subTab } = props;
  let tabList = MetaData.adminPannelTabsList.filter(item => !item.is_disabled);
  if (isNewCity) {
    tabList = MetaData.adminPannelTabsList.filter(item => item.newCity);
  }
  const subTabList = useMemo(() => {
    return (
      tabList.find(item => item.id === isSelected && item.isSubTab)?.subTab ||
      []
    );
  }, [isSelected]);
  return (
    <div className="admin-panel-tabs">
      <div className="tabs">
        <div className="tab-list">
          {tabList.map(item => {
            const { is_disabled, id, title } = item;
            return (
              <span
                className={
                  is_disabled
                    ? "unClickedChip disabledChip"
                    : id === isSelected
                    ? "unClickedChip clickedChip"
                    : "unClickedChip"
                }
                onClick={() => handleClick(id)}
                key={id}
              >
                {title}
              </span>
            );
          })}
        </div>
        {subTabList.length ? (
          <div className="tab-list subtab">
            {subTabList.map(item => (
              <span
                key={item.label}
                className={
                  item.id === subTab
                    ? "unClickedChip clickedChip"
                    : "unClickedChip"
                }
                onClick={() => handleSubTab(item.id)}
              >
                {item.label}
              </span>
            ))}
          </div>
        ) : null}
      </div>
      <div className="label">Moderator Volume Distribution</div>
    </div>
  );
};
export default AdminPannelTabs;
