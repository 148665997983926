import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import JobHai from "../../../api/JobHai";
import {
  LEARN_TAB,
  LEARN_TAB_TAG_CATEGORY_ADD
} from "../../../routes/RouteConstants";
import NoAccess from "../../Common/NoAccess";
import CustomePagination from "../../Common/Pagination";
import { checkPermission, getErrorMessage } from "../../../services/utils";
import LoadingSpinner from "../../LoadingSpinner";

const TagCategoryList = ({ query }) => {
  const isAccess = checkPermission("LearnTabVideo");
  const [dataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchDataList = async () => {
      try {
        setLoading(true);
        const page = activePage;
        const res = await JobHai.get(
          `view-category-tag-mapping?page_number=${page}&page_size=20`
        );
        const { data = [], total_count = 0 } = res?.data?.data || {};
        setCount(total_count);
        setDataList(data);
      } catch (error) {
        const msg = getErrorMessage(error);
        setError(msg);
      } finally {
        setLoading(false);
      }
    };

    if (isAccess) {
      fetchDataList();
    }
  }, [activePage, isAccess]);

  const onPageChange = page => {
    setActivePage(page);
  };

  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  const columns = [
    {
      text: "ID",
      dataField: "available_tag_ids",
      sort: true
    },
    {
      text: "Tags",
      dataField: "title",
      sort: true
    },
    {
      text: "Category",
      dataField: "cat_tag_map",
      formatter: (cellContent, row) => {
        return (
          <div className="ellipsis">
            {row?.cat_tag_map.length ? row?.cat_tag_map.join(", ") : ""}
          </div>
        );
      }
    }
  ];

  if (isLoading) return <LoadingSpinner />;
  return (
    <>
      <div className="gap-20" />
      <div className="pageHeading row">
        <h2>
          Tag Category Mapping
          <div className="float-right">
            <a href={LEARN_TAB_TAG_CATEGORY_ADD}>
              <i className="fa fa-plus" aria-hidden="true"></i> Add New Mapping
            </a>
            <span className="sperator">|</span>
            <a href={LEARN_TAB}>Go Back</a>
          </div>
        </h2>
      </div>
      <div className="gap-20" />
      <div className="whitebox tag-title-list">
        {isAccess ? (
          <>
            {error ? (
              <div className="text-danger">Error: {error}</div>
            ) : (
              <>
                <BootstrapTable
                  keyField="content_id"
                  data={dataList}
                  columns={columns}
                  hover
                  condensed
                  bordered={false}
                  loading={true}
                  rowClasses="listvalue"
                  headerClasses="listHeding"
                  classes="borderless"
                  noDataIndication={() => <NoDataIndication />}
                />
                <CustomePagination
                  activePage={activePage}
                  totalItemsCount={count}
                  showPagination={count > 0}
                  onPageChange={onPageChange}
                  itemsCountPerPage={20}
                />
              </>
            )}
            <div className="clr gap-20"></div>
          </>
        ) : (
          <NoAccess />
        )}
      </div>
    </>
  );
};

export default TagCategoryList;
