import useLcsCampaignEditPermission from "../../../zustandService/useLcsCampaignEditPermission";
import MaterialSwitch from "../../materialSwitch";
import classes from "./leadClassificationTab.module.css";

const LeadClassificationFormHeader = props => {
  const { ruleIndex, method, remove } = props;
  const { watch, control } = method;
  const singleRuleWatcher = watch(`rules.${ruleIndex}`);
  const { canEditCampaign } = useLcsCampaignEditPermission(state => ({
    canEditCampaign: state.canEditCampaign
  }));
  return (
    <div className={classes.formHeader}>
      <h4 className={classes.ruleHeading}>Rule {ruleIndex + 1}</h4>
      {!singleRuleWatcher.id ? (
        <i
          onClick={() => remove(ruleIndex)}
          className="fa-regular fa-circle-xmark cursor-pointer mx-1"
        />
      ) : (
        <>
          <MaterialSwitch
            name={`rules.${ruleIndex}.is_active`}
            label="Active"
            control={control}
            defaultChecked={singleRuleWatcher.is_active ?? true}
            size={"small"}
            disabled={!canEditCampaign}
            formControlClasses={{ root: classes.formSwitch }}
          />
        </>
      )}
    </div>
  );
};
export default LeadClassificationFormHeader;
