import React from "react";
import MetaData from "../../constants/MetaData";
import Label from "../Common/Form/Label";
import Chips from "../Common/Chips";
import { getActiveItemList } from "../../services/utils";

const RecruiterCallAttempt = props => {
  const { callStatusValue } = props;
  const {
    recruiter_calling_first_status,
    recruiter_calling_second_status,
    recruiter_calling_third_status
  } = callStatusValue;
  const optionsList = MetaData.CallingAttempt;
  const handleChips = (e, fieldName, item) => {
    const { callStatusValue, handleCallStatus } = props;
    handleCallStatus(
      fieldName,
      callStatusValue[fieldName] === item.id ? "NEW" : item.id
    );
  };
  const noClick = () => {
    //No action
  };
  return (
    <div className="formRow">
      <div className="fullWidth">
        <div className="callingList">
          <Label lable="1st Call Attempt" />
          {recruiter_calling_second_status !== "NEW" ? (
            <Chips
              chips={[
                getActiveItemList(optionsList, recruiter_calling_first_status)
              ]}
              activeChips={[
                getActiveItemList(optionsList, recruiter_calling_first_status)
              ]}
              onClick={noClick}
              isMulti={false}
              fieldName={"recruiter_calling_first_status"}
              isClose={true}
            />
          ) : (
            <Chips
              chips={optionsList}
              activeChips={getActiveItemList(
                optionsList,
                recruiter_calling_first_status
              )}
              onClick={handleChips}
              isMulti={false}
              fieldName={"recruiter_calling_first_status"}
              isClose={true}
            />
          )}
        </div>
        {recruiter_calling_first_status !== "NEW" ? (
          <div className="callingList">
            <Label lable="2nd Call Attempt" />
            {recruiter_calling_third_status !== "NEW" ? (
              <Chips
                chips={[
                  getActiveItemList(
                    optionsList,
                    recruiter_calling_second_status
                  )
                ]}
                activeChips={[
                  getActiveItemList(
                    optionsList,
                    recruiter_calling_second_status
                  )
                ]}
                isMulti={false}
                onClick={noClick}
                fieldName={"recruiter_calling_second_status"}
              />
            ) : (
              <Chips
                chips={optionsList}
                activeChips={getActiveItemList(
                  optionsList,
                  recruiter_calling_second_status
                )}
                onClick={handleChips}
                isMulti={false}
                fieldName={"recruiter_calling_second_status"}
                isClose={true}
              />
            )}
          </div>
        ) : null}
        {recruiter_calling_second_status !== "NEW" ? (
          <div className="callingList">
            <Label lable="3rd Call Attempt" />
            <Chips
              chips={optionsList}
              activeChips={getActiveItemList(
                optionsList,
                recruiter_calling_third_status
              )}
              onClick={handleChips}
              isMulti={false}
              fieldName={"recruiter_calling_third_status"}
              isClose={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default RecruiterCallAttempt;
