import React, { useMemo } from "react";
import { Alert } from "react-bootstrap";
import MetaData from "../../constants/MetaData";
import CustomTextField from "../Common/Form/CustomTextField";
import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";
import Chips from "../Common/Chips";
import CustomSelect from "../Common/Form/CustomSelect";
import { getNthItemText } from "../../services/utils";
import PremiumIntentCapture from "./PremiumIntentCapture";

const JobVerificationStatus = props => {
  const { getFieldData, sidebarInfo, onClick } = props;
  const {
    jobId,
    crm_status_remarks,
    otherJobList,
    jobRejectedReason,
    crmMetaData = [],
    job_status,
    role = "QA",
    page = "",
    isRecruiterVerified,
    isJobLiveClicked,
    isRecruiterOnboarded,
    makeJobliveDirectly,
    disabled,
    jobApprovalStatus,
    job_live_status,
    isDifferentAddress,
    job_distinction,
    isNewCity,
    jobRejectedSubReason
  } = sidebarInfo;
  const { jobRejectedReasons = [], jobRejectedSubReasons = [] } = crmMetaData;

  const index = useMemo(
    () => otherJobList.findIndex(item => item === Number(jobId)),
    [otherJobList, jobId]
  );
  const jobNumber = useMemo(() => getNthItemText(index), [index]);
  const labelMakeJobLive = isNewCity ? "Ready to Live" : "Make Job Live";
  const liveLabel = makeJobliveDirectly
    ? `${labelMakeJobLive} 🎉`
    : "Send For QA";
  const ctaLabel = jobApprovalStatus === "REJECTED" ? "Reject Job" : liveLabel;

  const jobRejectedSubReasonsList = useMemo(() => {
    return jobRejectedSubReasons.filter(
      item => item.job_reject_reason_id === Number(jobRejectedReason)
    );
  }, [jobRejectedReason, jobRejectedSubReasons]);

  const isShowButton = useMemo(() => {
    if (
      jobApprovalStatus === "VERIFIED" ||
      (jobApprovalStatus === "REJECTED" && jobRejectedReason)
    ) {
      if (jobRejectedSubReasonsList.length) {
        return !!jobRejectedSubReason;
      }
      return true;
    }
    return false;
  }, [
    jobApprovalStatus,
    jobRejectedReason,
    jobRejectedSubReasonsList,
    jobRejectedSubReason
  ]);

  const successMessage = useMemo(() => {
    if (jobApprovalStatus === "REJECTED") return "Job Rejected";
    if (isRecruiterOnboarded) return "New Recruiter Onboarded!!";
    if (job_live_status)
      return job_live_status === "READY_FOR_QA"
        ? "Sent for QA"
        : isNewCity
        ? "Ready to Live"
        : "Job is live";
    return "Job verification done!";
  }, [jobApprovalStatus, isRecruiterOnboarded, job_live_status, isNewCity]);

  const showWarning =
    jobApprovalStatus === "VERIFIED" && isDifferentAddress && !isJobLiveClicked;

  const handleChips = (event, fieldName, item) => {
    getFieldData(fieldName, item.id);
  };

  const handleOnOff = (event, fieldName) => {
    const id = event.currentTarget.dataset.id;
    getFieldData(fieldName, id);
    onClick(id);
  };

  return (
    <div className="jobStatusContainer">
      <div className="rowContainer">
        <Heading
          heading={`${index + 1}${jobNumber} Job Status`}
          customClass="sideBarHeading"
        />
        {job_distinction === "Extra" && <div className="paidStatus">Paid</div>}
      </div>
      {job_status === "DISABLED" && (
        <div className="formRow">
          <div className="fullWidth">
            <Label lable="Job Live Status" />
            <div className="statusChip">
              <Chips
                chips={[{ label: "Disabled", id: "DISABLED" }]}
                activeChips={["DISABLED"]}
                isMulti={false}
                fieldName="job_status"
              />
            </div>
          </div>
        </div>
      )}
      {role === "QA" && page === "live" && (
        <div className="formRow">
          <div className="fullWidth">
            <Label lable="Job Live Status" />
            <Chips
              chips={MetaData.jobStatus}
              activeChips={job_status ? [job_status] : []}
              onClick={handleOnOff}
              isMulti={false}
              label="title"
              fieldName="job_status"
            />
          </div>
        </div>
      )}
      <div className="formRow">
        <div className="fullWidth">
          <Label lable="Job Approval Status" />
          <div className="statusChip">
            <Chips
              chips={MetaData.jobApprovalStatus}
              activeChips={jobApprovalStatus ? [jobApprovalStatus] : []}
              onClick={handleChips}
              isMulti={false}
              fieldName="jobApprovalStatus"
            />
          </div>
        </div>
      </div>
      {jobApprovalStatus === "REJECTED" && (
        <>
          <div className="formRow">
            <div className="fullWidth">
              <Label lable="Rejected Reason" />
              <CustomSelect
                name="jobRejectedReason"
                value={jobRejectedReason}
                onChange={e => {
                  getFieldData("jobRejectedReason", e.target.value);
                  getFieldData("jobRejectedSubReason", "");
                }}
                optionsList={jobRejectedReasons}
                itemLabel="reason"
                optionValue="id"
                allowDisabled={true}
              />
            </div>
          </div>
          {jobRejectedSubReasonsList.length > 0 && (
            <div className="formRow">
              <div className="fullWidth">
                <Label lable="Rejected Sub Reason" />
                <CustomSelect
                  name="jobRejectedSubReason"
                  value={jobRejectedSubReason}
                  onChange={e =>
                    getFieldData("jobRejectedSubReason", e.target.value)
                  }
                  optionsList={jobRejectedSubReasonsList}
                  itemLabel="subreason"
                  optionValue="id"
                  allowDisabled={true}
                />
              </div>
            </div>
          )}
        </>
      )}
      <PremiumIntentCapture sidebarInfo={sidebarInfo} />
      <div className="formRow">
        <div className="fullWidth">
          <Label lable="Remarks" isOptional={true} />
          <CustomTextField
            fieldName={"crm_status_remarks"}
            name="crm_status_remarks"
            value={crm_status_remarks}
            onChange={e => getFieldData("crm_status_remarks", e.target.value)}
            placeholder="Leave your remarks here..."
            textArea={true}
          />
        </div>
      </div>
      {isShowButton && isRecruiterVerified && (
        <>
          <div className="formRow">
            <div className="statusBtnContainer">
              {isJobLiveClicked ? (
                <input
                  type="button"
                  value={successMessage}
                  className="successButton"
                />
              ) : (
                <input
                  type="button"
                  value={ctaLabel}
                  className="submitBtn"
                  onClick={onClick}
                  disabled={disabled}
                />
              )}
            </div>
          </div>
          {showWarning && (
            <div className="formRow warningContainer">
              <Alert variant="warning">
                <img
                  src={"/images/warning.svg"}
                  alt="arrow"
                  width="24"
                  height="24"
                  className="down-arrow"
                />
                Job and Recruiter City/Locality is not matching!
              </Alert>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default JobVerificationStatus;
