import React, { useState } from "react";
import Tooltip from "../Common/Tooltip";
import MetaData from "../../constants/MetaData";
import { getDocumentType } from "../../services/utils";

const VerificationMethod = props => {
  const [isShown, setIsShown] = useState(false);
  const { onboardingMethod } = props;
  const { method, otherMethodDetails, documentType = "" } = onboardingMethod;
  let methodList = MetaData.RecOnBoardingMethod;
  const activeMethod = methodList.filter(item => item.value === method);
  let methodName = "";
  let infoList = [];
  if (activeMethod.length) {
    methodName = activeMethod[0].label;
    infoList = activeMethod[0].details;
  }
  if (method === "OTHER") infoList = otherMethodDetails;
  const temArr = infoList.filter(item => item.key === "Next Action:");
  const h1 = temArr.length ? temArr[0].value : "Check onboarding score";
  const docType = getDocumentType(documentType);
  return (
    <div className="vmContainer">
      <div className="left">
        <div className="col1">Onboarding {methodName}</div>
        <div className="col2">{h1}</div>
      </div>
      <div className="col3">
        <img
          src="/images/union.svg"
          alt="union"
          width="12"
          height="12"
          onMouseOver={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        />
        {isShown ? (
          <Tooltip>
            <div className="title">Onboarding {methodName}</div>
            <div className="info">
              {infoList.map((item, index) => {
                return (
                  <div key={`item${index}`}>
                    {`${item.key} `}{" "}
                    <strong>
                      {method == "M2" && item.key === "Document:"
                        ? docType
                        : item.value}
                    </strong>
                  </div>
                );
              })}
            </div>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
export default VerificationMethod;
