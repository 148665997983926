import React, { useEffect, useState } from "react";

import Label from "../Common/Form/Label";
import Chips from "../Common/Chips";
import { debounce } from "lodash";

const PremiumIntentCapture = props => {
  const { sidebarInfo } = props;
  const { interested_in_premium_offering, recId, jobId, premiumProductIntent } =
    sidebarInfo;

  const chipsList = [
    { label: "Yes", id: "Yes" },
    { label: "No", id: "No" }
  ];
  function handleClick(data, fldName, active) {
    if (
      interested_in_premium_offering == null ||
      interested_in_premium_offering == undefined
    )
      sidebarInfo.handlePremiumIntentCapture({
        data,
        fldName,
        active,
        jobId,
        recId
      });
  }
  return (
    <div className="premiumIntent">
      <div className="formRow">
        <div className="fullWidth">
          <Label lable="Did recruiter Opt for Premium Jobs" />
          <Chips
            chips={chipsList}
            activeChips={[{ id: premiumProductIntent }] || []}
            isMulti={false}
            fieldName="premium_intent"
            onClick={debounce(handleClick, 500)}
            isDisabled={
              interested_in_premium_offering !== null &&
              interested_in_premium_offering !== undefined
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PremiumIntentCapture;

function getIntent(interested_in_premium_off) {
  if (
    interested_in_premium_off == null ||
    interested_in_premium_off == undefined
  ) {
    return null;
  } else if (!interested_in_premium_off) return "No";
  return "Yes";
}

function getValueFromLabel(active) {
  return active.label === "No" ? false : true;
}
