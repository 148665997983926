import React from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import MetaData from "../../../constants/MetaData";
import CountdownContainer from "../../Common/CountDown";
import {
  COMPLIANCE_PROTECTION,
  JOB_EDIT_PAGE,
  JOB_SEEKER,
  RECUITERS_APPLICANT,
  REPORTED_JOBS
} from "../../../routes/RouteConstants";
import { getSubStr } from "../../../services/utils";
import RecruiterCluster from "./RecruiterCluster";
import InvalidReason from "./InvalidReason";

class ReportedList extends React.Component {
  handleChange = (event, reportId) => {
    let value = event.target.value;
    let fieldName = event.target.name;
    this.props.handleChange(reportId, fieldName, value);
  };

  handleFileUpload = (event, reportId) => {
    if (event.target.type === "file" && event.target.files[0]) {
      let fieldValue = event.target.files[0];
      let fileName = fieldValue.name;
      let errorMessage = "";
      let isUploadError = false;
      var reg =
        /(.*?)\.(jpg|jpeg|png|pdf|mp3|WAV|M3U|FLAC|M4A|mpeg-4|MP4|WMA|AAC)$/;
      if (!fileName.match(reg)) {
        isUploadError = true;
        errorMessage =
          "Only extensions .jpeg/.jpg/.png/ .pdf/ .mp3/ .WAV/ .M3U/ .FLAC/ .M4A/ .mpeg-4/ .MP4/ .WMA/ .AAC allowed.";
      }
      this.props.handleChange(reportId, "uploadFile", {
        fileName,
        file: fieldValue,
        errorMessage,
        isUploadError
      });
    }
  };

  saveReport = () => {
    const { saveReport, dataList } = this.props;
    saveReport(dataList);
  };

  handleCloseJobs = (recruiterId, reportId) => {
    this.props.closeRecruiterJobs(recruiterId, reportId);
  };

  getHTMLData = array => {
    return array.map((item, index) => {
      return <div key={`ls=${index}`}>{item}</div>;
    });
  };

  getClassifiedReason = (classifiedReason, reportId) => {
    const { showCTA } = this.props;
    return (
      <>
        <b className="downArrow" />
        <select
          value={classifiedReason}
          className="form-control"
          onChange={e => this.handleChange(e, reportId)}
          name="classified_reason"
          disabled={!showCTA}
        >
          <option value="">Select</option>
          {MetaData.classfiedReasons.map((item, index) => {
            return (
              <option value={item.value} key={`cl-${index}`}>
                {item.label}
              </option>
            );
          })}
        </select>
      </>
    );
  };

  getCallStatus = (callStatus, reportId) => {
    const { showCTA } = this.props;
    return (
      <>
        <b className="downArrow" />
        <select
          value={callStatus}
          className="form-control"
          onChange={e => this.handleChange(e, reportId)}
          name="call_status"
          disabled={callStatus === "Auto_Resolved" || !showCTA}
        >
          <option value="">Select</option>
          {MetaData.reportedCallStatus.map((item, index) => {
            return (
              <option
                value={item.value}
                key={`cs-${index}`}
                disabled={item.isDisabled}
              >
                {item.label}
              </option>
            );
          })}
        </select>
      </>
    );
  };
  getAuthentic = (isValid, reportId) => {
    const { showCTA } = this.props;
    return (
      <>
        <b className="downArrow" />
        <select
          value={isValid}
          className="form-control"
          onChange={e => this.handleChange(e, reportId)}
          name="is_valid"
          disabled={!showCTA}
        >
          <option value="">Select</option>
          {MetaData.authenticStatus.map((item, index) => {
            return (
              <option value={item.label} key={`auth-${index}`}>
                {item.label}
              </option>
            );
          })}
        </select>
      </>
    );
  };

  renderBrowseFile = (files, reportId) => {
    const { showCTA } = this.props;
    return (
      <div className="upload-file">
        <input
          type="file"
          name="proof"
          onChange={e => this.handleFileUpload(e, reportId)}
          id={`imgupload-${reportId}`}
        />
        <label
          htmlFor={showCTA ? `imgupload-${reportId}` : `disables-${reportId}`}
          className="custom-file-upload"
        >
          Upload Proof <i className="fa fa-file-upload"></i>
        </label>
        {files && files.fileName && (
          <div className="file-name">{files.fileName}</div>
        )}
        {files.errorMessage && (
          <div className="text-danger">{files.errorMessage}</div>
        )}
      </div>
    );
  };

  getFileList = files => {
    return (
      <div className="view-file">
        {files.map((file, index) => {
          return (
            <span key={`files-${index}`}>
              {file.url && (
                <a
                  href={file.url}
                  title={file.fileName}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.fileName}
                </a>
              )}
            </span>
          );
        })}
      </div>
    );
  };
  render() {
    const {
      dataList = {},
      saveReportId,
      successMessage,
      isAllowEdit,
      showCTA = true,
      clusterList,
      isCPSection
    } = this.props;
    const {
      user_name = "",
      call_status = "",
      classified_reason = "",
      recruiter_name = "",
      recruiter_id = "",
      recruiter_remarks = "",
      report_id = "",
      recuiter_report_count = "",
      recuiter_valid_report_count = "",
      js_job_viewed_count = "",
      js_job_report_count = "",
      is_recruiter_job_closable = false,
      is_job_closed = false,
      crm_deadline = "",
      deadline_ts = "",
      job_id = "",
      job_title = "",
      user_id = "",
      files = [],
      createdAt = "",
      moderator_name = "",
      js_pending_report_count = 0,
      last_actioned_by = "",
      last_actioned_date = "",
      cluster_id = null,
      recruiter_asked_for_money_count,
      invalid_reason = "",
      invalid_reason_text = "",
      invalidReasonError = "",
      recruiter_pending_report_count = 0,
      potential_report_reason = ""
    } = dataList;
    let jsName = getSubStr(user_name);
    const clusterItem = cluster_id ? clusterList[cluster_id] : {};
    const reportPageURL = isCPSection ? COMPLIANCE_PROTECTION : REPORTED_JOBS;
    const cnpLabel = isCPSection ? " C&P " : "";
    return (
      <tr className="listvalue">
        <td>
          <div className="title">{dataList.company_name}</div>
          <div>
            {recruiter_name} - [
            <a
              href={`${RECUITERS_APPLICANT}/${recruiter_id}`}
              title={`View Applicant of ${recruiter_name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {recruiter_id}
            </a>
            ]
            {recruiter_remarks ? (
              <>
                <span
                  className="remarks"
                  data-tip
                  data-for={`rec_remark-${report_id}`}
                >
                  <i className="fas fa-comment"></i>
                </span>
                <ReactTooltip
                  id={`rec_remark-${report_id}`}
                  place="bottom"
                  effect="solid"
                  type={"info"}
                  className={"custome-tootip"}
                >
                  {recruiter_remarks}
                </ReactTooltip>
              </>
            ) : null}
          </div>
          <div>{dataList.recruiter_phone}</div>
          <div>
            {job_title}- [
            <a
              href={`${JOB_EDIT_PAGE}/organic/QA/${job_id}?recId=${recruiter_id}`}
              title={`View Job ${job_title}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {job_id}
            </a>
            ]
          </div>
        </td>
        <td>
          <div>
            {jsName} - [
            <a
              href={`${JOB_SEEKER}/edit/${user_id}`}
              title={`View ${user_name} Profile`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {user_id}
            </a>
            ]
          </div>
          <div>{dataList.user_phone}</div>
          {cluster_id ? <RecruiterCluster clusterItem={clusterItem} /> : null}
        </td>
        <td valign="top">
          <div>
            Total {cnpLabel} Reports on Rec -{" "}
            <a
              href={`${reportPageURL}?recId=${recruiter_id}`}
              title="View Total Reports on Rec"
              target="_blank"
              rel="noopener noreferrer"
            >
              {recuiter_report_count}
            </a>
          </div>
          {isCPSection ? (
            <div>
              Pending {cnpLabel} report for Rec{" "}
              <a
                href={`${reportPageURL}?recId=${recruiter_id}&reportStatus=pending`}
                title={`Pending ${cnpLabel} report for Rec`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {recruiter_pending_report_count}
              </a>
            </div>
          ) : null}
          <div>
            Rec Ask For Money -{" "}
            {recruiter_asked_for_money_count ? (
              <a
                href={`${reportPageURL}?recId=${recruiter_id}&reportReason=Recruiter asked for money`}
                title="View Recruiter ask for money"
                target="_blank"
                rel="noopener noreferrer"
              >
                {recruiter_asked_for_money_count}
              </a>
            ) : (
              recruiter_asked_for_money_count
            )}
          </div>
          {this.getHTMLData([
            `Valid ${cnpLabel} Reports for Rec - ${recuiter_valid_report_count}`,
            `Jobs Viewed by JS - ${js_job_viewed_count}`,
            `Jobs Reported ${cnpLabel} by JS - ${js_job_report_count}`
          ])}
          <div>
            Pending {cnpLabel} Reports From JS -{" "}
            {js_pending_report_count ? (
              <a
                href={`${reportPageURL}?jsId=${user_id}&reportStatus=pending`}
                title="View Total Pending reports from JS"
                target="_blank"
                rel="noopener noreferrer"
              >
                {js_pending_report_count}
              </a>
            ) : (
              js_pending_report_count
            )}
          </div>
        </td>
        <td>
          {potential_report_reason ? (
            <p>
              {potential_report_reason} <br />
            </p>
          ) : null}
          {dataList.report_reason}
          {dataList.js_remarks ? (
            <>
              <span
                className="remarks"
                data-tip
                data-for={`js-remark-${report_id}`}
              >
                <i className="fas fa-comment"></i>
              </span>
              <ReactTooltip
                id={`js-remark-${report_id}`}
                place="bottom"
                effect="solid"
                type={"info"}
                className={"custome-tootip"}
              >
                {dataList.js_remarks}
              </ReactTooltip>
            </>
          ) : null}
        </td>
        <td valign="top">
          {this.getClassifiedReason(
            classified_reason ? classified_reason : "",
            report_id
          )}
          {this.renderBrowseFile(dataList.uploadFile, report_id)}
          {files.length ? this.getFileList(dataList.files) : null}
          <textarea
            className="form-control"
            name="remarks"
            maxLength="1000"
            rows={1}
            placeholder="Remarks"
            onChange={e => this.handleChange(e, report_id)}
            value={dataList.remarks ? dataList.remarks : ""}
            readOnly={!showCTA}
          />
        </td>
        <td valign="top">
          {this.getAuthentic(dataList.is_valid, report_id)}
          {dataList.is_valid === "Invalid" ? (
            <InvalidReason
              onChange={this.handleChange}
              reportId={report_id}
              invalidReason={invalid_reason}
              invalidReasonText={invalid_reason_text}
              invalidReasonError={invalidReasonError}
            />
          ) : null}
        </td>
        <td valign="top">
          <div className="rightDiv">
            <div>{this.getCallStatus(call_status, report_id)}</div>
            <div className="button-container">
              {call_status !== "Auto_Resolved" && showCTA ? (
                <div className="actionButton">
                  {saveReportId === report_id && (
                    <span className="text-success">{successMessage}</span>
                  )}
                  {dataList.uploadFile && dataList.uploadFile.isUploadError ? (
                    <button
                      onClick={() => this.saveReport()}
                      className="btn btn-success"
                      disabled
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => this.saveReport()}
                      className="btn btn-success"
                      disabled={isAllowEdit}
                    >
                      Save
                    </button>
                  )}
                  {!is_job_closed && is_recruiter_job_closable ? (
                    <button
                      type="button"
                      className="btn btn-danger"
                      disabled={isAllowEdit}
                      onClick={() =>
                        this.handleCloseJobs(recruiter_id, report_id)
                      }
                    >
                      Close/Blacklist
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled
                    >
                      Close/Blacklist
                    </button>
                  )}
                </div>
              ) : null}
              {call_status === "Resolved" ? (
                <span className="created">
                  Posted On:{" "}
                  {createdAt
                    ? moment(createdAt).format("DD-MM-YYYY h:m:s")
                    : "NA"}
                </span>
              ) : (
                <span className="timerContainer">
                  <CountdownContainer
                    date={
                      createdAt ? moment(createdAt).format("DD-MMM-YYYY") : ""
                    }
                    deadline={crm_deadline ? crm_deadline : deadline_ts}
                    deadTimeStamp={deadline_ts}
                    isFeaze={false}
                  />
                </span>
              )}
              {moderator_name ? (
                <span className="created">Assigned to: {moderator_name}</span>
              ) : null}
              {last_actioned_by ? (
                <span className="created">
                  Last Actioned By: {last_actioned_by}
                </span>
              ) : null}
              {last_actioned_date ? (
                <span className="created">
                  Last Actioned At:{" "}
                  {moment(last_actioned_date).format("DD-MM-YYYY h:m:s")}
                </span>
              ) : null}
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default ReportedList;
