import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../ui/table";
import { Checkbox } from "../../ui/checkbox";
import { customDateFormat, isLmsModerator } from "../../../services/utilsV2";
import { RECUITERS_LIST } from "../../../routes/RouteConstants";

const CompanyList = ({ data, setSelectedIds }) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  useEffect(() => {
    setSelectedIds(selectedCompanies);
  }, [selectedCompanies]);

  const handleCheckboxChange = orgId => {
    setSelectedCompanies(prevSelected =>
      prevSelected.includes(orgId)
        ? prevSelected.filter(id => id !== orgId)
        : [...prevSelected, orgId]
    );
  };

  const handleSelectAllChange = () => {
    if (selectedCompanies.length === data.length) {
      setSelectedCompanies([]);
    } else {
      setSelectedCompanies(data.map(item => item.orgId));
    }
  };

  const isChecked = orgId => selectedCompanies.includes(orgId);
  const showAssignTo = !isLmsModerator();
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {showAssignTo && (
            <TableHead>
              <Checkbox
                className="border-gray-300"
                onCheckedChange={handleSelectAllChange}
                checked={selectedCompanies.length === data.length}
              />
            </TableHead>
          )}
          <TableHead>Org Id</TableHead>
          <TableHead>Company Name</TableHead>
          <TableHead>Cleaning Status</TableHead>
          <TableHead>Verified Rec</TableHead>
          <TableHead>Jobs</TableHead>
          <TableHead>Purchased</TableHead>
          <TableHead>Credits available</TableHead>
          {showAssignTo && <TableHead>Executive</TableHead>}
          <TableHead>Expiring</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.length ? (
          <>
            {data.map((item, index) => (
              <TableRow key={index}>
                {showAssignTo && (
                  <TableCell>
                    <Checkbox
                      className="border-gray-300"
                      onCheckedChange={() => handleCheckboxChange(item.orgId)}
                      checked={isChecked(item.orgId)}
                    />
                  </TableCell>
                )}
                <TableCell>{item.orgId}</TableCell>
                <TableCell>{item.orgName}</TableCell>
                <TableCell>
                  {item.isCompanyReviewed ? "Reviewed" : "Not Reviewed"}
                </TableCell>
                <TableCell>
                  <Link
                    to={`${RECUITERS_LIST}/?organisation_id=${item.orgId}&returnURL=insidesales`}
                    onClick={e => e.stopPropagation()}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {item.verifiedRecruitersCount}
                  </Link>
                </TableCell>
                <TableCell>{item.jobCount}</TableCell>
                <TableCell>{item.creditsPurchased}</TableCell>
                <TableCell>{item.creditsAvailable}</TableCell>
                {showAssignTo && <TableCell>{item.moderatorName}</TableCell>}
                <TableCell>
                  {customDateFormat(item.unallocationDate, "D MMMM")}
                </TableCell>
              </TableRow>
            ))}
          </>
        ) : (
          <TableRow>
            <TableCell colspan={9} align="center" className="text-red-500">
              No Data Found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default CompanyList;
