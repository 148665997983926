import React from "react";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";

const styles = theme => ({
  customClass: {
    color: "#2E2E36",
    lineHeight: "20px",
    background: "none",
    border: "none",
    borderRadius: "6px",
    fontWeight: 600,
    padding: "9px 14px",
    fontSize: "15px",
    outline: "none",
    width: "100%",
    boxSizing: "border-box",
    "&::-webkit-input-placeholder": {
      fontWeight: 400,
      color: "#AEB5C6",
      opacity: 1
    },
    "&::placeholder": {
      fontWeight: 400,
      color: "#AEB5C6",
      opacity: 1
    }
  },
  textfield: {
    border: "1px solid #E2E5EF",
    borderRadius: "6px",
    height: "40px",
    padding: "0",
    width: "100%"
  },
  flexClass: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    "&:hover": {
      border: "2px solid #2A89F6"
    },
    transition: "border 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms"
  },
  noPadding: {
    border: "none",
    padding: "11px 0"
  },
  noBorderHover: {
    "&:hover": {
      border: "1px solid #E2E5EF"
    }
  }
});

const NewCustomTextField = props => {
  const {
    classes,
    endAdornment,
    startAdornment,
    height,
    style,
    inputProps = {},
    autoComplete = "off",
    onClick,
    noPadding = false,
    className,
    inputClass,
    textArea = false,
    noBorderHover = false,
    ...otherProps
  } = props;
  return (
    <div
      className={cx(
        className ? className : classes.textfield,
        classes.flexClass,
        noBorderHover && classes.noBorderHover
      )}
      style={style}
      onClick={onClick}
    >
      {startAdornment ? startAdornment : null}
      {textArea ? (
        <textarea
          className={cx(
            inputClass,
            classes.customClass,
            noPadding && classes.noPadding
          )}
          autoComplete={autoComplete}
          {...otherProps}
          {...inputProps}
        />
      ) : (
        <input
          className={cx(
            inputClass,
            classes.customClass,
            noPadding && classes.noPadding
          )}
          autoComplete={autoComplete}
          {...otherProps}
          {...inputProps}
        />
      )}
      {endAdornment ? endAdornment : null}
    </div>
  );
};

export default withStyles(styles)(NewCustomTextField);
