import MetaData from "../../../constants/MetaData";

const InvalidReason = props => {
  const {
    reportId,
    onChange,
    invalidReason,
    invalidReasonText,
    invalidReasonError
  } = props;
  return (
    <div className="invalidReason">
      <b className="downArrow" />
      <select
        value={invalidReason}
        className="form-control"
        onChange={e => onChange(e, reportId)}
        name="invalid_reason"
        disabled={false}
      >
        <option value="">Select</option>
        {MetaData.invalidReasonsList.map((item, index) => {
          return (
            <option value={item.value} key={`invalidReason-${index}`}>
              {item.label}
            </option>
          );
        })}
      </select>
      {invalidReason === "OTHERS" ? (
        <textarea
          className="form-control"
          name="invalid_reason_text"
          maxLength="100"
          placeholder="Others Reasons"
          onChange={e => onChange(e, reportId)}
          value={invalidReasonText ? invalidReasonText : ""}
        />
      ) : null}
      {invalidReasonError && (
        <div className="text-danger">{invalidReasonError}</div>
      )}
    </div>
  );
};
export default InvalidReason;
