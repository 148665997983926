import { NavLink } from "react-router-dom";
import { SparkleIcon, Building, Handshake, User } from "lucide-react";
import {
  LFMS,
  LFMS_ASSIGNMENT,
  LFMS_COMPANIES,
  LFMS_SALES
} from "../../routes/RouteConstants";
import { isLmsAdmin } from "../../services/utilsV2";

const LeftNav = props => {
  const {
    aClass = "flex items-center gap-2 text-base font-medium hover:bg-accent hover:text-accent-foreground rounded-md px-3 py-2 hover:text-blue-500",
    activeClass = "bg-accent text-blue-500",
    navClass = "flex flex-col gap-1 mt-2 p-3",
    handleClose
  } = props;
  const isAdmin = isLmsAdmin();
  return (
    <nav className={navClass}>
      <NavLink
        to={LFMS}
        end
        className={({ isActive }) =>
          isActive ? `${aClass} ${activeClass}` : aClass
        }
        onClick={handleClose ? handleClose : undefined}
      >
        <SparkleIcon className="w-5 h-5" />
        Leads
      </NavLink>
      <NavLink
        to={LFMS_COMPANIES}
        className={({ isActive }) =>
          isActive ? `${aClass} ${activeClass}` : aClass
        }
        onClick={handleClose}
      >
        <Building className="w-5 h-5" />
        Companies
      </NavLink>
      <NavLink
        to={LFMS_SALES}
        className={({ isActive }) =>
          isActive ? `${aClass} ${activeClass}` : aClass
        }
        onClick={handleClose}
      >
        <Handshake className="w-5 h-5" />
        Sales
      </NavLink>
      {isAdmin ? (
        <NavLink
          to={LFMS_ASSIGNMENT}
          className={({ isActive }) =>
            isActive ? `${aClass} ${activeClass}` : aClass
          }
          onClick={handleClose}
        >
          <User className="w-5 h-5" />
          Assignment
        </NavLink>
      ) : null}
    </nav>
  );
};

export default LeftNav;
