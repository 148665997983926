import React from "react";
import DatePicker from "react-datepicker";
import MetaData from "../../constants/MetaData";

const ManualJDFilters = props => {
  const { handleOnChange, searchJobs, filters, isDisabled } = props;

  return (
    <div className="job_seeker row">
      <div className="col-md-3">
        <div className="date-lable padding15">Start Date</div>
        <div className="date-field">
          <DatePicker
            selected={filters.start_date}
            onChange={e => {
              handleOnChange("start_date", e);
            }}
            dateFormat="yyyy-MM-dd"
            className="form-control"
            maxDate={new Date()}
          />
        </div>
      </div>
      <div className="col-md-3">
        <div className="date-lable padding15">End Date</div>
        <div className="date-field">
          <DatePicker
            selected={filters.end_date}
            onChange={e => {
              handleOnChange("end_date", e);
            }}
            dateFormat="yyyy-MM-dd"
            className="form-control"
            maxDate={new Date()}
          />
        </div>
      </div>
      <div className="col-md-2">
        <b className="downArrow"></b>
        <select
          className="form-control"
          name="status"
          value={filters.status}
          onChange={e => {
            handleOnChange("status", e.target.value);
          }}
        >
          <option value="Pending">Pending</option>
          <option value="Resolved">Resolved</option>
        </select>
      </div>
      <div className="col-md-2">
        <b className="downArrow" />
        <select
          className="form-control"
          name="jobPlan"
          value={filters.jobPlan}
          onChange={e => {
            handleOnChange("jobPlan", e.target.value);
          }}
        >
          <option value="">Select Job Type</option>
          {MetaData.premiumJobType.map(option => (
            <option value={option.value} key={`premium${option}`}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-2">
        <div className="float-right">
          {isDisabled ? (
            <button className="btn btn-primary disable" disabled>
              SEARCHING ...
            </button>
          ) : (
            <button className="btn btn-primary" onClick={searchJobs}>
              SEARCH
            </button>
          )}
        </div>{" "}
      </div>
    </div>
  );
};
export default ManualJDFilters;
