import { useEffect, useState } from "react";
import AutoSearch from "../../components/Common/Form/AutoSearch";
import { isLmsModerator } from "../../services/utilsV2";
import useInsideLeadStore from "../../zustandService/useInsideLeadStore";
import { Label } from "../ui/label";

const AssignedTo = ({
  fieldItems,
  setFieldItems,
  label = "Executive",
  fieldName = "moderatorIds",
  additionalItems = [],
  excludedIds = [],
  optionsList = [],
  resetKey = 0
}) => {
  const { moderatorList } = useInsideLeadStore(state => ({
    moderatorList: state.moderatorList
  }));
  const [agentName, setAgentName] = useState("");
  const agentId = fieldItems[fieldName] || "";

  let combinedOptions = [];
  if (optionsList.length) {
    combinedOptions = optionsList;
  } else {
    combinedOptions = [...additionalItems, ...moderatorList].filter(
      option => !excludedIds.includes(option?.id)
    );
  }
  useEffect(() => {
    if (agentId) {
      const currentModerator = combinedOptions.filter(
        item => item.id === Number(agentId)
      );
      if (currentModerator.length) {
        setAgentName(currentModerator[0].name);
      }
    } else {
      setAgentName("");
    }
  }, [agentId]);

  const handleChange = option => {
    if (!option) {
      setFieldItems({
        ...fieldItems,
        [fieldName]: ""
      });
    }
  };

  const handleSelectedValue = option => {
    setFieldItems({
      ...fieldItems,
      [fieldName]: option?.id || ""
    });
    setAgentName(option?.label);
  };

  if (isLmsModerator()) return null;

  const newModeratorList = combinedOptions.map(({ name, ...rest }) => ({
    ...rest,
    label: name
  }));
  return (
    <>
      <div className="grid gap-2 assign-to">
        <Label htmlFor="assign-to">{label}</Label>
        <div className="relative w-full formRow">
          <div className="flex w-full justify-between text-sm py-2">
            <div className="selectBox w-full text-sm">
              <AutoSearch
                dataList={newModeratorList}
                getSelectedValue={handleSelectedValue}
                getSelectedInput={handleChange}
                placeholder={`Select ${label}`}
                variant="outlined"
                id={`search-${label}`}
                searchValue={agentName}
                isArrow={true}
                key={resetKey}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignedTo;
