import React from "react";
import { RECUITERS_LIST } from "../../../routes/RouteConstants";
import Heading from "../../Common/Form/Heading";

const AutoJobRecruiterDetails = ({ recruiterDetails }) => {
  const {
    recruiter_id = "",
    recruiter_name = "",
    recruiter_phone = ""
  } = recruiterDetails;

  return (
    <>
      <Heading heading="Recruiter Details" />
      <div className="recInfo">{`${recruiter_name} @ ${recruiter_phone}`}</div>

      <div className="recInfo">
        Recruiter ID -
        <a
          href={`${RECUITERS_LIST}?recIds=${recruiter_id}`}
          title="View Recruiter"
          target="_blank"
          rel="noopener noreferrer"
        >
          {recruiter_id}
        </a>
      </div>
    </>
  );
};
export default AutoJobRecruiterDetails;
