import JobHai from "../../api/JobHai";
import { getCookie } from "../cookie";
import { getModeratorLevel, getModeratorName } from "../utils";

export const logEvents = (eventObj, info) => {
  let moderator = getModeratorName();
  const moderatorId = getCookie("moderator_id");
  const eventInfo = {
    ...info,
    moderator,
    moderator_id: moderatorId,
    user_level: getModeratorLevel()
  };

  const dataObj = [
    {
      event_category: eventObj.category,
      event_type: eventObj.name,
      event_info: JSON.stringify(eventInfo),
      source: `job-hai-crm`,
      user_type: "crm"
    }
  ];
  JobHai.post("../logs/log-events", dataObj)
    .then(response => {
      console.log("Event log Save!!");
    })
    .catch(error => {
      console.log("Error in Event log!!", error);
    });
};
