import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import MetaData from "../../constants/MetaData";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import { getErrorMessage, checkPermission } from "../../services/utils";
import withRouter from "../../services/withRouter";

class EditJobSeeker extends React.Component {
  constructor(props) {
    super(props);
    const { params = {} } = this.props;
    this.state = {
      job_seeker: [],
      isLoading: true,
      experience: [],
      uid: params.uid || "",
      msgData: { msg: "", showMsg: false, msgType: "error" }
    };
  }

  handleChange = event => {
    if (event.target.name === "city_id") {
      this.setState({ locality_id: "" });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  saveUser = e => {
    e.preventDefault();
    const obj = {
      user_id: this.state.user_id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      date_of_birth: this.state.date_of_birth,
      gender: this.state.gender,
      city_id: this.state.city_id,
      locality_id: this.state.locality_id,
      status: this.state.status,
      fluency_english: this.state.fluency_english,
      current_salary: this.state.current_salary,
      qualification_id: this.state.qualification_id
    };
    // console.log(obj)
    JobHai.post("/job_seeker/update/", obj)
      .then(
        function (response) {
          this.setState({
            msgData: {
              msg: "User has been saved",
              showMsg: true,
              msgType: "success"
            }
          });
          window.scrollTo(0, 0);
        }.bind(this)
      )
      .catch(
        function (error) {
          let msg = getErrorMessage(error);
          this.setState({
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
          window.scrollTo(0, 0);
        }.bind(this)
      );
  };

  async componentDidMount() {
    JobHai.get("/job_seeker/edit/" + this.state.uid)
      .then(response => {
        let data = response.data.data;
        const User_Introduction_Audio = data.User_Introduction_Audio
          ? data.User_Introduction_Audio
          : {};
        this.setState({
          isLoading: false,
          user_id: data.user_id,
          first_name: data.first_name ? data.first_name : "",
          last_name: data.last_name ? data.last_name : "",
          email: data.email ? data.email : "",
          phone: data.phone ? data.phone : "",
          date_of_birth: new Date(data.date_of_birth),
          gender: data.gender ? data.gender : "",
          profile_photo: data.profile_photo ? data.profile_photo : "",
          qualification_id: data.qualification_id ? data.qualification_id : "",
          current_salary: data.current_salary ? data.current_salary : "",
          is_fresher: data.is_fresher ? data.is_fresher : "",
          is_soft_profile_completed: data.is_soft_profile_completed
            ? data.is_soft_profile_completed
            : "",
          last_login_tstamp: data.last_login_tstamp,
          city_id: data.city_id ? data.city_id : "",
          locality_id: data.locality_id ? data.locality_id : "",
          other_job_roles: data.other_job_roles ? data.other_job_roles : "",
          other_languages: data.other_languages ? data.other_languages : "",
          profile_update_time: data.profile_update_time,
          resume: data.resume ? data.resume : "",
          resume_uploaded_date: data.resume_uploaded_date,
          school_medium: data.school_medium ? data.school_medium : "",
          school_subject: data.school_subject ? data.school_subject : "",
          status: data.status ? data.status : "",
          fluency_english: data.fluency_english ? data.fluency_english : "",
          createdAt: moment(data.createdAt).format("YYYY-MM-DD hh:mm:ss"),
          updatedAt: moment(data.updatedAt).format("YYYY-MM-DD hh:mm:ss"),
          job_role: data.job_role ? data.job_role : "NA",
          skills: data.skills ? data.skills : "NA",
          experience: data.experience,
          audio: User_Introduction_Audio.audio_url
            ? User_Introduction_Audio.audio_url
            : ""
        });
      })
      .catch(
        function (error) {
          let msg = getErrorMessage(error);
          this.setState({
            isLoading: false,
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
        }.bind(this)
      );
  }

  render() {
    let experienceDetails = "NA";
    const { experience, isLoading, msgData, audio } = this.state;
    if (experience.length > 0) {
      experienceDetails = experience.map(key => (
        <div className="col-md-3" key={"ex" + key.label}>
          <label>{key.label}</label>
          <input
            type="text"
            className="form-control"
            readOnly
            value={key.text_answer + " month"}
          />
        </div>
      ));
    }
    const label = {
      display: "block"
    };

    const isAllowEdit = !checkPermission("Job Seeker", true);
    let arrQualification = MetaData.qualifications;
    if (isLoading) {
      return <LoadingSpinner key="loading" />;
    } else {
      return (
        <div className="edit-form">
          <div className="clr gap-20"></div>
          <div className="clr gap-20"></div>
          <PopupMessage
            data={msgData}
            key="popup"
            click={this.handleClosePopup}
          />
          <div className="pageHeading row">
            <h2>
              Edit Job Seeker
              <div className="float-right">
                <Link to={"/job-seeker/"}>Back</Link>
              </div>
            </h2>
          </div>
          <div className="clr"></div>
          <div className="clr gap-20"></div>
          <div className="whiteCard edit-form">
            <div className="container-fluid">
              <div>
                <h2>
                  User Details:
                  <span>
                    User ID - {this.state.user_id} | createdAt -{" "}
                    {this.state.createdAt}| Last Updated On -{" "}
                    {this.state.updatedAt}
                  </span>
                  <div className="float-right">
                    {this.state.resume && (
                      <a
                        href={this.state.resume}
                        target="_blank"
                        download
                        className="primary"
                        rel="noopener noreferrer"
                        title="Download Resume"
                      >
                        Download Resume
                      </a>
                    )}
                  </div>
                </h2>
              </div>
              {audio ? (
                <div className="form-row">
                  <div className="col-md-12 audio-item">
                    <div className="float-right">
                      <audio controls>
                        <source src={audio} type="audio/ogg" />
                        <source src={audio} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                    <div className="float-right">
                      <a
                        href={audio}
                        target="_blank"
                        download
                        className="audio"
                        rel="noopener noreferrer"
                        title="Download This Audio"
                      >
                        <i className="fas fa-download" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="gap-10"></div>
              <form autoComplete="off">
                <div className="form-row">
                  <div className="col-md-3">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      required
                      value={this.state.first_name}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      required
                      value={this.state.last_name}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      required
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      name="phone"
                      required
                      maxLength="10"
                      value={this.state.phone}
                      onChange={event =>
                        this.setState({
                          phone: event.target.value.replace(/\D/, "")
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-3">
                    <label>Date of Birth (yyyy-mm-dd)</label>
                    <DatePicker
                      selected={this.state.date_of_birth}
                      onChange={value => {
                        this.setState({ date_of_birth: value });
                      }}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Gender</label>
                    <b className="downArrow"></b>
                    <select
                      className="form-control"
                      name="gender"
                      value={this.state.gender}
                      onChange={this.handleChange}
                    >
                      <option value="" className="option-selected">
                        Select
                      </option>
                      {MetaData.gender.map(option => (
                        <option value={option.toLowerCase()} key={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <SelectCity
                      filedName={"city_id"}
                      value={this.state.city_id}
                      onChange={this.handleChange}
                      label="City"
                      allCity={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <SelectLocality
                      filedName={"locality_id"}
                      value={this.state.locality_id}
                      cityId={this.state.city_id}
                      onChange={this.handleChange}
                      label="Location"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-3">
                    <label>Status</label>
                    <b className="downArrow"></b>
                    <select
                      className="form-control"
                      name="status"
                      onChange={this.handleChange}
                      value={this.state.status}
                    >
                      <option value="" className="option-selected">
                        Select
                      </option>
                      {MetaData.user_status.map(option => (
                        <option value={option.toLowerCase()} key={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label>English Fluency</label>
                    <b className="downArrow"></b>
                    <select
                      className="form-control"
                      name="fluency_english"
                      value={this.state.fluency_english}
                      onChange={this.handleChange}
                    >
                      <option value="" className="option-selected">
                        Select
                      </option>
                      {MetaData.fluency_english_user.map(option => (
                        <option value={option.toLowerCase()} key={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label>Current Salary per month</label>
                    <input
                      type="text"
                      className="form-control"
                      name="current_salary"
                      required
                      value={this.state.current_salary}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Qualification</label>
                    <b className="downArrow"></b>
                    <select
                      className="form-control"
                      name="qualification_id"
                      value={this.state.qualification_id}
                      onChange={this.handleChange}
                    >
                      <option value="" className="option-selected">
                        Select
                      </option>
                      {arrQualification.map(option => (
                        <option value={option.id} key={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label>Selected Job Role</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={this.state.job_role}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12">
                    <label style={label}>Skills</label>
                    <textarea
                      className="form-control"
                      rows="1"
                      readOnly
                      value={this.state.skills}
                    ></textarea>
                  </div>
                  <div className="col-md-12">
                    <label>Experience</label>
                    <div className="form-row">{experienceDetails}</div>
                  </div>
                </div>
                <div className="gap-20"></div>
                <div className="bttn txt-align-right">
                  <button
                    onClick={this.saveUser}
                    className="bttn"
                    disabled={isAllowEdit}
                  >
                    SAVE USER
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(EditJobSeeker);
