import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  DownloadIcon,
  FlipHorizontalIcon,
  PenIcon
} from "lucide-react";
import { Button } from "../../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "../../ui/card";
import { Label } from "../../ui/label";

import { Input } from "../../ui/input";
import { Textarea } from "../../ui/textarea";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose
} from "../../ui/dialog";
import { Toggle } from "../../ui/toggle";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { ToggleGroup, ToggleGroupItem } from "../../ui/toggle-group";

import React, { useCallback, useRef } from "react";
import assets from "../../../manifest";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas-pro";
import Draggable from "react-draggable";
import cx from "classnames";
// import st from "./grid.module.css"; // We'll define the grid styles here
// import DraggableGrid from "./Draggable";

const MAX_LENGTH = 80;
function LCSImageGen() {
  const [brand, setBrand] = useState("Test");
  const [layout, setLayout] = useState("left");
  const [heading, setHeading] = useState("Amazing Heading");
  const [action, setAction] = useState("Button");
  const [subHeading, setSubheading] = useState("A simple subHeading");
  const [brandImage, setBrandImage] = useState(assets.brand[0]?.link);
  const [jobHaiLogo, setJobHaiLogo] = useState(
    "/images/brand/Logo/JobHaiTransparent.png"
  );
  const [bgImage, setBgImage] = useState("/images/bg/bg-00001.png");
  const [flipedFG, flipFG] = useState(false);
  const [fgImage, setFgImage] = useState("/images/fg/fg-00003.png");
  const [fgImageRight, setFgRightImage] = useState("");
  const [btnColor, setBtnColor] = useState("bg-teal-600 text-white");
  const [styles, setStyles] = useState({
    foregroundWidth: "300",
    foregroundHeight: "300",
    fgLeft: "0",
    fgBottom: "0",
    headingSize: "26",
    subHeadingSize: "22",
    btnWeight: "600",
    headingMaxWidth: "480"
  });
  const [compressingImageState, setCompressingImageState] = useState(false);
  useEffect(() => {
    if (layout == "centre") {
      setStyles({ ...styles, foregroundWidth: "200", foregroundHeight: "200" });
    } else {
      setStyles({ ...styles, foregroundWidth: "300", foregroundHeight: "300" });
    }
  }, [layout]);
  const [isOpen, setIsOpen] = useState({
    foreground: false,
    background: false
  });
  const handleOpen = type => {
    setIsOpen(prev => ({
      ...prev,
      [type]: !prev[type] // Toggle the value for the specified type
    }));
  };
  const remainingCharsForSubheading = MAX_LENGTH - heading.length;

  const handleClose = () => {
    setIsOpen({ foreground: false, background: false });
  };
  const ref = useRef(null);
  const ref2 = useRef(null);
  const onButtonClick = useCallback(async () => {
    setCompressingImageState(true);
    if (ref.current === null) {
      return;
    }
    const scale = 1; // Account for zoom level
    const canvas = await html2canvas(ref.current, {
      scale: scale,
      useCORS: true
    });
    const compressImage = async quality => {
      return new Promise(resolve => {
        canvas.toBlob(
          blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(blob);
          },
          "image/webp",
          quality
        );
      });
    };
    let quality = 1.0;
    let compressedDataURL = await compressImage(quality);

    // Check if the image size is below 50KB and reduce quality if needed
    while (compressedDataURL.length > 50 * 1024 && quality > 0.1) {
      quality -= 0.01;
      compressedDataURL = await compressImage(quality);
    }
    setCompressingImageState(false);
    downloadjs(compressedDataURL, "download.webp", "image/webp");
  }, [ref]);

  const onButtonClick2 = useCallback(async () => {
    if (ref2.current === null) {
      return;
    }

    const canvas = await html2canvas(ref2.current);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "download.png", "image/png");
  }, [ref2]);

  function setLayoutAndRef(layout) {
    setLayout(layout);
  }

  useEffect(() => {
    const firstRef = document.getElementById(`${layout}-1`);
    const secondRef = document.getElementById(`${layout}-2`);
    ref.current = firstRef;
    ref2.current = secondRef;
  }, [layout]);

  const handleHeadingChange = e => {
    if (e.target.value.length <= MAX_LENGTH - subHeading.length) {
      setHeading(e.target.value);
    }
  };

  // Handle subheading input change
  const handleSubHeadingChange = e => {
    if (e.target.value.length <= remainingCharsForSubheading) {
      setSubheading(e.target.value);
    }
  };
  return (
    <>
      <main>
        <Header
          onButtonClick={onButtonClick}
          compressingImageState={compressingImageState}
          onButtonClick2={onButtonClick2}
        />
        <section className="grid w-full grid-cols-8 gap-6 p-6">
          <SideBar
            setBrandImage={setBrandImage}
            handleOpen={handleOpen}
            isOpen={isOpen}
            handleClose={handleClose}
            setBgImage={setBgImage}
            setFgImage={setFgImage}
            setBtnColor={setBtnColor}
            heading={heading}
            subHeading={subHeading}
            layout={layout}
            setLayoutAndRef={setLayoutAndRef}
            flipFG={flipFG}
            flipedFG={flipedFG}
            setAction={setAction}
            handleSubHeadingChange={handleSubHeadingChange}
            handleHeadingChange={handleHeadingChange}
            action={action}
            styles={styles}
            setStyles={setStyles}
            brandImage={brandImage}
            setJobHaiLogo={setJobHaiLogo}
          />
          <MainView
            layout={layout}
            ref={ref}
            ref2={ref2}
            bgImage={bgImage}
            brandImage={brandImage}
            fgImage={fgImage}
            flipedFG={flipedFG}
            heading={heading}
            subHeading={subHeading}
            btnColor={btnColor}
            action={action}
            fgImageRight={fgImageRight}
            styles={styles}
            jobHaiLogo={jobHaiLogo}
          />
        </section>
      </main>
    </>
  );
}

export default LCSImageGen;

const Header = ({ onButtonClick2, onButtonClick, compressingImageState }) => {
  return (
    <header className="flex items-center justify-between p-4 border-b bg-background">
      <div className="flex items-center gap-4">
        <PenIcon className="w-6 h-6" />
        <h1 className="text-xl font-bold">LCS Banner Designer</h1>
      </div>
      <div className="flex items-center gap-4">
        {/* <Button variant="outline" size="sm" onClick={onButtonClick2}>
          <DownloadIcon className="w-4 h-4 mr-2" />
          Download Rounded
        </Button> */}
        <Button variant="outline" size="sm" onClick={onButtonClick}>
          <DownloadIcon className="w-4 h-4 mr-2" />
          {compressingImageState ? "Processing Image..." : "Download"}
        </Button>
        {/*<Button variant='default' size='sm'>
            <ShareIcon className='w-4 h-4 mr-2' />
            Share
          </Button>*/}
      </div>
    </header>
  );
};

const ImageUploader = ({ label, onUpload }) => {
  // Function to generate random name for the image
  const generateRandomName = () => {
    return "Fg-" + Math.floor(100000 + Math.random() * 900000); // Random number between 100000 and 999999
  };

  // Handle file upload
  const handleFileUpload = event => {
    const file = event.target.files[0];

    if (file) {
      const randomName = generateRandomName();
      const fileURL = URL.createObjectURL(file); // Creating a local URL for the uploaded image

      // Create new asset and add to state
      const newAsset = {
        name: randomName,
        link: fileURL
      };

      // Push new asset into the passed `assets` ref
      onUpload(newAsset);
    }
  };

  return (
    <div className="flex flex-col items-start">
      {/* Label for the file input */}
      {/* {label && (
        <label className="mb-2 text-sm font-medium text-gray-700">
          {label}
        </label>
      )} */}

      {/* File input with custom styles */}
      <input
        type="file"
        accept="image/*"
        id="file-upload"
        onChange={handleFileUpload}
        className="hidden"
      />
      <label
        htmlFor="file-upload"
        className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
      >
        {label}
      </label>
    </div>
  );
};

const SideBar = ({
  setBrandImage,
  handleOpen,
  isOpen,
  handleClose,
  setBgImage,
  setFgImage,
  setBtnColor,
  heading,
  subHeading,
  layout,
  setLayoutAndRef,
  flipFG,
  flipedFG,
  setAction,
  handleSubHeadingChange,
  handleHeadingChange,
  action,
  styles,
  setStyles,
  brandImage,
  setJobHaiLogo
}) => {
  const setStyleObject = (label, value) => {
    setStyles({ ...styles, [label]: value });
  };
  const foreGrounds = useRef([...assets.fg]);
  return (
    <Card className="col-span-3 ">
      <CardHeader>
        <CardTitle>Make Banner</CardTitle>
        <CardDescription>
          Enter details below to make your banner
        </CardDescription>
        <CardContent className="px-0 ">
          <div className="mt-4 space-y-3 ">
            <div>
              <Label htmlFor="Layout">JobHai logo</Label>
              <div className="flex w-full mt-2 items-center gap-2">
                <Select
                  onValueChange={val => {
                    setJobHaiLogo(val);
                  }}
                >
                  <SelectTrigger className="w-[360px]">
                    <SelectValue placeholder="Job Hai Transparent" />
                  </SelectTrigger>
                  <SelectContent>
                    {assets.jobHaiLogo.map(b => {
                      return <SelectItem value={b.link}>{b.name}</SelectItem>;
                    })}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div>
              <Label htmlFor="Layout">Brand logo</Label>
              <div className="flex w-full mt-2 items-center gap-2">
                <Select
                  onValueChange={val => {
                    setBrandImage(val);
                  }}
                >
                  <SelectTrigger className="w-[360px]">
                    <SelectValue placeholder={assets.brand[0]?.name} />
                  </SelectTrigger>
                  <SelectContent>
                    {assets.brand.map(b => {
                      return <SelectItem value={b.link}>{b.name}</SelectItem>;
                    })}
                    <SelectItem value="upload">Custom Image</SelectItem>
                  </SelectContent>
                </Select>
                {brandImage === "upload" && (
                  <ImageUploader
                    label="Upload Brand"
                    onUpload={o => {
                      setBrandImage(o.link);
                    }}
                  />
                )}
              </div>
            </div>
            <div></div>

            <Label htmlFor="Layout">Adjust Images</Label>
            <div className="grid grid-cols-2 gap-3 max-w-[360px]">
              <Dialog>
                <DialogTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    className="mb-4"
                    onClick={() => handleOpen("background")}
                  >
                    Select Background
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[625px]">
                  <DialogHeader>
                    <DialogTitle>Select Background Image</DialogTitle>
                  </DialogHeader>
                  <DialogClose>
                    <div className="grid grid-cols-4 gap-4 p-1 max-h-98 overflow-y-auto">
                      {assets.bg.map(img => {
                        let link = img.link;

                        return (
                          <img
                            onClick={() => {
                              setBgImage(link);
                            }}
                            src={link}
                            className="col-span-1 cursor-pointer "
                            key={`Background${link}`}
                          />
                        );
                      })}
                    </div>
                  </DialogClose>
                </DialogContent>
              </Dialog>

              <Dialog>
                <DialogTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    className="mb-4"
                    onClick={() => handleOpen("foreground")}
                  >
                    Select Foreground
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[825px]">
                  <DialogHeader>
                    <DialogTitle>Select Foreground Image</DialogTitle>
                  </DialogHeader>
                  <DialogClose>
                    <div className="grid grid-cols-4 gap-4 p-1 max-h-96 overflow-y-auto">
                      {foreGrounds.current.map(img => {
                        let link = img.link;
                        return (
                          <img
                            onClick={() => {
                              setFgImage(link);
                            }}
                            src={link}
                            className="col-span-1 cursor-pointer"
                            key={`Foreground${link}`}
                          />
                        );
                      })}
                    </div>
                  </DialogClose>
                </DialogContent>
              </Dialog>
              <ImageUploader
                label="Upload Foregrounds"
                onUpload={asset => {
                  // foreGrounds.current.push(asset);
                  setFgImage(asset.link);
                  handleOpen("foreground");
                }}
              />
              <NumberControl
                label="Foreground Image Width"
                defaultValue={styles.foregroundWidth}
                onValueChange={v => setStyleObject("foregroundWidth", v)}
              />
              <NumberControl
                label="Foreground Image Height"
                defaultValue={styles.foregroundHeight}
                onValueChange={v => setStyleObject("foregroundHeight", v)}
              />

              {/* <NumberControl
                label="Foreground Left"
                defaultValue={styles.fgLeft}
                onValueChange={v => setStyleObject("fgLeft", v)}
              />
              <NumberControl
                label="Foreground Bottom"
                defaultValue={styles.fgBottom}
                onValueChange={v => setStyleObject("fgBottom", v)}
              /> */}
              <NumberControl
                label="Heading Size"
                defaultValue={styles.headingSize}
                onValueChange={v => setStyleObject("headingSize", v)}
              />
              <NumberControl
                label="SubHeading size"
                defaultValue={styles.subHeadingSize}
                onValueChange={v => setStyleObject("subHeadingSize", v)}
              />
              <NumberControl
                label="Button Weight"
                defaultValue={styles.btnWeight}
                onValueChange={v => setStyleObject("btnWeight", v)}
                jumpSize={100}
              />
              <NumberControl
                label="Heading Width"
                defaultValue={styles.headingMaxWidth}
                onValueChange={v => setStyleObject("headingMaxWidth", v)}
              />
            </div>
            <div>
              <Label htmlFor="Layout Alignment">Layout</Label>
              <div className="flex w-full mt-2">
                <ToggleGroup
                  value={layout}
                  onValueChange={val => setLayoutAndRef(val)}
                  type="single"
                >
                  <ToggleGroupItem variant="outline" value="left">
                    <AlignLeftIcon size={16} />
                  </ToggleGroupItem>
                  <ToggleGroupItem variant="outline" value="right">
                    <AlignRightIcon size={16} />
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
            </div>
            <div>
              <Label htmlFor="Layout">Flip Foreground Image</Label>
              <div className="flex w-full mt-2">
                <Toggle
                  onClick={() => {
                    flipFG(!flipedFG);
                  }}
                  variant="outline"
                >
                  <FlipHorizontalIcon size={16} />
                </Toggle>
              </div>
            </div>
            <div className=" max-w-[360px]">
              <Label htmlFor="Heading">Heading</Label> ({heading.length}{" "}
              characters)
              <Input
                maxLength="80"
                value={heading}
                onChange={e => handleHeadingChange(e)}
                placeholder="Heading"
              />
            </div>
            <div className=" max-w-[360px]">
              <Label htmlFor="Subheading">Subheading</Label> (
              {subHeading.length} characters)
              <Textarea
                maxLength="80"
                value={subHeading}
                onChange={e => handleSubHeadingChange(e)}
                placeholder="Subheading"
              />
            </div>
            <div className=" max-w-[360px]">
              <Label htmlFor="Action">Button</Label>
              <Input
                value={action}
                onChange={e => setAction(e.target.value)}
                placeholder="Apply Now"
              />
            </div>

            <div className=" max-w-[360px]">
              <Label htmlFor="Button Color">Button Color</Label>
              <div className="flex items-center gap-2 mt-3">
                {assets.button.map(bg => {
                  let color = bg.color;

                  return (
                    <div
                      onClick={() => {
                        setBtnColor(color);
                      }}
                      className={`size-8 rounded-full border ${color}`}
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>
        </CardContent>
      </CardHeader>
    </Card>
  );
};

const MainView = ({
  layout,
  ref,
  ref2,
  bgImage,
  brandImage,
  fgImage,
  flipedFG,
  heading,
  subHeading,
  btnColor,
  action,
  fgImageRight,
  styles,
  jobHaiLogo
}) => {
  const [borderRounded, setBorder] = useState(false);
  return (
    <Card className="col-span-5 ">
      <CardHeader className="flex flex-row items-center gap-2">
        <CardTitle>Preview - Normal</CardTitle>
        <Button onClick={() => setBorder(!borderRounded)}>
          {borderRounded ? `Edged Border` : `Rounded Border`}
        </Button>
      </CardHeader>
      <CardContent>
        <div className="parent max-w-fit">
          {layout === "left" ? (
            <div
              id={`${layout}-1`}
              ref={ref}
              style={{ backgroundImage: `url('${bgImage}')` }}
              className={cx(
                `h-[400px] w-[720px] bg-cover flex flex-col py-6 justify-center relative `,
                borderRounded ? `rounded-2xl` : ``
              )}
            >
              <Draggable bounds=".parent" grid={[1, 1]}>
                <div className="flex align-middle absolute top-8 left-12">
                  <img
                    src={jobHaiLogo}
                    className="w-14 mr-4  cursor-pointer"
                    alt="logo"
                  />
                  <div className="h-14 border-solid border-r-[1px] border-[#000] opacity-10" />
                  <img
                    alt="logo2"
                    src={brandImage}
                    className="w-14 ml-4  cursor-pointer"
                  />
                </div>
              </Draggable>
              <Draggable bounds=".parent" grid={[1, 1]}>
                <div className="absolute bottom-0 right-6 ">
                  <img
                    alt="foreground"
                    style={{
                      width: `${styles.foregroundWidth}px`,
                      height: `${styles.foregroundHeight}px`
                    }}
                    src={fgImage}
                    className={`w-[300px] cursor-pointer ${
                      flipedFG ? "scale-x-[-1]" : null
                    }  `}
                  />
                </div>
              </Draggable>
              <div className="pl-11">
                <Draggable bounds=".parent" grid={[5, 5]}>
                  <h1
                    style={{
                      fontSize: `${styles.headingSize}px`,
                      maxWidth: `${styles.headingMaxWidth}px`
                    }}
                    className=" max-w-[480px] text-4xl font-bold text-left cursor-pointer"
                  >
                    {heading}
                  </h1>
                </Draggable>
                <Draggable bounds=".parent" grid={[5, 5]}>
                  <p
                    style={{ fontSize: `${styles.subHeadingSize}px` }}
                    className="mt-2 text-xl text-left cursor-pointer "
                  >
                    {subHeading}
                  </p>
                </Draggable>
                <Draggable bounds=".parent">
                  <div
                    style={{ fontWeight: `${styles.btnWeight}` }}
                    className={`px-6 py-2.5 mt-8 text-[24px] cursor-pointer ${btnColor} rounded-full w-fit`}
                  >
                    {action}
                  </div>
                </Draggable>
              </div>
            </div>
          ) : (
            <div
              id={`${layout}-1`}
              ref={ref}
              style={{ backgroundImage: `url('${bgImage}')` }}
              className={cx(
                `h-[400px] w-[720px] bg-cover flex flex-col py-6 justify-center items-end relative `,
                borderRounded ? `rounded-2xl` : ``
              )}
            >
              <Draggable bounds=".parent" grid={[1, 1]}>
                <div className="flex align-middle absolute top-8 right-12">
                  <img
                    src={jobHaiLogo}
                    className="w-14 mr-4  cursor-pointer"
                    alt="logo"
                  />
                  <div className="h-14 border-solid border-r-[1px] border-[#000] opacity-10" />
                  <img
                    alt="logo2"
                    src={brandImage}
                    className="w-14 ml-4  cursor-pointer"
                  />
                </div>
              </Draggable>
              <Draggable bounds=".parent" grid={[1, 1]}>
                <div
                  style={{ bottom: styles.fgBottom, left: styles.fgLeft }}
                  className="absolute cursor-pointer  "
                >
                  <img
                    alt="foreground"
                    src={fgImage}
                    style={{
                      width: `${styles.foregroundWidth}px`,
                      height: `${styles.foregroundHeight}px`
                    }}
                    className={` ${flipedFG ? "scale-x-[-1]" : null}`}
                  />
                </div>
              </Draggable>

              <div className="flex flex-col items-end pr-11 cursor-pointer">
                <Draggable bounds=".parent" grid={[5, 5]}>
                  <h1
                    style={{
                      fontSize: `${styles.headingSize}px`,
                      maxWidth: `${styles.headingMaxWidth}px`
                    }}
                    className="   font-bold text-right"
                  >
                    {heading}
                  </h1>
                </Draggable>
                <Draggable bounds=".parent" grid={[5, 5]}>
                  <p
                    style={{ fontSize: `${styles.subHeadingSize}px` }}
                    className="mt-2 text-right cursor-pointer "
                  >
                    {subHeading}
                  </p>
                </Draggable>
                <Draggable bounds=".parent">
                  <div
                    style={{ fontWeight: `${styles.btnWeight}` }}
                    className={`px-6 py-2.5 mt-8 text-[24px] text-white bg-teal-600 ${btnColor} rounded-full w-fit cursor-pointer`}
                  >
                    {action}
                  </div>
                </Draggable>
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const NumberControl = ({
  label,
  defaultValue,
  onValueChange,
  jumpSize = 1
}) => {
  const [value, setValue] = useState(defaultValue);

  // Handler to update the value and call the callback
  const handleValueChange = newValue => {
    setValue(newValue);
    onValueChange(newValue); // Pass the updated value to the parent
  };

  // Handler for directly typed input
  const handleInputChange = event => {
    const inputValue = Number(event.target.value);
    handleValueChange(isNaN(inputValue) ? 0 : inputValue);
  };

  // Handle arrow keys for increasing and decreasing the value
  const handleKeyDown = event => {
    if (event.key === "ArrowUp") {
      handleValueChange(Number(value) + jumpSize);
    } else if (event.key === "ArrowDown") {
      handleValueChange(Number(value) - jumpSize);
    }
  };

  return (
    <div style={styles.container}>
      <label style={styles.label}>{label}</label>
      <div style={styles.controlBox}>
        <button
          style={styles.button}
          onClick={() => handleValueChange(Number(value) - jumpSize)}
        >
          -
        </button>
        <input
          type="number"
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown} // Handle keyboard input
          style={styles.inputBox}
        />
        <button
          style={styles.button}
          onClick={() => handleValueChange(Number(value) + jumpSize)}
        >
          +
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0"
  },
  label: {
    marginRight: "10px",
    fontWeight: "bold"
  },
  controlBox: {
    display: "flex",
    alignItems: "center"
  },
  button: {
    padding: "5px",
    cursor: "pointer",
    fontSize: "18px"
  },
  inputBox: {
    border: "1px solid #ccc",
    padding: "5px",
    margin: "0 5px",
    minWidth: "10px",
    textAlign: "center",
    maxWidth: "40px"
  }
};
