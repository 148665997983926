import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import SelectCity from "../../Common/SelectCity";
import MetaData from "../../../constants/MetaData";

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      rec_id: "",
      company_name: "",
      recruiter_name: "",
      phone: "",
      city_id: "",
      is_email_verified: "",
      verification_status: "NEW",
      edate: "",
      sdate: "",
      document_status: "",
      recruiter_calling_status: "NEW",
      msgData: { msg: "", showMsg: false, msgType: "error" },
      jobPlan: ""
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  searchRecruiter = e => {
    e.preventDefault();
    const {
      document_status,
      rec_id,
      recruiter_name,
      phone,
      company_name,
      city_id,
      is_email_verified,
      verification_status,
      recruiter_calling_status,
      sdate,
      edate,
      jobPlan
    } = this.state;
    const obj = {
      recruiter_id: rec_id,
      recruiter_name,
      phone,
      company_name,
      city_id,
      document_status,
      verification_status,
      recruiter_calling_status,
      is_email_verified: is_email_verified,
      posted_date_start: sdate ? moment(sdate).format("YYYY-MM-DD") : "",
      posted_date_end: edate ? moment(edate).format("YYYY-MM-DD") : "",
      jobPlan
    };

    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        delete obj[key];
      }
    });
    this.props.searchRecruiters(obj);
  };

  render() {
    const { isButtonDisabled } = this.props;
    const { jobPlan } = this.state;
    const recStatusList = MetaData.recruiterStatusVirtual;
    return (
      <div className="job_seeker recruiter-search">
        <div className="gap-20"></div>
        <form
          autoComplete="off"
          onSubmit={this.searchRecruiter}
          className="search"
        >
          <div className="row">
            <div className="col-md-2">
              <input
                className="form-control"
                type="text"
                name="rec_id"
                placeholder="Recruiter ID"
                value={this.state.rec_id}
                onChange={event =>
                  this.setState({
                    rec_id: event.target.value.replace(/\D/, "")
                  })
                }
                maxLength="10"
              />
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="company_name"
                  placeholder="Company Name"
                  value={this.state.company_name}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="recruiter_name"
                  placeholder="Recruiter Name"
                  value={this.state.recruiter_name}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  placeholder="Recruiter Phone no"
                  value={this.state.phone}
                  onChange={event =>
                    this.setState({
                      phone: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <SelectCity
                filedName={"city_id"}
                value={this.state.city_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="is_email_verified"
                value={this.state.is_email_verified}
                onChange={this.handleChange}
              >
                <option value={null}>Select Email Status</option>
                <option value={1}>Verified</option>
                <option value={0}>Not Verified</option>
              </select>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="document_status"
                  value={this.state.document_status}
                  onChange={this.handleChange}
                >
                  <option value="">Select Doc Status</option>
                  <option value="no_document">No Document</option>
                  <option value="under_review">"New"</option>
                  <option value="verified">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="verification_status"
                value={this.state.verification_status}
                onChange={this.handleChange}
              >
                <option value="">Select Rec Status</option>
                {recStatusList.map(option => (
                  <option value={option.value} key={`recS${option.value}`}>
                    {option.value === "NEW" ? "Under Review" : option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="recruiter_calling_status"
                value={this.state.recruiter_calling_status}
                onChange={this.handleChange}
              >
                <option value="">Select Rec Calling Status</option>
                <option value="NEW">Rec Calling Status - New</option>
                <option value="ATTEMPT_1">Attempt 1</option>
                <option value="ATTEMPT_2">Attempt 2</option>
                <option value="ATTEMPT_3">Attempt 3</option>
              </select>
            </div>
            <div className="col-md-6">
              <div className="date-lable padding15">List Inflow Date From:</div>
              <div className="date-field">
                <DatePicker
                  selected={this.state.sdate}
                  onChange={value => {
                    this.setState({ sdate: value });
                  }}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
              </div>
              <div className="date-lable padding15">To:</div>
              <div className="date-field">
                <DatePicker
                  selected={this.state.edate}
                  onChange={value => {
                    this.setState({ edate: value });
                  }}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-2">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="jobPlan"
                value={jobPlan}
                onChange={this.handleChange}
              >
                <option value="">Select Job Type</option>
                {MetaData.premiumJobType.map(option => (
                  <option value={option.value} key={`premium${option}`}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-10">
              <div className="float-right">
                {isButtonDisabled ? (
                  <button className="btn btn-primary disable" disabled>
                    SEARCHING ...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={this.searchRecruiter}
                  >
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
      </div>
    );
  }
}
export default Filter;
