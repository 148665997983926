import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { getFieldName } from "../../services/utils";

const EditedFieldList = props => {
  const { editedField, showEditedLabel = true } = props;
  return (
    <div className="editedContainer">
      <img src="/images/warning.svg" width="16" height="16" alt="edited" />

      {editedField.length > 1 ? (
        <Dropdown>
          <Dropdown.Toggle variant="success" className="item active">
            {getFieldName(editedField[0])} {showEditedLabel ? "edited" : ""}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {editedField.map((item, i) => (
              <Dropdown.Item key={i} as="button">
                {getFieldName(item)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        `${getFieldName(editedField[0])} ${showEditedLabel ? "edited" : ""}`
      )}
    </div>
  );
};

export default EditedFieldList;
