import CreditWidget from "../../componentsV2/local/CreditWidget";
import { checkPermission, getFormattedValue } from "../../services/utils";
import withRouter from "../../services/withRouter";
import Modal from "react-bootstrap/Modal";

function TransactionHistorySearch(props) {
  const {
    onSearch,
    formData,
    setFormData,
    showPopup,
    setShowPopup,
    recruiterData: { data: recData } = {},
    view
  } = props;
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let state = formData || {};
    state = { ...formData, [name]: getFormattedValue(value, true, true) };
    setFormData(state);
  };

  const search = e => {
    e.preventDefault();
    onSearch(formData);
  };
  const addCreditsVisible =
    checkPermission("Sales User") || checkPermission("Sales User Admin");
  return (
    <>
      <div className="d-flex justify-between items-center">
        <form className="d-flex flex-row search w-75 mt-2 align-items-center">
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="recruiterId"
                placeholder="Recruiter Id "
                value={formData.recruiterId}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-1 text-center">OR</div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="recruiterPhone"
                placeholder="Recruiter Phone "
                value={formData.recruiterPhone}
                onChange={handleChange}
                maxLength={10}
              />
            </div>
          </div>
          <button className="btn btn-primary" onClick={search}>
            SEARCH
          </button>
        </form>
        {recData &&
          view !== "creditReserved" &&
          recData.totalJobsPosted == 0 &&
          addCreditsVisible && (
            <button
              className="btn btn-primary"
              onClick={e => {
                e.preventDefault();
                setShowPopup(!showPopup);
              }}
            >
              Add Credits
            </button>
          )}
      </div>
      <Modal show={showPopup} onHide={() => setShowPopup(!showPopup)}>
        <Modal.Body>
          <CreditWidget recId={recData?.recruiterId} showGetLink={false} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withRouter(TransactionHistorySearch);
