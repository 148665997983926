import MaterialInputTextField from "../../materialInputTextField";
import classes from "./lcsWalkinCampaignDetails.module.css";
import { daysOfWeek } from "../../../services/constant";
import SelectDropdown from "../../ReactSelect";
import MultiDatePicker from "../../MultiDatePicker";
import Heading from "../../Common/Form/Heading";
const DateTimeRange = props => {
  const { method } = props;
  const { control, watch, setValue } = method;
  const interviewStartDate = watch(
    `interview_date_details.interview_start_date`
  );
  const interviewEndDate = watch(`interview_date_details.interview_end_date`);
  const startEndDateEntered = !!interviewStartDate && !!interviewEndDate;
  const today = new Date().toISOString().split("T")[0];

  const validateDateExclusion = value => {
    const exludedDate = value.map(date =>
      typeof date === "object" ? date?.format?.("YYYY-MM-DD") : date
    );
    if (exludedDate.some(date => new Date(date) > new Date(interviewEndDate))) {
      return "Exlcuded date cannot be greater than interview end date";
    }
    return true;
  };

  const maxDateForExlusion = new Date(interviewEndDate);
  maxDateForExlusion.setDate(maxDateForExlusion.getDate() + 1);
  // due to a bug in library (not able to select max end date in multi picker).
  return (
    <>
      <Heading heading="Date and Time Range" />
      <div className={classes.fieldsGridClass}>
        <MaterialInputTextField
          name={`interview_date_details.interview_start_date`}
          label="Interview Start Date"
          control={control}
          rules={{ required: "Interview start date is required" }}
          fullWidth={false}
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            min: today
          }}
        />
        <MaterialInputTextField
          name={`interview_date_details.interview_end_date`}
          label="Interview End Date"
          control={control}
          rules={{ required: "Interview end date is required" }}
          fullWidth={false}
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            min: interviewStartDate || today
          }}
        />
        <MaterialInputTextField
          name={`interview_date_details.start_time`}
          label="Interview Start Time"
          control={control}
          rules={{ required: "Interview start time is required" }}
          fullWidth={false}
          type="time"
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            min: today
          }}
        />
        <MaterialInputTextField
          name={`interview_date_details.end_time`}
          label="Interview End Time"
          control={control}
          rules={{ required: "Interview end time is required" }}
          fullWidth={false}
          type="time"
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            min: today
          }}
        />
        <SelectDropdown
          name={`interview_date_details.excluded_week_days`}
          label="Exclude Week Days"
          control={control}
          options={daysOfWeek}
          fullWidth={false}
          idKey="id"
          closeMenuOnSelect={false}
          isMulti={true}
          isDisabled={!startEndDateEntered}
        />
        <MultiDatePicker
          control={control}
          name={"interview_date_details.excluded_dates"}
          label={"Exclude Dates"}
          fullWidth={false}
          minDate={new Date(interviewStartDate)}
          maxDate={maxDateForExlusion}
          disabled={!startEndDateEntered}
          validate={validateDateExclusion}
          setValue={setValue}
          scrollSensitive={false}
          calendarPosition={"top-start"}
        />
      </div>
    </>
  );
};
export default DateTimeRange;
