import React from "react";

const ViewOldStatus = props => {
  const { oldStatusList = {}, viewLog = false } = props;
  const { crm_cleaning_status, crm_calling_status, crm_qa_status } =
    oldStatusList;
  return (
    <div className="view-history oldHistory">
      <div className={viewLog ? "tableContainer" : null}>
        <table className="table borderless">
          <tbody>
            <tr className="listvalue2">
              <th>CRM Cleaning Status:</th>
              <td>{crm_cleaning_status}</td>
            </tr>
            <tr className="listvalue listvalue2">
              <th>CRM Calling Status:</th>
              <td>{crm_calling_status}</td>
            </tr>
            <tr className="listvalue listvalue2">
              <th>CRM QA Status:</th>
              <td>{crm_qa_status}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewOldStatus;
