import MetaData from "../../constants/MetaData";
import Label from "../Common/Form/Label";
import Chips from "../Common/Chips";
import { getNthItemText, getActiveItemList } from "../../services/utils";

const CallingAttempt = props => {
  const {
    callStatusValue = {},
    attemtList = [],
    handleCallStatus,
    isDisabled,
    oldCallAttemptCount = 0,
    isClose = true
  } = props;

  const optionsList = MetaData.CallingAttempt;
  const handleChips = (e, fieldName, item) => {
    const { id } = item;
    handleCallStatus(fieldName, callStatusValue[fieldName] === id ? "NEW" : id);
  };
  function noClick() {
    //
  }

  function renderChipsHTML(item, disabledClick) {
    const selectedValue = callStatusValue[item];
    const activeChips = getActiveItemList(optionsList, selectedValue);
    const isNew = selectedValue === "NEW";
    return (
      <Chips
        chips={isNew ? optionsList : [activeChips]}
        activeChips={activeChips}
        onClick={disabledClick ? noClick : handleChips}
        isMulti={false}
        fieldName={item}
        isClose={isClose}
        isDisabled={isDisabled}
      />
    );
  }
  // Convert object keys to an array
  const keysArray = Object.keys(callStatusValue);
  return (
    <div className="formRow">
      <div className="fullWidth">
        {attemtList.map((item, index) => {
          const preSelectedValue = callStatusValue[keysArray[index - 1]];
          let nexSelectedValue = "NEW";
          if (index + 1 < attemtList.length) {
            nexSelectedValue = callStatusValue[keysArray[index + 1]];
          }
          const disabledClick = nexSelectedValue !== "NEW";
          const indexCount = oldCallAttemptCount + index;
          return (
            <div className="callingList" key={`attempt${index}`}>
              {index === 0 ? (
                <>
                  <Label
                    lable={`${indexCount + 1}${getNthItemText(
                      indexCount
                    )} Call Attempt`}
                  />
                  {renderChipsHTML(item, disabledClick)}
                </>
              ) : (
                <>
                  {preSelectedValue !== "NEW" ? (
                    <>
                      <Label
                        lable={`${indexCount + 1}${getNthItemText(
                          indexCount
                        )} Call Attempt`}
                      />
                      {renderChipsHTML(item, disabledClick)}
                    </>
                  ) : null}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CallingAttempt;
