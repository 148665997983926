import React from "react";

function EditLink(props) {
  const { isAllowed = true, url, id } = props;
  return (
    <>
      {isAllowed ? (
        <a
          href={url}
          className="primary"
          title={id}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-pencil-alt"></i>
        </a>
      ) : (
        <i className="fas fa-pencil-alt"></i>
      )}
    </>
  );
}
export default EditLink;
