import { useState } from "react";
import JobHai from "../../api/JobHai";
import { getErrorMessage } from "../../services/utils";
import LeadReAssign from "./LeadReAssign";

const LeadSharingStatus = props => {
  const [isForm, SetForm] = useState(false);
  const [leadIds, setLeadIds] = useState("");
  const [errorMsg, handleError] = useState({ type: "", msg: "" });
  const ctaCopy = isForm ? "Hide Lead Share Status" : "Show Lead Share Status";

  function handleChange(event) {
    handleError({ type: "", msg: "" });
    const leads = event.target.value.replace(/[^0-9,]/g, "");
    setLeadIds(leads);
  }
  function updateLeadStatus() {
    handleError({ type: "", msg: "" });
    // TODO API Route need to be change
    handleError({ type: "success", msg: "Data Saved" });
    const leadsArr = leadIds.split(",").map(Number);
    JobHai.post("/lcs-leads", leadsArr)
      .then(response => {
        handleError({ type: "success", msg: "Data Saved" });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        handleError({ type: "error", msg });
      });
  }
  const { selectedLead, getUpdatedLeads, leadModeratorList } = props;
  const btnDisabled = !leadIds.length;
  const { type, msg } = errorMsg;
  return (
    <div className="row job_seeker form-group">
      <div className="col-md-12 form-group">
        <div className="float-right">
          <LeadReAssign
            selectedLead={selectedLead}
            getUpdatedLeads={getUpdatedLeads}
            leadModeratorList={leadModeratorList}
          />
          <div className="download_link" onClick={() => SetForm(!isForm)}>
            {ctaCopy}
          </div>
        </div>
      </div>
      {isForm ? (
        <>
          <div className="col-md-10">
            <input
              type="text"
              name="leadIds"
              className="form-control"
              value={leadIds}
              onChange={handleChange}
              placeholder="Please enter comma seperated lead id's"
            />
          </div>
          <div className="col-md-2">
            <div className="float-right">
              <button
                className="btn btn-primary"
                disabled={btnDisabled}
                onClick={updateLeadStatus}
              >
                Update Status
              </button>
            </div>
          </div>
          {type ? (
            <div className="col-md-12">
              <div className="float-right">
                <span
                  className={type === "error" ? "text-danger" : "text-success"}
                >
                  {msg}
                </span>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default LeadSharingStatus;
