const NoAccess = props => {
  return (
    <div style={{ textAlign: "center" }}>
      <span className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-triangle" aria-hidden="true" />
        You don't have access to view this page
      </span>
    </div>
  );
};
export default NoAccess;
