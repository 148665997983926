import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

class OperationTrackingFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sdate: "",
      edate: "",
      report_type: "",
      isDisabled: true
    };
  }

  updateMessage = (msg, flag, isReload = false, flagUpdate = false) => {
    this.props.statusMessage(msg, flag, isReload, flagUpdate);
  };

  handleSearch = e => {
    e.preventDefault();
    const { sdate, edate, report_type } = this.state;
    if (!sdate) {
      this.updateMessage("Please enter start date", 1);
      return;
    }
    if (!edate) {
      this.updateMessage("Please enter end date", 1);
      return;
    }
    if (sdate > edate) {
      this.updateMessage("Please enter start date is less than end date", 1);
      return;
    }
    const obj = {
      start_date: sdate ? moment(sdate).format("YYYY-MM-DD") : undefined,
      end_date: edate ? moment(edate).format("YYYY-MM-DD") : undefined,
      report_type: report_type
    };
    this.props.getReport(obj);
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      isDisabled: !value
    });
  };

  render() {
    const { isButtonDisabled, reportLink = "" } = this.props;
    const { sdate, edate, report_type, isDisabled } = this.state;
    return (
      <div className="job_seeker">
        <div className="gap-20"></div>
        <form
          autoComplete="off"
          onSubmit={this.handleSearch}
          className="search"
        >
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="report_type"
                  value={report_type}
                  onChange={this.handleChange}
                >
                  <option value="">Select Report Type</option>
                  <option value="unverified-recruiter-report">
                    Unverified Recruiters Report
                  </option>
                  <option value="verified-recruiter-report">
                    Verified Recruiters Report
                  </option>
                  <option value="moderator-recruiter-report">
                    Moderator Actions
                  </option>
                  <option value="recruiter-rejection-report">
                    Recruiter Rejection Report
                  </option>
                </select>
              </div>
            </div>
            <div className="col-md-5">
              <div className="date-lable padding15">Date From: </div>
              <div className="date-field">
                <DatePicker
                  selected={sdate}
                  onChange={value => {
                    this.setState({ sdate: value });
                  }}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  maxDate={new Date()}
                />
              </div>
              <div className="date-lable">To: </div>
              <div className="date-field">
                <DatePicker
                  selected={edate}
                  onChange={value => {
                    this.setState({ edate: value });
                  }}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="float-right">
                {isButtonDisabled && (
                  <button className="btn btn-primary disable" disabled>
                    Searching ...
                  </button>
                )}
                {!isButtonDisabled && (
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSearch}
                    disabled={isDisabled}
                  >
                    Get Report
                  </button>
                )}
                {reportLink ? (
                  <p>
                    <a
                      href={reportLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Report
                    </a>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </form>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
      </div>
    );
  }
}
export default OperationTrackingFilter;
