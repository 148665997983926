import React, { useState, useEffect } from "react";
//import { nl2br, wordWrap } from "../../services/utils";

const ViewRemarks = props => {
  const { remarks } = props;
  const [viewMore, updateState] = useState(false);

  useEffect(() => {
    if (remarks && remarks.length > 75) updateState(true);
  }, [remarks]);

  const handleViewMore = () => {
    updateState(false);
  };

  let string = remarks ? remarks : "";
  if (viewMore) {
    string = remarks.substr(0, 75);
  } else {
    string = remarks;
  }

  return (
    <div className="remarks">
      {viewMore ? (
        <>
          <span dangerouslySetInnerHTML={{ __html: string }} />
          ...
          <span className="viewmore" onClick={handleViewMore}>
            more
          </span>
        </>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: string }} />
      )}
    </div>
  );
};

export default ViewRemarks;
