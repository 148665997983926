import React from "react";
import LoadingSpinner from "../../LoadingSpinner";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import Filter from "./Filter";
import OrganicJobTab from "../../Common/OrganicJobTab";
import RecruiterList from "./RecruiterList";
import CustomePagination from "../../Common/Pagination";
import { getErrorMessage } from "../../../services/utils";
import RecBucket from "./RecBucket";
import withRouter from "../../../services/withRouter";
import TatSummary from "../../TatSummary";
import ProxyModeratorName from "../../Common/ProxyModeratorName";
import HeadingRow from "../../Common/HeadingRow";

class UnverifiedRecruiter extends React.Component {
  constructor(props) {
    super(props);
    const { params = {} } = props;
    const { role = "QA", type = "unverified" } = params;
    const search = window.location.search || "";
    const pathname = window.location.pathname || "";
    const queryParams = new URLSearchParams(search);
    const bucket = queryParams.get("bucket");
    this.state = {
      role,
      type,
      recruitersList: [],
      isLoading: true,
      isButtonDisabled: false,
      msgData: { msg: "", showMsg: false, msgType: "error" },
      activePage: 1,
      count: 0,
      itemsCountPerPage: 50,
      filters: { verification_status: "NEW", recruiter_calling_status: "NEW" },
      sectionData: {},
      pathname,
      bucket
    };
  }
  async componentDidMount() {
    this.getRecruiters();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters, activePage } = this.state;
    if (filters !== prevState.filters) {
      this.setState({
        filters: filters
      });
      this.getRecruiters();
    }
    if (activePage !== prevState.activePage) {
      this.setState({
        activePage: activePage
      });
      this.getRecruiters();
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }

  getRecruiters = () => {
    const { activePage, itemsCountPerPage, filters, role, bucket, type } =
      this.state;
    let sectionFilter = [];
    if (bucket) {
      sectionFilter =
        bucket === "review"
          ? "Under Review"
          : bucket === "nodoc"
          ? "No Document"
          : bucket === "verified"
          ? "Verified"
          : bucket === "followup"
          ? "Follow Ups"
          : bucket === "others"
          ? "Others"
          : bucket === "knowlarity"
          ? "Knowlarity"
          : bucket === "doc_company_update"
          ? "Doc Company Update"
          : bucket === "suspicious"
          ? "Suspicious"
          : undefined;
    }
    const tabType = type === "crawled" ? "CRAWLED" : "ORGANIC";
    let url = `/unverified-recruiters?type=${tabType}`;
    if (role) url = `${url}&role=${role}`;
    JobHai.post(url, {
      filters: bucket ? {} : filters,
      page: activePage - 1,
      size: itemsCountPerPage,
      section: bucket ? sectionFilter : undefined
    })
      .then(
        function (response) {
          const { sectionData = {}, count = 0, jobs = {} } = response.data.data;
          this.setState({
            recruitersList: jobs,
            isButtonDisabled: false,
            isLoading: false,
            count,
            sectionData,
            msgData: { msg: "", showMsg: false, msgType: "error" }
          });
        }.bind(this)
      )
      .catch(
        function (error) {
          let msg = getErrorMessage(error);
          this.setState({
            isButtonDisabled: false,
            isLoading: false,
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
          window.scrollTo(0, 0);
        }.bind(this)
      );
  };

  getSearchResult = objData => {
    this.setState({
      filters: objData,
      recruitersList: [],
      activePage: 1,
      isButtonDisabled: true,
      count: 0,
      msgData: {
        msg: "",
        showMsg: false,
        msgType: ""
      }
    });
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  render() {
    const {
      recruitersList,
      isButtonDisabled,
      count,
      activePage,
      itemsCountPerPage,
      role,
      isLoading,
      msgData,
      pathname,
      bucket,
      sectionData,
      type
    } = this.state;
    const gobackUrl = bucket ? pathname : `/jobs/`;
    const showTat = type === "unverified";
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <>
          <ProxyModeratorName />
          <PopupMessage data={msgData} click={this.handleClosePopup} />
          <div className="clr gap-20" />
          <div className="clr job-list">
            <HeadingRow headig="Organic Jobs" goBackURL={gobackUrl}>
              {!bucket ? <OrganicJobTab active={type} role={role} /> : null}
            </HeadingRow>
            {!bucket ? (
              <>
                <RecBucket pathname={pathname} sectionData={sectionData} />
                <Filter
                  isButtonDisabled={isButtonDisabled}
                  searchRecruiters={this.getSearchResult}
                />
              </>
            ) : (
              <div className="clr gap-20" />
            )}
            {showTat ? <TatSummary type="unverified" /> : null}
            <RecruiterList
              recruitersList={recruitersList}
              isSearching={isButtonDisabled}
              role={role}
            />
            <CustomePagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={count}
              onPageChange={this.handlePageChange.bind(this)}
              showPagination={recruitersList.length}
              showLimitedRow={true}
            />

            <div className="clr gap-20" />
          </div>
        </>
      );
    }
  }
}

export default withRouter(UnverifiedRecruiter);
