import JobHai from "../../api/JobHai";
import { checkPermission } from "../../services/utils";

const styles = theme => ({
  root: {
    color: "#414858",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    background: "#FFFFFF",
    border: "1px solid rgba(233, 236, 245, 0.6)",
    borderRadius: "10px",
    marginBottom: "20px",
    "& .flexRow": {
      display: "flex",
      flexDirection: "row"
    },
    "& .flexCol": {
      display: "flex",
      flexDirection: "column"
    },
    "& .heading": { marginBottom: "8px", fontWeight: 700 },
    "& .row": { margin: "0" },
    "& .row .col-md-4": { padding: "0 10px 0 0" },
    "& .row .col-md-6": { padding: "0 10px 0 0" },
    "& .row .col-md-8": { padding: "0", marginTop: "20px" },
    "& .row .col-md-12": { padding: "0", marginTop: "10px" },
    "& .formRow": { marginBottom: "8px" },
    "& .formRow .select": { minWidth: "auto" },
    "& .buttonContainer": {
      justifyContent: "flex-end"
    },
    "& .btn1": {
      width: "68px"
    },

    "& .btn2": {
      width: "82px",
      backgroundColor: "#ffffff",
      color: "#2a89f6",
      border: "1px solid #2a89f6",
      marginLeft: "4px"
    },
    "& .btn3": {
      width: "auto",
      marginTop: "10px",
      "& img": {
        marginRight: "4px"
      }
    },
    "& .btns": {
      height: "44px",
      padding: "0px"
    },
    "& .spaceBetween": {
      justifyContent: "space-between"
    },
    "& .justifyCenter": {
      justifyContent: "center"
    },
    "& .center": {
      alignItems: "center"
    },
    "& .flex": {
      display: "flex"
    },
    "& .container2Padding": {
      paddingRight: "12px"
    },
    "& .disabled": {
      opacity: 0.5
    },
    "& .loading": {
      marginBottom: "10px"
    },
    "& .text-danger": {
      marginRight: "10px"
    },
    "& .viewall": {
      marginLeft: "20px",
      color: "#2a89f6",
      textDecoration: "none"
    },
    "& .showAll": {
      margin: "5px 0 0 0",
      cursor: "pointer"
    },
    "& hr": {
      width: "100%",
      height: "1px",
      background: "#e9ecf5",
      margin: "24px 0"
    },
    "& .margintop": {
      marginTop: "10px"
    },
    "& .btn4": {
      width: "118px"
    },
    "& .mrAdjust": {
      marginRight: "4px"
    }
  },

  container3: {
    "& :first-child": {
      marginRight: "5px"
    }
  },
  padding: {
    padding: "16px 28px"
  },
  component1: {
    "& img": {
      marginRight: "9px"
    },
    "& .bottomSpacing": {
      marginBottom: "8px"
    }
  },
  header: {
    background: "rgba(233, 236, 245, 0.3)",
    border: "1px solid #F1F3F9",
    borderRadius: "10px",
    color: "#41485A",
    "& b": {
      marginRight: "18px"
    }
  },
  input: {
    width: "91px",
    color: "#2e2e36",
    fontWeight: "600",
    "&::placeholder": {
      color: "#8D9DC1",
      fontWeight: "400"
    }
  },
  jobLoader: { height: "16px", width: "40%" },
  successState: {
    display: "inline-block",
    fontWeight: "600",
    "& span": {
      color: "#2a89f6",
      fontStyle: "italic",
      display: "inline-block",
      marginLeft: "4px"
    },
    "& span i": {
      marginLeft: "4px",
      cursor: "pointer"
    }
  },
  errorState: {
    color: "red",
    fontStyle: "italic",
    display: "inline-block",
    fontWeight: "500"
  }
});

const formatResponse = data => {
  let showExpiringCredits = false;
  if (data.creditsDateOfExpiry) {
    const today = new Date();
    const expiryDate = new Date(data.creditsDateOfExpiry); // Convert string to Date object
    const differenceInMillis = expiryDate.getTime() - today.getTime();
    const differenceInDays = differenceInMillis / (1000 * 3600 * 24);
    showExpiringCredits = differenceInDays >= 0 && differenceInDays <= 7;
  }
  return {
    monthlyBasicJobsLeft: data.monthlyBasicJobsLeft || 0,
    totalAvailableCredits: data.totalAvailableCredits,
    showExpiringCredits,
    creditsGettingExpired: data.creditsGettingExpired,
    creditsDateOfExpiry: data.creditsDateOfExpiry,
    visible:
      checkPermission("Sales User") || checkPermission("Sales User Admin"),
    adminVisible: checkPermission("Sales User Admin")
  };
};
const getData = recId => {
  return new Promise((resolve, reject) => {
    JobHai.get(`/transactions/recruiter-profile?recruiterId=${recId}`)
      .then(res => {
        resolve(formatResponse(res.data.data));
      })
      .catch(err => reject(err));
  });
};
const initiateOrder = async data => {
  const res = await JobHai.post("transactions/initiate-order", data);
  return res.data.data;
};

const getPendingOrder = async recruiterId => {
  const res = await JobHai.get(
    `transactions/pending-transaction-list?recruiterId=${recruiterId}`
  );
  return res.data.data;
};

const purgeCredits = async data => {
  const res = await JobHai.post("transactions/purge-credits", data);
  const { creditProfile } = res.data.data;
  return creditProfile?.totalAvailableCredits;
};

const postCreditsTransfer = async data => {
  const res = await JobHai.post("transactions/credit-transfer", data);
  const { creditProfile } = res.data.data;
  return creditProfile?.totalAvailableCredits;
};

const postCreditsAddition = async data => {
  const res = await JobHai.post("transactions/add-credits", data);
  const { creditProfile } = res.data.data;
  return creditProfile?.totalAvailableCredits;
};

const generateLink = async data => {
  const res = await JobHai.post("transactions/initiate-payment-link", data);
  return res.data.data;
};
export {
  styles,
  getData,
  initiateOrder,
  purgeCredits,
  postCreditsAddition,
  postCreditsTransfer,
  getPendingOrder,
  generateLink
};
