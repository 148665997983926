import React from "react";
import { Navigate } from "react-router-dom";
import { LOGIN } from "../routes/RouteConstants";

const PrivateRoute = ({ children }) => {
  const currentUser = localStorage.getItem("userData");

  return currentUser ? children : <Navigate to={LOGIN} replace />;
};

export default PrivateRoute;
