import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import cx from "classnames";
import { styles, purgeCredits } from "./helper";
import { getErrorMessage, getFormattedValue } from "../../services/utils";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import ConfirmationCTA from "./ConfirmationCTA";
import AfterSuccessCTA from "./AfterSuccessCTA";
import FileUpload from "./FileUpload";

const STATES = {
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  ERROR: "ERROR",
  PRELIMINARY: "PRELIMINARY",
  FINAL: "FINAL",
  SUCCESS: "SUCCESS"
};

function CreditsPurge({ classes, recId, setTotalCredits, showTitle = true }) {
  const [currentState, setCurrentState] = useState(STATES.PRELIMINARY);
  const [fieldItems, setFieldItems] = useState({
    credits: "",
    remark: "",
    file: ""
  });
  const [postingState, setPostingState] = useState(STATES.FETCHED);
  const [errorMessage, setErrorMessage] = useState("");
  const { credits, file, remark } = fieldItems;
  async function onSave(st) {
    setErrorMessage("");

    if (st === STATES.PRELIMINARY) {
      if (credits && credits < 1) {
        setErrorMessage("Invalid Credits");
        return;
      }
      setCurrentState(STATES.FINAL);
    }
    if (st === STATES.FINAL) {
      setPostingState(STATES.FETCHING);

      let obj = new FormData();
      obj.append("credits", credits);
      obj.append("remark", remark);
      obj.append("file", file);
      obj.append("recruiterId", recId);
      try {
        const res = await purgeCredits(obj);
        setPostingState(STATES.SUCCESS);
        setTotalCredits(res);
      } catch (error) {
        const msg = getErrorMessage(error);
        setErrorMessage(msg?.message || "Unable to save, try again");
        setPostingState(STATES.ERROR);
        setCurrentState(STATES.PRELIMINARY);
      }
    }
  }
  function onCancel() {
    setCurrentState(STATES.PRELIMINARY);
    setPostingState(STATES.FETCHED);
    resetCredits();
  }

  function resetCredits() {
    setFieldItems({
      credits: "",
      remark: "",
      file: ""
    });
  }

  function handleChange({ target: { name, value } }) {
    if (name !== "file") value = getFormattedValue(value);
    if (name === "credits") {
      value = getFormattedValue(value, true, true);
    }
    setFieldItems(prevValues => ({ ...prevValues, [name]: value }));
  }
  function getFileData(file) {
    handleChange({ target: { name: "file", value: file } });
  }
  const disabled = !credits || !remark || !file;
  return (
    <>
      {showTitle && <div className="heading">Service de-activate</div>}
      {currentState === STATES.PRELIMINARY ? (
        <>
          <div className="formRow row">
            <div className="col-md-8">
              <Label lable="Quantity" />
              <CustomTextField
                name="credits"
                value={credits}
                onChange={handleChange}
                maxLength={6}
                placeholder="Credits"
              />
            </div>
            <div className="col-md-12">
              <Label lable="Remarks" />
              <CustomTextField
                name="remark"
                value={remark}
                onChange={handleChange}
                maxLength={100}
                placeholder="Remarks"
              />
            </div>
            <div className="col-md-12">
              <FileUpload
                name="file"
                label="Add Proof"
                getFileData={getFileData}
              />
            </div>

            <div className="col-md-12">
              <div className={cx("flex", "buttonContainer", "center")}>
                <input
                  type="button"
                  onClick={() => onSave(STATES.PRELIMINARY)}
                  value="Save"
                  className={cx(
                    "submitBtn",
                    "btns",
                    "btn1",
                    "flex",
                    "justifyCenter"
                  )}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          {postingState === STATES.ERROR ? (
            <div className={classes.errorState}>
              <i class="fa fa-x" aria-hidden="true" />
              <span>&nbsp; {errorMessage}</span>
            </div>
          ) : null}
        </>
      ) : (
        <>
          {postingState === STATES.SUCCESS ? (
            <div className={classes.successState}>
              SUCCESS - {credits} credits deleted permanently
            </div>
          ) : (
            `ALERT: ${credits} credits will be deleted permanently`
          )}
          {postingState === STATES.SUCCESS ? (
            <AfterSuccessCTA
              onCancel={() => onCancel()}
              lable="De activate more"
            />
          ) : (
            <ConfirmationCTA
              disabled={postingState === STATES.FETCHING}
              onSave={() => onSave(STATES.FINAL)}
              onCancel={() => onCancel()}
            />
          )}
        </>
      )}
    </>
  );
}

export default withStyles(styles)(React.memo(CreditsPurge));
