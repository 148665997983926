import JobHai from "../../api/JobHai";
import { getErrorMessage } from "../utils";

export const compareApplyJobs = async data => {
  const { job_id, category_id, cluster_id, job_distinction = "" } = data;
  try {
    const response = await JobHai.post("/compare-apply-jobs", {
      job_id,
      category_id,
      cluster_id,
      job_distinction: job_distinction ? job_distinction?.toUpperCase() : "FREE"
    });
    return response?.data?.data;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};
