import { useFormContext } from "react-hook-form";
import classes from "./lcsCampaignCreation.module.css";
import MaterialDropdown from "../materialDropDown";
import MaterialInputTextField from "../materialInputTextField";
import MaterialSwitch from "../materialSwitch";
import InterviewDateCenterDummyOptions from "./InterviewDateCenterDummyOptions";
import {
  customOptionMaxReq,
  customOptionMinReq,
  maxOptionsAllowed,
  maxQuestionsAllowed,
  minOptionsReq,
  minQuestionReq
} from "../Campaign/lcsCampaignConstants";
import Options from "./Options";
import useLcsCampaignDetails from "../../zustandService/useLcsCampaignDetails";
import { useEffect, useRef } from "react";

const LcsCampaignQueComponent = props => {
  const {
    questionIndex,
    lang,
    removeQuestion,
    nextQuestionIdOptions,
    update,
    isDefaultLang,
    priorityFieldArray,
    isConditional,
    defaultOptionObj,
    numberOfActiveQuestions
  } = props;
  const { control, watch } = useFormContext();
  const questionWatcher = watch(`questions.${questionIndex}`);
  const questionActiveStateWatcher = watch(
    `questions.${questionIndex}.is_active`
  );
  const prevQuestionActiveStateWatcher = useRef(questionActiveStateWatcher);
  useEffect(() => {
    if (prevQuestionActiveStateWatcher.current && !questionActiveStateWatcher) {
      alert("Be Careful!! This might impact the lead classification rules");
    }
    prevQuestionActiveStateWatcher.current = questionActiveStateWatcher;
  }, [questionActiveStateWatcher]);
  const {
    question_category: questionCategory = "",
    question_id: questionId = null
  } = questionWatcher;
  const isWalkinQuestion =
    questionCategory === "interview_date" ||
    questionCategory === "interview_center";
  const isWalkinQuestionSaved = isWalkinQuestion && !!questionId;
  const showDummyDateOptions = questionCategory === "interview_date"; // show dummy options for date always
  const showDummyCenterOptions =
    questionCategory === "interview_center" && !questionId; // in case of center show if center question is not saved.x
  const { isWalkinCampaign } = useLcsCampaignDetails(state => ({
    isWalkinCampaign: state.isWalkinCampaign
  }));

  const questionTypeOptions = [
    { value: "single_chip", label: "Single select" },
    { value: "multiple_chip", label: "Multi select" }
  ];

  const questionCategoryOptions = isWalkinCampaign
    ? [
        {
          value: "default",
          label: "Default"
        },
        { value: "interview_date", label: "Interview Date" },
        { value: "interview_center", label: "Interview Center" }
      ]
    : [{ value: "default", label: "Default" }];
  const questionTypeDefaultValue =
    questionWatcher.question_type ?? "single_chip";

  const onQuestionCategoryChange = e => {
    const questionCategory = e.target.value;
    const isWalkinQuestion =
      questionCategory === "interview_date" ||
      questionCategory === "interview_center" ||
      false;
    const obj = JSON.parse(JSON.stringify(questionWatcher));
    obj.question_category = questionCategory;
    if (isWalkinQuestion) obj.options = [];
    update(questionIndex, obj);
  };

  const nextQueIdFilteredOptions = nextQuestionIdOptions.filter(item => {
    if (item.index === null) return item;
    if (item.index > questionWatcher.priority_order) return item;
  });

  let numberOfActiveOptions = 0;
  questionWatcher.options.forEach(option => {
    numberOfActiveOptions += Number(!!option.is_active);
  });

  const isQueActiveSwitchDisabled = () =>
    (!questionWatcher.is_active &&
      numberOfActiveQuestions === maxQuestionsAllowed) ||
    (questionWatcher.is_active && numberOfActiveQuestions === minQuestionReq);
  const showCustomOptions = isWalkinQuestion;

  return (
    <>
      <h4>Question{questionIndex + 1}</h4>
      <div className={classes.questioncnt}>
        <div className={classes.questionTitleCnt}>
          <MaterialInputTextField
            name={`questions.${questionIndex}.question_label.${lang}`}
            label="Question"
            control={control}
            rules={{
              required: questionWatcher.is_active
                ? "Question title is required"
                : false,
              maxLength: questionWatcher.is_active
                ? {
                    message: `Max 120 character allowed`,
                    value: 120
                  }
                : undefined // No maxLength validation if not active
            }}
            formControlClasses={{ root: classes.formControlClassQue }}
            fullWidth={false}
          />
          <MaterialDropdown
            name={`questions.${questionIndex}.question_category`}
            label="Select Category"
            control={control}
            rules={{ required: "Please select an option" }} // Validation rules
            options={questionCategoryOptions}
            fullWidth={false}
            formControlClasses={{ root: classes.formNextQuestionId }}
            defaultValue={questionCategory}
            onChange={onQuestionCategoryChange}
            disabled={!isDefaultLang || isWalkinQuestionSaved} // cannot change question category if walkin questions are in saved state
          />
          <MaterialDropdown
            name={`questions.${questionIndex}.question_type`}
            label="Select Question Type"
            control={control}
            rules={{ required: "Please select an option" }} // Validation rules
            options={questionTypeOptions}
            fullWidth={false}
            formControlClasses={{
              root: isWalkinQuestion
                ? classes.formNextQuestionId
                : classes.formControlClassQueType
            }}
            defaultValue={questionTypeDefaultValue}
            disabled={!isDefaultLang || isWalkinQuestion} // default single select for walkin que
          />
        </div>
        <div className={classes.helpTextCnt}>
          <MaterialInputTextField
            name={`questions.${questionIndex}.help_text.${lang}`}
            label="Helptext"
            control={control}
            formControlClasses={{ root: classes.formControlClassQue }}
            fullWidth={false}
          />
        </div>
        {showDummyDateOptions || showDummyCenterOptions ? (
          <>
            <InterviewDateCenterDummyOptions
              questionCategory={questionCategory}
              questionIndex={questionIndex}
              control={control}
              isDefaultLang={isDefaultLang}
              isConditional={isConditional}
              nextQueIdFilteredOptions={nextQueIdFilteredOptions}
              questionId={questionId}
            />
          </>
        ) : (
          <>
            <Options
              questionWatcher={questionWatcher}
              control={control}
              questionIndex={questionIndex}
              lang={lang}
              readOnly={isWalkinQuestion}
              isDefaultLang={isDefaultLang}
              update={update}
              defaultOptionObj={defaultOptionObj}
              nextQuestionIdOptions={nextQuestionIdOptions}
              optionKey={"options"}
              minOptionsReq={isWalkinQuestion ? 1 : minOptionsReq}
              maxOptionsAllowed={isWalkinQuestion ? 0 : maxOptionsAllowed} // 0 for interview center as maxoption limit not applicable for it and to remove center's add option button
            />
          </>
        )}
        {showCustomOptions ? (
          <>
            <Options
              questionWatcher={questionWatcher}
              optionKey={"customOptions"}
              control={control}
              questionIndex={questionIndex}
              lang={lang}
              readOnly={false}
              isDefaultLang={isDefaultLang}
              update={update}
              defaultOptionObj={defaultOptionObj}
              nextQuestionIdOptions={nextQuestionIdOptions}
              minOptionsReq={customOptionMinReq}
              maxOptionsAllowed={customOptionMaxReq}
            />
          </>
        ) : null}
        {isDefaultLang ? (
          <div className={classes.footerCnt}>
            {!questionId && numberOfActiveQuestions > minQuestionReq ? (
              <i
                onClick={() => removeQuestion(questionIndex)}
                className="fa-solid fa-trash"
              />
            ) : null}

            {!!questionId ? (
              <MaterialSwitch
                name={`questions.${questionIndex}.is_active`}
                label="Active"
                control={control}
                defaultChecked={questionWatcher.is_active ?? true}
                formControlClasses={{ root: classes.formSwitch }}
                size={"small"}
                disabled={isQueActiveSwitchDisabled()}
              />
            ) : null}

            <MaterialDropdown
              name={`questions.${questionIndex}.priority_order`}
              label="Priority"
              control={control}
              rules={{ required: "Select Priority" }}
              options={priorityFieldArray}
              fullWidth={false}
              formControlClasses={{ root: classes.formPriorityField }}
              disabled={!isDefaultLang}
              defaultValue={questionWatcher.priority_order ?? questionIndex}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LcsCampaignQueComponent;
