import React from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { Paper, MenuItem } from "@material-ui/core";
import { debounce } from "lodash";
import distance from "jaro-winkler";
import { getFormattedValue, isEqual } from "../../../services/utils";
import CustomTextField from "./CustomTextField";

const classes = {
  root: {
    flexGrow: 1,
    position: "relative",
    zIndex: 1
  },
  rootFocus: {
    flexGrow: 1,
    position: "relative",
    zIndex: 9
  },
  container: {
    zIndex: 2
  },

  suggestionsContainer: {
    display: "none"
  },
  suggestionsContainerOpen: {
    fontFamily: "Source Sans Pro, sans-serif",
    display: "block",
    maxHeight: "300px",
    overflow: "auto",
    border: "1px solid #E2E5EF",
    boxShadow: "0px 2px 7px rgba(2, 99, 188, 0.15)",
    borderRadius: "6px"
  },
  suggestion: {
    display: "block",
    backgroundColor: "#FFFFFF"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    "& li:nth-child(2n)": {
      backgroundColor: "#F8FAFA"
    }
  },
  search: { marginLeft: "-40px", color: "#54747A" },
  searchContainer: {
    backgroundColor: "#ffffff",
    border: "1px solid #E2E5EF",
    position: "absolute",
    zIndex: 2,
    borderRadius: "6px",
    width: "100%"
  },
  noClass: {
    position: "absolute",
    zIndex: 2,
    borderRadius: "6px",
    width: "100%"
  },
  menuItem: {
    fontSize: "14px",
    color: "#374F4F",
    "& strong": {
      fontWeight: 700
    }
  },
  arrowImage: {
    position: "absolute",
    right: 16,
    top: 20,
    zIndex: 1,
    backgroundColor: "#ffffff"
  },

  disabled: {
    cursor: "not-allowed"
  },
  textfield: {},

  focusTextfield: {
    border: "none",
    borderRadius: "0",
    boxShadow: "none",
    width: "320px",
    height: "40px",
    color: "#374F4F",
    fontWeight: 700,
    padding: "0"
  }
};

class AutoSearch extends React.Component {
  state = {
    inputValue: "",
    suggestions: [],
    isFocus: false
  };

  componentDidMount() {
    const { searchValue } = this.props;
    if (searchValue) this.setState({ inputValue: searchValue });
  }

  componentDidUpdate(prevProps, prevState) {
    const { inputValue } = this.state;
    const { searchValue, id, dataList } = this.props;
    if (inputValue !== prevState.inputValue) {
      this.selectedValue();
    }
    if (searchValue !== prevProps.searchValue) {
      if (id === "search-title") this.setState({ inputValue: searchValue });
      else if (!inputValue) this.setState({ inputValue: searchValue });
    }
    if (!isEqual(dataList, prevProps.dataList)) {
      this.setState({
        suggestions: dataList
      });
    }
  }

  selectedValue = debounce(
    () => this.props.getSelectedInput(this.state.inputValue),
    100
  );

  renderInputComponent = inputProps => {
    const { ref, ...other } = inputProps;
    const { variant, id, isDisable, maxLength = null } = this.props;
    const { isFocus } = this.state;
    return (
      <CustomTextField
        style={isFocus ? classes.focusTextfield : classes.textfield}
        {...other}
        variant={variant}
        id={id}
        disabled={isDisable}
        autoFocus={false}
        maxLength={maxLength}
      />
    );
  };

  getSuggestions = (value, dataList) => {
    let filteredLocalityList = [];

    if (value.length < 1) {
      return dataList;
    }

    dataList.forEach(d => {
      let similarity_score = distance(d.label, value, { caseSensitive: false });
      if (similarity_score > 0.7) {
        filteredLocalityList.push({ ...d, score: similarity_score });
      } else if (d.label.toLowerCase().indexOf(value) !== -1) {
        filteredLocalityList.push({ ...d, score: 0.9 });
      }
    });

    filteredLocalityList = filteredLocalityList.sort(
      (a, b) => b.score - a.score
    );

    return filteredLocalityList;
  };

  shouldRenderSuggestions = value => {
    return true;
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value.toLowerCase(), this.props.dataList)
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = (event, { newValue }) => {
    const value = getFormattedValue(newValue, false, false, false);
    this.setState({
      inputValue: value
    });
    if (this.props.id === "search-title")
      this.props.getSelectedValue({ label: value });
  };

  handleFocus = () => {
    this.setState({ isFocus: true });
  };

  handleBlur = () => {
    this.setState({ isFocus: false });
  };

  getSuggestionValue = suggestion => {
    this.props.getSelectedValue(suggestion);
    return suggestion.label;
  };

  onSuggestionSelected = (event, { suggestionValue }) => {
    this.props.getSelectedInput(suggestionValue);
    this.handleBlur();
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div style={classes.menuItem}>
          {parts.map((part, index) =>
            part.highlight ? (
              <strong key={String(index)}>{part.text}</strong>
            ) : (
              <span key={String(index)}>{part.text}</span>
            )
          )}
        </div>
      </MenuItem>
    );
  };

  render() {
    const { placeholder, isArrow = false } = this.props;
    const { suggestions, isFocus, inputValue } = this.state;
    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
      shouldRenderSuggestions: this.shouldRenderSuggestions,
      onSuggestionSelected: this.onSuggestionSelected,
      focusInputOnSuggestionClick: false
    };
    return (
      <div style={isFocus ? classes.rootFocus : classes.root}>
        <div style={isFocus ? classes.searchContainer : classes.noClass}>
          {isArrow ? (
            <img
              src={isFocus ? "/images/arrow_up.svg" : "/images/arrow_down.svg"}
              alt="arrow"
              style={classes.arrowImage}
            />
          ) : null}
          <Autosuggest
            {...autosuggestProps}
            inputProps={{
              classes,
              placeholder: placeholder,
              value: inputValue ? inputValue : "",
              onChange: this.handleChange,
              onFocus: this.handleFocus,
              onBlur: this.handleBlur
            }}
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion
            }}
            renderSuggestionsContainer={options => (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            )}
          />
        </div>
      </div>
    );
  }
}

AutoSearch.propTypes = {
  classes: PropTypes.object,
  dataList: PropTypes.array.isRequired,
  getSelectedValue: PropTypes.func.isRequired,
  getSelectedInput: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  searchRef: PropTypes.any,
  isTryAnother: PropTypes.bool,
  isDisable: PropTypes.bool
};

export default AutoSearch;
