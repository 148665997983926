import React from "react";
import "../../styleNew.css";
import Header from "../../Container/Componets/Header";
import withRouter from "../../services/withRouter";
import UpdateLeadsContainer from "./UpdateLeadsContainer";
import NoAccess from "../Common/NoAccess";

const UpdateLeads = props => {
  const { params = {} } = props;
  const editParams = { ...params };
  const leadId = params.id;
  return (
    <>
      <div className="job-edit-container">
        <div className="main-container">
          <Header />
          {Number(params.id) ? (
            <UpdateLeadsContainer editParams={editParams} leadId={leadId} />
          ) : (
            <>
              <div class="clr gap-20" />
              <NoAccess />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(UpdateLeads);
