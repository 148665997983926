import { CSVLink } from "react-csv";
import moment from "moment";

const DownloadSharedLeads = ({ leadsList }) => {
  const csvFilename = `js-leads-${moment(new Date()).format(
    "YYYY-MM-DD-hh_mm_ss"
  )}.csv`;

  let csvHeaders = [
    { label: "Lead User Id", key: "lead_user_id" },
    { label: "shared_with_campaign_id", key: "shared_with_campaign_id" },
    { label: "KAM ID", key: "shared_by_kam_id" },
    { label: "Shared Date", key: "shared_date" },
    { label: "campaign_id", key: "campaign_id" },
    { label: "status", key: "lead_status" },
    { label: "Additional Info", key: "additional_info" }
  ];

  return (
    <>
      <CSVLink
        data={leadsList}
        // headers={csvHeaders}
        filename={csvFilename}
        className="download_link"
        target="_blank"
      >
        Export Leads
      </CSVLink>
    </>
  );
};
export default DownloadSharedLeads;
