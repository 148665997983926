import classes from "./lcsCampaignCreation.module.css";
import MaterialInputTextField from "../materialInputTextField";
import { Radio } from "@material-ui/core";
import MaterialDropdown from "../materialDropDown";

const InterviewDateCenterDummyOptions = ({
  questionCategory = "",
  control,
  questionIndex,
  nextQueIdFilteredOptions,
  isConditional,
  isDefaultLang,
  questionId
}) => {
  const label =
    questionCategory === "interview_center" ? "Interview Center" : "Date";
  const dummyOptions = [
    {
      optionLabel: `${label} 1`,
      readOnly: true
    },
    {
      optionLabel: `${label} 2`,
      readOnly: true
    },
    {
      optionLabel: `${label} 3`,
      readOnly: true
    }
  ];
  return (
    <>
      {dummyOptions.map((item, index) => (
        <div key={index} className={classes.optionField}>
          <Radio checked={false} />
          <MaterialInputTextField
            name="dummyOption"
            label="Option"
            control={control}
            formControlClasses={{ root: classes.formControlClassOptions }}
            fullWidth={false}
            value={item.optionLabel}
            readOnly={item.readOnly}
          />
          {isConditional &&
          questionCategory === "interview_date" &&
          !!questionId ? (
            <MaterialDropdown
              name={`questions.${questionIndex}.date_next_question_id`}
              label="Select Next Que"
              control={control}
              rules={{ required: "Please select an option" }}
              options={nextQueIdFilteredOptions}
              fullWidth={false}
              formControlClasses={{
                root: classes.formNextQuestionId
              }}
              disabled={!isDefaultLang}
              defaultValue={null}
              placeholder={"Submit Form"}
            />
          ) : null}
        </div>
      ))}
    </>
  );
};

export default InterviewDateCenterDummyOptions;
