import React from "react";
import classNames from "classnames";
import {
  CAT_ASSIGNMENT_VARIABLES,
  MODEARTOR_ASSIGNMENT
} from "../../../routes/RouteConstants";

const CatSubTabs = ({ isSelected, isAssignmentStarted }) => {
  const subTabList = [
    {
      title: "Lead Assignment Section",
      id: "LEAD_ASSIGNMENT",
      url: `${MODEARTOR_ASSIGNMENT}?pageType=CAT`
    },
    {
      title: "CAT Assignment Variables",
      id: "CAT_ASSIGNMENT_VARIABLES",
      url: CAT_ASSIGNMENT_VARIABLES
    }
  ];

  return (
    <div className="tabs tabs-cat">
      <div className="tab-list">
        {subTabList.map(({ id, title, url }) => (
          <React.Fragment key={id}>
            {id === isSelected ? (
              <span
                className={classNames("unClickedChip", {
                  clickedChip: id === isSelected
                })}
              >
                {title}
              </span>
            ) : (
              <a href={url}>
                <span
                  className={classNames("unClickedChip", {
                    clickedChip: id === isSelected
                  })}
                >
                  {title}
                </span>
              </a>
            )}
          </React.Fragment>
        ))}
      </div>

      {isAssignmentStarted ? (
        <div className="text-danger">
          {isAssignmentStarted.isAssgStartedMsg}
        </div>
      ) : null}
    </div>
  );
};

export default CatSubTabs;
