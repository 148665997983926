let PageHeader = (props) => {
    return (
      <div className=" border-b p-3 flex mb-6 justify-between w-full ">
      <div className="flex items-center space-x-3  ">
        <div className=" size-10 bg-blue-50 flex items-center text-blue-500 rounded-md justify-center">
          {props.icons}
        </div>
        <h1 className="text-2xl font-semibold">{props.heading}</h1>
      </div>
      {props.widget}
      </div>
    );
  };

  export default PageHeader