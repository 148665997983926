import { create } from "zustand";
import { getCrmMetaData } from "../services/metadataApiServices";

const convertArrayToObject = array => {
  return array.reduce((acc, { id, label }) => {
    acc[id] = label;
    return acc;
  }, {});
};

const useMetadataStore = create((set, get) => ({
  metaData: [],
  insideSalesBuckets: [],
  insideSalesLeadSources: [],
  insideSalesLeadSourceObject: {},
  insideSalesBucketObject: {},
  metaDataFetchState: "LOADING",
  fetchMetaData: async () => {
    try {
      if (get().metaDataFetchState === "SUCCESS") {
        return;
      }
      set({ metaDataFetchState: "LOADING" });
      const response = await getCrmMetaData();
      const { inside_sales_buckets = [], inside_sales_lead_sources = [] } =
        response;
      set({
        metaData: response,
        insideSalesBuckets: inside_sales_buckets,
        insideSalesBucketObject: convertArrayToObject(inside_sales_buckets),
        insideSalesLeadSources: inside_sales_lead_sources,
        insideSalesLeadSourceObject: convertArrayToObject(
          inside_sales_lead_sources
        ),
        metaDataFetchState: "SUCCESS"
      });
    } catch (err) {
      set({
        metaDataFetchState: "ERROR",
        metaData: [],
        insideSalesBuckets: [],
        insideSalesLeadSources: [],
        insideSalesLeadSourceObject: [],
        insideSalesBucketObject: []
      });
    }
  }
}));

export default useMetadataStore;
