import { HandshakeIcon } from "lucide-react";
import PageHeader from "../../local/pageHeader";
import SalesSummary from "./SalesSummary";
import SalesFilter from "./SalesFilter";
import useInsideSalesStore from "../../../zustandService/useInsideSalesStore";
import SalesList from "./SalesList";
import LmfsPagination from "../LmfsPagination";
import { customUseEffect } from "../../../services/utils";
import useInsideLeadStore from "../../../zustandService/useInsideLeadStore";

const Sales = () => {
  const {
    fetchSalesData,
    payloadData,
    salesData,
    fetchState,
    errorMessage,
    updatePageNumber
  } = useInsideSalesStore(state => ({
    fetchSalesData: state.fetchSalesData,
    payloadData: state.payloadData,
    fetchState: state.fetchState,
    errorMessage: state.errorMessage,
    salesData: state.salesData,
    updatePageNumber: state.updatePageNumber
  }));

  const { fetchModeratorData } = useInsideLeadStore(state => ({
    fetchModeratorData: state.fetchModeratorData
  }));

  const { page = 1, size = 20 } = payloadData;

  customUseEffect(() => {
    fetchSalesData();
    fetchModeratorData();
  }, []);

  const handlePagination = page => {
    updatePageNumber(page);
  };
  const { salesInfo = [], totalCount = 0 } = salesData;
  return (
    <div className=" w-screen lg:w-[calc(100vw-15rem)] h-full overflow-scroll pb-12">
      <PageHeader icons={<HandshakeIcon />} heading="Sales" />
      <SalesSummary />
      <SalesFilter />
      <div className="relative m-3 overflow-auto border rounded-xl">
        {fetchState === "LOADING" ? (
          <p className="text-center py-6">Loading...</p>
        ) : fetchState === "ERROR" ? (
          <p className="text-red-500 ml-4 py-6">
            {errorMessage || "Error fetching data"}
          </p>
        ) : (
          <>
            <SalesList data={salesInfo} />
            {!!totalCount && (
              <LmfsPagination
                totalCount={totalCount}
                handlePagination={handlePagination}
                activePage={page}
                itemsPerPage={size}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default Sales;
