import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import JobHai from "../../../api/JobHai";

const ReAssignCatLeads = props => {
  const [isReAssign, setReAssignModal] = useState(false);
  const [selectedModerator, setSelectedModerator] = useState("");

  const { selectedRecruiters, moderatorList, callBack, moderatorId } = props;
  function openModel(e) {
    e.preventDefault();
    setReAssignModal(true);
  }

  function closeModal() {
    setReAssignModal(false);
    setSelectedModerator("");
  }

  function handleSave() {
    let alertCopy = "";
    if (selectedModerator) {
      const selectedMod = moderatorList.filter(
        item => item.moderator_id == selectedModerator
      );
      alertCopy = `Are you sure if you want to reassign the selected leads to ${selectedMod[0].moderator_name}?`;
    }

    if (window.confirm(alertCopy)) {
      const obj = {
        toModeratorId: selectedModerator,
        recruiterIds: selectedRecruiters
      };
      JobHai.post("/cat-start-reassignment", obj)
        .then(response => {
          closeModal();
          callBack("reassign");
        })
        .catch(error => {
          console.log("Error: ", error);
        });
    }
  }
  const filteredModerators = moderatorList.filter(
    moderator => moderator.moderator_id !== Number(moderatorId)
  );

  const renderModal = () => {
    return (
      <Modal show={true}>
        <Modal.Body>
          <div className="clr">
            <b>
              Please select moderator to reassign selected leads <br />
            </b>
          </div>
          <div className="gap-20" />
          <div className="clr">
            <select
              className="form-control"
              name="moderator_id"
              value={selectedModerator}
              onChange={e => setSelectedModerator(e.target.value)}
            >
              <option value="">Select Moderator</option>
              {filteredModerators.map(item => {
                return (
                  <option
                    value={item.moderator_id}
                    key={`modlist${item.moderator_id}`}
                  >
                    {item.moderator_name}
                  </option>
                );
              })}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={handleSave}
            disabled={!selectedModerator}
          >
            Save
          </button>
          <button className="btn btn-secondary" onClick={closeModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {selectedRecruiters.length ? (
        <button className="btn btn-primary" onClick={openModel}>
          Reassign Lead
        </button>
      ) : null}
      {isReAssign ? renderModal() : null}
    </>
  );
};
export default ReAssignCatLeads;
