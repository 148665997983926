import React from "react";
import classes from "./lcsCampaignDetails.module.css";
import MaterialInputTextField from "../../materialInputTextField";
import MaterialDropdown from "../../materialDropDown";
import UploadImage from "../../UploadImage";

const BannerDetails = ({
  index,
  watch,
  name,
  control,
  languageList,
  setError,
  remove,
  numberOfBannerFields,
  isHeading = true,
  isCarouselImage = false,
  bannerLabel = "Banner",
  bannerHeadingLabel = "Banner Heading"
}) => {
  const item = watch(`${name}.${index}`);
  return (
    <div className={classes.campaignTypeList}>
      <MaterialDropdown
        name={`${name}.${index}.language`}
        label="Language"
        control={control}
        rules={{ required: "Language is required" }}
        options={languageList}
        fullWidth={false}
        formControlClasses={{
          root: classes.campaignTypeFormOption
        }}
        defaultValue={item.language}
      />
      {isHeading ? (
        <MaterialInputTextField
          name={`${name}.${index}.heading`}
          label={bannerHeadingLabel}
          control={control}
          formControlClasses={{
            root: classes.campaignTypeFormTextbox
          }}
          fullWidth={false}
          defaultValue={item.heading}
          rules={{
            required: `${bannerHeadingLabel} is required`,
            maxLength: {
              value: 36,
              message: "Maximum length is 36 characters"
            }
          }}
        />
      ) : null}
      <div className={classes.campaignTypeFormImg}>
        <UploadImage
          control={control}
          customClass={classes.uploadImage}
          label={bannerLabel}
          name={`${name}.${index}.file`}
          rules={
            !item.image_url && {
              required: "Banner image is required"
            }
          }
          defaultValue={""} // Set default value for file
          url={item.image_url || ""}
          setError={setError}
        />
      </div>
      {isCarouselImage ? (
        <div className={classes.campaignTypeFormImg}>
          <UploadImage
            control={control}
            customClass={classes.uploadImage}
            label="Carousel Banner"
            name={`${name}.${index}.carousel_file`}
            rules={
              !item.carousel_image_url && {
                required: "Carousel Banner image is required"
              }
            }
            defaultValue={""} // Set default value for file
            url={item.carousel_image_url || ""}
            setError={setError}
          />
        </div>
      ) : null}
      <div className={classes.campaignTypeFormRemove}>
        {numberOfBannerFields > 1 ? (
          <i className="fa-solid fa-x" onClick={() => remove(index)} />
        ) : null}
      </div>
    </div>
  );
};
export default BannerDetails;
