import React, { useState } from "react";
import { getErrorMessage, redirectURL } from "../../services/utils";
import { ENTERPRISE_LEADS_UPDATE } from "../../routes/RouteConstants";
import JobHai from "../../api/JobHai";

const AddNewLead = ({ phone = "", clientId = "", view = "" }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const addLead = () => {
    if (window.confirm(`Are you sure want to create new lead for ${phone}?`)) {
      setLoading(true);
      setError(null);
      JobHai.post(`/create-referral-lead`, {
        phone,
        client_id: clientId
      })
        .then(response => {
          setLoading(false);
          const leadUserId = response?.data?.data || "";
          if (leadUserId) {
            const leadUrl = `${ENTERPRISE_LEADS_UPDATE}/${leadUserId}?client=${clientId}&view=${view}`;
            redirectURL(leadUrl);
          } else {
            setError("Error: Creating Leads");
          }
        })
        .catch(error => {
          setLoading(false);
          setError(getErrorMessage(error));
        });
    }
  };
  return (
    <span className="edit_link">
      {isLoading ? (
        <span className="text-success"> Creating lead...</span>
      ) : (
        <a className="download_link" onClick={addLead}>
          Add Lead
        </a>
      )}
      {error && <span className="text-danger"> {error}</span>}
    </span>
  );
};

export default AddNewLead;
