import React, { useState, useEffect, useCallback } from "react";
import LoadingSpinner from "../../LoadingSpinner";
import JobHai from "../../../api/JobHai";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import JobLink from "../JobLink";
import AutoJobPostSearch from "./AutoJobPostSearch";
import PopupMessage from "../../PopupMessage";
import CustomePagination from "../../Common/Pagination";
import { getErrorMessage } from "../../../services/utils";
import {
  getLocalStorage,
  setLocalStorage
} from "../../../services/localStorage";
import { getEligibleCrawledJobs } from "../../../services/autoJobPostingService";
import { EDIT_AUTO_JOB_POSTING } from "../../../routes/RouteConstants";

const AutoJobPosting = () => {
  const [liveJobs, setLiveJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [role] = useState("QA");
  const [searchObj] = useState({
    made_live_date_start: moment(
      new Date().setMonth(new Date().getMonth() - 1)
    ).format("YYYY-MM-DD"),
    made_live_date_end: moment(new Date()).format("YYYY-MM-DD")
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [msgData, setMsgData] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });
  const [showSearchBox, setShowSearchBox] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [itemsCountPerPage] = useState(50);
  const [filters, setFilters] = useState({});
  const [categories, setCategories] = useState([]);

  const handleClosePopup = useCallback(() => {
    setMsgData({ msg: "", showMsg: false, msgType: "error" });
  }, []);

  const getCategories = useCallback(() => {
    JobHai.get("../metadata/v2/crm_metadata")
      .then(metadata => {
        const data = metadata.data.data;
        const categories = data.categories || [];
        setCategories(categories);
        setLocalStorage("crm_metadata_v2", JSON.stringify(data));
      })
      .catch(error => {
        setIsLoading(false);
        setMsgData({
          msg: getErrorMessage(error),
          showMsg: true,
          msgType: "error"
        });
      });
  }, []);

  const getLiveJobs = useCallback(async () => {
    try {
      const response = await getEligibleCrawledJobs({
        filters,
        page: activePage - 1,
        size: itemsCountPerPage
      });
      const { data: crawledRecruiters = [] } = response;
      const count = crawledRecruiters.length || 0;
      setLiveJobs(crawledRecruiters);
      setCount(count);
      setIsLoading(false);
      setIsButtonDisabled(false);
    } catch (error) {
      setIsLoading(false);
      setMsgData({
        msg: getErrorMessage(error),
        showMsg: true,
        msgType: "error"
      });
      setIsButtonDisabled(false);
    }
  }, [filters, activePage, itemsCountPerPage]);

  useEffect(() => {
    const crmMetadata = JSON.parse(getLocalStorage("crm_metadata_v2")) || {};
    const { categories = [] } = crmMetadata;
    if (categories.length > 0) {
      setCategories(categories);
    } else {
      getCategories();
    }
    getLiveJobs();
  }, [getCategories, getLiveJobs]);

  const handleShowHide = () => {
    setShowSearchBox(prev => !prev);
  };

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const statusMessage = (msg, msgType = "success") => {
    setMsgData({
      msg: msg,
      showMsg: true,
      msgType: msgType
    });
    window.scrollTo(0, 0);
  };

  const searchData = objData => {
    setLiveJobs([]);
    setIsButtonDisabled(true);
    setFilters(objData);
    setActivePage(1);
    setMsgData({ msg: "", showMsg: false, msgType: "" });
  };

  const updatedLiveJobs = liveJobs.map(d => {
    const { lead_id = "", old_job_id = "", recruiter_id = "" } = d;
    const editUrl = `${EDIT_AUTO_JOB_POSTING}/${old_job_id}?recId=${recruiter_id}&leadId=${lead_id}`;
    return {
      ...d,
      edit_link: <JobLink url={editUrl} id={lead_id} />
    };
  });

  const columns = [
    {
      text: "Recruiter ID",
      dataField: "recruiter_id",
      sort: true
    },
    {
      text: "Job Title",
      dataField: "job_title",
      sort: true
    },
    {
      text: "Company Name",
      dataField: "company_name",
      sort: true
    },
    {
      text: "Recruiter Name",
      dataField: "recruiter_name",
      sort: true
    },
    {
      text: "Category",
      dataField: "job_category",
      sort: true
    },
    {
      text: "SOURCE",
      dataField: "source",
      sort: true
    },
    {
      text: "City",
      dataField: "city",
      sort: true
    },
    {
      text: "",
      dataField: "edit_link"
    }
  ];

  const NoDataIndication = () => <div className="spinner">No Jobs Found</div>;

  if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div>
        <div className="clr gap-20"></div>
        <PopupMessage data={msgData} click={handleClosePopup} />
        <div className="pageHeading row">
          <h2>
            Auto Job Posting
            <div className="float-right">
              <a href={"/jobs/"}> Go Back</a>
            </div>
            <div className="float-right" onClick={handleShowHide}>
              <span className="link">
                {showSearchBox ? "Hide" : "Show"} Search{" "}
              </span>
            </div>
          </h2>
        </div>
        <div className="clr gap-20"></div>
        {showSearchBox && (
          <AutoJobPostSearch
            cat={categories}
            statusMessage={statusMessage}
            searchData={searchData}
            isButtonDisabled={isButtonDisabled}
          />
        )}
        <div className="clr"></div>
        <div className="whitebox livejobs">
          <BootstrapTable
            bootstrap4
            keyField="job_id"
            data={updatedLiveJobs}
            columns={columns}
            hover
            condensed
            bordered={false}
            rowClasses="listvalue"
            headerClasses="listHeding"
            classes="borderless"
            noDataIndication={() => <NoDataIndication />}
          />
          <CustomePagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={count}
            onPageChange={handlePageChange}
            showPagination={count > 0}
          />
          <div className="clr gap-20"></div>
        </div>
      </div>
    );
  }
};

export default AutoJobPosting;
