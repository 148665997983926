import classes from "./lcsWalkinCampaignDetails.module.css";
import { useForm } from "react-hook-form";
import DateTimeRange from "./DateTimeRange";
import InterviewAddress from "./InterviewAddress";
import InterviewSteps from "./InterviewSteps";
import { Button, CircularProgress } from "@material-ui/core";
import useLcsCampaignEditPermission from "../../../zustandService/useLcsCampaignEditPermission";
import ComponentWrappedBackdrop from "../../ComponentWrappedBackdrop";
import { useEffect, useState } from "react";
import useLcsCampaignDetails from "../../../zustandService/useLcsCampaignDetails";
import { useParams } from "react-router-dom";
import { saveWalkinCampaignDetails } from "../../../services/lcsCampaign";
import {
  convertToAmPm,
  getErrorMessage,
  getValueFromURL
} from "../../../services/utils";
import { walkinTab } from "../lcsCampaignConstants";
import { EDIT_CAMPAIGN } from "../../../routes/RouteConstants";
import useLcsFormSubmitState from "../../../zustandService/useLcsFormSubmitState";

const WalkinForm = props => {
  const { duplicateCampaignFlow, removeBeforeUnloadHandler } = props;
  const queryParams = new URLSearchParams(window.location.search);
  const { updateFormState } = useLcsFormSubmitState(state => ({
    updateFormState: state.updateFormState
  }));
  const { walkinData, setFormError, fetchWalkinData } = useLcsCampaignDetails(
    state => ({
      walkinData: state.walkinData,
      setFormError: state.setFormError,
      fetchWalkinData: state.fetchWalkinData
    })
  );
  const [saveApiStatus, setSaveApiStatus] = useState({
    status: "SUCCESS",
    error: ""
  });

  const { id } = useParams();
  const {
    interview_center_details: interviewCenter = [],
    interview_date_details: interviewDateDetails = {},
    interview_steps: interviewSteps = {}
  } = walkinData;
  const method = useForm({
    defaultValues: {
      interview_center_details: interviewCenter,
      interview_date_details: interviewDateDetails,
      interview_steps: interviewSteps
    }
  });
  const { handleSubmit } = method;

  const onError = error => {
    setFormError("walkin", error);
    updateFormState("ERROR");
  };

  const onSubmit = async data => {
    let { interview_date_details: interviewDateDetails } = data;
    interviewDateDetails = {
      ...interviewDateDetails,
      excluded_week_days: interviewDateDetails.excluded_week_days.map(
        item => item.id
      ),
      start_time: convertToAmPm(interviewDateDetails.start_time),
      end_time: convertToAmPm(interviewDateDetails.end_time),
      excluded_dates: interviewDateDetails.excluded_dates.map(date =>
        typeof date === "object" ? date?.format?.("YYYY-MM-DD") : date
      )
    };
    data.interview_date_details = interviewDateDetails;

    try {
      setSaveApiStatus({
        status: "LOADING",
        error: ""
      });
      await saveWalkinCampaignDetails(data, id);
      setSaveApiStatus({
        status: "SUCCESS",
        error: ""
      });
      if (duplicateCampaignFlow) {
        queryParams.append(walkinTab, true);
        const path = `${EDIT_CAMPAIGN}/${id}?${queryParams.toString()}`;
        removeBeforeUnloadHandler();
        window.location.replace(path);
        return;
      }
      fetchWalkinData(id);
    } catch (err) {
      const errMssg = getErrorMessage(err);
      setSaveApiStatus({
        status: "ERROR",
        error: typeof errMssg === "string" ? errMssg : "Something went wrong"
      });
      setTimeout(() => {
        setSaveApiStatus({
          status: "SUCCESS",
          error: ""
        });
      }, 3000);
    }
  };
  const { canEditCampaign } = useLcsCampaignEditPermission(state => ({
    canEditCampaign: state.canEditCampaign
  }));

  return (
    <form
      className="relative size-full"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <ComponentWrappedBackdrop isVisible={!canEditCampaign} onClick={null}>
        <div className="text-center">
          <i className="fas fa-lock fa-3x"></i>
          <p className="mt-2">Cannot Edit</p>
        </div>
      </ComponentWrappedBackdrop>
      <DateTimeRange method={method} />
      <div className={classes.seperator} />
      <InterviewAddress method={method} />
      <div className={classes.seperator} />
      <InterviewSteps method={method} />
      <div className={classes.seperator} />
      <div className="flex justify-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={saveApiStatus.status === "LOADING"}
        >
          {saveApiStatus.status === "LOADING"
            ? "Saving..."
            : "Save Walkin Details"}
        </Button>
      </div>
      {saveApiStatus.status === "ERROR" ? (
        <span className="text-danger ml-2">{saveApiStatus.error}</span>
      ) : null}
    </form>
  );
};

const LcsWalkinCampaignDetails = props => {
  const { removeBeforeUnloadHandler } = props;
  const { id } = useParams();
  const [duplicateCampaignFlow, setDuplicateCampaignFlow] = useState(null);
  const duplicateCampaignId = getValueFromURL("duplicateCampaignId");
  const queryParams = new URLSearchParams(window.location.search);
  const {
    fetchWalkinData,
    fetchState: { walkin: walkinDataFetchState }
  } = useLcsCampaignDetails(state => ({
    fetchWalkinData: state.fetchWalkinData,
    fetchState: state.fetchState,
    error: state.error
  }));
  useEffect(() => {
    setDuplicateCampaignFlow(
      !!duplicateCampaignId && !queryParams.has(walkinTab)
    );
  }, []);

  useEffect(() => {
    if (duplicateCampaignFlow !== null)
      fetchWalkinData(
        duplicateCampaignFlow ? duplicateCampaignId : id,
        duplicateCampaignFlow
      );
  }, [duplicateCampaignFlow]);
  return (
    <>
      {walkinDataFetchState === "LOADING" ? (
        <div className={classes.loaderCnt}>
          <CircularProgress
            classes={{ root: classes.loaderStyles }}
            color="inherit"
          />
        </div>
      ) : (
        <WalkinForm
          duplicateCampaignFlow={duplicateCampaignFlow}
          removeBeforeUnloadHandler={removeBeforeUnloadHandler}
        />
      )}
    </>
  );
};
export default LcsWalkinCampaignDetails;
