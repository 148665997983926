import SkeletonLoader from "../SkeletonLoader";

const PerformanceSummary = props => {
  const { heading, data, isLoading, isIndividual = false } = props;

  return (
    <div className="perfromanceSummery admin-pannel">
      <div>
        <strong>{heading}</strong>
      </div>
      <div className="clr gap-20" />
      <div className="row sortingWrap whitebox">
        <table
          width="100%"
          border="0"
          cellPadding="4"
          cellSpacing="10"
          className="table table-hover table-condensed borderless"
        >
          <tbody>
            <tr className="listHeding">
              <th>{isIndividual ? "Moderator" : "Date"}</th>
              <th>Assigned</th>
              <th>Touched</th>
              <th>Connected</th>
              <th>Interested</th>
              <th>Not Interested</th>
            </tr>
            {isLoading ? (
              <tr className="listvalue">
                <td>
                  <SkeletonLoader customClass="cellLoader" />
                </td>
                <td>
                  <SkeletonLoader customClass="cellLoader" />
                </td>
                <td>
                  <SkeletonLoader customClass="cellLoader" />
                </td>
                <td>
                  <SkeletonLoader customClass="cellLoader" />
                </td>
                <td>
                  <SkeletonLoader customClass="cellLoader" />
                </td>
                <td>
                  <SkeletonLoader customClass="cellLoader" />
                </td>
              </tr>
            ) : (
              <>
                {data.map((item, index) => {
                  const {
                    assigned,
                    touched,
                    connected,
                    interested,
                    not_interested = "",
                    date = "",
                    moderator = ""
                  } = item;
                  return (
                    <tr key={`moderator${index}`} className="listvalue">
                      <td>{isIndividual ? moderator : date}</td>
                      <td>{assigned}</td>
                      <td>{touched}</td>
                      <td>{connected}</td>
                      <td>{interested}</td>
                      <td>{not_interested}</td>
                    </tr>
                  );
                })}
              </>
            )}
            {data.length === 0 && (
              <tr>
                <td colSpan={6} align="center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default PerformanceSummary;
