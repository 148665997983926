import React from "react";
import MaterialInputTextField from "../../materialInputTextField";
import classes from "./lcsWalkinCampaignDetails.module.css";
import Heading from "../../Common/Form/Heading";
import { useFieldArray } from "react-hook-form";
import { Button } from "@material-ui/core";

const InterviewAddress = props => {
  const { method } = props;
  const { control, watch } = method;
  const {
    fields: interviewCenterFields,
    append,
    remove
  } = useFieldArray({
    name: "interview_center_details",
    control,
    rules: { minLength: 1 }
  });
  const interviewCenterDetailsWatcher = watch(`interview_center_details`);
  return (
    <>
      <Heading heading={"Interview Center Information"} />
      {interviewCenterFields.map((item, index) => (
        <React.Fragment key={item.id}>
          <h2 className={"subHeading mt-4"}>
            Interview Center {index + 1}
            {interviewCenterFields.length > 1 &&
            !interviewCenterDetailsWatcher[index]?.id ? (
              <i
                onClick={() => remove(index)}
                className="fa-regular fa-circle-xmark cursor-pointer mx-1"
              />
            ) : null}
          </h2>
          <div className={classes.fieldsGridClass}>
            <MaterialInputTextField
              name={`interview_center_details.${index}.interview_center_address`}
              label="Complete Center Address"
              control={control}
              rules={{
                required: "Center Address is required"
              }}
              fullWidth={false}
            />
            <MaterialInputTextField
              name={`interview_center_details.${index}.interview_center_map_link`}
              label="Google Map Link"
              control={control}
              rules={{
                required: "Google Map Link is required"
              }}
              fullWidth={false}
            />
            <MaterialInputTextField
              name={`interview_center_details.${index}.center_spoc_name`}
              label="Center SPOC Name"
              control={control}
              rules={{
                required: "Center SPOC Name is required"
              }}
              fullWidth={false}
            />
            <MaterialInputTextField
              name={`interview_center_details.${index}.center_spoc_phone.${0}`}
              label="Center SPOC Phone Number"
              control={control}
              rules={{
                required: "Center SPOC Phone Number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Please enter a valid phone number"
                }
              }}
              fullWidth={false}
            />
            <MaterialInputTextField
              name={`interview_center_details.${index}.center_label`}
              label="Center Label Name"
              control={control}
              rules={{
                required: "Center Label Name is required",
                maxLength: {
                  message: "Max 40 character allowed",
                  value: 40
                }
              }}
              fullWidth={false}
            />
          </div>
        </React.Fragment>
      ))}
      <div className="flex justify-end my-3">
        <Button
          variant="contained"
          color="primary"
          onClick={e => {
            e.preventDefault();
            append({
              interview_center_address: "",
              interview_center_map_link: "",
              center_spoc_name: "",
              center_spoc_phone: null,
              center_label: ""
            });
          }}
        >
          Add
          <i
            className="fa-solid fa-location-dot mx-1"
            style={{ color: "#FFF" }}
          />
        </Button>
      </div>
    </>
  );
};
export default InterviewAddress;
