import React, { useState, useEffect } from "react";
import {
  CAMPAIGN_DASHBOARD,
  CREATE_CAMPAIGN
} from "../../routes/RouteConstants";
import { checkPermission } from "../../services/utils";
import LoadingSpinner from "../LoadingSpinner";
import NoAccess from "../Common/NoAccess";
import CampaignList from "./CampaignList";
import withRouter from "../../services/withRouter";
import CampaignFilter from "./CampaignFilter";
import useLcsCampaignDetails from "../../zustandService/useLcsCampaignDetails";

const ViewCampaign = () => {
  const isAccess =
    checkPermission("CampaignManagement") ||
    checkPermission("CampaignManagementAdmin");
  const pageSize = 20;
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({});
  const [isDataInitialized, setDataInitialized] = useState(false); // New state to track initialization

  const {
    fetchCampaignMetaData,
    lcsMetaData,
    fetchCampaignList,
    campaignList,
    fetchState,
    error
  } = useLcsCampaignDetails(state => ({
    fetchCampaignMetaData: state.fetchCampaignMetaData,
    lcsMetaData: state.lcsMetaData,
    fetchCampaignList: state.fetchCampaignList,
    campaignList: state.campaignList,
    fetchState: state.fetchState,
    error: state.error
  }));
  const { metadata, campaign } = fetchState;
  const isLoading = metadata === "LOADING" || campaign === "LOADING";
  const apiError = error?.metadata || error?.campaign || "";

  // Optimize by using Promise.all to parallelize the API calls
  const initializeData = async () => {
    try {
      await Promise.all([
        fetchCampaignMetaData(),
        fetchCampaignList({
          page: activePage - 1,
          size: pageSize,
          filters
        })
      ]);
      setDataInitialized(true);
    } catch (error) {
      console.error("Error initializing data:", error);
    }
  };

  useEffect(() => {
    if (isAccess) initializeData();
  }, [isAccess]);

  useEffect(() => {
    if (isDataInitialized) {
      fetchCampaignList({
        page: activePage - 1,
        size: pageSize,
        filters
      });
    }
  }, [activePage, filters]);

  function onPageChange(page) {
    setActivePage(page);
  }

  function handleSearch(newFilters) {
    setActivePage(1);
    setFilters(newFilters);
  }

  const { campaign_data: campaignData = [], count = 0 } = campaignList;
  return (
    <>
      <div className="clr gap-20" />
      <div className="pageHeading row">
        <h2>
          View Campaigns
          <div className="float-right">
            <a href={CREATE_CAMPAIGN}>
              <i className="fa fa-plus" aria-hidden="true"></i> Create New
              Campaign
            </a>
            <span className="sperator">|</span>
            <a href={CAMPAIGN_DASHBOARD}>Go Back</a>
          </div>
        </h2>
      </div>
      <div className="clr gap-20" />
      {isAccess ? (
        <CampaignFilter
          lcsMetaData={lcsMetaData}
          isDisabled={isLoading}
          searchData={handleSearch}
        />
      ) : null}
      {apiError ? <span className="text-danger">{apiError} </span> : null}

      <div className="clr gap-20" />
      <div className={"whitebox"}>
        {isAccess ? (
          <>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <CampaignList
                dataList={campaignData}
                activePage={activePage}
                itemsCountPerPage={pageSize}
                totalCount={count}
                onPageChange={onPageChange}
              />
            )}
          </>
        ) : (
          <>
            <div className="clr gap-20" />
            <NoAccess />
          </>
        )}
        <div className="clr gap-20" />
      </div>
    </>
  );
};

export default withRouter(ViewCampaign);
