import React from "react";
import { NavLink } from "react-router-dom";
import {
  RECUITERS,
  JOB_SEEKER,
  JOBS,
  COMPLAINTS,
  ANALYTICS,
  TOOLS,
  ENTERPRISE_LEADS,
  CAMPAIGN_DASHBOARD,
  LFMS
} from "../routes/RouteConstants";
import { checkPermission } from "../services/utils";
import { isLmsUser } from "../services/utilsV2";
const isCamoignMgmtAccess =
  checkPermission("CampaignManagement") ||
  checkPermission("CampaignManagementAdmin");
const NavBar = () => {
  return (
    <div className="menuTxt">
      <ul>
        <li>
          <NavLink activeClassName="selected" exact to={TOOLS}>
            <span className="operations"></span>Tools
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="selected" exact to={JOBS}>
            <span className="jobs"></span>Jobs
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="selected" exact to={RECUITERS}>
            <span className="jobs"></span>Recruiters
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="selected" exact to={JOB_SEEKER}>
            <span className="jobSeeker"></span>Job Seeker
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="selected" exact to={ANALYTICS}>
            <span className="analytics"></span>Analytics
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="selected" exact to={COMPLAINTS}>
            <span className="complaints"></span>Complaints
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="selected" exact to={ENTERPRISE_LEADS}>
            <span className="enterpriseLeads"></span>Enterprise Leads
          </NavLink>
        </li>
        {isCamoignMgmtAccess ? (
          <li>
            <NavLink activeClassName="selected" exact to={CAMPAIGN_DASHBOARD}>
              <span className="enterpriseLeads"></span> Campaign Management
            </NavLink>
          </li>
        ) : null}
        {isLmsUser() ? (
          <li>
            <a href={LFMS}>
              <span className="enterpriseLeads"></span>Inside Sales
            </a>
          </li>
        ) : null}
      </ul>
    </div>
  );
};
export default NavBar;
