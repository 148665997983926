import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { getEditPageURL, isNumeric } from "../../services/utils";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import CustomePagination from "../Common/Pagination";
import RecruiterSearch from "./RecruiterSearch";
import { getErrorMessage, getComanyDocument } from "../../services/utils";
import withRouter from "../../services/withRouter";
import { RECUITERS_APPLICANT } from "../../routes/RouteConstants";

class Recruiter extends React.Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const queryParams = new URLSearchParams(search);
    const recIds = queryParams.get("recIds");
    var recIdList = [];
    if (recIds) {
      recIdList = recIds.split(",").filter(function (el) {
        return el !== "" && isNumeric(el);
      });
    }
    const { params = {} } = this.props;
    this.state = {
      recruitersList: [],
      isLoading: true,
      uid: params.uid || "",
      isButtonDisabled: false,
      msgData: { msg: "", showMsg: false, msgType: "error" },
      activePage: 1,
      count: 0,
      totalPages: 0,
      itemsCountPerPage: 50,
      filters: this.setInitialFilters(recIdList),
      recIdList
    };
  }

  setInitialFilters = recIdList => {
    const search = window.location.search;
    const queryParams = new URLSearchParams(search);
    const recIds = queryParams.get("recIds");
    const organisationId = queryParams.get("organisation_id");

    var recIdList = [];
    if (recIds) {
      recIdList = recIds.split(",").filter(function (el) {
        return el !== "" && isNumeric(el);
      });

      if (recIdList.length > 0) {
        return { recruiter_ids: recIdList };
      }
    } else if (organisationId) {
      return { organisation_id: organisationId };
    }
    return {};
  };

  async componentDidMount() {
    this.getRecruiters();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters, activePage } = this.state;
    if (filters !== prevState.filters) {
      this.setState({
        filters: filters
      });
      this.getRecruiters();
    }
    if (activePage !== prevState.activePage) {
      this.setState({
        activePage: activePage
      });
      this.getRecruiters();
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }

  getRecruiters = () => {
    const { activePage, itemsCountPerPage, filters } = this.state;
    JobHai.post(`/recruiter-list`, {
      filters: filters,
      page: activePage - 1,
      size: itemsCountPerPage
    })
      .then(response => {
        let data = response.data.data.recruiters;
        let count = response.data.data.count;
        let totalPages = response.data.data.pages;
        this.setState({
          recruitersList: data,
          isButtonDisabled: false,
          isLoading: false,
          totalPages: totalPages,
          count: count,
          msgData: { msg: "", showMsg: false, msgType: "error" }
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isButtonDisabled: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" },
          isLoading: false
        });
        window.scrollTo(0, 0);
      });
  };

  getSearchResult = objData => {
    this.setState({
      filters: objData,
      recruitersList: [],
      activePage: 1,
      isButtonDisabled: true,
      msgData: {
        msg: "",
        showMsg: false,
        msgType: ""
      }
    });
  };

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      }
    });
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  getDocumentStatus = (status, reason) => {
    let docStatus = status ? status.replace("_", " ") : "No Document";
    if (status === "rejected") {
      docStatus = reason ? `${status} ${reason}` : status;
    }
    return <span className={"doc-status"}> {docStatus}</span>;
  };

  render() {
    const {
      recruitersList,
      isButtonDisabled,
      count,
      activePage,
      itemsCountPerPage,
      recIdList,
      isLoading
    } = this.state;

    recruitersList.map(d => {
      const {
        job_id,
        recruiter_id,
        jobs_posted = 0,
        document_url = "",
        document_status = "",
        reason = "",
        denial_reason = "",
        document_type = ""
      } = d;
      const editUrl = getEditPageURL(false, job_id, recruiter_id, "QA");
      d.recId = job_id ? (
        <a
          href={`${editUrl}&returnURL=recruiters`}
          target={"_blank"}
          rel="noreferrer"
        >
          {recruiter_id}
        </a>
      ) : (
        recruiter_id
      );
      d.jobsPosted = (
        <a
          href={`${RECUITERS_APPLICANT}/${recruiter_id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {jobs_posted}
        </a>
      );

      d.documents = document_url ? (
        <a href={document_url} target={"_blank"} rel="noreferrer">
          {this.getDocumentStatus(document_status, reason)}
        </a>
      ) : (
        this.getDocumentStatus(document_status)
      );
      d.denialReason = denial_reason ? denial_reason : "NA";
      d.documentType = getComanyDocument(document_type);
      return d;
    });

    const columns = [
      {
        text: "Id",
        dataField: "recId",
        sort: true
      },
      {
        text: "Recruiter Name",
        dataField: "full_name",
        sort: true
      },
      {
        text: "Recruiter Email",
        dataField: "email",
        sort: true
      },
      {
        text: "Company Name",
        dataField: "company_name",
        sort: true
      },
      {
        text: "Phone no",
        dataField: "phone"
      },
      {
        text: "Jobs Posted",
        dataField: "jobsPosted"
      },
      {
        text: "Doc Status",
        dataField: "documents"
      },
      {
        text: "Doc Type",
        dataField: "documentType",
        sort: true
      },
      {
        text: "Live Basic Job",
        dataField: "liveBasicJobs"
      },
      {
        text: "Live Premium Job",
        dataField: "livePremiumJobs"
      },
      {
        text: "Rec Bucket",
        dataField: "denialReason",
        sort: true
      }
    ];
    const defaultSorted = [
      {
        dataField: "recruiter_id",
        order: "desc"
      }
    ];
    const NoDataIndication = () => <div className="spinner">No Data Found</div>;
    if (isLoading) {
      return <LoadingSpinner key="loading" />;
    } else {
      return (
        <div className="job_seeker">
          <div className="clr gap-20" />
          <PopupMessage
            data={this.state.msgData}
            key="popup"
            click={this.handleClosePopup}
          />
          <div className="pageHeading row">
            <h2>Recruiter</h2>
          </div>
          <div className="clr" />
          <RecruiterSearch
            statusMessage={this.statusMessage}
            searchRecruiters={this.getSearchResult}
            isButtonDisabled={isButtonDisabled}
            recIdList={recIdList}
          />
          <div className="clr gap-20" />
          <div className="whitebox recruiter">
            <BootstrapTable
              keyField="recruiter_id"
              data={recruitersList}
              columns={columns}
              hover
              condensed
              bordered={false}
              loading={true}
              rowClasses="listvalue"
              headerClasses="listHeding"
              classes="borderless"
              defaultSorted={defaultSorted}
              noDataIndication={() => <NoDataIndication />}
            />
            <div className="clr" />
            <CustomePagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={count}
              onPageChange={this.handlePageChange.bind(this)}
              showPagination={recruitersList.length > 0 ? true : false}
              showLimitedRow={true}
            />
            <div className="clr gap-20" />
          </div>
        </div>
      );
    }
  }
}
export default withRouter(Recruiter);
