import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import { styles, postCreditsTransfer } from "./helper";
import { getErrorMessage, getFormattedValue } from "../../services/utils";
import AfterSuccessCTA from "./AfterSuccessCTA";
import CreditsTransferForm from "./CreditsTransferForm";
import ConfirmationCTA from "./ConfirmationCTA";

const STATES = {
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  ERROR: "ERROR",
  PRELIMINARY: "PRELIMINARY",
  FINAL: "FINAL",
  SUCCESS: "SUCCESS"
};

function CreditsTransfer({
  classes,
  recId,
  setTotalCredits,
  showTitle = true
}) {
  const [currentState, setCurrentState] = useState(STATES.PRELIMINARY);
  const [fieldItems, setFieldItems] = useState({
    credits: "",
    receiverRecruiterNumber: "",
    remark: "",
    file: ""
  });
  const [postingState, setPostingState] = useState(STATES.FETCHED);
  const [errorMessage, setErrorMessage] = useState("");

  const { credits, receiverRecruiterNumber, file, remark } = fieldItems;

  async function onSave(st) {
    setErrorMessage("");

    if (st === STATES.PRELIMINARY) {
      if (handleFormValidation()) {
        return;
      }
      setCurrentState(STATES.FINAL);
    } else if (st === STATES.FINAL) {
      setPostingState(STATES.FETCHING);
      let obj = new FormData();
      obj.append("credits", credits);
      obj.append("receiverRecruiterNumber", receiverRecruiterNumber);
      obj.append("remark", remark);
      obj.append("file", file);
      obj.append("senderRecruiterId", recId);
      try {
        const res = await postCreditsTransfer(obj);
        setPostingState(STATES.SUCCESS);
        setTotalCredits(res);
      } catch (error) {
        const msg = getErrorMessage(error);
        setErrorMessage(msg?.message || "Unable to save, try again");
        setPostingState(STATES.ERROR);
        setCurrentState(STATES.PRELIMINARY);
        resetFieldItems();
      }
    }
  }
  function onCancel() {
    setCurrentState(STATES.PRELIMINARY);
    setPostingState(STATES.FETCHED);
    resetFieldItems();
  }

  function resetFieldItems() {
    setFieldItems({
      credits: "",
      receiverRecruiterNumber: "",
      remark: "",
      file: ""
    });
  }

  function handleChange({ target: { name, value } }) {
    setErrorMessage(null);
    if (name !== "file") value = getFormattedValue(value);
    if (name === "credits" || name === "receiverRecruiterNumber") {
      value = getFormattedValue(value, true, true);
    }
    setFieldItems(prevValues => ({ ...prevValues, [name]: value }));
  }

  function handleFormValidation() {
    if (receiverRecruiterNumber && receiverRecruiterNumber.length < 10) {
      setErrorMessage("Enter Valid Mobile");
      return true;
    }
    if (!file) {
      setErrorMessage("Please upload file");
      return true;
    }
  }
  return (
    <>
      {showTitle && <div className="heading">Credits Transfer</div>}
      {currentState === STATES.PRELIMINARY ? (
        <CreditsTransferForm
          fieldItems={fieldItems}
          onChange={handleChange}
          errorMessage={errorMessage}
          onSave={onSave}
        />
      ) : (
        <>
          {postingState === STATES.SUCCESS ? (
            <div className={classes.successState}>
              SUCCESS - {credits} credits sent to {receiverRecruiterNumber}
            </div>
          ) : (
            <>
              {credits} credits will be sent to {receiverRecruiterNumber}
            </>
          )}
          {postingState === STATES.SUCCESS ? (
            <AfterSuccessCTA
              onCancel={() => onCancel()}
              label="Transfer more"
            />
          ) : (
            <ConfirmationCTA
              disabled={postingState === STATES.FETCHING}
              onSave={() => onSave(STATES.FINAL)}
              onCancel={() => onCancel()}
            />
          )}
        </>
      )}
    </>
  );
}

export default withStyles(styles)(React.memo(CreditsTransfer));
