import React, { useState, useEffect } from "react";
import { checkPermission, getErrorMessage } from "../../../services/utils";
import NoAccess from "../../Common/NoAccess";
import { DYNAMIC_DAG, TOOLS } from "../../../routes/RouteConstants";
import JobHai from "../../../api/JobHai";
import LoadingSpinner from "../../LoadingSpinner";
import DagBuilderList from "./DagBuilderList";

const DynamicDagBuilder = () => {
  const isAccess = checkPermission("Dynamic Dag Builder");
  const [isLoading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isAccess) {
      fetchDataList();
    }
  }, [activePage, isAccess]);

  const fetchDataList = async () => {
    setLoading(true);
    try {
      const page = activePage ? Number(activePage) - 1 : 0;
      const response = await JobHai.get(
        `dynamic-dag?page_number=${page}&size=50`
      );
      const data = response.data.data;
      const { dag_data } = data;
      setDataList(dag_data?.rows);
      setCount(dag_data?.count);
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const onPageChange = page => {
    setActivePage(page);
  };

  if (isLoading) return <LoadingSpinner />;
  return (
    <>
      <div className="gap-20" />
      <div className="pageHeading row">
        <h2>
          Dynamic Dag Builder
          <div className="float-right">
            <a href={`${DYNAMIC_DAG}/add`}>
              <i className="fa fa-plus" aria-hidden="true"></i> Create New Query
            </a>
            <span className="sperator">|</span>
            <a href={TOOLS}>Go Back</a>
          </div>
        </h2>
      </div>

      <div className="gap-20" />
      <div className="whitebox">
        <div className="gap-20" />
        {isAccess ? (
          <>
            {error ? (
              <div className="text-danger">Error: {error}</div>
            ) : (
              <DagBuilderList
                dataList={dataList}
                itemsCountPerPage={50}
                count={count}
                activePage={activePage}
                onPageChange={onPageChange}
              />
            )}
          </>
        ) : (
          <NoAccess />
        )}
      </div>
    </>
  );
};

export default DynamicDagBuilder;
