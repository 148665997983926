import React from "react";
import JobHai from "../../api/JobHai";
import AutoSearch from "../Common/Form/AutoSearch";
import { getErrorMessage } from "../../services/utils";

class ComanyName extends React.Component {
  constructor() {
    super();
    this.state = {
      dataList: []
    };
  }

  componentDidUpdate(prevProps) {
    const { companyName } = this.props;
    if (companyName !== prevProps.companyName) {
      if (!companyName.length || companyName.length >= 2) {
        this.selectedValue(companyName);
      }
    }
  }

  selectedValue = company => {
    JobHai.get(`../suggestions/organisations?query_string=${company}`)
      .then(response => {
        const { data } = response.data;
        let dataList = [];
        data.map(item => {
          return dataList.push({ id: item.organisation_id, label: item.name });
        });
        this.setState({
          dataList
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        console.log(msg);
      });
  };

  getSelectedValue = obj => {
    const { onChange, filedName } = this.props;
    const { label = "" } = obj;
    onChange(filedName, label);
  };

  onSuggestionSelected = () => {
    //
  };

  render() {
    const { dataList } = this.state;
    const { id = "search-title", placeholder, companyName } = this.props;
    return (
      <AutoSearch
        dataList={dataList}
        getSelectedValue={this.getSelectedValue}
        getSelectedInput={this.onSuggestionSelected}
        placeholder={placeholder}
        variant="outlined"
        id={id}
        error={false}
        searchValue={companyName}
        defaultSearch={true}
        searchType={"array"}
      />
    );
  }
}

export default ComanyName;
