import React from "react";
import { Link } from "react-router-dom";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import { getErrorMessage } from "../../services/utils";

class RecommendedJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompanyInfo: false,
      desabledEmailBtn: false,
      reco_user_id: "",
      job_id: "",
      msgData: { msg: "", showMsg: false, msgType: "error" }
    };
    this.handleChange = this.handleChange.bind(this);
    this.sentEmail = this.sentEmail.bind(this);
    this.searchJob = this.searchJob.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.VerifyUserID = this.VerifyUserID.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleClosePopup() {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  }

  searchJob(e) {
    e.preventDefault();
    if (!Number(this.state.job_id)) {
      this.setState({
        msgData: {
          msg: "Please provide valid job id.",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scrollTo(0, 0);
      return;
    }

    JobHai.get("/recommendedcandidates?job_id=" + this.state.job_id)
      .then(
        function (response) {
          let data = response.data.data;
          var shortlist = "",
            rejected = "",
            viewed = "";
          if (data.applications.length > 0) {
            shortlist = data.applications.filter(
              d => d.status === "r_shortlisted"
            );
            rejected = data.applications.filter(d => d.status === "r_rejected");
          }
          if (data.invitations.length > 0) {
            viewed = data.invitations.filter(d => d.status === "r_viewed");
            rejected = data.invitations.filter(d => d.status === "r_rejected");
          }
          let current_user = data.system_generated_recommendedations.user_list;
          // if(data.current_manual_recommendations.user_list)
          var current_manual = data.current_manual_recommendations.user_list;

          if (shortlist.length > 0) shortlist = shortlist[0].user_list;
          if (rejected.length > 0) rejected = rejected[0].user_list;
          if (viewed.length > 0) viewed = viewed[0].user_list;
          let recruiter_profile = data.hasOwnProperty("recruiter_profile")
            ? data.recruiter_profile
            : [];
          this.setState({
            company_name: recruiter_profile.company_name
              ? recruiter_profile.company_name
              : "",
            full_name: recruiter_profile.full_name
              ? recruiter_profile.full_name
              : "",
            email: recruiter_profile.email ? recruiter_profile.email : "",
            phone: recruiter_profile.phone ? recruiter_profile.phone : "",
            job_title: recruiter_profile.job_title
              ? recruiter_profile.job_title
              : "",
            current_manual: current_manual,
            last_sent_on: data.current_manual_recommendations.last_sent_on,
            shortlist_user: shortlist,
            rejected_user: rejected,
            viewed_user: viewed,
            current_user: current_user,
            showCompanyInfo: true,
            reco_user_id: "",
            msgData: { msg: "", showMsg: false, msgType: "success" }
          });
        }.bind(this)
      )
      .catch(
        function (error) {
          let msg = getErrorMessage(error);
          this.setState({
            showCompanyInfo: false,
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
          window.scrollTo(0, 0);
        }.bind(this)
      );
  }
  VerifyUserID(e) {
    e.preventDefault();
    if (this.state.reco_user_id === "") {
      this.setState({
        msgData: {
          msg: "Please enter comma seperated user id.",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scrollTo(0, 0);
      return;
    }
    let auto_reco_user = (
      this.state.shortlist_user +
      "," +
      this.state.rejected_user +
      "," +
      this.state.viewed_user +
      "," +
      this.state.current_user +
      "," +
      this.state.current_manual
    ).split(",");
    let reco_user_id = this.state.reco_user_id.split(",");
    let repeated_uid = auto_reco_user.filter(x => reco_user_id.includes(x));
    repeated_uid = repeated_uid.filter(function (e) {
      return e;
    });
    // console.log(repeated_uid)
    if (reco_user_id.some(ele => auto_reco_user.includes(ele))) {
      this.setState({
        desabledEmailBtn: false,
        msgData: {
          msg:
            "User id " +
            repeated_uid +
            " is already in recommended candidate list.",
          showMsg: true,
          msgType: "error"
        }
      });
      window.scrollTo(0, 0);
      return;
    }
    this.setState({
      desabledEmailBtn: true,
      msgData: { msg: this.state.user_id, showMsg: false, msgType: "error" }
    });
  }
  sentEmail(e) {
    e.preventDefault();
    const obj = {
      user_list: this.state.reco_user_id.split(","),
      job_id: this.state.job_id
    };
    //console.log(obj)
    JobHai.post("/recommendedcandidates", obj)
      .then(
        function (response) {
          this.setState({
            msgData: {
              msg: "Email has been sent.",
              showMsg: true,
              msgType: "success"
            }
          });
          window.scrollTo(0, 0);
        }.bind(this)
      )
      .catch(
        function (error) {
          let msg = getErrorMessage(error);
          this.setState({
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
          window.scrollTo(0, 0);
        }.bind(this)
      );
  }

  render() {
    //  console.log(this.state.recruiter_profile)
    return (
      <div className="edit-form">
        <div className="clr gap-20"></div>
        <PopupMessage data={this.state.msgData} click={this.handleClosePopup} />
        <div className="pageHeading row">
          <h2>Recommended Job Seeker</h2>
        </div>
        <div className="whiteCard">
          <div className="container-fluid">
            <div>
              <h2>Job Details</h2>
            </div>
            <div className="gap-10"></div>
            <form noValidate autoComplete="off" className="search">
              <div className="form-row">
                <div className="col-md-3">
                  <label>Job Id</label>
                  <input
                    type="text"
                    className="form-control"
                    name="job_id"
                    value={this.state.job_id}
                    onChange={event =>
                      this.setState({
                        job_id: event.target.value.replace(/\D/, "")
                      })
                    }
                  />
                </div>
                <div className="col-md-2 bttn">
                  <label>&nbsp;</label>
                  <div>
                    <button onClick={this.searchJob} className="bttn">
                      Search
                    </button>
                  </div>
                </div>
                {this.state.showCompanyInfo && (
                  <div className="col-md-7">
                    <label>Company Name</label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.company_name}
                        readOnly
                      />
                    </div>
                  </div>
                )}
              </div>
              {this.state.showCompanyInfo && (
                <div className="form-row">
                  <div className="col-md-5">
                    <label>Recruiter Name</label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.full_name}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Recruiter Number</label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.phone}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label>Recruiter Email</label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.email}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <label>Job Title</label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.job_title}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
        <div className="gap-20"></div>
        <div className="gap-10"></div>
        {this.state.showCompanyInfo && (
          <div className="whiteCard">
            <div className="container-fluid">
              <h2>
                Recommended Candidate Auto{" "}
                <span>Last Sent On - {this.state.last_sent_on}</span>
              </h2>
              <div className="gap-10"></div>
              <form noValidate autoComplete="off" className="search">
                <div className="form-row">
                  <div className="col-md-12">
                    <label>Shortlisted</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.shortlist_user}
                      readOnly
                    />
                  </div>
                  <div className="col-md-12">
                    <label>Rejected</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.rejected_user}
                      readOnly
                    />
                  </div>
                  <div className="col-md-12">
                    <label>Viewed</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.viewed_user}
                      readOnly
                    />
                  </div>
                  <div className="col-md-12">
                    <label>
                      Current System Generated Recommended Candidate
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.current_user}
                      readOnly
                    />
                  </div>
                </div>
                <div className="gap-10"></div>
                <h2>Manually Recommended Candidate</h2>
                <div className="gap-10"></div>
                <div className="form-row">
                  <div className="col-md-12">
                    <label>Current Manual</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.current_manual}
                      readOnly
                    />
                  </div>
                  <div className="col-md-10">
                    <label>User Id (comma seperated)</label>
                    <input
                      type="text"
                      name="reco_user_id"
                      className="form-control"
                      value={this.state.reco_user_id}
                      onChange={event =>
                        this.setState({
                          reco_user_id: event.target.value.replace(
                            /[^0-9,]/g,
                            ""
                          )
                        })
                      }
                    />
                  </div>
                  <div className="col-md-2 bttn">
                    <label>&nbsp;</label>
                    <div>
                      <Link onClick={this.VerifyUserID} to="/">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                        Verify
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-2 bttn">
                    <label>&nbsp;</label>
                    <div>
                      <button
                        onClick={this.sentEmail}
                        className="bttn"
                        disabled={!this.state.desabledEmailBtn}
                      >
                        Sent Email
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        <div className="gap-20"></div>
        <div className="gap-10"></div>
      </div>
    );
  }
}
export default RecommendedJobs;
