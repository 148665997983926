import LoadingSpinner from "../LoadingSpinner";

function UnverifiedTat({ tatData = {}, loading }) {
  const {
    assigned_recruiters_actioned_today = 0,
    assigned_recruiters_onboarded_today = 0,
    assigned_recruiter_today = 0,
    overall_recruiters_actioned_today = 0,
    overall_recruiters_onboarded_today = 0,
    first_action_tat = 0,
    onboarding_tat = 0,
    weekly_onboarding_percentage = 0
  } = tatData;
  return (
    <div className="row">
      <table
        width="100%"
        border="0"
        cellPadding="4"
        cellSpacing="10"
        className="table table-condensed borderless1 summeryTable"
      >
        <tbody>
          {loading ? (
            <tr>
              <td>
                <LoadingSpinner key="loading" />
              </td>
            </tr>
          ) : (
            <>
              <tr>
                <th>Assigned Recruiter (Today)</th>
                <th>Recruiters Actioned (Today)</th>
                <th>Overall Recruiters Actioned</th>
                <th>Recruiter Onboarded (Today)</th>
                <th>Total Onboarding count</th>
                <th>1st Action TAT (Median)</th>
                <th>Onboarding TAT(Median)</th>
                <th>Weekly Onboarding % (Current Week)</th>
              </tr>
              <tr>
                <td>{assigned_recruiter_today}</td>
                <td>{assigned_recruiters_actioned_today}</td>
                <td>{overall_recruiters_actioned_today}</td>
                <td>{assigned_recruiters_onboarded_today}</td>
                <td>{overall_recruiters_onboarded_today}</td>
                <td>{first_action_tat}</td>
                <td>{onboarding_tat}</td>
                <td>{weekly_onboarding_percentage}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default UnverifiedTat;
