import React from "react";
import CustomTextField from "../Common/Form/CustomTextField";
import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";
import Chips from "../Common/Chips";
import RecruiterStatus from "./RecruiterStatus";
import PremiumIntentCapture from "./PremiumIntentCapture";

const RecruiterVerificationStatus = props => {
  const { getFieldData, onClick, sidebarInfo } = props;
  const {
    remarks = "",
    recruiterStatus = "",
    isDataSaved = false
  } = sidebarInfo;
  const verificationStatus =
    recruiterStatus === "VERIFIED_REPEAT" ||
    recruiterStatus === "VERIFIED_NON_REPEAT"
      ? "VERIFIED"
      : recruiterStatus;
  const chipsList = [
    { label: "Verified", id: "VERIFIED" },
    { label: "Rejected", id: "REJECTED" }
  ];

  const handleClick = () => {
    // No Action
  };

  return (
    <div className="statusContainer">
      <Heading
        heading="Company and Recruiter Verification Status"
        customClass="sideBarHeading"
      />
      <div className="formRow">
        <div className="fullWidth">
          <Label
            lable="Recruiter Status"
            recStatus={<RecruiterStatus recruiterStatus={recruiterStatus} />}
          />
          <Chips
            chips={chipsList}
            activeChips={[verificationStatus]}
            isMulti={false}
            fieldName="rec_status"
            onClick={handleClick}
          />
        </div>
      </div>

      <PremiumIntentCapture sidebarInfo={sidebarInfo} />

      <div className="formRow">
        <div className="fullWidth">
          <Label lable="Remarks" isOptional={true} />
          <CustomTextField
            fieldName={"remarks"}
            name="remarks"
            value={remarks}
            onChange={e => getFieldData("remarks", e.target.value)}
            placeholder="Leave your remarks here..."
            textArea={true}
          />
        </div>
      </div>
      {verificationStatus && verificationStatus === "VERIFIED" ? (
        <div className="formRow">
          <div className="statusBtnContainer">
            <input
              type="button"
              value={
                isDataSaved
                  ? "Recruiter verification done!"
                  : "Verify Job Requirements"
              }
              className={isDataSaved ? "successButton" : "submitBtn"}
              onClick={() => onClick("jobs")}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RecruiterVerificationStatus;
