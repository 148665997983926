import { create } from "zustand";
import { daysOfWeek } from "../services/constant";
import {
  getAllowedLeadTypes,
  getCampaignDetails,
  getCampaignList,
  getCampaignQuestions,
  getLcsMetdadata,
  getLeadClassificationRules,
  getWalkinCampaignDetails,
  saveAllowedLeadTypes,
  saveLeadClassificationRules,
  updateLeadClassificationRules
} from "../services/lcsCampaign";
import { convertTo24Hr, isEmpty } from "../services/utils";

let questionsLoader = null;
const useLcsCampaignDetails = create((set, get) => ({
  campaignData: {},
  isWalkinCampaign: false,
  lcsMetaData: {},
  walkinData: {},
  questionsData: {},
  leadClassificationRules: {},
  interviewDateOptions: [],
  allowedLeadTypes: [],
  fetchState: {
    campaign: "IDLE",
    metadata: "LOADING",
    walkin: "LOADING",
    questions: "LOADING",
    leadClassificationRules: "LOADING",
    saveLeadClassificationRules: "SUCCESS",
    allowedLeadTypes: "LOADING",
    saveAllowedLeadTypes: "SUCCESS"
  },
  error: {
    campaign: "",
    metadata: "",
    walkin: "",
    questions: "",
    leadClassificationRules: "",
    saveLeadClassificationRules: "",
    allowedLeadTypes: "",
    saveAllowedLeadTypes: ""
  },
  formError: {
    walkin: {}
  },
  campaignList: {},
  setFormError: (formType, error) => {
    set(state => ({
      formError: { ...state.formError, [formType]: error }
    }));
  },
  fetchCampaignData: async (campaignId, loading = true) => {
    set(state => ({
      fetchState: {
        ...state.fetchState,
        campaign: loading ? "LOADING" : "IDLE"
      },
      error: { ...state.error, campaign: "" }
    }));

    try {
      const response = await getCampaignDetails(campaignId);
      const jobIds = response?.job_ids?.length
        ? response.job_ids.join(",")
        : "";
      const newData = { ...response, jobIds };
      const { campaign_category: campaignCategory } = newData;
      const isWalkinCampaign = campaignCategory === 2;
      set(state => ({
        campaignData: newData,
        isWalkinCampaign,
        fetchState: { ...state.fetchState, campaign: "SUCCESS" }
      }));
    } catch (err) {
      console.error("Error fetching campaign data:", err);
      set(state => ({
        fetchState: { ...state.fetchState, campaign: "ERROR" },
        error: {
          ...state.error,
          campaign:
            err.message || "An error occurred while fetching campaign data"
        },
        campaignData: {}
      }));
    }
  },

  fetchCampaignMetaData: async () => {
    set(state => ({
      fetchState: { ...state.fetchState, metadata: "LOADING" },
      error: { ...state.error, metadata: "" }
    }));

    try {
      const response = await getLcsMetdadata();
      set(state => ({
        lcsMetaData: response,
        fetchState: { ...state.fetchState, metadata: "SUCCESS" }
      }));
    } catch (err) {
      console.error("Error fetching metadata:", err);
      set(state => ({
        fetchState: { ...state.fetchState, metadata: "ERROR" },
        error: {
          ...state.error,
          metadata: err.message || "An error occurred while fetching metadata"
        },
        lcsMetaData: {}
      }));
    }
  },
  fetchWalkinData: async (campaignId, duplicateCampaignFlow) => {
    set(state => ({
      fetchState: { ...state.fetchState, walkin: "LOADING" },
      error: { ...state.error, walkin: "" }
    }));
    const defaultObj = {
      interviewCenter: [
        {
          interview_center_address: "",
          interview_center_map_link: "",
          center_spoc_name: "",
          center_spoc_phone: [],
          center_label: ""
        }
      ],
      interviewDateDetails: {
        interview_start_date: "",
        interview_end_date: "",
        excluded_week_days: [],
        excluded_dates: [],
        start_time: "",
        end_time: ""
      },
      interviewSteps: {}
    };
    try {
      let response = await getWalkinCampaignDetails(campaignId);
      const {
        interview_date_details: interviewDateDetails = {},
        interview_center_details: interviewCenter = [],
        interview_steps: interviewSteps = {}
      } = response;
      if (duplicateCampaignFlow && interviewCenter.length) {
        response.interviewCenter = interviewCenter.map(center => {
          delete center.id; // cannot send id related to parent campaign in the new campaign during duplicate flow
          return center;
        });
      }

      response = {
        interview_center_details: interviewCenter?.length
          ? interviewCenter
          : defaultObj.interviewCenter,
        interview_date_details: {
          interview_start_date:
            interviewDateDetails?.interview_start_date ??
            defaultObj.interviewDateDetails.interview_start_date,
          interview_end_date:
            interviewDateDetails?.interview_end_date ??
            defaultObj.interviewDateDetails.interview_end_date,
          excluded_dates: interviewDateDetails?.excluded_dates?.length
            ? interviewDateDetails.excluded_dates
            : defaultObj.interviewDateDetails.excluded_dates,
          excluded_week_days: interviewDateDetails?.excluded_week_days?.length
            ? daysOfWeek.filter(
                item =>
                  interviewDateDetails?.excluded_week_days.indexOf(item.id) !==
                  -1
              )
            : defaultObj.interviewDateDetails.excluded_week_days,
          start_time: interviewDateDetails?.start_time
            ? convertTo24Hr(interviewDateDetails?.start_time)
            : defaultObj.interviewDateDetails.start_time,
          end_time: interviewDateDetails?.end_time
            ? convertTo24Hr(interviewDateDetails?.end_time)
            : defaultObj.interviewDateDetails.end_time
        },
        interview_steps: !isEmpty(interviewSteps)
          ? interviewSteps
          : defaultObj.interviewSteps
      };
      set(state => ({
        walkinData: response,
        fetchState: { ...state.fetchState, walkin: "SUCCESS" }
      }));
    } catch (err) {
      console.error("Error fetching walkin data:", err);
      set(state => ({
        fetchState: { ...state.fetchState, walkin: "ERROR" },
        error: {
          ...state.error,
          walkin: err.message || "An error occurred while fetching walkin"
        },
        walkinData: {
          interview_center_details: defaultObj.interviewCenter,
          interview_date_details: defaultObj.interviewDateDetails,
          interview_steps: defaultObj.interviewSteps
        }
      }));
    }
  },
  fetchQuestionData: async (
    campaignId,
    duplicateCampaignFlow,
    campaignIdToBeSaved
  ) => {
    set(state => ({
      fetchState: {
        ...state.fetchState,
        questions: "LOADING"
      },
      error: { ...state.error, questions: "" }
    }));

    try {
      const response = await getCampaignQuestions(campaignId);
      response.questions = response.questions.map(question => {
        if (duplicateCampaignFlow) {
          question.question_id = null; // while duplicating the campaign we cannot use ids from the campaign we are using to duplicate
          question.options = question.options.map(option => {
            option.option_id = null;
            option.next_question_id = 0; // resetting all next question ids as it requires real ids
            return option;
          });
        }
        question.customOptions = [];
        if (question.question_category === "interview_date") {
          question.customOptions = question.options.filter(
            option => option.option_type !== "interview_date"
          );
          if (duplicateCampaignFlow) question.options = [];
          // cannot send duplicate options in the new campaign flow
          //as dates for new campaign are already dupilcated in walkin tab
          else {
            question.options = question.options.filter(
              option => option.option_type === "interview_date"
            );
            question.date_next_question_id =
              question.options?.[0]?.next_question_id ?? 0;
            set({
              interviewDateOptions: question.options // saving dates to send them in save api and add next q id
            });
            question.options = []; // cannot render date options
          }
        } else if (question.question_category === "interview_center") {
          question.customOptions = question.options.filter(
            option => option.option_type !== "interview_center"
          );
          if (duplicateCampaignFlow) question.options = [];
          // cannot send duplicate options in the new campaign flow
          //as centers for new campaign are already dupilcated in walkin tab
          else
            question.options = question.options.filter(
              option => option.option_type === "interview_center"
            );
        }
        return question;
      });
      set(state => ({
        questionsData: { ...response, campaign_id: campaignIdToBeSaved },
        fetchState: { ...state.fetchState, questions: "SUCCESS" }
      }));
      return response;
    } catch (err) {
      console.error("Error fetching campaign questions data:", err);
      set(state => ({
        fetchState: { ...state.fetchState, questions: "ERROR" },
        error: {
          ...state.error,
          questions:
            err.message ||
            "An error occurred while fetching campaign questions data"
        },
        questionsData: {}
      }));
    }
  },
  fetchLeadClassificationRules: async params => {
    set(state => ({
      fetchState: {
        ...state.fetchState,
        leadClassificationRules: "LOADING"
      },
      error: { ...state.error, leadClassificationRules: "" }
    }));
    try {
      const response = await getLeadClassificationRules(params);
      set(state => ({
        leadClassificationRules: response,
        fetchState: { ...state.fetchState, leadClassificationRules: "SUCCESS" }
      }));
    } catch (error) {
      set(state => ({
        fetchState: { ...state.fetchState, leadClassificationRules: "ERROR" },
        error: {
          ...state.error,
          leadClassificationRules:
            error.message ||
            "An error occurred while fetching classification rules"
        },
        leadClassificationRules: {}
      }));
    }
  },
  saveUpdateLeadClassificationRules: async payload => {
    set(state => ({
      fetchState: {
        ...state.fetchState,
        saveLeadClassificationRules: "LOADING"
      },
      error: { ...state.error, saveLeadClassificationRules: "" }
    }));

    try {
      const newRules = [];
      const updatedRules = [];
      payload.rules.forEach(rule => {
        if (rule.hasOwnProperty("id")) updatedRules.push(rule);
        else newRules.push(rule);
      });
      if (newRules.length)
        await saveLeadClassificationRules({
          ...payload,
          rules: newRules
        });
      if (updatedRules.length)
        await updateLeadClassificationRules({
          ...payload,
          rules: updatedRules
        });

      set(state => ({
        fetchState: {
          ...state.fetchState,
          saveLeadClassificationRules: "SUCCESS"
        }
      }));
    } catch (error) {
      set(state => ({
        fetchState: {
          ...state.fetchState,
          saveLeadClassificationRules: "ERROR"
        },
        error: {
          ...state.error,
          saveLeadClassificationRules:
            error.message ||
            "An error occurred while saving classification rules"
        }
      }));
    }
  },
  resetQuestionsData: () => {
    clearTimeout(questionsLoader);
    set(state => ({
      fetchState: {
        ...state.fetchState,
        questions: "LOADING"
      },
      error: { ...state.error, questions: "" },
      questionsData: {}
    }));
  },
  setQuestionsData: data => {
    set(state => ({
      fetchState: {
        ...state.fetchState,
        questions: "LOADING"
      },
      questionsData: {
        ...get().questionsData,
        ...data
      }
    }));
    questionsLoader = setTimeout(() => {
      set(state => ({
        fetchState: {
          ...state.fetchState,
          questions: "SUCCESS"
        }
      }));
    }, 500);
  },
  fetchAllowedLeadTypes: async campaignId => {
    set(state => ({
      fetchState: {
        ...state.fetchState,
        allowedLeadTypes: "LOADING"
      },
      error: { ...state.error, allowedLeadTypes: "" }
    }));
    try {
      const response = await getAllowedLeadTypes(campaignId);
      set(state => ({
        allowedLeadTypes: response,
        fetchState: { ...state.fetchState, allowedLeadTypes: "SUCCESS" }
      }));
    } catch (error) {
      set(state => ({
        fetchState: { ...state.fetchState, allowedLeadTypes: "ERROR" },
        error: {
          ...state.error,
          allowedLeadTypes:
            error.message ||
            "An error occurred while fetching allowed lead types"
        },
        allowedLeadTypes: []
      }));
    }
  },
  callSaveAllowedLeadTypes: async payload => {
    set(state => ({
      fetchState: {
        ...state.fetchState,
        saveAllowedLeadTypes: "LOADING"
      },
      error: { ...state.error, saveAllowedLeadTypes: "" }
    }));

    try {
      await saveAllowedLeadTypes(payload);
      set(state => ({
        fetchState: {
          ...state.fetchState,
          saveAllowedLeadTypes: "SUCCESS"
        }
      }));
    } catch (error) {
      set(state => ({
        fetchState: {
          ...state.fetchState,
          saveAllowedLeadTypes: "ERROR"
        },
        error: {
          ...state.error,
          saveAllowedLeadTypes:
            error.message || "An error occurred while saving allowed lead types"
        }
      }));
    }
  },

  fetchCampaignList: async payload => {
    set(state => ({
      fetchState: { ...state.fetchState, campaign: "LOADING" },
      error: { ...state.error, campaign: "" }
    }));
    try {
      const response = await getCampaignList(payload);
      set(state => ({
        campaignList: response,
        fetchState: { ...state.fetchState, campaign: "SUCCESS" }
      }));
    } catch (err) {
      console.error("Error fetching metadata:", err);
      set(state => ({
        fetchState: { ...state.fetchState, campaign: "ERROR" },
        error: {
          ...state.error,
          campaign: err.message || "An error occurred while fetching metadata"
        },
        campaignList: {}
      }));
    }
  }
}));

export default useLcsCampaignDetails;
