import React from "react";
import { useFieldArray } from "react-hook-form";
import classes from "./lcsCampaignDetails.module.css";
import BannerDetails from "./BannerDetails";

const DashboardBanner = props => {
  const { control, languageList, setError, name, watch } = props;
  const {
    fields: dashboardBannerFields,
    append,
    remove
  } = useFieldArray({
    name,
    control
  });
  const onAddMoreBanner = () => {
    append({ language: "", heading: "", file: "" }); // Add a new empty banner item
  };

  const showAddMore = dashboardBannerFields.length < languageList.length;

  return (
    <div className={classes.fullWidth}>
      <div className={classes.questioncnt}>
        {dashboardBannerFields.map((item, index) => (
          <BannerDetails
            item={item}
            index={index}
            key={item.id}
            watch={watch}
            name={name}
            control={control}
            languageList={languageList}
            setError={setError}
            remove={remove}
            numberOfBannerFields={dashboardBannerFields.length}
            isCarouselImage={true}
            bannerLabel="Full Banner"
          />
        ))}
        {showAddMore ? (
          <div
            type="button"
            onClick={onAddMoreBanner}
            className={classes.addMore}
          >
            Add More
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(DashboardBanner);
