import React from "react";
import JobHai from "../../../api/JobHai";
import { getErrorMessage } from "../../../services/utils";
import Label from "../../Common/Form/Label";
import Chips from "../../Common/Chips";
import CustomTextField from "../../Common/Form/CustomTextField";

class IndustryTagging extends React.Component {
  state = {
    tagsData: {
      industries: [],
      sub_industries: []
    },
    selectedTags: {
      industries: [],
      sub_industries: []
    },
    extraTags: {
      industries: [],
      sub_industries: []
    },
    isTagChanged: false,
    isOtherIndustry: false,
    isOtherSubIndustry: false
  };

  updateMessage = msg => {
    this.props.statusMessage(msg, 1);
  };

  handleChangeTags = event => {
    const { name, value } = event.target;
    this.updateOtherFields(name, value);
    this.props.handleIndustryClick();
  };

  updateOtherFields = (name, value) => {
    const array = this.state.extraTags;
    array[name] = value ? [value] : [];
    this.setState({ extraTags: array, isTagChanged: true });
  };

  getTagList = () => {
    JobHai.get("../metadata/tags").then(metadata => {
      const tagsData = metadata.data.data;
      this.setState({
        tagsData: tagsData || []
      });
      localStorage.setItem("recTags", JSON.stringify(tagsData));
    });
  };

  getUpdatedTagData = () => {
    const { recId } = this.props;
    JobHai.get(`tags/get_tags?recruiter_id=${recId}`)
      .then(response => {
        let responseData = response.data.data.tags;
        const { predefined_tags = [], extra_tags = [] } = responseData;
        let { industries = [], sub_industries = [] } = predefined_tags;
        const otherIndustry = extra_tags.industries;
        const otherSubIndustry = extra_tags.sub_industries;
        if (otherIndustry.length) industries.push("add_another");
        if (otherSubIndustry.length) sub_industries.push("add_another_sub");
        this.setState({
          selectedTags: {
            industries,
            sub_industries
          },
          extraTags: {
            industries: extra_tags.industries,
            sub_industries: extra_tags.sub_industries
          },
          initialActiveTags: { industries, sub_industries },
          isOtherIndustry: otherIndustry.length,
          isOtherSubIndustry: otherSubIndustry.length
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.updateMessage(msg);
      });
  };

  componentDidMount() {
    let crmTags = {};
    if (localStorage.getItem("recTags")) {
      crmTags = JSON.parse(localStorage.getItem("recTags"));
      if (crmTags.industries && crmTags.industries.length) {
        this.setState({
          tagsData: crmTags
        });
      }
    } else {
      this.getTagList();
    }
    this.getUpdatedTagData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isSaved } = this.props;
    const { isTagChanged } = this.state;
    if (isSaved !== prevProps.isSaved) {
      if (isTagChanged && isSaved) this.saveTags();
    }
  }

  saveTags = () => {
    let { extraTags, selectedTags } = this.state;
    const { industries = [], sub_industries = [] } = selectedTags;
    if (!industries.length && !extraTags.industries) {
      return;
    }
    const payload = {
      industries: industries.filter(i => i !== "add_another"),
      sub_industries: sub_industries.filter(i => i !== "add_another_sub")
    };
    const objAdd = {
      recruiter_id: this.props.recId,
      payload,
      extra_payload: extraTags
    };
    JobHai.post("/tags/add_recruiter_tags", objAdd)
      .then(() => {
        this.removeTags();
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.updateMessage(msg);
      });
  };

  removeTags = () => {
    let activeTags = this.state.selectedTags;
    let tagging = this.state.initialActiveTags;
    console.log(activeTags, tagging);
    tagging.industries = tagging.industries.filter(
      x => !activeTags.industries.includes(x)
    );
    tagging.sub_industries = tagging.sub_industries.filter(
      x => !activeTags.sub_industries.includes(x)
    );
    console.log(activeTags, tagging);

    const obj = {
      recruiter_id: this.props.recId,
      payload: tagging
    };
    JobHai.post("/tags/remove_recruiter_tags", obj)
      .then(() => {
        this.getUpdatedTagData();
      })
      .catch(function (error) {
        let msg = getErrorMessage(error);
        this.updateMessage(msg);
      });
  };

  handleChips = (event, fieldName, item) => {
    let id = item.id;
    let { selectedTags } = this.state;
    let tempArray = selectedTags[fieldName];
    const isInArray = tempArray.filter(item => item === id).length;
    if (isInArray) {
      tempArray = tempArray.filter(item => item !== id);
    } else {
      tempArray.push(id);
    }
    selectedTags[fieldName] = tempArray;
    this.setState({
      selectedTags,
      isTagChanged: true
    });
    if (id === "add_another") {
      this.setState({
        isOtherIndustry: id === "add_another" && !isInArray
      });
      this.updateOtherFields("industries", "");
    }
    if (id === "add_another_sub") {
      this.setState({
        isOtherSubIndustry: id === "add_another_sub" && !isInArray
      });
      this.updateOtherFields("sub_industries", "");
    }
    this.props.handleIndustryClick();
  };

  render() {
    const {
      tagsData,
      selectedTags,
      extraTags,
      isOtherIndustry,
      isOtherSubIndustry
    } = this.state;
    let selectedIndustry = selectedTags.industries;
    let selectedSubIndustry = selectedTags.sub_industries;
    let industriesList = tagsData.industries ? tagsData.industries : [];
    let subIndustriesList = tagsData.sub_industries
      ? tagsData.sub_industries.filter(item => {
          return selectedIndustry.some(d => {
            return item.industry_id === d;
          });
        })
      : [];

    industriesList = [
      ...industriesList,
      { id: "add_another", title: "Add another" }
    ];
    subIndustriesList = [
      ...subIndustriesList,
      { id: "add_another_sub", title: "Add another" }
    ];

    return (
      <>
        <div className="formRow">
          <div className="fullWidth">
            <Label lable="Industry" />
            <Chips
              chips={industriesList}
              activeChips={selectedIndustry}
              onClick={this.handleChips}
              fieldName="industries"
              label="title"
            />
          </div>
        </div>
        {isOtherIndustry ? (
          <div className="formRow">
            <div className="fullWidth">
              <CustomTextField
                name="industries"
                value={extraTags.industries}
                onChange={this.handleChangeTags}
                placeholder="Type Industry here..."
              />
            </div>
          </div>
        ) : null}
        {selectedIndustry.length ? (
          <>
            <div className="formRow">
              <div className="fullWidth">
                <Label lable="Sub Industry" />
                <Chips
                  chips={subIndustriesList}
                  activeChips={selectedSubIndustry}
                  onClick={this.handleChips}
                  fieldName="sub_industries"
                  label="title"
                />
              </div>
            </div>
            {isOtherSubIndustry ? (
              <div className="formRow">
                <div className="fullWidth">
                  <CustomTextField
                    name="sub_industries"
                    value={extraTags.sub_industries}
                    onChange={this.handleChangeTags}
                    placeholder="Type Sub Industry here..."
                  />
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}
export default IndustryTagging;
