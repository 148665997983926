import JobHai from "../../../api/JobHai";

const CloseLead = ({ recId, callBack }) => {
  const closeLead = () => {
    if (
      window.confirm("Are you sure you want to close this lead permanently?")
    ) {
      JobHai.post("/cat-close-lead", { recruiter_id: recId })
        .then(response => {
          callBack();
        })
        .catch(error => {
          console.log("Error: ", error);
        });
    }
  };
  return (
    <>
      <button className="btn btn-danger postjob" onClick={closeLead}>
        Terminate
      </button>
    </>
  );
};
export default CloseLead;
