import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter
} from "../../ui/card";
import { Popover, PopoverTrigger, PopoverContent } from "../../ui/popover";
import { Calendar } from "../../ui/calendar";
import { Textarea } from "../../ui/textarea";
import { PhoneIcon } from "lucide-react";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
} from "../../ui/select";
import { ClockIcon } from "lucide-react";
import { ToggleGroup, ToggleGroupItem } from "../../ui/toggle-group";
import { useEffect, useState } from "react";
import moment from "moment";
import { adjustHours } from "../../../services/dateUtils";
import useLeadRecruiterDetailStore from "../../../zustandService/useLeadRecruiterDetailStore";
import StateRenderer from "./stateRenderer";
import { lmsBuckets } from "../../../services/constant";
import { redirectURL } from "../../../services/utils";
import { LFMS } from "../../../routes/RouteConstants";
import useLmsRedirectionStore from "../../../zustandService/useRedirectionStore";
import {
  getTimes,
  linkMaps,
  defaultStates,
  checkDisabledValue,
  checkSelectedValue,
  getStatusMaps
} from "../../../services/callStatusUtils";
import { reviveLead } from "../../../services/insideSales";

let CallStatus = () => {
  const {
    callDetailsState,
    postCall,
    callDetails,
    recruiterDetails,
    leadDetails
  } = useLeadRecruiterDetailStore(state => ({
    callDetailsState: state.callDetailsState,
    postCall: state.postCall,
    callDetails: state.callDetails,
    recruiterDetails: state.recruiterDetails,
    leadDetails: state.leadDetails
  }));
  const { setRedirectionData } = useLmsRedirectionStore(state => ({
    setRedirectionData: state.setRedirectionData
  }));

  const [currentBindings, setCurrentBindings] = useState({});
  const [followUpDate, setFollowUpDate] = useState();
  const [followUpTime, setFollowUpTime] = useState({});
  const [markCall, setMarkCall] = useState(false);
  const searchParams = window.location.search || "";
  const queryParams = new URLSearchParams(searchParams);
  const recId = queryParams.get("recruiterId");
  const bucketId = leadDetails?.bucketId;

  const leadId = queryParams.get("leadId");
  const [remarks, setRemarks] = useState("");
  const [reviveStatus, setReviveStatus] = useState("IDLE");
  const {
    remarks: storeRemarks = "",
    followUpDate: storeDate,
    followUpTime: storeTime
  } = callDetails;
  useEffect(() => {
    setRemarks(storeRemarks || "");
    if (storeDate) {
      setFollowUpDate(moment(storeDate));
    }
    if (storeTime) {
      const momentTime = moment(storeTime, "HH:mm");
      var hour = momentTime.format("hh");
      var minute = momentTime.format("mm");
      var ampm = momentTime.format("A");
      setFollowUpTime({ hour: hour, minute: minute, ampm: ampm });
    }
  }, [storeRemarks, storeDate, storeTime]);
  let bucket = lmsBuckets[bucketId]?.name;
  useEffect(() => {
    if (bucket === "warm") {
      setCurrentBindings({
        ...currentBindings,
        pitchCompleted: "Yes",
        interested: "Yes"
      });
    }
    if (bucket === "hot") {
      setCurrentBindings({
        ...currentBindings,
        pitchCompleted: "Yes",
        interested: "Yes",
        paymentLinkGenerated: "Yes"
      });
    }
  }, [bucket]);
  const statusMaps = getStatusMaps({ bucket: bucket, currentBindings });

  const updateStateAndTime = hours => {
    if (hours == null) {
      setFollowUpDate(null);

      setFollowUpTime(null);
      return;
    }
    const adjustedTime = adjustHours(hours);
    setFollowUpDate(adjustedTime);
    const { hour, minute, ampm } = getTimes(adjustedTime);
    setFollowUpTime({ hour, minute, ampm });
  };
  function onSelectStatus(status, chip) {
    if (!chip) return;

    if (chip === "paymentLinkGenerated") {
      // const adjustedTime = adjustHours(24);
      // setFollowUpDate(adjustedTime);
      // const { hour, minute, ampm } = getTimes(adjustedTime);
      // setFollowUpTime({ hour: hour, minute: minute, ampm: ampm });
      updateStateAndTime(24);
    }
    if (bucket === "cold" || bucket === "new") {
      if (status === "No") {
        if (chip == "callConnected") {
          const obj = JSON.parse(
            JSON.stringify({ ...defaultStates[bucket], callConnected: "No" })
          );

          setCurrentBindings(obj);
          // const adjustedTime = adjustHours(2);
          // setFollowUpDate(adjustedTime);
          // const { hour, minute, ampm } = getTimes(adjustedTime);
          // setFollowUpTime({ hour: hour, minute: minute, ampm: ampm });
          updateStateAndTime(2);
          return;
        }
        if (chip == "pitchCompleted") {
          const obj = JSON.parse(
            JSON.stringify({
              ...defaultStates[bucket],
              ...currentBindings,
              pitchCompleted: "No",
              interested: null,
              paymentLinkGenerated: null
            })
          );

          setCurrentBindings(obj);
          // const adjustedTime = adjustHours(2);
          // setFollowUpDate(adjustedTime);
          // const { hour, minute, ampm } = getTimes(adjustedTime);
          // setFollowUpTime({ hour: hour, minute: minute, ampm: ampm });
          updateStateAndTime(2);
          return;
        }
        if (chip == "interested") {
          const obj = JSON.parse(
            JSON.stringify({
              ...defaultStates[bucket],
              ...currentBindings,
              interested: "No",
              paymentLinkGenerated: null
            })
          );

          setCurrentBindings(obj);
          // setFollowUpDate(null);

          // setFollowUpTime(null);
          updateStateAndTime(null);
          return;
        }
      }
    }
    if (bucket === "hot") {
      if (status === "No") {
        if (chip === "callConnected") {
          const obj = JSON.parse(
            JSON.stringify({ ...defaultStates[bucket], callConnected: "No" })
          );
          setCurrentBindings(obj);
          // const adjustedTime = adjustHours(24);
          // setFollowUpDate(adjustedTime);
          // const { hour, minute, ampm } = getTimes(adjustedTime);
          // setFollowUpTime({ hour: hour, minute: minute, ampm: ampm });
          updateStateAndTime(24);
          return;
        }
        if (chip === "interested") {
          const obj = JSON.parse(
            JSON.stringify({
              ...defaultStates[bucket],
              ...currentBindings,
              interested: "No"
            })
          );
          setCurrentBindings(obj);
          // setFollowUpDate(null);
          // setFollowUpTime(null);
          updateStateAndTime(null);
          return;
        }
      }
    }
    if (bucket === "warm") {
      if (status === "No") {
        if (chip === "callConnected") {
          const obj = JSON.parse(
            JSON.stringify({ ...defaultStates[bucket], callConnected: "No" })
          );
          setCurrentBindings(obj);
          // const adjustedTime = adjustHours(2);
          // setFollowUpDate(adjustedTime);
          // const { hour, minute, ampm } = getTimes(adjustedTime);
          // setFollowUpTime({ hour: hour, minute: minute, ampm: ampm });
          updateStateAndTime(2);
          return;
        }
        if (chip === "interested") {
          const obj = JSON.parse(
            JSON.stringify({
              ...defaultStates[bucket],
              ...currentBindings,
              interested: "No",
              paymentLinkGenerated: null
            })
          );
          setCurrentBindings(obj);
          // setFollowUpDate(null);

          // setFollowUpTime(null);
          updateStateAndTime(null);
          return;
        }
      }
    }
    setCurrentBindings({ ...currentBindings, [chip]: status });
  }

  async function onMarkCall() {
    try {
      const activeButNotSelected = statusMaps.filter(
        item =>
          (item.disabled === undefined || item.disabled === false) &&
          !item.selected
      );
      if (activeButNotSelected.length) {
        alert("Please select required options to mark call");
        return;
      }
      setMarkCall(true);
      const res = await postCall({
        statusMaps,
        followUpDate,
        followUpTime,
        remarks,
        leadId,
        bucketId
      });
      setRedirectionData({
        recruiterId: recId,
        bucketId: res.updatedBucket || bucketId,
        leadId: leadId,
        recruiterName: recruiterDetails?.recruiterName || ""
      });
      setTimeout(() => {
        redirectURL(`${LFMS}`);
      }, 100);
    } catch (err) {
      alert("FATAL ERROR");
      setRedirectionData(null);
      setTimeout(() => {
        setMarkCall(false);
      }, 1000);
    }
  }
  function onTimeUpdate(val, key) {
    setFollowUpTime({ ...followUpTime, [key]: val });
  }

  if (!leadDetails?.bucketId) return <StateRenderer state="IDLE" />;
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Ensure the time is set to midnight

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  async function onReviveCall() {
    try {
      setReviveStatus("PENDING");
      const res = await reviveLead({ leadId });
      console.log("res", res);
      setReviveStatus("FINISHED");
      setRedirectionData({
        recruiterId: recId,
        bucketId: 1,
        leadId: res.leadId,
        recruiterName: recruiterDetails?.recruiterName || ""
      });
      setTimeout(() => {
        redirectURL(`${LFMS}`);
      }, 100);
    } catch (error) {
      setRedirectionData(null);
      setReviveStatus("IDLE");
      alert("Something went wrong");
    }
  }
  const revLead =
    (bucket === "sales" || bucket === "churned") &&
    reviveStatus !== "FINISHED" &&
    leadDetails?.isRevived != true;
  return (
    <StateRenderer state={callDetailsState}>
      <Card className="">
        <CardHeader>
          <div className="flex items-center space-x-3">
            <div className="flex items-center justify-center text-blue-500 rounded-md size-10 bg-blue-50">
              <PhoneIcon />
            </div>
            <CardTitle className="text-xl">Call status</CardTitle>
          </div>
        </CardHeader>
        {!revLead ? (
          <CardContent className="space-y-4">
            <div className="grid gap-2">
              {statusMaps.map(status => {
                return (
                  <div
                    key={status.index}
                    className="flex items-center justify-between "
                  >
                    <div className="">{status.view}</div>
                    <ToggleGroup
                      onValueChange={data => {
                        if (!data) return;
                        onSelectStatus(data, status.name);
                      }}
                      disabled={status.disabled || false}
                      // defaultValue={status.selected === true ? "Yes" : null}
                      value={status.selected ? status.selected : null}
                      type="single"
                      variant="outline"
                    >
                      <ToggleGroupItem value="Yes">Yes</ToggleGroupItem>
                      <ToggleGroupItem value="No">No</ToggleGroupItem>
                    </ToggleGroup>
                  </div>
                );
              })}
            </div>
            <div className="space-y-2 ">
              <Label className="">Follow up date</Label>
              <Popover className="">
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="flex-col items-start w-full h-auto"
                  >
                    <span className="font-normal">
                      {followUpDate
                        ? moment(followUpDate).format("DD-MM-YYYY")
                        : "Select Date"}
                    </span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0 max-w-[276px]">
                  <Calendar
                    disabled={date => date <= yesterday}
                    mode="single"
                    selected={followUpDate}
                    onSelect={date => {
                      if (!date) return;

                      setFollowUpDate(date);
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className="flex flex-col justify-between w-full space-y-2 items-left ">
              <Label className="">Follow up time</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="w-[200px] justify-start text-left font-normal"
                  >
                    <ClockIcon className="w-4 h-4 mr-2" />
                    {followUpTime?.hour ? (
                      `${followUpTime.hour}:${followUpTime.minute}`
                    ) : (
                      <span>Select Time</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[240px] p-4 space-y-4">
                  <div className="flex items-center justify-between">
                    <h4 className="font-semibold">Time</h4>
                    <div className="flex items-center gap-2">
                      <Select
                        onValueChange={val => onTimeUpdate(val, "hour")}
                        value={followUpTime?.hour}
                      >
                        <SelectTrigger className="w-16 h-8">
                          <SelectValue placeholder="Hour" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="01">1 PM</SelectItem>
                          <SelectItem value="02">2 PM</SelectItem>
                          <SelectItem value="03">3 PM</SelectItem>
                          <SelectItem value="04">4 PM</SelectItem>
                          <SelectItem value="05">5 PM</SelectItem>
                          <SelectItem value="06">6 PM</SelectItem>
                          <SelectItem value="07">7 PM</SelectItem>
                          {/* <SelectItem value="8">8</SelectItem> */}
                          <SelectItem value="09">9 AM</SelectItem>
                          <SelectItem value="10">10 AM</SelectItem>
                          <SelectItem value="11">11 AM</SelectItem>
                          <SelectItem value="12">12 PM</SelectItem>
                        </SelectContent>
                      </Select>
                      <span>:</span>
                      <Select
                        onValueChange={val => onTimeUpdate(val, "minute")}
                        value={followUpTime?.minute}
                      >
                        <SelectTrigger className="w-16 h-8">
                          <SelectValue placeholder="Minute" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="00">00</SelectItem>
                          <SelectItem value="15">15</SelectItem>
                          <SelectItem value="30">30</SelectItem>
                          <SelectItem value="45">45</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  {/* <div className="flex justify-end gap-2">
                  <Button variant="ghost" size="sm">
                    Cancel
                  </Button>
                  <Button size="sm">Select</Button>
                </div> */}
                </PopoverContent>
              </Popover>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="remarks">Remarks</Label>
              <Textarea
                value={remarks}
                onChange={e => {
                  setRemarks(e.target.value);
                }}
                id="remarks"
                placeholder="Add your remarks..."
              />
            </div>
          </CardContent>
        ) : null}
        <CardFooter>
          {bucket === "hot" ||
          bucket === "new" ||
          bucket === "cold" ||
          bucket === "warm" ? (
            <Button
              onClick={onMarkCall}
              disabled={markCall}
              className="mr-auto"
            >
              Mark Call
            </Button>
          ) : null}
          {revLead ? (
            <div className="flex-col w-100">
              <Button
                onClick={onReviveCall}
                disabled={reviveStatus == "PENDING"}
                className="mr-auto"
              >
                Revive Lead
              </Button>
              <div className=" w-100 mt-2">
                <Label htmlFor="remarks">Remarks</Label>
                <Textarea
                  value={remarks}
                  onChange={e => {
                    setRemarks(e.target.value);
                  }}
                  id="remarks"
                  placeholder="Add your remarks..."
                />
              </div>
            </div>
          ) : null}
        </CardFooter>
      </Card>
    </StateRenderer>
  );
};

export default CallStatus;
