import React from "react";
import JobHai from "../../../api/JobHai";
import { commonTextFieldLimit } from "../../../services/constant";
import AutoSearch from "../../Common/Form/AutoSearch";
import {
  getLocalStorage,
  setLocalStorage
} from "../../../services/localStorage";

class JobTitleJobsPage extends React.Component {
  constructor() {
    super();
    this.state = {
      optionsList: []
    };
  }

  getTitleCategortList = () => {
    JobHai.get("../metadata/job_titles")
      .then(metadata => {
        let dataList = metadata.data.data;
        this.setState({
          optionsList: dataList ? dataList : []
        });
        setLocalStorage("crmJobTitle", JSON.stringify(dataList));
      })
      .catch();
  };

  componentDidMount() {
    let crmJobTitle = JSON.parse(getLocalStorage("crmJobTitle")) || [];
    if (crmJobTitle.length > 0) {
      this.setState({
        optionsList: crmJobTitle
      });
    } else {
      this.getTitleCategortList();
    }
  }

  getSelectedValue = obj => {
    this.props.onChange(obj);
  };

  render() {
    const { optionsList } = this.state;
    let options = [];
    optionsList.map(item => {
      return options.push({ id: item.category_id, label: item.title });
    });
    const { id = "search-title", placeholder, selected } = this.props;
    return (
      <AutoSearch
        dataList={options}
        getSelectedValue={this.getSelectedValue}
        getSelectedInput={e => {}}
        placeholder={placeholder}
        variant="outlined"
        id={id}
        error={false}
        searchValue={selected}
        defaultSearch={true}
        searchType={"array"}
        maxLength={commonTextFieldLimit}
      />
    );
  }
}

export default JobTitleJobsPage;
