import { REPORTED_JOBS } from "../../../routes/RouteConstants";

const RecruiterCluster = props => {
  const { clusterItem } = props;
  const {
    cluster_size = "",
    asked_for_money_count = "",
    cluster_recruiter_ids = [],
    asked_for_money_recruiter_ids = []
  } = clusterItem;
  return (
    <div className="clusterItem">
      <ul>
        <li>
          Cluster Size :{" "}
          {cluster_size ? (
            <a
              href={`${REPORTED_JOBS}?recIds=${cluster_recruiter_ids.join()}`}
              title="View Total Cluster Size"
              target="_blank"
              rel="noopener noreferrer"
            >
              {cluster_size}
            </a>
          ) : (
            cluster_size
          )}
        </li>
        <li>
          Asked For Money Count :{" "}
          {asked_for_money_count ? (
            <a
              href={`${REPORTED_JOBS}?recIds=${asked_for_money_recruiter_ids.join()}&reportReason=Recruiter asked for money`}
              title="View Total Asked For Money"
              target="_blank"
              rel="noopener noreferrer"
            >
              {asked_for_money_count}
            </a>
          ) : (
            asked_for_money_count
          )}
        </li>
      </ul>
    </div>
  );
};
export default RecruiterCluster;
