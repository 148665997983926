import React from "react";
import Heading from "../Common/Form/Heading";
import { getNthItemText } from "../../services/utils";

const LeadVerificationStatus = props => {
  const { sidebarInfo, onClick } = props;
  const { otherJobList, isJobLiveClicked, disabled } = sidebarInfo;
  let index = otherJobList.length;
  const jobNumber = getNthItemText(index);

  let ctaLabel = "Post this job";
  let successMesage = "Job Auto Posted!";

  return (
    <div className="jobStatusContainer">
      <div className="rowContainer">
        <Heading
          heading={`${index + 1}${jobNumber} Job Status`}
          customClass="sideBarHeading"
        />
      </div>

      <div className="formRow">
        <div className="statusBtnContainer">
          {isJobLiveClicked ? (
            <input
              type="button"
              value={successMesage}
              className={"successButton"}
            />
          ) : (
            <input
              type="button"
              value={ctaLabel}
              className={"submitBtn"}
              onClick={onClick}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadVerificationStatus;
