import {
  AlertCircle,
  CheckCheckIcon,
  FlameIcon,
  ListIcon,
  Repeat2Icon,
  ShieldBanIcon,
  SparkleIcon,
  ThermometerSunIcon
} from "lucide-react";
import { ToggleGroup, ToggleGroupItem } from "../../ui/toggle-group";
import useInsideLeadStore from "../../../zustandService/useInsideLeadStore";
import useMetadataStore from "../../../zustandService/useMetadataStore";

const bucketIconsMapping = [
  {
    id: 1,
    component: <SparkleIcon size={16} className="mr-2 text-purple-500" />
  },
  {
    id: 2,
    component: <FlameIcon size={16} className="mr-2 text-red-500" />
  },
  {
    id: 3,
    component: <ThermometerSunIcon size={16} className="mr-2 text-yellow-500" />
  },
  {
    id: 4,
    component: <Repeat2Icon size={16} className="mr-2 text-blue-500" />
  },
  {
    id: 5,
    component: <CheckCheckIcon size={16} className="mr-2 text-green-500" />
  },
  {
    id: 6,
    component: <AlertCircle size={16} className="mr-2 text-red-500" />
  },
  {
    id: 7,
    component: <ShieldBanIcon size={16} className="mr-2 text-red-500" />
  }
];

const LeadBucket = ({ bucketCountData = [], handleBucket }) => {
  const { bucketCounts = [], allBucketData = {} } = bucketCountData;

  const { activeBucket, setActiveBucket } = useInsideLeadStore(state => ({
    activeBucket: state.activeBucket,
    setActiveBucket: state.setActiveBucket
  }));

  const { insideSalesBuckets } = useMetadataStore(state => ({
    insideSalesBuckets: state.insideSalesBuckets
  }));

  // Create a map for bucket counts for quick access
  const bucketCountMap = bucketCounts.reduce((acc, curr) => {
    acc[curr.id] = curr.totalBucketCount;
    return acc;
  }, {});

  const handleClick = bucketId => {
    handleBucket(bucketId);
    setActiveBucket(bucketId); // Set active bucket in Zustand store
  };

  if (!insideSalesBuckets.length) return null;
  return (
    <div className="flex justify-between p-3 mb-4 lfms-bucket">
      <ToggleGroup type="single" className="flex-wrap justify-start gap-3">
        {insideSalesBuckets.map(item => {
          const iconMapping = bucketIconsMapping.find(
            mapping => mapping.id === item.id
          );
          if (!iconMapping) return null;

          const count = bucketCountMap[item.id] || 0; // Default to 0 if count is not available

          return (
            <ToggleGroupItem
              value={item?.label} // Assuming label can be used as a value
              variant="outline"
              data-state={activeBucket === item.id ? "on" : "off"}
              aria-checked={activeBucket === item.id ? "true" : "false"}
              className={`rounded-full w-fit ${
                activeBucket === item.id ? "pointer-events-none" : ""
              }`}
              key={`bucket${item.id}`}
              onClick={() => handleClick(item.id)}
            >
              {iconMapping.component} {item.label} ({count})
            </ToggleGroupItem>
          );
        })}
        <ToggleGroupItem
          value={"ALL"}
          variant="outline"
          data-state={activeBucket === "ALL" ? "on" : "off"}
          aria-checked={activeBucket === "ALL" ? "true" : "false"}
          className={`rounded-full w-fit ${
            activeBucket === "ALL" ? "pointer-events-none" : ""
          }`}
          onClick={() => handleClick("ALL")}
        >
          <ListIcon size={16} className="mr-2 text-pink-500" /> All (
          {allBucketData?.totalBucketCount || 0})
        </ToggleGroupItem>
      </ToggleGroup>
      <div></div>
    </div>
  );
};

export default LeadBucket;
