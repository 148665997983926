import React from "react";
import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

const Page404 = ({ location }) => (
  <div className="dashboard">
    <div className="clr gap-20" />
    <div className="container-fluid">
      <Alert variant="danger">
        <h2>
          404 Error : No matching page
          <Link to="/jobs">Return to Jobs Page</Link>
        </h2>
      </Alert>
    </div>
  </div>
);
export default Page404;
