import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../ui/table";
import { customUseEffect } from "../../services/utils";
import useLeadAssignmentStore from "../../zustandService/useLeadAssignmentStore";
import { Checkbox } from "../ui/checkbox";
import { Button } from "../ui/button";
import SubmitButton from "./submitButton";

const RenderActiveCheckBox = props => {
  const { moderatorConfigLocalState, updateModeratorConfigState } =
    useLeadAssignmentStore(state => ({
      moderatorConfigLocalState: state.moderatorConfigLocalState,
      updateModeratorConfigState: state.updateModeratorConfigState
    }));
  const { row } = props;
  const { id } = row;
  const { isTl = false } = moderatorConfigLocalState.find(
    item => item.id == id
  );
  const updateIsTl = () => {
    updateModeratorConfigState(id, { isTl: !isTl });
  };
  return <Checkbox checked={!!isTl} onCheckedChange={updateIsTl} />;
};

export default function TeamLeadLevel() {
  const {
    moderatorConfig,
    moderatorConfigFetchState,
    fetchModeratorConfig,
    saveModeratorConfig
  } = useLeadAssignmentStore(state => ({
    moderatorConfig: state.moderatorConfig,
    moderatorConfigFetchState: state.moderatorConfigFetchState,
    fetchModeratorConfig: state.fetchModeratorConfig,
    saveModeratorConfig: state.saveModeratorConfig
  }));
    customUseEffect(() => {
      fetchModeratorConfig({ tab: "team-lead" });
    },[]);
  if (moderatorConfigFetchState === "LOADING") return null;
  return (
    <div>
      <SubmitButton onClick={() => saveModeratorConfig({ tab: "team-lead" })} />
      <div className="w-full border rounded-xl">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Team Lead</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {moderatorConfig.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <RenderActiveCheckBox row={row} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
