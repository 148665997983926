import React from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationFactory from "react-bootstrap-table2-paginator";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import { getErrorMessage } from "../../services/utils";
import { JOB_EDIT_PAGE } from "../../routes/RouteConstants";
import withRouter from "../../services/withRouter";

function JobLink(props) {
  const { jobId = "", recId = "" } = props;
  const editUrl = `${JOB_EDIT_PAGE}/live/QA/${jobId}?recId=${recId}`;
  return (
    <div>
      <Link
        to={editUrl}
        className="primary"
        rel="noopener"
        target="_blank"
        title={"View Jobs in Edit Jobs"}
      >
        {jobId}
      </Link>
    </div>
  );
}

class AppliedJobs extends React.Component {
  constructor(props) {
    super(props);
    const { params = {} } = this.props;
    this.state = {
      jobApplied: [],
      job_applied_temp: ["js_applied"],
      isLoading: true,
      status: "",
      source: "",
      uid: params.uid || "",
      msgData: { msg: "", showMsg: false, msgType: "error" }
    };
  }

  changeAppliedStatus = event => {
    let filterVal = event.target.value;
    let filterData = [];
    this.setState({
      status: filterVal
    });
    const { source } = this.state;
    if (filterVal) {
      filterData = this.state.job_applied_temp.filter(function (data) {
        if (source) {
          if (filterVal === "r_shortlisted" || filterVal === "r_invited")
            return (
              (data.status === "r_shortlisted" ||
                data.status === "r_invited") &&
              data.flow === source
            );
          else return data.status === filterVal && data.flow === source;
        } else {
          if (filterVal === "r_shortlisted" || filterVal === "r_invited")
            return (
              data.status === "r_shortlisted" || data.status === "r_invited"
            );
          else return data.status === filterVal;
        }
      });
    } else if (source) {
      filterData = this.state.job_applied_temp.filter(function (data) {
        return data.flow === source;
      });
    } else {
      filterData = this.state.job_applied_temp;
    }
    this.setState({ jobApplied: filterData });
  };

  changeSourceFilter = event => {
    let filterVal = event.target.value;
    let filterData = [];
    this.setState({
      source: filterVal
    });
    const { status } = this.state;
    if (filterVal) {
      filterData = this.state.job_applied_temp.filter(function (data) {
        if (status) {
          if (status === "r_shortlisted" || status === "r_invited")
            return (
              data.flow === filterVal &&
              (data.status === "r_shortlisted" || data.status === "r_invited")
            );
          else return data.flow === filterVal && data.status === status;
        } else return data.flow === filterVal;
      });
    } else if (status) {
      filterData = this.state.job_applied_temp.filter(function (data) {
        if (status === "r_shortlisted" || status === "r_invited")
          return data.status === "r_shortlisted" || data.status === "r_invited";
        else return data.status === status;
      });
    } else {
      filterData = this.state.job_applied_temp;
    }
    this.setState({ jobApplied: filterData });
  };

  blockUser = e => {
    e.preventDefault();
    const obj = {
      user_id: this.state.uid,
      status: "blocked"
    };
    if (window.confirm("Are you sure you want to block this user?")) {
      JobHai.post("/job_seeker/update/", obj)
        .then(response => {
          this.setState({
            msgData: {
              msg: "User has been blocked",
              showMsg: true,
              msgType: "success"
            }
          });
          window.scrollTo(0, 0);
        })
        .catch(error => {
          let msg = getErrorMessage(error);
          this.setState({
            msgData: {
              msg: msg,
              showMsg: true,
              msgType: "error"
            }
          });
          window.scrollTo(0, 0);
        });
    }
  };

  getAppliedJobSeeker = () => {
    JobHai.get(`/job_seeker/applied?uid=${this.state.uid}`)
      .then(response => {
        let data = response.data.data;
        this.setState({
          jobApplied: data,
          job_applied_temp: data,
          isLoading: false
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isLoading: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" },
          isButtonDisabled: false
        });
        window.scrollTo(0, 0);
      });
  };

  componentDidMount() {
    this.getAppliedJobSeeker();
  }

  render() {
    const jobApplied = this.state.jobApplied;
    jobApplied.map(d => {
      d.jobId = <JobLink jobId={d.job_id} recId={d.recruiter_id} />;
      return d;
    });

    const columns = [
      {
        text: "Job Id",
        dataField: "jobId",
        sort: false
      },
      {
        text: "COMPANY NAME",
        dataField: "company_name",
        sort: true
      },
      {
        text: "RECRUITER NAME",
        dataField: "full_name",
        sort: true
      },
      {
        text: "RECRUITER NUMBER",
        dataField: "phone",
        sort: true
      },

      {
        text: "JOB TITLE",
        dataField: "job_title",
        sort: true
      },
      {
        text: "SOURCE",
        dataField: "flow",
        sort: true
      },
      {
        text: "STATUS",
        dataField: "status",
        sort: true
      }
    ];

    const jobStatusOptions = [
      { val: "", label: "All Status" },
      { val: "js_viewed", label: "JS Viewed" },
      { val: "js_called", label: "JS Called" },
      { val: "js_applied", label: "JS Applied" },
      { val: "r_rejectedited", label: "JS Rejected" },
      { val: "r_viewed", label: "Recruiter View" },
      { val: "r_shortlisted", label: "Recruiter Shortlisted" },
      { val: "r_called", label: "Recruiter Called" },
      { val: "r_rejected", label: "Recruiter Rejected" }
    ];

    const jobSourceOptions = [
      { val: "", label: "All Source" },
      { val: "APPLICATIONS", label: "APPLICATIONS" },
      { val: "RECOMMENDATIONS", label: "RECOMMENDATIONS" }
    ];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
    const pagination_options = {
      paginationSize: 4,
      pageStartIndex: 0,
      showTotal: true,
      paginationTotalRenderer: customTotal,
      hideSizePerPage: true,
      sizePerPageList: [
        {
          text: "30",
          value: 30
        },
        {
          text: "50",
          value: 50
        },
        {
          text: "All",
          value: jobApplied.length
        }
      ]
    };
    const defaultSorted = [
      {
        dataField: "name",
        order: "asc"
      }
    ];

    if (this.state.isLoading) {
      return <LoadingSpinner key="loading" />;
    } else {
      return (
        <div className="job_seeker">
          <div className="clr gap-20"></div>
          <div className="clr gap-20"></div>
          <PopupMessage
            data={this.state.msgData}
            key="popup"
            click={this.handleClosePopup}
          />
          <div className="pageHeading row">
            <h2>
              Actions On Jobs
              <div className="float-right">
                <Link to={"/job-seeker/"}>Back</Link>
              </div>
            </h2>
          </div>
          <div className="clr"></div>
          <div className="clr gap-20"></div>
          <form autoComplete="off">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <b className="downArrow"></b>
                  <select
                    className="form-control"
                    name="source"
                    onChange={this.changeSourceFilter}
                  >
                    {jobSourceOptions.map(option => (
                      <option value={option.val} key={option.val}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <b className="downArrow"></b>
                  <select
                    className="form-control"
                    name="status"
                    onChange={this.changeAppliedStatus}
                  >
                    {jobStatusOptions.map(option => (
                      <option value={option.val} key={option.val}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </form>
          <div className="clr"></div>
          <div className="clr gap-20"></div>
          <div className="whitebox">
            <BootstrapTable
              key="seeker"
              keyField="serialNo"
              data={jobApplied}
              columns={columns}
              hover
              condensed
              bordered={false}
              loading={true}
              rowClasses="listvalue"
              headerClasses="listHeding"
              classes="borderless"
              pagination={PaginationFactory(pagination_options)}
              defaultSorted={defaultSorted}
            />
            <div className="clr"></div>
            <div className="clr gap-20"></div>
            <div className="clr"></div>
            <div className="clr gap-20"></div>
          </div>
        </div>
      );
    }
  }
}
export default withRouter(AppliedJobs);
