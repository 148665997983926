import { useParams } from "react-router-dom";
import TabsList from "../TabsList";
import useLcsCampaignDetails from "../../zustandService/useLcsCampaignDetails";
import {
  campaignDetailsTab,
  leadsClassificationTab,
  questionsTab,
  walkinTab
} from "./lcsCampaignConstants";
import { useMemo } from "react";

const CampaignTabs = ({ activeTab = "CAMPAIGN", callBack }) => {
  const { id } = useParams();
  const { campaignData, isWalkinCampaign } = useLcsCampaignDetails(state => ({
    campaignData: state.campaignData,
    isWalkinCampaign: state.isWalkinCampaign
  }));

  const tabList = useMemo(() => {
    const { skip_questionniare_redirect = false } = campaignData || {};
    const allTabsList = [
      {
        title: "Campaign Details",
        id: campaignDetailsTab,
        is_hidden: false
      },
      {
        title: "Walkin Details",
        id: walkinTab,
        is_hidden: !id || !isWalkinCampaign // walkin category id is 2
      },
      {
        title: "Questions Details",
        id: questionsTab,
        is_hidden: !id || skip_questionniare_redirect
      },
      {
        title: "Leads Classification",
        id: leadsClassificationTab,
        is_hidden: !id
      }
    ];
    return allTabsList.filter(tab => !tab.is_hidden);
  }, [campaignData]);

  const handleTabClicked = id => {
    callBack(id);
  };
  return (
    <div className="admin-pannel">
      <TabsList
        tabList={tabList}
        isSelected={activeTab}
        onClick={handleTabClicked}
      />
      <div className="gap-20" />
    </div>
  );
};
export default CampaignTabs;
