import JobHai from "../../api/JobHai";
import { getErrorMessage } from "../utils";

export const getSalesAssignmentCohort = () => {
  return new Promise((resolve, reject) => {
    JobHai.get(`lms/assignment-cohorts`)
      .then(response => {
        resolve(response.data.data); // Resolve with the data
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg); // Reject with the error message
      });
  });
};

export const saveSalesAssignmentCohort = data => {
  return new Promise((resolve, reject) => {
    JobHai.post(`lms/assignment-cohorts`, data)
      .then(response => {
        resolve(response.data.data); // Resolve with the data
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg); // Reject with the error message
      });
  });
};

export const getModeratorConfig = tab => {
  return new Promise((resolve, reject) => {
    const url = `lms/moderator-config?tab=${tab}`;
    JobHai.get(url)
      .then(response => {
        resolve(response.data.data); // Resolve with the data
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg); // Reject with the error message
      });
  });
};

export const saveModeratorConfig = params => {
  return new Promise((resolve, reject) => {
    const { tab, data } = params;
    JobHai.post(`lms/moderator-config?tab=${tab}`, data)
      .then(response => {
        resolve(response.data.data); // Resolve with the data
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg); // Reject with the error message
      });
  });
};

export const getAssignmentModeratorMapping = () => {
  return new Promise((resolve, reject) => {
    const url = `lms/assignment-moderator-mapping`;
    JobHai.get(url)
      .then(response => {
        resolve(response.data.data); // Resolve with the data
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg); // Reject with the error message
      });
  });
};

export const saveAssignmentModeratorMapping = async data => {
  return new Promise((resolve, reject) => {
    JobHai.post(`lms/assignment-moderator-mapping`, data)
      .then(response => {
        resolve(response.data.data); // Resolve with the data
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg); // Reject with the error message
      });
  });
};
