import React from "react";
import DocumentTitle from "react-document-title";
import { checkPermission } from "../../services/utils";
import {
  RECUITERS_LIST,
  RECUITERS_LEADS,
  RECOMMENDED_JOB,
  TRANSACTION_HISTORY,
  CREDITS_RESERVED,
  RESTRICT_VALUE_DELIVERY
} from "../../routes/RouteConstants";
import NoAccess from "../Common/NoAccess";
import HeadingRow from "../Common/HeadingRow";

const RecruiterDashboard = props => {
  const showRecruiters = checkPermission("Recruiters");
  const showLeads = checkPermission("Leads");
  const showRecoJob = checkPermission("Recommended Job");
  const showTransactionHistory = true;
  const showCreditReserved = true;
  const showRestrictValueDelivery = checkPermission("Restrict Value");

  const isViewPage =
    showRecruiters ||
    showLeads ||
    showRecoJob ||
    showTransactionHistory ||
    showCreditReserved ||
    showRestrictValueDelivery;
  return (
    <DocumentTitle title={"Job Hai CRM - Recruiters Dashboard"}>
      <div className="dashboard">
        <div className="clr gap-20" />
        <HeadingRow heading="Recruiters Dashboard" />
        <div className="clr gap-20" />
        <div className="whiteCard">
          {isViewPage ? (
            <div className="container-fluid">
              <div className="clr gap-20"></div>
              <div className="row marginBottom20">
                {showRecruiters ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={RECUITERS_LIST}>Recruiters</a>
                    </div>
                  </div>
                ) : null}
                {showLeads ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={RECUITERS_LEADS}>Leads</a>
                    </div>
                  </div>
                ) : null}
                {showRecoJob ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={RECOMMENDED_JOB}>Recommended Job</a>
                    </div>
                  </div>
                ) : null}
                <div className="d-flex flex-row w-100 mt-3">
                  {showTransactionHistory ? (
                    <div className="col-md-4">
                      <div className="box">
                        <a href={TRANSACTION_HISTORY}>Transaction History</a>
                      </div>
                    </div>
                  ) : null}

                  {showCreditReserved ? (
                    <div className="col-md-4">
                      <div className="box">
                        <a href={CREDITS_RESERVED}>Credits Reserved</a>
                      </div>
                    </div>
                  ) : null}

                  {showRestrictValueDelivery ? (
                    <div className="col-md-4">
                      <div className="box">
                        <a href={RESTRICT_VALUE_DELIVERY}>Restrict Value</a>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <NoAccess />
          )}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default RecruiterDashboard;
