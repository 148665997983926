import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import MetaData from "../../../constants/MetaData";

class ReportedJobSearch extends React.Component {
  constructor(props) {
    super(props);
    const { recruiterId = "", recIds = "", reportReason = "" } = props;
    this.state = {
      recruiterId: recruiterId || "",
      recruiterName: "",
      companyName: "",
      recruiterPhoneNumber: "",
      jobId: "",
      jsName: "",
      jsPhoneNumber: "",
      reportReason: reportReason || "",
      classifiedReason: "",
      isAuthentic: "",
      callStatus: recruiterId || recIds ? "" : "New",
      dateFrom: "",
      dateTo: "",
      showAllReports: 1,
      source: "",
      recIds: recIds || ""
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedTab } = this.props;
    if (selectedTab !== prevProps.selectedTab && selectedTab) {
      this.setInitiaValue();
    }
  }

  setInitiaValue = () => {
    this.setState({
      recruiterId: "",
      recruiterName: "",
      companyName: "",
      recruiterPhoneNumber: "",
      jobId: "",
      jsName: "",
      jsPhoneNumber: "",
      reportReason: "",
      classifiedReason: "",
      isAuthentic: "",
      callStatus: "New",
      dateFrom: "",
      dateTo: "",
      showAllReports: "1",
      source: ""
    });
  };

  updateMessage = (msg, flag) => {
    this.props.statusMessage(msg, flag);
  };

  handleReportedJobs = e => {
    const {
      isAuthentic,
      recruiterId,
      recruiterName,
      companyName,
      recruiterPhoneNumber,
      jobId,
      jsName,
      jsPhoneNumber,
      reportReason,
      classifiedReason,
      callStatus,
      dateFrom,
      dateTo,
      showAllReports,
      source,
      recIds
    } = this.state;
    e.preventDefault();
    const obj = {
      recruiterId,
      recruiterName,
      companyName: companyName,
      recruiterPhoneNumber: recruiterPhoneNumber,
      jobId: jobId,
      jsName: jsName,
      jsPhoneNumber,
      reportReason,
      classifiedReason,
      isAuthentic:
        isAuthentic === "1" ? true : isAuthentic === "0" ? false : "",
      callStatus,
      dateFrom: dateFrom ? moment(dateFrom).format("MM-DD-YYYY") : "",
      dateTo: dateTo ? moment(dateTo).format("MM-DD-YYYY") : "",
      showAllReports: showAllReports ? true : "",
      source,
      recIds
    };

    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        delete obj[key];
      }
    });

    this.props.searchReportedJobs(obj);
  };

  render() {
    const { isButtonDisabled, isCPSection } = this.props;
    let reportedReasonList = MetaData.reportedReason;
    if (isCPSection) {
      reportedReasonList = reportedReasonList.filter(item => item.isPotential);
    }

    return (
      <div className="job_seeker">
        <div className="gap-20"></div>
        <form
          autoComplete="off"
          onSubmit={this.handleReportedJobs}
          className="search"
        >
          <div className="row">
            <div className="col-md-2">
              <input
                className="form-control"
                type="text"
                name="recruiterId"
                placeholder="Recruiter Id"
                value={this.state.recruiterId}
                onChange={event =>
                  this.setState({
                    recruiterId: event.target.value.replace(/\D/, "")
                  })
                }
                maxLength="10"
              />
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="recruiterName"
                  placeholder="Recruiter Name"
                  value={this.state.recruiterName}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  value={this.state.companyName}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="recruiterPhoneNumber"
                  placeholder="Recruiter Phone"
                  value={this.state.recruiterPhoneNumber}
                  onChange={event =>
                    this.setState({
                      recruiterPhoneNumber: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="jobId"
                  placeholder="Job Id"
                  value={this.state.jobId}
                  onChange={event =>
                    this.setState({
                      jobId: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="jsPhoneNumber"
                  placeholder="JS Phone No"
                  value={this.state.jsPhoneNumber}
                  onChange={event =>
                    this.setState({
                      jsPhoneNumber: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="jsName"
                  placeholder="JS Name"
                  value={this.state.jsName}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="reportReason"
                  value={this.state.reportReason}
                  onChange={this.handleChange}
                >
                  <option value="">Select Reported Reason</option>
                  {reportedReasonList.map((item, index) => {
                    return (
                      <option value={item.value} key={`cl-${index}`}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="classifiedReason"
                  value={this.state.classifiedReason}
                  onChange={this.handleChange}
                >
                  <option value="">Select Classified Reason</option>
                  {MetaData.classfiedReasons.map((item, index) => {
                    return (
                      <option value={item.value} key={`cl-${index}`}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="isAuthentic"
                  value={this.state.isAuthentic}
                  onChange={this.handleChange}
                >
                  <option value="">Select Authentic</option>
                  {MetaData.authenticStatus.map((item, index) => {
                    return (
                      <option value={item.value} key={`auth-${index}`}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="callStatus"
                  value={this.state.callStatus}
                  onChange={this.handleChange}
                >
                  <option value="">Select Call Status</option>
                  {MetaData.reportedCallStatus.map((item, index) => {
                    return (
                      <option value={item.value} key={`cs-${index}`}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="showAllReports"
                  value={this.state.showAllReports}
                  onChange={this.handleChange}
                >
                  <option value="">Live Job Reports</option>
                  <option value="1">All Reports</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="source"
                  value={this.state.source}
                  onChange={this.handleChange}
                >
                  <option value="">Source</option>
                  <option value="JS Feedback">JS Feedback</option>
                  <option value="Report">Report</option>
                </select>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row">
                <div className="from-col">
                  <div className="form-group">Date From</div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <DatePicker
                      selected={this.state.dateFrom}
                      onChange={value => {
                        this.setState({ dateFrom: value });
                      }}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="col-md-1 to-col">To</div>
                <div className="col-md-4">
                  <DatePicker
                    selected={this.state.dateTo}
                    onChange={value => {
                      this.setState({ dateTo: value });
                    }}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                    maxDate={new Date()}
                    minDate={this.state.dateFrom}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-1">
              <div className="float-right">
                {isButtonDisabled ? (
                  <button className="btn btn-primary disable" disabled>
                    SEARCHING
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={this.handleReportedJobs}
                  >
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
      </div>
    );
  }
}
export default ReportedJobSearch;
