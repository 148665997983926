import React, { useState } from "react";
import DatePicker from "react-datepicker";
import SelectCity from "../Common/SelectCity";

const CrawlFilter = props => {
  const [searchItems, handleFormItems] = useState({
    sdate: new Date(),
    edate: new Date(),
    city_id: "",
    source: ""
  });
  const [errorMsg, handleError] = useState("");
  function handleChange(event) {
    let {
      target: { name, value }
    } = event;
    let tempObj = { ...searchItems };
    tempObj[name] = value;
    handleFormItems(tempObj);
    handleError("");
  }
  const handleDateChange = (fieldName, value) => {
    let tempObj = { ...searchItems };
    tempObj[fieldName] = value;
    handleFormItems(tempObj);
    handleError("");
  };

  function handleSearch(e) {
    e.preventDefault();
    const { sdate, edate } = searchItems;
    const { getStats } = props;
    if (!sdate) {
      handleError("Please enter start date");
      return;
    }
    if (!edate) {
      handleError("Please enter end date");
      return;
    }
    if (sdate > edate) {
      handleError("Please enter start date is less than end date");
      return;
    }
    getStats(searchItems);
  }
  const { sdate, edate, city_id, source } = searchItems;
  const { sourceList, isBtnDisabled = false } = props;
  return (
    <div className="job_seeker">
      <div className="gap-20"></div>
      <form autoComplete="off" onSubmit={handleSearch} className="search">
        <div className="row">
          <div className="col-md-2">
            <SelectCity
              filedName={"city_id"}
              value={city_id}
              onChange={handleChange}
              label={false}
              isDelhiNcr={true}
              hideNCRCity={true}
            />
          </div>
          <div className="col-md-2">
            <b
              className="downArrow"
              style={{ padding: "3px", marginTop: "13px" }}
            />
            <select
              className="form-control"
              name="source"
              value={source}
              onChange={handleChange}
            >
              <option value="">Select Source</option>
              {sourceList.map((option, index) => (
                <option value={option.value} key={"cle" + index}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <div className="date-lable padding15">Date From: </div>
            <div className="date-field">
              <DatePicker
                selected={sdate}
                onChange={value => handleDateChange("sdate", value)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                maxDate={new Date()}
              />
            </div>
            <div className="date-lable">To: </div>
            <div className="date-field">
              <DatePicker
                selected={edate}
                onChange={value => handleDateChange("edate", value)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                maxDate={new Date()}
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="float-right">
              {isBtnDisabled && (
                <button className="btn btn-primary disable" disabled>
                  Searching ...
                </button>
              )}
              {!isBtnDisabled && (
                <button className="btn btn-primary" onClick={handleSearch}>
                  Search
                </button>
              )}
            </div>
            {errorMsg ? <span className="text-danger">{errorMsg}</span> : null}
          </div>
        </div>
      </form>
      <div className="clr"></div>
      <div className="clr gap-20"></div>
    </div>
  );
};

export default CrawlFilter;
