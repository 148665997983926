const PremiumTag = ({ jobPlan, label = "" }) => {
  if (jobPlan === "PREMIUM") {
    return (
      <span className="premiumTag">
        <img src="/images/premium.svg" alt="premium" width="19" height="19" />
        {label}
      </span>
    );
  }
};

export default PremiumTag;
