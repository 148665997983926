import React from "react";

function TextButtons(props) {
  const { isAllowed = true, url, id } = props;
  return (
    <div>
      {isAllowed ? (
        <a
          href={url}
          className="primary"
          title={id}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-pencil-alt"></i>
        </a>
      ) : (
        <i className="fas fa-pencil-alt"></i>
      )}
    </div>
  );
}
export default TextButtons;
