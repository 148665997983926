import React, { useState } from "react";
import DocumentTitle from "react-document-title";
import {
  checkPermission,
  getFormattedValue,
  isCRMAdmin,
  redirectURL
} from "../../services/utils";
import NoAccess from "../Common/NoAccess";
import HeadingRow from "../Common/HeadingRow";
import CustomTextField from "../Common/Form/CustomTextField";
import {
  getOrganisations,
  restrictRecruiters
} from "../../services/restricValueService";
import PopupMessage from "../PopupMessage";
import OrganisationList from "./OrganisationList";
import CustomSelect from "../Common/Form/CustomSelect";
import { restrictActionList } from "../../constants/MetaData";
import ModalWrapper from "../Common/ModalWrapper";
import {
  RESTRICT_VALUE_DELIVERY,
  VIEW_RESTRICT_VALUE
} from "../../routes/RouteConstants";

const RaiseRequest = () => {
  const isViewPage = checkPermission("Restrict Value");
  const [companyNames, setCompanyNames] = useState(["", "", "", "", ""]);
  const [popupMessageObj, setPopupMessageObj] = useState({ showMsg: false });
  const [organisationsData, setOrganisationsData] = useState([]);
  const [showOrgTable, setShowOrgTable] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [requestName, setRequestName] = useState("");
  const [restrictAction, setRestrictAction] = useState("ZERO_FREE");
  const [showModal, setShowModal] = useState(false);

  const handleCompanyChange = (e, index) => {
    const { value } = e.target;
    // const formattedValue = value.replace(/[^0-9A-Za-z]/gi, "");
    const newCompanyNames = [...companyNames];
    newCompanyNames[index] = value;
    setCompanyNames(newCompanyNames);
  };

  const onCompanySearch = e => {
    e.preventDefault();

    if (companyNames.length) {
      getOrganisationsData();
    }
  };

  const getOrganisationsData = async () => {
    try {
      const companyData = companyNames.filter(companyName => companyName);
      const data = await getOrganisations({ companyNames: companyData });
      if (data.length) {
        setOrganisationsData(data);
      }

      setShowOrgTable(true);

      setPopupMessageObj({
        showMsg: false
      });
    } catch (error) {
      console.error(error);
      setPopupMessageObj({
        msgType: "error",
        msg: "Something went wrong",
        showMsg: true
      });
    }
  };

  const onBack = e => {
    setShowOrgTable(false);

    setPopupMessageObj({
      showMsg: false
    });
  };

  const handleRequestNameChange = e => {
    const { value } = e.target;
    setRequestName(value);
  };

  const handleRestrictAction = e => {
    const { value } = e.target;
    setRestrictAction(value);
  };

  const handleSelectedItems = (isSelect, rows, selectedAll = false) => {
    let newOrg = [...selectedOrg];
    let orgIds = [];
    if (selectedAll) {
      orgIds = isSelect ? rows.map(item => item.organisation_id) : [];
      setSelectedOrg(orgIds);
    } else {
      orgIds = rows.organisation_id;
      if (isSelect) {
        newOrg = [...newOrg, orgIds];
      } else {
        newOrg.splice(newOrg.indexOf(orgIds), 1);
      }
      setSelectedOrg(newOrg);
    }
  };

  const onRestrictConfirm = e => {
    e.preventDefault();

    if (requestName.length < 5) {
      setPopupMessageObj({
        msgType: "error",
        msg: "Request name should be at least 5 characters long",
        showMsg: true
      });
    } else {
      setShowModal(true);
    }
  };

  const onRestrictRecruiters = async () => {
    const dataObj = {
      organisation_ids: selectedOrg,
      request_name: requestName,
      restriction: restrictAction
    };
    try {
      const data = await restrictRecruiters(dataObj);
      setShowModal(false);

      if (isCRMAdmin()) {
        redirectURL(VIEW_RESTRICT_VALUE);
      }

      setPopupMessageObj({
        msgType: "success",
        msg: `Value delivery successfully restricted for ${selectedOrg.length} organisations`,
        showMsg: true
      });
    } catch (error) {
      console.error(error);
      setPopupMessageObj({
        msgType: "error",
        msg: "Something went wrong",
        showMsg: true
      });
    }
  };

  const handleModalSave = () => {
    onRestrictRecruiters();
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const isConfirmDisabled =
    !selectedOrg.length || !requestName || !restrictAction;

  return (
    <DocumentTitle title={"Job Hai CRM - Raise Request"}>
      <div>
        <HeadingRow
          heading="Restrict value delivery - Raise Request"
          goBackURL={!showOrgTable && RESTRICT_VALUE_DELIVERY}
        />

        <PopupMessage
          data={popupMessageObj}
          click={() => setPopupMessageObj({ showMsg: false })}
        />
        <div className="clr gap-20" />
        {isViewPage ? (
          <>
            {!showOrgTable ? (
              <div className="whitebox">
                <div className="clr gap-20" />
                <div className="formRow company-name-container ">
                  {companyNames.map((company, index) => (
                    <CustomTextField
                      key={index}
                      onChange={e => handleCompanyChange(e, index)}
                      placeholder={`Company ${index + 1}`}
                      value={company}
                      disabled={index > 0 && !companyNames[index - 1]}
                    />
                  ))}
                </div>

                <div className="form-row fullWidth">
                  <div className="col-md-12">
                    <div className="float-right">
                      <button
                        className="btn btn-primary marginleft"
                        disabled={companyNames.every(name => name === "")}
                        onClick={onCompanySearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="whitebox">
                <div className="clr gap-20" />

                <div className="formRow d-flex justify-content-between align-items-center">
                  <div className="back-button">
                    <div onClick={onBack}>Back</div>
                  </div>

                  <div className="d-flex">
                    <div className="mr-3">
                      <CustomTextField
                        value={requestName}
                        onChange={handleRequestNameChange}
                        placeholder="Request name *"
                      />
                    </div>

                    <CustomSelect
                      name="restrictAction"
                      value={restrictAction}
                      onChange={handleRestrictAction}
                      optionsList={restrictActionList}
                      placeholder="Action to be taken*"
                    />

                    <button
                      className="btn btn-primary marginleft"
                      disabled={isConfirmDisabled}
                      onClick={onRestrictConfirm}
                    >
                      Confirm
                    </button>
                  </div>
                </div>

                <OrganisationList
                  dataList={organisationsData}
                  handleSelectedItems={handleSelectedItems}
                  selectedOrg={selectedOrg}
                />

                <ModalWrapper
                  primaryCTA="Confirm"
                  showModal={showModal}
                  handleSave={handleModalSave}
                  closeModal={handleModalClose}
                >
                  <div>
                    Value delivery will be restricted for {selectedOrg.length}{" "}
                    organisations
                  </div>
                </ModalWrapper>
              </div>
            )}
          </>
        ) : (
          <NoAccess />
        )}
      </div>
    </DocumentTitle>
  );
};

export default RaiseRequest;
