import { cityGroupList } from "../../../constants/MetaData";
import { MAX_ASSIGN_VOLUME } from "../../../services/constant";
import { getFormattedValue } from "../../../services/utils";
import CustomSelect from "../../Common/Form/CustomSelect";

const ModeratorListCAT = props => {
  const { onChange, moderatorList, onCityGroupChange } = props;
  const total = moderatorList.length;
  const totalAbsent = moderatorList.filter(item => item.is_absent).length;
  const totalActive = moderatorList.filter(item => item.is_active).length;

  const totalAssignVolume = moderatorList.reduce((acc, obj) => {
    return acc + obj.max_limit_to_assign_data;
  }, 0);

  return (
    <>
      <div className="moderator-list-cat" style={{ minHeight: "auto" }}>
        <table className="table table-hover borderless">
          <thead>
            <tr className="listHeding">
              <th>ID</th>
              <th>Moderator Name</th>
              <th>Is Active</th>
              <th>Is Absent</th>
              <th>City Groups</th>
              <th>Assign Volume ({MAX_ASSIGN_VOLUME})</th>
            </tr>
          </thead>
          <tbody>
            {moderatorList.map(item => {
              return (
                <tr
                  className="listvalue"
                  key={`moderatorlist${item.moderator_id}`}
                >
                  <td>{item.moderator_id}</td>
                  <td>{item.moderator_name}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={item.is_active}
                      onChange={e =>
                        onChange(
                          item.moderator_id,
                          e.target.checked,
                          "is_active"
                        )
                      }
                    />
                  </td>
                  <td valign="top">
                    <input
                      type="checkbox"
                      checked={item.is_absent}
                      onChange={e =>
                        onChange(
                          item.moderator_id,
                          e.target.checked,
                          "is_absent"
                        )
                      }
                    />
                  </td>
                  <td>
                    <CustomSelect
                      customCls="cityGroup"
                      name="city_group"
                      value={item.city_group}
                      onChange={e => onCityGroupChange(e, item.moderator_id)}
                      optionsList={cityGroupList}
                      isSelectDisabled={true}
                    />
                  </td>

                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={item.max_limit_to_assign_data}
                      name="max_limit_to_assign_data"
                      onChange={e =>
                        onChange(
                          item.moderator_id,
                          getFormattedValue(e.target.value, true),
                          "max_limit_to_assign_data"
                        )
                      }
                      maxLength={2}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="custom-pagination">
          <table className="table table-hover table-condensed borderless">
            <thead>
              <tr className="listHeding">
                <th>Total</th>
                <th>{total}</th>
                <th>{totalActive}</th>
                <th>{totalAbsent}</th>
                <th>{""}</th>
                <th>{totalAssignVolume}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </>
  );
};

export default ModeratorListCAT;
