import React, { useState, useEffect, useCallback } from "react";
import JobHai from "../../api/JobHai";
import { setLocalStorage, getLocalStorage } from "../../services/localStorage";

const SuggestedJd = ({
  categoryId,
  selectedJD,
  handleSuggestedJd,
  updateJdList,
  cityId
}) => {
  const [jdList, setJdList] = useState([]);
  const [selectedJdList, setSelectedJdList] = useState(selectedJD);

  const getSuggestedJdList = useCallback(() => {
    const jdMetadataKey = `jdMetadataV2_${categoryId}_${cityId}`;
    let oldJdList = false;
    if (getLocalStorage(jdMetadataKey)) {
      oldJdList = JSON.parse(getLocalStorage(jdMetadataKey));
    }
    if (oldJdList) {
      updateJdList(oldJdList);
      setJdList(oldJdList);
    } else {
      JobHai.get(
        `../metadata/jd-metadata?category_id=${categoryId}&city_id=${cityId}`
      )
        .then(metadata => {
          let jdList = metadata.data.data || [];
          updateJdList(jdList);
          setJdList(jdList);
          setLocalStorage(jdMetadataKey, JSON.stringify(jdList));
        })
        .catch();
    }
  }, [categoryId, updateJdList]);

  useEffect(() => {
    getSuggestedJdList();
  }, [getSuggestedJdList]);

  useEffect(() => {
    setJdList([]);
    if (categoryId && cityId) {
      getSuggestedJdList();
    }
  }, [categoryId, cityId]);

  const handleOnChange = event => {
    const checked = event.target.checked;
    const value = Number(event.currentTarget.value);
    const updatedSelectedJdList = checked
      ? [...selectedJdList, value]
      : selectedJdList.filter(id => id !== value);
    setSelectedJdList(updatedSelectedJdList);
    handleSuggestedJd(updatedSelectedJdList);
  };
  return (
    <>
      {jdList.map(item => {
        const checked = selectedJdList.includes(item.id);
        return (
          <div
            className="form-check-inline suggestedJdItem"
            key={`jd${item.id}`}
          >
            <input
              className="form-check-input"
              type="checkbox"
              id={`jd${item.id}`}
              value={item.id}
              onChange={handleOnChange}
              checked={checked}
            />
            <label className="form-check-label" htmlFor={`jd${item.id}`}>
              {item.label}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default SuggestedJd;
