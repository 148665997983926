import React, { useState, useEffect } from "react";
import moment from "moment";
import PopupMessage from "../PopupMessage";
import { ENTERPRISE_LEADS } from "../../routes/RouteConstants";
import JobHai from "../../api/JobHai";
import HeadingRow from "../Common/HeadingRow";
import { checkPermission, getErrorMessage } from "../../services/utils";
import NoAccess from "../Common/NoAccess";
import PerformanceSummary from "./PerformanceSummary";
import PerformanceFilter from "./PerformanceFilter";
import ModeratorPerformanceSummary from "./ModeratorPerformanceSummary";

const Performance = () => {
  const isAdmin = checkPermission("EnterpriseLeadsAdmin");
  const isAccess = checkPermission("Enterprise Leads") || isAdmin;

  const [msgData, setMessage] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });
  const [performance, setPerformance] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    start_date: new Date(),
    end_date: new Date(),
    city_id: "",
    moderator_id: "",
    client_id: ""
  });

  useEffect(() => {
    if (isAccess) {
      getPerformanceSummery();
    }
  }, [isAccess]);

  function getPerformanceSummery() {
    const { start_date, end_date, moderator_id, city_id, client_id } = filters;
    const formattedStartDate = moment(start_date).format("YYYY-MM-DD");
    const formattedEndDate = moment(end_date).format("YYYY-MM-DD");
    let url = `/get-lead-moderator-performance?start_date=${formattedStartDate}&end_date=${formattedEndDate}&is_admin=${isAdmin}`;
    if (client_id) url += `&client_id=${client_id}`;
    if (isAdmin) {
      if (moderator_id) url += `&moderator_id=${moderator_id}`;
      if (city_id) url += `&city_id=${city_id}`;
    }
    JobHai.get(url)
      .then(response => {
        let data = response.data.data;
        setPerformance(data);
        setLoading(false);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        setMessage({ msg, showMsg: true, msgType: "error" });
        setLoading(false);
      });
  }

  function handleClosePopup() {
    setMessage({ msg: "", showMsg: false });
  }

  function showErrorMsg(msg) {
    setMessage({ msg, showMsg: true, msgType: "error" });
  }
  function getSearchResult() {
    setLoading(true);
    getPerformanceSummery();
    setMessage({ msg: "", showMsg: false, msgType: "error" });
  }

  function handleChange(field, value) {
    setFilters(prevValues => ({ ...prevValues, [field]: value }));
  }

  function renderModeratorPerformance() {
    const { old = [], fresh = [] } = performance;
    return (
      <>
        <ModeratorPerformanceSummary
          data={fresh}
          isLoading={isLoading}
          heading="Fresh Leads"
        />
        <div className="clr gap-20" />
        <ModeratorPerformanceSummary
          data={old}
          isLoading={isLoading}
          heading="Old Leads"
        />
      </>
    );
  }
  function renderAdminPerformance() {
    const {
      date_wise_moderators_metric = [],
      individual_moderator_metric = []
    } = performance;
    return (
      <>
        <PerformanceSummary
          data={date_wise_moderators_metric}
          isLoading={isLoading}
          heading="Daily Moderator Progress"
        />
        <div className="clr gap-20" />
        <PerformanceSummary
          data={individual_moderator_metric}
          isLoading={isLoading}
          heading="Individual Moderator Performance"
          isIndividual={true}
        />
      </>
    );
  }

  let goBackURL = ENTERPRISE_LEADS;
  const heading = isAdmin ? "Moderator Performance" : "My Performance";
  return (
    <>
      <PopupMessage data={msgData} click={handleClosePopup} />
      <div className="clr gap-20" />
      <HeadingRow heading={heading} goBackURL={goBackURL} />
      <div className="clr gap-20" />
      {isAccess ? (
        <PerformanceFilter
          getSearchResult={getSearchResult}
          showErrorMsg={showErrorMsg}
          isButtonDisabled={isLoading}
          handleChange={handleChange}
          filters={filters}
          isAdmin={isAdmin}
        />
      ) : null}
      <div className={isAccess ? "" : "whitebox"}>
        {isAccess ? (
          <>
            {isAdmin ? renderAdminPerformance() : renderModeratorPerformance()}
          </>
        ) : (
          <NoAccess />
        )}
      </div>
    </>
  );
};

export default Performance;
