import { useState, useEffect } from "react";
import JobHai from "../../api/JobHai";

const SelectModerator = props => {
  const {
    type = "lead",
    isAssign = false,
    filedName = "moderator_id",
    fieldValue,
    onChange
  } = props;
  const is_lead = type === "lead";
  const [moderatorList, setModeratorList] = useState([]);
  useEffect(() => {
    getLeadModerators();
  }, []);

  function getLeadModerators() {
    JobHai.get(
      `/moderator-assignment-weightage?is_lead=${is_lead}&is_new_city_tab=false`
    )
      .then(response => {
        const data = response.data.data;
        setModeratorList(data);
      })
      .catch(error => {
        console.log("Error: ", error);
      });
  }
  return (
    <>
      <b className="downArrow" style={{ padding: "3px", marginTop: "13px" }} />
      <select
        className="form-control"
        name={filedName}
        value={fieldValue}
        onChange={onChange}
      >
        <option value="">Select Moderator</option>
        {moderatorList.map(item => {
          return (
            <option value={item.moderator_id} key={"mod" + item.moderator_id}>
              {item.moderator_name}
            </option>
          );
        })}
        {isAssign && <option value="unassign">Unassign</option>}
      </select>
    </>
  );
};

export default SelectModerator;
