import React, { useCallback } from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import CustomePagination from "../Common/Pagination";
import { ENTERPRISE_LEADS_UPDATE } from "../../routes/RouteConstants";
import { getFieldLabel } from "../../services/utils";
import { enterPriseLeadStatus } from "../../constants/MetaData";
import AddNewLead from "./AddNewLead";

function ActionLink({ item, view }) {
  const { lead_user_id = "", client_id } = item;
  const editUrl = `${ENTERPRISE_LEADS_UPDATE}/${lead_user_id}?client=${client_id}&view=${view}`;
  return (
    <div className="edit_link">
      <a href={editUrl} target="_blank" rel="noopener noreferrer">
        {lead_user_id}
      </a>
    </div>
  );
}

function BucketName({ item }) {
  const {
    lead_status = "",
    lead_bucket = "New",
    not_connected_count = 0,
    is_interview_date = false,
    is_d_1_date = false,
    is_reconciliation_lead
  } = item;
  let leadBucket = "";
  if (is_interview_date || is_d_1_date || is_reconciliation_lead) {
    leadBucket = is_interview_date
      ? "D0"
      : is_d_1_date
      ? "D1"
      : is_reconciliation_lead
      ? "Reconciliation"
      : lead_bucket;
  } else if (
    (lead_bucket !== "New" || lead_status !== "Assigned") &&
    not_connected_count
  ) {
    leadBucket = `Not Connected #${not_connected_count}`;
  } else if (lead_bucket === "New" || lead_status !== "Assigned") {
    leadBucket = "New";
  } else {
    leadBucket = lead_bucket;
  }
  return leadBucket;
}

const LeadsList = props => {
  const {
    dataList = [],
    activePage,
    itemsCountPerPage,
    totalItemsCount,
    onPageChange,
    isSearching = false,
    adminOnly,
    handleSelectedItems,
    selectedLead,
    clientId = "",
    phone = ""
  } = props;
  const pageType = adminOnly ? "admin" : "moderator";
  const columns = [
    {
      text: "#",
      dataField: "edit_link",
      formatter: (cellContent, row) => {
        return <ActionLink item={row} view={pageType} />;
      }
    },
    {
      text: "Full Name",
      dataField: "full_name",
      sort: true
    },
    {
      text: "City",
      dataField: "city",
      sort: true
    },
    {
      text: "Lead Type",
      dataField: "lead_type",
      sort: true
    },
    {
      text: "Lead Source",
      dataField: "lead_source",
      sort: true
    },
    {
      text: "Created At",
      dataField: "created_at",
      sort: true,
      formatter: (cellContent, row) => {
        return moment(row.createdAt).format("YYYY-MM-DD");
      }
    },
    {
      text: "Interview Date",
      dataField: "interview_date",
      sort: true,
      formatter: (cellContent, row) => {
        return row.interview_date ? row.interview_date : "NA";
      }
    },
    {
      text: "Interview Center",
      dataField: "interview_center",
      sort: true,
      formatter: (cellContent, row) => {
        return row.interview_center ? row.interview_center : "NA";
      }
    },
    {
      text: "Rescheduled Date",
      dataField: "rescheduled_date",
      sort: true,
      formatter: (cellContent, row) => {
        return moment(row.rescheduled_date).isValid()
          ? moment(row.rescheduled_date).format("YYYY-MM-DD")
          : "NA";
      }
    },
    adminOnly
      ? {
          text: "Lead Shared",
          dataField: "lead_bucket",
          sort: true,
          formatter: (cellContent, row) => {
            return row?.lead_bucket === "Shared" ? "Yes" : "No";
          }
        }
      : {
          text: "Bucket",
          dataField: "leadBucket",
          sort: true,
          formatter: (cellContent, row) => {
            return <BucketName item={row} />;
          }
        },
    adminOnly
      ? {
          text: "Moderator",
          dataField: "moderator",
          sort: true,
          formatter: (cellContent, row) => {
            return row.moderator ? row.moderator : "NA";
          }
        }
      : {},
    {
      text: "Status",
      dataField: "lead_status",
      sort: true,
      formatter: (cellContent, row) => {
        return row.lead_status
          ? getFieldLabel(enterPriseLeadStatus, row.lead_status, "id")
          : "NA";
      }
    }
  ];
  const NoDataIndication = () => (
    <div className="spinner">
      {isSearching ? (
        "Searching..."
      ) : (
        <>
          No Data Found
          {phone && (
            <AddNewLead phone={phone} view={pageType} clientId={clientId} />
          )}
        </>
      )}
    </div>
  );

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      const rowData = dataList[rowIndex];
      const leadId = rowData["lead_user_id"] || "";
      const client_id = rowData["client_id"] || "";
      const editUrl = `${ENTERPRISE_LEADS_UPDATE}/${leadId}?client=${client_id}`;
      if (leadId) window.open(editUrl, "_blank");
    }
  };

  const handleOnSelect = useCallback(
    (row, isSelect) => {
      handleSelectedItems(isSelect, row);
    },
    [handleSelectedItems]
  );

  const handleOnSelectAll = useCallback(
    (isSelect, rows) => {
      handleSelectedItems(isSelect, rows, true);
    },
    [handleSelectedItems]
  );

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedLead,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    hideSelectColumn: false
  };

  return (
    <div className="list-ellipsis">
      <BootstrapTable
        keyField="lead_user_id"
        data={dataList}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
        rowEvents={!adminOnly ? rowEvents : undefined}
        selectRow={adminOnly ? selectRow : undefined}
      />
      <CustomePagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        onPageChange={onPageChange}
        showPagination={totalItemsCount > 0 ? true : false}
      />
    </div>
  );
};

export default LeadsList;
