import React, { useEffect } from "react";
import Select from "react-select";
import cx from "classnames";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
import classes from "./lcsCampaignCreation.module.css";
import { isEmpty, isObjectEmpty } from "../../services/utils";
import useLcsFormSubmitState from "../../zustandService/useLcsFormSubmitState";
import ComponentWrappedBackdrop from "../ComponentWrappedBackdrop";
import useLcsCampaignEditPermission from "../../zustandService/useLcsCampaignEditPermission";
import MaterialInputTextField from "../materialInputTextField";
import LcsCampaignQueComponent from "./LcsCampaignQueComponent";
import {
  maxQuestionsAllowed,
  minQuestionReq
} from "../Campaign/lcsCampaignConstants";
import { recruiter_consultant } from "../../constants/MetaData";
import useLcsCampaignDetails from "../../zustandService/useLcsCampaignDetails";

const getLangLabel = (lang, allLanguages) => {
  return allLanguages.find(item => item.id === lang);
};

const LcsCampaignLangForm = props => {
  const {
    defaultLang,
    lang,
    formQuestionsData,
    addDuplicateLangForm,
    duplicateLangList,
    invalidateFormData,
    allLanguages,
    isConditional,
    disableSaveQuestionCta,
    removeDuplicateLangForm,
    expandedAccordionLang
  } = props;
  const { campaignData } = useLcsCampaignDetails(state => ({
    campaignData: state.campaignData
  }));

  const { updateFormState } = useLcsFormSubmitState(state => ({
    updateFormState: state.updateFormState
  }));

  const { canEditCampaign } = useLcsCampaignEditPermission(state => ({
    canEditCampaign: state.canEditCampaign
  }));

  const defaultOptionObj = {
    option_id: null,
    option_label: { [lang]: "Option 1" },
    is_lead_success: false,
    is_active: true,
    next_question_id: 0,
    option_type: null
  };
  const defaultOptions = [
    {
      ...defaultOptionObj
    },
    {
      ...defaultOptionObj,
      option_label: { [lang]: "Option 2" },
      option_type: null
    }
  ];
  const defaultQuestionObj = {
    question_id: null,
    priority_order: 0,
    question_category: "default",
    options: defaultOptions,
    customOptions: [],
    question_type: "single_chip",
    question_label: { [defaultLang]: "" },
    help_text: { [defaultLang]: "" },
    is_active: true
  };
  const defaultQuestionsFormData = formQuestionsData?.questions?.length
    ? formQuestionsData.questions
    : [
        {
          ...defaultQuestionObj
        }
      ];

  const postSubmitTextFailure =
    formQuestionsData.post_submit_text_failure ?? {};
  const postSubmitTextSuccess =
    formQuestionsData.post_submit_text_success ?? {};
  const postSubmitTitleFailure =
    formQuestionsData.post_submit_title_failure ?? {};
  const postSubmitTitleSucces =
    formQuestionsData.post_submit_title_success ?? {};

  const methods = useForm({
    defaultValues: {
      questions: defaultQuestionsFormData,
      post_submit_text_failure: isObjectEmpty(postSubmitTextFailure)
        ? { [defaultLang]: "" }
        : postSubmitTextFailure,
      post_submit_text_success: isObjectEmpty(postSubmitTextSuccess)
        ? { [defaultLang]: "" }
        : postSubmitTextSuccess,
      post_submit_title_failure: isObjectEmpty(postSubmitTitleFailure)
        ? { [defaultLang]: "" }
        : postSubmitTitleFailure,
      post_submit_title_success: isObjectEmpty(postSubmitTitleSucces)
        ? { [defaultLang]: "" }
        : postSubmitTitleSucces
    }
  });
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { isDirty, dirtyFields, errors },
    setError,
    clearErrors
  } = methods;
  const anyFieldChanged = !isEmpty(Object.keys(dirtyFields));
  useEffect(() => {
    if (isDirty && anyFieldChanged) {
      disableSaveQuestionCta(true);
    }
  }, [anyFieldChanged]); // only runs when one field gets dirty and doesn't run when we change other fields after it
  const {
    fields: questionFields,
    append,
    update,
    remove
  } = useFieldArray({
    name: "questions",
    control,
    rules: { maxLength: maxQuestionsAllowed, minLength: minQuestionReq }
  });

  const postTextArr = [
    {
      lead: "SUCCESS",
      id: "post_submit_title_success",
      label: "Post submit title success",
      maxLength: {
        message: "Max 24 character allowed",
        value: 24
      }
    },
    {
      lead: "SUCCESS",
      id: "post_submit_text_success",
      label: "Post submit description success",
      maxLength: {
        message: "Max 120 character allowed",
        value: 120
      }
    },
    {
      lead: "FAILURE",
      id: "post_submit_title_failure",
      label: "Post submit title failure",
      maxLength: {
        message: "Max 24 character allowed",
        value: 24
      }
    },
    {
      lead: "FAILURE",
      id: "post_submit_text_failure",
      label: "Post submit description failure",
      maxLength: {
        message: "Max 120 character allowed",
        value: 120
      }
    }
  ];

  const revalidateQuestionIds = () => {
    let updatedForm = getValues("questions");
    updatedForm = updatedForm.map((item, index) => {
      return {
        ...item,
        priority_order:
          item.priority_order >= updatedForm.length
            ? null
            : item.priority_order,
        options: item.options.map((option, index) => {
          return {
            ...option,
            next_question_id: 0
          };
        })
      };
    });
    setValue("questions", updatedForm);
  };

  const removeQuestion = index => {
    remove(index);
    revalidateQuestionIds();
  };

  const checkCustomValidations = data => {
    const questions = data.questions;
    const maxInterviewDateQuesAllowed = 1;
    let interviewDateQuestionCount = 0;
    const maxInterviewCenterQuesAllowed = 1;
    let interviewCenterQuestionCount = 0;
    questions.forEach(question => {
      if (question.question_category === "interview_date" && question.is_active)
        interviewDateQuestionCount++;
      if (
        question.question_category === "interview_center" &&
        question.is_active
      )
        interviewCenterQuestionCount++;
    });
    if (interviewDateQuestionCount > maxInterviewDateQuesAllowed) {
      throw "You can only have one interview date question";
    }
    if (interviewCenterQuestionCount > maxInterviewCenterQuesAllowed) {
      throw "You can only have one interview center question";
    }
  };

  const onSubmit = async data => {
    try {
      checkCustomValidations(data);
    } catch (error) {
      setError("customError", {
        type: "manual",
        message: error
      });
      setTimeout(() => {
        clearErrors("customError");
      }, 3000);
      return;
    }
    invalidateFormData(data, lang);
  };
  let nextQuestionIdOptions = isConditional
    ? [
        {
          value: -1,
          index: null,
          label: "Submit Form"
        }
      ]
    : [];
  let priorityFieldArray = [];
  questionFields.forEach((question, index) => {
    // as long as we are using length property of field array we will get the updated data
    if (isConditional && !!question.question_id)
      // if quesitons are newly added they are not added in the nextQlist
      nextQuestionIdOptions.push({
        value: question.question_id,
        index,
        label: `Question ${index + 1}`
      });
    priorityFieldArray.push({ value: index, label: `${index + 1}` });
  });
  const onDuplicateForm = ({ duplicatedLang, defaultLang = "en" }) => {
    const formData = JSON.parse(JSON.stringify(getValues())); // added for deep copy of getvalues
    formData.questions = formData.questions.map(item => {
      item.question_label = {
        ...item.question_label,
        [duplicatedLang]: item.question_label[defaultLang]
      };
      item.help_text = {
        ...item.help_text,
        [duplicatedLang]: item.help_text[defaultLang]
      };
      item.options = item.options.map(option => {
        return {
          ...option,
          option_label: {
            ...option.option_label,
            [duplicatedLang]: option.option_label?.[defaultLang]
          }
        };
      });
      item.customOptions = item.customOptions.map(option => {
        return {
          ...option,
          option_label: {
            ...option.option_label,
            [duplicatedLang]: option.option_label?.[defaultLang]
          }
        };
      });
      return item;
    });
    postTextArr.map((item, index) => {
      formData[item.id] = {
        ...formData[item.id],
        [duplicatedLang]: formData[item.id][defaultLang]
      };
    });
    return formData;
  };

  const onDuplicateFormDeletion = duplicatedLang => {
    const formData = JSON.parse(JSON.stringify(getValues())); // added for deep copy of getvalues
    formData.questions = formData.questions.map(item => {
      delete item.question_label[duplicatedLang];
      delete item.help_text[duplicatedLang];
      item.options = item.options.map(option => {
        delete option.option_label[duplicatedLang];
        return option;
      });
      item.customOptions = item.customOptions.map(option => {
        delete option.option_label[duplicatedLang];
        return option;
      });
      return item;
    });
    postTextArr.map((item, index) => {
      delete formData[item.id][duplicatedLang];
    });
    return formData;
  };

  const isDefaultLang = defaultLang === lang;
  const langObj = getLangLabel(lang, allLanguages) ?? {};
  let numberOfActiveQuestions = 0;
  const questions = getValues("questions"); // here we could not use questionFields as we will get the stale value is_active from it hence we used getValues
  questions.forEach(question => {
    numberOfActiveQuestions += Number(!!question.is_active);
  });

  const validatePostText = (value, item, leadsObj) => {
    if (!value && leadsObj[item.lead] > 0) return `${item.label} is required`;
    return true;
  };

  const evaluateNumberOfLeadSuccessOptions = questionsArray => {
    const leadsObj = {
      SUCCESS: 0,
      FAILURE: 0
    };
    questionsArray.forEach(question => {
      if (
        question.question_category === "interview_date" ||
        question.question_category === "interview_center"
      ) {
        leadsObj["SUCCESS"] += 1;
        return; // special case of date and center as by default lead success is true for them.
      }
      question.options.forEach(option => {
        const key = option.is_lead_success ? "SUCCESS" : "FAILURE";
        leadsObj[key] += 1;
      });
    });
    return leadsObj;
  };

  const leadsObj = evaluateNumberOfLeadSuccessOptions(questions);
  // whereever we want to use any field value from questionFields we should use getValues as it gived latest data
  // although if we need to use length propery then directy using questionFields will work fine
  // we are accessign quesition_id from questionFields as it is a derived value of questionField's length.

  const onLangDeleteClick = () => {
    const newFormData = onDuplicateFormDeletion(langObj.id);
    removeDuplicateLangForm(newFormData, langObj.id);
  };

  const onDuplicateLangClick = langObj => {
    const newFormData = onDuplicateForm({
      duplicatedLang: langObj.id,
      defaultLang
    });
    addDuplicateLangForm(newFormData, langObj.id);
  };

  const onAddQuestionClick = e => {
    if (e) e.preventDefault();
    append({
      ...defaultQuestionObj,
      question_id: null,
      priority_order: questionFields.length
    });
  };

  const onError = error => {
    updateFormState("ERROR");
  };

  return (
    <>
      {defaultLang === lang ? (
        <div className={classes.duplicateDropdown}>
          <div>
            <label htmlFor={"language_to_duplicate"}>
              Select language to duplicate the Questionnaire
            </label>
            <Select
              name={"language_to_duplicate"}
              onChange={langObj => {
                onDuplicateLangClick(langObj);
              }}
              options={duplicateLangList}
              getOptionLabel={option => option["label"]}
              getOptionValue={option => option["id"]}
              isMulti={false}
              isSearchable={false}
              placeholder="Duplicate for language"
              isDisabled={!canEditCampaign}
            />
          </div>
        </div>
      ) : null}
      <Accordion
        defaultExpanded={lang === expandedAccordionLang}
        classes={{ root: classes.accordionClass }}
      >
        <AccordionSummary
          expandIcon={
            <img src="/images/arrow_down.svg" height={14} width={14} />
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className={classes.languageHeading}>
            {langObj.label} language
            {isDirty && anyFieldChanged ? (
              <i className="fa-regular fa-circle-check"></i>
            ) : (
              <i
                className="fa-solid fa-circle-check"
                style={{ color: "#63E6BE" }}
              ></i>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.languageFormCnt}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className={classes.formCnt}>
                  <ComponentWrappedBackdrop
                    isVisible={!canEditCampaign}
                    onClick={null}
                  >
                    <div className="text-center">
                      <i className="fas fa-lock fa-3x" />
                      <p className="mt-2">Cannot Edit</p>
                    </div>
                  </ComponentWrappedBackdrop>
                  <div className={classes.fieldCnt}>
                    {questionFields.map((item, index) => (
                      <React.Fragment key={item.id}>
                        <LcsCampaignQueComponent
                          lang={lang}
                          key={index}
                          questionIndex={index}
                          removeQuestion={removeQuestion}
                          nextQuestionIdOptions={nextQuestionIdOptions}
                          update={update}
                          isDefaultLang={isDefaultLang}
                          priorityFieldArray={priorityFieldArray}
                          isConditional={isConditional}
                          defaultOptionObj={defaultOptionObj}
                          numberOfActiveQuestions={numberOfActiveQuestions}
                          formQuestionsData={formQuestionsData}
                        />
                      </React.Fragment>
                    ))}
                    <div className={classes.postTextCnt}>
                      {postTextArr.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display:
                              leadsObj[item.lead] === 0 ? "none" : "block"
                          }}
                        >
                          <MaterialInputTextField
                            fullWidth={true}
                            name={`${item.id}.${lang}`}
                            label={`${item.label}`}
                            control={control}
                            rules={{
                              maxLength: {
                                ...item.maxLength
                              }
                            }}
                            validateArray={value =>
                              validatePostText(value, item, leadsObj)
                            }
                            defaultValue={getValues(item.id)?.[lang]}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={classes.actionCnt}>
                    <button
                      className="submitBtn"
                      title="Delete"
                      onClick={onLangDeleteClick}
                      disabled={isDefaultLang}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                    <button
                      className="submitBtn"
                      title="Add Question"
                      onClick={onAddQuestionClick}
                      disabled={
                        !isDefaultLang ||
                        numberOfActiveQuestions === maxQuestionsAllowed
                      }
                    >
                      <i className="fa-solid fa-circle-plus" />
                    </button>
                    <button
                      title="Save details"
                      className={cx("btn btn-primary", classes.saveDtlsButton)}
                      type="submit"
                      disabled={!(isDirty && anyFieldChanged)}
                    >
                      <i className="fa-regular fa-circle-check"></i>
                    </button>
                  </div>
                </div>
                {errors.customError && (
                  <div className="text-danger flex justify-end my-2 items-center">
                    <i className="fa-solid fa-triangle-exclamation" />
                    {errors.customError.message}
                  </div>
                )}
              </form>
            </FormProvider>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default LcsCampaignLangForm;
