import React from "react";
import JobHai from "../../api/JobHai";
import { getErrorMessage } from "../../services/utils";
import ViewJobLogs from "./ViewJobLogs";
import ViewOldStatus from "./ViewOldStatus";
import ViewRecruiterLogs from "./ViewRecruiterLogs";
import LoadingSpinner from "../LoadingSpinner";

class ViewLogs extends React.Component {
  state = {
    viewLog: false,
    logHistory: [],
    viewOldStatus: false,
    dataFetched: false,
    loading: false
  };

  handleViewLog = () => {
    if (!this.state.dataFetched) {
      this.setState({
        loading: true
      });
      this.geCallingLog();
    }
    this.setState(prevState => ({
      viewLog: !prevState.viewLog,
      viewOldStatus: false
    }));
  };

  geCallingLog = () => {
    const { recId, jobId, statusMessage, type = "job" } = this.props;
    let url = "/recruiter-calling-logs";
    let obj = { recruiter_id: recId };
    if (type === "job") {
      url = "/job-calling-logs";
      obj = { job_id: jobId };
    }
    JobHai.post(url, obj)
      .then(response => {
        const data = response.data.data;
        const { call_history = [] } = data;
        this.setState({
          logHistory: call_history,
          loading: false
        });
      })
      .catch(function (error) {
        let errorMessage = "";
        this.setState({
          loading: false
        });
        errorMessage = getErrorMessage(error);
        statusMessage(errorMessage, 1);
      });
  };

  handleOldStatus = () => {
    this.setState(prevState => ({
      viewOldStatus: !prevState.viewOldStatus,
      viewLog: false
    }));
  };

  render() {
    const { viewLog, logHistory, viewOldStatus, loading } = this.state;
    const { oldStatus = false, oldStatusList = [], type = "job" } = this.props;
    const isActive = viewLog;
    return (
      <>
        <div className="viewLogContainer">
          <ul className={isActive ? "active" : null}>
            <li
              onClick={this.handleViewLog}
              className={viewLog ? "active" : null}
            >
              View Logs
              <img
                src={
                  viewLog ? "/images/arrow_up.svg" : "/images/arrow_down.svg"
                }
                alt="arrow"
                width="10"
                height="6"
                className="down-arrow"
              />
            </li>
            {oldStatus ? (
              <li
                onClick={this.handleOldStatus}
                className={viewOldStatus ? "active" : null}
              >
                Old Status
                <img
                  src="/images/arrow_down.svg"
                  alt="arrow"
                  width="10"
                  height="6"
                  className="down-arrow"
                />
              </li>
            ) : null}
          </ul>
          {viewLog ? (
            <>
              {loading ? (
                <LoadingSpinner key="loading" />
              ) : (
                <>
                  {type === "job" ? (
                    <ViewJobLogs logHistory={logHistory} />
                  ) : (
                    <ViewRecruiterLogs logHistory={logHistory} />
                  )}
                </>
              )}
            </>
          ) : null}
          {viewOldStatus ? (
            <ViewOldStatus
              viewLog={logHistory.length}
              oldStatusList={oldStatusList}
            />
          ) : null}
        </div>
      </>
    );
  }
}
export default ViewLogs;
