import React, { useState } from "react";
import LoadingSpinner from "../LoadingSpinner";

const FraudRecruiter = props => {
  const { rfdData = {}, handleRfdClick } = props;
  const {
    isRefLoading = false,
    rfdList = [],
    restrictedCityCatData = {}
  } = rfdData;
  const [showHide, setShowHide] = useState(true);

  const handleShowHide = () => setShowHide(value => !value);
  const isFraudRec = rfdList.length;
  const isRestrictedCityCatData = Object.keys(
    restrictedCityCatData || {}
  ).length;
  const newRfdList = showHide && rfdList.length ? rfdList.slice(0, 3) : rfdList;
  const isShowMore = rfdList.length > 3;
  return (
    <>
      {isRefLoading ? (
        <div className="rfdContainer">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {isFraudRec || isRestrictedCityCatData ? (
            <div className="rfdContainer">
              <ul>
                {isRestrictedCityCatData ? (
                  <li className="item">
                    <img
                      src={`/images/danger.svg`}
                      alt="danger"
                      height={"16"}
                      width={"16"}
                      className={"flag"}
                    />
                    <span className="restrictedCityCatLine">
                      This recruiter/job belongs to 'Zero-Free Jobs'
                      city-category cohort
                    </span>
                  </li>
                ) : null}
                {newRfdList.map((item, index) => {
                  return (
                    <li className="item" key={`rf${index}`}>
                      {item.type ? (
                        <img
                          src={`/images/${item.type}.svg`}
                          alt="flag"
                          height="16"
                          width={item.type === "autoLive" ? "16" : "11"}
                          className={
                            item.type === "autoLive" ? "flagAutolive" : "flag"
                          }
                        />
                      ) : (
                        <span className="circle"></span>
                      )}
                      {item.msg ? (
                        <span
                          onClick={e => handleRfdClick(e, item)}
                          dangerouslySetInnerHTML={{ __html: item.msg }}
                        />
                      ) : null}
                    </li>
                  );
                })}
              </ul>
              {isShowMore ? (
                <div className="showHide" onClick={handleShowHide}>
                  <span>
                    {showHide ? `Show ${isFraudRec - 3} More` : `Show Less`}
                    <img
                      src={
                        showHide
                          ? "/images/arrow_down.svg"
                          : "/images/arrow_up.svg"
                      }
                      alt="show hide"
                      width="10"
                      height="6"
                    />
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
export default FraudRecruiter;
