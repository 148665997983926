import cx from "classnames";
import { withStyles } from "@material-ui/styles";
import { styles } from "../CreditWidget/helper";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import FileUpload from "./FileUpload";

const CreditsTransferForm = props => {
  const { fieldItems, errorMessage, onChange, onSave, classes } = props;
  const { receiverRecruiterNumber, credits, remark, file } = fieldItems;
  function getFileData(file) {
    onChange({ target: { name: "file", value: file } });
  }
  let disabled =
    !receiverRecruiterNumber ||
    !credits ||
    receiverRecruiterNumber.length < 10 ||
    !remark ||
    !file;
  return (
    <>
      <div className="formRow row">
        <div className="col-md-6">
          <Label lable="Recruiter Mobile" />
          <CustomTextField
            name="receiverRecruiterNumber"
            value={receiverRecruiterNumber}
            onChange={onChange}
            maxLength={10}
            placeholder="Recruiter Mobile"
          />
        </div>
        <div className="col-md-6">
          <Label lable="Quantity" />
          <CustomTextField
            name="credits"
            value={credits}
            onChange={onChange}
            maxLength={6}
            placeholder="Credit Quantity"
          />
        </div>
        <div className="col-md-12">
          <Label lable="Remarks" />
          <CustomTextField
            name="remark"
            value={remark}
            onChange={onChange}
            maxLength={100}
            placeholder="Remarks"
          />
        </div>
        <div className="col-md-12">
          <FileUpload name="file" label="Add Proof" getFileData={getFileData} />
        </div>
      </div>
      <div className={cx("flex", "buttonContainer", "center")}>
        <input
          type="button"
          onClick={() => onSave("PRELIMINARY")}
          value="Save"
          className={cx("submitBtn", "btns", "btn1", "flex", "justifyCenter")}
          disabled={disabled}
        />
      </div>
      {errorMessage && (
        <div className={classes.errorState}>
          <i class="fa fa-x" aria-hidden="true" />
          <span>&nbsp; {errorMessage}</span>
        </div>
      )}
    </>
  );
};
export default withStyles(styles)(CreditsTransferForm);
