import React from "react";
import moment from "moment";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import { getErrorMessage, checkPermission } from "../../services/utils";
import AssignList from "./AssignList";
import MetaData from "../../constants/MetaData";
import AddCrawlData from "./AddCrawlData";
import CrawlFilter from "./CrawlFilter";
import { getLocalStorage, setLocalStorage } from "../../services/localStorage";
import { getCrmMetaData } from "../../services/metadataApiServices";

class CrawlingV2 extends React.Component {
  state = {
    isLoading: true,
    statsList: [],
    moderatorList: [],
    msgData: { msg: "", showMsg: false, msgType: "error" },
    isCrawling: false,
    isBtnDisabled: false,
    categoriesList: []
  };

  componentDidMount() {
    const isCrawling = checkPermission("Job Crawling");
    this.setState({ isCrawling });
    if (isCrawling) {
      this.getModerators();
      this.getStats();
      this.getCategories();
    }
  }

  setDefaultState = (msg = "") => {
    const showMsg = !!msg;
    this.setState({
      isLoading: false,
      isBtnDisabled: false,
      msgData: { msg, showMsg, msgType: "error" }
    });
  };

  getStats = (filters = {}) => {
    this.setState({
      isBtnDisabled: true
    });
    const { sdate = "", edate = "", city_id = "", source = "" } = filters;
    const searchObj = {
      start_date: sdate ? moment(sdate).format("YYYY-MM-DD") : undefined,
      end_date: edate ? moment(edate).format("YYYY-MM-DD") : undefined,
      city_id: city_id ? city_id : undefined,
      source: source ? source : undefined
    };
    JobHai.post(`/bravo-moderator-stats`, searchObj)
      .then(response => {
        let data = response.data.data;
        this.setState({
          statsList: data
        });
        this.setDefaultState();
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setDefaultState(msg);
      });
  };

  getModerators = () => {
    JobHai.get(`../v2/metadata/bravo-moderators`)
      .then(response => {
        let data = response.data.data;
        this.setState({
          moderatorList: data
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setDefaultState(msg);
        window.scrollTo(0, 0);
      });
  };

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  getCategories = async () => {
    try {
      const response = await getCrmMetaData();
      const { categories = [] } = response;
      this.setState({
        categoriesList: categories
      });
    } catch (error) {
      const msg = getErrorMessage(error);
      this.setState({
        isLoading: false,
        msgData: { msg: msg, showMsg: true, msgType: "error" }
      });
    }
  };

  render() {
    const {
      isLoading,
      statsList,
      msgData,
      moderatorList,
      isCrawling,
      isBtnDisabled,
      categoriesList
    } = this.state;
    const sourceList = MetaData.crawlSourceList;
    return (
      <div className="edit-form">
        <div className="clr gap-20" />
        <PopupMessage data={msgData} click={this.handleClosePopup} />
        <div className="pageHeading row">
          <h2>Job Crawling</h2>
        </div>
        {isCrawling ? (
          <>
            <CrawlFilter
              sourceList={sourceList}
              getStats={this.getStats}
              isBtnDisabled={isBtnDisabled}
            />
            <AssignList
              dataList={statsList}
              isLoading={isLoading}
              sourceList={sourceList}
            />
            <AddCrawlData
              moderatorList={moderatorList}
              sourceList={sourceList}
              getStats={this.getStats}
              catList={categoriesList}
            />
          </>
        ) : (
          <div
            className="container-fluid gap-20"
            style={{ textAlign: "center" }}
          >
            <div className="clr gap-20" />
            <span className="alert alert-danger" role="alert">
              <i className="fa fa-exclamation-triangle" aria-hidden="true" />
              You don't have access to view this page
            </span>
          </div>
        )}
      </div>
    );
  }
}
export default CrawlingV2;
