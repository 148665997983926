import { Route, Routes } from "react-router-dom";
import { LOGIN } from "../routes/RouteConstants";
import { Login } from "./Login/Login";
const PublickRoutes = () => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path={LOGIN} element={<Login />} />
    </Routes>
  );
};

export default PublickRoutes;
