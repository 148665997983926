import { InputLabel } from "@material-ui/core";
import MaterialCheckBoxGroup from "../../materialCheckBoxGroup";
import useLcsCampaignDetails from "../../../zustandService/useLcsCampaignDetails";
import useLcsCampaignEditPermission from "../../../zustandService/useLcsCampaignEditPermission";

const LeadClassificationCriteria = props => {
  const { questionsData } = useLcsCampaignDetails(state => ({
    questionsData: state.questionsData
  }));
  const { canEditCampaign } = useLcsCampaignEditPermission(state => ({
    canEditCampaign: state.canEditCampaign
  }));
  const { questions } = questionsData;
  const { ruleIndex, method } = props;
  const { watch, control } = method;
  const singleRuleWatcher = watch(`rules.${ruleIndex}`);
  const conditions = singleRuleWatcher.conditions.all;

  return (
    <>
      {conditions.map((field, index) => {
        const questionIndex = questions.findIndex(
          item => item.question_id === field.fact
        );
        const question = questions[questionIndex];
        const isDateQuestion = question.question_category === "interview_date";
        const dummyDateOption = {
          option_id: "interview_date",
          option_label: {
            en: "Date"
          },
          is_active: true
        };
        let options = [];
        if (isDateQuestion) options.push(dummyDateOption);
        options.push(
          ...question.options.filter(option => option.is_active),
          ...question.customOptions.filter(option => option.is_active)
        );

        return (
          <div
            className="my-3 border-b border-[#ecebeb]"
            key={field.question_id}
          >
            <div>
              <label>Question {questionIndex + 1}</label>
              <InputLabel>{question.question_label.en}</InputLabel>
            </div>
            <MaterialCheckBoxGroup
              name={`rules.${ruleIndex}.conditions.all.${index}.value`}
              control={control}
              options={options}
              disabled={!canEditCampaign}
            />
          </div>
        );
      })}
    </>
  );
};

export default LeadClassificationCriteria;
