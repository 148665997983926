const TabsList = ({ tabList, onClick, isSelected, labelId = "title" }) => {
  if (!tabList.length) return;
  return (
    <div className="tabs">
      <div className="tab-list">
        {tabList.map(item => {
          const { is_disabled, id } = item;
          return (
            <span
              className={
                is_disabled
                  ? "unClickedChip disabledChip"
                  : id === isSelected
                  ? "unClickedChip clickedChip"
                  : "unClickedChip"
              }
              onClick={() => onClick(id)}
              key={id}
            >
              {item[labelId]}
            </span>
          );
        })}
      </div>
    </div>
  );
};
export default TabsList;
