import React from "react";
import moment from "moment";
import JobHai from "../../api/JobHai";
import MetaData from "../../constants/MetaData";
import DatePicker from "react-datepicker";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import { getErrorMessage } from "../../services/utils";

class LeadSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      company_name: "",
      city_id: "",
      locality_id: "",
      company_type: "",
      company_contact_info: "",
      industry_type: "",
      recruiter_name: "",
      email: "",
      phone: "",
      sourced_from: "",
      acquisition_status: "",
      call_status: "",
      industryType: [],
      edate: "",
      sdate: "",
      moderator_id: "",
      msgData: { msg: "", showMsg: false, msgType: "error" }
    };
  }

  handleChange = event => {
    if (event.target.name === "city_id") {
      this.setState({ locality_id: "" });
    }
    if (event.target.name === "locality_id" && event.target.value !== "") {
      this.setState({
        locality_id: event.target.value
      });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  updateMessage = (msg, flag) => {
    this.props.statusMessage(msg, flag);
  };

  componentDidMount() {
    let crmTags = {};
    if (localStorage.getItem("crmTags")) {
      crmTags = JSON.parse(localStorage.getItem("crmTags"));
      if (crmTags.industries && crmTags.industries.length) {
        this.setState({
          industryType: crmTags.industries
        });
      } else {
        this.getIndustry();
      }
    } else {
      this.getIndustry();
    }
  }

  getIndustry = () => {
    JobHai.get("../metadata/tags")
      .then(metadata => {
        let data = metadata.data.data;
        localStorage.setItem("crmTags", JSON.stringify(data));
        this.setState({
          industryType: data.industries ? data.industries : []
        });
      })
      .catch(
        function (error) {
          let msg = getErrorMessage(error);
          this.setState({
            isLoading: false,
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
        }.bind(this)
      );
  };

  searchLeads = e => {
    e.preventDefault();
    const obj = {
      company_name: this.state.company_name,
      city_id: this.state.city_id,
      locality_id: this.state.locality_id,
      company_type: this.state.company_type,
      company_contact_info: this.state.company_contact_info,
      industry_type: this.state.industry_type,
      recruiter_name: this.state.recruiter_name,
      email: this.state.email,
      phone: this.state.phone ? Number(this.state.phone) : "",
      sourced_from: this.state.sourced_from,
      acquisition_status: this.state.acquisition_status,
      call_status: this.state.call_status,
      moderator_id: this.state.moderator_id
    };

    if (this.state.edate || this.state.sdate) {
      obj.date = {
        start_date: this.state.sdate
          ? moment(this.state.sdate).format("YYYY-MM-DD")
          : "",
        end_date: this.state.edate
          ? moment(this.state.edate).format("YYYY-MM-DD")
          : ""
      };
    }
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        delete obj[key];
      }
    });
    this.props.searchRecruiters(obj);
  };

  render() {
    const { isButtonDisabled } = this.props;
    const { industryType } = this.state;
    const sourceFrom = MetaData.sourced_from;
    const ModeratorList = [
      { name: "Apeksha", id: 1 },
      { name: "Ashmeet", id: 4 },
      { name: "Aastha", id: 10 },
      { name: "Ishika", id: 12 },
      { name: "Kuljit", id: 7 },
      { name: "Mahima", id: 3 },
      { name: "Nitya", id: 6 },
      { name: "Priya", id: 2 },
      { name: "Tarika", id: 5 },
      { name: "Sonali", id: 13 }
    ];

    return (
      <div className="job_seeker recruiter-search">
        <div className="gap-20"></div>
        <form autoComplete="off" onSubmit={this.searchLeads} className="search">
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="company_name"
                  placeholder="Company Name"
                  value={this.state.company_name}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <SelectCity
                filedName={"city_id"}
                value={this.state.city_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-2">
              <SelectLocality
                filedName={"locality_id"}
                value={this.state.locality_id}
                cityId={this.state.city_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="company_contact_info"
                  value={this.state.company_contact_info}
                  onChange={this.handleChange}
                  placeholder="Contact Number"
                />
              </div>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="company_type"
                value={this.state.company_type}
                onChange={this.handleChange}
              >
                <option value="">Select Company Type</option>
                {MetaData.company_type.map(option => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="industry_type"
                value={this.state.industry_type}
                onChange={this.handleChange}
              >
                <option value="">Select Industry Type</option>
                {industryType.map(item => (
                  <option value={item.id} key={`it${item.id}`}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="recruiter_name"
                  placeholder="Recruiter Name "
                  value={this.state.recruiter_name}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  placeholder="Recruiter Phone no"
                  value={this.state.phone}
                  onChange={event =>
                    this.setState({
                      phone: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  placeholder="Recruiter Email ID"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="sourced_from"
                  value={this.state.sourced_from}
                  onChange={this.handleChange}
                >
                  <option value="">Source From</option>
                  {sourceFrom.map(option => (
                    <option value={option} key={`sourcef${option}`}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="acquisition_status"
                  value={this.state.acquisition_status}
                  onChange={this.handleChange}
                >
                  <option value="">Acquisition Status</option>
                  <option value="ACQUIRED">Acquired</option>
                  <option value="NOT_ACQUIRED">Not Acquired</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="call_status"
                  value={this.state.call_status}
                  onChange={this.handleChange}
                >
                  <option value="">Call Status</option>
                  <option value="CONNECTED">Connected</option>
                  <option value="NOT_CONNECTED">Not Connected</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="moderator_id"
                  value={this.state.moderator_id}
                  onChange={this.handleChange}
                >
                  <option value="">Select Agent</option>
                  {ModeratorList.map(option => (
                    <option value={option.id} key={"mod" + option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="from-col">Date From</div>
            <div className="col-md-2">
              <DatePicker
                selected={this.state.sdate}
                onChange={value => {
                  this.setState({ sdate: value });
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </div>
            <div className="col-md-1 to-col">To</div>
            <div className="col-md-2">
              <DatePicker
                selected={this.state.edate}
                onChange={value => {
                  this.setState({ edate: value });
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </div>
            <div className="col-md-4">
              <div className="float-right">
                {isButtonDisabled ? (
                  <button className="btn btn-primary disable" disabled>
                    SEARCHING ...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={this.searchLeads}
                  >
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="clr"></div>
      </div>
    );
  }
}
export default LeadSearch;
