import React, { useState, useEffect } from "react";
import { getValueFromURL } from "../../services/utils";
import { getLcsCampaignList } from "../../services/metadataApiServices";

const LcsClients = props => {
  const [clientList, setClientList] = useState([]);
  const {
    url = "",
    showAll = false,
    listType = "chip",
    onChange,
    clientId
  } = props;
  useEffect(() => {
    getLcsCampaignList()
      .then(response => {
        setClientList(response);
      })
      .catch(error => {
        console.log("Error: ", error);
      });
  }, []);
  const pageType = getValueFromURL("pageType");
  return (
    <>
      {listType === "chip" ? (
        <div className="organic-tab lcs-tab">
          <ul className="nav">
            {showAll ? (
              <li className="nav-item">
                <a
                  href={`${url}`}
                  rel="noopener noreferrer"
                  className={!clientId ? "nav-link active" : "nav-link"}
                >
                  All
                </a>
              </li>
            ) : null}
            {clientList.map(item => (
              <li className="nav-item" key={`client-${item.id}`}>
                <a
                  href={`${url}/?client=${item.client_id}${
                    pageType === "LEAD" ? `&pageType=LEAD` : ""
                  }`}
                  rel="noopener noreferrer"
                  className={
                    Number(clientId) === item.client_id
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          <b
            className="downArrow"
            style={{ padding: "3px", marginTop: "13px" }}
          />
          <select
            className="form-control"
            name="client_id"
            value={clientId}
            onChange={onChange}
          >
            <option value="">Select Client</option>
            {clientList.map(item => {
              return (
                <option value={item.client_id} key={"mod" + item.client_id}>
                  {item.title}
                </option>
              );
            })}
          </select>
        </>
      )}
    </>
  );
};

export default LcsClients;
