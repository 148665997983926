import { User } from "lucide-react";
import { Card, CardHeader, CardTitle, CardContent } from "../../ui/card";
import { Badge } from "../../ui/badge";
import useLeadRecruiterDetailStore from "../../../zustandService/useLeadRecruiterDetailStore";
import StateRenderer from "./stateRenderer";

let BasicDetails = () => {
  const { recruiterDetailsState, recruiterDetails } =
    useLeadRecruiterDetailStore(state => ({
      recruiterDetailsState: state.recruiterDetailsState,
      recruiterDetails: state.recruiterDetails
    }));
  return (
    <>
      <StateRenderer state={recruiterDetailsState}>
        <Card className="">
          <CardHeader>
            <div className="flex  items-center space-x-3">
              <div className=" size-10 bg-blue-50 flex items-center text-blue-500 rounded-md   justify-center">
                <User />
              </div>
              <CardTitle className="text-xl">Basic Details</CardTitle>
              {recruiterDetails?.recruiterType ? (
                <Badge className={"bg-green-500 hover:bg-green-500"}>
                  {recruiterDetails?.recruiterType}
                </Badge>
              ) : null}
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1  md:grid-cols-4 gap-x-6 gap-y-6">
              <div>
                <p className="text-sm  text-gray-500">Name</p>
                <p className="font-medium">{recruiterDetails?.recruiterName}</p>
              </div>
              <div>
                <p className="text-sm  text-gray-500">Company</p>
                <p className="font-medium">{recruiterDetails?.companyName}</p>
              </div>
              <div>
                <p className="text-sm  text-gray-500">Phone number</p>
                <p className="font-medium">{recruiterDetails?.phoneNo}</p>
              </div>
              <div>
                <p className="text-sm  text-gray-500">Staus</p>
                <p className="font-medium">
                  <Badge
                    variant={"outline"}
                    className=" font-medium  text-green-500"
                  >
                    {recruiterDetails?.recruiterStatus}
                  </Badge>
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
        {/* <Alert className="">
        <Flag />
        <Alert>Flagged Recruiter</AlertTitle>
        <AlertDescription>
          This recruiter posts jobs in{' '}
          <span className="  font-semibold">New Delhi</span> and{' '}
          <span className="  font-semibold">Mumbai</span>
        </AlertDescription>
      </Alert> */}
      </StateRenderer>
    </>
  );
};
export default BasicDetails;
