import { CoinsIcon, HandshakeIcon, User2Icon } from "lucide-react";
import { Card, CardHeader, CardTitle, CardContent } from "../../ui/card";
import useInsideSalesStore from "../../../zustandService/useInsideSalesStore";

const SalesSummary = () => {
  const { salesSummary, fetchState, errorMessage } = useInsideSalesStore(
    state => ({
      salesSummary: state.salesSummary,
      fetchState: state.fetchState,
      errorMessage: state.errorMessage
    })
  );

  if (fetchState === "LOADING") {
    return (
      <div className="grid gap-4 mx-3 my-3 md:grid-cols-2 lg:grid-cols-4">
        {[1, 2, 3].map(index => (
          <Card key={index}>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">Loading...</CardTitle>
              <div className="w-8 h-4 bg-gray-200 rounded-full dark:bg-gray-700" />
            </CardHeader>
            <CardContent>
              <div className="flex w-40 h-4 bg-gray-200 rounded-full dark:bg-gray-700" />
            </CardContent>
          </Card>
        ))}
      </div>
    );
  }

  if (fetchState === "LOADING") {
    return (
      <div className="grid gap-4 mx-3 my-3 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <div className="text-red-500 mx-3 my-3">Error: {errorMessage}</div>
        </Card>
      </div>
    );
  }

  return (
    <div className="grid gap-4 mx-3 my-3 md:grid-cols-2 lg:grid-cols-4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">Total Sales</CardTitle>
          <HandshakeIcon className="w-8 h-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {salesSummary?.orderCount || 0}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">
            Total Collection
          </CardTitle>
          <User2Icon className="w-4 h-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {salesSummary?.totalCollection || 0}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">Total Credit</CardTitle>
          <CoinsIcon className="w-4 h-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {salesSummary?.totalCreditsPurchased || 0}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SalesSummary;
