import { CREATE_CAMPAIGN, VIEW_CAMPAIGN } from "../../routes/RouteConstants";
import { checkPermission } from "../../services/utils";
import withRouter from "../../services/withRouter";
import HeadingRow from "../Common/HeadingRow";
import NoAccess from "../Common/NoAccess";

const CampaignDashboard = () => {
  const isAccess =
    checkPermission("CampaignManagement") ||
    checkPermission("CampaignManagementAdmin");
  const isViewPage = isAccess;
  return (
    <div className="dashboard">
      <HeadingRow heading="Campaign Dashboard" />
      <div className="gap-20" />
      <div className="whiteCard">
        {isViewPage ? (
          <>
            <div className="container-fluid">
              <div className="clr gap-20" />
              <div className="row marginBottom20">
                {isAccess ? (
                  <>
                    <div className="col-md-4">
                      <div className="box">
                        <a href={VIEW_CAMPAIGN}>View Campaigns</a>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="box">
                        <a href={CREATE_CAMPAIGN}>Add New Campaign</a>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <NoAccess />
        )}
      </div>
    </div>
  );
};

export default withRouter(CampaignDashboard);
