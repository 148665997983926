import React, { useMemo, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Button, Card, CardContent } from "@material-ui/core";
import useLcsCampaignDetails from "../../../zustandService/useLcsCampaignDetails";
import LeadClassificationCriteria from "./leadClassficationCriteria";
import classes from "./leadClassificationTab.module.css";
import { useParams } from "react-router-dom";
import LeadClassificationFormHeader from "./leadClassificationFormHeader";
import useLcsCampaignEditPermission from "../../../zustandService/useLcsCampaignEditPermission";

export default function LeadClassificationRules({ activeBucketTab }) {
  const { id: campaignId } = useParams();
  const {
    questionsData,
    leadClassificationRules,
    fetchLeadClassificationRules,
    saveUpdateLeadClassificationRules,
    fetchState: {
      saveLeadClassificationRules: saveLeadClassificationFetchState
    }
  } = useLcsCampaignDetails(state => ({
    questionsData: state.questionsData,
    leadClassificationRules: state.leadClassificationRules,
    fetchLeadClassificationRules: state.fetchLeadClassificationRules,
    saveUpdateLeadClassificationRules: state.saveUpdateLeadClassificationRules,
    fetchState: state.fetchState
  }));
  const { canEditCampaign } = useLcsCampaignEditPermission(state => ({
    canEditCampaign: state.canEditCampaign
  }));
  const { questions } = questionsData;

  const emptyAllConditions = () => {
    const conditions = [];
    questions.forEach(question => {
      if (question.is_active)
        conditions.push({
          fact: question.question_id,
          operator: "in",
          value: []
        });
    });
    return conditions;
  };

  const defaultRulesFromApi = useMemo(
    () =>
      leadClassificationRules?.rules.filter(rule => {
        let conditions = [];
        for (let i = 0; i < questions.length; i++) {
          const savedCondition = rule.conditions.all.find(
            condition => condition.fact === questions[i].question_id
          );
          if (savedCondition) {
            if (!questions[i].is_active) {
              rule.is_active = false;
              conditions = emptyAllConditions();
              break;
            }
            const isConditionValueInvalid = savedCondition.value.some(
              valueId => {
                const option = questions[i].options.find(
                  option => option.option_id === valueId
                );
                if (!option) return false;
                return !option.is_active;
              }
            );

            if (isConditionValueInvalid) {
              rule.is_active = false;
              conditions = emptyAllConditions();
              break;
            }

            const isConditionValueInvalidCustomOption =
              savedCondition.value.some(valueId => {
                const option = questions[i].customOptions.find(
                  option => option.option_id === valueId
                );
                if (!option) return false;
                return !option.is_active;
              });

            if (isConditionValueInvalidCustomOption) {
              rule.is_active = false;
              conditions = emptyAllConditions();
              break;
            }

            conditions.push(savedCondition);
          } else if (questions[i].is_active) {
            conditions.push({
              fact: questions[i].question_id,
              operator: "in",
              value: []
            });
          }
        }
        rule.conditions.all = conditions;
        return rule;
      }),
    [leadClassificationRules]
  );
  const defaultConditions = [];
  questions.forEach(question => {
    if (question.is_active) {
      defaultConditions.push({
        fact: question.question_id,
        operator: "in",
        value: []
      });
    }
  });
  const defaultRule = {
    conditions: {
      all: defaultConditions
    }
  };
  const method = useForm({
    defaultValues: {
      rules: defaultRulesFromApi.length ? defaultRulesFromApi : [defaultRule]
    }
  });
  const { control, handleSubmit } = method;

  const {
    fields: rulesList,
    append,
    remove
  } = useFieldArray({
    control,
    name: `rules`
  });

  const onSubmit = async data => {
    try {
      const rules = data.rules.map(rule => {
        rule.conditions.all = rule.conditions.all.filter(
          condition => condition.value?.length
        );
        return rule;
      });
      await saveUpdateLeadClassificationRules({
        rules: rules,
        bucket: activeBucketTab,
        campaign_id: campaignId
      });
      fetchLeadClassificationRules({ campaignId, bucket: activeBucketTab });
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
      {rulesList.map((field, index) => {
        return (
          <div className={classes.ruleFormCnt} key={field.id}>
            <LeadClassificationFormHeader
              ruleIndex={index}
              method={method}
              remove={remove}
            />
            <Card className={classes.card}>
              <CardContent>
                <LeadClassificationCriteria ruleIndex={index} method={method} />
              </CardContent>
            </Card>
          </div>
        );
      })}
      <div className="flex justify-between">
        <Button
          variant="outlined"
          color="primary"
          onClick={e => append(defaultRule)}
          disabled={!canEditCampaign}
        >
          Add Criteria
        </Button>

        <Button
          disabled={
            saveLeadClassificationFetchState === "LOADING" || !canEditCampaign
          }
          variant="contained"
          color="primary"
          type="submit"
        >
          Save Rules
        </Button>
      </div>
    </form>
  );
}
