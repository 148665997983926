import JobHai from "../../api/JobHai";
import { getErrorMessage, getQueryString } from "../utils";

export const getLeadCount = params => {
  const queryStr = getQueryString(params);
  return new Promise((resolve, reject) => {
    JobHai.get(`/enterprise-kam/count-lcs-lead?${queryStr}`)
      .then(response => {
        resolve(response.data.data); // Resolve with the data
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg); // Reject with the error message
      });
  });
};

export const getSharedLeads = params => {
  const queryStr = getQueryString(params);
  return new Promise((resolve, reject) => {
    JobHai.get(`/enterprise-kam/lcs-leads?${queryStr}`)
      .then(response => {
        resolve(response.data.data); // Resolve with the data
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg); // Reject with the error message
      });
  });
};

export const uploadSharedLeads = params => {
  return new Promise((resolve, reject) => {
    JobHai.post(`/enterprise-kam/upload-shared-lcs-leads`, { data: params })
      .then(response => {
        resolve(response.data.data); // Resolve with the data
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg); // Reject with the error message
      });
  });
};
