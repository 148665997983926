import React from "react";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../LoadingSpinner";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import CustomePagination from "../../Common/Pagination";
import ReportedList from "./ReportedList";
import ReportedJobSearch from "./ReportedJobSearch";
import {
  getErrorMessage,
  checkPermission,
  isCRMAdmin,
  getValueFromURL
} from "../../../services/utils";
import TatSummary from "./TatSummary";
import ReportedJobTab from "./ReportedJobTab";
import {
  COMPLAINTS,
  COMPLIANCE_PROTECTION,
  REPORTED_JOBS
} from "../../../routes/RouteConstants";
import withRouter from "../../../services/withRouter";
import NoAccess from "../../Common/NoAccess";
import HeadingRow from "../../Common/HeadingRow";

class ReportedJobs extends React.Component {
  constructor(props) {
    super(props);
    const { params = {} } = props;
    const { type = "reported-jobs" } = params;
    const recId = getValueFromURL("recId");
    const jsId = getValueFromURL("jsId");
    const recIds = getValueFromURL("recIds");
    const reportReason = getValueFromURL("reportReason");
    const isCPSection = type === "compliance-protection";
    let defaultFilters = { callStatus: "New", showAllReports: true };
    if (recId) {
      defaultFilters = { recruiterId: recId, showAllReports: true };
    }
    if (jsId) {
      defaultFilters = { jsId };
    }
    if (recIds) {
      defaultFilters = { recruiterIds: recIds };
    }
    if (reportReason) defaultFilters = { ...defaultFilters, reportReason };

    this.state = {
      isLoading: true,
      filters: defaultFilters,
      reportedJobList: [],
      activePage: 1,
      count: 0,
      itemsCountPerPage: 10,
      saveReportId: "",
      successMessage: "",
      isButtonDisabled: false,
      msgData: { msg: "", showMsg: false, msgType: "error" },
      reportSummary: [],
      isAlert: false,
      recruiterId: recId,
      reportId: "",
      tabType: "Assigned",
      isRecruiter: !!recId,
      isJobSeeker: !!jsId,
      isReportMainPage: !recId && !jsId && !recIds,
      clusterList: {},
      reportReason,
      recIds,
      sectionType: type,
      isCPSection,
      myPerformance: false,
      performanceLoading: false
    };
  }

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      }
    });
    window.scrollTo(0, 0);
  };

  componentDidUpdate(prevProps, prevState) {
    const { activePage, filters } = this.state;
    if (activePage !== prevState.activePage) {
      this.setState({
        activePage: activePage
      });
      this.getReports();
      window.scroll({ top: 0, behavior: "smooth" });
    }
    if (filters !== prevState.filters) {
      this.setState({
        filters: filters
      });
      this.getReports();
    }
  }

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  async componentDidMount() {
    const { sectionType } = this.state;
    const showReportedJob = checkPermission("Reported Jobs");
    const isAccess =
      showReportedJob &&
      (sectionType === "compliance-protection" ||
        sectionType === "reported-jobs");
    if (isAccess) {
      this.getReports();
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  handleChange = (reportId, fieldName, fieldValue) => {
    const { reportedJobList } = this.state;
    var foundIndex = reportedJobList.findIndex(
      item => item.report_id === reportId
    );
    const checkReportId = reportedJobList.filter(
      item => item.report_id === reportId
    );
    if (checkReportId.length > 0 && checkReportId[0]) {
      checkReportId[0][fieldName] = fieldValue;
      reportedJobList[foundIndex] = checkReportId[0];
      this.setState({ reportedJobList: reportedJobList });
    }
    this.setState({
      saveReportId: "",
      successMessage: ""
    });
  };

  searchReportedJobs = objData => {
    this.setState({
      isButtonDisabled: true,
      filters: objData,
      activePage: 1,
      saveReportId: "",
      successMessage: "",
      msgData: {
        msg: "",
        showMsg: false,
        msgType: ""
      }
    });
  };

  getReports = () => {
    const { filters, activePage, itemsCountPerPage, tabType, isCPSection } =
      this.state;
    let queryString = Object.keys(filters)
      .map(key => key + "=" + filters[key])
      .join("&");
    let apiRoute = isCPSection
      ? `/reported-jobs/compliance-protection`
      : `/reported-jobs`;
    let url = `${apiRoute}?page=${activePage}&size=${itemsCountPerPage}&CPSection=${isCPSection}`;
    if (!isCRMAdmin() && tabType === "Assigned") {
      url += "&isModerator=true";
    }

    url += queryString ? `&${queryString}` : "";
    const reportStatus = getValueFromURL("reportStatus");
    if (reportStatus) url += `&reportStatus=${reportStatus}`;
    JobHai.get(url)
      .then(response => {
        const {
          reportSummary,
          total,
          paginatedResult = [],
          clusterResult = {}
        } = response.data.data;
        let data = paginatedResult;
        data.map(item => {
          item.is_valid =
            item.is_valid === 1
              ? "Valid"
              : item.is_valid === 0
              ? "Invalid"
              : "";
          item.uploadFile = {};
          return item;
        });
        this.setState({
          reportedJobList: data,
          isLoading: false,
          count: total,
          isButtonDisabled: false,
          isAlert: false,
          msgData: { msg: "", showMsg: false, msgType: "error" },
          clusterList: clusterResult,
          reportSummary
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          msgData: { msg: msg, showMsg: true, msgType: "error" },
          isButtonDisabled: false,
          isLoading: false
        });
        window.scrollTo(0, 0);
      });
  };

  getReportSummery() {
    const { tabType, isCPSection } = this.state;
    let url = `reportedjobs-summary?CPSection=${isCPSection}`;
    url = tabType === "Assigned" ? `${url}&isModerator=true` : url;
    JobHai.get(url)
      .then(response => {
        const { reportSummary } = this.state;
        const { total_reports_today = 0, pending_reports = 0 } = reportSummary;
        this.setState({
          reportSummary: {
            total_reports_today,
            pending_reports,
            ...response.data.data
          },
          performanceLoading: false
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          msgData: { msg: msg, showMsg: true, msgType: "error" },
          performanceLoading: false
        });
      });
  }

  updateReport = updatedItems => {
    if (!updatedItems) return;
    const {
      classified_reason,
      call_status,
      is_valid,
      remarks = null,
      report_id = "",
      uploadFile,
      invalid_reason_text = "",
      invalid_reason = ""
    } = updatedItems;
    let data = new FormData();
    const classifiedReason = classified_reason || null;
    const callStatus = call_status || null;
    let isValid = null;
    if (is_valid === "Valid") isValid = true;
    else if (is_valid === "Invalid") {
      isValid = false;
      if (!invalid_reason) {
        this.handleChange(
          report_id,
          "invalidReasonError",
          "Please select invalid reason"
        );
        return;
      }
      if (invalid_reason === "OTHERS" && !invalid_reason_text) {
        this.handleChange(
          report_id,
          "invalidReasonError",
          "Please enter reason"
        );
        return;
      }
      data.append("invalidReason", invalid_reason || null);
      if (invalid_reason === "OTHERS" && invalid_reason_text)
        data.append("invalidReasonText", invalid_reason_text);
    }
    if (!classifiedReason && isValid) {
      this.handleChange(report_id, "uploadFile", {
        errorMessage: "Please select classified reason"
      });
      return;
    }

    data.append("classifiedReason", classifiedReason);
    data.append("callStatus", callStatus);
    data.append("isValid", isValid);
    data.append("reportId", report_id);
    if (uploadFile?.file) data.append("file", uploadFile.file);
    data.append("remarks", remarks || null);

    JobHai.post("/update-reported-job", data)
      .then(response => {
        this.setState({
          saveReportId: report_id,
          successMessage: "Saved"
        });
        this.getReports();
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
        window.scrollTo(0, 0);
      });
  };

  closeRecruiterJobsAPI = isBlaclList => {
    const { recruiterId, reportId } = this.state;
    if (recruiterId) {
      JobHai.post("/close-job", {
        recruiterId,
        reportId,
        blacklistRecruiter: isBlaclList
      })
        .then(response => {
          this.getReports();
        })
        .catch(error => {
          let msg = getErrorMessage(error);
          this.setState({
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
          window.scrollTo(0, 0);
        });
    }
  };

  handleTabClick = tabType => {
    this.setState({
      tabType,
      filters: { callStatus: "New" },
      myPerformance: false
    });
  };

  closeRecruiterJobs = (recruiterId, reportId) => {
    this.setState({ isAlert: true, recruiterId, reportId });
  };

  hideModal = () => {
    this.setState({ isAlert: false });
  };

  renderModal = () => {
    return (
      <Modal show={true}>
        <Modal.Body>
          <b>Please confirm</b>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => this.closeRecruiterJobsAPI(false)}
          >
            Close
          </button>
          <button
            className="btn btn-warning"
            onClick={() => this.closeRecruiterJobsAPI(true)}
          >
            Close & Blacklist
          </button>
          <button className="btn btn-secondary" onClick={this.hideModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  getMyPerformance = () => {
    if (!this.state.myPerformance) {
      this.setState({ myPerformance: true, performanceLoading: true }, () =>
        this.getReportSummery()
      );
    }
  };

  render() {
    const {
      reportedJobList,
      isLoading,
      count,
      activePage,
      itemsCountPerPage,
      msgData,
      saveReportId,
      successMessage,
      isButtonDisabled,
      reportSummary,
      isAlert,
      tabType,
      recruiterId,
      isRecruiter,
      isReportMainPage,
      isJobSeeker,
      clusterList,
      reportReason,
      recIds,
      sectionType,
      isCPSection,
      performanceLoading,
      myPerformance
    } = this.state;
    const isAllowEdit = !checkPermission("Reported Jobs", true);
    const showFilter = isReportMainPage || isRecruiter || recIds;
    const heading = isCPSection ? "Compliance & Protection" : "Reported Jobs";
    const showReportedJob = checkPermission("Reported Jobs");
    const isAccess =
      showReportedJob && (isCPSection || sectionType === "reported-jobs");
    const reportURL = isCPSection ? COMPLIANCE_PROTECTION : REPORTED_JOBS;
    const goBackURL = isReportMainPage ? COMPLAINTS : reportURL;
    if (isLoading) {
      return <LoadingSpinner key="loading" />;
    } else {
      return (
        <div className="job_seeker admin-pannel">
          {isAlert ? this.renderModal() : null}
          <div className="clr gap-20" />
          <PopupMessage
            data={msgData}
            key="popup"
            click={this.handleClosePopup}
          />
          <HeadingRow heading={heading} goBackURL={goBackURL} />
          {isReportMainPage && isAccess ? (
            <ReportedJobTab type={tabType} handleClick={this.handleTabClick} />
          ) : null}
          {showFilter && isAccess ? (
            <ReportedJobSearch
              isButtonDisabled={isButtonDisabled}
              searchReportedJobs={this.searchReportedJobs}
              statusMessage={this.statusMessage}
              recruiterId={recruiterId}
              selectedTab={tabType}
              reportReason={reportReason}
              recIds={recIds}
              isCPSection={isCPSection}
            />
          ) : null}
          {isReportMainPage && isAccess ? (
            <TatSummary
              reportSummary={reportSummary}
              onClick={this.getMyPerformance}
              loading={performanceLoading}
              myPerformance={myPerformance}
            />
          ) : null}

          <div className="whitebox reported-job">
            {isAccess ? (
              <>
                <div className="react-bootstrap-table">
                  <table className="table table-hover table-condensed borderless reportedTable">
                    <thead>
                      <tr className="listHeding">
                        <th>Job Details</th>
                        <th>Job Seeker</th>
                        <th>#Report/View</th>
                        <th>Report Reason</th>
                        <th>Classified Reason</th>
                        <th>Authentic</th>
                        <th>Call Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportedJobList.length > 0 ? (
                        <>
                          {reportedJobList.map((item, index) => {
                            return (
                              <ReportedList
                                dataList={item}
                                handleChange={this.handleChange}
                                saveReport={this.updateReport}
                                saveReportId={saveReportId}
                                successMessage={successMessage}
                                closeRecruiterJobs={this.closeRecruiterJobs}
                                key={`row-${index}`}
                                isAllowEdit={isAllowEdit}
                                showCTA={
                                  tabType === "Assigned" &&
                                  !isRecruiter &&
                                  !recIds
                                }
                                clusterList={clusterList}
                                isCPSection={isCPSection}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={7} align="center">
                            <div className="spinner">
                              {isJobSeeker ? (
                                <>
                                  No pending reports present now. <br />
                                  Please refresh or{" "}
                                  <a href={REPORTED_JOBS}>go back</a> to
                                  previous page for latest reports.
                                </>
                              ) : (
                                "No Reported Jobs Assigned"
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <CustomePagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={count}
                  onPageChange={this.handlePageChange}
                  showPagination={reportedJobList.length > 0 ? true : false}
                />
                <div className="clr gap-20" />
              </>
            ) : (
              <>
                <div className="clr gap-20" /> <NoAccess />
              </>
            )}
          </div>
        </div>
      );
    }
  }
}

export default withRouter(ReportedJobs);
