import React, { useState, useEffect } from "react";
import { getValueFromURL } from "../../services/utils";
import PopupMessage from "../PopupMessage";
import { checkPermission } from "../../services/utils";
import { ENTERPRISE_LEADS } from "../../routes/RouteConstants";
import GoBack from "../Common/GoBack";
import NoAccess from "../Common/NoAccess";
import UpdateLeadsFormV2 from "./UpdateLeadsFormV2";
import ViewLcsRemarks from "./ViewLcsRemarks";

const UpdateLeadsContainer = ({ leadId }) => {
  const [msgData, setMsgData] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });
  const [isSticky, setIsSticky] = useState(false);

  const handleClosePopup = () => {
    setMsgData({ msg: "", showMsg: false });
  };

  const statusMessage = (msg, msgType) => {
    setMsgData({
      msg: msg,
      showMsg: true,
      msgType: !msgType ? "success" : "error"
    });
    const timeoutId = setTimeout(() => {
      handleClosePopup();
    }, 5000);
    window.scrollTo(0, 0);
    return () => clearTimeout(timeoutId);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sticky = window.scrollY >= 121;
      setIsSticky(sticky);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isAccess = checkPermission("Enterprise Leads");
  const clientId = getValueFromURL("client");
  const view = getValueFromURL("view") || "moderator";
  return (
    <>
      {isAccess ? (
        <div className="form-container">
          <PopupMessage data={msgData} click={handleClosePopup} />
          {!isSticky && (
            <GoBack
              href={`${ENTERPRISE_LEADS}/${view}-view?client=${clientId}`}
              lable={"Go back to main list"}
            />
          )}
          <div className="topContainerLead" />
          <UpdateLeadsFormV2
            leadId={leadId}
            isSticky={isSticky}
            statusMessage={statusMessage}
            clientId={clientId}
          />
          <ViewLcsRemarks leadId={leadId} />
        </div>
      ) : (
        <>
          <div className="clr gap-20" />
          <NoAccess />
        </>
      )}
    </>
  );
};

export default UpdateLeadsContainer;
