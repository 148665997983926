import React, { useRef } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { useController } from "react-hook-form";
import LcsFormErrorHook from "../../customHooks/LcsFormErrorHook";

const MaterialDropdown = ({
  name,
  label,
  control,
  rules,
  error,
  helperText,
  fullWidth = true,
  options,
  formControlClasses,
  defaultValue,
  validate,
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules: {
      ...rules,
      validate
    },
    defaultValue
  });
  const inputRef = useRef(null);
  LcsFormErrorHook({
    fieldState,
    inputRef
  });

  return (
    <FormControl
      fullWidth={fullWidth}
      error={!!fieldState.error || !!error}
      classes={formControlClasses}
      innerRef={inputRef}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        {...field}
        labelId={`${name}-label`}
        id={name}
        MenuProps={{ variant: "menu" }}
        {...rest}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {(fieldState.error || error) && (
        <FormHelperText>
          {fieldState.error ? fieldState.error.message : error}
        </FormHelperText>
      )}
      {helperText && !fieldState.error && !error && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default MaterialDropdown;
