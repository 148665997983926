import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  logout,
  redirectURL,
  isJobEditPage,
  isLFMSPage
} from "./services/utils";
import PrivateRoute from "./components/PrivateRoute";
import Page404 from "./components/Page404";
import NavBar from "./components/NavBar";
import Layout from "./Container";
import {
  LOGIN,
  ENTERPRISE_LEADS,
  EDIT_AUTO_JOB_POSTING
} from "./routes/RouteConstants";
import UpdateLeads from "./components/EnterpriseLeads/UpdatedLeads";
import LoginRoutes from "./components/LoginRoutes";
import PublickRoutes from "./components/PublickRoutes";
import LfmsLayout from "./componentsV2/local/layout";
import useCityDataStore from "./zustandService/useCityDataStore";
import useMetadataStore from "./zustandService/useMetadataStore";
import { clearStoreIndexedDb } from "./lib/indexedDB";

const App = () => {
  const [isHidden, setHidden] = useState(true);
  let userJsonString = JSON.parse(localStorage.getItem("userData")) || {};
  let token = localStorage.getItem("token");
  const [currentUser, setCurrentUser] = useState(userJsonString?.name || "");
  const { fetchCityData } = useCityDataStore(state => ({
    fetchCityData: state.fetchCityData
  }));
  const { fetchMetaData } = useMetadataStore(state => ({
    fetchMetaData: state.fetchMetaData
  }));

  useEffect(() => {
    if (token) {
      fetchCityData();
      fetchMetaData();
    }
  }, [token]);

  function toggleHidden() {
    setHidden(!isHidden);
  }

  function handleLogout() {
    logout();
    setCurrentUser("");
    handleClearData()
      .then(() => {
        redirectURL(LOGIN);
      })
      .catch(error => {
        console.error("Failed to clear data during logout", error);
        // Handle error (optional)
        redirectURL(LOGIN);
      });
  }

  const handleClearData = async () => {
    try {
      await clearStoreIndexedDb();
    } catch (error) {
      console.error("Failed to clear data", error);
    }
  };

  function renderLogout() {
    return (
      <>
        <span onClick={toggleHidden} className="humburger">
          <i className="fas fa-bars fa-lg"></i>
        </span>
        {currentUser && (
          <span onClick={handleLogout} className="btn-logout" title="Logout">
            <i className="fas fa-power-off"></i>
          </span>
        )}
      </>
    );
  }

  const pathName = window.location.pathname;
  const isEditPage = isJobEditPage(pathName);
  const isLFMS = isLFMSPage(pathName);
  if (isLFMS && currentUser) {
    return (
      <Router>
        <LfmsLayout>
          <LoginRoutes isLogin={currentUser} />
        </LfmsLayout>
      </Router>
    );
  }
  return (
    <Router>
      <div className="wrapper">
        {!isHidden && !isEditPage ? (
          <div className="leftMenu">
            <div className="leftStickyContainer">
              <div className="leftMenuHeader">
                <div className="menuSlide">{renderLogout()}</div>
                <span className="sidebar-toggle d-flex mr-2">
                  <i className="hamburger align-self-center"></i>
                </span>
                <div className="logo">
                  <img
                    src={process.env.PUBLIC_URL + "/images/logo.jpg"}
                    alt="Job Hai"
                  />
                </div>
                {currentUser && (
                  <div className="userName">
                    <p className="name">{currentUser}</p>
                  </div>
                )}
              </div>
              <div className="tagLine">JobHai - CRM Cum Job Edit Portal</div>
              {currentUser && <NavBar />}
            </div>
          </div>
        ) : null}

        {isEditPage ? (
          <Routes>
            <Route
              exact
              path="/:type/:page/:role/:id"
              element={
                <PrivateRoute>
                  <Layout />
                </PrivateRoute>
              }
            />
            <Route
              path={`${ENTERPRISE_LEADS}/update/:id`}
              exact
              element={
                <PrivateRoute>
                  <UpdateLeads />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={`${EDIT_AUTO_JOB_POSTING}/:id`}
              element={
                <PrivateRoute>
                  <Layout isAutoJobPost={true} />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Page404 />} />
          </Routes>
        ) : (
          <div className="rightWrap">
            {isHidden && <div className="menuSlideOpen">{renderLogout()}</div>}
            <PublickRoutes isLogin={currentUser} />
            <LoginRoutes isLogin={currentUser} />
          </div>
        )}
      </div>
    </Router>
  );
};

export default App;
