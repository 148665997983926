import React from "react";
import moment from "moment";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import IndustryTagging from "./Tagging";
import {
  isEditedFields,
  getFormattedValue,
  findChipValue,
  findBooleanState
} from "../../services/utils";
import ComanyName from "../Common/ComanyName";
import Chips from "../Common/Chips";
import MetaData from "../../constants/MetaData";

const CompanyDetails = props => {
  const {
    editedFields = [],
    companyData,
    statusMessage,
    isSaved,
    recId,
    handleIndustryClick,
    getFieldData,
    reviewedCompany,
    isMarkedRviewedClick,
    isOrgReviewed
  } = props;
  const {
    company_address = "",
    company_name = "",
    company_website = "",
    city_id = "",
    locality_id = "",
    is_branded_company = "",
    recent_comp_data = {},
    reviewDetails = {}
  } = companyData;
  const recentCompanyData = recent_comp_data || {};
  const handleChange = event => {
    let { value, name } = event.target;
    if (name === "company_name")
      value = getFormattedValue(value, false, false, false);
    props.getFieldData(name, value);
  };

  const handleBooleanChips = (event, fieldName, item) => {
    const { label } = item;
    props.getFieldData(
      fieldName,
      findChipValue(is_branded_company) ? null : findBooleanState(label)
    );
  };
  const activeBrandedCompany = {
    id: findChipValue(is_branded_company),
    label: findChipValue(is_branded_company)
  };
  const isCompanyEdited =
    isEditedFields(editedFields, "company_name") ||
    isEditedFields(editedFields, "company_website") ||
    isEditedFields(editedFields, "company_website") ||
    isEditedFields(editedFields, "company_address") ||
    isEditedFields(editedFields, "city_id") ||
    isEditedFields(editedFields, "locality_id");

  return (
    <>
      <Heading heading="Company Details" isEdited={isCompanyEdited}>
        {isOrgReviewed ? (
          <>
            <div class="successButton reviewed">
              <img src="/images/verified.svg" alt="saved" />
              Reviewed
            </div>
            {reviewDetails?.moderator_name && (
              <div className="reviewedBy">
                Reviewed By: <span>{reviewDetails?.moderator_name}</span>
                {reviewDetails?.timeStamp && (
                  <>
                    On{" "}
                    <span>
                      {moment(reviewDetails.timeStamp).format(
                        "YYYY-MM-DD hh:mm A"
                      )}
                    </span>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <button
            className="markReviewed"
            onClick={reviewedCompany}
            disabled={isMarkedRviewedClick}
          >
            Mark Reviewed
          </button>
        )}
      </Heading>
      <div className="formRow">
        <div className="left">
          <Label
            lable="Company Name"
            isEdited={isEditedFields(editedFields, "company_name")}
          />
          <ComanyName
            onChange={getFieldData}
            placeholder="Company Name"
            companyName={company_name}
            filedName="company_name"
          />
          <CustomTextField
            name="company_name"
            value={company_name}
            onChange={handleChange}
          />
          {recentCompanyData?.company_name ? (
            <div className="recDetails">
              Last Company Name: <span>{recentCompanyData?.company_name}</span>
            </div>
          ) : null}
        </div>
        <div className="right">
          <Label
            lable="Company Website"
            isOptional={true}
            isEdited={isEditedFields(editedFields, "company_website")}
          />
          <CustomTextField
            name="company_website"
            value={company_website}
            onChange={handleChange}
          />
          {recentCompanyData?.company_website ? (
            <div className="recDetails">
              Last Company Website:{" "}
              <span>{recentCompanyData?.company_website}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="formRow">
        <div className="fullWidth">
          <Label lable="Is branded company" />
          <Chips
            chips={MetaData.booleanOptions}
            activeChips={activeBrandedCompany}
            onClick={handleBooleanChips}
            isClose={true}
            fieldName="is_branded_company"
            isMulti={false}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="fullWidth">
          <Label
            lable="Address"
            isEdited={isEditedFields(editedFields, "company_address")}
          />
          <CustomTextField
            name="company_address"
            value={company_address}
            onChange={handleChange}
          />
          {recentCompanyData?.company_address ? (
            <div className="recDetails">
              Last Company Address:{" "}
              <span>{recentCompanyData?.company_address}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="formRow">
        <div className="left">
          <Label
            lable="City"
            isEdited={isEditedFields(editedFields, "city_id")}
          />
          <div className="selectBox">
            <SelectCity
              filedName={"city_id"}
              value={city_id}
              onChange={handleChange}
              customClass="select fullWidth"
            />
          </div>
        </div>
        <div className="right">
          <Label
            lable="Locality"
            isEdited={isEditedFields(editedFields, "locality_id")}
          />
          <SelectLocality
            filedName={"locality_id"}
            value={locality_id}
            cityId={city_id}
            onChange={handleChange}
          />
        </div>
      </div>
      <IndustryTagging
        statusMessage={statusMessage}
        recId={recId}
        isSaved={isSaved}
        handleIndustryClick={handleIndustryClick}
      />
    </>
  );
};

export default CompanyDetails;
