import moment from "moment";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ReactDatePicker from "react-datepicker";
import { getEditPageURL, getFormattedValue } from "../../services/utils";
import {
  RECUITERS_APPLICANT,
  TRANSACTION_HISTORY
} from "../../routes/RouteConstants";

const columns = (view, pageSearchData, data) => {
  return [
    {
      text: "Rec ID",
      dataField: "recruiterId",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <a
            href={getEditPageURL(false, data.jobId, row.recruiterId)}
            target="_blank"
            rel="noreferrer"
            className=""
          >
            {row.recruiterId}
          </a>
        );
      }
    },
    {
      text: "Name",
      dataField: "name",
      sort: true
    },
    {
      text: "Email Id",
      dataField: "email",
      sort: true
    },
    {
      text: "Company Name",
      dataField: "companyName"
    },
    {
      text: "Phone No",
      dataField: "phone"
    },
    {
      text: "Job Posted",
      dataField: "totalJobsPosted",
      formatter: (cellContent, row) => {
        return (
          <a
            href={`${RECUITERS_APPLICANT}/${row.recruiterId}`}
            target="_blank"
            rel="noreferrer"
            className=""
          >
            {row?.totalJobsPosted}
          </a>
        );
      }
    },
    {
      text: "Live Basic Job",
      dataField: "liveBasicJobs"
    },
    {
      text: "Live Premium Job",
      dataField: "livePremiumJobs",
      sort: true
    },
    {
      text: "Rec Bucket",
      dataField: "bucket"
    },
    {
      text: "Total Credits",
      dataField: "totalAvailableCredits",
      formatter: (cellContent, row) => {
        const recId = pageSearchData.recId;
        const recPhone = pageSearchData.recPhone;
        return view !== "creditReserved" ? (
          row?.holdCredits + row.totalAvailableCredits
        ) : (
          <a
            href={`${TRANSACTION_HISTORY}?${(() => {
              if (recId) return `recruiterId=${recId}`;
              if (recPhone) return `recruiterPhone=${recPhone}`;
            })()}`}
            target="_blank"
            rel="noreferrer"
            className=""
          >
            {row?.holdCredits + row.totalAvailableCredits}
          </a>
        );
      }
    },
    {
      text: "Reserved Credits",
      dataField: "reserved_credits",
      formatter: (cellContent, row) => {
        const recId = pageSearchData.recId;
        const recPhone = pageSearchData.recPhone;
        return view === "creditReserved" ? (
          row.holdCredits
        ) : (
          <a
            href={`${TRANSACTION_HISTORY}?view=creditReserved${(() => {
              if (recId) return `&recruiterId=${recId}`;
              if (recPhone) return `&recruiterPhone=${recPhone}`;
            })()}`}
            target="_blank"
            rel="noreferrer"
            className=""
          >
            {row?.holdCredits}
          </a>
        );
      }
    },
    {
      text: "Available Credits",
      dataField: "totalAvailableCredits"
    }
  ];
};
const STATUS = view => {
  if (view === "creditReserved")
    return [
      { value: "PENDING", label: "Pending" },
      { value: "VERIFIED", label: "Verified" },
      { value: "REJECTED", label: "Rejected" }
    ];
  return [
    { value: "ALL_TRANSACTIONS", label: "Transaction Type (All)" },
    // { value: "CREDITS_ADDED", label: "Credit Added" },
    { value: "PAID_CREDITS_ADDED", label: "Paid Credit Addition" },
    { value: "ECOMMERCE_CREDITS_ADDED", label: "Ecommerce Addition" },
    { value: "CREDITS_FAILED", label: "Failed Transactions" },
    { value: "CREDITS_PENDING", label: "Pending Transactions" },
    { value: "CREDITS_CONSUMED", label: "Credits Consumed" },
    { value: "CREDITS_EXPIRED", label: "Credits Expired" },
    { value: "CREDITS_TRANSFERRED", label: "Credits Transferred" },
    // { value: "CREDITS_ON_HOLD", label: "Credits on Hold" },
    { value: "CREDITS_DEACTIVATED", label: "Service Deactivated" }
  ];
};

function TransactionHistoryRecruiterDetails({
  recruiterData,
  filterSearch,
  view,
  pageSearchData
}) {
  const [searchData, setSearchData] = useState({ status: "PENDING" });
  const onChange = e => {
    let value = e.target.value;
    let name = e.target.name;
    if (
      name === "recruiterId" ||
      name === "orderId" ||
      name === "sentTo" ||
      name === "receivedFrom" ||
      name === "jobId"
    ) {
      value = getFormattedValue(value, true, true);
    }
    setSearchData({ ...searchData, [name]: value });
  };
  const onSearch = e => {
    e.preventDefault();
    const data = JSON.parse(JSON.stringify(searchData));
    if (!data) return;
    if (
      !data.fromDate &&
      !data.toDate &&
      !data.status &&
      !data.jobId &&
      !data.orderId
    ) {
      alert("Please select filter");
      return;
    }

    if (data.fromDate)
      data.fromDate = moment(data.fromDate).format("YYYY-MM-DD");
    if (data.toDate) data.toDate = moment(data.toDate).format("YYYY-MM-DD");
    if (data.toDate && !data.fromDate) {
      alert("Please select fromDate");
      return;
    }
    filterSearch(data);
  };
  const onReservedCreditSearched = e => {
    e.preventDefault();
    const data = JSON.parse(JSON.stringify(searchData));
    if (!data) return;
    const validStatus = ["", "PENDING", "VERIFIED", "REJECTED"];
    if (
      !data.fromDate &&
      !data.toDate &&
      !validStatus.includes(data.status) &&
      !data.jobId
    ) {
      alert("Please select filter1");
      return;
    }

    if (data.fromDate)
      data.fromDate = moment(data.fromDate).format("YYYY-MM-DD");
    if (data.toDate) data.toDate = moment(data.toDate).format("YYYY-MM-DD");
    if (data.toDate && !data.fromDate) {
      alert("Please select fromDate");
      return;
    }
    // recruiterReservedCreditsData(data);
    filterSearch(data);
  };
  const recData = recruiterData?.data;
  const creditReservedView = view === "creditReserved";
  if (recruiterData.state === "FETCHED")
    return (
      <div>
        <div className={"whitebox"} style={{ minHeight: "120px" }}>
          <BootstrapTable
            keyField="moderator_id"
            data={[recruiterData.data]}
            columns={columns(view, pageSearchData, {
              jobId: recruiterData.data.jobId
            })}
            hover
            condensed
            bordered={false}
            loading={true}
            rowClasses="listvalue"
            headerClasses="listHeding"
            classes="borderless"
            noDataIndication={() => <div>No data</div>}
          />

          <div className="clr gap-20" />
          <div className="row">
            <div className="col-md-12">
              {recData.creditsGettingExpired ? (
                <div className="d-flex justify-content-end">
                  <div className="expiringCredits">
                    <img src="/images/danger.svg" alt="danger" />
                    {recData.creditsGettingExpired} Credits expiring on{" "}
                    {moment(recData.creditsDateOfExpiry).format("DD-MM-YYYY")}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="clr gap-20" />
        <div className="clr gap-20" />
        <div className="d-flex">
          <div className="col-md-2">
            <b className="downArrow"></b>

            <select
              className="form-control"
              name="status"
              onChange={onChange}
              value={searchData.status}
            >
              {view === "creditReserved" ? (
                <option value="">Status (All)</option>
              ) : null}

              {STATUS(view).map((option, index) => (
                <option value={option.value} key={"lb" + index}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <input
              className="form-control"
              type="text"
              name="jobId"
              placeholder="Job Id"
              onChange={onChange}
              value={searchData.jobId}
            />
          </div>
          {!creditReservedView && (
            <div className="col-md-2">
              <input
                className="form-control"
                type="text"
                name="orderId"
                onChange={onChange}
                value={searchData.orderId}
                placeholder="Order Id"
              />
            </div>
          )}
          <div className="col-md-2" style={{ position: "relative" }}>
            <ReactDatePicker
              dateFormat="yyyy-MM-dd"
              className="form-control"
              maxDate={new Date()}
              placeholderText="Date From"
              onChange={val => {
                onChange({ target: { name: "fromDate", value: val } });
              }}
              selected={searchData.fromDate}
            />
          </div>
          <div className="col-md-2">
            <ReactDatePicker
              dateFormat="yyyy-MM-dd"
              className="form-control"
              maxDate={new Date()}
              placeholderText="Date To"
              onChange={val => {
                onChange({ target: { name: "toDate", value: val } });
              }}
              selected={searchData.toDate}
            />
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-primary"
              onClick={
                !creditReservedView ? onSearch : onReservedCreditSearched
              }
            >
              SEARCH
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3"></div>
      </div>
    );
  if (recruiterData.state === "LOADING") return <div>Loading</div>;
  if (recruiterData.state === "FAILED")
    return <div>{recruiterData?.errorMsg || "ERROR"}</div>;
}

export default TransactionHistoryRecruiterDetails;
