import { Button, CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useLcsCampaignDetails from "../../../zustandService/useLcsCampaignDetails";
import SelectDropdown from "../../ReactSelect";
import classes from "./leadClassificationTab.module.css";
import styles from "../lcsCampaigiRoot.module.css";
import { allowedLeadTypesOptions } from "./constants";
import useLcsCampaignEditPermission from "../../../zustandService/useLcsCampaignEditPermission";

const AllowedLeadTypesForm = props => {
  const {
    callSaveAllowedLeadTypes,
    allowedLeadTypes,
    fetchState: { saveAllowedLeadTypes: saveAllowedLeadTypesFetchState },
    error: { saveAllowedLeadTypes: saveAllowedLeadTypesErrorMssg }
  } = useLcsCampaignDetails(state => ({
    callSaveAllowedLeadTypes: state.callSaveAllowedLeadTypes,
    fetchState: state.fetchState,
    error: state.error,
    allowedLeadTypes: state.allowedLeadTypes
  }));

  const { canEditCampaign } = useLcsCampaignEditPermission(state => ({
    canEditCampaign: state.canEditCampaign
  }));

  const { allowed_lead_type: selectedAllowedLeadTypes } = allowedLeadTypes;

  const method = useForm({
    defaultValues: {
      allowed_lead_type: allowedLeadTypesOptions.filter(item =>
        selectedAllowedLeadTypes.includes(item.value)
      )
    }
  });
  const { id: campaignId } = useParams();
  const { control, handleSubmit } = method;

  const onSubmit = data => {
    const { allowed_lead_type: selectedValues } = data;

    const formattedSelectedValues = selectedValues.map(item => item.value);
    const payload = {
      campaign_id: campaignId,
      allowed_lead_type: formattedSelectedValues
    };
    callSaveAllowedLeadTypes(payload);
  };
  return (
    <form className="mb-28" onSubmit={handleSubmit(onSubmit)}>
      <SelectDropdown
        name={`allowed_lead_type`}
        label="Allowed lead types"
        control={control}
        options={allowedLeadTypesOptions}
        customClass={classes.allowedLeadtypeField}
        fullWidth={false}
        idKey="value"
        labelKey="label"
        closeMenuOnSelect={false}
        isMulti
        isDisabled={!canEditCampaign}
      />
      <div className="flex justify-end items-center my-3">
        {saveAllowedLeadTypesErrorMssg ? (
          <div className="text-danger mx-3">
            {saveAllowedLeadTypesErrorMssg}
          </div>
        ) : null}
        <Button
          disabled={
            saveAllowedLeadTypesFetchState === "LOADING" || !canEditCampaign
          }
          variant="contained"
          color="primary"
          type="submit"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

const AllowedLeadTypes = props => {
  const {
    fetchAllowedLeadTypes,
    fetchState: { allowedLeadTypes: allowedLeadTypesFetchState },
    error: { allowedLeadTypes: allowedLeadTypesErrorMssg }
  } = useLcsCampaignDetails(state => ({
    fetchAllowedLeadTypes: state.fetchAllowedLeadTypes,
    fetchState: state.fetchState,
    error: state.error
  }));
  const { id } = useParams();
  useEffect(() => {
    fetchAllowedLeadTypes(id);
  }, []);

  if (allowedLeadTypesFetchState === "LOADING")
    return (
      <div className={styles.loaderCnt}>
        <CircularProgress
          classes={{ root: styles.loaderStyles }}
          color="inherit"
        />
      </div>
    );

  if (allowedLeadTypesFetchState === "ERROR")
    return <div className="text-danger">{allowedLeadTypesErrorMssg}</div>;

  return (
    <>
      <div className="clr gap-20"></div>
      <h2 className="heading">Add allowed lead types</h2>
      <AllowedLeadTypesForm />
    </>
  );
};
export default AllowedLeadTypes;
