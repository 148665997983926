import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useLmsRedirectionStore = create(
  persist(
    (set, get) => ({
      redirectionData: 0,
      setRedirectionData: data => set({ redirectionData: data })
    }),
    {
      name: "lms-redirection-data", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage) // (optional) by default, 'localStorage' is used
    }
  )
);

export default useLmsRedirectionStore;
