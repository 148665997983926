import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import CountdownContainer from "../../Common/CountDown";
import { getEditPageURL } from "../../../services/utils";
import PremiumTag from "../../Common/PremiumTag";
import { RECUITERS_APPLICANT } from "../../../routes/RouteConstants";

function ActionLink({ url, label, jobPlan }) {
  return (
    <div className="edit_link">
      <a
        href={url}
        className="primary"
        target="_blank"
        rel="noopener noreferrer"
      >
        {label}
        {jobPlan && <PremiumTag jobPlan={jobPlan} />}
      </a>
    </div>
  );
}

function RecruiterList(props) {
  const { recruitersList = {}, isSearching = false, role } = props;
  recruitersList.map(d => {
    const {
      job_id,
      pending_jobs,
      list_inflow_date,
      deadline_ts,
      crm_deadline,
      recruiter_id,
      document_status,
      verification_status,
      jobPlan
    } = d;
    const editUrl = getEditPageURL(
      verification_status,
      job_id,
      recruiter_id,
      role
    );
    d.pendingJobs = (
      <>
        <ActionLink
          key={d}
          url={`${RECUITERS_APPLICANT}/${recruiter_id}`}
          label={pending_jobs}
          jobPlan={jobPlan}
        />
      </>
    );
    d.recURL = (
      <>
        <ActionLink
          key={d}
          url={`${editUrl}&returnURL=unverified`}
          label={recruiter_id}
        />
      </>
    );
    d.docStatus =
      document_status === "under_review"
        ? "New"
        : document_status === "verified"
        ? "Approved"
        : document_status;
    d.verificationStatus =
      verification_status === "NEW" ? "Under Review" : verification_status;
    d.tatTimer = (
      <CountdownContainer
        date={moment(list_inflow_date).format("DD-MMM-YYYY")}
        deadline={crm_deadline ? crm_deadline : deadline_ts}
        deadTimeStamp={deadline_ts}
        isFeaze={false}
      />
    );
    return d;
  });

  const columns = [
    {
      text: "Rec Id",
      dataField: "recURL",
      sort: true
    },
    {
      text: "Priority",
      dataField: "onboarding_priority",
      sort: true
    },
    {
      text: "Company Name",
      dataField: "company_name",
      sort: true
    },
    {
      text: "Rec Name",
      dataField: "recruiter_name",
      sort: true
    },
    {
      text: "City",
      dataField: "city_name",
      sort: true
    },
    {
      text: "Pending Jobs",
      dataField: "pendingJobs"
    },
    {
      text: "Phone no",
      dataField: "phone"
    },
    {
      text: "Jobs List Inflow Date",
      dataField: "tatTimer"
    },
    {
      text: "Email Status",
      dataField: "email_status"
    },
    {
      text: "Doc Status",
      dataField: "docStatus"
    },
    {
      text: "Rec Status",
      dataField: "verificationStatus"
    }
  ];

  const NoDataIndication = () => (
    <div className="spinner">
      {isSearching ? "Searching..." : "No Data Found"}
    </div>
  );
  return (
    <div className="whitebox unverified-recruiter">
      <BootstrapTable
        keyField="recruiter_id"
        data={recruitersList}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
}

export default RecruiterList;
