export const bucketTabList = [
  {
    title: "Hot",
    id: "hot"
  },
  {
    title: "Warm",
    id: "warm"
  },
  {
    title: "Cold",
    id: "cold"
  }
];

export const allowedLeadTypesOptions = [
  { value: "hot", label: "Hot" },
  { value: "cold", label: "Cold" },
  { value: "warm", label: "Warm" }
];
