// import { Button } from '@/components/ui/button';
// import { Badge } from '@/components/ui/badge';
// import { Input } from '@/components/ui/input';

import { Badge } from "../../ui/badge";
import { Button } from "../../ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "../../ui/card";
import { User } from "lucide-react";
import { Input } from "../../ui/input";
import { Switch } from "../../ui/switch";
import useLeadRecruiterDetailStore from "../../../zustandService/useLeadRecruiterDetailStore";
import StateRenderer from "./stateRenderer";
import { useEffect, useState } from "react";
import moment from "moment";
import { ToggleGroup, ToggleGroupItem } from "../../ui/toggle-group";
// import { Switch } from '@/components/ui/switch';
// const recruiterDetails = {
//   id: 'REC001',
//   name: 'John Doe',
//   phone: '9810938361',
//   status: 'Verified',
//   type: 'Company',
//   email: 'john.doe@example.com',
//   registeredOn: '2023-04-15',
//   decisionMaker: false,
//   decisionMakerName: 'Jane Smith',
//   decisionMakerNumber: '+1 (555) 987-6543',
//   totalJobsPosted: 150,
//   paidJobsMadeLive: 120,
//   totalLiveJobs: 80,
//   categories: ['Tele Calling', 'Field Sales'],
//   cities: ['Delhi', 'Mumbai'],
//   avgJobsPerMonth: 12,
//   avgResponseOnJobs: 75,
// };

let RecruiterDetailsUI = () => {
  const [loading, setLoading] = useState(false);
  const { recruiterDetailsState, recDetails, setDecisionMaker } =
    useLeadRecruiterDetailStore(state => ({
      recruiterDetailsState: state.recruiterDetailsState,
      recDetails: state.recruiterDetails,
      setDecisionMaker: state.setDecisionMaker
    }));
  const isRecruiterDecisionMaker = recDetails?.isRecruiterDecisionMaker;
  const [decisionMakerLocal, setShowDecisionMakerLocal] = useState({
    isRecruiterDecisionMaker,
    decisionMakerName: recDetails?.decisionMakerName,
    decisionMakerNumber: recDetails?.decisionMakerNumber
  });

  useEffect(() => {
    setShowDecisionMakerLocal({
      isRecruiterDecisionMaker,
      decisionMakerName: recDetails?.decisionMakerName,
      decisionMakerNumber: recDetails?.decisionMakerNumber
    });
  }, [isRecruiterDecisionMaker]);
  const searchParams = window.location.search || "";
  const queryParams = new URLSearchParams(searchParams);
  const recId = queryParams.get("recruiterId");
  // const bucketId = queryParams.get("bucketId");
  // const leadId = queryParams.get("leadId");
  const nameRegex = /^[A-Za-z0-9]+(?:\s[A-Za-z0-9]+)*$/;
  async function onSaveDetails() {
    if (decisionMakerLocal.isRecruiterDecisionMaker ===false) {
      if (
        !decisionMakerLocal.decisionMakerName ||
        !nameRegex.test(decisionMakerLocal.decisionMakerName)
      ) {
        alert("Please enter a decisionMakerName");
        return;
      }

      if (
        !decisionMakerLocal.decisionMakerNumber ||
        decisionMakerLocal.decisionMakerNumber.length !== 10
      ) {
        alert("Please enter valid decisionMakerNumber");
        return;
      }
    }
    else{
      setShowDecisionMakerLocal({...decisionMakerLocal,decisionMakerName:"",decisionMakerNumber:""})
    }
    try {
      setLoading(true);
      await setDecisionMaker({ ...decisionMakerLocal, recruiterId: recId });
      alert("Saved Successfully");
      setLoading(false);
    } catch (e) {
      alert("Something went wrong");
      setLoading(false);
    }
  }
  function onChangeDetails(e) {
    const name = e.target.name;
    const val = e.target.value;

    if (name === "decisionMakerNumber") {
      const digitsOnly = val.replace(/\D/g, "");

      const isValid = digitsOnly === "" || /^[5-9][0-9]{0,9}$/.test(digitsOnly);

      if (isValid) {
        setShowDecisionMakerLocal({
          ...decisionMakerLocal,
          [name]: digitsOnly
        });
      }
    } else if (name === "decisionMakerName") {
      setShowDecisionMakerLocal({ ...decisionMakerLocal, [name]: val });
    } else {
      setShowDecisionMakerLocal({ ...decisionMakerLocal, [name]: val });
    }
  }
  return (
    <StateRenderer state={recruiterDetailsState}>
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between space-x-3">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center text-blue-500 rounded-md size-10 bg-blue-50">
                <User />
              </div>
              <CardTitle className="text-xl">Recruiter Details</CardTitle>
            </div>
            <Badge className={"bg-green-500 hover:bg-green-500"}>
              {recDetails?.recruiterStatus}
            </Badge>
          </div>
        </CardHeader>

        <CardContent>
          <div className="grid grid-cols-1 gap-2 pb-6 border-b md:grid-cols-4 md:gap-4">
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Recruiter ID</p>
              <div className="text-medium">{recDetails?.recruiterId}</div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Recruiter Name</p>
              <div className=" text-medium">{recDetails?.recruiterName}</div>
            </div>

            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Type</p>
              <div className="text-medium">{recDetails?.recruiterType}</div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Registered On</p>
              <div className="text-medium">
                {moment(recDetails?.registeredOn).format("DD-MM-YYYY")}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-2 mt-6 md:grid-cols-4 md:gap-4">
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Total Jobs Posted</p>
              <div className="font-medium ">{recDetails?.jobPostCount}</div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Paid Jobs Made Live</p>
              <div className="font-medium ">{recDetails?.paidLiveJobs}</div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Total Live Jobs</p>
              <div className="font-medium ">{recDetails?.totalLiveJobs}</div>
            </div>

            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Total Applications</p>
              <div className="font-medium ">
                {recDetails?.totalResponses || "-"}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Total Database Matches</p>
              <div className="font-medium ">
                {recDetails?.totalRecommendations || "-"}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-2 pb-6 my-12 border-b md:grid-cols-4 md:gap-4">
            <div className="grid gap-2 md:col-span-2">
              <p className="text-sm text-gray-500">Categories</p>
              <div className="flex flex-wrap gap-2">
                {recDetails?.categories?.map((category, index) => (
                  <div
                    key={index}
                    className="px-2 py-1 text-sm bg-gray-100 rounded-md dark:bg-gray-800"
                  >
                    {category?.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="grid gap-2 md:col-span-2">
              <p className="text-sm text-gray-500">Cities Posted</p>
              <div className="flex flex-wrap gap-1">
                {recDetails?.cities?.map((city, index) => (
                  <div
                    key={index}
                    className="px-2 py-1 text-sm bg-gray-100 rounded-md dark:bg-gray-800"
                  >
                    {city?.name}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 mt-12 md:grid-cols-2 gap-y-6">
            <div className="grid col-span-1 gap-2 ">
              <p className="text-sm text-gray-500">Phone Number</p>
              <div className="text-medium">{recDetails?.phoneNo}</div>
            </div>

            <div className="grid col-span-1 gap-2">
              <p className="text-sm font-medium text-gray-500">Email ID</p>
              <div className="text-medium">{recDetails?.emailId}</div>
            </div>

            <div className="flex items-center col-span-2 space-x-2">
              <span className="text-sm font-medium text-muted-foreground data-[state=unchecked]:text-primary">
                {" "}
                Decision Maker
              </span>
              <ToggleGroup
                defaultValue={
                  recDetails?.isRecruiterDecisionMaker === true
                    ? "true"
                    : recDetails?.isRecruiterDecisionMaker === false
                    ? "false"
                    : null
                }
                onValueChange={data => {
                  if (data == "false") {
                    setShowDecisionMakerLocal({
                      ...decisionMakerLocal,
                      isRecruiterDecisionMaker: false
                    });
                  } else {
                    setShowDecisionMakerLocal({
                      ...decisionMakerLocal,
                      isRecruiterDecisionMaker: true
                    });
                  }
                }}
                type="single"
              >
                <ToggleGroupItem
                  className="border border-gray-300 rounded px-4 py-2"
                  value="true"
                >
                  Yes
                </ToggleGroupItem>
                <ToggleGroupItem
                  className="border border-gray-300 rounded px-4 py-2"
                  value="false"
                >
                  No
                </ToggleGroupItem>
              </ToggleGroup>
              {/* <div>No</div> */}
              {/* <Switch
                onCheckedChange={data => {
                  if (data === false) {
                    setShowDecisionMakerLocal({
                      ...decisionMakerLocal,
                      decisionMakerPresent: false
                    });
                  } else {
                    setShowDecisionMakerLocal({
                      ...decisionMakerLocal,
                      decisionMakerPresent: true
                    });
                  }
                }}
                id="yes-no-toggle"
                aria-label="Yes or No"
                defaultChecked={decisionMakerPresent}
              >
              
              </Switch> */}
              {/* <span>Yes</span> */}
            </div>
            {decisionMakerLocal.isRecruiterDecisionMaker===false && 
            <>
              <div className="grid col-span-1 gap-2">
                <p className="text-sm font-medium text-gray-500">
                  Decision Maker Name
                </p>
                <div className="">
                  <Input
                    id="decisionmaker"
                    name="decisionMakerName"
                    type="text"
                    placeholder="Add decision maker name"
                    className=""
                    defaultValue={
                       recDetails?.decisionMakerName ||""
                    }
                    value={decisionMakerLocal.decisionMakerName}
                    onChange={onChangeDetails}
                  />
                </div>
              </div>
            

              <div className="grid col-span-1 gap-2">
                <p className="text-sm font-medium text-gray-500">
                  Decision Maker Number
                </p>
                <div className="">
                  <Input
                    name="decisionMakerNumber"
                    id="decisionmaker"
                    type="number"
                    placeholder="Add decision maker phone number"
                    className=""
                    value={decisionMakerLocal.decisionMakerNumber}
                    defaultValue={
                      
                         recDetails?.decisionMakerNumber||""
                        
                    }
                    onChange={onChangeDetails}
                  />
                </div>
              </div>
              </>
            }
            <Button
              onClick={onSaveDetails}
              disabled={loading}
              className="mt-6 w-fit"
            >
              Save Changes{" "}
            </Button>
          </div>
        </CardContent>
      </Card>
    </StateRenderer>
  );
};

export default RecruiterDetailsUI;
