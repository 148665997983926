import { useEffect, useState } from "react";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { Popover, PopoverTrigger, PopoverContent } from "../../ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from "../../ui/command";
import cn from "../../../lib/utils";
import { Check } from "lucide-react";
import {
  addManualLead,
  checkForAssignment,
  fetchRecruiterDetailsLms
} from "../../../services/insideSales";
import useInsideLeadStore from "../../../zustandService/useInsideLeadStore";
import { customUseEffect, getModeratorName, redirectURL } from "../../../services/utils";
import {  isAdmin, isModerator, isTl } from "../../../services/utilsV2";
import { getCookie } from "../../../services/cookie";
import moment from "moment";
import { LFMS, RECUITERS_APPLICANT } from "../../../routes/RouteConstants";
const Search = ({
  setSearchData,
  loading,
  setLoading,
  searchTriggered,
  setSearchTriggered
}) => {
  const [data, setData] = useState({ recruiterId: "", phoneNo: "" });

  const onChangeInput = e => {
    setSearchTriggered(false);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSearchClick = e => {
    e.preventDefault();
    if (searchTriggered) return;

    const mobileNumberRegex = /^[5-9]\d{9}$/;
    if (data?.phoneNo && !mobileNumberRegex.test(data?.phoneNo)) {
      alert("Please enter a valid number");
      return;
    }

    setSearchData(data);
    setLoading(true);
    setSearchTriggered(true);
  };

  return (
    <form className="grid grid-cols-1 gap-6 mb-12">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-4 gap-y-8">
        <h1 className="col-span-1 pb-3 mb-3 text-lg font-medium border-b md:col-span-4">
          Add lead
        </h1>
        <div className="grid gap-2">
          <Label htmlFor="recruiter-id">Recruiter ID</Label>
          <Input
            name="recruiterId"
            value={data.recruiterId}
            id="recruiter-id"
            onChange={onChangeInput}
            placeholder="Enter recruiter ID"
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="phone-number">Phone Number</Label>
          <Input
            name="phoneNo"
            value={data.phoneNo}
            id="phone-number"
            type="number"
            onChange={onChangeInput}
            placeholder="Enter phone number"
            maxLength="10"
          />
        </div>
        <div className="grid items-end gap-2">
          <Button
            onClick={onSearchClick}
            className="w-fit"
            disabled={loading || (!data.phoneNo && !data.recruiterId)}
          >
            {loading ? "Loading..." : "Search"}
          </Button>
        </div>
      </div>
    </form>
  );
};

const ModList = ({ mods, setSelectedModerator }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({});

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="w-full px-3 py-2 bg-white border rounded-lg cursor-pointer">
          {!value.name ? "Select Agent" : value.name}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandList>
            <CommandEmpty>No agent found.</CommandEmpty>
            <CommandGroup>
              {mods.map(mod => (
                <CommandItem
                  key={mod.id}
                  value={mod.name}
                  onSelect={() => {
                    setValue(mod);
                    setSelectedModerator(mod);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={
                      value.id === mod.id ? "opacity-100" : "opacity-0"
                    }
                  />
                  {mod.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default function AddLead() {
  const [recData, setRecData] = useState(null);
  const [leadAssignable, setLeadAssignable] = useState(null);
  const [moderators, setModerators] = useState([]);
  const [moderator, setSelectedModerator] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [leadModeratorId,setLeadModeratorId] = useState(null);
  const { fetchModeratorData, moderatorList } = useInsideLeadStore(state => ({
    fetchModeratorData: state.fetchModeratorData,
    moderatorList: state.moderatorList
  }));
  const moderatorId = getCookie("moderator_id");
  const [remarks, setRemarks] = useState("");


  const onSearchClick = async data => {
    try {
      setRecData(null);
      setLeadModeratorId(null)
      const res = await fetchRecruiterDetailsLms(data);
      if (!res.recruiterId) {
        alert("No Recruiter Found");
        setLoading(false);
        return;
      }
      const res2 = await checkForAssignment({ recruiterId: res.recruiterId });
      setRecData(res);
      setLeadAssignable(res2.assignable === true);
      setLeadModeratorId(res2.moderatorId)
      setLoading(false);
    } catch (error) {
      alert("Something went wrong");
      setLoading(false);
    }
  };

  const onAssign = async e => {
    e.preventDefault();

    try {
      await addManualLead({
        moderatorId:moderator.id,
        recruiterId: recData.recruiterId,
        remarks: remarks
      });
      alert("Lead assigned successfully");
      redirectURL(`${LFMS}`)
    } catch (error) {
      alert("Something went wrong");
    }
  };
  
  customUseEffect(() => {
    if (isModerator()) {
      const name = getModeratorName();
      setModerators([{ name, id: moderatorId }]);
    } else if (!moderators.length &&( isAdmin()||isTl())) {
      fetchModeratorData().then(() => setModerators(moderatorList));
    }
  }, [searchTriggered,leadModeratorId]);
  const filtered=leadModeratorId?moderators.filter((obj)=>obj.id==leadModeratorId):moderators;

  return (
    <div className="md:grid  grid-cols-6 w-screen lg:w-[calc(100vw-15rem)] h-screen lg:overflow-hidden ">
      <div className="col-span-6 p-6 space-y-6 overflow-scroll border-r">
        <Search
          setSearchData={onSearchClick}
          loading={loading}
          setLoading={setLoading}
          searchTriggered={searchTriggered}
          setSearchTriggered={setSearchTriggered}
        />
        {recData && !loading ? (
          <div className="grid grid-cols-1 gap-2 p-12 bg-gray-50 rounded-xl md:grid-cols-4 md:gap-4">
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Recruiter ID</p>
              <div className="text-medium">{recData?.recruiterId}</div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Recruiter Name</p>
              <div className=" text-medium">{recData?.recruiterName}</div>
            </div>

            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Type</p>
              <div className="text-medium">{recData?.recruiterType}</div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Registered On</p>
              <div className="text-medium">
                {moment(recData?.registeredOn || "").format("DD-MM-YYYY")}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Phone</p>
              <div className="text-medium">
                {recData?.phoneNo}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Status</p>
              <div className="text-medium">
               {recData?.recruiterStatus}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Company</p>
              <div className="text-medium">
               {recData?.companyName}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Credits Available</p>
              <div className="text-medium">
               {recData?.totalCredits}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Job Post</p>
              <a target="__blank" href={`${RECUITERS_APPLICANT}/${recData?.recruiterId}`} className=" font-medium text-blue-400 underline">            {recData?.jobPostCount}
</a>
           
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Company Assigned To</p>
              <div className="text-medium">
               {recData?.companyAssigned||"-"}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Recruiter Assigned To</p>
              <div className="text-medium">
               {recData?.recruiterAssigned||"-"}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm text-gray-500">Total Credits</p>
              <div className="text-medium">
               {recData?.totalCreditsPurchased||"-"}
              </div>
            </div>
          </div>
        ) : null}
        {filtered && filtered.length && recData && leadAssignable ? (
          <div>
            <DropdownWithOtherOption setRemarks={setRemarks} />

            <div className="grid items-end grid-cols-2 col-span-4 gap-3 pt-6 mt-6 border-t">
              <div className="grid col-span-1 gap-2">
                <Label htmlFor="verification-status">Assign to</Label>
                <ModList
                  mods={filtered}
                  setSelectedModerator={setSelectedModerator}
                />
              </div>

              <div className="col-span-1">
                <Button
                  disabled={!moderator.id || !remarks}
                  onClick={onAssign}
                  className=" w-fit"
                >
                  Assign
                </Button>
              </div>
            </div>
          </div>
        ) : null}
        {recData &&leadAssignable===false && !loading &&
        <div>
         Note:- Recruiter is not assignable
          </div>}
      </div>
    </div>
  );
}

const DropdownWithOtherOption = ({ setRemarks }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [otherValue, setOtherValue] = useState("");

  const handleSelectChange = event => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value !== "other") {
      setRemarks(value);
    } else {
      setRemarks("");
    }
  };

  const handleOtherChange = event => {
    const value = event.target.value;
    setOtherValue(value);
    setRemarks(value);
  };

  return (
    <div className="flex flex-col space-y-4">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="options"
      >
        Manual Addition Reason:
      </label>
      <select
        id="options"
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        value={selectedOption}
        onChange={handleSelectChange}
      >
        <option value="" disabled>
          Select an option
        </option>
        <option value="Referral">Referral</option>
        <option value="In Bound Call">In Bound Call</option>
        <option value="other">Other</option>
      </select>
      {selectedOption === "other" && (
        <div>
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="otherInput"
          >
            Please specify:
          </label>
          <input
            id="otherInput"
            type="text"
            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            value={otherValue}
            onChange={handleOtherChange}
          />
        </div>
      )}
    </div>
  );
};
