import cx from "classnames";
import { withStyles } from "@material-ui/styles";
import { styles } from "../CreditWidget/helper";
import MetaData from "../../constants/MetaData";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import CustomSelect from "../Common/Form/CustomSelect";
import FileUpload from "./FileUpload";
import {
  formatNumber,
  linkGeneratorUtils,
  validityMapper
} from "../../services/utils";
import { useMemo } from "react";
import { minBasicValueReqForPan } from "../../services/constant";

const RenderProofOfPayment = props => {
  const { fieldItems, onChange } = props;
  const { paymentMode, payUid, chequeNo, financeReferenceNumber } = fieldItems;
  switch (paymentMode) {
    case "CHEQUE":
      return (
        <div className="col-md-6 mt-2" id="chequeNo">
          <Label lable="Cheque number (mandatory)" />
          <CustomTextField
            name="chequeNo"
            value={chequeNo}
            onChange={onChange}
            maxLength={6}
            placeholder="Cheque number"
          />
        </div>
      );
    case "BANK_TRANSFER":
      return (
        <div className="col-md-6 mt-2" id="financeReferenceNumber">
          <Label lable="Finance reference number (mandatory)" />
          <CustomTextField
            name="financeReferenceNumber"
            value={financeReferenceNumber}
            onChange={onChange}
            maxLength={255}
            placeholder="Finance ref no"
          />
        </div>
      );

    case "PAYU":
    case "SCANNER":
      return (
        <div className="col-md-6 mt-2" id="payUid">
          <Label lable="Pay U id (mandatory)" />
          <CustomTextField
            name="payUid"
            value={payUid}
            onChange={onChange}
            maxLength={11}
            placeholder="Pay U id"
          />
        </div>
      );
    default:
      return null;
  }
};

const CreditsAdditionForm = props => {
  const creditsAdditionType = MetaData.creditsAdditionType;
  const creditsValidityList = MetaData.creditsValidityList;
  const paymentModes = MetaData.paymentModes;
  const {
    fieldItems,
    errorMessage,
    onChange,
    onSave,
    classes,
    pendingData,
    onGstFieldBlur
  } = props;
  const {
    transactionType,
    orderId,
    validity,
    credits,
    creditReason,
    file,
    gstNumber,
    paymentMode,
    panNumber
  } = fieldItems;

  function getFileData(file) {
    onChange({ target: { name: "file", value: file } });
  }

  let disabled = !transactionType || !file;
  if (transactionType === "PAID") {
    disabled = !orderId || !creditReason || !paymentMode;
  }
  if (transactionType === "UNPAID") {
    disabled = !credits || !validity || !creditReason || !file;
  }
  const orderObj = pendingData.find(item => item.orderId == orderId) || {};
  const panDisabled = useMemo(() => {
    return !!gstNumber;
  }, [gstNumber]);
  return (
    <>
      <div className="formRow row">
        <div className="col-md-4">
          <Label lable="Credit Type" />
          <CustomSelect
            name="transactionType"
            value={transactionType}
            onChange={onChange}
            optionsList={creditsAdditionType}
          />
        </div>
        {transactionType === "PAID" ? (
          <>
            {pendingData.length ? (
              <>
                <div className="col-md-4">
                  <Label lable="Order Id" />
                  <CustomSelect
                    name="orderId"
                    value={orderId}
                    onChange={onChange}
                    optionsList={pendingData}
                    itemLabel="orderId"
                    optionValue="orderId"
                  />
                </div>
                <div className="col-md-4">
                  <Label lable="Payment Mode" />
                  <CustomSelect
                    name="paymentMode"
                    value={paymentMode}
                    onChange={onChange}
                    optionsList={paymentModes}
                  />
                </div>
                <RenderProofOfPayment
                  fieldItems={fieldItems}
                  onChange={onChange}
                />
                <div className="col-md-6 mt-2">
                  <Label lable="GST (Optional)" />
                  <CustomTextField
                    name="gstNumber"
                    value={gstNumber}
                    onChange={onChange}
                    maxLength={15}
                    placeholder="GST (Optional)"
                    onBlur={onGstFieldBlur}
                  />
                </div>
                {orderObj.sellingPriceWithoutGst > minBasicValueReqForPan ? (
                  <div className="col-md-6 mt-2">
                    <Label lable="PAN (mandatory)" />
                    <CustomTextField
                      name="panNumber"
                      value={panNumber}
                      onChange={onChange}
                      maxLength={10}
                      placeholder="PAN"
                      disabled={panDisabled}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <div className="col-md-8">
                <Label lable="" />
                <div className={classes.errorState}>
                  <i class="fa fa-x" aria-hidden="true" />
                  <span>&nbsp; No order id available</span>
                </div>
              </div>
            )}
          </>
        ) : null}
        {transactionType === "UNPAID" ? (
          <>
            <div className="col-md-4">
              <Label lable="No. of Credit" />
              <CustomTextField
                name="credits"
                value={credits}
                onChange={onChange}
                maxLength={6}
                placeholder="Credit"
              />
            </div>
            <div className="col-md-4">
              <Label lable="Validity" />
              <CustomSelect
                name="validity"
                value={validity}
                onChange={onChange}
                optionsList={creditsValidityList}
              />
            </div>
          </>
        ) : null}
        <div className="col-md-12">
          <Label lable="Remarks" />
          <CustomTextField
            name="creditReason"
            value={creditReason}
            onChange={onChange}
            maxLength={100}
            placeholder="Remarks"
          />
        </div>
        {transactionType === "UNPAID" ? (
          <div className="col-md-12">
            <FileUpload
              name="file"
              label="Add Proof"
              getFileData={getFileData}
            />
          </div>
        ) : null}
      </div>
      <div className={cx("flex", "buttonContainer", "center")}>
        <input
          type="button"
          onClick={() => onSave("PRELIMINARY")}
          value="Save"
          className={cx("submitBtn", "btns", "btn1", "flex", "justifyCenter")}
          disabled={disabled}
        />
      </div>
      {errorMessage && (
        <div className={classes.errorState}>
          <i class="fa fa-x" aria-hidden="true" />
          <span>&nbsp; {errorMessage}</span>
        </div>
      )}
      {orderId && pendingData && pendingData.length ? (
        <div>
          {(function () {
            const obj = pendingData.find(item => item.orderId == orderId);
            const credits = obj.credits;
            const sellingPrice = obj.sellingPriceWithoutGst;
            const validityLabel = obj.validityLabel;
            const noOfJobs = linkGeneratorUtils({
              credits,
              compute: "noOfJobs"
            });
            const perJob = formatNumber(sellingPrice / noOfJobs);
            return (
              <div>
                {`${credits} credits(good for ${noOfJobs} Premium jobs) @ INR ${sellingPrice}(${perJob}/Job)+Taxes with validity of ${validityLabel}`}
              </div>
            );
          })()}
        </div>
      ) : null}
    </>
  );
};
export default withStyles(styles)(CreditsAdditionForm);
