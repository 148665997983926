import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import ChipInput from "material-ui-chip-input";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import AutoSearch from "../Common/AutoSearch";
import MetaData from "../../constants/MetaData";
import ClientChips from "./ClientChips";
import { isNumeric } from "../../services/utils";
import * as _ from "underscore";

class RecruiterSearch extends React.Component {
  constructor(props) {
    super(props);
    const { recIdList = [] } = props;
    this.state = {
      isLoading: true,
      rec_id: "",
      job_id: "",
      recruiter_name: "",
      phone: "",
      email: "",
      company_name: "",
      city_id: "",
      locality_id: "",
      job_status: "",
      application_count: "",
      job_count: "",
      pack_type: "",
      posted_after: "",
      denial_reason: "",
      edate: "",
      sdate: "",
      document_status: "",
      client_id: [],
      selectedHiringCompList: [],
      chipsRecIds: recIdList,
      isHiringCompanySearch: false,
      msgData: { msg: "", showMsg: false, msgType: "error" },
      document_no: ""
    };
  }

  handleChange = event => {
    if (event.target.name === "city_id") {
      this.setState({ locality_id: "" });
    }
    if (event.target.name === "locality_id" && event.target.value !== "") {
      this.setState({
        locality_id: event.target.value
      });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCompChange = keyword => {
    this.setState({ isHiringCompanySearch: keyword.length > 0 ? true : false });
  };

  // Add Chips
  handleAddChip = chip => {
    if (isNumeric(chip)) {
      this.setState({
        chipsRecIds: [...this.state.chipsRecIds, chip]
      });
    }
  };
  // Delete Chips
  handleDeleteChip = chip => {
    this.setState({
      chipsRecIds: _.without(this.state.chipsRecIds, chip)
    });
  };

  updateMessage = (msg, flag) => {
    this.props.statusMessage(msg, flag);
  };

  handleHiringCompany = params => {
    this.setState({ client_id: params });
  };

  handleHiringCompanyList = compName => {
    const { selectedHiringCompList } = this.state;
    let checkDuplicate = selectedHiringCompList.filter(
      item => item.client_id === compName.client_id
    );
    if (checkDuplicate.length === 0) {
      selectedHiringCompList.push(compName);
      this.setState({
        selectedHiringCompList: selectedHiringCompList
      });
    }
  };

  handleRemove = clientId => {
    const { selectedHiringCompList } = this.state;
    let list = selectedHiringCompList.filter(
      item => item.client_id !== clientId
    );
    this.setState({
      selectedHiringCompList: list
    });
  };

  uniqueArray = arr => {
    const seen = new Set();
    const filteredArr = arr.filter(el => {
      const duplicate = seen.has(el.client_id);
      seen.add(el.client_id);
      return !duplicate;
    });
    return filteredArr;
  };

  searchRecruiter = e => {
    e.preventDefault();
    const {
      document_status,
      selectedHiringCompList,
      rec_id,
      job_id,
      recruiter_name,
      phone,
      company_name,
      email,
      city_id,
      locality_id,
      job_status,
      application_count,
      job_count,
      denial_reason,
      sdate,
      edate,
      isHiringCompanySearch,
      chipsRecIds,
      document_no
    } = this.state;
    let status = document_status;
    let reject_reason = "";
    if (document_status) {
      let rejectVal = document_status.split("_");
      if (rejectVal[0] === "rejected") {
        status = rejectVal[0];
        reject_reason = rejectVal[1] ? rejectVal[1] : "";
      }
    }

    let clientList = selectedHiringCompList.map(item => {
      return item.client_id;
    });

    const obj = {
      recruiter_id: rec_id,
      job_id,
      recruiter_name: recruiter_name?.trim(),
      phone,
      email,
      company_name: company_name?.trim(),
      city_id,
      locality_id,
      job_status,
      application_count,
      job_count,
      denial_reason,
      posted_date_start: sdate ? moment(sdate).format("YYYY-MM-DD") : "",
      posted_date_end: edate ? moment(edate).format("YYYY-MM-DD") : "",
      document_status: status,
      reject_reason,
      client_id: clientList.length > 0 ? clientList : "",
      recruiter_ids: chipsRecIds.length > 0 ? chipsRecIds : "",
      document_no
    };

    if (isHiringCompanySearch && selectedHiringCompList.length === 0) {
      this.updateMessage(
        "Please select hiring company name from list or clear hiring company name from search",
        1
      );
      window.scrollTo(0, 0);
      return;
    }

    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        delete obj[key];
      }
    });
    this.props.searchRecruiters(obj);
  };

  render() {
    const { isButtonDisabled } = this.props;
    const { selectedHiringCompList, chipsRecIds, document_no } = this.state;
    const docStatusList = MetaData.companyDocumentStatus;
    return (
      <div className="job_seeker recruiter-search">
        <div className="gap-20"></div>
        <form
          autoComplete="off"
          onSubmit={this.searchRecruiter}
          className="search"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <ChipInput
                  label="Enter Recruiter Ids..."
                  value={chipsRecIds}
                  onAdd={chipsRecIds => this.handleAddChip(chipsRecIds)}
                  onDelete={(chipsRecIds, index) =>
                    this.handleDeleteChip(chipsRecIds, index)
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="recruiter_name"
                  placeholder="Recruiter Name "
                  value={this.state.recruiter_name}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  placeholder="Rec Phone no"
                  value={this.state.phone}
                  onChange={event =>
                    this.setState({
                      phone: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  placeholder="Recruiter Email ID"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <SelectCity
                filedName={"city_id"}
                value={this.state.city_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-2">
              <SelectLocality
                filedName={"locality_id"}
                value={this.state.locality_id}
                cityId={this.state.city_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="company_name"
                  placeholder="Company Name"
                  value={this.state.company_name}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="job_id"
                  placeholder="Job ID"
                  live_status
                  value={this.state.job_id}
                  onChange={event =>
                    this.setState({
                      job_id: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>

            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="denial_reason"
                value={this.state.denial_reason}
                onChange={this.handleChange}
              >
                <option value="">Select Recruiter Bucket</option>
                <option value="DENIED">Denied</option>
                <option value="JOB_SEEKER">Job Seeker</option>
                <option value="BLACKLIST">Black list</option>
                <option value="GRAYLIST">Gray list</option>
              </select>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="job_status"
                  onChange={this.handleChange}
                  value={this.state.job_status}
                >
                  <option value="" className="option-selected">
                    Job Live Status
                  </option>
                  <option value="LIVE">Currently Live</option>
                  <option value="LIVE_IN_PAST">Live in Past</option>
                </select>
              </div>
            </div>

            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="document_status"
                value={this.state.document_status}
                onChange={this.handleChange}
              >
                <option value="">Document Status</option>
                {docStatusList.map(option => (
                  <option value={option.value} key={`docS${option.value}`}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="job_count"
                  value={this.state.job_count}
                  placeholder="Jobs Posted"
                  onChange={event =>
                    this.setState({
                      job_count: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="application_count"
                  value={this.state.applications}
                  placeholder="Applications"
                  onChange={event =>
                    this.setState({
                      application_count: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>
            <div className="from-col">Date From</div>
            <div className="col-md-2">
              <DatePicker
                selected={this.state.sdate}
                onChange={value => {
                  this.setState({ sdate: value });
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </div>
            <div className="col-md-1 to-col">To</div>
            <div className="col-md-2">
              <DatePicker
                selected={this.state.edate}
                onChange={value => {
                  this.setState({ edate: value });
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </div>

            <div className="col-md-4">
              <AutoSearch
                handleHiringCompanyList={this.handleHiringCompanyList}
                handleCompChange={this.handleCompChange}
                hiringCompList={selectedHiringCompList}
              />

              {selectedHiringCompList.length > 0 ? (
                <div className="row">
                  <div className="col-md-12 client">
                    <ClientChips
                      list={selectedHiringCompList}
                      onClick={this.handleRemove}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="document_no"
                  placeholder="Document Number"
                  value={document_no}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="float-right">
                {isButtonDisabled ? (
                  <button className="btn btn-primary disable" disabled>
                    SEARCHING ...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={this.searchRecruiter}
                  >
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
      </div>
    );
  }
}
export default RecruiterSearch;
