import { getCookie } from "../../services/cookie";

const ProxyModeratorName = () => {
  const proxyModeratorName = getCookie("proxy_moderator_name");
  return (
    <>
      {proxyModeratorName ? (
        <div className="row">
          <div className="col-md-12">
            <div className=" float-right">
              <b>Proxy for – {proxyModeratorName}</b>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default ProxyModeratorName;
