import React from "react";

const RecruiterStatus = props => {
  const { recruiterStatus } = props;
  return (
    <>
      {recruiterStatus === "VERIFIED_REPEAT" ? (
        <span className="verified">
          <b className="green" />
        </span>
      ) : recruiterStatus === "VERIFIED_NON_REPEAT" ? (
        <span className="not-repeat">
          <b className="blue" />
        </span>
      ) : recruiterStatus === "REJECTED" ? (
        <span className="unVerified">
          <b className="red" />
        </span>
      ) : recruiterStatus === "NEW" ? (
        <span className="underReview">
          <b className="yellow" />
        </span>
      ) : null}
    </>
  );
};

export default RecruiterStatus;
