import moment from "moment";
import { CSVLink } from "react-csv";
const DownloadLeads = props => {
  const { onClick, isDisabled, leadsList, isDownload, clientId } = props;
  const csvFilename = `js-leads-${moment(new Date()).format(
    "YYYY-MM-DD-hh_mm_ss"
  )}.csv`;
  let csvHeaders = [
    { label: "S.No.", key: "srNo" },
    { label: "Lead User Id", key: "lead_user_id" },
    { label: "Date", key: "createdAt" },
    { label: "Full Name", key: "full_name" },
    { label: "Age", key: "dob" },
    { label: "Gender", key: "gender" },
    { label: "Phone No.", key: "phone" },
    { label: "City", key: "city" }
  ];
  // TML - will make it configurable from BE,
  // temperary making it as static/hard code.
  if (Number(clientId) === 2) {
    csvHeaders = [
      ...csvHeaders,
      { label: "State", key: "user_state" },
      { label: "House/Street Name", key: "residential_property" },
      { label: "Region", key: "locality" },
      { label: "Pin Code", key: "pinCode" },
      { label: "Aadhar Card", key: "is_aadhar_card_available" },
      { label: "4W Driving License", key: "is_four_wheeler_license_available" },
      { label: "PAN Card", key: "is_pan_card_available" },
      { label: "Address Proof", key: "is_address_proof_available" },
      { label: "Current/last job", key: "category" },
      { label: "No. of years of experience", key: "exp" },
      { label: "Type of Driver (CV/PV)", key: "type_of_driver" },
      { label: "Current Salary", key: "current_salary" },
      { label: "Willing to attend Workshop?", key: "workshop_availability" },
      { label: "Workshop Date", key: "workshop_date" },
      { label: "Anxiety", key: "remarks" },
      { label: "Lead Shared", key: "leadShared" }
    ];
  }
  // Rapido - will make it configurable from BE.
  if (Number(clientId) === 10) {
    csvHeaders = [
      ...csvHeaders,
      { label: "Region", key: "locality" },
      { label: "4W Driving License", key: "is_four_wheeler_license_available" },
      {
        label: "Willing to drive 3W auto?",
        key: "is_willing_for_three_wheeler"
      },
      {
        label: "Has the interview center been confirmed",
        key: "is_interview_center_confirmed"
      },
      {
        label: "Has the interview date been confirmed",
        key: "rescheduling_intent"
      },
      { label: "Interview Date", key: "interview_date" },
      { label: "Rescheduled Date", key: "rescheduled_date" },
      { label: "Lead Shared", key: "leadShared" }
    ];
  }
  // G4S - will make it configurable from BE.
  if (Number(clientId) === 12) {
    csvHeaders = [
      ...csvHeaders,
      {
        label: "height greater than or equal to 5'7",
        key: "is_height_adequate"
      },
      {
        label: "candidate confirmed the payment for registration?",
        key: "is_registration_payment_confirmed"
      },
      {
        label: "Has the interview center been confirmed",
        key: "is_interview_center_confirmed"
      },
      {
        label: "Has the interview date been confirmed",
        key: "rescheduling_intent"
      },
      { label: "Interview Date", key: "interview_date" },
      { label: "Rescheduled Date", key: "rescheduled_date" },
      { label: "Lead Shared", key: "leadShared" }
    ];
  }

  leadsList.map((d, index) => {
    const { date_of_birth, lead_bucket, lead_campaign_attributes } = d;
    let exp = "NA";
    let typeOfDriver = "NA";
    if (lead_campaign_attributes) {
      const { type_of_driver = "", experience = "" } = JSON.parse(
        lead_campaign_attributes
      );
      exp = experience ? experience : "NA";
      typeOfDriver = type_of_driver ? type_of_driver : "NA";
    }
    d.dob = date_of_birth ? moment(date_of_birth).format("YYYY-MM-DD") : "NA";
    d.agencyName = "NA";
    d.srNo = ++index;
    d.pinCode = "NA";
    d.leadShared = lead_bucket === "Shared" ? "Yes" : "No";
    d.exp = exp;
    d.type_of_driver = typeOfDriver;
    return d;
  });

  return (
    <span className="marginleft">
      <button
        className="btn btn-primary"
        onClick={onClick}
        disabled={isDisabled}
      >
        <i className="fas fa-download" aria-hidden="true" /> Download Leads
      </button>
      {isDownload && !isDisabled ? (
        <CSVLink
          data={leadsList}
          headers={csvHeaders}
          filename={csvFilename}
          className="primary"
          target="_blank"
        >
          Export Leads
        </CSVLink>
      ) : null}
    </span>
  );
};

export default DownloadLeads;
