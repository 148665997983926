import React from "react";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import JobLink from "./JobLink";
import JobSearch from "./JobSearch";
import PopupMessage from "../PopupMessage";
import CustomePagination from "../Common/Pagination";
import { getErrorMessage, getEditPageURL } from "../../services/utils";
import PremiumTag from "../Common/PremiumTag";
import { getCrmMetaData } from "../../services/metadataApiServices";

class LiveJobList extends React.Component {
  constructor(props) {
    super(props);
    let edate = new Date();
    let d = new Date();
    let sdate = d.setMonth(d.getMonth() - 1);
    this.state = {
      liveJobs: [],
      isLoading: true,
      role: "QA",
      searchObj: {
        made_live_date_start: moment(sdate).format("YYYY-MM-DD"),
        made_live_date_end: moment(edate).format("YYYY-MM-DD")
      },
      isButtonDisabled: false,
      msgData: { msg: "", showMsg: false, msgType: "error" },
      showSearchBox: true,
      activePage: 1,
      count: 0,
      itemsCountPerPage: 50,
      filters: {}
    };
  }

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  componentDidMount() {
    this.getCategories();
    this.getLiveJobs();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters, activePage } = this.state;
    if (filters !== prevState.filters) {
      this.setState({
        filters: filters
      });
      this.getLiveJobs();
    }
    if (activePage !== prevState.activePage) {
      this.setState({
        activePage: activePage
      });
      this.getLiveJobs();
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }

  handleShowHide = () => {
    this.setState({
      showSearchBox: !this.state.showSearchBox
    });
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      }
    });
    window.scrollTo(0, 0);
  };

  searchData = objData => {
    this.setState({
      liveJobs: [],
      isButtonDisabled: true,
      filters: objData,
      activePage: 1,
      msgData: {
        msg: "",
        showMsg: false,
        msgType: ""
      }
    });
  };

  getCategories = async () => {
    try {
      const response = await getCrmMetaData();
      const { categories = [] } = response;
      this.setState({
        categories
      });
    } catch (error) {
      const msg = getErrorMessage(error);
      this.setState({
        isLoading: false,
        msgData: { msg: msg, showMsg: true, msgType: "error" }
      });
    }
  };

  getLiveJobs = () => {
    const { filters, activePage, itemsCountPerPage } = this.state;
    JobHai.post(`/search_jobs`, {
      filters: filters,
      page: activePage - 1,
      size: itemsCountPerPage
    })
      .then(response => {
        let data = response.data.data;
        let count = data.count ? data.count : 0;
        this.setState({
          liveJobs: data.jobs,
          count: count,
          isLoading: false,
          isButtonDisabled: false
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isLoading: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" },
          isButtonDisabled: false
        });
        window.scrollTo(0, 0);
      });
  };

  render() {
    const {
      liveJobs = [],
      isButtonDisabled,
      showSearchBox,
      categories = [],
      role,
      activePage,
      itemsCountPerPage,
      count
    } = this.state;

    const totalJobs = count;

    liveJobs.map(d => {
      const {
        job_id = "",
        verification_status = "",
        recruiter_id = "",
        jobPlan = ""
      } = d;
      const editUrl = getEditPageURL(
        verification_status,
        job_id,
        recruiter_id,
        role,
        "live"
      );
      d.edit_link = <JobLink url={editUrl} id={job_id} />;
      d.jobId = (
        <>
          {job_id}
          <PremiumTag jobPlan={jobPlan} />
        </>
      );
      d.createdAt = d.made_live_date
        ? moment(d.made_live_date).format("ddd, DD-MMM-YYYY, hh:mm A")
        : "NA";
      d.category_name =
        categories.filter(k => k.id === d.category_id).length > 0
          ? categories.filter(k => k.id === d.category_id)[0].title
          : "";
      return d;
    });

    const columns = [
      {
        text: "",
        dataField: "_status",
        classes: "statusCircle",
        formatter: (cellContent, row) => {
          if (row.isRepeatingRecruiter) {
            return <b className="green"></b>;
          }
          return <b className="gray"></b>;
        }
      },
      {
        text: "JOB ID",
        dataField: "jobId",
        sort: true
      },

      {
        text: "Job Title",
        dataField: "job_title",
        sort: true
      },
      {
        text: "Company Name",
        dataField: "company_name",
        sort: true
      },
      {
        text: "Recruiter Name",
        dataField: "recruiter_name",
        sort: true
      },
      {
        text: "Category",
        dataField: "category_name",
        sort: true
      },
      {
        text: "SOURCE",
        dataField: "source",
        sort: true
      },
      {
        text: "Locality",
        dataField: "locality_name",
        sort: true
      },
      {
        text: "",
        dataField: "edit_link"
      }
    ];

    const NoDataIndication = () => <div className="spinner">No Jobs Found</div>;
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <div>
          <div className="clr gap-20"></div>
          <PopupMessage
            data={this.state.msgData}
            click={this.handleClosePopup}
          />
          <div className="pageHeading row">
            <h2>
              Live Jobs
              <div className="float-right">
                <a href={"/jobs/"}> Go Back</a>
              </div>
              <div className="float-right" onClick={this.handleShowHide}>
                <span className="link">
                  {showSearchBox ? "Hide" : "Show"} Search{" "}
                </span>
              </div>
            </h2>
          </div>
          <div className="clr gap-20"></div>
          {showSearchBox && (
            <JobSearch
              cat={categories}
              statusMessage={this.statusMessage}
              searchData={this.searchData}
              isButtonDisabled={isButtonDisabled}
            />
          )}
          <div className="clr"></div>
          <div className="row sortingWrap">
            <span>
              <b className="green"></b>Repeat Verified Recruiter
            </span>
            {/* <span>
              <b className="red"></b>DISABLED ({desableJobs})
            </span> */}
            <span>
              <b></b>Total Live Jobs ({totalJobs})
            </span>
          </div>
          <div className="clr"></div>
          <div className="whitebox livejobs">
            <BootstrapTable
              bootstrap4
              keyField="job_id"
              data={liveJobs}
              columns={columns}
              hover
              condensed
              bordered={false}
              loading={true}
              rowClasses="listvalue"
              headerClasses="listHeding"
              classes="borderless"
              noDataIndication={() => <NoDataIndication />}
            />
            <CustomePagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={count}
              onPageChange={this.handlePageChange}
              showPagination={count > 0 ? true : false}
            />
            <div className="clr gap-20"></div>
          </div>
        </div>
      );
    }
  }
}

export default LiveJobList;
