import React from "react";
import DocumentTitle from "react-document-title";
import { checkPermission, isCRMAdmin } from "../../services/utils";
import {
  COMPLIANCE_PROTECTION,
  FRAUD_KEYWORDS,
  REPORTED_JOBS
} from "../../routes/RouteConstants";
import NoAccess from "../Common/NoAccess";

const ComplaintsDashboard = () => {
  const showReportedJob = checkPermission("Reported Jobs");
  const isViewPage = showReportedJob;
  return (
    <DocumentTitle title={"Job Hai CRM - Complaints Dashboard"}>
      <div className="dashboard">
        <div className="clr gap-20"></div>
        <div className="pageHeading row">
          <h2>Complaints Dashboard </h2>
        </div>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
        <div className="whiteCard">
          {!isViewPage ? (
            <NoAccess />
          ) : (
            <div className="container-fluid">
              <div className="clr gap-20"></div>
              <div className="row marginBottom20">
                {showReportedJob ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={COMPLIANCE_PROTECTION}>
                        Compliance & Protection
                      </a>
                    </div>
                  </div>
                ) : null}
                {showReportedJob ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={REPORTED_JOBS}>Reported Jobs</a>
                    </div>
                  </div>
                ) : null}
                {/* {isCRMAdmin() ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={FRAUD_KEYWORDS}>Fraud Keywords</a>
                    </div>
                  </div>
                ) : null} */}
              </div>
            </div>
          )}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default ComplaintsDashboard;
