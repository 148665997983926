import React, { useState, useEffect } from "react";
import JobHai from "../../api/JobHai";
import { getCustomErrorMessage } from "../../services/utils";

const SendWhatsApp = ({ campaignId, leadUserId }) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ error: "", success: "" });

  useEffect(() => {
    let hideTimeout;
    if (message.error || message.success) {
      hideTimeout = setTimeout(() => {
        setMessage({ error: "", success: "" });
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    return () => clearTimeout(hideTimeout);
  }, [message.error, message.success]);

  const sendWhatsApp = () => {
    if (window.confirm("Are you sure you want to send WhatsApp?")) {
      setLoading(true);
      JobHai.post("send-user-whatsapp", {
        campaign_id: campaignId,
        user_id: leadUserId
      })
        .then(response => {
          setMessage({ error: "", success: "Message sent successfully" });
        })
        .catch(error => {
          let msg = getCustomErrorMessage(error);
          setMessage({ error: msg, success: "" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <span className="float-right">
      {message.error && <span className="text-danger">{message.error}</span>}
      {message.success && (
        <span className="text-success">{message.success}</span>
      )}
      <button
        className="btn btn-primary"
        onClick={sendWhatsApp}
        disabled={isLoading}
      >
        {isLoading ? `Sending...` : `Send WhatsApp`}
      </button>
    </span>
  );
};

export default SendWhatsApp;
