import React, { useState, useEffect } from "react";
import Select from "react-select";
import { capitalizeFirstLetter, getErrorMessage } from "../../services/utils";
import { getLocality } from "../../services/metadataApiServices";

const SelectLocality = ({
  cityId,
  label = "",
  value = "",
  isDisabled = false,
  filedName = "locality_id",
  customClass = "",
  onChange
}) => {
  const [localityList, setLocalityList] = useState([]);

  useEffect(() => {
    const getLocalities = async () => {
      if (cityId) {
        try {
          const data = (await getLocality(cityId)) || [];
          setLocalityList(data);
        } catch (error) {
          setLocalityList([]);
          console.log("Error", getErrorMessage(error));
        }
      } else {
        setLocalityList([]);
      }
    };

    getLocalities();
  }, [cityId]);

  const handleChange = params => {
    let event = { target: { name: filedName, value: params._id } };
    onChange(event);
  };

  localityList.forEach(item => {
    item.value = item._id;
  });

  localityList.sort((a, b) =>
    capitalizeFirstLetter(a.label) < capitalizeFirstLetter(b.label)
      ? -1
      : capitalizeFirstLetter(a.label) === capitalizeFirstLetter(b.label)
      ? 0
      : 1
  );

  const selectedOptions = value
    ? localityList.find(item => item._id === value)
    : [];

  return (
    <>
      {label && <label>{label}</label>}
      {isDisabled ? (
        <>
          {!customClass ? <b className="downArrow" /> : null}
          <select
            className={customClass ? customClass : "form-control"}
            name={filedName}
            onChange={handleChange}
            value={value}
            disabled={isDisabled}
          >
            <option value="" className="option-selected">
              {label ? `Locality` : "Select Locality"}
            </option>
            {localityList.map(option => (
              <option value={option._id} key={"loc" + option._id}>
                {capitalizeFirstLetter(option.label)}
              </option>
            ))}
          </select>
        </>
      ) : (
        <div className="multi-select">
          <Select
            value={selectedOptions}
            onChange={handleChange}
            defaultValue={localityList[0]}
            options={localityList}
            placeholder={"Select Locality"}
          />
        </div>
      )}
    </>
  );
};

export default SelectLocality;
