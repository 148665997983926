import React from "react";
import DocumentTitle from "react-document-title";
import BootstrapTable from "react-bootstrap-table-next";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import LoadingSpinner from "../../LoadingSpinner";
import { getErrorMessage } from "../../../services/utils";
import CustomePagination from "../../Common/Pagination";

class ModeratorAttendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msgData: { msg: "", showMsg: false, msgType: "error" },
      isLoading: false,
      moderatorList: [],
      activePage: 1,
      count: 0,
      itemsCountPerPage: 100,
      count: ""
    };
  }

  componentDidMount() {
    this.getModerator();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activePage } = this.state;
    if (activePage !== prevState.activePage) {
      this.getModerator();
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  getModerator = () => {
    const { activePage, itemsCountPerPage } = this.state;
    let url = `moderator-attendance?page=${activePage}&size=${itemsCountPerPage}`;
    JobHai.get(url)
      .then(response => {
        let data = response.data.data || [];
        this.setState({
          isLoading: false,
          moderatorList: data,
          count: data.length,
          msgData: { msg: "", showMsg: false, msgType: "" }
        });
      })
      .catch(error => {
        let errorMessage = "";
        errorMessage = getErrorMessage(error);
        this.setState({
          isLoading: false,
          msgData: { msg: errorMessage, showMsg: true, msgType: "error" }
        });
      });
  };

  render() {
    const {
      msgData,
      isLoading,
      moderatorList,
      activePage,
      itemsCountPerPage,
      count
    } = this.state;
    moderatorList.map(d => {
      d.moderatorName = (
        <>
          {d.moderator_name}{" "}
          {d.is_absent ? <span className="isAbsent" /> : null}
        </>
      );
      d.moderatorType = d.moderator_type ? d.moderator_type : "NA";
      return d;
    });

    const columns = [
      {
        text: "ID",
        dataField: "moderator_id",
        sort: true
      },
      {
        text: "Moderator Name",
        dataField: "moderatorName",
        sort: true
      },
      {
        text: "Moderator Type",
        dataField: "moderatorType",
        sort: true
      }
    ];
    const NoDataIndication = () => <div className="spinner">No Data Found</div>;
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <DocumentTitle title={"Job Hai CRM - Moderator Attendance"}>
          <div className="modAssignmentcontainer">
            <div className="admin-pannel">
              <div className="clr gap-20" />
              <PopupMessage data={msgData} click={this.handleClosePopup} />
              <div className="pageHeading">
                <h2>
                  Moderator Attendance
                  <div className="float-right">
                    <a href={"/analytics"}>Go Back</a>
                  </div>
                </h2>
              </div>
              <div className="whitebox table-responsive">
                <BootstrapTable
                  keyField="moderator_id"
                  data={moderatorList}
                  columns={columns}
                  hover
                  condensed
                  bordered={false}
                  loading={true}
                  rowClasses="listvalue"
                  headerClasses="listHeding"
                  classes="borderless"
                  noDataIndication={() => <NoDataIndication />}
                />
                <CustomePagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={count}
                  onPageChange={this.handlePageChange}
                  showPagination={count > 0 ? true : false}
                />
              </div>
            </div>
            <div className="clr gap-20"></div>
          </div>
        </DocumentTitle>
      );
    }
  }
}

export default ModeratorAttendance;
