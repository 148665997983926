import React from "react";
import { isEmpty } from "../../../services/utils";

const CustomTextField = props => {
  const {
    endAdornment,
    startAdornment,
    autoComplete = "new-password",
    textArea = false,
    name = "",
    value = "",
    isReadonly = false,
    maxLength = 200,
    textAreaMaxLength = null,
    placeholder = "",
    onChange,
    onBlur,
    rows = "2",
    isError = false,
    ...otherProps
  } = props;
  let className = isError ? `customInputClass borderRed` : "customInputClass";
  return (
    <>
      {textArea ? (
        <textarea
          className="customTextArea"
          autoComplete={autoComplete}
          name={name}
          value={!isEmpty(value) ? value : ""}
          placeholder={placeholder}
          onChange={onChange}
          rows={rows}
          maxLength={textAreaMaxLength}
          readOnly={isReadonly}
        />
      ) : (
        <div className={isReadonly ? "inputBox noPadding" : "inputBox"}>
          {startAdornment ? startAdornment : null}
          <input
            className={className}
            autoComplete={autoComplete}
            readOnly={isReadonly}
            name={name}
            value={!isEmpty(value) ? value : ""}
            maxLength={maxLength}
            onChange={onChange}
            placeholder={placeholder}
            onBlur={onBlur ? onBlur : null}
            {...otherProps}
          />
          {endAdornment ? endAdornment : null}
        </div>
      )}
    </>
  );
};

export default CustomTextField;
