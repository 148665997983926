import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  enterPriseLeadType,
  enterPriseLeadStatus,
  lcsLeadSource,
  leadPlatform
} from "../../constants/MetaData";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import { getFormattedValue, isObjectEmpty } from "../../services/utils";
import DownloadLeads from "./DownloadLeads";
import { ENTERPRISE_LEADS } from "../../routes/RouteConstants";

const LeadsFilter = props => {
  const {
    searchLeads,
    isDisabled,
    adminOnly,
    leadsList,
    activeBucket,
    leadModeratorList,
    callingAttempts,
    interviewCenterList,
    clientId,
    type
  } = props;
  const [filterItems, handleFormItems] = useState({
    startDate: "",
    endDate: "",
    city_id: "",
    locality_id: "",
    lead_type: "",
    lead_bucket: "",
    lead_status: "",
    lead_shared: "",
    phone: "",
    lead_user_id: "",
    moderator_id: "",
    interview_date: "",
    interview_center: "",
    follow_up_date: activeBucket === "Followup" ? new Date() : "",
    lead_source: "",
    source: ""
  });
  const [errorMsg, handleError] = useState("");
  const [isReset, setReset] = useState(false);
  const [isDownload, setDownload] = useState(false);
  useEffect(() => {
    const allowBucket = ["New", "Followup", 1, 2, 3, 4];
    if (activeBucket) {
      setReset(true);
      if (allowBucket.includes(activeBucket))
        handleDateChange("lead_bucket", activeBucket);
    }
  }, [activeBucket]);

  function handleChange(event) {
    let {
      target: { name, value }
    } = event;
    let tempObj = { ...filterItems };
    if (name === "phone") {
      value = getFormattedValue(value, true, true);
    }
    if (name === "lead_bucket") {
      if (value === "Followup" && !tempObj["follow_up_date"])
        tempObj["follow_up_date"] = new Date();
      else tempObj["follow_up_date"] = tempObj["follow_up_date"];
    }
    tempObj[name] = value;
    handleFormItems(tempObj);
    handleError("");
    setReset(true);
  }

  const handleDateChange = (fieldName, value) => {
    let tempObj = { ...filterItems };
    tempObj[fieldName] = value;
    handleFormItems(tempObj);
    handleError("");
    setReset(true);
  };

  function handleSearch(e) {
    e.preventDefault();
    const { startDate, endDate, phone } = filterItems;
    setDownload(false);
    const phoneNumberPattern = /^[5-9][0-9]{9}$/;
    if (phone && !phoneNumberPattern.test(phone)) {
      handleError("Please enter a valid phone number.");
      return;
    }
    if (isObjectEmpty(filterItems)) {
      handleError("Please select from filter list");
      return;
    }
    if (startDate > endDate) {
      handleError("Please enter start date is less than end date");
      return;
    }
    handleSearchAPI();
  }

  function handleSearchAPI(download = false) {
    let tempObj = { ...filterItems };
    const { startDate, endDate, interview_date, follow_up_date } = filterItems;
    tempObj["startDate"] = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    tempObj["endDate"] = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    tempObj["interview_date"] = interview_date
      ? moment(interview_date).format("YYYY-MM-DD")
      : null;
    tempObj["follow_up_date"] = follow_up_date
      ? moment(follow_up_date).format("YYYY-MM-DD")
      : null;
    searchLeads(tempObj, download);
  }

  function handleDownload() {
    setDownload(true);
    handleSearchAPI(true);
  }

  function resetFilter() {
    setReset(false);
    handleError("");
    handleFormItems({
      startDate: "",
      endDate: "",
      city_id: "",
      locality_id: "",
      lead_type: "",
      lead_bucket: "",
      lead_status: "",
      lead_shared: "",
      phone: "",
      lead_user_id: "",
      moderator_id: "",
      interview_date: "",
      interview_center: "",
      follow_up_date: "",
      lead_source: "",
      source: ""
    });
    searchLeads();
  }

  function renderCallAttemtList() {
    const callAttemptsElements = [];
    for (let i = 1; i < callingAttempts; i++) {
      callAttemptsElements.push(
        <option value={i} key={`lb${i}`}>
          Not Connected #{i}
        </option>
      );
    }
    return callAttemptsElements;
  }
  const {
    startDate,
    endDate,
    city_id,
    locality_id,
    lead_type,
    lead_bucket,
    lead_status,
    lead_shared,
    phone,
    lead_user_id,
    moderator_id,
    interview_date,
    interview_center,
    follow_up_date,
    lead_source,
    source
  } = filterItems;
  return (
    <div className="job_seeker recruiter-search">
      <form autoComplete="off" onSubmit={handleSearch} className="search">
        <div className="clr gap-20" />
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="phone"
                placeholder="Phone"
                value={phone}
                onChange={handleChange}
                maxLength="10"
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="lead_user_id"
                placeholder="Lead id"
                value={lead_user_id}
                onChange={handleChange}
                maxLength="10"
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="lead_type"
                value={lead_type}
                onChange={handleChange}
              >
                <option value="">Select Lead Type</option>
                {enterPriseLeadType.map((option, index) => (
                  <option value={option.value} key={"lt" + index}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <b
              className="downArrow"
              style={{ padding: "3px", marginTop: "13px" }}
            />
            <select
              className="form-control"
              name="lead_bucket"
              value={lead_bucket}
              onChange={handleChange}
            >
              <option value="">Select Lead Bucket</option>
              <option value="New">New</option>
              {renderCallAttemtList()}
              <option value="Followup">Follow Up</option>
            </select>
          </div>
          <div className="col-md-2">
            <SelectCity
              filedName={"city_id"}
              value={city_id}
              onChange={handleChange}
              label={false}
              allCity={true}
            />
          </div>
          <div className="col-md-2">
            <SelectLocality
              filedName={"locality_id"}
              value={locality_id}
              cityId={city_id}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          {adminOnly ? (
            <>
              <div className="col-md-2">
                <div className="form-group">
                  <b
                    className="downArrow"
                    style={{ padding: "3px", marginTop: "13px" }}
                  />
                  <select
                    className="form-control"
                    name="lead_status"
                    value={lead_status}
                    onChange={handleChange}
                  >
                    <option value="">Select Lead Status</option>
                    {enterPriseLeadStatus.map((option, index) => (
                      <option value={option.id} key={"ls" + index}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <b
                  className="downArrow"
                  style={{ padding: "3px", marginTop: "13px" }}
                />
                <select
                  className="form-control"
                  name="lead_shared"
                  value={lead_shared}
                  onChange={handleChange}
                >
                  <option value="">Lead Shared</option>
                  <option value="Shared">Yes</option>
                  <option value="Assigned,Closed">No</option>
                </select>
              </div>
              <div className="col-md-2">
                <b
                  className="downArrow"
                  style={{ padding: "3px", marginTop: "13px" }}
                />
                <select
                  className="form-control"
                  name="moderator_id"
                  value={moderator_id}
                  onChange={handleChange}
                >
                  <option value="">Select Moderator</option>
                  {leadModeratorList.map(item => {
                    return (
                      <option
                        value={item.moderator_id}
                        key={"mod" + item.moderator_id}
                      >
                        {item.moderator_name}
                      </option>
                    );
                  })}
                  <option value="unassign">Unassign</option>
                </select>
              </div>
            </>
          ) : null}
          <div className="col-md-2">
            <DatePicker
              selected={startDate}
              onChange={e => {
                handleDateChange("startDate", e);
              }}
              placeholderText="Start Date"
              dateFormat="yyyy-MM-dd"
              className="form-control"
              maxDate={new Date()}
            />
          </div>
          <div className="col-md-2">
            <DatePicker
              selected={endDate}
              onChange={e => {
                handleDateChange("endDate", e);
              }}
              placeholderText="End Date"
              dateFormat="yyyy-MM-dd"
              className="form-control"
              maxDate={new Date()}
              minDate={startDate}
            />
          </div>

          <div className="col-md-2">
            <DatePicker
              selected={interview_date}
              onChange={e => {
                handleDateChange("interview_date", e);
              }}
              placeholderText="Interview Date"
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="source"
                value={source}
                onChange={handleChange}
              >
                <option value="">Select Platform</option>
                {leadPlatform.map(option => (
                  <option value={option.id} key={"platform" + option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {lead_bucket === "Followup" ? (
            <div className="col-md-2">
              <DatePicker
                selected={follow_up_date}
                onChange={e => {
                  handleDateChange("follow_up_date", e);
                }}
                placeholderText="Follow Up Date"
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </div>
          ) : null}
          {interviewCenterList.length ? (
            <div className="col-md-2">
              <div className="form-group">
                <b
                  className="downArrow"
                  style={{ padding: "3px", marginTop: "13px" }}
                />
                <select
                  className="form-control"
                  name="interview_center"
                  value={interview_center}
                  onChange={handleChange}
                >
                  <option value="">Select Interview center</option>
                  {interviewCenterList.map((option, index) => (
                    <option
                      value={option.value}
                      key={"interview-center" + index}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : null}
          <div className="col-md-2">
            <div className="form-group">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="lead_source"
                value={lead_source}
                onChange={handleChange}
              >
                <option value="">Select Lead Source</option>
                {lcsLeadSource.map((option, index) => (
                  <option value={option.value} key={"lt" + index}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="clr gap-20" />
          <div className="col-md-12 ">
            <div className="float-right job_seeker form-group">
              {errorMsg ? (
                <span className="text-danger">{errorMsg} </span>
              ) : null}
              {isReset ? (
                <a
                  href={`${ENTERPRISE_LEADS}/${type}/?client=${clientId}`}
                  rel="noopener noreferrer"
                  className="download_link"
                >
                  Reset Filter
                </a>
              ) : null}
              {isDisabled && !isDownload ? (
                <button className="btn btn-primary disable" disabled>
                  SEARCHING ...
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  disabled={isDisabled}
                  onClick={handleSearch}
                >
                  SEARCH
                </button>
              )}
              {adminOnly && (
                <DownloadLeads
                  onClick={handleDownload}
                  isDisabled={isDisabled}
                  leadsList={leadsList}
                  isDownload={isDownload}
                  clientId={clientId}
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default LeadsFilter;
