import React from "react";
import JobHai from "../../api/JobHai";
import MetaData from "../../constants/MetaData";
import {
  getErrorMessage,
  getFormattedValue,
  getActiveItemList,
  isEditedFields
} from "../../services/utils";
import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import CustomSelect from "../Common/Form/CustomSelect";
import Chips from "../Common/Chips";
import UploadFiles from "../Common/Form/UploadFiles";
import { getLocalStorage, setLocalStorage } from "../../services/localStorage";

class CompanyDocuments extends React.Component {
  state = {
    docList: [],
    isEntityDocUpload: false
  };

  componentDidMount() {
    let crmDoc = JSON.parse(getLocalStorage("crmAllowDocument")) || [];
    if (crmDoc.length > 0) {
      this.setState({
        docList: crmDoc
      });
    } else {
      this.getDocumentsList();
    }
    this.setIntialState();
  }

  componentDidUpdate(prevProps, prevState) {
    const { companyDocuments, getDocFieldData } = this.props;
    const { activeEentityDoc, entityDocfileName } = this.state;
    if (companyDocuments !== prevProps.companyDocuments) {
      this.setIntialState();
    }
    if (activeEentityDoc !== prevState.activeEentityDoc) {
      this.setState({
        isEntityDocUpload: activeEentityDoc.id !== "Not required"
      });
      if (activeEentityDoc.id === "Not required" && entityDocfileName) {
        this.setState({ entityDocfileName: "" });
        getDocFieldData("entityDocFile", "");
      }
    }
  }

  setIntialState = () => {
    const { companyDocuments } = this.props;
    const { entity_doc_status = "Not required" } = companyDocuments;
    this.setState({
      fileName: "",
      document_url: companyDocuments.document_url,
      document_type: companyDocuments.document_type,
      document_no: companyDocuments.document_no,
      document_status: companyDocuments.document_status,
      doc_remarks: companyDocuments.doc_remarks,
      reason: companyDocuments.reason,
      entity_doc_status: companyDocuments.entity_doc_status,
      entity_document_url: companyDocuments.entity_document_url,
      isUploadError: false,
      activeEentityDoc: { id: entity_doc_status, label: entity_doc_status },
      isEntityDocUpload: entity_doc_status !== "Not required",
      entityDocfileName: ""
    });
  };

  updateMessage = msg => {
    this.props.statusMessage(msg, 1);
  };

  handleChange = event => {
    let { name, value } = event.target;
    value = getFormattedValue(value);
    this.updateMessage(``);
    if (name === "document_no") {
      value = value.replace(/[^a-z0-9]/gi, "").toUpperCase();
    }
    if (name === "document_type") {
      this.setState({
        document_no: ""
      });
      this.props.getDocFieldData("document_no", "");
    }
    this.setState({
      [name]: value
    });
    this.props.getDocFieldData(name, value);
  };

  handleFileUpload = event => {
    const { type = "", files = [], name = "" } = event.target;
    if (type === "file" && files.length) {
      let fieldValue = files[0];
      const fileSize = fieldValue.size;
      let fileName = fieldValue.name;
      var reg = /(.*?)\.(jpg|jpeg|png|pdf)$/;
      if (!fileName.match(reg)) {
        this.updateMessage(
          "Please upload valid file. Only extensions .jpeg/.jpg/.png/pdf allowed only."
        );
      } else if (fileSize && fileSize > 2097152) {
        this.updateMessage("Please upload file size below 2MB");
      } else {
        this.updateMessage("");
      }
      if (name === "entityDocFile") {
        this.setState({ entityDocfileName: fileName });
        this.props.getDocFieldData("entityDocFile", files[0]);
      } else {
        this.setState({ fileName });
        this.props.getDocFieldData("file", files[0]);
      }
    }
  };

  getDocumentsList = () => {
    JobHai.get("../v2/metadata/v2/list-allowed-documents")
      .then(response => {
        const docList = response.data.data;
        this.setState({
          docList: docList
        });
        setLocalStorage("crmAllowDocument", JSON.stringify(docList));
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.updateMessage(msg);
      });
  };

  handleChips = (event, fieldName, item) => {
    let id = item.id;
    const { getDocFieldData } = this.props;
    const { activeEentityDoc } = this.state;
    if (fieldName === "document_status" && id !== "rejected") {
      this.setState({ reason: "" });
      getDocFieldData("reason", "");
    }
    this.setState({
      [fieldName]: id
    });
    if (fieldName === "entity_doc_status") {
      this.setState({
        activeEentityDoc: activeEentityDoc.id ? [] : item
      });
      getDocFieldData(fieldName, activeEentityDoc.id ? "" : id);
    } else {
      getDocFieldData(fieldName, id);
    }
  };

  render() {
    const {
      docList,
      document_type,
      document_no,
      document_status = "",
      document_url,
      doc_remarks,
      reason,
      activeEentityDoc,
      fileName = "",
      isEntityDocUpload,
      entity_document_url,
      entityDocfileName = ""
    } = this.state;
    const {
      isAgentJob = false,
      documentRrejectedReason,
      editedFields,
      recentDocData,
      recId

    } = this.props;
    let docRejectedReasonList = documentRrejectedReason.map(item => ({
      value: item.id,
      label: item.label
    }));
    const selectedItem =
      getActiveItemList(docList, document_type, "type") || {};
    const { websiteUrl = "", validation = [] } = selectedItem;
    let docTypeList = docList.map(item => ({
      value: item.type,
      label: item.title
    }));
    docTypeList.push({
      value: "OTHER_COMPANY_DOCUMENT",
      label: "Other Company Document"
    });
    docTypeList.push({
      value: "TEST_DOCUMENT",
      label: "Test Document"
    });
    if (isAgentJob) {
      docTypeList.push({
        value: "SHOP_IMAGE",
        label: "Shop Image"
      });
    }
    const isReadonly =
      !validation.length && document_type !== "OTHER_COMPANY_DOCUMENT";
    let isDisabled =
      !document_type || !document_no || (!fileName && !document_url);
    if (
      document_type &&
      document_type !== "OTHER_COMPANY_DOCUMENT" &&
      (fileName || document_url) &&
      isDisabled &&
      !validation.length
    )
      isDisabled = false;
    const isDocumentEdited =
      isEditedFields(editedFields, "document_url") ||
      isEditedFields(editedFields, "document_no") ||
      isEditedFields(editedFields, "document_type") ||
      isEditedFields(editedFields, "document_status");
    return (
      <>
        <Heading heading="Company Documents" isEdited={isDocumentEdited} />
        <div className="formRow">
          <div className="left">
            <Label
              lable="Document"
              isOptional={true}
              optionalTitle="Maximum file size 2 MB"
              isEdited={isEditedFields(editedFields, "document_url")}
            />
            <UploadFiles
              fileUrl={document_url}
              fileName={fileName}
              fieldName="document"
              handleFileUpload={this.handleFileUpload}
              disabled={document_type === "TEST_DOCUMENT"}
            />
            {recentDocData?.document_url ? (
              <div className="recDetails">
                Last Document:
                <span>
                  <a
                    href={recentDocData?.document_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={"Click here to Last Document"}
                  >
                    Last Document Link
                  </a>
                </span>
              </div>
            ) : null}
          </div>
          <div className="right">
            <Label
              lable="Document Number"
              isEdited={isEditedFields(editedFields, "document_no")}
            />
            <CustomTextField
              name="document_no"
              value={document_type==="TEST_DOCUMENT"?`TESTDOC${recId}`: document_no}
              onChange={this.handleChange}
              maxLength={30}
              endAdornment={
                websiteUrl ? (
                  <a
                    className="reviewOnsite"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={websiteUrl}
                  >
                    <img
                      src="/images/review.svg"
                      alt="review"
                      width="14"
                      height="14"
                    />
                    Review on site
                  </a>
                ) : null
              }
              isReadonly={isReadonly}
            />
            {recentDocData?.document_no ? (
              <div className="recDetails">
                Last Document No: <span>{recentDocData?.document_no}</span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="formRow">
          <div className="left">
            <Label
              lable="Document Type"
              isEdited={isEditedFields(editedFields, "document_type")}
            />
            <CustomSelect
              name="document_type"
              value={document_type}
              onChange={this.handleChange}
              optionsList={docTypeList}
            />
            {recentDocData?.document_type ? (
              <div className="recDetails">
                Last Document Type:
                <span>{recentDocData?.document_type}</span>
              </div>
            ) : null}
          </div>
          <div className="right">
            <Label
              lable="Document Status"
              isEdited={isEditedFields(editedFields, "document_status")}
            />
            <Chips
              chips={MetaData.companyDocumentStatus}
              activeChips={[document_status]}
              onClick={this.handleChips}
              isMulti={false}
              fieldName="document_status"
              isDisabled={isDisabled}
            />
            {recentDocData?.document_status ? (
              <div className="recDetails">
                Last Document Status:
                <span>{recentDocData?.document_status}</span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="formRow">
          <div className="left">
            <Label lable="Remarks" isOptional={true} />
            <CustomTextField
              name="doc_remarks"
              value={doc_remarks}
              onChange={this.handleChange}
              placeholder="Leave your remarks here..."
            />
            {recentDocData?.doc_remarks ? (
              <div className="recDetails">
                Last Remarks:
                <span>{recentDocData?.doc_remarks}</span>
              </div>
            ) : null}
          </div>
          <div className="right">
            {document_status === "rejected" ? (
              <>
                <Label lable="Rejected Reason" />
                <CustomSelect
                  name="reason"
                  value={reason}
                  onChange={this.handleChange}
                  optionsList={docRejectedReasonList}
                />
              </>
            ) : null}
          </div>
        </div>
        {document_status !== "rejected" ? (
          <>
            <div className="formRow">
              <div className="fullwidth">
                <Label lable="Employment Proof Status" />
                <Chips
                  chips={MetaData.entityDocStatusNew}
                  activeChips={activeEentityDoc}
                  onClick={this.handleChips}
                  isMulti={false}
                  fieldName="entity_doc_status"
                  isClose={true}
                />
                {recentDocData?.entity_doc_status ? (
                  <div className="recDetails">
                    Last Status:
                    <span>{recentDocData?.entity_doc_status}</span>
                  </div>
                ) : null}
              </div>
            </div>
            {isEntityDocUpload ? (
              <div className="formRow">
                <div className="left">
                  <Label
                    lable="Employment Proof"
                    isOptional={true}
                    optionalTitle="Maximum file size 2 MB"
                  />
                  <UploadFiles
                    fileUrl={entity_document_url}
                    fileName={entityDocfileName}
                    fieldName="entityDocFile"
                    handleFileUpload={this.handleFileUpload}
                  />
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

export default CompanyDocuments;
