import React from "react";
import { useController } from "react-hook-form";
import { Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";

const MaterialCheckBoxGroup = ({ name, control, options, ...rest }) => {
  const {
    field: { value, onChange }
  } = useController({
    name,
    control
  });

  const handleCheckboxChange = optionId => event => {
    const updatedValue = event.target.checked
      ? [...value, optionId]
      : value.filter(v => v !== optionId);
    onChange(updatedValue);
  };

  return (
    <FormGroup>
      {options.map(option => (
        <FormControlLabel
          key={option.option_id}
          control={
            <Checkbox
              checked={value.includes(option.option_id)}
              onChange={handleCheckboxChange(option.option_id)}
              {...rest}
            />
          }
          label={option.option_label.en}
        />
      ))}
    </FormGroup>
  );
};

export default MaterialCheckBoxGroup;
