import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import {
  getLeads,
  getBucketCount,
  getInsideSalesModerators
} from "../services/insideSales";

const defaultFilters = {
  recruiterIds: "",
  recruiterName: "",
  companyId: "",
  companyName: "",
  recruiterPhone: "",
  assignedDate: { from: "", to: "" },
  followupDate: { from: "", to: "" },
  churnDate: { from: "", to: "" },
  lastPurchaseAttemptDate: "",
  unallocationDate: { from: "", to: "" },
  saleDate: { from: "", to: "" },
  jobCount: { min: "", max: "" },
  creditsPurchased: { min: "", max: "" },
  creditsAvailable: { min: "", max: "" },
  leadSourceIds: "",
  moderatorIds: ""
};
const defaultLeadType = "ALL";
const defaultBucketIds = ["ALL"];
const pageSize = 20;

const useInsideLeadStore = create(
  persist(
    (set, get) => ({
      defaultFilters,
      defaultLeadType,
      activeBucket: "ALL", // Add active bucket state
      initialFilters: { ...defaultFilters },
      defaultBucketIds,
      payloadData: {
        page: 1,
        size: pageSize,
        filters: {
          bucketIds: defaultBucketIds
        }
      },
      fetchState: "LOADING",
      metaDataFetchState: "",
      leadData: {},
      bucketCountData: {},
      errorMessage: null,
      moderatorList: [],
      setActiveBucket: bucketId => set({ activeBucket: bucketId }), // Method to set active bucket
      fetchData: async (payloadData, resetCountApi) => {
        set({ fetchState: "LOADING" });
        try {
          const requests = [getLeads(payloadData)];
          if (resetCountApi) {
            requests.push(getBucketCount(payloadData));
          }

          const responses = await Promise.all(requests);
          const [responseLeadData, responseCount] = responses;

          set(state => ({
            leadData: responseLeadData,
            fetchState: "SUCCESS",
            bucketCountData: resetCountApi
              ? responseCount
              : state.bucketCountData,
            errorMessage: null
          }));
        } catch (err) {
          console.error("Error fetching data:", err);
          set({
            fetchState: "ERROR",
            leadData: {},
            bucketCountData: {},
            errorMessage: err.message || ""
          });
        }
      },
      fetchModeratorData: async () => {
        try {
          if (get().metaDataFetchState === "SUCCESS") {
            return;
          }
          set({ fetchState: "LOADING" });

          const responseModerator = await getInsideSalesModerators();
          set({
            moderatorList: responseModerator?.subordinateModerators || [],
            metaDataFetchState: "SUCCESS",
            errorMessage: null
          });
        } catch (err) {
          console.error("Error fetching data:", err);
          set({
            metaDataFetchState: "ERROR",
            errorMessage: err.message || "",
            moderatorList: []
          });
        }
      },
      updateLeadsFilterData: (newFilterData, restParams = {}) => {
        const { resetCountApi = false, isNewFilter = false } = restParams;
        set(state => ({
          payloadData: {
            size: pageSize,
            page: 1,
            filters: isNewFilter
              ? { ...newFilterData }
              : {
                  ...state.payloadData.filters,
                  ...newFilterData
                }
          }
        }));
        get().fetchData(
          {
            ...get().payloadData,
            filters: {
              ...get().payloadData.filters,
              ...newFilterData
            }
          },
          resetCountApi
        );
      },
      updatePageNumber: page => {
        set(state => ({
          payloadData: { ...state.payloadData, page }
        }));
        get().fetchData({
          ...get().payloadData,
          page
        });
      },
      resetFilterData: () => {
        set(state => ({
          payloadData: {
            ...state.payloadData,
            filters: {}
          }
        }));
        get().fetchData(get().payloadData);
      },
      updateInitialFilters: newFilters => {
        set(state => ({
          initialFilters: { ...state.initialFilters, ...newFilters }
        }));
      },
      setLeadType: leadType => set({ defaultLeadType: leadType })
    }),
    {
      name: "lfmsLeadData",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

export default useInsideLeadStore;
