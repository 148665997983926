import React from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import ViewRemarks from "./ViewRemarks";

const ViewJobLogs = props => {
  const { logHistory = [] } = props;
  logHistory.map((d, index) => {
    d.srNo = index + 1;
    d.createdDate = moment(d.createdAt).format("DD/MM/YYYY h:m:s");
    d.moderatorName = d.moderator_name ? d.moderator_name : d.actioned_by;
    d.moderatorRemarks = <ViewRemarks remarks={d.remarks} />;
    return d;
  });

  const columns = [
    {
      text: "S.No.",
      dataField: "srNo"
    },
    {
      text: "Date & Time",
      dataField: "createdDate"
    },
    {
      text: "Moderator Name",
      dataField: "moderatorName",
      sort: true
    },
    {
      text: "Jobs Calling Status",
      dataField: "job_calling_status"
    },
    {
      text: "Remarks",
      dataField: "moderatorRemarks"
    },
    {
      text: "Job Approval Status",
      dataField: "job_approval_status"
    },
    {
      text: "Job Live Status",
      dataField: "job_live_status"
    }
  ];
  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  return (
    <div className="view-history">
      <BootstrapTable
        keyField="log_id"
        data={logHistory}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default ViewJobLogs;
