import React, { useState } from "react";
import {
  crmFeaturesList,
  getFeaturesOptionIds,
  getLabelFromId
} from "../../constants/ModeratorAccessConstants";
import AccessPopup from "./AccessPopup";

const AccessLevel = ({ moderatorData, callBack }) => {
  const { access_json } = moderatorData;
  const allowAccess = access_json?.length ? access_json : [];

  const [showAll, setShowAll] = useState(false);
  const [openAccess, setOpenAccess] = useState(false);

  const getOptionIds = getFeaturesOptionIds(crmFeaturesList);
  const allowAccessUpdated = allowAccess.filter(item =>
    getOptionIds.includes(item.label)
  );

  const allowAccessWithId = allowAccessUpdated.map(item => ({
    ...item,
    id: item.label,
    label: getLabelFromId(item.label, crmFeaturesList)
  }));

  const handleShowMore = () => {
    setShowAll(!showAll); // Toggle between true and false
  };

  const handleAccess = () => {
    setOpenAccess(true);
  };

  const handleClose = () => {
    setOpenAccess(false);
  };

  const defaultCount = 15;
  return (
    <div className="access-level-container">
      {allowAccessWithId
        .slice(0, showAll ? allowAccessWithId.length : defaultCount)
        .map((item, index) => (
          <span key={index}>
            {item.label}
            {index !== allowAccessWithId.length - 1 && ""}
          </span>
        ))}
      <div className="read-more-btn">
        {allowAccessWithId.length > defaultCount ? (
          <span onClick={handleShowMore}>View {showAll ? `Less` : `More`}</span>
        ) : null}
        <span onClick={handleAccess}>Update Access</span>
      </div>
      {openAccess && (
        <AccessPopup
          handleClose={handleClose}
          data={moderatorData}
          activeAccess={allowAccessWithId}
          callBack={callBack}
        />
      )}
    </div>
  );
};

export default AccessLevel;
