import Modal from "react-bootstrap/Modal";

const ModalWrapper = ({
  showModal,
  h1 = "Are you sure you want to proceed?",
  children,
  primaryCTA = "Save",
  secondaryCTA = "Cancel",
  handleSave,
  isDisabled = false,
  closeModal
}) => {
  return (
    <Modal show={showModal}>
      <Modal.Body>
        <div className="clr">
          <b>
            {h1} ​<br />
          </b>
        </div>
        <div className="gap-20" />
        <div className="clr">{children}</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={handleSave}
          disabled={isDisabled}
        >
          {primaryCTA}
        </button>
        <button className="btn btn-secondary" onClick={closeModal}>
          {secondaryCTA}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWrapper;
