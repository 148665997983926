import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CampaignCreationV2 from "./CampaignCreationV2";
import HeadingRow from "../Common/HeadingRow";
import CampaignTabs from "./CampaignTabs";
import { checkPermission, getValueFromURL } from "../../services/utils";
import NoAccess from "../Common/NoAccess";
import LoadingSpinner from "../LoadingSpinner";
import { VIEW_CAMPAIGN } from "../../routes/RouteConstants";
import useLcsCampaignDetails from "../../zustandService/useLcsCampaignDetails";

const AddEditCampaign = () => {
  const isAccess =
    checkPermission("CampaignManagement") ||
    checkPermission("CampaignManagementAdmin");

  const { id } = useParams();
  const duplicateCampaignId = getValueFromURL("duplicateCampaignId");
  const campaignId = id || duplicateCampaignId;
  const [activeTab, setActiveTab] = useState("CAMPAIGN");

  const {
    fetchCampaignData,
    fetchState: { campaign: campaignFetchState, metadata: metadataFetchState },
    error: { campaign: campaignError, metadata: metadataError },
    fetchCampaignMetaData
  } = useLcsCampaignDetails(state => ({
    fetchCampaignData: state.fetchCampaignData,
    fetchState: state.fetchState,
    error: state.error,
    fetchCampaignMetaData: state.fetchCampaignMetaData
  }));

  useEffect(() => {
    if (isAccess) {
      fetchCampaignMetaData();
      if (campaignId) {
        fetchCampaignData(campaignId);
      }
    }
  }, [isAccess, campaignId]);

  const handleTabClick = tabId => {
    setActiveTab(tabId);
  };

  let heading = "Create New Campaign";
  if (id) heading = "Edit Campaign";
  else if (duplicateCampaignId)
    heading = `Create Duplicate Campaign From ${duplicateCampaignId}`;

  return (
    <>
      <HeadingRow heading={"Campaign Manager"} goBackURL={VIEW_CAMPAIGN} />
      <div className="whitebox">
        {isAccess ? (
          <>
            <CampaignTabs callBack={handleTabClick} activeTab={activeTab} />
            {campaignFetchState === "ERROR" && (
              <div className="text-danger">{campaignError}</div>
            )}
            {metadataFetchState === "ERROR" && (
              <div className="text-danger">{metadataError}</div>
            )}
            {campaignFetchState === "LOADING" ||
            metadataFetchState === "LOADING" ? (
              <LoadingSpinner />
            ) : (
              <CampaignCreationV2 activeTab={activeTab} />
            )}
          </>
        ) : (
          <>
            <div className="gap-20" />
            <NoAccess />
          </>
        )}
      </div>
    </>
  );
};

export default AddEditCampaign;
