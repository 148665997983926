import React from "react";
import LoadingSpinner from "../../LoadingSpinner";

const TatSummary = props => {
  const { reportSummary, loading, myPerformance, onClick } = props;
  const {
    total_pending_reports_for_action,
    total_reports_actioned_today,
    total_reports_assigned,
    total_reports_resolved,
    pending_reports,
    median_tat_first_action
  } = reportSummary;
  return (
    <>
      <div className="row job_seeker form-group">
        <div className="col-md-12 form-group">
          <div className="float-right">
            <div className="download_link" onClick={onClick}>
              My Performance
            </div>
          </div>
        </div>
      </div>
      {myPerformance ? (
        <div className="row sortingWrap">
          <table
            width="100%"
            border="0"
            cellPadding="4"
            cellSpacing="10"
            className="table table-hover table-condensed borderless1 summeryTable"
          >
            <tbody>
              {loading ? (
                <tr>
                  <td>
                    <LoadingSpinner key="loading" />
                  </td>
                </tr>
              ) : (
                <>
                  <tr>
                    <th>Total Pending Reports For Action (Assigned Any day)</th>
                    <th>Total Reports Actioned Today (Assigned Any day)</th>
                    <th>Total Reports (Assigned Today)</th>
                    <th>Total Reports Resolved (Assigned Today)</th>
                    <th>Pending Reports (Assigned Today)</th>
                    <th>Median TAT 1st Action (Sun-Sat)</th>
                  </tr>
                  <tr>
                    <td>{total_pending_reports_for_action}</td>
                    <td>{total_reports_actioned_today}</td>
                    <td>{total_reports_assigned}</td>
                    <td>{total_reports_resolved}</td>
                    <td>{pending_reports}</td>
                    <td>{median_tat_first_action}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  );
};
export default TatSummary;
