import React, { useState, useEffect } from "react";
import DocumentTitle from "react-document-title";
import { FormGroup, FormControl } from "react-bootstrap";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import { getErrorMessage } from "../../services/utils";
import { setLocalStorage, getLocalStorage } from "../../services/localStorage";
import { setCookie } from "../../services/cookie";
import { JOBS } from "../../routes/RouteConstants";
import { getCrmMetaData } from "../../services/metadataApiServices";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msgData, setMsgData] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });
  const [moderatorList, setModeratorList] = useState([]);
  const [proxyModeratorId, setProxyModeratorId] = useState("");

  useEffect(() => {
    const token = getLocalStorage("token");
    if (token) {
      window.location = JOBS;
    }
    getMetaData();
  }, []);

  const validateForm = () => {
    const reg =
      /^([a-zA-Z0-9])+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,4})+$/;
    const emailValid = reg.test(email);
    return email && password.length > 0 && emailValid;
  };

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
      setProxyModeratorId("");
    }
    if (name === "password") {
      setPassword(value);
    }
    if (name === "proxyModeratorId") {
      setProxyModeratorId(value);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const params = { email, password };
    try {
      const response = await JobHai.post(
        process.env.REACT_APP_DEV_LOGIN_URL + "/crm-login",
        params
      );
      const data = response.data.data;
      const {
        token = "",
        moderator_id = "",
        moderator_name = "",
        lms_user_level = ""
      } = data;
      setMsgData({
        msg: "Login Successful ....",
        showMsg: true,
        msgType: "success"
      });
      const userData = {
        name: moderator_name || email.split("@")[0],
        lms_user_level
      };
      Object.assign(data, userData);
      setLocalStorage("userData", JSON.stringify(data));
      setLocalStorage("token", token);
      if (proxyModeratorId) {
        const list = moderatorList.filter(
          item => item.id === Number(proxyModeratorId)
        );
        // 0.375 =  9 hours
        setCookie("proxy_moderator_id", proxyModeratorId, 0.375);
        setCookie("proxy_moderator_name", list[0]?.name, 0.375);
      }
      setCookie("moderator_id", moderator_id);
      window.scrollTo(0, 0);
      window.location = JOBS;
    } catch (error) {
      const msg = getErrorMessage(error);
      setMsgData({ msg, showMsg: true, msgType: "error" });
      window.scrollTo(0, 0);
    }
  };

  const getMetaData = async () => {
    try {
      const response = await getCrmMetaData();
      const { proxy_moderators = [] } = response;
      setModeratorList(proxy_moderators);
    } catch (error) {
      const msg = getErrorMessage(error);
      setMsgData({ msg, showMsg: true, msgType: "error" });
    }
  };

  let proxyList = moderatorList;
  if (email) {
    proxyList = moderatorList.filter(
      item => item.email.toLowerCase() !== email.toLowerCase()
    );
  }

  return (
    <DocumentTitle title={`Login - Job Hai CRM`}>
      <div className="Login">
        <div className="clr gap-20"></div>
        <div className="clr gap-20"></div>
        <div className="pageHeading row">
          <h2>Login</h2>
        </div>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
        <div className="clr gap-20"></div>
        <div className="whiteCard loginWrap col-md-6">
          <div className="container-fluid">
            <PopupMessage data={msgData} />
            <div className="gap-10"></div>
            <form onSubmit={handleSubmit} autoComplete="off">
              <FormGroup controlId="email">
                <label>Email</label>
                <FormControl
                  autoFocus
                  type="text"
                  value={email}
                  name="email"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup controlId="password">
                <label>Password</label>
                <FormControl
                  value={password}
                  onChange={handleChange}
                  type="password"
                  name="password"
                />
              </FormGroup>

              <FormGroup>
                <b
                  className="downArrow"
                  style={{ right: "50px", marginTop: "8px" }}
                ></b>
                <select
                  className="form-control"
                  value={proxyModeratorId}
                  name="proxyModeratorId"
                  onChange={handleChange}
                  disabled={!validateForm()}
                >
                  <option value="">Select Proxy For</option>
                  {proxyList.map(option => (
                    <option value={option.id} key={`modlist${option.id}`}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
              <button
                className="bttn txt-align-right btn-primary custom-btn"
                disabled={!validateForm()}
                type="submit"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export { Login };
