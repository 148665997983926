import React from "react";
import moment from "moment";
import MetaData from "../../constants/MetaData";
import DatePicker from "react-datepicker";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";

class JobSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      job_id: "",
      job_title: "",
      full_name: "",
      phone: "",
      email: "",
      company_name: "",
      gender_req: "",
      minSal: "",
      maxSal: "",
      category_id: "",
      minExp: "",
      maxExp: "",
      job_type: "",
      job_city_id: "",
      job_locality_id: "",
      latitude: "",
      longitude: "",
      distance: "",
      source: "",
      edate: "",
      sdate: "",
      qualification: "",
      msgData: { msg: "", showMsg: false, msgType: "error" },
      localityList: [],
      shift_type: "",
      is_auto_live: "",
      jobPlan: ""
    };
  }

  handleChange = event => {
    if (event.target.name === "job_city_id") {
      this.setState({ job_locality_id: "", latitude: "", longitude: "" });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  updateMessage = (msg, flag) => {
    this.props.statusMessage(msg, flag);
  };

  searchJobs = e => {
    e.preventDefault();
    const {
      job_id,
      job_title,
      full_name,
      phone,
      email,
      company_name,
      gender_req,
      minSal,
      maxSal,
      category_id,
      minExp,
      maxExp,
      job_type,
      job_city_id,
      job_locality_id,
      latitude,
      longitude,
      distance,
      source,
      qualification,
      shift_type,
      is_auto_live,
      sdate,
      edate,
      jobPlan
    } = this.state;
    const isAutoLive =
      is_auto_live === "Yes" ? true : is_auto_live === "No" ? false : "";
    const obj = {
      job_id,
      job_title,
      full_name,
      phone,
      email,
      company_name,
      gender_req,
      minSal,
      maxSal,
      category_id,
      minExp,
      maxExp,
      job_type,
      job_city_id,
      job_locality_id,
      latitude,
      longitude,
      distance,
      source,
      minimum_qualification: qualification,
      shift_type,
      is_auto_live: isAutoLive,
      made_live_date_start: sdate ? moment(sdate).format("YYYY-MM-DD") : "",
      made_live_date_end: edate ? moment(edate).format("YYYY-MM-DD") : "",
      jobPlan
    };

    if (obj.distance !== "" && obj.job_locality_id === "") {
      this.updateMessage("Please select locality to use distance filter.", 1);
      return;
    }
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        delete obj[key];
      }
    });
    this.props.searchData(obj);
  };

  render() {
    const { isButtonDisabled } = this.props;
    const jobCategories = this.props.cat ? this.props.cat : [];
    const { jobPlan } = this.setState;
    return (
      <div className="job_seeker">
        <div className="gap-20"></div>
        <div className="gap-20"></div>
        <form autoComplete="off" onSubmit={this.searchJobs} className="search">
          <div className="row">
            <div className="col-md-2">
              <input
                className="form-control"
                type="text"
                name="job_id"
                placeholder="Job ID"
                value={this.state.job_id}
                onChange={event =>
                  this.setState({
                    job_id: event.target.value.replace(/\D/, "")
                  })
                }
                maxLength="10"
              />
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="job_title"
                  placeholder="Job Title"
                  value={this.state.job_title}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="full_name"
                  placeholder="Recruiter Name"
                  value={this.state.full_name}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  placeholder="Recruiter Mobile no"
                  value={this.state.phone}
                  onChange={event =>
                    this.setState({
                      phone: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  placeholder="Recruiter Email ID"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="company_name"
                  value={this.state.company_name}
                  placeholder="Company Name"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="gender_req"
                  value={this.state.gender_req}
                  onChange={this.handleChange}
                >
                  <option value="" className="option-selected">
                    Select Gender
                  </option>
                  {MetaData.gender.map(option => (
                    <option value={option} key={"g" + option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <input
                type="text"
                className="form-control"
                placeholder="Min Salary"
                name="minSal"
                value={this.state.minSal}
                onChange={event =>
                  this.setState({
                    minSal: event.target.value.replace(/\D/, "")
                  })
                }
                maxLength="8"
              />
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Max Salary"
                  name="maxSal"
                  value={this.state.maxSal}
                  onChange={event =>
                    this.setState({
                      maxSal: event.target.value.replace(/\D/, "")
                    })
                  }
                  maxLength="8"
                />
              </div>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="category_id"
                value={this.state.category_id}
                onChange={this.handleChange}
              >
                <option value="" className="option-selected">
                  Select Job Category
                </option>
                {jobCategories.map(option => (
                  <option value={option.id} key={"cat" + option.id}>
                    {option.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <input
                type="text"
                className="form-control"
                placeholder="Min Exp"
                name="minExp"
                value={this.state.minExp}
                onChange={event =>
                  this.setState({
                    minExp: event.target.value.replace(/\D/, "")
                  })
                }
                maxLength="8"
              />
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Max Exp"
                  name="maxExp"
                  value={this.state.maxExp}
                  onChange={event =>
                    this.setState({
                      maxExp: event.target.value.replace(/\D/, "")
                    })
                  }
                  maxLength="8"
                />
              </div>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="job_type"
                value={this.state.job_type}
                onChange={this.handleChange}
              >
                <option value="">Select Job Type</option>
                {MetaData.job_type.map(option => (
                  <option value={option} key={"job_type" + option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <SelectCity
                filedName={"job_city_id"}
                value={this.state.job_city_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-2">
              <SelectLocality
                filedName={"job_locality_id"}
                value={this.state.job_locality_id}
                cityId={this.state.job_city_id}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="distance"
                  value={this.state.distance}
                  placeholder="Distance (km)"
                  onChange={event =>
                    this.setState({
                      distance: event.target.value.replace(/\D/, "")
                    })
                  }
                  maxLength="4"
                />
              </div>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="qualification"
                value={this.state.qualification}
                onChange={this.handleChange}
              >
                <option value="" className="option-selected">
                  Select Qualification
                </option>
                {MetaData.minimum_qualification.map(option => (
                  <option value={option} key={"q" + option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <b className="downArrow"></b>
                <select
                  className="form-control"
                  name="source"
                  onChange={this.handleChange}
                  value={this.state.source}
                >
                  <option value="" className="option-selected">
                    Select Source
                  </option>
                  <option value="APP">App</option>
                  <option value="FIELD_AGENT">Agent</option>
                  <option value="WEB">Web</option>
                  <option value="NAUKRI">Naukri</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="shift_type"
                value={this.state.shift_type}
                onChange={this.handleChange}
              >
                <option value="">Job Shift</option>
                {MetaData.shift_type.map(option => (
                  <option value={option} key={"shift_type" + option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="is_auto_live"
                value={this.state.is_auto_live}
                onChange={this.handleChange}
              >
                <option value="">Is Auto Live</option>
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </select>
            </div>
            <div className="col-md-2">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="jobPlan"
                value={jobPlan}
                onChange={this.handleChange}
              >
                <option value="">Select Job Type</option>
                {MetaData.premiumJobType.map(option => (
                  <option value={option.value} key={`premium${option.value}`}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="from-col">Date From</div>
            <div className="col-md-2">
              <DatePicker
                selected={this.state.sdate}
                onChange={value => {
                  this.setState({ sdate: value });
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </div>
            <div className="col-md-1 to-col">To</div>
            <div className="col-md-2">
              <DatePicker
                selected={this.state.edate}
                onChange={value => {
                  this.setState({ edate: value });
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </div>
            <div className="col-md-12">
              <div className="float-right">
                {isButtonDisabled ? (
                  <button className="btn btn-primary disable" disabled>
                    SEARCHING ...
                  </button>
                ) : (
                  <button className="btn btn-primary" onClick={this.searchJobs}>
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
      </div>
    );
  }
}
export default JobSearch;
