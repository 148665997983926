import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

class ModeratorFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sdate: "",
      edate: ""
    };
  }

  updateMessage = (msg, flag, isReload = false, flagUpdate = false) => {
    this.props.statusMessage(msg, flag, isReload, flagUpdate);
  };

  handleSearch = e => {
    e.preventDefault();
    const { sdate, edate } = this.state;
    const today = new Date();
    if (!sdate) {
      this.updateMessage("Please enter start date", 1);
      return;
    } else if (sdate > today) {
      this.updateMessage("Start date greater than or equal to today", 1);
      return;
    }
    if (!edate) {
      this.updateMessage("Please enter end date", 1);
      return;
    } else if (edate > today) {
      this.updateMessage("End date greater than or equal to today", 1);
      return;
    }
    if (sdate > edate) {
      this.updateMessage("Please start date is less than end date", 1);
      return;
    }
    const obj = {
      start_date: sdate ? moment(sdate).format("YYYY-MM-DD") : undefined,
      end_date: edate ? moment(edate).format("YYYY-MM-DD") : undefined
    };
    this.props.searchModerator(obj);
  };

  render() {
    const { isButtonDisabled } = this.props;
    const { sdate, edate } = this.state;
    return (
      <div className="job_seeker">
        <div className="gap-20"></div>
        <form
          autoComplete="off"
          onSubmit={this.handleSearch}
          className="search"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="date-lable padding15">Date From: </div>
              <div className="date-field">
                <DatePicker
                  selected={sdate}
                  onChange={value => {
                    this.setState({ sdate: value });
                  }}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  maxDate={new Date()}
                />
              </div>
              <div className="date-lable">To: </div>
              <div className="date-field">
                <DatePicker
                  selected={edate}
                  onChange={value => {
                    this.setState({ edate: value });
                  }}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="float-right">
                {isButtonDisabled && (
                  <button className="btn btn-primary disable" disabled>
                    SEARCHING ...
                  </button>
                )}
                {!isButtonDisabled && (
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSearch}
                  >
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
      </div>
    );
  }
}
export default ModeratorFilter;
