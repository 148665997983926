import React from "react";
import DocumentTitle from "react-document-title";
import { checkPermission } from "../../services/utils";
import {
  ADMIN_PANEL,
  ANALYTICS,
  DASHBOARD_REPORTS,
  MODEARTOR_ASSIGNMENT,
  MODEARTOR_ATTENDANCE,
  OPERATION_TRACKING
} from "../../routes/RouteConstants";
import NoAccess from "../Common/NoAccess";
import HeadingRow from "../Common/HeadingRow";

const AnalyticDashboard = props => {
  const showAdminPannel = checkPermission("Moderator Report");
  const showModeratorAssignment = checkPermission("Moderator Assignment");
  const catAssignment = checkPermission("CAT Assignment");
  const moderatorAttendance = checkPermission("Moderator Attendance");
  const agentActivity = checkPermission("AgentActivity");
  const isViewPage =
    showAdminPannel ||
    showModeratorAssignment ||
    moderatorAttendance ||
    catAssignment ||
    agentActivity;
  return (
    <DocumentTitle title={"Job Hai CRM - Analytic"}>
      <div className="dashboard">
        <div className="clr gap-20" />
        <HeadingRow heading="Analytic Dashboard" />
        <div className="clr gap-20" />
        <div className="whiteCard">
          {isViewPage ? (
            <div className="container-fluid">
              <div className="clr gap-20" />
              <div className="row marginBottom20">
                {showAdminPannel ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={ADMIN_PANEL}>Admin Panel</a>
                    </div>
                  </div>
                ) : null}
                {showModeratorAssignment || catAssignment ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={MODEARTOR_ASSIGNMENT}>Moderator Assignment</a>
                    </div>
                  </div>
                ) : null}
                {moderatorAttendance ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={MODEARTOR_ATTENDANCE}>Moderator Attendance</a>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="row marginBottom20">
                {agentActivity ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={`${ANALYTICS}/agent-activity`}>Agent Activity</a>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <NoAccess />
          )}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default AnalyticDashboard;
