import React from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import JobLink from "./JobLink";
import CountdownContainer from "../Common/CountDown";
import CustomePagination from "../Common/Pagination";
import { COMPANY_EDIT_PAGE, JOB_EDIT_PAGE } from "../../routes/RouteConstants";
import PremiumTag from "../Common/PremiumTag";

function ActionLink(props) {
  return (
    <div className="edit_link">
      <JobLink url={props.editUrl} id={props.id} />
    </div>
  );
}

const AppJobList = props => {
  const {
    jobs = [],
    role,
    activePage,
    itemsCountPerPage,
    totalItemsCount,
    onPageChange,
    page,
    isSearching = false,
    showRecruiterTab
  } = props;

  jobs.map(d => {
    const {
      job_id = "",
      recruiter_id = "",
      crm_status,
      crm_deadline,
      deadline_ts,
      createdAt,
      crm_cleaning_status,
      crm_calling_status,
      crm_qa_status,
      job_approval_status,
      job_calling_status,
      job_live_status,
      job_distinction,
      jobPlan
    } = d;
    const roleQstr = role ? `${page}/${role}` : `${page}`;
    const pageURL = showRecruiterTab ? JOB_EDIT_PAGE : COMPANY_EDIT_PAGE;
    const editUrl = `${pageURL}/${roleQstr}/${job_id}?recId=${recruiter_id}`;
    d.jobId =
      job_distinction === "Extra" ? (
        <span className="cellJobId">
          {job_id}
          <img src="/images/premium.svg" alt="premium" width="19" height="19" />
        </span>
      ) : (
        <>
          {job_id} <PremiumTag jobPlan={jobPlan} />
        </>
      );
    d.edit_link = <ActionLink key={job_id} editUrl={editUrl} id={job_id} />;

    let deadline =
      crm_calling_status === "READY_FOR_QA" || crm_status === "REJECTED"
        ? null
        : crm_deadline;
    d.updatedDate = (
      <CountdownContainer
        date={moment(createdAt).format("DD-MMM-YYYY")}
        deadline={crm_deadline ? crm_deadline : deadline}
        deadTimeStamp={deadline_ts}
        isFeaze={false}
      />
    );
    d.crmCleaningStatus =
      crm_cleaning_status != null ? crm_cleaning_status : "NEW";
    d.crmCallingStatus =
      crm_calling_status != null ? crm_calling_status : "NEW";
    d.crmQaStatus = crm_qa_status != null ? crm_qa_status : "NEW";
    d.jobApprovalStatus =
      job_approval_status != null ? job_approval_status : "NEW";
    d.jobCallingStatus =
      job_calling_status != null ? job_calling_status : "NEW";
    d.jobLiveStatus = job_live_status != null ? job_live_status : "NEW";
    return d;
  });

  const roleCategory = {
    text: "Role Category",
    dataField: "role_category",
    sort: true
  };
  const agentName = {
    text: "AGENT NAME",
    dataField: "agent_name",
    sort: true
  };
  const cityName = {
    text: "City",
    dataField: "city_name",
    sort: true
  };
  const jobStatus = {
    text: "STATUS",
    dataField: "job_status",
    sort: true
  };

  let showRoleCategory = false;
  if (page === "organic" || page === "edited") {
    showRoleCategory = true;
  }

  const columns = [
    {
      text: "",
      dataField: "isRepeatRec",
      classes: "statusCircle",
      formatter: (cellContent, row) => {
        if (row.isRepeatingRecruiter) {
          return <b className="green"></b>;
        }
        return <b className="blue"></b>;
      }
    },
    {
      text: "JOB ID",
      dataField: "jobId",
      sort: true
    },
    {
      text: "COMPANY NAME",
      dataField: "company_name",
      sort: true
    },
    {
      text: "RECRUITER NAME",
      dataField: "recruiter_name",
      sort: true
    },
    {
      text: "Rec Phone",
      dataField: "phone",
      sort: true
    },
    {
      text: page === "edited" ? "Edit Date" : "POST DATE",
      dataField: "updatedDate",
      sort: true
    },
    {
      text: "Job Title",
      dataField: "job_title",
      sort: true
    },
    showRoleCategory ? roleCategory : agentName,
    page === "organic" ? cityName : jobStatus,
    {
      text: "Locality",
      dataField: "locality_name",
      sort: true
    },
    {
      text: "",
      dataField: "edit_link"
    }
  ];
  const NoDataIndication = () => (
    <div className="spinner">
      {isSearching ? "Searching..." : "No Data Found"}
    </div>
  );
  return (
    <>
      <div className="clr" />
      <div className="whitebox organic">
        <BootstrapTable
          keyField="job_id"
          data={jobs}
          columns={columns}
          hover
          condensed
          bordered={false}
          loading={true}
          rowClasses="listvalue"
          headerClasses="listHeding"
          classes="borderless"
          noDataIndication={() => <NoDataIndication />}
        />
        <CustomePagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onPageChange={onPageChange}
          showPagination={totalItemsCount > 0 ? true : false}
        />
        <div className="clr gap-20" />
      </div>
    </>
  );
};

export default AppJobList;
