import React, { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { isCRMAdmin } from "../../services/utils";
import NoAccess from "../Common/NoAccess";
import HeadingRow from "../Common/HeadingRow";
import PopupMessage from "../PopupMessage";
import { RESTRICT_VALUE_DELIVERY } from "../../routes/RouteConstants";
import RequestsList from "./RequestsList";
import { getRecruiterRestrictionsList } from "../../services/restricValueService";
import Heading from "../Common/Form/Heading";

const ViewRequest = () => {
  const isViewPage = isCRMAdmin();
  const [popupMessageObj, setPopupMessageObj] = useState({ showMsg: false });
  const [requestsData, setRequestsData] = useState([]);
  const [summaryData, setSummaryData] = useState({});

  useEffect(() => {
    getRequestsData();
  }, []);

  const getRequestsData = async () => {
    try {
      const data = await getRecruiterRestrictionsList();
      const { summaryData = {}, request_data = [] } = data;
      if (request_data.length) {
        setSummaryData(summaryData);
        setRequestsData(request_data);
      }
    } catch (error) {
      console.error(error);
      setPopupMessageObj({
        msgType: "error",
        msg: "Something went wrong",
        showMsg: true
      });
    }
  };

  const {
    active_request_count = null,
    restricted_organisation_count = null,
    verified_recruiter_restricting_count = null
  } = summaryData;

  return (
    <DocumentTitle title={"Job Hai CRM - View All Requests"}>
      <div>
        <HeadingRow
          heading="Restrict value delivery - View all requests"
          goBackURL={RESTRICT_VALUE_DELIVERY}
        />

        <PopupMessage
          data={popupMessageObj}
          click={() => setPopupMessageObj({ showMsg: false })}
        />

        <div className="clr gap-20" />

        {isViewPage ? (
          <>
            <div className="whitebox">
              <div className="clr gap-20" />

              <Heading heading="Summary of requests" />

              <div className="summary-request">
                <p>
                  <span>Total active requests</span>
                  <span>{active_request_count}</span>
                </p>
                <p>
                  <span>Total organisations restricted</span>
                  <span>{restricted_organisation_count}</span>
                </p>
                <p>
                  <span>Total verified recruiters restricted</span>
                  <span>{verified_recruiter_restricting_count}</span>
                </p>
              </div>
              <div className="clr gap-20" />

              <Heading heading="Request Level" />
              <RequestsList dataList={requestsData} />
            </div>
          </>
        ) : (
          <NoAccess />
        )}
      </div>
    </DocumentTitle>
  );
};

export default ViewRequest;
