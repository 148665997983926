import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

const UnverifiedList = props => {
  const { dataList = [], totalData = {} } = props;
  const {
    request_volume = 0,
    total_assignments = 0,
    under_review = 0,
    no_doc = 0,
    verified = 0,
    follow_ups = 0,
    onboarded = 0,
    otherBucket = 0,
    knowlarityBucket = 0
  } = totalData;
  dataList.map(d => {
    d.moderatorName = (
      <>
        {d.name} {d.isAbsent ? <span className="isAbsent" /> : null}
      </>
    );
    d.isActive = d.is_active ? "Yes" : "No";
    return d;
  });

  const columns = [
    {
      text: "ID",
      dataField: "moderator_id",
      sort: true
    },
    {
      text: "Moderator Name",
      dataField: "moderatorName",
      sort: true
    },
    {
      text: "Request Volume",
      dataField: "request_volume",
      sort: true
    },
    {
      text: "Is Active",
      dataField: "isActive",
      sort: true
    },
    {
      text: "Total Assigned",
      dataField: "total_assignments",
      sort: true
    },
    {
      text: "Under Review",
      dataField: "under_review",
      sort: true
    },
    {
      text: "No doc",
      dataField: "no_doc",
      sort: true
    },
    {
      text: "Verified",
      dataField: "verified",
      sort: true
    },
    {
      text: "Follow Ups",
      dataField: "follow_ups",
      sort: true
    },
    {
      text: "Knowlarity",
      dataField: "knowlarityBucket",
      sort: true
    },
    {
      text: "Other",
      dataField: "otherBucket",
      sort: true
    },
    {
      text: "Onboarded",
      dataField: "onboarded",
      sort: true
    }
  ];
  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  return (
    <>
      <BootstrapTable
        keyField="moderator_id"
        data={dataList}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
      />
      <div className="custom-pagination">
        <table className="table table-hover table-condensed borderless">
          <thead>
            <tr className="listHeding">
              <th>Total</th>
              <th>{dataList.length}</th>
              <th>{request_volume}</th>
              <th />
              <th>{total_assignments}</th>
              <th>{under_review}</th>
              <th>{no_doc}</th>
              <th>{verified}</th>
              <th>{follow_ups}</th>
              <th>{knowlarityBucket}</th>
              <th>{otherBucket}</th>
              <th>{onboarded}</th>
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
};

export default UnverifiedList;
