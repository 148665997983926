import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../ui/table";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { X } from "lucide-react";
import { useState, useMemo } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from "../ui/dialog";

import { Input } from "../ui/input";

import { Checkbox } from "../ui/checkbox";
import { customUseEffect } from "../../services/utils";
import useLeadAssignmentStore from "../../zustandService/useLeadAssignmentStore";
import SubmitButton from "./submitButton";

const getCopyObj = (obj, sourceXcohort) => {
  const copyObj = JSON.parse(JSON.stringify(obj));
  return copyObj[sourceXcohort]["moderators"];
};

const RenderModeratorAssignDrawer = props => {
  const { row, getAssignedModeratorsInCohort } = props;
  const {
    assignedModeratorsDraft,
    assignedModerators,
    updateAssignedModeratorsState,
    assignmentModeratorMappingData,
    moderatorConfig
  } = useLeadAssignmentStore(state => ({
    assignedModerators: state.assignedModerators,
    assignedModeratorsDraft: state.assignedModeratorsDraft,
    updateAssignedModeratorsState: state.updateAssignedModeratorsState,
    assignmentModeratorMappingData: state.assignmentModeratorMappingData,
    moderatorConfig: state.moderatorConfig
  }));

  const [search, setSearch] = useState("");

  const resetDraftState = sourceXcohort => {
    const moderators = getCopyObj(assignedModerators, sourceXcohort);
    updateAssignedModeratorsState(sourceXcohort, moderators, false);
  };

  const resetToApiData = sourceXcohort => {
    const assignedModeratorsFromApi =
      assignmentModeratorMappingData.filter(
        item => item.sourceXcohort === sourceXcohort
      )[0] || [];
    updateAssignedModeratorsState(
      sourceXcohort,
      assignedModeratorsFromApi["moderators"]?.map(item => {
        return {
          ...item,
          isSelected: true
        };
      }),
      false
    );
  };

  const saveDraftToFinalState = sourceXcohort => {
    let assignedModerators = getCopyObj(assignedModeratorsDraft, sourceXcohort);
    updateAssignedModeratorsState(sourceXcohort, assignedModerators, false);
  };

  const handleModeratorSelect = (selectedModeratorObj, sourceXcohort) => {
    let assignedModerators = getCopyObj(assignedModeratorsDraft, sourceXcohort);
    const index = assignedModerators.findIndex(
      u => u.id === selectedModeratorObj.id
    );
    if (index !== -1) {
      const moderatorObj = assignedModerators[index];
      assignedModerators[index] = {
        ...moderatorObj,
        isSelected: !moderatorObj.isSelected
      };
    } else {
      assignedModerators.push({
        id: selectedModeratorObj.id,
        name: selectedModeratorObj.name,
        isSelected: true
      });
    }
    updateAssignedModeratorsState(sourceXcohort, assignedModerators, true);
  };
  const moderators = useMemo(() => {
    return moderatorConfig.filter(
      user =>
        user.isActive && user.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);
  return (
    <TableCell>
      <Dialog>
        <DialogTrigger>
          <Button variant="outline" size="sm">
            Add
          </Button>
        </DialogTrigger>
        <DialogContent
          handleClose={() => resetDraftState(row.sourceXcohort)}
          className="sm:max-w-[600px]"
        >
          <DialogHeader>
            <DialogTitle>Assign Sales Executive</DialogTitle>
            <DialogDescription>
              Select executive to assign to this City Cohort.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4">
            <Input
              placeholder="Search executive..."
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <div className="max-h-[400px] overflow-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Select</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {moderators.map(user => (
                    <TableRow
                      key={user.id}
                      className={`${
                        getAssignedModeratorsInCohort(row.sourceXcohort).some(
                          u => u.id === user.id
                        )
                          ? "bg-primary/10"
                          : ""
                      }`}
                    >
                      <TableCell className="font-medium">{user.name}</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={getAssignedModeratorsInCohort(
                            row.sourceXcohort
                          ).some(u => u.id === user.id)}
                          onCheckedChange={() =>
                            handleModeratorSelect(user, row.sourceXcohort)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
          <DialogFooter>
            <DialogClose>
              <Button
                onClick={() => resetToApiData(row.sourceXcohort)}
                variant="ghost"
              >
                Reset
              </Button>
            </DialogClose>
            <DialogClose>
              <Button
                onClick={() => saveDraftToFinalState(row.sourceXcohort)}
                className="ml-auto"
              >
                Assign City
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </TableCell>
  );
};

export default function ModeratorAssignment() {
  const {
    fetchAssignmentModeratorMapping,
    assignmentModeratorMappingFetchState,
    assignmentModeratorMappingData,
    leadAssignmentColumns,
    assignedModeratorsDraft,
    assignedModerators,
    saveModeratorAssignment,
    fetchModeratorConfig
  } = useLeadAssignmentStore(state => ({
    fetchAssignmentModeratorMapping: state.fetchAssignmentModeratorMapping,
    assignmentModeratorMappingFetchState:
      state.assignmentModeratorMappingFetchState,
    assignmentModeratorMappingData: state.assignmentModeratorMappingData,
    leadAssignmentColumns: state.leadAssignmentColumns,
    assignedModerators: state.assignedModerators,
    assignedModeratorsDraft: state.assignedModeratorsDraft,
    saveModeratorAssignment: state.saveModeratorAssignment,
    fetchModeratorConfig: state.fetchModeratorConfig
  }));
  customUseEffect(() => {
    fetchAssignmentModeratorMapping();
    fetchModeratorConfig();
  }, []);

  const getAssignedModeratorsInCohort = (sourceXcohort, isDraft = true) => {
    const obj = isDraft ? assignedModeratorsDraft : assignedModerators;
    return obj[sourceXcohort]["moderators"]?.filter(item => item.isSelected);
  };

  if (assignmentModeratorMappingFetchState === "LOADING") return null;
  return (
    <div>
      <SubmitButton onClick={saveModeratorAssignment} />
      <div className="w-full border rounded-lg">
        <div className="relative w-full overflow-auto">
          <Table>
            <TableHeader>
              <TableRow>
                {Object.keys(leadAssignmentColumns).map((column, index) => {
                  return <TableHead key={index}>{column}</TableHead>;
                })}
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {assignmentModeratorMappingData.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {Object.values(leadAssignmentColumns).map(
                      (columnValue, index) => {
                        {
                          return columnValue === "moderators" ? (
                            <TableCell key={index}>
                              <div className="flex flex-wrap gap-2">
                                {getAssignedModeratorsInCohort(
                                  row.sourceXcohort,
                                  false
                                ).map((item, index) => {
                                  return (
                                    <Badge key={index} variant={"outline"}>
                                      {item.name}
                                    </Badge>
                                  );
                                })}
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell key={index}>
                              {row[columnValue]}
                            </TableCell>
                          );
                        }
                      }
                    )}
                    <RenderModeratorAssignDrawer
                      getAssignedModeratorsInCohort={
                        getAssignedModeratorsInCohort
                      }
                      row={row}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

function MoveVerticalIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="8 18 12 22 16 18" />
      <polyline points="8 6 12 2 16 6" />
      <line x1="12" x2="12" y1="2" y2="22" />
    </svg>
  );
}
