import { Link } from "react-router-dom";
import {
  customDateFormat,
  isLmsAdmin,
  isLmsModerator
} from "../../../services/utilsV2";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../ui/table";
import { LFMS_LEAD_DETAILS } from "../../../routes/RouteConstants";
import { useState } from "react";
import { Button } from "../../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../../ui/dialog";
import { Label } from "../../ui/label";
import { attributeSale } from "../../../services/insideSales";
import CustomSelect from "../../ui/CustomSelect";
import useLeadAssignmentStore from "../../../zustandService/useLeadAssignmentStore";
import useInsideSalesStore from "../../../zustandService/useInsideSalesStore";

const showAssignTo = !isLmsModerator();
const isLMSAdmin = isLmsAdmin();
const ECOM = "E-commerce";
const SalesList = ({ data }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [detailDialogOpen, setDetailDialog] = useState(false);
  const { fetchModeratorConfig } = useLeadAssignmentStore(state => ({
    fetchModeratorConfig: state.fetchModeratorConfig
  }));
  const handleViewDetails = row => {
    setDetailDialog(true);
    fetchModeratorConfig();

    setSelectedRow(row);
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Order Id</TableHead>
          <TableHead>Recruiter</TableHead>
          <TableHead>Phone</TableHead>
          <TableHead>Organisation Id</TableHead>
          <TableHead>Company Name</TableHead>
          <TableHead>Credits</TableHead>
          <TableHead>Basic Value</TableHead>
          <TableHead>Purchase Date</TableHead>
          {showAssignTo && <TableHead>Executive</TableHead>}
          {isLMSAdmin && <TableHead>Team Lead</TableHead>}
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.length ? (
          <>
            {data.map((item, index) => (
              <TableRow key={item.orderId}>
                <TableCell>{item.orderId}</TableCell>
                <TableCell>
                  {item.recruiterName} ·{" "}
                  {item.leadId ? (
                    <Link
                      to={`${LFMS_LEAD_DETAILS}?leadId=${item.leadId}&recruiterId=${item.recruiterId}`}
                    >
                      <span className="text-gray-400 ">{item.recruiterId}</span>
                    </Link>
                  ) : (
                    <span className="text-gray-400 ">{item.recruiterId}</span>
                  )}
                </TableCell>
                <TableCell>{item.recruiterPhone || "NA"}</TableCell>
                <TableCell>{item.orgId}</TableCell>
                <TableCell>{item.orgName}</TableCell>
                <TableCell>{item.creditsPurchased}</TableCell>
                <TableCell>{item.value}</TableCell>
                <TableCell>
                  {customDateFormat(item.creditPurchaseDate, "D MMMM")}
                </TableCell>
                {showAssignTo && (
                  <TableCell>
                    {item.salesChannel === 1
                      ? ECOM
                      : item.moderatorName || "NA"}
                    {item?.salesAttributionCount > 0 ? "*" : ""}
                  </TableCell>
                )}
                {isLMSAdmin && (
                  <TableCell>
                    {item.moderatorParentName || "NA"}
                    {item?.salesAttributionCount > 0 ? "*" : ""}
                  </TableCell>
                )}
                {isLMSAdmin ? (
                  <TableCell>
                    <DetailsDialog
                      setDetailDialog={setDetailDialog}
                      open={
                        detailDialogOpen &&
                        selectedRow?.orderId === item.orderId
                      }
                      item={item}
                      handleViewDetails={handleViewDetails}
                      selectedRow={selectedRow}
                    />
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </>
        ) : (
          <TableRow>
            <TableCell colSpan={10} align="center" className="text-red-500">
              No Data Found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const DetailsDialog = ({
  item,
  handleViewDetails,
  selectedRow,
  open,
  setDetailDialog
}) => {
  const [submitState, setSubmitState] = useState(false);
  const { moderatorConfigLocalState: moderatorList } = useLeadAssignmentStore(
    state => ({
      moderatorConfigLocalState: state.moderatorConfig
    })
  );

  const { fetchSalesData } = useInsideSalesStore(state => ({
    fetchSalesData: state.fetchSalesData
  }));

  const getModeratorName = id => {
    const moderator = moderatorList.find(mod => mod.id === id);
    return moderator ? moderator.name : "NA";
  };
  const [fieldItems, setFieldItems] = useState(null);
  return (
    <Dialog open={open}>
      <DialogTrigger asChild>
        <Button
          variant="link"
          className="p-0"
          onClick={() => handleViewDetails(item)}
        >
          View Details
        </Button>
      </DialogTrigger>
      <DialogContent showCrossIcon={false} className="sm:min-w-max">
        <DialogHeader>
          <DialogTitle>Details</DialogTitle>
        </DialogHeader>
        {selectedRow && (
          <div className="p-4 grid grid-cols-2 gap-4">
            <div>
              <h3 className="font-bold mb-2">Current Attribution</h3>
              <p>
                <Label className="font-bold">Executive:</Label>{" "}
                {selectedRow.salesChannel === 1
                  ? ECOM
                  : selectedRow.moderatorName || "NA"}
              </p>
              {selectedRow.salesChannel !== 1 && (
                <p>
                  <Label className="font-bold">Team Lead (TL):</Label>{" "}
                  {selectedRow.moderatorParentName || "NA"}
                </p>
              )}
            </div>
            <div>
              <h3 className="font-bold mb-2">New Attribution</h3>
              <div>
                {moderatorList?.length ? (
                  <div className="grid gap-2">
                    <CustomSelect
                      options={moderatorList}
                      value={fieldItems?.id}
                      additionalItems={
                        selectedRow.salesChannel !== 1
                          ? [{ id: ECOM, name: ECOM }]
                          : []
                      }
                      excludedIds={
                        selectedRow.salesChannel !== 1
                          ? [selectedRow?.moderatorId]
                          : []
                      }
                      onChange={option => setFieldItems({ id: option.id })}
                      placeholder={`Change Attribution`}
                    />
                  </div>
                ) : null}

                <p>
                  <Label className="font-bold mt-2">Team Lead (TL):</Label>{" "}
                  {getModeratorName(
                    moderatorList?.find(item => item.id == fieldItems?.id)?.tlId
                  )}
                </p>
                {/* )} */}
              </div>
            </div>
            {selectedRow?.salesAttributionCount > 0 ? (
              <div className="col-span-2">
                <h3 className="font-bold mb-2">History</h3>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Label className="font-bold">Old Attribution:</Label>{" "}
                        {getModeratorName(selectedRow?.previousModeratorId)}
                      </TableCell>
                      <TableCell>
                        <Label className="font-bold">
                          Updated Attribution:
                        </Label>{" "}
                        {selectedRow.salesChannel == 1
                          ? ECOM
                          : getModeratorName(selectedRow.moderatorId)}
                      </TableCell>
                      <TableCell>
                        <Label className="font-bold">Date Changed:</Label>{" "}
                        {customDateFormat(
                          selectedRow.attributionUpdateTime,
                          "D MMMM YYYY"
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            ) : null}
          </div>
        )}
        <DialogFooter>
          <Button
            variant={submitState ? "loader" : "default"}
            disabled={!fieldItems || submitState}
            onClick={async () => {
              const payload = {
                orderId: String(selectedRow.orderId)
              };
              if (fieldItems?.id !== ECOM && selectedRow?.salesChannel != 1) {
                payload.newModeratorId = fieldItems.id;
                payload.previousModeratorId = selectedRow.moderatorId;
              }
              if (selectedRow?.salesChannel !== 1 && fieldItems?.id == ECOM) {
                payload.previousSalesChannel = 2;
                payload.newSalesChannel = 1; //yahpe previous mod id jyu nhi chhaiye? mapping kahi reh to nhi jaygi?
              }
              if (selectedRow?.salesChannel == 1 && fieldItems?.id != ECOM) {
                payload.previousSalesChannel = 1;
                payload.newSalesChannel = 2;
                payload.newModeratorId = fieldItems.id;
              }
              try {
                setSubmitState(true);
                await attributeSale(payload);
                await fetchSalesData();
                setSubmitState(false);
                setDetailDialog(false);
              } catch (e) {
                setSubmitState(false);
                alert("Something went wrong. Please try again later.");
              }
            }}
          >
            Submit
          </Button>
          <DialogClose asChild>
            <Button onClick={() => setDetailDialog(false)} variant="ghost">
              Cancel
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SalesList;
