import { useState, useEffect, useCallback } from "react";
import JobHai from "../../api/JobHai";
import UnverifiedTat from "./UnverifiedTat";
import VerifiedTat from "./VerifiedTat";

function TatSummary({ type }) {
  const [tatData, setTatData] = useState({});
  const [showTat, setShowTat] = useState(false);
  const [loading, setLoading] = useState(false);

  const getModeratorTat = useCallback(() => {
    const url = `/get-moderator-performance?type=${type}`;
    setLoading(true);
    JobHai.get(url)
      .then(response => {
        setLoading(false);
        setTatData(response.data.data);
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  }, [type]);

  useEffect(() => {
    if (showTat && type) {
      getModeratorTat();
    }
  }, [showTat, type, getModeratorTat]);

  const handleShowPerformance = () => {
    setShowTat(true);
  };

  return (
    <>
      <div className="row job_seeker form-group">
        <div className="col-md-12 form-group">
          <div className="float-right">
            <div className="download_link" onClick={handleShowPerformance}>
              My Performance
            </div>
          </div>
        </div>
      </div>
      {showTat && (
        <>
          {type === "unverified" && (
            <UnverifiedTat tatData={tatData} loading={loading} />
          )}
          {type === "verified" && (
            <VerifiedTat tatData={tatData} loading={loading} />
          )}
        </>
      )}
    </>
  );
}

export default TatSummary;
