import { withStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { checkFreeTextValidation } from "../services/utils";
import Chips from "./Common/Chips";
import NewCustomTextField from "./Common/Form/NewCustomTextField";

const styles = () => ({
  addIconClass: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    lineHeight: "20px",
    fontWeight: 600,
    color: "#2A89F6",
    paddingRight: "19px",
    border: "none",
    background: "none",
    "&:disabled": {
      color: "#7F8AA5"
    },
    "& img": {
      marginRight: "5px"
    }
  },
  customClass: {
    color: "#2E2E36",
    fontSize: "15px",
    lineHeight: "20px",
    "&::-webkit-input-placeholder": {
      // color: "#8D9DC1",
      fontWeight: 400
    },
    padding: "10px 12px",
    fontWeight: 400
  },
  textfield: {
    border: "1px solid #E2E5EF",
    borderRadius: "6px",
    width: "320px",
    height: "40px",
    padding: "0",
    margin: "16px 0 10px"
  }
});

const JobPostingOtherField = props => {
  const {
    value,
    onChange,
    maxLength = 30,
    placeholder,
    id,
    onSubmit,
    eventCallback,
    panel,
    onChipSelect,
    isMultiSelect = true,
    otherChips,
    otherChipObj,
    allowedChips = 1,
    initiallyShowTextField = false
  } = props;
  const [showTextField, setShowTextField] = useState(false);

  useEffect(() => {
    if (initiallyShowTextField) setShowTextField(initiallyShowTextField);
  }, [initiallyShowTextField]);

  const { classes } = props;
  const regex = /^$|^[a-zA-Z0-9 \-/]+$/;
  const validationFailed = !regex.test(value);
  useEffect(() => {
    if (otherChips?.length >= allowedChips) {
      setShowTextField(false);
    }
  }, [otherChips.length]);

  const submitText = () => {
    const trimmedValue = value?.trim();
    if (checkFreeTextValidation(trimmedValue)) {
      if (otherChips.filter(item => item.label === trimmedValue).length) {
        return;
      }

      if (eventCallback) eventCallback();
      const obj = {
        label: trimmedValue,
        panel: panel,
        type: "free-text"
      };
      onChipSelect({
        chipValue: obj.label,
        panel,
        chipObject: obj,
        valueKey: "label",
        source: "jobPostingOtherField",
        callback: onSubmit,
        isMultiSelect
      });
    }
  };
  return (
    <>
      {otherChips?.length < allowedChips && !showTextField ? (
        <Chips
          chips={otherChipObj}
          activeChips={[]}
          onClick={() => setShowTextField(true)}
        />
      ) : null}
      {showTextField ? (
        <form
          onSubmit={e => {
            e.preventDefault();
            submitText();
          }}
          style={{ position: "relative" }}
        >
          <NewCustomTextField
            value={value}
            onChange={e => {
              const regex = /^$|^[a-zA-Z0-9 \-/]+$/;
              if (regex.test(e.target.value)) onChange(e);
              else if (validationFailed) {
                e.target.value = "";
                onChange(e);
              }
            }}
            onKeyDown={e => {
              if (e.key === ",") {
                e.preventDefault();
                submitText();
              }
            }}
            id={id}
            className={classes.textfield}
            inputClass={classes.customClass}
            variant="outlined"
            placeholder={placeholder}
            autoComplete="off"
            inputProps={{ maxLength }}
            endAdornment={
              <button disabled={!value} className={classes.addIconClass}>
                <img
                  src={value ? "/images/bluePlus.svg" : "/images/greyPlus.svg"}
                  alt="add"
                />
                Add
              </button>
            }
          />
        </form>
      ) : null}
      {validationFailed && (
        <div className="text-danger">
          Only Alphanumerics,space,hyphen and slash are allowed
        </div>
      )}
    </>
  );
};
export default withStyles(styles)(JobPostingOtherField);
