import { create } from "zustand";
import JobHai from "../api/JobHai";
import moment from "moment";

const states = {
  PENDING: "PENDING",
  IDLE: "IDLE",
  FETCHED: "FETCHED",
  ERROR: "ERROR"
};

const recDetails = {
  status: true,
  data: {
    categories: [28, 38],
    cities: ["1_LOCATION", "2_LOCATION"],
    phoneNo: "8700254296",
    recruiterName: "Ajay",
    emailId: "example@example.com",
    companyName: "Tech Solutions Inc.",
    orgId: "12345",
    recruiterCount: 10,
    companyJobPostCount: 50,
    companyPaidLiveJobs: 20,
    companyTotalLiveJobs: 30,
    companyAverageMonthlyJobPost: 5,
    companyAverageJobResponse: 100,
    companyCategories: [
      { id: 3, name: "Driver" },
      { id: 4, name: "Delivery" }
    ],
    companyCities: [
      { id: "1_LOCATION", name: "Delhi" },
      { id: "2_LOCATION", name: "Mumbai" }
    ],
    decisionMakerName: "John Doe",
    decisionMakerNumber: "9876543210",
    totalCredits: 2345,
    freeJobsLeft: 34
  },
  message: "",
  code: 200
};

const salesDetails = {
  status: true,
  data: {
    totalCollection: 56789,
    totalSales: 233,
    totalCredit: 45678,
    saleDetails: [
      {
        credit: 500,
        value: 1000,
        purchaseDate: "2024-01-15",
        expiryDate: "2025-01-15",
        agent: "Agent Smith"
      },
      {
        credit: 500,
        value: 1500,
        purchaseDate: "2024-02-10",
        expiryDate: "2025-02-10",
        agent: "Agent Jones"
      },
      {
        credit: 500,
        value: 2000,
        purchaseDate: "2024-03-05",
        expiryDate: "2025-03-05",
        agent: "Agent Brown"
      }
    ]
  },
  message: "",
  code: 200
};
const useLeadRecruiterDetailStore = create(set => ({
  recruiterDetailsState: states.IDLE,
  leadDetailsState: states.IDLE,
  recentSalesState: states.IDLE,
  recruiterDetails: null,
  leadDetails: {},
  recentSales: {},
  decisionMakerState: states.IDLE,
  salesCurrentPage: 1,
  salesSelectedTab: null,
  callDetails: {},
  callDetailsState: states.IDLE,
  fetchCallDetails: async ({ leadId, recId }) => {
    if (!leadId || !recId) {
      set({ callDetailsState: states.ERROR });
      return;
    }
    try {
      set({ callDetailsState: states.PENDING });

      const res = await JobHai.get(`lms/call-details?leadId=${leadId}`);
      const data = res.data.data;
      set({
        callDetailsState: states.FETCHED,
        callDetails: {
          ...data
        }
      });
    } catch (error) {
      set({ callDetailsState: states.ERROR });
    }
  },
  postCall: async ({
    statusMaps,
    followUpDate,
    followUpTime,
    remarks,
    leadId,
    bucketId
  }) => {
    try {
      if (!leadId || !bucketId) return;
      let selectedStatus = statusMaps.reduce((obj, item) => {
        if (item.selected === "Yes" || item.selected === "No") {
          obj[item.name] = item.selected === "Yes" ? 1 : 0;
        }
        return obj;
      }, {});
      selectedStatus["remarks"] = remarks;
      if (followUpDate) {
        selectedStatus["followUpDate"] =
          moment(followUpDate).format("YYYY-MM-DD");
      }
      if (followUpTime) {
        selectedStatus[
          "followUpTime"
        ] = `${followUpTime.hour}:${followUpTime.minute}`;
      }
      selectedStatus["leadId"] = leadId;
      selectedStatus["bucketId"] = bucketId;

      const res = await JobHai.post("/lms/call-details", { ...selectedStatus });
      return res.data.data;
    } catch (error) {}
  },
  setSalesData: (data, key) => {
    set({ [key]: data });
  },
  fetchRecruiterDetails: async ({ recruiterId }) => {
    if (!recruiterId) {
      set({ recruiterDetailsState: states.ERROR });
      return;
    }
    set({ recruiterDetailsState: states.PENDING });
    try {
      const response = await JobHai.get(
        `/lms/recruiter-details?recruiterId=${recruiterId}`
      );
      const data = response.data.data;
      set({ recruiterDetails: data, recruiterDetailsState: states.FETCHED });
    } catch (error) {
      set({ recruiterDetailsState: states.ERROR });
    }
  },

  fetchLeadDetails: async ({ leadId }) => {
    if (!leadId) {
      set({ leadDetailsState: states.ERROR });
    }
    set({ leadDetailsState: states.PENDING });
    try {
      const response = await JobHai.get(`/lms/lead-details?leadId=${leadId}`);
      const data = response.data.data;

      set({ leadDetails: data, leadDetailsState: states.FETCHED });
    } catch (error) {
      set({ leadDetailsState: states.ERROR });
    }
  },

  fetchRecentSales: async ({ pageNo = 1, tab = "company", recruiterId }) => {
    // console.log("fetching for", pageNo, tab);
    set({ recentSalesState: states.PENDING });
    try {
      console.log("get data", pageNo, tab);
      const response = await JobHai.get(
        `lms/sales-details?recruiterId=${recruiterId}&type=${tab}&size=10&page=${pageNo}`
      );
      // await delay();
      const data = response.data.data;
      set({ recentSales: data, recentSalesState: states.FETCHED });
    } catch (error) {
      set({ recentSalesState: states.ERROR });
    }
  },

  setDecisionMaker: async data => {
    try {
      const response = await JobHai.post("/lms/decision-maker", { ...data });

      // set({ recentSales: data, recentSalesState: states.FETCHED });
      // set({ decisionMakerState: { ...data } });
      return "OK";
    } catch (error) {
      // set({ de: states.ERROR });
    }
  },

  resetStates: () => {
    set({
      recruiterDetailsState: states.IDLE,
      leadDetailsState: states.IDLE,
      recentSalesState: states.IDLE
    });
  }
}));

export default useLeadRecruiterDetailStore;

const delay = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 2000);
  });
};
