import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { JOB_EDIT_PAGE } from "../../routes/RouteConstants";
import { getNthItemText } from "../../services/utils";

const CustomPagination = props => {
  const { otherJobsList, jobId } = props;
  const jobCount = otherJobsList.length;
  const setSelectedItem = jobId => {
    const { recId, page, role } = props;
    window.location = `${JOB_EDIT_PAGE}/${page}/${role}/${jobId}?recId=${recId}`;
  };

  const handleNextPrev = type => {
    const { recId, page, role, otherJobsList, jobId } = props;
    let foundIndex = otherJobsList.indexOf(Number(jobId));
    let nextIndex = type === "next" ? foundIndex + 1 : foundIndex - 1;
    let nextJobId = otherJobsList[nextIndex];
    window.location = `${JOB_EDIT_PAGE}/${page}/${role}/${nextJobId}?recId=${recId}`;
  };
  let foundIndex = otherJobsList.indexOf(Number(jobId));
  let prev = foundIndex > 0;
  let next = jobCount > foundIndex + 1;

  const selectedItem = `${foundIndex + 1}${getNthItemText(
    foundIndex
  )} / ${jobCount}`;
  return (
    <div className="paginationContainer">
      {prev ? (
        <div className="prev" onClick={() => handleNextPrev("prev")}>
          <img src="/images/previous.svg" alt="prev" width="20" height="20" />
        </div>
      ) : null}
      <Dropdown>
        <Dropdown.Toggle variant="success" className="item active">
          {selectedItem}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {otherJobsList.map((item, i) => (
            <Dropdown.Item
              key={i}
              as="button"
              onClick={() => setSelectedItem(item)}
            >
              {`${i + 1}${getNthItemText(i)} / ${jobCount}`}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {next ? (
        <div className="next" onClick={() => handleNextPrev("next")}>
          <img src="/images/next.svg" alt="nex" width="20" height="20" />
        </div>
      ) : null}
    </div>
  );
};

export default CustomPagination;
