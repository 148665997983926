import {
  JOBS,
  UNVERIFIED_RECRUITERS,
  CRAWLED_RECRUITERS
} from "../../routes/RouteConstants";

function OrganicJobTab(props) {
  const { role = "QA", active = "verified" } = props;
  return (
    <div className="organic-tab">
      <ul className="nav">
        <li className="nav-item">
          <a
            className={active === "verified" ? "nav-link active" : "nav-link"}
            aria-current="page"
            href={`${JOBS}/organic/${role}`}
          >
            Verified Recruiter
          </a>
        </li>
        <li className="nav-item">
          <a
            className={active === "unverified" ? "nav-link active" : "nav-link"}
            href={`${UNVERIFIED_RECRUITERS}/${role}`}
          >
            Unverified Recruiter
          </a>
        </li>
        <li className="nav-item">
          <a
            className={active === "crawled" ? "nav-link active" : "nav-link"}
            href={`${CRAWLED_RECRUITERS}/${role}`}
          >
            Crawled
          </a>
        </li>
      </ul>
    </div>
  );
}
export default OrganicJobTab;
