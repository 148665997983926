import React from "react";
import MetaData from "../../../constants/MetaData";
import { MODEARTOR_ASSIGNMENT } from "../../../routes/RouteConstants";

const ModeratorAssignmentTabs = props => {
  const { isSelected, isNewCity, isOnlyCatAdmin = false } = props;
  let tabList = MetaData.adminPannelTabsList.filter(item => !item.is_disabled);
  if (isNewCity) {
    tabList = MetaData.adminPannelTabsList.filter(item => item.newCity);
  }
  return (
    <div className="tabs">
      <div className="tab-list">
        {tabList.map(item => {
          const { id, title } = item;
          return (
            <React.Fragment key={id}>
              {id === isSelected ? (
                <span
                  className={
                    isOnlyCatAdmin && id !== "CAT"
                      ? "unClickedChip disabledChip"
                      : id === isSelected
                      ? "unClickedChip clickedChip"
                      : "unClickedChip"
                  }
                >
                  {title}
                </span>
              ) : (
                <a href={`${MODEARTOR_ASSIGNMENT}?pageType=${id}`}>
                  <span
                    className={
                      isOnlyCatAdmin && id !== "CAT"
                        ? "unClickedChip disabledChip"
                        : id === isSelected
                        ? "unClickedChip clickedChip"
                        : "unClickedChip"
                    }
                  >
                    {title}
                  </span>
                </a>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default ModeratorAssignmentTabs;
