import Heading from "../Common/Form/Heading";
import SkeletonLoader from "../SkeletonLoader";

const UpdateLeadsSkeleton = () => {
  return (
    <div className="company-details">
      <Heading heading="Lead Information" />

      <div className="formRow">
        <div className="left">
          <SkeletonLoader customClass="leadsLoader" />
        </div>

        <div className="right">
          <SkeletonLoader customClass="leadsLoader" />
        </div>
      </div>

      <div className="formRow">
        <div className="left">
          <SkeletonLoader customClass="leadsLoader2" />
        </div>

        <div className="right">
          <SkeletonLoader customClass="leadsLoader" />
        </div>
      </div>

      <div className="formRow">
        <div className="left">
          <SkeletonLoader customClass="leadsLoader2" />
        </div>
      </div>

      <div className="formRow">
        <div className="left">
          <SkeletonLoader customClass="leadsLoader" />
        </div>

        <div className="right">
          <SkeletonLoader customClass="leadsLoader" />
        </div>
      </div>

      <div className="formRow">
        <div className="left">
          <SkeletonLoader customClass="leadsLoader2" />
        </div>

        <div className="right">
          <SkeletonLoader customClass="leadsLoader2" />
        </div>
      </div>

      <div className="formRow">
        <div className="left">
          <SkeletonLoader customClass="leadsLoader2" />
        </div>

        <div className="right">
          <SkeletonLoader customClass="leadsLoader2" />
        </div>
      </div>

      <div className="formRow">
        <div className="left">
          <SkeletonLoader customClass="leadsLoader2" />
        </div>

        <div className="right">
          <SkeletonLoader customClass="leadsLoader" />
        </div>
      </div>

      <div className="formRow">
        <div className="left">
          <SkeletonLoader customClass="leadsLoader" />
        </div>

        <div className="right">
          <SkeletonLoader customClass="leadsLoader" />
        </div>
      </div>

      <div className="formRow">
        <div className="left">
          <SkeletonLoader customClass="leadsLoader" />
        </div>

        <div className="right">
          <SkeletonLoader customClass="leadsLoader" />
        </div>
      </div>
    </div>
  );
};

export default UpdateLeadsSkeleton;
