// const moment = require("moment");

// // function adjustHours(hours) {
// //   // Get current time
// //   let currentTime = moment();

// //   // Add 2 hours
// //   let newTime = currentTime.add(hours, "hours");

// //   // Define working hours
// //   const startHour = 10;
// //   const endHour = 18;
// //   const endMinute = 30;

// //   // Check if the new time is within business hours
// //   if (
// //     newTime.day() === 0 || // Sunday
// //     newTime.hour() < startHour || // Before 10 AM
// //     (newTime.hour() >= endHour && newTime.minute() > endMinute) // After 6:30 PM
// //   ) {
// //     // If not, move to the next working day at 10 am
// //     newTime = newTime
// //       .add(1, "days")
// //       .set({ hour: startHour, minute: 0, second: 0 });

// //     // Ensure the new time falls on a working day (Monday to Saturday)
// //     while (newTime.day() === 0) {
// //       // Skip Sundays
// //       newTime = newTime.add(1, "days");
// //     }
// //   }

// //   return newTime;
// // }

// // console.log(addTwoHoursAdjust());
// function adjustHours(hours) {
//   // Get current time
//   debugger;
//   let currentTime = moment();

//   // Add the specified hours
//   let newTime = currentTime.add(hours, "hours");

//   // Round to the nearest 15 minutes
//   let minutes = newTime.minute();
//   let roundedMinutes = Math.round(minutes / 15) * 15;
//   newTime.minute(roundedMinutes).second(0);

//   // Define working hours
//   const startHour = 10;
//   const endHour = 18;
//   const endMinute = 30;

//   // Check if the new time is within business hours
//   if (
//     newTime.day() === 0 || // Sunday
//     newTime.hour() < startHour || // Before 10 AM
//     (newTime.hour() >= endHour && newTime.minute() > endMinute) // After 6:30 PM
//   ) {
//     // If not, move to the next working day at 10 am
//     newTime = newTime
//       .add(1, "days")
//       .set({ hour: startHour, minute: 0, second: 0 });

//     // Ensure the new time falls on a working day (Monday to Saturday)
//     while (newTime.day() === 0) {
//       // Skip Sundays
//       newTime = newTime.add(1, "days");
//     }
//   }

//   return newTime;
// }
// export { adjustHours };

import moment from "moment";
function adjustHours(hours) {
  // Get current time
  let currentTime = moment();

  // Add the specified hours
  let newTime = currentTime.add(hours, "hours");

  // Round to the nearest 15 minutes
  let minutes = newTime.minute();
  let roundedMinutes = Math.round(minutes / 15) * 15;
  newTime.minute(roundedMinutes).second(0);

  // Define working hours
  const startHour = 10;
  const endHour = 18;
  const endMinute = 30;

  // Check if the new time is within business hours
  if (
    newTime.day() === 0 || // Sunday
    newTime.hour() < startHour || // Before 10 AM
    newTime.hour() > endHour ||
    (newTime.hour() === endHour && newTime.minute() > endMinute) // After 6:30 PM
  ) {
    // If not, move to the next working day at 10 am
    newTime = newTime
      .add(1, "days")
      .set({ hour: startHour, minute: 0, second: 0 });

    // Ensure the new time falls on a working day (Monday to Saturday)
    while (newTime.day() === 0) {
      // Skip Sundays
      newTime = newTime.add(1, "days");
    }
  }

  return newTime;
}

export { adjustHours };
