import { useState, useEffect } from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { getCustomErrorMessage } from "../../../services/utils";
import JobHai from "../../../api/JobHai";
import CopyQuery from "./CopyQuery";
import Heading from "../../Common/Form/Heading";
import CustomePagination from "../../Common/Pagination";

const SqlHistory = () => {
  const pageSize = 20;
  const [isLoading, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [queryList, setQueryList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getQuery();
  }, []);

  useEffect(() => {
    getQuery();
  }, [activePage]);

  function getQuery() {
    setLoader(true);
    const page = activePage ? Number(activePage) - 1 : 0;
    JobHai.get(`workbench/query-history?page_number=${page}&size=${pageSize}`)
      .then(response => {
        const data = response?.data?.data || [];
        data.map(d => {
          const { query = "", error = "", createdAt } = d;
          d.sqlQuery = <CopyQuery query={query} />;
          d.sqlError = error || "NA";
          d.date = moment(createdAt).format("DD-MMM-YYYY hh:mm a");
          return d;
        });
        setQueryList(data);
        setLoader(false);
        setCount(data.length);
      })
      .catch(error => {
        let msg = getCustomErrorMessage(error);
        setErrorMessage(msg);
        setLoader(false);
      });
  }

  function onPageChange(page) {
    setActivePage(page);
  }

  const columns = [
    {
      text: "#",
      dataField: "id"
    },
    {
      text: "Query",
      dataField: "sqlQuery"
    },
    {
      text: "Result Count",
      dataField: "result_count",
      sort: true
    },
    {
      text: "Time Taken",
      dataField: "time_taken",
      sort: true
    },
    {
      text: "Date",
      dataField: "date",
      sort: true
    },
    {
      text: "Error",
      dataField: "sqlError"
    },
    {
      text: "",
      dataField: "copyQuery",
      sort: true
    }
  ];
  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  return (
    <div className="form-row admin-pannel">
      <div className="seperator" />
      <Heading heading="Your Old Query, you can copy and use it" />
      <div className="col-md-12">
        {errorMessage && <span className="text-danger">{errorMessage}</span>}
      </div>
      {isLoading ? (
        "Loading ..."
      ) : (
        <>
          <BootstrapTable
            keyField="id"
            data={queryList}
            columns={columns}
            hover
            condensed
            bordered={false}
            loading={true}
            rowClasses="listvalue"
            headerClasses="listHeding"
            classes="borderless"
            noDataIndication={() => <NoDataIndication />}
          />
          {count > pageSize ? (
            <CustomePagination
              activePage={activePage}
              itemsCountPerPage={pageSize}
              totalItemsCount={count}
              onPageChange={onPageChange}
              showPagination={count > pageSize}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default SqlHistory;
