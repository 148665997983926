import SkeletonLoader from "../SkeletonLoader";

const ModeratorPerformanceSummary = props => {
  const { heading, data, isLoading } = props;
  const { leads_assigned, leads_touched, leads_connected, leads_interested } =
    data;
  return (
    <div className="perfromanceSummery">
      <strong>{heading}</strong>
      <div className="row sortingWrap">
        <table
          width="100%"
          border="0"
          cellPadding="4"
          cellSpacing="10"
          className="table table-hover table-condensed borderless1 summeryTable"
        >
          <tbody>
            <tr>
              <th>Leads Assigned</th>
              <th>Leads Touched</th>
              <th>Connected</th>
              <th>Interested</th>
            </tr>
            {isLoading ? (
              <tr>
                <td>
                  <SkeletonLoader customClass="cellLoader" />
                </td>
                <td>
                  <SkeletonLoader customClass="cellLoader" />
                </td>
                <td>
                  <SkeletonLoader customClass="cellLoader" />
                </td>
                <td>
                  <SkeletonLoader customClass="cellLoader" />
                </td>
              </tr>
            ) : (
              <tr>
                <td>{leads_assigned}</td>
                <td>{leads_touched}</td>
                <td>{leads_connected}</td>
                <td>{leads_interested}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ModeratorPerformanceSummary;
