import { useEffect, useState } from "react";
import { FilterIcon } from "lucide-react";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "../../ui/sheet";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import useMetadataStore from "../../../zustandService/useMetadataStore";
import useInsideLeadStore from "../../../zustandService/useInsideLeadStore";
import { getFormattedValue } from "../../../services/utils";
import {
  formatDateTime,
  hasAnyValue,
  parseDateStringToDate,
  validateDateRange,
  validateMinMax
} from "../../../services/utilsV2";
import AssignedTo from "../AssignedTo";
import CustomSelect from "../../ui/CustomSelect";

const LeadFilters = () => {
  const [fieldItems, setFieldItems] = useState({});
  const [errorMesage, setErrorMessage] = useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const { insideSalesLeadSources } = useMetadataStore(state => ({
    insideSalesLeadSources: state.insideSalesLeadSources
  }));

  const {
    updateLeadsFilterData,
    initialFilters,
    updateInitialFilters,
    defaultFilters,
    defaultBucketIds,
    setLeadType,
    setActiveBucket
  } = useInsideLeadStore(state => ({
    updateLeadsFilterData: state.updateLeadsFilterData,
    initialFilters: state.initialFilters,
    updateInitialFilters: state.updateInitialFilters,
    defaultFilters: state.defaultFilters,
    defaultBucketIds: state.defaultBucketIds,
    setLeadType: state.setLeadType,
    setActiveBucket: state.setActiveBucket
  }));

  useEffect(() => {
    if (Object.keys(initialFilters).length > 0) {
      setResetFilter(hasAnyValue(initialFilters, true));
      setFieldItems({ ...initialFilters });
    }
  }, []);

  const resetFieldItems = () => {
    setFieldItems(defaultFilters);
    setErrorMessage("");
  };

  const resetFilters = () => {
    setFieldItems(defaultFilters);
    setResetFilter(false);
    setActiveBucket("ALL");
    setLeadType("ALL");
    updateInitialFilters(defaultFilters);
    updateLeadsFilterData(
      { bucketIds: defaultBucketIds },
      { isNewFilter: true, resetCountApi: true }
    );
    setErrorMessage("");
  };

  const handleChange = e => {
    let { name, value } = e.target;
    if (
      name === "recruiterIds" ||
      name === "companyId" ||
      name === "recruiterPhone"
    ) {
      value = getFormattedValue(value, true);
    }
    setFieldItems({
      ...fieldItems,
      [name]: value
    });
  };

  const handleDateChange = (field, date) => {
    setFieldItems({
      ...fieldItems,
      [field]: date
    });
  };

  const handleOptionChange = (option, filedName) => {
    setFieldItems({
      ...fieldItems,
      [filedName]: option?.id || ""
    });
  };

  const handleMinMaxChange = (field, e) => {
    let { name, value } = e.target;
    setFieldItems(prevState => ({
      ...prevState,
      [field]: { ...prevState[field], [name]: getFormattedValue(value, true) }
    }));
  };

  const handleSubmit = () => {
    const filters = {};
    const today = new Date();
    const toDay = moment(today).format("YYYY-MM-DD");
    const dateMap = {
      1: `${toDay} 00:00:00`,
      2: moment(today).subtract(2, "days").format("YYYY-MM-DD 00:00:00"),
      3: moment(today).subtract(7, "days").format("YYYY-MM-DD 00:00:00")
    };
    setErrorMessage("");
    // Assign filters from fieldItems
    const fieldsToMap = [
      { key: "recruiterIds", isArray: true },
      { key: "recruiterName", isArray: false },
      { key: "companyId", isArray: true },
      { key: "companyName", isArray: false },
      { key: "recruiterPhone", isArray: true },
      { key: "leadSourceIds", isArray: true },
      { key: "moderatorIds", isArray: true }
    ];

    fieldsToMap.forEach(({ key, isArray }) => {
      if (fieldItems[key]) {
        filters[key] = isArray ? [Number(fieldItems[key])] : fieldItems[key];
      }
    });

    // Validate date ranges
    const dateFields = [
      { field: "assignedDate", label: "Assigned date" },
      { field: "followupDate", label: "Follow up date" },
      { field: "churnDate", label: "Churn date" },
      { field: "saleDate", label: "Sale date" },
      { field: "unallocationDate", label: "Expiry" }
    ];

    for (const { field, label } of dateFields) {
      const dateError = validateDateRange(fieldItems, field, label);
      if (dateError) {
        setErrorMessage(dateError);
        return;
      }
      const { from, to } = fieldItems[field];
      if (from || to) {
        filters[field] = {
          from: formatDateTime(from),
          to: formatDateTime(to, "23:59:59")
        };
      }
    }
    if (fieldItems.lastPurchaseAttemptDate) {
      filters.lastPurchaseAttemptDate = {
        from: dateMap[fieldItems.lastPurchaseAttemptDate],
        to: `${toDay} 23:59:59`
      };
    }

    // Validate min-max ranges
    const minMaxFields = [
      { field: "jobCount", label: "Job Posted" },
      { field: "creditsPurchased", label: "Credits purchased" },
      { field: "creditsAvailable", label: "Credits available" }
    ];

    for (const { field, label } of minMaxFields) {
      const minMaxerror = validateMinMax(fieldItems, field, label);
      if (minMaxerror) {
        setErrorMessage(minMaxerror);
        return;
      }
      const { min, max } = fieldItems[field];
      if (min || max) {
        filters[field] = {
          min: min ? Number(min) : undefined,
          max: max ? Number(max) : undefined
        };
      }
    }
    updateLeadsFilterData(
      { ...filters, bucketIds: defaultBucketIds },
      { isNewFilter: true, resetCountApi: true }
    );
    setResetFilter(hasAnyValue(filters, true));
    updateInitialFilters(fieldItems);
    handleClose();
    setActiveBucket("ALL");
    setLeadType("ALL");
  };

  const dataRange = [
    { id: 1, label: "Today" },
    { id: 2, label: "Last 2 days" },
    { id: 3, label: "Last 7 days" }
  ];

  return (
    <div className="flex items-center gap-3">
      <Sheet>
        <SheetTrigger>
          {resetFilter && (
            <Button
              onClick={resetFilters}
              className="mr-2 bg-blue-500 border border-blue-500 text-white hover:bg-blue-600"
            >
              Reset Filter
            </Button>
          )}
          <Button
            variant="outline"
            className="ml-2"
            size="icon"
            onClick={handleOpen}
          >
            <FilterIcon className="w-4 h-4" />
          </Button>
        </SheetTrigger>
        <SheetContent
          isOpen={isOpen}
          handleClose={handleClose}
          className="overflow-y-auto max-h-full filter"
        >
          <SheetHeader>
            <SheetTitle>Filters</SheetTitle>
          </SheetHeader>
          <div className="flex flex-col gap-4 mt-12">
            <div className="grid gap-2">
              <Label htmlFor="recruiterId" className="text-sm font-medium">
                Recruiter Id
              </Label>
              <Input
                id="recruiterId"
                name="recruiterIds"
                type="tel"
                placeholder="123456"
                value={fieldItems.recruiterIds || ""}
                onChange={handleChange}
                maxLength={20}
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="recruiterName" className="text-sm font-medium">
                Recruiter Name
              </Label>
              <Input
                id="recruiterName"
                name="recruiterName"
                type="text"
                value={fieldItems.recruiterName || ""}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="companyId" className="text-sm font-medium">
                Organization Id
              </Label>
              <Input
                id="companyId"
                name="companyId"
                type="tel"
                value={fieldItems.companyId || ""}
                onChange={handleChange}
                maxLength={10}
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="companyName" className="text-sm font-medium">
                Company Name
              </Label>
              <Input
                id="companyName"
                name="companyName"
                type="text"
                placeholder="Eg. InfoEdge"
                value={fieldItems.companyName || ""}
                onChange={handleChange}
                maxLength="100"
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="recruiterPhone" className="text-sm font-medium">
                Recruiter Phone
              </Label>
              <Input
                id="recruiterPhone"
                name="recruiterPhone"
                type="tel"
                maxLength="10"
                value={fieldItems.recruiterPhone || ""}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="endDate">Assigned</Label>
              <div className="flex items-center space-x-2">
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.assignedDate?.from
                  )}
                  onSelect={date =>
                    handleDateChange("assignedDate", {
                      ...fieldItems.assignedDate,
                      from: date
                    })
                  }
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                />
                <DatePicker
                  selected={parseDateStringToDate(fieldItems.assignedDate?.to)}
                  onSelect={date =>
                    handleDateChange("assignedDate", {
                      ...fieldItems.assignedDate,
                      to: date
                    })
                  }
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                  minDate={parseDateStringToDate(fieldItems.assignedDate?.from)}
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label className="text-sm font-medium">Follow up</Label>
              <div className="flex items-center space-x-2">
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.followupDate?.from
                  )}
                  onSelect={date =>
                    handleDateChange("followupDate", {
                      ...fieldItems.followupDate,
                      from: date
                    })
                  }
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                />
                <DatePicker
                  selected={parseDateStringToDate(fieldItems.followupDate?.to)}
                  onSelect={date =>
                    handleDateChange("followupDate", {
                      ...fieldItems.followupDate,
                      to: date
                    })
                  }
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                  minDate={parseDateStringToDate(fieldItems.followupDate?.from)}
                />
              </div>
            </div>
            {/* <div className="grid gap-2">
              <Label className="text-sm font-medium">Job Posted</Label>
              <div className="flex items-center space-x-2">
                <Input
                  type="tel"
                  placeholder="Min"
                  value={fieldItems.jobCount?.min || ""}
                  name="min"
                  onChange={e => handleMinMaxChange("jobCount", e)}
                  className="w-40"
                  maxLength="6"
                />
                <Input
                  type="tel"
                  placeholder="Max"
                  value={fieldItems.jobCount?.max || ""}
                  name="max"
                  onChange={e => handleMinMaxChange("jobCount", e)}
                  className="w-40"
                  maxLength="6"
                />
              </div>
            </div> */}
            <div className="grid gap-2">
              <Label className="text-sm font-medium">
                Total Credits Purchased
              </Label>
              <div className="flex items-center space-x-2">
                <Input
                  type="tel"
                  placeholder="Min"
                  value={fieldItems.creditsPurchased?.min || ""}
                  name="min"
                  onChange={e => handleMinMaxChange("creditsPurchased", e)}
                  className="w-40"
                />
                <Input
                  type="tel"
                  placeholder="Max"
                  value={fieldItems.creditsPurchased?.max || ""}
                  name="max"
                  onChange={e => handleMinMaxChange("creditsPurchased", e)}
                  className="w-40"
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label className="text-sm font-medium">Credits Available</Label>
              <div className="flex items-center space-x-2">
                <Input
                  type="tel"
                  placeholder="Min"
                  value={fieldItems.creditsAvailable?.min || ""}
                  name="min"
                  onChange={e => handleMinMaxChange("creditsAvailable", e)}
                  className="w-40"
                />
                <Input
                  type="tel"
                  placeholder="Max"
                  value={fieldItems.creditsAvailable?.max || ""}
                  name="max"
                  onChange={e => handleMinMaxChange("creditsAvailable", e)}
                  className="w-40"
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="source">Lead Source</Label>
              <CustomSelect
                options={insideSalesLeadSources}
                value={fieldItems.leadSourceIds}
                onChange={handleOptionChange}
                placeholder="Select Lead Source"
                fieldName="leadSourceIds"
                labelKey="label"
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="lead-activity">Lead Activity</Label>
              <CustomSelect
                options={dataRange}
                value={fieldItems.lastPurchaseAttemptDate}
                onChange={handleOptionChange}
                placeholder="Select Lead Activity"
                fieldName="lastPurchaseAttemptDate"
                labelKey="label"
              />
            </div>
            <AssignedTo fieldItems={fieldItems} setFieldItems={setFieldItems} />
            <div className="grid gap-2">
              <Label className="text-sm font-medium">Expiry</Label>
              <div className="flex items-center space-x-2">
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.unallocationDate?.from
                  )}
                  onSelect={date =>
                    handleDateChange("unallocationDate", {
                      ...fieldItems.unallocationDate,
                      from: date
                    })
                  }
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                />
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.unallocationDate?.to
                  )}
                  onSelect={date =>
                    handleDateChange("unallocationDate", {
                      ...fieldItems.unallocationDate,
                      to: date
                    })
                  }
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                  minDate={parseDateStringToDate(
                    fieldItems.unallocationDate?.from
                  )}
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label className="text-sm font-medium">Churn Date</Label>
              <div className="flex items-center space-x-2">
                <DatePicker
                  selected={parseDateStringToDate(fieldItems.churnDate?.from)}
                  onSelect={date =>
                    handleDateChange("churnDate", {
                      ...fieldItems.churnDate,
                      from: date
                    })
                  }
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                />
                <DatePicker
                  selected={parseDateStringToDate(fieldItems.churnDate?.to)}
                  onSelect={date =>
                    handleDateChange("churnDate", {
                      ...fieldItems.churnDate,
                      to: date
                    })
                  }
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                  minDate={parseDateStringToDate(fieldItems.churnDate?.from)}
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label className="text-sm font-medium">Sales Date</Label>
              <div className="flex items-center space-x-2">
                <DatePicker
                  selected={parseDateStringToDate(fieldItems.saleDate?.from)}
                  onSelect={date =>
                    handleDateChange("saleDate", {
                      ...fieldItems.saleDate,
                      from: date
                    })
                  }
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                />
                <DatePicker
                  selected={parseDateStringToDate(fieldItems.saleDate?.to)}
                  onSelect={date =>
                    handleDateChange("saleDate", {
                      ...fieldItems.saleDate,
                      to: date
                    })
                  }
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                  minDate={parseDateStringToDate(fieldItems.saleDate?.from)}
                />
              </div>
            </div>
          </div>
          {errorMesage && (
            <div className="flex text-red-500 mt-6">{errorMesage}</div>
          )}
          <SheetFooter className="flex justify-end mt-12">
            <Button variant="outline" onClick={resetFieldItems}>
              Reset
            </Button>
            <Button onClick={handleSubmit}>Apply Filter</Button>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default LeadFilters;
