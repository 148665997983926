import React from "react";
import { JOB_EDIT_PAGE, RECUITERS_LIST } from "../../routes/RouteConstants";
import { jdTextFieldLimit } from "../../services/constant";
import PremiumTag from "../Common/PremiumTag";

const ManualJobList = props => {
  const {
    handleChange,
    handleSave,
    dataList = {},
    saveRowId,
    errorMsg = ""
  } = props;
  const itemList = { ...dataList };
  const {
    recruiter_id,
    job_id = "",
    job_title = "",
    job_description = "",
    category_title = "",
    suggested_jds = [],
    is_jd_audited = false,
    jobPlan = ""
  } = itemList;
  let showVerified = is_jd_audited || saveRowId === job_id;
  if (errorMsg && saveRowId === job_id) showVerified = false;
  return (
    <tr className="listvalue">
      <td>
        <a
          href={`${JOB_EDIT_PAGE}/organic/QA/${job_id}?recId=${recruiter_id}`}
          title={`View Job ${job_title}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {job_id}
        </a>
        <PremiumTag jobPlan={jobPlan} />
      </td>
      <td>
        <a
          href={`${RECUITERS_LIST}?recIds=${recruiter_id}`}
          title="View Recruiter"
          target="_blank"
          rel="noopener noreferrer"
        >
          {recruiter_id}
        </a>
      </td>
      <td>{job_title ? job_title : "NA"}</td>
      <td>{category_title}</td>
      <td>
        {suggested_jds ? (
          <>
            {suggested_jds.map(item => {
              return (
                <div key={`jds${item.id}`}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={item.is_selected}
                    disabled
                  />
                  {item.label}
                </div>
              );
            })}
          </>
        ) : (
          "NA"
        )}
      </td>
      <td>
        <p>
          <textarea
            rows={4}
            style={{ width: "100%" }}
            onChange={e => handleChange(e, job_id)}
            name="job_description"
            value={job_description ? job_description : ""}
            id={`job_description${job_id}`}
            maxLength={jdTextFieldLimit}
          />
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          {saveRowId === job_id ? (
            <span
              style={{
                color: "#18c1a3",
                display: "flex",
                alignItems: "center",
                width: "70%"
              }}
            >
              {errorMsg ? (
                <span className="text-danger">{errorMsg}</span>
              ) : (
                <span className="emailVerified">
                  <img
                    src="/images/verified.svg"
                    alt="verified"
                    width="12"
                    height="9"
                  />{" "}
                  Verified
                </span>
              )}
            </span>
          ) : null}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: `${saveRowId === job_id ? "70%" : "100%"}`
            }}
          >
            {showVerified ? (
              <button
                className="successButton"
                disabled
                style={{
                  padding: "11px 20px",
                  marginLeft: "10px"
                }}
              >
                <img src="/images/verified.svg" alt="saved" />
                Saved
              </button>
            ) : (
              <button
                className="submitBtn"
                onClick={() => handleSave(dataList)}
                style={{
                  padding: "11px 20px",
                  marginLeft: "10px"
                }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
export default ManualJobList;
