// Login Events

export const URL_SHORTENER = { name: "URL_SHORTENER", category: "CRM" };
export const RFD_CLICK = { name: "RFD_CLICK", category: "CRM" };
export const WORKBENCH_DOWNLOAD_CLICK = {
  name: "WORKBENCH_DOWNLOAD_CLICK",
  category: "CRM"
};
export const MARK_REVIEWED_CLICK = {
  name: "MARK_REVIEWED_CLICK",
  category: "CRM"
};
export const RECRUITER_SAVE_CHANGES_CLICK = {
  name: "RECRUITER_SAVE_CHANGES_CLICK",
  category: "CRM"
};
export const ADD_VIDEO = {
  name: "ADD_VIDEO",
  category: "CRM"
};
export const ADD_TAG_CATEGORY = {
  name: "ADD_TAG_CATEGORY",
  category: "CRM"
};
export const RFD_API_CALLED = {
  name: "RFD_API_CALLED",
  category: "CRM"
};
