function PageTabs(props) {
  const { pageTabs = [] } = props;
  return (
    <div className="organic-tab">
      <ul className="nav">
        {pageTabs.map(item => {
          return (
            <li className="nav-item" key={item.label}>
              <a
                className={item.isActive ? "nav-link active" : "nav-link"}
                aria-current="page"
                href={item.url}
              >
                {item.label}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
export default PageTabs;
