import React from "react";
import NewCityAlert from "../../JobsNew/NewCityAlert";

const Heading = props => {
  const {
    heading = "",
    customClass = "",
    isNewCity = false,
    isEdited = false,
    children
  } = props;
  return (
    <h2 className={customClass ? customClass : "heading"}>
      {heading}
      {isNewCity ? <NewCityAlert /> : null}
      {isEdited ? <span className="editedField">Edited</span> : null}
      {children}
    </h2>
  );
};

export default Heading;
