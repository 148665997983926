import LoadingSpinner from "../LoadingSpinner";

function VerifiedTat({ tatData = {}, loading }) {
  const {
    assigned_jobs_actioned_today = 0,
    assigned_jobs_today = 0,
    overall_jobs_actioned_today = 0,
    weekly_first_action_tat = "",
    weekly_live_job_tat = "",
    assigned_jobs_live_today = 0
  } = tatData;
  return (
    <div className="row">
      <table
        width="100%"
        border="0"
        cellPadding="4"
        cellSpacing="10"
        className="table table-condensed borderless1 summeryTable"
      >
        <tbody>
          {loading ? (
            <tr>
              <td>
                <LoadingSpinner key="loading" />
              </td>
            </tr>
          ) : (
            <>
              <tr>
                <th>Assigned Jobs (Today)</th>
                <th>Actioned Jobs (Today)</th>
                <th>Assigned Jobs Live (Today)</th>
                <th>Total Jobs Actioned (Today)</th>
                <th>Weekly 1st Action TAT (Median)</th>
                <th>Weekly Job Live TAT(Median)</th>
              </tr>
              <tr>
                <td>{assigned_jobs_today}</td>
                <td>{assigned_jobs_actioned_today}</td>
                <td>{assigned_jobs_live_today}</td>
                <td>{overall_jobs_actioned_today}</td>
                <td>{weekly_first_action_tat}</td>
                <td>{weekly_live_job_tat}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default VerifiedTat;
