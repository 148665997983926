import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { useController } from "react-hook-form";

const MaterialSwitch = ({
  name,
  label,
  control,
  rules,
  error,
  defaultChecked,
  formControlClasses,
  customOnChangeHandler,
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue: defaultChecked
  });
  const { onChange, ...restField } = field;

  return (
    <FormControlLabel
      classes={formControlClasses}
      control={
        <Switch
          defaultChecked={defaultChecked}
          onChange={e => {
            if (customOnChangeHandler) customOnChangeHandler(e);
            onChange(e);
          }}
          {...restField}
          {...rest}
        />
      }
      label={label}
      error={!!fieldState.error || !!error}
    />
  );
};

export default MaterialSwitch;
