import React from "react";

const ReportedJobTab = props => {
  const { type, handleClick } = props;
  return (
    <div className="tabs">
      <div className="tab-list">
        <span
          className={
            type === "Assigned" ? "unClickedChip clickedChip" : "unClickedChip"
          }
          onClick={() => handleClick("Assigned")}
        >
          Assigned Reports
        </span>
        <span
          className={
            type === "Reported" ? "unClickedChip clickedChip" : "unClickedChip"
          }
          onClick={() => handleClick("Reported")}
        >
          Reported Jobs
        </span>
      </div>
    </div>
  );
};
export default ReportedJobTab;
