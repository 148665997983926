import React, { useEffect, useState } from "react";
import cx from "classnames";
import { MODEARTOR_ASSIGNMENT } from "../../../routes/RouteConstants";
import { checkPermission } from "../../../services/utils";
import ModeratorAssignmentTabs from "./ModeratorAssignmentTabs";
import CatSubTabs from "./CatSubTabs";
import DocumentTitle from "react-document-title";
import HeadingRow from "../../Common/HeadingRow";
import NoAccess from "../../Common/NoAccess";
import CatAssignmentVarForm from "./CatAssignmentVarForm";
import classes from "./catAssignmentVarForm.module.css";

const CatAssignmentVariables = () => {
  const showAssignment = checkPermission("Moderator Assignment");
  const catAdmin = checkPermission("CAT Assignment");
  const isAccess = showAssignment || catAdmin;
  const isOnlyCatAdmin = !showAssignment && catAdmin;
  const handleTabClick = id => {
    window.location = `${MODEARTOR_ASSIGNMENT}?pagetype=${id}`;
  };
  return (
    <DocumentTitle title={"Job Hai CRM - Assignment"}>
      <div className="modAssignmentcontainer">
        <div className="admin-pannel">
          <div className="clr gap-20" />
          <HeadingRow
            heading="Moderator Assignment"
            goBackURL={`${MODEARTOR_ASSIGNMENT}?pageType=CAT`}
          />
          <ModeratorAssignmentTabs
            handleClick={handleTabClick}
            isSelected={"CAT"}
            isOnlyCatAdmin={isOnlyCatAdmin}
          />
          <CatSubTabs isSelected={"CAT_ASSIGNMENT_VARIABLES"} />
          <div className={cx("whitebox table-responsive", classes.cntClass)}>
            {isAccess ? <CatAssignmentVarForm /> : <NoAccess />}
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default CatAssignmentVariables;
