import { useState } from "react";
import { ENTERPRISE_LEADS } from "../../routes/RouteConstants";
import { checkPermission, customUseEffect } from "../../services/utils";
import HeadingRow from "../Common/HeadingRow";
import NoAccess from "../Common/NoAccess";
import LeadsSharingFilter from "./LeadsSharingFilter";
import LoadingSpinner from "../LoadingSpinner";
import { getLcsMetdadata } from "../../services/lcsCampaign";
import { getKamList } from "../../services/metadataApiServices";

const LeadSharingDistribution = () => {
  const isAccess =
    checkPermission("LeadSharingDistribution") ||
    checkPermission("EnterpriseLeadsAdmin");
 
  const [loading, setLoading] = useState(true);
  const [campaignList, setCamaignList] = useState([]);
  const [kamList, setKamList] = useState([]);

  customUseEffect(() => {
    if (!isAccess) return;
    const fetchData = async () => {
      try {
        const [campaignResponse, kamResponse] = await Promise.all([
          getLcsMetdadata(),
          getKamList()
        ]);
        setCamaignList(campaignResponse?.current_campaigns || []);
        setKamList(kamResponse || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setCamaignList([]);
        setKamList([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isAccess]);

  if (!isAccess) return <NoAccess />;
  if (loading) return <LoadingSpinner />;

  return (
    <>
      <HeadingRow
        heading="Lead Sharing / Distribution"
        goBackURL={ENTERPRISE_LEADS}
      />
      <LeadsSharingFilter campaignList={campaignList} kamList={kamList} />
    </>
  );
};

export default LeadSharingDistribution;
