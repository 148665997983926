import React from "react";
const Dashboard = () => (
  <div className="dashboard">
    <div className="clr gap-20"></div>
    <div className="clr gap-20"></div>
    <div className="pageHeading row">
      <h2>CRM Dashboard</h2>
    </div>
    <div className="clr"></div>
    <div className="clr gap-20"></div>
    <div className="clr gap-20"></div>
    <div className="whiteCard">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <p> Welcome to Job Hai CRM</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Dashboard;
