import React, { useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { RECUITERS_LIST } from "../../routes/RouteConstants";
import { formattedRestriction } from "../../services/utils";

function ActionLink({ item }) {
  const { verified_recruiter_count, organisation_id } = item;
  const recUrl = `${RECUITERS_LIST}?organisation_id=${organisation_id}`;

  return (
    <div className="edit_link">
      <a href={recUrl} target="_blank" rel="noopener noreferrer">
        {verified_recruiter_count}
      </a>
    </div>
  );
}

const OrganisationList = props => {
  const {
    dataList = [],
    isSearching = false,
    handleSelectedItems,
    selectedOrg
  } = props;

  const NoDataIndication = () => (
    <div className="spinner">
      {isSearching ? "Searching..." : "No Data Found"}
    </div>
  );

  const handleOnSelect = useCallback(
    (row, isSelect) => {
      handleSelectedItems(isSelect, row);
    },
    [handleSelectedItems]
  );

  const handleOnSelectAll = useCallback(
    (isSelect, rows) => {
      handleSelectedItems(isSelect, rows, true);
    },
    [handleSelectedItems]
  );

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedOrg,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    hideSelectColumn: false
  };

  const selectedDataList = dataList.filter(item =>
    selectedOrg.includes(item.organisation_id)
  );

  const sumResult = {
    verified_recruiter_count: 0,
    recruiters_with_live_jobs_count: 0,
    lifetime_life_job_count: 0,
    current_live_job_count: 0,
    application_in_last_30_days: 0,
    positive_action_in_last_30_days: 0
  };

  const totalSum = selectedDataList.reduce((acc, curr) => {
    acc.verified_recruiter_count += curr.verified_recruiter_count || 0;
    acc.recruiters_with_live_jobs_count +=
      curr.recruiters_with_live_jobs_count || 0;
    acc.lifetime_life_job_count += curr.lifetime_life_job_count || 0;
    acc.current_live_job_count += curr.current_live_job_count || 0;
    acc.application_in_last_30_days += curr.application_in_last_30_days || 0;
    acc.positive_action_in_last_30_days +=
      curr.positive_action_in_last_30_days || 0;

    return acc;
  }, sumResult);

  const columns = [
    {
      text: "Org Id",
      dataField: "organisation_id",
      sort: true,
      footer: "Total Selected"
    },
    {
      text: "Organisation name",
      dataField: "company_name",
      sort: true,
      footer: `${selectedDataList.length} organisations`
    },
    {
      text: "Number of verified recruiters",
      dataField: "verified_recruiter_count",
      sort: true,
      formatter: (cellContent, row) => <ActionLink item={row} />,
      footer: `${totalSum.verified_recruiter_count}`
    },
    {
      text: "Number of recruiters with live jobs",
      dataField: "recruiters_with_live_jobs_count",
      sort: true,
      footer: `${totalSum.recruiters_with_live_jobs_count}`
    },
    {
      text: "Lifetime JML",
      dataField: "lifetime_life_job_count",
      sort: true,
      footer: `${totalSum.lifetime_life_job_count}`
    },
    {
      text: "Live Jobs",
      dataField: "current_live_job_count",
      sort: true,
      footer: `${totalSum.current_live_job_count}`
    },
    {
      text: "Applications recieved in last 30 days",
      dataField: "application_in_last_30_days",
      sort: true,
      footer: `${totalSum.application_in_last_30_days}`
    },
    {
      text: "Positive actions in last 30 days",
      dataField: "positive_action_in_last_30_days",
      sort: true,
      footer: `${totalSum.positive_action_in_last_30_days}`
    },
    {
      text: "Current restriction",
      dataField: "current_restriction",
      sort: true,
      formatter: cellContent => formattedRestriction(cellContent),
      footer: ""
    }
  ];

  return (
    <div className="list-ellipsis restrict-value">
      <BootstrapTable
        keyField="organisation_id"
        data={dataList}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
        selectRow={selectRow}
        footerClasses="table-footer"
      />
    </div>
  );
};

export default OrganisationList;
