import React from "react";
import { ENTERPRISE_LEADS } from "../../routes/RouteConstants";

const LeadBucket = props => {
  const {
    activeBucket = "",
    type = "bucket",
    clientId,
    callingAttempts = 5,
    additionalBucket,
    bucketCount
  } = props;
  const { new_count = 0, follow_up_count = 0, ...ncCounts } = bucketCount;

  const renderCallAttempts = () => {
    return Array.from({ length: callingAttempts - 1 }, (_, index) => {
      const attemptNumber = index + 1;
      return (
        <div
          className={`box${activeBucket === attemptNumber ? " active" : ""}`}
          key={`attempt-${attemptNumber}`}
        >
          <a
            href={`${ENTERPRISE_LEADS}/${type}/?bucket=${attemptNumber}&client=${clientId}`}
            rel="noopener noreferrer"
          >
            Not Connected #{attemptNumber}(
            {ncCounts[`nc${attemptNumber}_count`] || 0})
          </a>
        </div>
      );
    });
  };
  return (
    <div className="lead-bucket-container">
      <div className="rec-bucket">
        <div className={`box${activeBucket === "New" ? " active" : ""}`}>
          <a
            href={`${ENTERPRISE_LEADS}/${type}/?bucket=New&client=${clientId}`}
            rel="noopener noreferrer"
          >
            New ({new_count})
          </a>
        </div>
        {renderCallAttempts()}
        <div className={`box${activeBucket === "Followup" ? " active" : ""}`}>
          <a
            href={`${ENTERPRISE_LEADS}/${type}/?bucket=Followup&client=${clientId}`}
            rel="noopener noreferrer"
          >
            Follow Up ({follow_up_count})
          </a>
        </div>
        {additionalBucket.map((item, index) => {
          let count = bucketCount[item?.count_key]
            ? bucketCount[item?.count_key]
            : 0;
          return (
            <div
              className={`box${activeBucket === item.value ? " active" : ""}`}
              key={`bucket${index}`}
            >
              <a
                href={`${ENTERPRISE_LEADS}/${type}/?${item.key}=${item.value}&client=${clientId}`}
                rel="noopener noreferrer"
              >
                {item.label} ({count})
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LeadBucket;
