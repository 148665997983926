import { useState } from "react";
import { Building } from "lucide-react";
import PageHeader from "../../local/pageHeader";
import CompanyList from "./CompanyList";
import useInsideCompaniesStore from "../../../zustandService/useInsideCompaniesStore";
import useInsideLeadStore from "../../../zustandService/useInsideLeadStore";
import LmfsPagination from "../LmfsPagination";
import CompaniesFilters from "./CompaniesFilters";
import ReAssign from "./ReAssign";
import { isLmsModerator } from "../../../services/utilsV2";
import { customUseEffect } from "../../../services/utils";

function Companies() {
  const [selectedIds, setSelectedIds] = useState([]);
  const {
    fetchCompaniesData,
    payloadData,
    companiesData,
    fetchState,
    errorMessage,
    updatePageNumber
  } = useInsideCompaniesStore(state => ({
    fetchCompaniesData: state.fetchCompaniesData,
    payloadData: state.payloadData,
    fetchState: state.fetchState,
    errorMessage: state.errorMessage,
    companiesData: state.companiesData,
    updatePageNumber: state.updatePageNumber
  }));

  const { fetchModeratorData } = useInsideLeadStore(state => ({
    fetchModeratorData: state.fetchModeratorData
  }));

  const { page = 1, size = 20 } = payloadData;

  customUseEffect(() => {
    fetchCompaniesData();
    fetchModeratorData();
  }, []);

  const handlePagination = page => {
    updatePageNumber(page);
  };

  const reAssignCallBack = () => {
    setSelectedIds([]);
    fetchCompaniesData();
  };

  const { companyInfo = [], totalCompaniesCount = 0 } = companiesData;
  let showReAssign = false;
  if (!isLmsModerator()) {
    showReAssign = true;
  }
  return (
    <div className="w-screen lg:w-[calc(100vw-15rem)] h-screen overflow-scroll pb-12">
      <div className="sticky top-0 z-50 bg-white">
        <PageHeader icons={<Building />} heading="Companies" />
      </div>
      <div className="flex items-center  justify-end p-3 mb-4">
        {showReAssign && selectedIds.length ? (
          <ReAssign
            selectedIds={selectedIds}
            reAssignCallBack={reAssignCallBack}
          />
        ) : null}
        <CompaniesFilters />
      </div>
      <div className="grid grid-cols-4 gap-4 mb-12">
        <div className="col-span-4 mx-3 border rounded-lg">
          <div className="relative w-full overflow-auto">
            <div className="flex flex-col">
              {fetchState === "LOADING" ? (
                <p className="text-center py-6">Loading...</p>
              ) : fetchState === "ERROR" ? (
                <p className="text-red-500 ml-4 py-6">
                  {errorMessage || "Error fetching data"}
                </p>
              ) : (
                <>
                  <CompanyList
                    data={companyInfo}
                    setSelectedIds={setSelectedIds}
                  />
                  {!!totalCompaniesCount && (
                    <LmfsPagination
                      totalCount={totalCompaniesCount}
                      handlePagination={handlePagination}
                      activePage={page}
                      itemsPerPage={size}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Companies;
