import RecruiterDetails from "./RecruiterDetail";

function LeadRecruiterDetails() {
  return (
    <div>
      <RecruiterDetails />
    </div>
  );
}

export default LeadRecruiterDetails;
