import { useState } from "react";
import { getFormattedValue, isObjectEmpty } from "../../services/utils";
import SelectCity from "../Common/SelectCity";

const CampaignFilter = ({ searchData, isDisabled, lcsMetaData }) => {
  const [filterItems, handleFormItems] = useState({
    title: "",
    campaign_category: "",
    id: "",
    city_ids: "",
    allowed_categories: "",
    campaign_status: ""
  });
  const [errorMsg, handleError] = useState("");
  const [isReset, setReset] = useState(false);
  function handleChange(event) {
    let {
      target: { name, value }
    } = event;
    let tempObj = { ...filterItems };
    if (name === "id") {
      value = getFormattedValue(value, true, true);
    }
    tempObj[name] = value;
    handleFormItems(tempObj);
    handleError("");
    if (!isObjectEmpty(tempObj)) setReset(true);
  }

  function handleSearch(e) {
    e.preventDefault();
    if (isObjectEmpty(filterItems)) {
      handleError("Please select from filter list");
      return;
    }
    searchData({ ...filterItems, title: filterItems?.title?.trim() });
  }

  function resetFilter() {
    setReset(false);
    handleError("");
    handleFormItems({
      title: "",
      campaign_category: "",
      id: "",
      city_ids: "",
      allowed_categories: "",
      campaign_status: ""
    });
    searchData({});
  }

  const { campaignCategory = [], category = [] } = lcsMetaData || {};
  const campaignStatusList = [
    { id: "ACTIVE", label: "Active" },
    { id: "INACTIVE", label: "Inactive" },
    { id: "TEST", label: "Active For Testing" }
  ];
  const {
    title,
    campaign_category,
    id,
    city_ids,
    allowed_categories,
    campaign_status
  } = filterItems;
  return (
    <div className="job_seeker recruiter-search">
      <form autoComplete="off" onSubmit={handleSearch} className="search">
        <div className="clr gap-20" />
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="title"
                placeholder="Campaign Name"
                value={title}
                onChange={handleChange}
                maxLength="255"
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="campaign_category"
                value={campaign_category}
                onChange={handleChange}
              >
                <option value="">Select Campaign Category</option>
                {campaignCategory.map(option => (
                  <option
                    value={option.campaign_category_id}
                    key={`campcat${option.campaign_category_id}`}
                  >
                    {option.campaign_category_label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="id"
                placeholder="Campaign Id"
                value={id}
                onChange={handleChange}
                maxLength="5"
              />
            </div>
          </div>

          <div className="col-md-2">
            <SelectCity
              filedName={"city_ids"}
              value={city_ids}
              onChange={handleChange}
              label={false}
              key={city_ids}
            />
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="allowed_categories"
                value={allowed_categories}
                onChange={handleChange}
              >
                <option value="">Select Category</option>
                {category.map(option => (
                  <option value={option.id} key={`cat${option.id}`}>
                    {option.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="campaign_status"
                value={campaign_status}
                onChange={handleChange}
              >
                <option value="">Select Campaign Status</option>
                {campaignStatusList.map(option => (
                  <option value={option.id} key={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="clr gap-20" />
          <div className="col-md-12 ">
            <div className="float-right job_seeker form-group">
              {isReset ? (
                <span onClick={resetFilter} className="download_link">
                  Reset Filter
                </span>
              ) : null}
              {errorMsg ? (
                <span className="text-danger">{errorMsg} </span>
              ) : null}
              {isDisabled ? (
                <button className="btn btn-primary disable" disabled>
                  SEARCHING ...
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  disabled={isDisabled}
                  onClick={handleSearch}
                >
                  SEARCH
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CampaignFilter;
