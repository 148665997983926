import { useEffect, useState, useCallback, useMemo } from "react";
import Select from "react-select";
import JobHai from "../../../api/JobHai";
import { LEARN_TAB_TAG_CATEGORY } from "../../../routes/RouteConstants";
import HeadingRow from "../../Common/HeadingRow";
import NoAccess from "../../Common/NoAccess";
import {
  checkPermission,
  getErrorMessage,
  redirectURL
} from "../../../services/utils";
import LoadingSpinner from "../../LoadingSpinner";
import { getLearnTabData } from "../../../services/metadataApiServices";
import Heading from "../../Common/Form/Heading";
import Label from "../../Common/Form/Label";
import CustomSelect from "../../Common/Form/CustomSelect";
import { logEvents } from "../../../services/eventsLogs";
import { ADD_TAG_CATEGORY } from "../../../services/eventsLogs/eventConstants";

const AddTagCategory = ({ query }) => {
  const isAccess = useMemo(() => checkPermission("LearnTabVideo"), []);
  const [metadata, setMetadata] = useState({ categories: [], tags: [] });
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [fieldItems, setFieldItems] = useState({ tagId: "", categoryIds: [] });
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (isAccess) {
      setLoading(true);
      getLearnTabData()
        .then(response => {
          setMetadata(response);
          setLoading(false);
        })
        .catch(error => {
          setError(getErrorMessage(error));
          setLoading(false);
        });
    }
  }, [isAccess]);

  const handleChange = useCallback(({ target: { name, value } }) => {
    setFieldItems(prevValues => ({ ...prevValues, [name]: value }));
  }, []);

  const changeCategory = useCallback(params => {
    setSelectedCategory(params);
  }, []);

  const handleSave = useCallback(() => {
    const data = {
      tag_id: fieldItems.tagId,
      category_list: selectedCategory.map(category => category.value)
    };
    setError("");
    if (!fieldItems.tagId) {
      setError("Please select tag");
      return;
    }
    if (!selectedCategory.length) {
      setError("Please select category");
      return;
    }
    setDisabled(true);
    JobHai.post("add-category-tag-mapping", data)
      .then(() => {
        setDisabled(false);
        setSuccess("Data Saved");
        logEvents(ADD_TAG_CATEGORY, data);
        setTimeout(() => {
          setSuccess("");
          redirectURL(LEARN_TAB_TAG_CATEGORY);
        }, 2000);
      })
      .catch(error => {
        setError(getErrorMessage(error));
        setDisabled(false);
      });
  }, [fieldItems.tagId, selectedCategory]);

  const categoryList = useMemo(
    () =>
      metadata.categories.map(item => ({ value: item.id, label: item.label })),
    [metadata.categories]
  );

  if (isLoading) return <LoadingSpinner />;
  return (
    <>
      <div className="job_seeker">
        <div className="gap-20" />
        <HeadingRow
          heading="Add Tag Category"
          goBackURL={LEARN_TAB_TAG_CATEGORY}
        />
        <div className="gap-20" />
        <div className="whitebox" style={{ minHeight: "500px" }}>
          {isAccess ? (
            <>
              <Heading heading="Add Details" />
              <div className="formRow">
                <div className="left">
                  <Label lable="Tags" />
                  <CustomSelect
                    name="tagId"
                    value={fieldItems.tagId}
                    onChange={handleChange}
                    defaultPlaceholder="Select Tag"
                    optionsList={metadata.tags}
                    optionValue="tag_id"
                  />
                </div>
                <div className="right">
                  <Label lable="Category" />
                  <Select
                    value={selectedCategory}
                    onChange={changeCategory}
                    options={categoryList}
                    isMulti
                    placeholder={"Select Category"}
                  />
                </div>
              </div>
              <div className="formRow">
                <div className="form-row fullWidth">
                  <div className="col-md-12">
                    <div className="float-right">
                      {error && <span className="text-danger">{error} </span>}
                      {success && (
                        <span className="text-success">{success} </span>
                      )}
                      <button
                        className="btn btn-primary"
                        disabled={isDisabled}
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoAccess />
          )}
        </div>
      </div>
    </>
  );
};

export default AddTagCategory;
