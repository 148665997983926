import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
const styles = theme => ({
  skeletonBox: {
    display: "inline-block",
    height: "1em",
    overflow: "hidden",
    backgroundColor: "#F7F8FB",
    background:
      "linear-gradient(to right,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.5) 50%,rgba(255, 255, 255, 0) 80%),lightgray",
    backgroundRepeat: "repeat-y",
    backgroundSize: "50px 500px",
    backgroundPosition: "0 0",
    animation: "$shimmer 1s infinite",
    content: ""
  },
  "@keyframes shimmer": {
    to: {
      backgroundPosition: "100% 0"
    }
  }
});

const SkeletonLoader = props => {
  const { classes, customClass } = props;

  return (
    <div>
      <div
        className={
          customClass
            ? cx(classes.skeletonBox, customClass)
            : classes.skeletonBox
        }
      />
    </div>
  );
};

export default withStyles(styles)(SkeletonLoader);
