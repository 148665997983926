import { useState, useEffect } from "react";
import { getCustomErrorMessage } from "../../../services/utils";
import Label from "../../Common/Form/Label";
import CustomTextField from "../../Common/Form/CustomTextField";
import JobHai from "../../../api/JobHai";
import { COMPLAINTS } from "../../../routes/RouteConstants";
import { isCRMAdmin } from "../../../services/utils";
import NoAccess from "../../Common/NoAccess";

const FraudsKeywords = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isReadonly, setReadOnly] = useState(true);

  useEffect(() => {
    getKeyword();
  }, []);

  function getKeyword() {
    JobHai.post("workbench/query")
      .then(response => {
        const { result = [] } = response.data.data || {};
        setKeyword(result);
      })
      .catch(error => {
        let msg = getCustomErrorMessage(error);
        setErrorMessage(msg);
      });
  }

  function updateKeyword() {
    const dataObj = {
      keyword: keyword
    };
    if (!keyword) {
      setErrorMessage("Please enter valid keyword");
      return;
    }
    if (window.confirm("Are you sure wants to update fraud keywords")) {
      setErrorMessage("");
      setLoading(true);
      JobHai.post("workbench/query", dataObj)
        .then(response => {
          const { result = [] } = response.data.data || {};
          setKeyword(result);
          setLoading(false);
        })
        .catch(error => {
          let msg = getCustomErrorMessage(error);
          setErrorMessage(msg);
          setLoading(false);
        });
    }
  }

  function enableEdit() {
    setReadOnly(false);
  }

  function handleChange({ target: { value } }) {
    setKeyword(value);
  }

  return (
    <div className="job_seeker admin-pannel">
      <div className="clr gap-20" />
      <div className="pageHeading">
        <h2>
          Fraud Keywords
          <div className="float-right">
            <a href={COMPLAINTS}>Back</a>
          </div>
        </h2>
      </div>
      {isCRMAdmin() ? (
        <div className="whitebox reported-job">
          <div className="clr gap-20" />
          <div className="formRow">
            <div className="fullWidth">
              <Label lable="Write new fraud keyword" />
              <CustomTextField
                value={keyword}
                onChange={handleChange}
                name="keyword"
                textArea={true}
                rows="4"
                placeholder="Write fraud keyword"
                isReadonly={isReadonly}
              />
              <span className="text-danger">{errorMessage}</span>
            </div>
          </div>
          <div className="formRow">
            <div className="fullWidth">
              <div className="float-right">
                {isLoading ? (
                  <button className="btn btn-primary" disabled>
                    Saved ...
                  </button>
                ) : (
                  <>
                    {isReadonly ? (
                      <button className="btn btn-primary" onClick={enableEdit}>
                        Make Editable
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={updateKeyword}
                      >
                        Submit
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </div>
  );
};
export default FraudsKeywords;
