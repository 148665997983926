import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

const VerifiedList = props => {
  const { dataList = [], totalData = {} } = props;
  const {
    request_volume = 0,
    total_assignments = 0,
    ready_for_qa = 0,
    pending = 0,
    jml = 0,
    assignedRecruiter = 0,
    not_attempted = 0
  } = totalData;

  dataList.map(d => {
    d.moderatorName = (
      <>
        {d.name} {d.isAbsent ? <span className="isAbsent" /> : null}
      </>
    );
    d.isActive = d.is_active ? "Yes" : "No";
    return d;
  });

  const columns = [
    {
      text: "ID",
      dataField: "moderator_id",
      sort: true
    },
    {
      text: "Moderator Name",
      dataField: "moderatorName",
      sort: true
    },
    {
      text: "Request Volume",
      dataField: "request_volume",
      sort: true
    },
    {
      text: "Is Active",
      dataField: "isActive",
      sort: true
    },
    {
      text: "Recruiter Assigned",
      dataField: "assignedRecruiter",
      sort: true
    },
    {
      text: "Jobs Assigned",
      dataField: "total_assignments",
      sort: true
    },
    {
      text: "Not Attempted",
      dataField: "not_attempted",
      sort: true
    },
    {
      text: "Pending",
      dataField: "pending",
      sort: true
    },
    {
      text: "Ready for QA",
      dataField: "ready_for_qa",
      sort: true
    },
    {
      text: "Job Live",
      dataField: "jml",
      sort: true
    }
  ];
  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  return (
    <>
      <BootstrapTable
        keyField="moderator_id"
        data={dataList}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
      />
      <div className="custom-pagination">
        <table className="table table-hover table-condensed borderless">
          <thead>
            <tr className="listHeding">
              <th>Total</th>
              <th>{dataList.length}</th>
              <th>{request_volume}</th>
              <th />
              <th>{assignedRecruiter}</th>
              <th>{total_assignments}</th>
              <th>{not_attempted}</th>
              <th>{pending}</th>
              <th>{ready_for_qa}</th>
              <th>{jml}</th>
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
};

export default VerifiedList;
