import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useLcsCampaignDetails from "../../../zustandService/useLcsCampaignDetails";
import { bucketTabList } from "./constants";
import styles from "../lcsCampaigiRoot.module.css";
import { CircularProgress } from "@material-ui/core";
import LeadClassificationRules from "./leadClassificationRuleEngine";
import TabsList from "../../TabsList";
import AllowedLeadTypes from "./allowedLeadTypes";

const LeadsClassificationTab = props => {
  const {
    fetchLeadClassificationRules,
    fetchState: {
      leadClassificationRules: leadClassificationRulesFetchState,
      questions: questionsFetchState
    },
    fetchQuestionData,
    questionsData
  } = useLcsCampaignDetails(state => ({
    fetchLeadClassificationRules: state.fetchLeadClassificationRules,
    fetchState: state.fetchState,
    fetchQuestionData: state.fetchQuestionData,
    questionsData: state.questionsData
  }));

  const { id: campaignId } = useParams();
  useEffect(() => {
    fetchQuestionData(campaignId);
  }, []);

  const [activeBucketTab, setActiveBucketTab] = useState("hot");
  useEffect(() => {
    fetchLeadClassificationRules({ campaignId, bucket: activeBucketTab });
  }, [activeBucketTab]);

  if (
    leadClassificationRulesFetchState === "LOADING" ||
    questionsFetchState === "LOADING"
  )
    return (
      <div className={styles.loaderCnt}>
        <CircularProgress
          classes={{ root: styles.loaderStyles }}
          color="inherit"
        />
      </div>
    );
  if (
    leadClassificationRulesFetchState === "ERROR" ||
    questionsFetchState === "ERROR"
  )
    return <div>Something went wrong</div>;
  if (questionsData.questions?.length === 0)
    return <div>Please fill the questionnaire</div>;
  return (
    <>
      <div className="admin-pannel">
        <TabsList
          tabList={bucketTabList}
          isSelected={activeBucketTab}
          onClick={tab => {
            setActiveBucketTab(tab);
          }}
        />
        <div className="gap-20" />
      </div>
      <LeadClassificationRules
        key={activeBucketTab}
        activeBucketTab={activeBucketTab}
      />
      <AllowedLeadTypes />
    </>
  );
};
export default LeadsClassificationTab;
