import React, { useEffect, useState } from "react";
import classes from "./lcsCampaignCreation.module.css";
import { CircularProgress } from "@material-ui/core";
import { saveCampaignQuestion } from "../../services/lcsCampaign";
import { getValueFromURL, isEmpty } from "../../services/utils";
import { useParams } from "react-router-dom";
import { EDIT_CAMPAIGN } from "../../routes/RouteConstants";
import useLcsCampaignDetails from "../../zustandService/useLcsCampaignDetails";
import Heading from "../Common/Form/Heading";
import { questionsTab } from "../Campaign/lcsCampaignConstants";
import LcsCampaignLangForm from "./LcsCampaignLangForm";

const LcsCampaignQuestions = props => {
  const { removeBeforeUnloadHandler } = props;
  const duplicateCampaignId = getValueFromURL("duplicateCampaignId");
  const { id: newCampaignId } = useParams();
  const [duplicateCampaignFlow, setDuplicateCampaignFlow] = useState(null);
  const {
    campaignData,
    lcsMetaData,
    fetchQuestionData,
    interviewDateOptions,
    fetchWalkinData,
    fetchState: { questions: questionsFetchState },
    walkinData,
    isWalkinCampaign,
    resetQuestionsData,
    questionsData: formQuestionsDataState,
    setQuestionsData,
    fetchLeadClassificationRules
  } = useLcsCampaignDetails(state => ({
    campaignData: state.campaignData,
    lcsMetaData: state.lcsMetaData,
    fetchQuestionData: state.fetchQuestionData,
    interviewDateOptions: state.interviewDateOptions,
    fetchState: state.fetchState,
    fetchWalkinData: state.fetchWalkinData,
    walkinData: state.walkinData,
    isWalkinCampaign: state.isWalkinCampaign,
    resetQuestionsData: state.resetQuestionsData,
    questionsData: state.questionsData,
    setQuestionsData: state.setQuestionsData,
    fetchLeadClassificationRules: state.fetchLeadClassificationRules
  }));
  const allLanguages = lcsMetaData?.language || [];
  const {
    default_language: defaultLang = "en",
    allowed_languages: allowedLanguages = [],
    is_conditional: isConditional = false
  } = campaignData;
  const [campaignLangList, addCampaignLangList] = useState([defaultLang]);
  const [saveQuestionCtaDisabled, setSaveQuestionCtaDisabled] = useState(true);
  const [saveQuestionApiError, setSaveQuestionApiError] = useState(null);

  const queryParams = new URLSearchParams(window.location.search);

  const [expandedAccordionLang, setExpandedAccordionLang] =
    useState(defaultLang);

  useEffect(() => {
    setDuplicateCampaignFlow(
      !!duplicateCampaignId && !queryParams.has(questionsTab)
    );
    return () => {
      resetQuestionsData();
    };
  }, []);

  useEffect(() => {
    if (questionsFetchState === "SUCCESS") {
      const campaignLanguages = Object.keys(
        formQuestionsDataState?.questions?.[0]?.question_label ?? {}
      );
      campaignLanguages.sort((a, b) => {
        if (a === defaultLang) return -1;
        if (b === defaultLang) return 1;
        return 0;
      });
      addCampaignLangList(
        campaignLanguages.length ? campaignLanguages : [defaultLang]
      );
    }
  }, [questionsFetchState]);

  useEffect(() => {
    if (duplicateCampaignFlow !== null) {
      getQuestionsData();
      if (isWalkinCampaign) fetchWalkinData(newCampaignId);
      setSaveQuestionCtaDisabled(!duplicateCampaignFlow);
    }
  }, [duplicateCampaignFlow]);

  const getQuestionsData = () => {
    fetchQuestionData(
      duplicateCampaignFlow ? duplicateCampaignId : newCampaignId,
      duplicateCampaignFlow,
      newCampaignId
    );
  };
  /*Formated Data in case of Walking
   * remove date and interview center and status is iactive
   * store inactive data in new state
   */
  const invalidateFormData = (formData, langSubmitted) => {
    setQuestionsData(formData);
    disableSaveQuestionCta(false);
    if (langSubmitted) setExpandedAccordionLang(langSubmitted);
  };

  const removeDuplicateLangForm = (formData, duplicatedLang) => {
    addCampaignLangList(
      campaignLangList.filter(lang => lang !== duplicatedLang)
    );
    invalidateFormData(formData);
  };

  const addDuplicateLangForm = (formData, duplicatedLang) => {
    addCampaignLangList([...campaignLangList, duplicatedLang]);
    invalidateFormData(formData, duplicatedLang);
  };
  const allowedLangList = allLanguages.filter(
    langObj => allowedLanguages.indexOf(langObj.id) !== -1
  );
  const duplicateLangList = allowedLangList.filter(
    langObj => campaignLangList.indexOf(langObj.id) === -1
  );
  const disableSaveQuestionCta = value => {
    setSaveQuestionCtaDisabled(value);
  };

  const onSaveClick = e => {
    if (e) e.preventDefault();
    disableSaveQuestionCta(true);
    delete formQuestionsDataState.dummyOption;

    formQuestionsDataState.questions = formQuestionsDataState.questions.map(
      question => {
        if (question.question_category === "interview_date") {
          question.options = interviewDateOptions;
          question.options = question.options.map(option => {
            option.next_question_id = question.date_next_question_id ?? 0;
            return option;
          });
        }
        question.options = [...question.options, ...question.customOptions];
        delete question.customOptions;
        delete question.date_next_question_id;
        return question;
      }
    );
    const { questions = [] } = formQuestionsDataState;
    // Iterate over the questions

    saveCampaignQuestion({
      ...formQuestionsDataState,
      questions
    })
      .then(() => {
        disableSaveQuestionCta(false);
        if (duplicateCampaignFlow) {
          queryParams.append(questionsTab, true);
          const path = `${EDIT_CAMPAIGN}/${newCampaignId}?${queryParams.toString()}`;
          removeBeforeUnloadHandler();
          window.location.replace(path);
          return;
        }
        getQuestionsData();
        setSaveQuestionCtaDisabled(true);
      })
      .catch(error => {
        disableSaveQuestionCta(false);
        setSaveQuestionApiError(error);
        setTimeout(() => {
          setSaveQuestionApiError(null);
        }, 3000);
      });
  };

  const {
    interview_center_details: interviewCenter = [],
    interview_date_details: interviewDateDetails = {}
  } = walkinData;
  const isWalkinNotFilled =
    (function () {
      return !interviewCenter.some(item => !!item.id);
    })() ||
    (function () {
      return !interviewDateDetails.interview_start_date;
    })();

  return (
    <>
      <div className="gap-20" />
      <Heading heading={`Add Questions & Options`} />
      {questionsFetchState === "LOADING" ? (
        <div className={classes.loaderCnt}>
          <CircularProgress
            classes={{ root: classes.loaderStyles }}
            color="inherit"
          />
        </div>
      ) : (
        <>
          {isWalkinCampaign && isWalkinNotFilled ? (
            <div>Please fill walkin details</div>
          ) : (
            campaignLangList.map((lang, index) => (
              <LcsCampaignLangForm
                key={index}
                lang={lang}
                defaultLang={defaultLang}
                formQuestionsData={formQuestionsDataState}
                allLanguages={allLanguages}
                isConditional={isConditional}
                addDuplicateLangForm={addDuplicateLangForm}
                duplicateLangList={duplicateLangList}
                invalidateFormData={invalidateFormData}
                disableSaveQuestionCta={disableSaveQuestionCta}
                removeDuplicateLangForm={removeDuplicateLangForm}
                expandedAccordionLang={expandedAccordionLang}
                duplicateCampaignFlow={duplicateCampaignFlow}
              />
            ))
          )}
          <div className={classes.saveQueCnt}>
            {saveQuestionApiError ? (
              <span className="text-danger">{saveQuestionApiError}</span>
            ) : null}
            <button
              className="submitBtn"
              disabled={saveQuestionCtaDisabled}
              onClick={onSaveClick}
            >
              Submit Questionnaire
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default LcsCampaignQuestions;
