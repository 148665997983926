function CatSubTabs(props) {
  const { active = "FRESH", onClick } = props;
  const subTabList = [
    { label: "Fresh", id: "FRESH" },
    {
      label: "Overall",
      id: "OVERALL"
    }
  ];
  return (
    <div className="sub-tab">
      <div className="clr gap-20" />
      <ul className="nav">
        {subTabList.map(item => {
          return (
            <li className="nav-item" key={item.id}>
              <span
                className={item.id === active ? "nav-link active" : "nav-link"}
                onClick={() => onClick(item.id)}
              >
                {item.label}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
export default CatSubTabs;
