import React from "react";
import { useFieldArray } from "react-hook-form";
import classes from "./lcsCampaignDetails.module.css";
import BannerDetails from "./BannerDetails";

const PostApplyBanner = ({ control, languageList, name, watch, setError }) => {
  const {
    fields: postApplyFields,
    append,
    remove
  } = useFieldArray({
    name,
    control
  });
  const onAddMoreBanner = () => {
    append({ language: "", heading: "", file: "" }); // Add a new empty banner item
  };

  const showAddMore = postApplyFields.length < languageList.length;
  return (
    <div className={classes.fullWidth}>
      <div className={classes.questioncnt}>
        {postApplyFields.map((item, index) => (
          <BannerDetails
            item={item}
            index={index}
            key={item.id}
            watch={watch}
            name={name}
            control={control}
            languageList={languageList}
            setError={setError}
            remove={remove}
            numberOfBannerFields={postApplyFields.length}
            bannerLabel="Banner"
            bannerHeadingLabel="Form Heading"
          />
        ))}
        {showAddMore ? (
          <div
            type="button"
            onClick={onAddMoreBanner}
            className={classes.addMore}
          >
            Add More
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PostApplyBanner;
