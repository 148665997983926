import React from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import JSFilter from "./JSFilter";
import { getErrorMessage } from "../../services/utils";
import { RECUITERS_LIST } from "../../routes/RouteConstants";
import withRouter from "../../services/withRouter";
class JSDetails extends React.Component {
  constructor(props) {
    super(props);
    const { params = {} } = this.props;
    this.state = {
      jsList: [],
      isLoading: true,
      jobId: params.id || "",
      isButtonDisabled: false,
      filters: {},
      msgData: { msg: "", showMsg: false, msgType: "error" }
    };
  }

  componentDidMount() {
    this.getJobSeeker();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters } = this.state;
    if (filters !== prevState.filters) {
      this.setState({
        filters: filters
      });
      this.getJobSeeker();
    }
  }

  getJobSeeker = () => {
    const { jobId, filters } = this.state;
    JobHai.post(`/get-js-detail?job_id=${jobId}`, {
      filters: filters
    })
      .then(response => {
        let data = response.data.data.js_details;
        this.setState({
          jsList: data,
          isButtonDisabled: false,
          isLoading: false,
          msgData: { msg: "", showMsg: false, msgType: "error" }
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isButtonDisabled: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
        window.scrollTo(0, 0);
      });
  };

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      }
    });
  };

  getSearchResult = objData => {
    this.setState({
      filters: objData,
      jsList: [],
      isButtonDisabled: true,
      msgData: {
        msg: "",
        showMsg: false,
        msgType: ""
      }
    });
  };

  render() {
    const { jsList, isButtonDisabled, jobId } = this.state;
    const total = jsList.length;
    const jsApplied = Object.keys(
      jsList.filter(a => a.status === "js_applied")
    ).length;

    const jsCalled = Object.keys(
      jsList.filter(a => a.status === "js_called")
    ).length;

    const rCalled = Object.keys(
      jsList.filter(a => a.status === "r_called")
    ).length;

    const rShortlisted = Object.keys(
      jsList.filter(a => a.status === "r_shortlisted")
    ).length;

    const rRejected = Object.keys(
      jsList.filter(a => a.status === "r_rejected")
    ).length;

    const noAction = Object.keys(
      jsList.filter(a => a.status === "no_action")
    ).length;

    jsList.map(d => {
      d.createdAt = moment(d.date).format("DD-MM-YYYY, hh:mm A");
      return d;
    });

    jsList.map(d => {
      d.userId = (
        <a
          href={`/job-seeker/edit/${d.user_id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {d.user_id}
        </a>
      );
      return d;
    });

    const columns = [
      {
        text: "",
        dataField: "_status",
        classes: "statusCircle",
        formatter: (cellContent, row) => {
          if (row.status === "js_applied") {
            return <b className="green"></b>;
          }
          if (row.status === "js_called") {
            return <b className="darkblue"></b>;
          }
          if (row.status === "r_called") {
            return <b className="blue"></b>;
          }
          if (row.status === "r_shortlisted") {
            return <b className="yellow"></b>;
          }
          if (row.status === "r_rejected") {
            return <b className="red"></b>;
          }
          return <b className="gray"></b>;
        }
      },
      {
        text: "JS ID",
        dataField: "userId"
      },
      {
        text: "Name",
        dataField: "name",
        sort: true
      },
      {
        text: "Phone No.",
        dataField: "phone",
        sort: true
      },
      {
        text: "Status",
        dataField: "status",
        sort: true
      },
      {
        text: "Source",
        dataField: "source",
        sort: true
      },
      {
        text: "JS Created Date",
        dataField: "createdAt"
      }
    ];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );

    const pagination_options = {
      paginationSize: 4,
      pageStartIndex: 0,
      showTotal: true,
      paginationTotalRenderer: customTotal,
      hideSizePerPage: true,
      sizePerPageList: [
        {
          text: "30",
          value: 30
        },
        {
          text: "50",
          value: 50
        },
        {
          text: "All",
          value: jsList.length
        }
      ]
    };

    if (this.state.isLoading) {
      return <LoadingSpinner key="loading" />;
    } else {
      return (
        <div className="job_seeker">
          <div className="clr gap-20"></div>
          <div className="clr gap-20"></div>
          <PopupMessage
            data={this.state.msgData}
            key="popup"
            click={this.handleClosePopup}
          />
          <div className="pageHeading row">
            <h2>
              Job Seeker <span className="label">(Job Id - {jobId})</span>
              <div className="float-right">
                <Link to={RECUITERS_LIST}>Back</Link>
              </div>
            </h2>
          </div>
          <div className="clr"></div>
          <JSFilter
            statusMessage={this.statusMessage}
            searchApplicant={this.getSearchResult}
            isButtonDisabled={isButtonDisabled}
          />
          <div className="clr gap-20"></div>
          <div className="row sortingWrap">
            <span>
              <b></b>ALL ({total})
            </span>
            <span>
              <b className="green"></b>JS Applied ({jsApplied})
            </span>
            <span>
              <b className="darkblue"></b>JS Called ({jsCalled})
            </span>
            <span>
              <b className="blue"></b>Rec Called ({rCalled})
            </span>
            <span>
              <b className="yellow"></b>Rec Shortlisted ({rShortlisted})
            </span>
            <span>
              <b className="red"></b>Rec Rejected ({rRejected})
            </span>
            <span>
              <b className="gray"></b>No Actions ({noAction})
            </span>
          </div>
          <div className="clr"></div>
          <div className="clr gap-20"></div>
          <div className="whitebox">
            <BootstrapTable
              key="jobSeeker"
              keyField="serialNo"
              data={jsList}
              columns={columns}
              hover
              condensed
              bordered={false}
              loading={true}
              rowClasses="listvalue"
              headerClasses="listHeding"
              classes="borderless"
              pagination={PaginationFactory(pagination_options)}
            />

            <div className="clr"></div>
            <div className="clr gap-20"></div>
          </div>
        </div>
      );
    }
  }
}
export default withRouter(JSDetails);
