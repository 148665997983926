import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../ui/table";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { X } from "lucide-react";
import React, { useState, useMemo } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose
} from "../ui/dialog";

import { Input } from "../ui/input";

import { Checkbox } from "../ui/checkbox";
import { Pagination } from "../ui/pagination";
import useLeadAssignmentStore from "../../zustandService/useLeadAssignmentStore";
import { customUseEffect } from "../../services/utils";
import useCityDataStore from "../../zustandService/useCityDataStore";
import SubmitButton from "./submitButton";
export default function SalesAssignment() {
  const {
    salesCohortdata,
    salesCohortFetchState,
    fetchSalesAssignmentCohort,
    selectedSalesCohort,
    updateSelectedSalesCohort,
    selectedSalesCohortDraft,
    saveSalesAssignmentCohort
  } = useLeadAssignmentStore(state => ({
    salesCohortdata: state.salesCohortdata,
    salesCohortFetchState: state.salesCohortFetchState,
    fetchSalesAssignmentCohort: state.fetchSalesAssignmentCohort,
    selectedSalesCohort: state.selectedSalesCohort,
    updateSelectedSalesCohort: state.updateSelectedSalesCohort,
    selectedSalesCohortDraft: state.selectedSalesCohortDraft,
    saveSalesAssignmentCohort: state.saveSalesAssignmentCohort
  }));
  const { liveCities } = useCityDataStore(state => ({
    liveCities: state.liveCities
  }));

  customUseEffect(() => {
    fetchSalesAssignmentCohort();
  }, []);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const cohorts = useMemo(() => {
    return liveCities.filter(city =>
      city.label.toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);

  const resetToApiData = cohortId => {
    const salesCohortDataFromApi =
      salesCohortdata.filter(item => item.cohortId === cohortId)[0] || [];
    updateSelectedSalesCohort(
      cohortId,
      salesCohortDataFromApi["selectedCohortValues"]?.map(item => {
        return {
          ...item,
          isSelected: true
        };
      }),
      false
    );
  };

  const getCopyObj = (obj, cohortId) => {
    const copyObj = JSON.parse(JSON.stringify(obj));
    return copyObj[cohortId]["selectedCohortValues"];
  };

  const resetDraftState = cohortId => {
    let selectedCohortValues = getCopyObj(selectedSalesCohort, cohortId);
    updateSelectedSalesCohort(cohortId, selectedCohortValues, false);
  };

  const saveDraftToFinalState = cohortId => {
    let selectedCohortValues = getCopyObj(selectedSalesCohortDraft, cohortId);
    updateSelectedSalesCohort(cohortId, selectedCohortValues, false);
  };

  const handleCohortSelect = (city, cohortId) => {
    let selectedCohortValues = getCopyObj(selectedSalesCohortDraft, cohortId);
    const index = selectedCohortValues.findIndex(u => u.id === city._id);
    if (index !== -1) {
      const cohortObj = selectedCohortValues[index];
      selectedCohortValues[index] = {
        ...cohortObj,
        isSelected:
          cohortObj.id === city._id
            ? !cohortObj.isSelected
            : cohortObj.isSelected
      };
    } else {
      selectedCohortValues.push({
        id: city._id,
        label: city.label,
        isSelected: true
      });
    }
    updateSelectedSalesCohort(cohortId, selectedCohortValues, true);
  };
  const checkIfCityIsDisabled = (cohortIdToIgnore, cityId) => {
    for (let cohortId in selectedSalesCohort) {
      if (cohortIdToIgnore == cohortId) {
        // types can be different

        continue;
      }
      if (
        selectedSalesCohort[cohortId]["selectedCohortValues"].findIndex(
          u => u.id === cityId && u.isSelected
        ) !== -1
      ) {
        return true;
      }
    }
    return false;
  };
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const getSelectedValuesInCohort = (cohortId, isDraft = true) => {
    const obj = isDraft ? selectedSalesCohortDraft : selectedSalesCohort;
    return obj[cohortId]["selectedCohortValues"]?.filter(
      item => item.isSelected
    );
  };
  if (salesCohortFetchState === "LOADING") return null;
  return (
    <div>
      <SubmitButton onClick={saveSalesAssignmentCohort} />
      <div className="w-full border rounded-lg">
        <div className="relative w-full overflow-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>

                <TableHead>Cohort</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {salesCohortdata.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>{item.cohortLabel}</TableCell>
                      <TableCell>
                        <div className="flex flex-wrap gap-2">
                          {getSelectedValuesInCohort(item.cohortId, false).map(
                            (item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Badge
                                    className={"cursor-pointer"}
                                    variant={"outline"}
                                  >
                                    {item.label}
                                  </Badge>
                                </React.Fragment>
                              );
                            }
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Dialog>
                          <DialogTrigger>
                            <Button variant="outline" size="sm">
                              Add
                            </Button>
                          </DialogTrigger>
                          <DialogContent
                            handleClose={() => resetDraftState(item.cohortId)}
                            className="sm:max-w-[600px]"
                          >
                            <DialogHeader>
                              <DialogTitle>Assign City</DialogTitle>
                            </DialogHeader>
                            <div className="space-y-4">
                              <Input
                                placeholder="Search City..."
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                              />
                              <div className="max-h-[400px] overflow-auto">
                                <Table>
                                  <TableHeader>
                                    <TableRow>
                                      <TableHead>City</TableHead>

                                      <TableHead>Select</TableHead>
                                    </TableRow>
                                  </TableHeader>
                                  <TableBody>
                                    {cohorts.map(city => (
                                      <TableRow
                                        key={city._id}
                                        className={`${
                                          getSelectedValuesInCohort(
                                            item.cohortId
                                          ).some(u => u.id === city._id)
                                            ? "bg-primary/10"
                                            : ""
                                        }`}
                                      >
                                        <TableCell className="font-medium">
                                          {city.label}
                                        </TableCell>

                                        <TableCell>
                                          <Checkbox
                                            disabled={checkIfCityIsDisabled(
                                              item.cohortId,
                                              city._id
                                            )}
                                            checked={getSelectedValuesInCohort(
                                              item.cohortId
                                            ).some(u => u.id === city._id)}
                                            onCheckedChange={() =>
                                              handleCohortSelect(
                                                city,
                                                item.cohortId
                                              )
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </div>
                              <div className="flex justify-center">
                                <Pagination
                                  currentPage={currentPage}
                                  totalPages={totalPages}
                                  onPageChange={handlePageChange}
                                />
                              </div>
                            </div>
                            <DialogFooter>
                              <DialogClose>
                                <Button
                                  onClick={() => resetToApiData(item.cohortId)}
                                  variant="ghost"
                                >
                                  Reset to Default
                                </Button>
                              </DialogClose>
                              <DialogClose>
                                <Button
                                  onClick={() =>
                                    saveDraftToFinalState(item.cohortId)
                                  }
                                  className="ml-auto"
                                >
                                  Assign City
                                </Button>
                              </DialogClose>
                            </DialogFooter>
                          </DialogContent>
                        </Dialog>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

function MoveVerticalIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="8 18 12 22 16 18" />
      <polyline points="8 6 12 2 16 6" />
      <line x1="12" x2="12" y1="2" y2="22" />
    </svg>
  );
}
