import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { VIEW_SINGLE_REQUEST } from "../../routes/RouteConstants";
import { formattedRequestType } from "../../services/utils";

function ViewRequest({ item }) {
  const { request_id } = item;
  const reqUrl = `${VIEW_SINGLE_REQUEST}?request_id=${request_id}`;

  return (
    <div className="edit_link">
      <a href={reqUrl} target="_blank" rel="noopener noreferrer">
        View
      </a>
    </div>
  );
}

const RequestsList = props => {
  const { dataList = [], isSearching = false } = props;

  const columns = [
    {
      text: "ID",
      dataField: "request_id",
      sort: true
    },
    {
      text: "Request Name",
      dataField: "request_name",
      sort: true
    },
    {
      text: "Request raised by",
      dataField: "name",
      sort: true
    },
    {
      text: "Created On",
      dataField: "created_date",
      sort: true,
      formatter: cellContent =>
        cellContent ? moment(cellContent).format("DD-MM-YYYY") : null
    },
    {
      text: "Status",
      dataField: "status",
      sort: true
    },
    {
      text: "Request Type",
      dataField: "request_type",
      sort: true,
      formatter: cellContent => formattedRequestType(cellContent)
    },
    {
      text: "#Org Ids",
      dataField: "organisation_id_count",
      sort: true
    },
    {
      text: "#Verified recruiter when raised",
      dataField: "verified_recruiter_when_raised",
      sort: true
    },
    {
      text: "#Verified recruiter as on date",
      dataField: "verified_recruiter_now",
      sort: true
    },
    {
      dataField: "company_name",
      formatter: (cellContent, row) => <ViewRequest item={row} />
    }
  ];

  const NoDataIndication = () => (
    <div className="spinner">
      {isSearching ? "Searching..." : "No Data Found"}
    </div>
  );

  return (
    <div className="list-ellipsis">
      <BootstrapTable
        keyField="request_id"
        data={dataList}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default RequestsList;
