import { useState } from "react";
import CustomTextField from "../Common/Form/CustomTextField";
import Label from "../Common/Form/Label";
import { getCustomErrorMessage, getFormattedValue } from "../../services/utils";
import CustomSelect from "../Common/Form/CustomSelect";
import JobHai from "../../api/JobHai";

const CustomerFeedback = ({ jobId, recId }) => {
  const [fields, setFieldItems] = useState({ remarks: "", issue_type: "" });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleChange = ({ target: { name, value } }) => {
    value = getFormattedValue(value);
    setFieldItems(prevValues => ({ ...prevValues, [name]: value }));
  };

  const sendFeedback = () => {
    const obj = { ...fields, recruiter_id: recId, job_id: jobId };
    setLoading(true);
    JobHai.post(`/recruiter-job-feedback`, obj)
      .then(response => {
        setSuccess("Data Saved");
      })
      .catch(error => {
        setError(getCustomErrorMessage(error));
        setLoading(false);
      });
  };

  const clientFeedbackIssueList = [
    { id: "Low number of applies", label: "Low number of applies" },
    { id: "Relevancy issue", label: "Relevancy issue" },
    { id: "Both", label: "Both" }
  ];
  return (
    <div className="sidebarWidgetroot">
      <div className="heading">Client Feedback</div>
      <div className="formRow">
        <div className="fullWidth">
          <Label lable="Issue Type" />
          <CustomSelect
            name="issue_type"
            value={fields.issue_type}
            onChange={handleChange}
            optionsList={clientFeedbackIssueList}
            itemLabel="label"
            optionValue="id"
            defaultPlaceholder="Select Issue Type"
          />
        </div>
      </div>
      <div className="formRow">
        <div className="fullWidth">
          <Label lable="Remarks" />
          <CustomTextField
            fieldName={"remarks"}
            name="remarks"
            value={fields.remarks}
            onChange={handleChange}
            placeholder="Leave your remarks here..."
            textArea={true}
          />
        </div>
      </div>
      <div className="formRow flexend">
        {error && <span className="text-danger">{error}</span>}
        {success && <span className="text-success">{success}</span>}
        {!success ? (
          <input
            type="button"
            value="Share feedback"
            className={"submitBtn"}
            onClick={sendFeedback}
            disabled={!fields.remarks || !fields.issue_type || loading}
          />
        ) : null}
      </div>
    </div>
  );
};
export default CustomerFeedback;
