import React from "react";
import Label from "../Common/Form/Label";
import Chips from "../Common/Chips";
import CustomTextField from "../Common/Form/CustomTextField";
import CustomSelect from "../Common/Form/CustomSelect";
import {
  enterPriseLeadType,
  lcsLeadSource,
  leadPlatform
} from "../../constants/MetaData";

const NonEditableFields = ({
  leadData,
  activeGender,
  onChange,
  onClick,
  genderList
}) => {
  const {
    user_language = "",
    Job_Categories = [],
    full_name = "",
    lead_type = "",
    phone = "",
    lead_source = "",
    moderator_id = "",
    user_id = "",
    source
  } = leadData;
  const userLanguage = user_language || "Not Available";
  const activeLeadPlatform = leadPlatform.find(item => item.id === source);
  let isDisabled = lead_source !== "REFERRAL";
  if (!user_id && !moderator_id) isDisabled = true;
  return (
    <>
      <div className="formRow">
        <div className="left">
          <Label lable="Full Name" />
          <CustomTextField
            name="full_name"
            value={full_name}
            onChange={onChange}
            maxLength={100}
            isReadonly={isDisabled}
          />
        </div>

        <div className="right">
          <Label lable="Lead Type" />
          <CustomSelect
            name="lead_type"
            value={lead_type}
            onChange={onChange}
            optionsList={enterPriseLeadType}
            itemLabel="label"
            optionValue="value"
            isSelectDisabled={true}
            disabled
          />
        </div>
      </div>
      <div className="formRow">
        <div className="left">
          <Label lable="Gender" />
          <Chips
            chips={genderList}
            activeChips={activeGender}
            onClick={onClick}
            isMulti={false}
            fieldName="gender"
            isClose={true}
            isDisabled={isDisabled}
          />
        </div>
        <div className="right">
          <Label lable="Phone Number" />
          <CustomTextField
            name="phone"
            value={phone}
            onChange={onChange}
            maxLength={10}
            isReadonly
          />
        </div>
      </div>
      <div className="formRow">
        <div className="left">
          <Label lable="Lead Source" />
          <Chips
            chips={lcsLeadSource}
            activeChips={{
              label: lead_source,
              id: lead_source
            }}
            onClick={() => {}}
            isMulti={false}
            fieldName="gender"
            isClose={true}
            isDisabled={true}
          />
        </div>
        <div className="left">
          <Label lable="Lead Platfrom" />
          <Chips
            chips={leadPlatform}
            activeChips={activeLeadPlatform}
            onClick={() => {}}
            isMulti={false}
            fieldName="gender"
            isClose={true}
            isDisabled={true}
          />
        </div>
      </div>

      <div className="formRow">
        <div className="left">
          <Label lable="User App Language" />
          <Chips
            chips={[userLanguage]}
            activeChips={{ label: userLanguage, id: userLanguage }}
            onClick={() => {}}
            isMulti={false}
            fieldName="user_language"
            isClose={true}
            isDisabled
          />
        </div>
      </div>
      {Job_Categories.length ? (
        <div className="formRow">
          <div className="left fullWidth">
            <Label lable="Job Categories" />
            <ul className="chips">
              {Job_Categories.map((item, index) => {
                return (
                  <li className="active" key={`li-${index}`}>
                    <div>
                      {item}
                      <img
                        src="/images/cross.svg"
                        alt="cancel"
                        width="16"
                        height="16"
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}

      <div className="seperator" />
    </>
  );
};

export default NonEditableFields;
