import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { RECUITERS_LIST } from "../../routes/RouteConstants";
import {
  formattedRequestType,
  formattedRestriction
} from "../../services/utils";

function ActionLink({ item }) {
  const { verified_recruiter_now, organisation_id } = item;
  const recUrl = `${RECUITERS_LIST}?organisation_id=${organisation_id}`;
  return (
    <div className="edit_link">
      <a href={recUrl} target="_blank" rel="noopener noreferrer">
        {verified_recruiter_now}
      </a>
    </div>
  );
}

const RequestOrgList = props => {
  const { dataList = [], isSearching = false } = props;

  const sumResult = {
    verified_recruiter_when_raised: 0,
    verified_recruiter_now: 0
  };

  const totalSum = dataList.reduce((acc, curr) => {
    acc.verified_recruiter_when_raised +=
      curr.verified_recruiter_when_raised || 0;
    acc.verified_recruiter_now += curr.verified_recruiter_now || 0;
    return acc;
  }, sumResult);

  const columns = [
    {
      text: "Org ID",
      dataField: "organisation_id",
      sort: true,
      footer: "Total"
    },
    {
      text: "Organisation Name",
      dataField: "company_name",
      sort: true,
      footer: `${dataList.length} organisations`
    },
    {
      text: "Current Status",
      dataField: "org_status",
      sort: true,
      formatter: cellContent => formattedRestriction(cellContent),
      footer: ""
    },
    {
      text: "Current Restriction",
      dataField: "request_type",
      sort: true,
      formatter: cellContent => formattedRequestType(cellContent),
      footer: ""
    },
    {
      text: "#Verified recruiter when raised",
      dataField: "verified_recruiter_when_raised",
      sort: true,
      footer: `${totalSum.verified_recruiter_when_raised}`
    },
    {
      text: "#Verified recruiter as on date",
      dataField: "verified_recruiter_now",
      sort: true,
      formatter: (cellContent, row) => <ActionLink item={row} />,
      footer: `${totalSum.verified_recruiter_now}`
    }
  ];

  const NoDataIndication = () => (
    <div className="spinner">
      {isSearching ? "Searching..." : "No Data Found"}
    </div>
  );

  return (
    <div className="list-ellipsis">
      <BootstrapTable
        keyField="request_id"
        data={dataList}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
        footerClasses="table-footer"
      />
    </div>
  );
};

export default RequestOrgList;
