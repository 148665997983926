import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Page404 from "./Page404";
import { routeConfig } from "../routes/routeConfig";

const LoginRoutes = ({ isLogin = true }) => {
  return (
    <Routes>
      {routeConfig.map(({ path, component }) => (
        <Route
          key={path}
          exact
          path={path}
          element={<PrivateRoute>{component}</PrivateRoute>}
        />
      ))}
      {isLogin ? <Route path="*" element={<Page404 />} /> : null}
    </Routes>
  );
};
export default LoginRoutes;
