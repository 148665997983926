import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import JobHai from "../../api/JobHai";
import MetaData from "../../constants/MetaData";
import PopupMessage from "../PopupMessage";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import { getErrorMessage, checkPermission } from "../../services/utils";
import { AGENT_LEADS } from "../../routes/RouteConstants";
import withRouter from "../../services/withRouter";

class AgentLeadsEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msgData: { msg: "", showMsg: false, msgType: "error" }
    };
  }

  handleChange = event => {
    if (event.target.name === "city_id") {
      this.setState({ locality_id: "" });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  componentDidMount() {
    this.getLeads();
  }

  getLeads = () => {
    const { params = {} } = this.props;
    const { id = "" } = params;
    console.log(params, this.props);
    JobHai.get(`/v2/getLead?lead_id=${id}`)
      .then(response => {
        let responseData = response.data.data;
        let companyData = responseData.AppCompany;
        let leadData = companyData.AgentLead ? companyData.AgentLead : [];
        let agentInfo = responseData.agent_info ? responseData.agent_info : [];
        this.setState({
          company_id: companyData.company_id,
          recruiter_id: responseData.recruiter_id,
          company_name: companyData.company_name
            ? companyData.company_name
            : "",
          company_contact_info: companyData.company_contact_info
            ? companyData.company_contact_info
            : "",
          company_website: companyData.company_website
            ? companyData.company_website
            : "",
          company_type: companyData.company_type
            ? companyData.company_type
            : "",
          company_email: companyData.company_email
            ? companyData.company_email
            : "",
          company_address: companyData.company_address
            ? companyData.company_address
            : "",
          city_id: companyData.city_id ? companyData.city_id : "",
          locality_id: companyData.locality_id ? companyData.locality_id : "",
          company_about: companyData.company_about
            ? companyData.company_about
            : "",
          company_logo: companyData.company_logo
            ? companyData.company_logo
            : "",
          updatedAt: moment(companyData.updatedAt).format(
            "YYYY-MM-DD hh:mm:ss"
          ),
          recruiter_name: responseData.full_name ? responseData.full_name : "",
          recruiter_contact_info: responseData.phone ? responseData.phone : "",
          recruiter_email: responseData.email ? responseData.email : "",
          is_consultant: !responseData.is_consultant
            ? ""
            : responseData.is_consultant
            ? "1"
            : "0",
          denial_reason: responseData.denial_reason
            ? responseData.denial_reason
            : "",
          designation: responseData.designation ? responseData.designation : "",
          remarks: responseData.remarks ? responseData.remarks : "",
          company_perks: companyData.company_perks
            ? companyData.company_perks
            : "",
          status: responseData.status ? responseData.status : "",
          lead_status: leadData.status ? leadData.status : "",
          lead_remarks: leadData.remarks ? leadData.remarks : "",
          tentative_next_opening_date: companyData.tentative_next_opening_date,
          company_size: companyData.company_size
            ? companyData.company_size
            : "",
          industry_type: companyData.industry_type
            ? companyData.industry_type
            : "",
          agent_name: agentInfo.agent_name ? agentInfo.agent_name : "NA",
          agent_phone: agentInfo.agent_phone ? agentInfo.agent_phone : "NA"
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
      });
  };

  submitRecruiterForm = e => {
    e.preventDefault();
    const obj = {
      recruiter_id: this.state.recruiter_id,
      company_id: this.state.company_id,
      company_name: this.state.company_name,
      company_contact_info: this.state.company_contact_info
        ? this.state.company_contact_info
        : null,
      company_website: this.state.company_website,
      company_type: this.state.company_type ? this.state.company_type : null,
      company_email: this.state.company_email,
      company_address: this.state.company_address,
      city_id: this.state.city_id ? this.state.city_id : null,
      locality_id: this.state.locality_id ? this.state.locality_id : null,
      company_about: this.state.company_about,
      full_name: this.state.recruiter_name,
      email: this.state.recruiter_email,
      denial_reason: this.state.denial_reason ? this.state.denial_reason : null,
      designation: this.state.designation,
      company_perks: this.state.company_perks,
      lead_status: this.state.lead_status,
      remarks: this.state.remarks,
      lead_remarks: this.state.lead_remarks,
      tentative_next_opening_date: moment(
        this.state.tentative_next_opening_date
      ).format("YYYY-MM-DD"),
      company_size: this.state.company_size,
      industry_type: this.state.industry_type,
      is_consultant: this.state.is_consultant ? this.state.is_consultant : null
    };
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        obj[key] = null;
      }
    });
    JobHai.post("/v2/updateLead", obj)
      .then(response => {
        this.setState({
          msgData: {
            msg: "Data has been saved",
            showMsg: true,
            msgType: "success"
          }
        });
        window.scrollTo(0, 0);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
        window.scrollTo(0, 0);
      });
  };

  render() {
    const isAllowEdit = !checkPermission("Agent Leads", true);
    return (
      <div className="edit-form">
        <div className="clr gap-20"></div>
        <div className="clr gap-20"></div>
        <PopupMessage
          key={"msg"}
          data={this.state.msgData}
          click={this.handleClosePopup}
        />
        <div className="pageHeading row">
          <h2>
            Edit Agent Leads
            <div className="float-right">
              <a href={AGENT_LEADS}>Go Back</a>
            </div>
          </h2>
        </div>
        {this.state.recruiter_id && (
          <div className="whiteCard edit-form">
            <div className="container-fluid">
              <div>
                <h2>
                  Company Details{" "}
                  <span>Company/Lead Id - {this.state.company_id} </span>
                  <span>
                    Agent : {this.state.agent_name} ({this.state.agent_phone})
                  </span>
                </h2>
              </div>
              <div className="gap-10"></div>
              <form noValidate autoComplete="off">
                <div className="form-row">
                  <div className="col-md-3">
                    <label>Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_name"
                      value={this.state.company_name}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_address"
                      value={this.state.company_address}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <SelectCity
                      filedName={"city_id"}
                      value={this.state.city_id}
                      onChange={this.handleChange}
                      label={"City"}
                    />
                  </div>
                  <div className="col-md-3">
                    <SelectLocality
                      filedName={"locality_id"}
                      value={this.state.locality_id}
                      cityId={this.state.city_id}
                      onChange={this.handleChange}
                      label={"Locality"}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-3">
                    <label htmlFor="company_email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_email"
                      value={this.state.company_email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Contact Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_contact_info"
                      value={this.state.company_contact_info}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Company Type</label>
                    <b className="downArrow"></b>
                    <select
                      className="form-control"
                      name="company_type"
                      value={this.state.company_type}
                      onChange={this.handleChange}
                    >
                      {MetaData.company_type.map(option => (
                        <option value={option} key={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label>Website</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_website"
                      required
                      value={this.state.company_website}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-3">
                    <label htmlFor="naukri_banner_url">Logo</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_logo"
                      value={this.state.company_logo}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Perks</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.company_perks}
                      name="company_perks"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>About</label>
                    <textarea
                      className="form-control"
                      rows="1"
                      name="company_about"
                      value={this.state.company_about}
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-3">
                    <label>Industry Type</label>
                    <input
                      type="text"
                      className="form-control"
                      name="industry_type"
                      value={this.state.industry_type}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Tentative Date Of Opening</label>
                    <DatePicker
                      selected={Date.parse(
                        this.state.tentative_next_opening_date
                      )}
                      onChange={value => {
                        this.setState({ tentative_next_opening_date: value });
                      }}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Company Size</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.company_size}
                      name="company_size"
                      onChange={event =>
                        this.setState({
                          company_size: event.target.value.replace(/\D/, "")
                        })
                      }
                    />
                  </div>
                </div>
                <div className="gap-10"></div>
                <h2>
                  Recruiter Details
                  <span>
                    <a
                      href={`/recruiters/applicant/${this.state.recruiter_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Recruiter Id - {this.state.recruiter_id}
                    </a>
                  </span>
                </h2>
                {this.state.status === "VERIFIED" && (
                  <div className="check"></div>
                )}
                <div className="gap-10"></div>
                <div className="form-row">
                  <div className="col-md-3">
                    <label>Recruiter Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="recruiter_name"
                      value={this.state.recruiter_name}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="recruiter_email">Recruiter Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="recruiter_email"
                      value={this.state.recruiter_email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Recruiter Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="recruiter_contact_info"
                      value={this.state.recruiter_contact_info}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Recruiter Designation</label>
                    <input
                      type="text"
                      className="form-control"
                      name="designation"
                      value={this.state.designation}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-1">
                    <label>Denial Reason</label>
                    <b className="downArrow"></b>
                    <select
                      className="form-control"
                      name="denial_reason"
                      value={this.state.denial_reason}
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      <option value="DENIED">Denied</option>
                      <option value="JOB_SEEKER">Job Seeker</option>
                      <option value="BLACKLIST">Blacklist</option>
                    </select>
                  </div>
                  <div className="col-md-1">
                    <label>Consultant</label>
                    <b className="downArrow"></b>
                    <select
                      className="form-control"
                      name="is_consultant"
                      value={this.state.is_consultant}
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>Remarks</label>
                    <textarea
                      name="remarks"
                      value={this.state.remarks}
                      onChange={this.handleChange}
                      className="form-control"
                      rows="2"
                    />
                  </div>
                </div>
                <div className="gap-20"></div>
                <h2>Lead Status</h2>
                <div className="form-row">
                  <div className="col-md-3">
                    <label>Lead Status</label>
                    <b className="downArrow"></b>
                    <select
                      className="form-control"
                      name="lead_status"
                      onChange={this.handleChange}
                      value={this.state.lead_status}
                    >
                      <option value="">Select</option>
                      <option value="Qualified">Qualified</option>
                      <option value="UnQualified - Consultant">
                        Unqualified - Consultant
                      </option>
                      <option value="UnQualified - Fake">
                        Unqualified - Fake
                      </option>
                      <option value="UnQualified - Others">
                        Unqualified - Other
                      </option>
                    </select>
                  </div>
                  <div className="col-md-9">
                    <label>Remarks</label>
                    <textarea
                      className="form-control"
                      rows="1"
                      name="lead_remarks"
                      value={this.state.lead_remarks}
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="gap-10"></div>
                <div className="bttn txt-align-right">
                  <span> Last Updated On : {this.state.updatedAt} </span>
                  <button
                    onClick={this.submitRecruiterForm}
                    className="bttn"
                    disabled={isAllowEdit}
                  >
                    SAVE
                  </button>
                </div>
                <div className="gap-20"></div>
              </form>
            </div>
          </div>
        )}
        <div className="gap-20"></div>
      </div>
    );
  }
}
export default withRouter(AgentLeadsEditForm);
