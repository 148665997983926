import React, { useState } from "react";
import { isEmpty } from "../../../services/utils";

const CustomSelect = props => {
  const [isActive, updateState] = useState(false);
  const onFocus = () => {
    updateState(true);
  };
  const onBlur = () => {
    updateState(false);
  };

  const {
    fullWidth = true,
    name = "",
    value = "",
    itemLabel = "label",
    optionsList = [],
    onChange,
    optionValue = "value",
    allowDisabled = false,
    isSelectDisabled = false,
    defaultPlaceholder = "Select",
    disabled = false,
    isError = false,
    customCls
  } = props;
  const handleOnchange = e => {
    updateState(false);
    onChange(e);
  };
  const selectCls = isActive ? "select selectActive" : "select";
  let className = fullWidth ? `${selectCls} fullWidth` : selectCls;
  className = isError ? `${className} borderRed` : className;
  return (
    <div className="selectBox">
      <select
        name={name}
        value={!isEmpty(value) ? value : ""}
        onChange={handleOnchange}
        className={customCls ? customCls : className}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      >
        <option value="" disabled={isSelectDisabled}>
          {defaultPlaceholder}
        </option>
        {optionsList.map((option, index) => (
          <option
            value={option[optionValue]}
            key={`recB${index}-${option[itemLabel]}`}
            disabled={allowDisabled && !option.is_clickable}
          >
            {option[itemLabel]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelect;
