import { openDB } from "idb";

const DB_NAME = "crm-db";
const DB_VERSION = 2;
const STORE_NAME = "jobhai-crm";

// Initialize the database
export const initDB = async () => {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db, oldVersion, newVersion, transaction) {
      // Check if the store exists
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, {
          keyPath: "id",
          autoIncrement: true
        });
      } else {
        // Handle migrations or data reset on version update
        if (oldVersion < newVersion) {
          // Example: Clear the store on version update
          transaction.objectStore(STORE_NAME).clear();
        }
      }
    }
  });
};

// Get the database instance
export const getDB = async () => {
  return initDB();
};

// Add an item to the store
export const addItemIndexedDb = async item => {
  try {
    const db = await getDB();
    const tx = db.transaction(STORE_NAME, "readwrite");
    const store = tx.objectStore(STORE_NAME);

    // Handle autoIncrement and avoid conflicts
    if ("id" in item) {
      const existingItem = await store.get(item.id);

      if (existingItem) {
        console.warn(
          `Item with id ${item.id} already exists. Consider using updateItem instead.`
        );
        return null;
      }
    }
    const result = await store.add(item);
    await tx.done;
    return result;
  } catch (error) {
    console.error("Error adding item to IndexedDB:", error);
    return null;
  }
};

// Get an item by id
export const getItemIndexedDb = async id => {
  try {
    const db = await getDB();
    const tx = db.transaction(STORE_NAME, "readonly");
    const store = tx.objectStore(STORE_NAME);

    const result = await store.get(id);
    await tx.done;

    if (result === undefined) {
      console.warn(`No item found with id: ${id}`);
    }

    return result;
  } catch (error) {
    console.error(`Error getting item with id ${id} from IndexedDB:`, error);
    return undefined;
  }
};

// Update an item in the store
export const updateItemIndexedDb = async item => {
  try {
    const db = await getDB();
    const tx = db.transaction(STORE_NAME, "readwrite");
    const store = tx.objectStore(STORE_NAME);

    if (!item.id) {
      console.error("Item must have an id to be updated.");
      return undefined;
    }

    const result = await store.put(item);
    await tx.done;

    return result;
  } catch (error) {
    console.error("Error updating item in IndexedDB:", error);
    return undefined;
  }
};

// Delete an item by id
export const deleteItemIndexedDb = async id => {
  try {
    const db = await getDB();
    const tx = db.transaction(STORE_NAME, "readwrite");
    const store = tx.objectStore(STORE_NAME);

    await store.delete(id);
    await tx.done;

    console.log(`Item with id ${id} deleted successfully.`);
  } catch (error) {
    console.error(`Error deleting item with id ${id} from IndexedDB:`, error);
  }
};

// Clear all items from the store
export const clearStoreIndexedDb = async () => {
  try {
    const db = await getDB();
    const tx = db.transaction(STORE_NAME, "readwrite");
    const store = tx.objectStore(STORE_NAME);

    await store.clear();
    await tx.done;

    console.log("Store cleared successfully");
  } catch (error) {
    if (error.name === "AbortError") {
      console.error("Transaction aborted while clearing store:", error);
    } else {
      console.error("Failed to clear store:", error);
    }
  }
};
