import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import PopupMessage from "../PopupMessage";
import { ENTERPRISE_LEADS } from "../../routes/RouteConstants";
import JobHai from "../../api/JobHai";
import {
  checkPermission,
  getErrorMessage,
  isObjectEmpty,
  getQueryString,
  getValueFromURL
} from "../../services/utils";
import LoadingSpinner from "../LoadingSpinner";
import NoAccess from "../Common/NoAccess";
import LeadsList from "./LeadsList";
import LeadsFilter from "./LeadsFilter";
import LeadBucket from "./LeadBucket";
import LeadSharingStatus from "./LeadSharingStatus";
import { enterPriseLeadBucket } from "../../constants/MetaData";
import ProxyModeratorName from "../Common/ProxyModeratorName";
import HeadingRow from "../Common/HeadingRow";
import LcsClients from "../Common/LcsClients";
import {
  getInterviewCenter,
  getLCSLeadMetadata
} from "../../services/metadataApiServices";

const ViewLeads = () => {
  const showLeadsAdmin = checkPermission("EnterpriseLeadsAdmin");
  const isAccess = checkPermission("Enterprise Leads") || showLeadsAdmin;
  const clientId = getValueFromURL("client");
  const bucket = getValueFromURL("bucket");
  const interviewDate = getValueFromURL("interview_date_tab");
  const { type } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [leadsList, setLeadsList] = useState([]);
  const [selectedLead, setSelectedLeads] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [leadsCount, setLeadCount] = useState(0);
  const [msgData, setMessage] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });
  const [filters, setFilters] = useState({});
  const [leadModeratorList, setModeratorList] = useState([]);
  const [callingAttempts, setCallingAttempts] = useState(3);
  const [interviewCenterList, setInterviewCenterList] = useState([]);
  const [additionalBucket, setAdditionalBucket] = useState([]);
  const [bucketCount, setBucketCount] = useState([]);

  useEffect(() => {
    if (isAccess) {
      getLeadModerators();
      getLCSLeadMetadataInfo();
      getBucketCount();
      getInterviewCenterList();
    }
  }, [isAccess]);

  useEffect(() => {
    const { follow_up_date } = filters;
    let updatedFilters = {
      ...filters
    };
    if (isAccess) {
      if (bucket === "Followup" && !follow_up_date) {
        updatedFilters = {
          ...updatedFilters,
          follow_up_date: moment(new Date()).format("YYYY-MM-DD")
        };
      }
      getLeads(updatedFilters);
    }
  }, [activePage, isAccess]);

  const getLeads = (filters = {}, isDownload = false) => {
    const page = activePage ? Number(activePage) - 1 : 0;
    let queryString = "";
    const leadBucket = filters?.lead_bucket || bucket;

    filters = {
      ...filters,
      client_id: clientId,
      lead_bucket: leadBucket
    };
    if (!leadBucket) {
      filters.interview_date_tab = interviewDate;
    }
    if (!isObjectEmpty(filters)) {
      let filterList = filters;
      setFilters(filters);
      const leadShared = filterList.lead_shared;
      if (leadBucket && Number(leadBucket)) {
        filterList["not_connected_count"] = Number(leadBucket);
        delete filterList.lead_bucket;
      }
      if (leadShared) {
        filterList["lead_bucket"] = leadShared;
        delete filterList.lead_shared;
      }
      if (leadBucket !== "Followup") {
        delete filterList.follow_up_date;
      }
      queryString = getQueryString(filterList);
      if (filters.moderator_id) {
        queryString = queryString.replace(
          "moderator_id=unassign",
          "moderator_id=null"
        );
      }
    }
    const apiURL = `/lcs-leads`;
    let url = `${apiURL}?page_number=${page}&size=100`;
    if (isDownload) {
      url = `${apiURL}?isDownload=true`;
    }
    if (queryString) {
      url = `${url}&${queryString}`;
    }

    JobHai.get(url)
      .then(response => {
        let data = response.data.data;
        const { leads = [], total_count = 0 } = data;
        setLeadsList(leads);
        setLeadCount(total_count);
        setLoading(false);
        setButtonDisabled(false);
        setSelectedLeads([]);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        setMessage({ msg, showMsg: true, msgType: "error" });
        setLoading(false);
        setButtonDisabled(false);
      });
  };

  const handleClosePopup = () => {
    setMessage({ msg: "", showMsg: false });
  };

  const onPageChange = page => {
    setActivePage(page);
  };

  const searchLeads = (filters, isDownload = false) => {
    setActivePage(1);
    setButtonDisabled(true);
    getLeads(filters, isDownload);
  };

  const handleSelectedItems = (isSelect, rows, selectedAll = false) => {
    let newLead = [...selectedLead];
    let leadIds = [];
    if (selectedAll) {
      leadIds = isSelect ? rows.map(item => item.lead_user_id) : [];
      setSelectedLeads(leadIds);
    } else {
      leadIds = rows.lead_user_id;
      if (isSelect) {
        newLead = [...newLead, leadIds];
      } else {
        newLead.splice(newLead.indexOf(leadIds), 1);
      }
      setSelectedLeads(newLead);
    }
  };

  const getUpdatedLeads = () => {
    getLeads(filters);
  };

  const getLeadModerators = () => {
    let url =
      "/moderator-assignment-weightage?is_lead=true&is_new_city_tab=false";
    if (clientId) url += `&client_id=${clientId}`;
    JobHai.get(url)
      .then(response => {
        const data = response.data.data;
        setModeratorList(data);
      })
      .catch(error => {
        console.log("Error: ", error);
      });
  };

  const getLCSLeadMetadataInfo = () => {
    getLCSLeadMetadata(clientId)
      .then(res => {
        const { call_attempts = 5, additional_bucket = [] } = res;
        setCallingAttempts(call_attempts);
        setAdditionalBucket(additional_bucket);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getInterviewCenterList = () => {
    getInterviewCenter(clientId)
      .then(interviewCenters => {
        setInterviewCenterList(interviewCenters);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const handleError = error => {
    setMessage({ error, showMsg: true, msgType: "error" });
  };

  const getBucketCount = () => {
    if (clientId) {
      JobHai.get(
        `/get-lead-bucket-count?client_id=${clientId}&is_admin=${showLeadsAdmin}`
      )
        .then(response => {
          const { data = [] } = response.data;
          setBucketCount(data);
        })
        .catch(error => {
          handleError(getErrorMessage(error));
        });
    }
  };

  let goBackURL = ENTERPRISE_LEADS;
  let allowAccess =
    isAccess &&
    type &&
    (type === "bucket" || type === "moderator-view" || type === "admin-view");

  let activeBucket = interviewDate || "";
  if (bucket) {
    const isValidBucket = enterPriseLeadBucket.filter(
      item => item.value == bucket
    );

    if (isValidBucket.length) {
      activeBucket = isValidBucket[0].value;
      goBackURL = `${ENTERPRISE_LEADS}/${type}`;
      if (clientId) goBackURL = `${goBackURL}?client=${clientId}`;
    } else {
      allowAccess = false;
    }
  }

  const adminOnly = showLeadsAdmin && type === "admin-view";
  if (isLoading && isAccess) {
    return <LoadingSpinner />;
  }

  let reassignModList = [
    ...leadModeratorList,
    { moderator_id: "unassign", moderator_name: "Unassign" }
  ];
  if (filters?.moderator_id) {
    reassignModList = reassignModList.filter(
      item => item.moderator_id !== filters.moderator_id
    );
  }
  const phoneNumber = filters.phone ? filters.phone : "";
  return (
    <>
      <ProxyModeratorName />
      <PopupMessage data={msgData} click={handleClosePopup} />
      <HeadingRow heading="Leads" goBackURL={goBackURL}>
        <LcsClients
          type={type}
          url={`${ENTERPRISE_LEADS}/${type}`}
          clientId={clientId}
        />
      </HeadingRow>
      {allowAccess && (
        <>
          <LeadBucket
            activeBucket={activeBucket}
            clientId={clientId}
            type={type}
            callingAttempts={callingAttempts}
            additionalBucket={additionalBucket}
            bucketCount={bucketCount}
          />
          <LeadsFilter
            searchLeads={searchLeads}
            isDisabled={isButtonDisabled}
            leadsList={leadsList}
            adminOnly={adminOnly}
            activeBucket={activeBucket}
            leadModeratorList={leadModeratorList}
            callingAttempts={callingAttempts}
            interviewCenterList={interviewCenterList}
            clientId={clientId}
            type={type}
          />
          {adminOnly && (
            <LeadSharingStatus
              selectedLead={selectedLead}
              getUpdatedLeads={getUpdatedLeads}
              leadModeratorList={reassignModList}
            />
          )}
        </>
      )}
      <div className="clr gap-20" />
      <div className={allowAccess ? "whitebox" : "whiteCard"}>
        {allowAccess ? (
          <LeadsList
            dataList={leadsList}
            activePage={activePage}
            itemsCountPerPage={100}
            totalItemsCount={leadsCount}
            onPageChange={onPageChange}
            isSearching={isButtonDisabled}
            adminOnly={adminOnly}
            handleSelectedItems={handleSelectedItems}
            selectedLead={selectedLead}
            clientId={clientId}
            phone={phoneNumber}
          />
        ) : (
          <NoAccess />
        )}
        <div className="clr gap-20" />
      </div>
    </>
  );
};

export default ViewLeads;
