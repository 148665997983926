import JobHai from "../../api/JobHai";

const styles = theme => ({
  widgetRot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .viewLogContainer": { padding: "10px" },
    "& .view-history": { width: "100%" }
  },
  root: {
    color: "#414858",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    background: "#FFFFFF",
    border: "1px solid rgba(233, 236, 245, 0.6)",
    borderRadius: "10px",
    marginBottom: "20px",
    "& .flexCol": {
      display: "flex",
      flexDirection: "column"
    },
    "& .buttonContainer": {
      justifyContent: "flex-end",
      marginLeft: "4px !important"
    },
    "& .btn3": {
      width: "140px",
      height: "44px",
      padding: "0px"
    },
    "& .btn2": {
      width: "82px",
      backgroundColor: "#ffffff",
      color: "#2a89f6",
      border: "1px solid #2a89f6",
      marginLeft: "4px !important"
    },
    "& .justifyCenter": {
      justifyContent: "center"
    },
    "& .center": {
      alignItems: "center"
    },
    "& .flex": {
      display: "flex"
    },
    "& .disabled": {
      opacity: 0.5
    }
  },

  padding: {
    padding: "16px 12px"
  },
  header: {
    background: "rgba(233, 236, 245, 0.3)",
    border: "1px solid #F1F3F9",
    borderRadius: "10px",
    color: "#41485A",
    "& .container2Child1": {
      marginRight: "16px"
    },
    "& .selectBox": { display: "flex", alignItems: "center" },
    "& .selectBox .select": {
      background: "#ffffff",
      border: "1px solid #e2e5ef",
      backgroundImage: `url(/images/arrow_down.svg)`,
      backgroundPosition: "right 16px center",
      backgroundRepeat: "no-repeat",
      borderRadius: "6px",
      minWidth: "120px",
      fontWeight: 600,
      fontSize: "15px",
      color: "#2e2e36",
      padding: "12px 16px",
      outline: "none",
      appearance: "none"
    },
    "& .container": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: 0
    }
  },
  container2: {
    "& :last-child": {
      marginLeft: "auto"
    }
  },
  errorState: {
    color: "red",
    fontStyle: "italic",
    display: "inline-block",
    fontWeight: "500"
  },
  successState: {
    display: "flex",
    alignItems: "center",
    "& .btn2": {
      width: "124px",
      alignItems: "center"
    },
    "& :last-child": {
      marginLeft: "0px"
    }
  }
});

const saveJobBoost = async data => {
  const res = await JobHai.post("update-boost-logs-list", data);
  return res.data.data;
};

const getBoostLogs = async jobId => {
  const res = await JobHai.post("get-boost-logs-list", { job_id: jobId });
  return res.data.data;
};

export { styles, saveJobBoost, getBoostLogs };
