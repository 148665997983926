import { useState, useMemo } from "react";
import { Button } from "../../ui/button";

import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../ui/tabs";
import { Card, CardHeader, CardTitle, CardContent } from "../../ui/card";
// import {
//   Select,
//   SelectTrigger,
//   SelectValue,
//   SelectContent,
//   SelectItem
// } from "../../ui/select";

import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../ui/table";

import StateRenderer from "./stateRenderer";
import useLeadRecruiterDetailStore from "../../../zustandService/useLeadRecruiterDetailStore";
import moment from "moment";
// import { User2Icon } from 'lucide-react';

// function ArrowUpDownIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="m21 16-4 4-4-4" />
//       <path d="M17 20V4" />
//       <path d="m3 8 4-4 4 4" />
//       <path d="M7 4v16" />
//     </svg>
//   );
// }

let RecentSales = () => {
  const {
    setSalesData,
    fetchRecentSales,
    recentSalesState,
    salesSelectedTab,
    recentSales
  } = useLeadRecruiterDetailStore(state => ({
    recentSalesState: state.recentSalesState,
    fetchRecentSales: state.fetchRecentSales,
    salesSelectedTab: state.salesSelectedTab,
    setSalesData: state.setSalesData,
    recentSales: state.recentSales
  }));
  // const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const searchParams = window.location.search || "";
  const queryParams = new URLSearchParams(searchParams);
  const recId = queryParams.get("recruiterId");

  const totalPages = Math.ceil(recentSales.totalSales / itemsPerPage);
  // const startIndex = (page - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const currentPageData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = pageNumber => {
    setPage(pageNumber);
    fetchRecentSales({
      pageNo: pageNumber,
      tab: salesSelectedTab,
      recruiterId: recId
    });
  };
  return !salesSelectedTab ? (
    <>
      <h1 className=" mb-2 text-xl font-semibold ">
        Recent sales(Select Company/Recruiter to view details)
      </h1>
      <Tabs
        onValueChange={v => {
          setSalesData(v, "salesSelectedTab");
          fetchRecentSales({ pageNo: 1, tab: v, recruiterId: recId });
        }}
        value={salesSelectedTab}
      >
        <TabsList className="grid grid-cols-2">
          <TabsTrigger value="company">Company</TabsTrigger>
          <TabsTrigger value="recruiter">Recruiter</TabsTrigger>
        </TabsList>
        <TabsContent value="company">
          <h1 className="mt-6 text-xl font-semibold ">
            Recent sales - Company{" "}
          </h1>
        </TabsContent>
        <TabsContent value="recruiter">
          <h1 className="mt-6 text-xl font-semibold ">
            Recent sales - Recruiter{" "}
          </h1>
        </TabsContent>
      </Tabs>
    </>
  ) : (
    <StateRenderer state={recentSalesState}>
      <Tabs
        onValueChange={v => {
          setSalesData(v, "salesSelectedTab");
          fetchRecentSales({ pageNo: 1, tab: v, recruiterId: recId });
        }}
        value={salesSelectedTab}
      >
        <TabsList className="grid grid-cols-2">
          <TabsTrigger value="company">Company</TabsTrigger>
          <TabsTrigger value="recruiter">Recruiter</TabsTrigger>
        </TabsList>
        <TabsContent value="company">
          <h1 className="mt-6 text-xl font-semibold ">
            Recent sales - Company{" "}
          </h1>
        </TabsContent>
        <TabsContent value="recruiter">
          <h1 className="mt-6 text-xl font-semibold ">
            Recent sales - Recruiter{" "}
          </h1>
        </TabsContent>
      </Tabs>
      <div className="grid gap-4 mt-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium">
              Total Collection
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {recentSales?.totalCollection || 0}
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium">
              Number of Sales
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {recentSales?.totalSales || 0}
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium">
              Credits Purchased
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {recentSales?.totalCredits || 0}
            </div>
            {/* <p className="text-xs text-muted-foreground">
                  +8% from last month
                </p> */}
          </CardContent>
        </Card>
      </div>
      <div className="flex items-center justify-between mt-6 mb-4"></div>
      {recentSales?.totalSales > 0 ? (
        <>
          <Card>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell>Recruiter ID</TableCell>

                  <TableCell>Recruiter</TableCell>
                  <TableCell>Order ID</TableCell>

                  <TableHead className="cursor-pointer">
                    Credit
                    {/* {sort.key === "credit" && (
                      <span className="ml-1">
                        {sort.order === "asc" ? "\u2191" : "\u2193"}
                      </span>
                    )} */}
                  </TableHead>
                  <TableHead className="cursor-pointer">
                    Value
                    {/* {sort.key === "value" && (
                      <span className="ml-1">
                        {sort.order === "asc" ? "\u2191" : "\u2193"}
                      </span>
                    )} */}
                  </TableHead>
                  <TableHead className="cursor-pointer">
                    Purchase Date
                  </TableHead>
                  <TableHead className="cursor-pointer">Expiry Date</TableHead>
                  <TableHead className="cursor-pointer">Agent</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {recentSales?.saleDetails?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.recruiterId}</TableCell>
                    <TableCell>{item.recruiterName}</TableCell>
                    <TableCell>{item.orderId}</TableCell>

                    <TableCell>{item.credit}</TableCell>
                    <TableCell>{item.value ? `₹${item.value}` : "-"}</TableCell>
                    <TableCell>
                      {item.purchaseDate
                        ? moment(item.purchaseDate).format("DD-MM-YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {item.expiryDate
                        ? moment(item.expiryDate).format("DD-MM-YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell>{item.agent}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                disabled={page === 1}
                onClick={() => handlePageChange(page - 1)}
              >
                Previous
              </Button>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                pageNumber => (
                  <Button
                    key={pageNumber}
                    variant={page === pageNumber ? "primary" : "outline"}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </Button>
                )
              )}
              <Button
                variant="outline"
                disabled={page === totalPages}
                onClick={() => handlePageChange(page + 1)}
              >
                Next
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </StateRenderer>
  );
};

export default RecentSales;
