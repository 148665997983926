import React from "react";
import JobHai from "../../api/JobHai";
import MetaData from "../../constants/MetaData";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import { getCustomErrorMessage } from "../../services/utils";

class ViewCompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    const { email = "", is_email_verified = false } = props.companyData;
    this.state = {
      recruiterEmail: email,
      verificationStatus: is_email_verified ? "Verified" : ""
    };
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  updateEmailStatus = e => {
    e.preventDefault();
    const { recruiterEmail, verificationStatus } = this.state;
    const { companyData } = this.props;
    const {
      company_id = "",
      recruiter_id = "",
      company_name = ""
    } = companyData;
    console.log(companyData);
    const obj = {
      recruiter_id,
      company_id,
      email: recruiterEmail,
      verified_email: verificationStatus === "Verified" ? recruiterEmail : null,
      company_name
    };
    JobHai.post("/v2/save_recruiter", obj)
      .then(response => {
        // handle success
        this.props.statusMessage(
          "Email verification status has been updated",
          false
        );
      })
      .catch(error => {
        let msg = getCustomErrorMessage(error);
        this.props.statusMessage(msg, true);
      });
  };

  renderEmailStatusUpdate = () => {
    const { verificationStatus } = this.state;
    const { isAllowEdit } = this.props;
    return (
      <div className="form-row">
        <div className="col-md-2">
          <label>Verification Status</label>
          <b className="downArrow"></b>
          <select
            className="form-control"
            name="verificationStatus"
            onChange={this.handleChange}
            value={verificationStatus}
          >
            <option value="">Select</option>
            <option value="Unverified">Unverified</option>
            <option value="Verified">Verified</option>
          </select>
        </div>
        <div className="col-md-2">
          <label>&nbsp;</label>
          <div>
            <button
              onClick={this.updateEmailStatus}
              className="btn btn-primary"
              disabled={isAllowEdit}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { companyData = {}, isEmailVerify = false } = this.props;
    const { recruiterEmail } = this.state;
    return (
      <div className="whiteCard">
        <div className="container-fluid">
          <div>
            <h2>
              Company Details{" "}
              <span>Company ID - {companyData.company_id} </span>
            </h2>
          </div>
          <div className="gap-10"></div>
          <div className="form-row">
            <div className="col-md-3">
              <label>Company Name</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={companyData.company_name ? companyData.company_name : ""}
              />
            </div>
            <div className="col-md-3">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={
                  companyData.company_address ? companyData.company_address : ""
                }
              />
            </div>
            <div className="col-md-3">
              <label>City</label>
              <SelectCity
                filedName={"city_id"}
                value={companyData.city_id ? companyData.city_id : ""}
                isDisabled={true}
              />
            </div>
            <div className="col-md-3">
              <label>Locality</label>
              <SelectLocality
                filedName={"locality_id"}
                value={companyData.locality_id ? companyData.locality_id : ""}
                isDisabled={true}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-3">
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={
                  companyData.company_email ? companyData.company_email : ""
                }
              />
            </div>
            <div className="col-md-3">
              <label>Contact Number</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={
                  companyData.company_contact_info
                    ? companyData.company_contact_info
                    : ""
                }
              />
            </div>
            <div className="col-md-3">
              <label>Company Type</label>
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="company_type"
                value={companyData.company_type ? companyData.company_type : ""}
                disabled
              >
                {MetaData.company_type.map(option => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <label>Website</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={
                  companyData.company_website ? companyData.company_website : ""
                }
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-3">
              <label>Logo</label>
              <div>
                {companyData.logo_url ? (
                  <img
                    src={companyData.logo_url}
                    alt={companyData.company_name}
                    width="40"
                    style={{ marginRight: "10px" }}
                  />
                ) : null}
              </div>
            </div>
            <div className="col-md-3">
              <label>Whatsapp Contact</label>
              <input
                type="text"
                className="form-control"
                name="whatsapp_contact"
                value={companyData.whatsapp_contact}
                readOnly
              />
            </div>
            <div className="col-md-2">
              <label>Whatsapp Enable</label>
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="isWhatsappEnabled"
                value={companyData.isWhatsappEnabled}
                disabled
              >
                <option value="">Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>

            <div className="col-md-4">
              <label>About</label>
              <textarea
                className="form-control"
                rows="1"
                name="company_about"
                value={companyData.company_about}
                readOnly
              ></textarea>
            </div>
          </div>
          <div className="gap-10"></div>
          <div>
            <h2>
              Recruiter Details{" "}
              <span>
                <a
                  href={`/recruiters/applicant/${companyData.recruiter_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Recruiter Id - {companyData.recruiter_id}
                </a>
              </span>
            </h2>
          </div>
          <div className="gap-10"></div>
          <div className="form-row">
            <div className="col-md-3">
              <label>Recruiter Name</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={companyData.full_name ? companyData.full_name : ""}
              />
            </div>
            <div className="col-md-3">
              <label>Recruiter Email</label>
              <input
                type="text"
                className="form-control"
                value={recruiterEmail}
                name="recruiterEmail"
                readOnly={!isEmailVerify}
                onChange={this.handleChange}
              />
            </div>

            <div className="col-md-2">
              <label>Recruiter Number</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={companyData.phone ? companyData.phone : ""}
              />
            </div>
            <div className="col-md-2">
              <label>Recruiter Designation</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={companyData.designation ? companyData.designation : ""}
              />
            </div>
            <div className="col-md-2">
              <label>Recruiter Bucket</label>
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="denial_reason"
                value={
                  companyData.denial_reason ? companyData.denial_reason : ""
                }
                disabled
              >
                <option value="">Select</option>
                <option value="DENIED">Denied</option>
                <option value="JOB_SEEKER">Job Seeker</option>
                <option value="BLACKLIST">Black list</option>
                <option value="GRAYLIST">Gray list</option>
              </select>
            </div>
            <div className="col-md-3">
              <label>Recruiter Status</label>
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="verification_status"
                value={companyData.verification_status}
                disabled
              >
                {MetaData.recruiterStatus.map(option => (
                  <option value={option.value} key={`recS${option.value}`}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-1">
              <label>Consultant</label>
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="is_consultant"
                value={companyData.is_consultant}
                disabled
              >
                <option value="">Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>

            <div className="col-md-6">
              <label>Remarks</label>
              <textarea
                name="remarks"
                value={companyData.remarks}
                onChange={this.handleChange}
                className="form-control"
                rows="2"
                readOnly
              />
            </div>
          </div>
          {isEmailVerify ? this.renderEmailStatusUpdate() : null}
          <div className="bttn txt-align-right">
            {companyData.is_email_verified ? (
              <span className="verified">
                <i className="fa fa-check" aria-hidden="true"></i> Email
                Verified
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default ViewCompanyDetails;
