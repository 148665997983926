import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import CustomePagination from "../../Common/Pagination";
import Heading from "../../Common/Form/Heading";

const OTLDataList = ({ dataList, cancelRun }) => {
  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  const columns = [
    {
      text: "Dag Id",
      dataField: "dag_id",
      sort: true
    },
    {
      text: "Run Id",
      dataField: "dag_run_id",
      sort: true
    },
    {
      text: "State",
      dataField: "state",
      sort: true
    },
    { text: "Run Type", dataField: "run_type", sort: true },
    {
      text: "start_date",
      dataField: "start_date",
      sort: true,
      formatter: (cellContent, row) => {
        return row.start_date
          ? moment(row.start_date).format("DD-MM-YYYY hh:mm:ss")
          : "NA";
      }
    },
    {
      text: "#",
      dataField: "delete",
      formatter: (cellContent, row) => {
        return (
          <>
            {row.state === "running" ? (
              <div
                className="cursor-pointer text-danger font-weight-bold font-italic"
                onClick={() => cancelRun(row)}
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-remove" aria-hidden="true" />
              </div>
            ) : (
              <i className="fa fa-remove" aria-hidden="true" />
            )}
          </>
        );
      }
    }
  ];
  return (
    <>
      <hr /> <div className="gap-20" />
      <Heading heading="Run Status" />
      <>
        <BootstrapTable
          keyField="campaign_id"
          data={dataList}
          columns={columns}
          hover
          condensed
          bordered={false}
          loading={true}
          rowClasses="listvalue"
          headerClasses="listHeding"
          classes="borderless"
          noDataIndication={() => <NoDataIndication />}
        />
        <CustomePagination
          activePage={1}
          itemsCountPerPage={100}
          totalItemsCount={dataList.length}
          showPagination={false}
        />
      </>
      <div className="gap-20" />
    </>
  );
};

export default OTLDataList;
