import { withStyles } from "@material-ui/styles";
import cx from "classnames";
import { styles } from "./helper";
function AfterSuccessCTA({ label = "Deactivate more", onCancel }) {
  return (
    <div className={cx("flex", "justifyCenter")}>
      <button
        type="button"
        onClick={onCancel}
        className={cx("submitBtn", "flex", "justifyCenter", "btn2", "btn3")}
      >
        <img src="/images/refresh.svg" alt="refresh" width="18" height="18" />
        {label}
      </button>
    </div>
  );
}

export default withStyles(styles)(AfterSuccessCTA);
