let assets = {
  bg: [
    { name: "Blue-W", link: "/images/bg/bg-00001.png", color: "bg-blue-600" },
    { name: "Blue-X", link: "/images/bg/bg-00002.png", color: "bg-blue-600" },
    { name: "Blue-Y", link: "/images/bg/bg-00003.png", color: "bg-blue-600" },
    { name: "Blue-Z", link: "/images/bg/bg-00004.png", color: "bg-blue-600" },
    { name: "Green-W", link: "/images/bg/bg-00005.png", color: "bg-green-600" },
    { name: "Green-X", link: "/images/bg/bg-00006.png", color: "bg-green-600" },
    { name: "Green-Y", link: "/images/bg/bg-00007.png", color: "bg-green-600" },
    { name: "Green-Z", link: "/images/bg/bg-00008.png", color: "bg-green-600" },
    { name: "Red-W", link: "/images/bg/bg-00009.png", color: "bg-red-600" },
    { name: "Red-X", link: "/images/bg/bg-00010.png", color: "bg-red-600" },
    { name: "Red-Y", link: "/images/bg/bg-00011.png", color: "bg-red-600" },
    { name: "Red-Z", link: "/images/bg/bg-00012.png", color: "bg-red-600" },
    {
      name: "Yellow-W",
      link: "/images/bg/bg-00013.png",
      color: "bg-yellow-600"
    },
    {
      name: "Yellow-X",
      link: "/images/bg/bg-00014.png",
      color: "bg-yellow-600"
    },
    {
      name: "Yellow-Y",
      link: "/images/bg/bg-00015.png",
      color: "bg-yellow-600"
    },
    {
      name: "Yellow-Z",
      link: "/images/bg/bg-00016.png",
      color: "bg-yellow-600"
    },
    {
      name: "Purple-W",
      link: "/images/bg/bg-00017.png",
      color: "bg-purple-600"
    },
    {
      name: "Purple-X",
      link: "/images/bg/bg-00018.png",
      color: "bg-purple-600"
    },
    {
      name: "Purple-Y",
      link: "/images/bg/bg-00019.png",
      color: "bg-purple-600"
    },
    {
      name: "Purple-Z",
      link: "/images/bg/bg-00020.png",
      color: "bg-purple-600"
    },
    {
      name: "Orange-W",
      link: "/images/bg/bg-00021.png",
      color: "bg-orange-600"
    },
    {
      name: "Orange-X",
      link: "/images/bg/bg-00022.png",
      color: "bg-orange-600"
    },
    {
      name: "Orange-Y",
      link: "/images/bg/bg-00023.png",
      color: "bg-orange-600"
    },
    {
      name: "Orange-Z",
      link: "/images/bg/bg-00024.png",
      color: "bg-orange-600"
    },
    { name: "Teal-W", link: "/images/bg/bg-00025.png", color: "bg-teal-600" },
    { name: "Teal-X", link: "/images/bg/bg-00026.png", color: "bg-teal-600" },
    { name: "Teal-Y", link: "/images/bg/bg-00027.png", color: "bg-teal-600" },
    { name: "Teal-Z", link: "/images/bg/bg-00028.png", color: "bg-teal-600" },
    {
      name: "Indigo-W",
      link: "/images/bg/bg-00029.png",
      color: "bg-indigo-600"
    },
    {
      name: "Indigo-X",
      link: "/images/bg/bg-00030.png",
      color: "bg-indigo-600"
    },
    {
      name: "Indigo-Y",
      link: "/images/bg/bg-00031.png",
      color: "bg-indigo-600"
    },
    {
      name: "Indigo-Z",
      link: "/images/bg/bg-00032.png",
      color: "bg-indigo-600"
    },
    { name: "Brown-W", link: "/images/bg/bg-00033.png", color: "bg-brown-600" },
    { name: "Brown-X", link: "/images/bg/bg-00034.png", color: "bg-brown-600" },
    { name: "Brown-Y", link: "/images/bg/bg-00035.png", color: "bg-brown-600" },
    { name: "Brown-Z", link: "/images/bg/bg-00036.png", color: "bg-brown-600" },
    { name: "Pink-W", link: "/images/bg/bg-00037.png", color: "bg-pink-600" },
    { name: "Pink-X", link: "/images/bg/bg-00038.png", color: "bg-pink-600" },
    { name: "Pink-Y", link: "/images/bg/bg-00039.png", color: "bg-pink-600" },
    { name: "Pink-Z", link: "/images/bg/bg-00040.png", color: "bg-pink-600" },
    { name: "Lime-W", link: "/images/bg/bg-00041.png", color: "bg-lime-600" },
    { name: "Lime-X", link: "/images/bg/bg-00042.png", color: "bg-lime-600" },
    { name: "Lime-Y", link: "/images/bg/bg-00043.png", color: "bg-lime-600" },
    { name: "Lime-Z", link: "/images/bg/bg-00044.png", color: "bg-lime-600" },
    { name: "Gray-W", link: "/images/bg/bg-00045.png", color: "bg-gray-600" },
    { name: "Gray-X", link: "/images/bg/bg-00046.png", color: "bg-gray-600" },
    { name: "Gray-Y", link: "/images/bg/bg-00047.png", color: "bg-gray-600" },
    { name: "Gray-Z", link: "/images/bg/bg-00048.png", color: "bg-gray-600" },
    { name: "Cyan-W", link: "/images/bg/bg-00049.png", color: "bg-cyan-600" }
  ],
  fg: [
    { name: "Fg-1", link: "/images/fg/fg-00001.png" },
    { name: "Fg-2", link: "/images/fg/fg-00002.png" },
    { name: "Fg-3", link: "/images/fg/fg-00003.png" },
    { name: "Fg-4", link: "/images/fg/fg-00004.png" },
    { name: "Fg-5", link: "/images/fg/fg-00005.png" },
    { name: "Fg-6", link: "/images/fg/fg-00006.png" },
    { name: "Fg-7", link: "/images/fg/fg-00007.png" },
    { name: "Fg-8", link: "/images/fg/fg-00008.png" },
    { name: "Fg-9", link: "/images/fg/fg-00009.png" },
    { name: "Fg-10", link: "/images/fg/fg-00010.png" },
    { name: "Fg-11", link: "/images/fg/fg-00011.png" },
    { name: "Fg-12", link: "/images/fg/fg-00012.png" },
    { name: "Fg-13", link: "/images/fg/fg-00013.png" },
    { name: "Fg-14", link: "/images/fg/fg-00014.png" },
    { name: "Fg-15", link: "/images/fg/fg-00015.png" },
    { name: "Fg-16", link: "/images/fg/fg-00016.png" },
    { name: "Fg-17", link: "/images/fg/fg-00017.png" },
    { name: "Fg-18", link: "/images/fg/fg-00018.png" },
    { name: "Fg-19", link: "/images/fg/fg-00019.png" },
    { name: "Fg-20", link: "/images/fg/fg-00020.png" },
    { name: "Fg-21", link: "/images/fg/fg-00021.png" },
    { name: "Fg-22", link: "/images/fg/fg-00022.png" },
    { name: "Fg-23", link: "/images/fg/fg-00023.png" },
    { name: "Fg-24", link: "/images/fg/fg-00024.png" },
    { name: "Fg-25", link: "/images/fg/fg-00025.png" },
    { name: "Fg-26", link: "/images/fg/fg-00026.png" },
    { name: "Fg-27", link: "/images/fg/fg-00027.png" },
    { name: "Fg-28", link: "/images/fg/fg-00028.png" },
    { name: "Fg-29", link: "/images/fg/fg-00029.png" },
    { name: "Fg-30", link: "/images/fg/fg-00030.png" },
    { name: "Fg-31", link: "/images/fg/fg-00031.png" },
    { name: "Fg-32", link: "/images/fg/fg-00032.png" },
    { name: "Fg-33", link: "/images/fg/fg-00033.png" },
    { name: "Fg-34", link: "/images/fg/fg-00034.png" },
    { name: "Fg-35", link: "/images/fg/fg-00035.png" },
    { name: "Fg-36", link: "/images/fg/fg-00036.png" },
    { name: "Fg-37", link: "/images/fg/fg-00037.png" },
    { name: "Fg-38", link: "/images/fg/fg-00038.png" },
    { name: "Fg-39", link: "/images/fg/fg-00039.png" },
    { name: "Fg-40", link: "/images/fg/fg-00040.png" },
    { name: "Fg-41", link: "/images/fg/fg-00041.png" },
    { name: "Fg-42", link: "/images/fg/fg-00042.png" },
    { name: "Fg-43", link: "/images/fg/fg-00043.png" },
    { name: "Fg-44", link: "/images/fg/fg-00044.png" },
    { name: "Fg-45", link: "/images/fg/fg-00045.png" },
    { name: "Fg-46", link: "/images/fg/fg-00046.png" },
    { name: "Fg-47", link: "/images/fg/fg-00047.png" },
    { name: "Fg-48", link: "/images/fg/fg-00048.png" },
    { name: "Fg-49", link: "/images/fg/fg-00049.png" },
    { name: "Fg-50", link: "/images/fg/fg-00050.png" },
    { name: "Fg-51", link: "/images/fg/fg-00051.png" },
    { name: "Fg-52", link: "/images/fg/fg-00052.png" },
    { name: "Fg-53", link: "/images/fg/fg-00053.png" },
    { name: "Fg-54", link: "/images/fg/fg-00054.png" },
    { name: "Fg-55", link: "/images/fg/fg-00055.png" },
    { name: "Fg-56", link: "/images/fg/fg-00056.png" },
    { name: "Fg-57", link: "/images/fg/fg-00057.png" },
    { name: "Fg-58", link: "/images/fg/fg-00058.png" },
    { name: "Fg-59", link: "/images/fg/fg-00059.png" },
    { name: "Fg-60", link: "/images/fg/fg-00060.png" },
    { name: "Fg-61", link: "/images/fg/fg-00061.png" },
    { name: "Fg-62", link: "/images/fg/fg-00062.png" },
    { name: "Fg-63", link: "/images/fg/fg-00063.png" },
    { name: "Fg-64", link: "/images/fg/fg-00064.png" },
    { name: "Fg-65", link: "/images/fg/fg-00065.png" },
    { name: "Fg-66", link: "/images/fg/fg-00066.png" },
    { name: "Fg-67", link: "/images/fg/fg-00067.png" },
    { name: "Fg-68", link: "/images/fg/fg-00068.png" },
    { name: "Fg-69", link: "/images/fg/fg-00069.png" },
    { name: "Fg-70", link: "/images/fg/fg-00070.png" },
    { name: "Fg-71", link: "/images/fg/fg-00071.png" },
    { name: "Fg-72", link: "/images/fg/fg-00072.png" },
    { name: "Fg-73", link: "/images/fg/fg-00073.png" },
    { name: "Fg-74", link: "/images/fg/fg-00074.png" },
    { name: "Fg-75", link: "/images/fg/fg-00075.png" },
    { name: "Fg-76", link: "/images/fg/fg-00076.png" },
    { name: "Fg-77", link: "/images/fg/fg-00077.png" },
    { name: "Fg-78", link: "/images/fg/fg-00078.png" },
    { name: "Fg-79", link: "/images/fg/fg-00079.png" },
    { name: "Fg-80", link: "/images/fg/fg-00080.png" },
    { name: "Fg-81", link: "/images/fg/fg-00081.png" },
    { name: "Fg-82", link: "/images/fg/fg-00082.png" },
    { name: "Fg-83", link: "/images/fg/fg-00083.png" },
    { name: "Fg-84", link: "/images/fg/fg-00084.png" },
    { name: "Fg-85", link: "/images/fg/fg-00085.png" },
    { name: "Fg-86", link: "/images/fg/fg-00086.png" },
    { name: "Fg-87", link: "/images/fg/fg-00087.png" },
    { name: "Fg-88", link: "/images/fg/fg-00088.png" },
    { name: "Fg-89", link: "/images/fg/fg-00089.png" },
    { name: "Fg-90", link: "/images/fg/fg-00090.png" },
    { name: "Fg-91", link: "/images/fg/fg-00091.png" },
    { name: "Fg-92", link: "/images/fg/fg-00092.png" },
    { name: "Fg-93", link: "/images/fg/fg-00093.png" },
    { name: "Fg-94", link: "/images/fg/fg-00094.png" },
    { name: "Fg-95", link: "/images/fg/fg-00095.png" },
    { name: "Fg-96", link: "/images/fg/fg-00096.png" }
  ],
  brand: [
    { name: "Airtel", link: "/images/brand/Logo/Airtel.png" },
    { name: "Amazon", link: "/images/brand/Logo/Amazon.png" },
    { name: "Bigbasket", link: "/images/brand/Logo/Bigbasket.png" },
    { name: "Blinkit", link: "/images/brand/Logo/blinkit.png" },
    { name: "Bluesmart", link: "/images/brand/Logo/Bluesmart.png" },
    { name: "Cars24", link: "/images/brand/Logo/cars24.png" },
    { name: "Cognizant", link: "/images/brand/Logo/cognizant.png" },
    { name: "Credgenics", link: "/images/brand/Logo/Credgenics.png" },
    { name: "Delhivery", link: "/images/brand/Logo/Delhivery.png" },
    { name: "G4S", link: "/images/brand/Logo/G4S.png" },
    { name: "Garageworks", link: "/images/brand/Logo/Garageworks.png" },
    { name: "Gobolt", link: "/images/brand/Logo/Gobolt.png" },
    { name: "IBM", link: "/images/brand/Logo/IBM.png" },
    { name: "Infosys", link: "/images/brand/Logo/Infosys.png" },
    { name: "Jio", link: "/images/brand/Logo/Jio.png" },
    { name: "Magenta", link: "/images/brand/Logo/Magenta.png" },
    { name: "Makemytrip", link: "/images/brand/Logo/Makemytrip.png" },
    { name: "No Broker", link: "/images/brand/Logo/No Broker.png" },
    { name: "Onsitego", link: "/images/brand/Logo/onsitego.png" },
    { name: "Rapido", link: "/images/brand/Logo/rapido.png" },
    { name: "Revv", link: "/images/brand/Logo/revv.png" },
    { name: "Swiggy", link: "/images/brand/Logo/Swiggy.png" },
    { name: "Tata Motors", link: "/images/brand/Logo/Tata Motors.png" },
    { name: "Uber", link: "/images/brand/Logo/Uber.png" },
    { name: "UC", link: "/images/brand/Logo/UC.png" },
    { name: "Walmart", link: "/images/brand/Logo/Walmart.png" },
    { name: "Wipro", link: "/images/brand/Logo/Wipro.png" },
    { name: "Zepto", link: "/images/brand/Logo/Zepto.png" },
    { name: "Zomato", link: "/images/brand/Logo/Zomato.png" },
    { name: "WTICabs", link: "/images/brand/Logo/WTICabs.png" },
    { name: "EverestFleet", link: "/images/brand/Logo/EverestFleet.png" },
    { name: "FleetPro", link: "/images/brand/Logo/FleetPro.png" }
  ],
  button: [
    { color: "bg-white   text-gray-800" },
    { color: "bg-teal-600  text-white" },
    { color: "bg-indigo-600  text-white" },
    { color: "bg-green-600 text-white" },
    { color: "bg-blue-600 text-white" },
    { color: "bg-rose-600 text-white" },
    { color: "bg-yellow-600 text-white" },
    { color: "bg-orange-600 text-white" }
  ],
  jobHaiLogo: [
    {
      name: "Job Hai Transparent",
      link: "/images/brand/Logo/JobHaiTransparent.png"
    },
    { name: "Job Hai", link: "/images/brand/Logo/JobHai.png" }
  ]
};

export default assets;
