import { useState, useEffect } from "react";
import moment from "moment";
import PopupMessage from "../PopupMessage";
import { JOBS } from "../../routes/RouteConstants";
import LoadingSpinner from "../LoadingSpinner";
import ManualJobList from "./ManualJobList";
import CustomePagination from "../Common/Pagination";
import {
  checkPermission,
  getErrorMessage,
  getFormattedValue
} from "../../services/utils";
import NoAccess from "../Common/NoAccess";
import JobHai from "../../api/JobHai";
import ManualJDFilters from "./ManualJDFilters";
import HeadingRow from "../Common/HeadingRow";

const ManualJDAuditing = () => {
  const [isLoading, setLoading] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [saveRowId, setSaveRowId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [msgData, setMessageData] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });
  const [filters, setFilters] = useState({
    start_date: "", //new Date(),
    end_date: "", //new Date(),
    status: "Pending",
    jobPlan: ""
  });

  const [jobList, setJobList] = useState([]);
  const [jobCount, setJobCount] = useState(0);

  function handleClosePopup() {
    setMessageData({ msg: "", showMsg: false });
  }
  function handlePageChange(page) {
    setActivePage(page);
  }

  useEffect(() => {
    getJobs();
  }, [activePage]);

  function getJobs() {
    const page = activePage ? Number(activePage) - 1 : 0;
    const { start_date, end_date, status, jobPlan } = filters;
    let url = `/get-manual-jd-audit-data?status=${status}&page=${page}&size=50`;
    if (start_date)
      url += `&start_date=${moment(start_date).format("YYYY-MM-DD")}`;
    if (end_date) url += `&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
    if (jobPlan) url += `&jobPlan=${jobPlan}`;
    setSaveRowId("");
    JobHai.get(url)
      .then(response => {
        const { job_data = [], count = 0 } = response.data.data;
        setJobList(job_data);
        setJobCount(count);
        setLoading(false);
        setButtonDisabled(false);
      })
      .catch(error => {
        const msg = getErrorMessage(error);
        setMessageData({ msg, showMsg: true, msgType: "error" });
        setLoading(false);
        setButtonDisabled(false);
      });
  }

  function searchJobs() {
    const { start_date, end_date, status } = filters;
    setMessageData({ msg: "", showMsg: false, msgType: "error" });
    if (!status) {
      setMessageData({
        msg: "Please select status",
        showMsg: true,
        msgType: "error"
      });
      return;
    }
    if (start_date > end_date) {
      setMessageData({
        msg: "End date must be greater than start date",
        showMsg: true,
        msgType: "error"
      });
      return;
    }
    setActivePage(1);
    setButtonDisabled(true);
    getJobs();
  }

  function handleOnChange(field, value) {
    const { start_date, end_date, status } = filters;
    setFilters({
      start_date,
      end_date,
      status,
      [field]: value
    });
  }

  function saveJobs(item) {
    const { job_id, job_description = "", suggested_jds = [] } = item;
    const { status } = filters;
    setSaveRowId(job_id);
    setErrorMsg("");
    const suggestedJD = suggested_jds || [];
    const selectedItems = suggestedJD.filter(item => item.is_selected === true);
    if (selectedItems.length === 0 && !job_description) {
      setErrorMsg("Please add valid job description");
      return;
    }
    JobHai.post("/save-manual-jd-audit-data", { job_id, job_description })
      .then(response => {
        setTimeout(() => {
          if (status === "Pending") getJobs();
          else updateIndexValue("is_jd_audited", true, job_id);
        }, 1000);
      })
      .catch(error => {
        const msg = getErrorMessage(error);
        setMessageData({ msg, showMsg: true, msgType: "error" });
        setSaveRowId("");
      });
  }

  function onFieldsChange(e, jobId) {
    let { name, value = "" } = e.target;
    value = getFormattedValue(value);
    updateIndexValue(name, value, jobId, true);
  }

  function updateIndexValue(key, value, jobId, isChange = false) {
    const listArr = JSON.parse(JSON.stringify(jobList));
    const foundIndex = listArr.findIndex(item => item.job_id === jobId);
    const curJobId = listArr.find(item => item.job_id === jobId);
    if (curJobId) {
      curJobId[key] = value;
      if (isChange) curJobId["is_jd_audited"] = false;
      listArr[foundIndex] = curJobId;
      setJobList(listArr);
    }
    setSaveRowId("");
  }

  const isAccess = checkPermission("Manual JD Auditing");
  if (isLoading && isAccess) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <div className="clr gap-20" />
        <PopupMessage data={msgData} click={handleClosePopup} />
        <HeadingRow heading=" Manual JD Auditing" goBackURL={JOBS} />
        <div className="clr gap-20" />
        <ManualJDFilters
          filters={filters}
          handleOnChange={handleOnChange}
          searchJobs={searchJobs}
          isDisabled={isButtonDisabled}
        />
        <div className="clr gap-20" />
        <div className="whitebox crawled-rec">
          {isAccess ? (
            <>
              <div className="react-bootstrap-table">
                <table className="table table-hover table-condensed borderless">
                  <thead>
                    <tr className="listHeding">
                      <th width="60">Job ID</th>
                      <th width="90">Recruiter Id</th>
                      <th width="250">Job Title</th>
                      <th width="300">Job Category/Role</th>
                      <th width="350">Suggested Job Description</th>
                      <th>Manual JD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobCount > 0 ? (
                      <>
                        {jobList.map((item, index) => {
                          return (
                            <ManualJobList
                              dataList={item}
                              handleChange={onFieldsChange}
                              handleSave={saveJobs}
                              saveRowId={saveRowId}
                              key={`manaul-jd-list${index}-${item.job_id}`}
                              errorMsg={errorMsg}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={6} align="center">
                            <div className="spinner">No Data Found</div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="clr" />
              <CustomePagination
                activePage={activePage}
                itemsCountPerPage={50}
                totalItemsCount={jobCount}
                onPageChange={handlePageChange}
                showPagination={jobCount > 0}
              />
              <div className="clr gap-20" />
            </>
          ) : (
            <NoAccess />
          )}
        </div>
      </>
    );
  }
};

export default ManualJDAuditing;
