import React from "react";
import CreatableSelect from "react-select/creatable";
import { useController } from "react-hook-form";
import { FormControl, FormHelperText } from "@material-ui/core";

const SelectCreateNew = ({
  name,
  label,
  control,
  defaultValue,
  customClass,
  helpText,
  fullWidth = true,
  options,
  rules,
  addNewLabel = "Client",
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === "create-option") {
      // Handle creation here if needed
    }
    field.onChange(newValue);
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      error={fieldState.error}
      classes={{ root: customClass }}
    >
      <label htmlFor={name}>{label}</label>
      <CreatableSelect
        id={name}
        name={name}
        options={options}
        onChange={handleChange}
        value={field.value}
        formatCreateLabel={inputText => `Add New ${addNewLabel} - ${inputText}`}
        {...rest}
      />
      {fieldState.error && (
        <FormHelperText>{fieldState.error.message}</FormHelperText>
      )}
      {helpText && <FormHelperText error={false}>{helpText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectCreateNew;
