import React from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import MetaData from "../../constants/MetaData";
import { getErrorMessage } from "../../services/utils";
import { getCrmCityData } from "../../services/metadataApiServices";

class MiniSearch extends React.Component {
  constructor(props) {
    super(props);
    let cleaningStatusDefault = "";
    let callingStatusDefault = "";
    let qaStatusDefault = "NEW";
    let jobApprovaStatusDefault = "";
    let jobLiveStatusDefault = "";
    if (props.page !== "edited") {
      cleaningStatusDefault = props.role === "QA" ? "CLEANED" : "NEW";
      callingStatusDefault = props.role === "QA" ? "READY_FOR_QA" : "NEW";
      jobApprovaStatusDefault = props.role === "QA" ? "VERIFIED" : "NEW";
      jobLiveStatusDefault = props.role === "QA" ? "READY_FOR_QA" : "NEW";
    }
    this.state = {
      isLoading: true,
      job_city: [],
      source: [],
      cityList: [],
      phone: "",
      is_email_verified: "",
      document_status: "",
      crm_cleaning_status: cleaningStatusDefault,
      crm_calling_status: callingStatusDefault,
      crm_qa_status: qaStatusDefault,
      job_approval_status: jobApprovaStatusDefault,
      job_calling_status: "",
      job_live_status: jobLiveStatusDefault,
      repeating_recruiter: "",
      entity_doc_status: "",
      msgData: { msg: "", showMsg: false, msgType: "error" },
      isButtonDisabled: false,
      sdate: "",
      edate: "",
      jobPlan: ""
    };
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  updateMessage = (msg, flag) => {
    this.props.statusMessage(msg, flag);
  };

  changeCity = params => {
    this.setState({ job_city: params });
  };

  changeSource = params => {
    this.setState({ source: params });
  };

  componentDidMount() {
    this.getCityList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { repeatingRecruiter } = this.props;
    if (repeatingRecruiter !== prevProps.repeatingRecruiter) {
      this.setState({
        repeating_recruiter: repeatingRecruiter
      });
    }
  }

  getCityList = async () => {
    try {
      const cityList = (await getCrmCityData()) || [];
      this.setState({
        cityList
      });
    } catch (error) {
      let msg = getErrorMessage(error);
      this.setState({
        msgData: { msg: msg, showMsg: true, msgType: "error" },
        cityList: []
      });
    }
  };

  searchJobs = e => {
    e.preventDefault();
    const {
      document_status,
      is_email_verified,
      phone,
      crm_cleaning_status,
      crm_calling_status,
      crm_qa_status,
      job_approval_status,
      job_calling_status,
      job_live_status,
      repeating_recruiter,
      entity_doc_status,
      sdate,
      edate,
      jobPlan,
      job_city,
      source
    } = this.state;
    const obj = {};
    if (job_city.length) obj.job_city = job_city.map(item => item.value);
    if (source.length) obj.source = source.map(item => item.value);
    if (phone) obj.phone = phone;
    if (is_email_verified)
      obj.is_email_verified = is_email_verified === "Yes" ? 1 : 0;
    if (document_status) obj.document_status = document_status;
    if (crm_cleaning_status) obj.crm_cleaning_status = crm_cleaning_status;
    if (crm_calling_status) obj.crm_calling_status = crm_calling_status;
    if (crm_qa_status) obj.crm_qa_status = crm_qa_status;
    if (job_approval_status) obj.job_approval_status = job_approval_status;
    if (job_calling_status) obj.job_calling_status = job_calling_status;
    if (job_live_status) obj.job_live_status = job_live_status;
    if (repeating_recruiter) obj.repeating_recruiter = repeating_recruiter;
    if (entity_doc_status) obj.entity_doc_status = entity_doc_status;
    if (sdate) obj.start_date = moment(sdate).format("YYYY-MM-DD");
    if (edate) obj.end_date = moment(edate).format("YYYY-MM-DD");
    if (jobPlan) obj.jobPlan = jobPlan;
    this.props.searchData(obj);
  };

  render() {
    const { isButtonDisabled, page } = this.props;
    const pageSource =
      page === "crawled" ? "NAUKRI" : page === "organic" ? "APP" : "AGENT";
    const { sdate, edate, cityList, jobPlan } = this.state;
    let multiCityList = [];
    cityList.map(item =>
      multiCityList.push({
        label: item.label,
        value: item._id
      })
    );
    let sourceList = [];
    if (pageSource === "APP") {
      sourceList = [
        {
          label: "App",
          value: "APP"
        },
        {
          label: "Web",
          value: "WEB"
        }
      ];
    }
    if (pageSource === "NAUKRI") {
      sourceList = MetaData.crawlSourceList;
    }

    const jobApprovalOptions = MetaData.jobApprovalStatus;
    const jobCallingOptions = MetaData.jobCallingStatus;
    const jobLiveStatusOptions = MetaData.jobLiveStatus;
    const buttonCalss =
      page === "crawled"
        ? "col-md-3"
        : sourceList.length > 0
        ? "col-md-6"
        : "col-md-9";
    return (
      <div className="job_seeker">
        <div className="gap-20"></div>
        <form autoComplete="off" onSubmit={this.searchJobs} className="search">
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  placeholder="Mobile no"
                  value={this.state.phone}
                  onChange={event =>
                    this.setState({
                      phone: event.target.value.replace(/\D/, "")
                    })
                  }
                  maxLength="10"
                />
              </div>
            </div>
            <div className="col-md-3 multi-select">
              <Select
                value={this.state.job_city}
                onChange={this.changeCity}
                options={multiCityList}
                isMulti
                placeholder={"Select City"}
              />
            </div>
            <div className="col-md-2">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              ></b>
              <select
                className="form-control"
                name="is_email_verified"
                value={this.state.is_email_verified}
                onChange={this.handleChange}
              >
                <option value="">Verified Email</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-md-2">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              ></b>
              <select
                className="form-control"
                name="document_status"
                value={this.state.document_status}
                onChange={this.handleChange}
              >
                <option value="">Document Status</option>
                <option value="under_review">New</option>
                <option value="no_document">No Document</option>
                <option value="verified">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
            {sourceList.length > 0 ? (
              <div className="col-md-3 multi-select">
                <Select
                  value={this.state.source}
                  onChange={this.changeSource}
                  options={sourceList}
                  isMulti
                  placeholder={"Select Source"}
                />
              </div>
            ) : null}
            <div className="col-md-3">
              <div className="form-group">
                <b
                  className="downArrow"
                  style={{ padding: "3px", marginTop: "13px" }}
                ></b>
                <select
                  className="form-control"
                  name="job_approval_status"
                  value={this.state.job_approval_status}
                  onChange={this.handleChange}
                >
                  <option value="">Select Job Approval Status</option>
                  <option value="NEW">Job Approval Status - New</option>
                  {jobApprovalOptions.map(option => (
                    <option value={option.id} key={`jobApprovalS${option.id}`}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <b
                  className="downArrow"
                  style={{ padding: "3px", marginTop: "13px" }}
                />
                <select
                  className="form-control"
                  name="job_calling_status"
                  value={this.state.job_calling_status}
                  onChange={this.handleChange}
                >
                  <option value="">Select Job Calling Status</option>
                  {jobCallingOptions.map((option, index) => (
                    <option value={option.value} key={`jobCallS${index}`}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              ></b>
              <select
                className="form-control"
                name="job_live_status"
                value={this.state.job_live_status}
                onChange={this.handleChange}
              >
                <option value="">Select Job Live Status</option>
                <option value="NEW">Job Live Status - New</option>
                {jobLiveStatusOptions.map(option => (
                  <option value={option.id} key={`jobLiveS${option.id}`}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-3">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="repeating_recruiter"
                value={this.state.repeating_recruiter}
                onChange={this.handleChange}
              >
                <option value="">Select Repeat Verified Recruiter</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
            <div className="col-md-3">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              ></b>
              <select
                className="form-control"
                name="entity_doc_status"
                value={this.state.entity_doc_status}
                onChange={this.handleChange}
              >
                <option value="">Select Employment Proof</option>
                {MetaData.entityDocStatus.map(option => (
                  <option value={option} key={`eds${option}`}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {page === "crawled" ? (
              <div className="col-md-6">
                <div className="date-lable padding15">Date From:</div>
                <div className="date-field">
                  <DatePicker
                    selected={sdate}
                    onChange={value => {
                      this.setState({ sdate: value });
                    }}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>
                <div className="date-lable">To:</div>
                <div className="date-field">
                  <DatePicker
                    selected={edate}
                    onChange={value => {
                      this.setState({ edate: value });
                    }}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>
              </div>
            ) : (
              <div className="col-md-3">
                <div className="form-group">
                  <b
                    className="downArrow"
                    style={{ padding: "3px", marginTop: "13px" }}
                  />
                  <select
                    className="form-control"
                    name="jobPlan"
                    value={jobPlan}
                    onChange={this.handleChange}
                  >
                    <option value="">Select Job Type</option>
                    {MetaData.premiumJobType.map(option => (
                      <option value={option.value} key={`premium${option}`}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div
              className={buttonCalss}
              style={{ marginTop: sourceList.length === 0 ? "10px" : "auto" }}
            >
              <div className="float-right">
                {isButtonDisabled && (
                  <button className="btn btn-primary disable" disabled>
                    SEARCHING ...
                  </button>
                )}
                {!isButtonDisabled && (
                  <button className="btn btn-primary" onClick={this.searchJobs}>
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
      </div>
    );
  }
}
export default MiniSearch;
