import React, { useState, useEffect } from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import { checkPermission } from "../../services/utils";
import {
  ADMIN_PANEL,
  ENTERPRISE_LEADS,
  ENTERPRISE_LEADS_SHAREING,
  MODEARTOR_ASSIGNMENT
} from "../../routes/RouteConstants";
import NoAccess from "../Common/NoAccess";
import HeadingRow from "../Common/HeadingRow";
import LoadingSpinner from "../LoadingSpinner";
import { getLcsCampaignList } from "../../services/metadataApiServices";

const EnterpriseLeadsDashboard = () => {
  const [defaultClient, setDefaultClient] = useState("");
  const [isLoading, setLoader] = useState(true);
  const showEnterpriseLeads = checkPermission("Enterprise Leads");
  const showLeadsAdmin = checkPermission("EnterpriseLeadsAdmin");
  const showLeadsSharing =
    checkPermission("LeadSharingDistribution") || showLeadsAdmin;
  const isAccess = showEnterpriseLeads || showLeadsAdmin || showLeadsSharing;

  useEffect(() => {
    if (isAccess) {
      getLcsCampaignList()
        .then(response => {
          if (response.length) {
            setDefaultClient(response[0].client_id);
          }
          setLoader(false);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
          setLoader(false);
        });
    }
  }, [isAccess]);

  if (!isAccess) return <NoAccess />;
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <DocumentTitle title={"Job Hai CRM - Enterprise Leads"}>
      <div className="dashboard">
        <HeadingRow heading="Enterprise Leads - Dashboard" />
        <div className="clr gap-20" />
        <div className="whiteCard">
          <div className="container-fluid">
            <div className="clr gap-20" />
            <div className="row marginBottom20">
              {showLeadsAdmin ? (
                <>
                  <div className="col-md-4 marginBottom20">
                    <div className="box">
                      <Link
                        to={`${ENTERPRISE_LEADS}/admin-view?client=${defaultClient}`}
                      >
                        Admin Leads
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-4 marginBottom20">
                    <div className="box">
                      <Link to={`${ENTERPRISE_LEADS}/perfromance`}>
                        Moderator Performance
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-4 marginBottom20">
                    <div className="box">
                      <Link to={`${ADMIN_PANEL}?pageType=LEAD`}>
                        Moderator Report
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box">
                      <Link
                        to={`${MODEARTOR_ASSIGNMENT}?pageType=LEAD&client=${defaultClient}`}
                      >
                        Moderator Assignment
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {showEnterpriseLeads ? (
                    <>
                      <div className="col-md-4">
                        <div className="box">
                          <Link
                            to={`${ENTERPRISE_LEADS}/moderator-view?client=${defaultClient}`}
                          >
                            Leads
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="box">
                          <Link to={`${ENTERPRISE_LEADS}/perfromance`}>
                            My Performance
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              )}
              {showLeadsSharing ? (
                <div className="col-md-4">
                  <div className="box">
                    <Link to={ENTERPRISE_LEADS_SHAREING}>
                      Lead Sharing / Distribution
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default EnterpriseLeadsDashboard;
