import React from "react";

class JSFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: "",
      name: "",
      phone: "",
      status: "",
      source: "",
      msgData: { msg: "", showMsg: false, msgType: "error" }
    };
    this.handleChange = this.handleChange.bind(this);
    this.searchJobSeeker = this.searchJobSeeker.bind(this);
  }
  handleChange(event) {
    if (
      event.target.name === "source" &&
      this.state.status === "no_action" &&
      event.target.value === "applies"
    ) {
      this.setState({ status: "" });
    }
    this.setState({ [event.target.name]: event.target.value });
  }
  updateMessage(msg, flag) {
    this.props.statusMessage(msg, flag);
  }

  searchJobSeeker(e) {
    e.preventDefault();
    const obj = {
      user_id: this.state.user_id ? Number(this.state.user_id) : "",
      phone: this.state.phone ? Number(this.state.phone) : "",
      name: this.state.name,
      status: this.state.status,
      source: this.state.source
    };
    var exists = Object.keys(obj).some(function (k) {
      return obj[k] !== "";
    });
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        delete obj[key];
      }
    });
    if (exists === false) {
      this.updateMessage("Please provide search options.", 1);
      return;
    }
    this.props.searchApplicant(obj);
  }

  render() {
    const { isButtonDisabled } = this.props;
    const statusOptions = [
      { val: "", label: "Status" },
      { val: "js_applied", label: "JS Applied" },
      { val: "js_called", label: "JS Called" },
      { val: "r_called", label: "Recruiter Called" },
      { val: "r_shortlisted", label: "Recruiter Shortlisted" },
      { val: "r_rejected", label: "Recruiter Rejected" },
      { val: "no_action", label: "No Actions" }
    ];
    return (
      <div className="job_seeker">
        <div className="gap-20"></div>
        <form
          autoComplete="off"
          onSubmit={this.searchJobSeeker}
          className="search"
        >
          <div className="row">
            <div className="col-md-2">
              <input
                className="form-control"
                type="text"
                name="rec_id"
                placeholder="JS ID"
                value={this.state.user_id}
                onChange={event =>
                  this.setState({
                    user_id: event.target.value.replace(/\D/, "")
                  })
                }
                maxLength="10"
              />
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Name "
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  placeholder="Phone no"
                  value={this.state.phone}
                  onChange={event =>
                    this.setState({
                      phone: event.target.value.replace(/\D/, "")
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="status"
                onChange={this.handleChange}
                value={this.state.status}
              >
                {statusOptions.map(option => (
                  <option value={option.val} key={option.val}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <b className="downArrow"></b>
              <select
                className="form-control"
                name="source"
                onChange={this.handleChange}
                value={this.state.source}
              >
                <option value="" className="option-selected">
                  Source
                </option>
                <option value="applies">Applies</option>
                <option value="recommended">Recommended</option>
              </select>
            </div>
            <div className="col-md-2">
              <div className="float-right">
                {isButtonDisabled ? (
                  <button className="btn btn-primary disable" disabled>
                    SEARCHING ...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={this.searchJobSeeker}
                  >
                    SEARCH
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="clr"></div>
        <div className="clr gap-20"></div>
      </div>
    );
  }
}
export default JSFilter;
