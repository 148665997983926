import React from "react";

const GoBack = props => {
  const { href, lable = "" } = props;
  return (
    <div className="go-back">
      <a href={href}>
        <img
          src="/images/arrow-left.svg"
          width="20"
          height="20"
          alt="Go back"
          className=""
        />
        {lable}
      </a>
    </div>
  );
};

export default GoBack;
