import cx from "classnames";
import { withStyles } from "@material-ui/styles";
import { styles } from "../CreditWidget/helper";
import MetaData from "../../constants/MetaData";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import CustomSelect from "../Common/Form/CustomSelect";
import { ONE_JOB_CREDITS } from "../../services/constant";
import { linkGeneratorUtils } from "../../services/utils";

const LinkGenerationForm = props => {
  const creditsValidityList = MetaData.creditsValidityList2;
  const {
    fieldItems,
    onChange,
    onSave,
    classes,
    admin,
    showGetLink = true
  } = props;
  const { jobs, basicPrice, validity } = fieldItems;

  const showDetails = Boolean(Number(jobs) && Number(basicPrice));
  const error =
    showDetails && Number(basicPrice) > ONE_JOB_CREDITS * Number(jobs);
  const disabled = !jobs || !basicPrice || !validity || error;
  const credits = jobs ? Number(jobs) * ONE_JOB_CREDITS : "";
  return (
    <>
      <div className="formRow row">
        <div className="col-md-4">
          <Label lable="No. of Jobs" />
          <CustomTextField
            name="jobs"
            value={jobs}
            onChange={onChange}
            // maxLength={6}
            placeholder="Jobs"
          />
          {jobs && <span>{credits} Credits</span>}
        </div>
        <div className="col-md-4">
          <Label lable="Basic Price" />
          <CustomTextField
            name="basicPrice"
            value={basicPrice}
            onChange={onChange}
            // maxLength={2}
            placeholder="Basic Price"
          />
          {showDetails && (
            <div>
              <div>
                {" "}
                INR{" "}
                {`${(function () {
                  return linkGeneratorUtils({ basicPrice, jobs }).pricePerJob;
                })()}/Job`}{" "}
              </div>
              <div>{`(${(function () {
                return linkGeneratorUtils({ basicPrice, jobs })
                  .discountPercentage;
              })()}% Discount)`}</div>
              <div>{`INR ${(function () {
                return linkGeneratorUtils({ basicPrice, jobs }).gstAmount;
              })()} incl GST`}</div>
            </div>
          )}
        </div>
        <div className="col-md-4">
          <Label lable="Validity" />
          <CustomSelect
            name="validity"
            value={validity}
            onChange={onChange}
            optionsList={creditsValidityList}
          />
        </div>
      </div>
      <div className={cx("flex", "buttonContainer", "center")}>
        {showGetLink ? (
          <input
            type="button"
            onClick={() => onSave("PRELIMINARY")}
            value="Get Link"
            className={cx(
              "submitBtn",
              "btns",
              "btn1 btn4 mrAdjust",
              "flex",
              "justifyCenter"
            )}
            disabled={disabled}
          />
        ) : null}
        {admin ? (
          <input
            type="button"
            onClick={() => onSave("PRELIMINARY", "createOrder")}
            value="Create Order"
            className={cx(
              "submitBtn",
              "btns",
              "btn1 btn4",
              "flex",
              "justifyCenter"
            )}
            disabled={disabled}
          />
        ) : null}
      </div>
      {error ? (
        <div className={classes.errorState}>
          <i class="fa fa-x" aria-hidden="true" />
          <span>&nbsp; Discount can not be negative</span>
        </div>
      ) : null}
    </>
  );
};
export default withStyles(styles)(LinkGenerationForm);
