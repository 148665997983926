import JobHai from "../../api/JobHai";
import { getErrorMessage } from "../utils";

export const getOrganisations = async data => {
  const { companyNames = [] } = data;
  try {
    const response = await JobHai.post("/get-organisations", {
      organisation_names: companyNames
    });
    return response?.data?.data;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};

export const restrictRecruiters = async data => {
  const { organisation_ids = [], request_name = "", restriction = "" } = data;
  try {
    const response = await JobHai.post("/restrict-recruiter", {
      organisation_ids,
      request_name,
      restriction
    });
    return response?.data?.data;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};

export const getRecruiterRestrictionsList = async () => {
  try {
    const response = await JobHai.get("/get-recruiter-restrictions");
    return response?.data?.data;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};

export const revertRecruiterRestriction = async data => {
  const { request_id = "" } = data;
  try {
    const response = await JobHai.post("/revert-recruiter", {
      request_id
    });
    return response?.data?.data;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};

export const getRestrictionDetail = async data => {
  const { request_id = "" } = data;
  try {
    const response = await JobHai.get(
      `/get-restrictions-details?request_id=${request_id}`
    );
    return response?.data?.data;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};
