import DocumentTitle from "react-document-title";
import React, { useState, useEffect } from "react";

import HeadingRow from "../Common/HeadingRow";
import NoAccess from "../Common/NoAccess";
import {
  columnsSortItems,
  getErrorMessage,
  isCRMAdmin
} from "../../services/utils";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import CustomePagination from "../Common/Pagination";
import AccessLevel from "./AccessLevel";
import ModeratorSearch from "./ModeratorSearch";
import { TOOLS } from "../../routes/RouteConstants";

const Moderators = () => {
  const [isLoading, setLoading] = useState(false);
  const [moderatorList, setModeratorList] = useState([]);
  const [filteredModeratorList, setFilteredModeratorList] = useState([]);
  const [error, setError] = useState(null);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  useEffect(() => {
    if (isCRMAdmin()) {
      fetchModeratorList();
    }
  }, []);

  const fetchModeratorList = async () => {
    setLoading(true);
    try {
      const response = await JobHai.post("moderator-access-list");
      const data = response.data.data;
      setModeratorList(data);
      setFilteredModeratorList(data);
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = ({ searchKey, searchValue }) => {
    const regex = new RegExp(searchValue, "i"); // Case-insensitive regex pattern
    const updatedList = moderatorList.filter(item =>
      regex.test(item[searchKey])
    );
    setFilteredModeratorList(updatedList);
  };

  const handleOnUpdateCallback = () => {
    setModeratorList([]);
    setFilteredModeratorList([]);
    fetchModeratorList();
  };

  const sortFunction = column => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedModeratorList = columnsSortItems(
    filteredModeratorList,
    sortColumn,
    sortOrder
  );

  if (isLoading) return <LoadingSpinner />;

  return (
    <DocumentTitle title={`CRM: Moderator Access`}>
      <div className="Login">
        <div className="gap-20" />
        <HeadingRow heading="Moderator Access Management" goBackURL={TOOLS} />
        <div className="gap-20" />
        <ModeratorSearch handleSearch={handleSearch} />
        <div className="whitebox moderator-access">
          {error ? (
            <div>Error: {error}</div>
          ) : isCRMAdmin() ? (
            <>
              <div className="react-bootstrap-table custom-list">
                <table className="table table-hover table-condensed borderless">
                  <thead>
                    <tr className="listHeding">
                      <th className="sort" onClick={() => sortFunction("id")}>
                        Id
                      </th>
                      <th className="sort" onClick={() => sortFunction("name")}>
                        Name
                      </th>
                      <th className="sort" onClick={() => sortFunction("role")}>
                        Role
                      </th>
                      <th>Access</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedModeratorList.map(item => (
                      <tr className="listvalue">
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.user_level}</td>
                        <td>
                          <AccessLevel
                            moderatorData={item}
                            callBack={handleOnUpdateCallback}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <CustomePagination
                activePage={1}
                itemsCountPerPage={100}
                totalItemsCount={filteredModeratorList.length}
                showPagination={false}
              />
              <div className="clr gap-20" />
            </>
          ) : (
            <NoAccess />
          )}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default Moderators;
