import React, { useState } from "react";

const ModeratorSearch = ({ handleSearch }) => {
  const [moderatorName, setModeratorName] = useState("");
  const handleChange = e => {
    const { name, value } = e.target;
    setModeratorName(value);
    handleSearch({ searchKey: name, searchValue: value });
  };
  return (
    <div className="job_seeker">
      <form
        autoComplete="off"
        onSubmit={e => e.preventDefault()}
        className="search"
      >
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Search Moderator"
                value={moderatorName}
                onChange={handleChange}
                maxLength={40}
              />
            </div>
          </div>
          {/* <div className="col-md-2">
                <div className="float-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => handleSearch(moderatorName)}
                  >
                    Search
                  </button>
                </div>
              </div> */}
        </div>
      </form>
      <div className="clr gap-20" />
    </div>
  );
};

export default ModeratorSearch;
