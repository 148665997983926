import JobHai from "../../api/JobHai";
import { getErrorMessage, removeNullKeys } from "../utils";

export const getEligibleCrawledJobs = async data => {
  const { filters = {}, page = 0, size = 50 } = data;
  try {
    const response = await JobHai.post("/get-eligible-crawled-jobs", {
      filters,
      page_number: page,
      page_size: size
    });
    return response?.data?.data;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};

export const getPreviewOneCrawledJob = async data => {
  const { lead_id = null } = data;
  try {
    const response = await JobHai.get(
      `/get-preview-one-job?lead_id=${lead_id}`
    );
    return response?.data?.data;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};

export const autoJobPost = async data => {
  const cleanedData = removeNullKeys(data);
  const removeJobId = {
    ...cleanedData,
    job_id: undefined
  };
  try {
    const response = await JobHai.post("/auto-job-post", removeJobId);
    return response?.data?.data;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};
