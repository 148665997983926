import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import LeadSearch from "./LeadSearch";
import CustomePagination from "../Common/Pagination";
import { getErrorMessage } from "../../services/utils";
import { RECUITERS_LEADS } from "../../routes/RouteConstants";

class LeadsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      isLoading: true,
      msgData: { msg: "", showMsg: false, msgType: "error" },
      isButtonDisabled: false,
      filter: {},
      activePage: 1,
      count: 0,
      itemsCountPerPage: 50,
      totalAcquired: 0,
      totalNotAcquired: 0,
      isSearching: false
    };
  }

  async componentDidMount() {
    this.getLeads();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filter, activePage } = this.state;
    if (filter !== prevState.filter) {
      this.setState({
        filter: filter
      });
      this.getLeads();
    }
    if (activePage !== prevState.activePage) {
      this.setState({
        activePage: activePage
      });
      this.getLeads();
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      }
    });
    window.scroll(0, "smooth");
  };

  getSearchResult = objData => {
    this.setState({
      filter: objData,
      activePage: 1,
      leads: [],
      isButtonDisabled: true,
      msgData: {
        msg: "",
        showMsg: false,
        msgType: ""
      }
    });
  };

  getLeads() {
    const { filter, activePage, itemsCountPerPage } = this.state;
    JobHai.post(`/leads/get-all`, {
      filter,
      page: activePage - 1,
      size: itemsCountPerPage
    })
      .then(response => {
        let data = response.data.data;
        const {
          totalNotAcquired = 0,
          totalAcquired = 0,
          count = 0,
          leads = {}
        } = data;
        this.setState({
          leads,
          count,
          isLoading: false,
          isButtonDisabled: false,
          totalAcquired,
          totalNotAcquired,
          msgData: { msg: "", showMsg: false, msgType: "error" },
          isSearching: leads.length
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isLoading: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" },
          isSearching: false
        });
        window.scrollTo(0, 0);
      });
  }

  render() {
    const {
      leads,
      isLoading,
      isButtonDisabled,
      activePage,
      itemsCountPerPage,
      count,
      totalAcquired,
      totalNotAcquired,
      isSearching
    } = this.state;
    const totalLeads = count;

    leads.map(d => {
      d.edit_link = (
        <a href={`${RECUITERS_LEADS}/${d.lead_id}`}>
          <i className="fas fa-pencil-alt"></i>
        </a>
      );
      return d;
    });

    leads.map(d => {
      d.acquisition_status =
        d.acquisition_status === "NOT_ACQUIRED" ? "Not Acquired" : "Acquired";
      return d;
    });

    const columns = [
      {
        text: "",
        dataField: "_status",
        classes: "statusCircle",
        formatter: (cellContent, row) => {
          if (row.acquisition_status === "Acquired") {
            return <b className="green"></b>;
          }
          return <b className="red"></b>;
        }
      },
      {
        text: "ID",
        dataField: "lead_id",
        sort: true
      },
      {
        text: "Company Name",
        dataField: "company_name",
        sort: true
      },
      {
        text: "Recruiter Name",
        dataField: "full_name",
        sort: true
      },
      {
        text: "Rec Phone no",
        dataField: "phone"
      },
      {
        text: "Email",
        dataField: "email"
      },
      {
        text: "City",
        dataField: "city"
      },
      {
        text: "Locality",
        dataField: "locality"
      },
      {
        text: "Acquired Status",
        dataField: "acquisition_status",
        sort: true
      },
      {
        text: "#",
        dataField: "edit_link"
      }
    ];

    const NoDataIndication = () => (
      <div className="spinner">
        {isSearching ? "Searching..." : "No Data Found"}
      </div>
    );

    if (isLoading) {
      return <LoadingSpinner key="loading" />;
    } else {
      return (
        <div className="job_seeker">
          <div className="clr gap-20" />
          <PopupMessage
            data={this.state.msgData}
            key="popup"
            click={this.handleClosePopup}
          />
          <div className="pageHeading row">
            <h2>
              Leads
              <div className="float-right">
                <a href={`${RECUITERS_LEADS}/add`}>
                  <i className="fa fa-plus" aria-hidden="true"></i> Add New Lead
                </a>
                <span className="sperator">|</span>
                <a href={"/recruiters"}>Go Back</a>
              </div>
            </h2>
          </div>
          <div className="clr gap-20" />
          <div className="clr" />
          <LeadSearch
            statusMessage={this.statusMessage}
            searchRecruiters={this.getSearchResult}
            isButtonDisabled={isButtonDisabled}
          />
          <div className="row sortingWrap">
            <span>ALL ({totalLeads})</span>
            <span>
              <b className="green" />
              Acquired ({totalAcquired})
            </span>
            <span>
              <b className="red" />
              Not Acquired ({totalNotAcquired})
            </span>
          </div>
          <div className="clr gap-20" />
          <div className="whitebox">
            <BootstrapTable
              keyField="lead_id"
              data={leads}
              columns={columns}
              hover
              condensed
              bordered={false}
              loading={true}
              rowClasses="listvalue"
              headerClasses="listHeding"
              classes="borderless"
              noDataIndication={() => <NoDataIndication />}
            />
            <CustomePagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={count}
              onPageChange={this.handlePageChange}
              showPagination={count > 0 ? true : false}
            />
            <div className="clr gap-20" />
          </div>
        </div>
      );
    }
  }
}
export default LeadsTab;
