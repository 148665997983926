import { useState, useEffect, useRef } from "react";
import { Ellipsis } from "lucide-react";
import { Button } from "../../ui/button";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose
} from "../../ui/dialog";
import { Label } from "../../ui/label";
import { isLmsAdmin } from "../../../services/utilsV2";
import { reAssignCompanies } from "../../../services/insideSales";
import AssignedTo from "../AssignedTo";
import CustomSelect from "../../ui/CustomSelect";

const ReAssign = ({ selectedIds, reAssignCallBack }) => {
  const [fieldItems, setFieldItems] = useState({
    actionType: "",
    moderatorId: "",
    extension: ""
  });
  const [fetchState, setFetchState] = useState("");
  const [message, setMessage] = useState("");
  const { actionType, moderatorId, extension } = fieldItems;
  const isAdmin = isLmsAdmin();
  const dialogCloseRef = useRef(); // Ref to programmatically close the dialog

  useEffect(() => {
    const initialActionType = isAdmin ? "" : "reallocation";
    setFieldItems(prevItems => ({
      ...prevItems,
      actionType: initialActionType
    }));
  }, [isAdmin]);

  const handleSubmit = async () => {
    try {
      setFetchState("LOADING");
      await reAssignCompanies({ ...fieldItems, orgIds: selectedIds });
      reAssignCallBack();
      dialogCloseRef.current?.click(); // Close the dialog on success
      setFetchState("");
      setMessage("");
    } catch (err) {
      setFetchState("ERROR");
      const msg = err.message || "Failed to update data";
      setMessage(msg);
    }
  };
  const handleOptionChange = (option, filedName) => {
    setFieldItems({
      ...fieldItems,
      [filedName]: option?.id || ""
    });
  };

  const btnDisabled = !actionType || (!moderatorId && !extension);
  const heading = isAdmin ? "Re-Assign or Extend" : "Re-Assign";
  const extensionList = [
    { id: 10, name: "10 days" },
    { id: 15, name: "15 days" },
    { id: 30, name: "30 days" }
  ];
  const actionTypeList = [
    { id: "reallocation", name: "Re-Assign" },
    { id: "extend", name: "Extend" }
  ];
  return (
    <div className="flex items-center mr-4">
      <Dialog>
        <DialogTrigger asChild>
          <div className="flex items-center justify-center border rounded-md cursor-pointer size-8">
            <Ellipsis />
          </div>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{heading}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {isAdmin && (
              <div className="grid gap-2">
                <Label htmlFor="action">Action Type</Label>
                <CustomSelect
                  options={actionTypeList}
                  value={actionType}
                  onChange={handleOptionChange}
                  placeholder="Select action"
                  fieldName="actionType"
                />
              </div>
            )}
            {actionType === "reallocation" ? (
              <AssignedTo
                fieldItems={fieldItems}
                setFieldItems={setFieldItems}
                fieldName="moderatorId"
              />
            ) : (
              <div className="grid gap-2">
                <Label htmlFor="extension">Select Extension</Label>
                <CustomSelect
                  options={extensionList}
                  value={extension}
                  onChange={handleOptionChange}
                  placeholder="Select days of extension"
                  fieldName="extension"
                />
              </div>
            )}
          </div>
          <DialogFooter>
            {message ? (
              <div className="flex text-red-500">{message}</div>
            ) : null}
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button
              disabled={btnDisabled || fetchState === "LOADING"}
              onClick={handleSubmit}
              type="submit"
            >
              Submit
            </Button>
            <DialogClose ref={dialogCloseRef} asChild>
              <span></span>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReAssign;
