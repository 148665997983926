import React from "react";
import MetaData from "../../constants/MetaData";
import Label from "../Common/Form/Label";
import Chips from "../Common/Chips";
import { getActiveItemList } from "../../services/utils";

const JobCallAttempt = props => {
  const { callStatusValue = {} } = props;
  const { job_calling_first_status, job_calling_second_status } =
    callStatusValue;
  const optionsList = MetaData.CallingAttempt;
  const handleChips = (e, fieldName, item) => {
    const { callStatusValue, handleCallStatus } = props;
    handleCallStatus(
      fieldName,
      callStatusValue[fieldName] === item.id ? "NEW" : item.id
    );
  };
  const noClick = () => {};
  return (
    <div className="formRow">
      <div className="fullWidth">
        <div className="callingList">
          <Label lable="1st Call Attempt" />
          {job_calling_second_status !== "NEW" ? (
            <Chips
              chips={[getActiveItemList(optionsList, job_calling_first_status)]}
              activeChips={[
                getActiveItemList(optionsList, job_calling_first_status)
              ]}
              onClick={noClick}
              isMulti={false}
              fieldName={"job_calling_first_status"}
              isClose={true}
            />
          ) : (
            <Chips
              chips={optionsList}
              activeChips={getActiveItemList(
                optionsList,
                job_calling_first_status
              )}
              onClick={handleChips}
              isMulti={false}
              fieldName={"job_calling_first_status"}
              isClose={true}
            />
          )}
        </div>
        {job_calling_first_status !== "NEW" ? (
          <div className="callingList">
            <Label lable="2nd Call Attempt" />
            <Chips
              chips={optionsList}
              activeChips={getActiveItemList(
                optionsList,
                job_calling_second_status
              )}
              onClick={handleChips}
              isMulti={false}
              fieldName={"job_calling_second_status"}
              isClose={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default JobCallAttempt;
