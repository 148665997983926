import { getFormattedValue } from "../../../services/utils";
import MultiSelect from "../../Common/Form/MultiSelect";
import SelectCity from "../../Common/SelectCity";

const ModeratorList = props => {
  const {
    onChange,
    moderatorList,
    showCity = false,
    interviewCenterList = [],
    handleAddModerator,
    showAddmore = false,
    pendingModeratos = []
  } = props;
  const total = moderatorList.length;
  const totalAbsent = moderatorList.filter(item => item.is_absent).length;
  const totalActive = moderatorList.filter(item => item.is_active).length;
  const totalWeightage = moderatorList.reduce(
    (n, { weightage }) => n + weightage,
    0
  );

  function handleChange(selectedOption, id, field) {
    onChange(id, selectedOption, field);
  }

  const showInterviewCenter = interviewCenterList.length;
  return (
    <>
      <div
        className="moderator-list"
        style={{ minHeight: showCity ? "400px" : "auto" }}
      >
        <table className="table table-hover borderless">
          <thead>
            <tr className="listHeding">
              <th>ID</th>
              <th>Moderator Name</th>
              <th>
                {showInterviewCenter
                  ? "Interview Center"
                  : showCity
                  ? "City"
                  : "Weighted %"}
              </th>
              <th>Is Active</th>
              <th>Is Absent</th>
            </tr>
          </thead>
          <tbody>
            {moderatorList.map(item => {
              return (
                <tr
                  className="listvalue"
                  key={`moderatorlist${item.moderator_id}`}
                >
                  <td>{item.moderator_id}</td>
                  <td>
                    {!item.moderator_id && showCity ? (
                      <>
                        <select
                          name="moderator_name"
                          className="form-control"
                          value={item.moderator_name}
                          onChange={e =>
                            onChange(
                              item.moderator_id,
                              getFormattedValue(e.target.value, true),
                              "moderator_name"
                            )
                          }
                        >
                          <option value="">Select Moderator</option>
                          {pendingModeratos.map(option => (
                            <option value={option.id} key={`mod-${option.id}`}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </>
                    ) : (
                      item.moderator_name
                    )}
                  </td>
                  <td>
                    {showInterviewCenter ? (
                      <MultiSelect
                        value={item.interview_centers}
                        onChange={selectedOption =>
                          handleChange(
                            selectedOption,
                            item.moderator_id,
                            "interview_centers"
                          )
                        }
                        optionList={interviewCenterList}
                        placeholder={"Select Center"}
                      />
                    ) : (
                      <>
                        {showCity ? (
                          <div className="assignment-city">
                            <SelectCity
                              filedName={"city_ids"}
                              value={item.city_ids}
                              onChange={selectedOption =>
                                handleChange(
                                  selectedOption,
                                  item.moderator_id,
                                  "city_ids"
                                )
                              }
                              label={false}
                              isMulti={true}
                            />
                          </div>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            value={item.weightage}
                            name="weightage"
                            onChange={e =>
                              onChange(
                                item.moderator_id,
                                getFormattedValue(e.target.value, true),
                                "weightage"
                              )
                            }
                            maxLength="3"
                          />
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={item.is_active}
                      onChange={e =>
                        onChange(
                          item.moderator_id,
                          e.target.checked,
                          "is_active"
                        )
                      }
                    />
                  </td>
                  <td valign="top">
                    <input
                      type="checkbox"
                      checked={item.is_absent}
                      onChange={e =>
                        onChange(
                          item.moderator_id,
                          e.target.checked,
                          "is_absent"
                        )
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="custom-pagination">
          <table className="table table-hover table-condensed borderless">
            <thead>
              <tr className="listHeding">
                <th>Total</th>
                <th>{total}</th>
                <th>{showCity ? "" : totalWeightage}</th>
                <th>{totalActive}</th>
                <th>{totalAbsent}</th>
              </tr>
            </thead>
          </table>
        </div>
        {showAddmore ? (
          <div className="row">
            <div className="col-md-12">
              <div
                className="float-right download_link"
                onClick={handleAddModerator}
              >
                Add Moderator
              </div>
              <div className="gap-20" />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ModeratorList;
