import { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getErrorMessage } from "../../../services/utils";
import JobHai from "../../../api/JobHai";
import ModalWrapper from "../../Common/ModalWrapper";

const OTLButton = ({ id, callBack }) => {
  const maxDate = new Date(new Date().setDate(new Date().getDate() - 1));
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(null);
  const [date, setDate] = useState(maxDate);
  const [modalType, setModalType] = useState(null);

  const getOTL = async () => {
    try {
      setLoading(true);
      await JobHai.post(`dynamic-dag/one-time/${id}`);
      if (callBack) callBack();
    } catch (error) {
      const msg = getErrorMessage(error);
      setError(msg);
      hideErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  const dropTable = async () => {
    if (window.confirm("Are you sure you want to drop table?")) {
      try {
        setLoading(true);
        await JobHai.post(`dynamic-dag/table-drop/${id}`);
        if (callBack) callBack();
      } catch (error) {
        const msg = getErrorMessage(error);
        setError(msg);
        hideErrorMessage();
      } finally {
        setLoading(false);
        setDate(null);
      }
    }
  };

  const sampleRun = async () => {
    setOpen(false);
    setModalType(null);
    try {
      setLoading(true);
      await JobHai.post(`dynamic-dag/sample-run/${id}`, {
        date: moment(date).format("YYYY-MM-DD")
      });
      if (callBack) callBack();
    } catch (error) {
      const msg = getErrorMessage(error);
      setError(msg);
      hideErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  const handleModal = (type = "") => {
    setOpen(prevState => !prevState);
    setModalType(type ? type : null);
  };

  const hideErrorMessage = () => {
    setTimeout(() => {
      setError(null);
    }, 5000);
  };

  const deleteData = async () => {
    setOpen(false);
    setModalType(null);
    try {
      setLoading(true);
      await JobHai.post(`dynamic-dag/delete-data/${id}`, {
        date: moment(date).format("YYYY-MM-DD")
      });
    } catch (error) {
      const msg = getErrorMessage(error);
      setError(msg);
      hideErrorMessage();
    } finally {
      setLoading(false);
      setDate(null);
    }
  };

  return (
    <>
      <div className="formRow">
        <div className="d-flex justify-content-start align-items-center flex-wrap">
          <button
            className="btn btn-danger d-flex align-items-center mr-3"
            disabled={isLoading}
            onClick={() => handleModal("delete")}
          >
            <i className="fas fa-trash-alt mr-2"></i> Delete Data
          </button>

          <button
            className="btn btn-warning d-flex align-items-center mr-3"
            disabled={isLoading}
            onClick={dropTable}
          >
            <i className="fas fa-table mr-2"></i> Drop Table
          </button>

          <button
            className="btn btn-primary d-flex align-items-center mr-3"
            disabled={isLoading}
            onClick={() => handleModal("sample")}
          >
            <i className="fas fa-play-circle mr-2"></i> Insert Data
          </button>

          <button
            className="btn btn-success d-flex align-items-center"
            disabled={isLoading}
            onClick={getOTL}
          >
            <i className="fas fa-sync-alt mr-2"></i> One Time Load
          </button>
          {error && <span className="text-danger ml-3">{error}</span>}
        </div>
      </div>
      <ModalWrapper
        showModal={open}
        closeModal={() => handleModal()}
        h1="Are you sure you want to proceed?"
        isDisabled={!date}
        handleSave={modalType === "delete" ? deleteData : sampleRun}
      >
        <div className="flex items-center justify-center">
          <div className="mr-[15px]">Select Date</div>
          <div className="w-full md:w-1/2">
            <DatePicker
              selected={date}
              onChange={date => setDate(date)}
              dateFormat="yyyy-MM-dd"
              className="form-control w-full"
              maxDate={maxDate}
            />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default OTLButton;
