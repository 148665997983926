import cookie from "js-cookie";

const isBrowser = typeof window !== "undefined";

// Sets a cookie in the browser
export const setCookie = (key, value, expires = 365) => {
  if (isBrowser) {
    try {
      cookie.set(key, value, {
        expires,
        path: "/"
      });
    } catch (error) {
      console.error("Error setting cookie:", error);
    }
  }
};

// Removes a cookie from the browser
export const removeCookie = key => {
  if (isBrowser) {
    try {
      cookie.remove(key, {
        expires: 1
      });
    } catch (error) {
      console.error("Error removing cookie:", error);
    }
  }
};

// Gets a cookie value (browser-independent)
export const getCookie = key => {
  if (isBrowser) {
    try {
      return cookie.get(key);
    } catch (error) {
      console.error("Error getting cookie:", error);
      return null;
    }
  }
  return null;
};
