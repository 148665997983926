import React, { useState } from "react";
import { FormControl } from "@material-ui/core";
import { useController } from "react-hook-form";
import { uploadImage } from "../../services/lcsCampaign";

const UploadImage = ({
  control,
  name,
  customClass,
  rules,
  label = "",
  defaultValue,
  url,
  setError,
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  const [preview, setPreview] = useState(url ?? null);
  const [isUploading, setUploading] = useState(false);
  const handleUploadedFile = event => {
    const { type = "", files = [] } = event.target;
    setError(name, null);
    if (type === "file" && files.length) {
      const file = files[0];
      const fileSize = file.size;
      let fileName = file.name;
      var reg = /(.*?)\.(png|webp|svg)$/;
      if (!fileName.match(reg)) {
        setError(name, {
          type: "manual",
          message: "Only extensions .png/.webp/.svg allowed."
        });
        return;
      } else if (fileSize && fileSize > 50000) {
        setError(name, {
          type: "manual",
          message: "Please upload file size below 50kb"
        });
        return;
      } else {
        const urlImage = URL.createObjectURL(file);
        const fileObj = new FormData();
        fileObj.append("file", file);
        // Update form value directly
        setUploading(true);
        uploadImage(fileObj)
          .then(data => {
            setUploading(false);
            setPreview(urlImage);
            if (data?.key) {
              field.onChange(data?.key);
            } else {
              setError(name, {
                type: "manual",
                message: "Upload Error, try again."
              });
            }
          })
          .catch(error => {
            console.log("Upload Error", error);
            setError(name, {
              type: "manual",
              message: "Upload Error."
            });
          });
      }
    }
  };

  const uploadButtonLabel = preview ? "Change" : "Upload";
  const isError = !!fieldState.error;
  let errorMessage = "";
  if (fieldState.error) errorMessage = fieldState.error.message;

  return (
    <div className={customClass}>
      <FormControl error={isError}>
        <label className="ducument-file-upload">
          <input
            type="file"
            name={name}
            onChange={handleUploadedFile}
            {...rest}
          />
          <img
            src={"/images/upload.svg"}
            alt="add document"
            width="24"
            height="19"
          />
          <span variant="text">
            {`${uploadButtonLabel} ${label ? label : " image"}`}
          </span>
        </label>
        {isUploading ? <>Uploading ...</> : null}
        {preview && (
          <div>
            <img src={preview} width={100} alt="preview" />
          </div>
        )}
        {isError && <div className="text-danger">{errorMessage}</div>}
      </FormControl>
    </div>
  );
};

export default UploadImage;
