import React from "react";
import Label from "../Common/Form/Label";
import AutoSearch from "../Common/Form/AutoSearch";
import { commonTextFieldLimit } from "../../services/constant";

const ClientDetails = props => {
  const { clientList = [], clientName, onChange, recDetails = "" } = props;
  let options = [];
  clientList.map(item => {
    return options.push({ id: item.id, label: item.client_name });
  });
  return (
    <div className="formRow">
      <div className="client-list">
        <div className="left">
          <Label lable="Hiring Company" />
          <AutoSearch
            dataList={options}
            getSelectedValue={onChange}
            getSelectedInput={e => {}}
            placeholder={"Select Hiring Company"}
            variant="outlined"
            id="search-title"
            error={false}
            searchValue={clientName}
            defaultSearch={true}
            searchType={"array"}
            isArrow={true}
            maxLength={commonTextFieldLimit}
          />
        </div>
        {recDetails ? (
          <div className="recDetails" style={{ marginTop: "70px" }}>
            Recruiter Company Details: <span>{recDetails}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ClientDetails;
