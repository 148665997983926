import ModeratorAssignment from "../local/moderatorAssignment";
import ModeratorLevel from "../local/moderatorLevel";
import SalesAssignment from "../local/salesAssignment";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";

import { User } from "lucide-react";
import PageHeader from "../local/pageHeader";
import TeamLeadLevel from "./teamLeadLevel";
import { isLmsAdmin } from "../../services/utilsV2";
import NoAccess from "../../components/Common/NoAccess";

export default function LeadAssignment() {
  const isAdmin = isLmsAdmin();
  if (!isAdmin)
    return (
      <>
        <div className="w-screen lg:w-[calc(100vw-15rem)] p-4">
          <NoAccess />
        </div>
      </>
    );
  return (
    <>
      <div
        className="
overflow-y-auto max-h-full  w-screen lg:w-[calc(100vw-15rem)]"
      >
        <div className="sticky top-0 z-50 bg-white">
          <PageHeader icons={<User />} heading="Assignment" />
        </div>
        <Tabs defaultValue="lead" className="p-3">
          <TabsList className="mb-6 ">
            <TabsTrigger value="lead">Lead Assignment</TabsTrigger>
            <TabsTrigger value="team-lead">Team Lead Level</TabsTrigger>
            <TabsTrigger value="moderator">Executive Level</TabsTrigger>
            <TabsTrigger value="sales">Sales Assignment Variable</TabsTrigger>
          </TabsList>
          <TabsContent value="lead">
            <ModeratorAssignment />
          </TabsContent>
          <TabsContent value="moderator">
            <ModeratorLevel />
          </TabsContent>
          <TabsContent value="team-lead">
            <TeamLeadLevel />
          </TabsContent>
          <TabsContent value="sales">
            <SalesAssignment />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
}
