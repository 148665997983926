import React, { useState } from "react";
import JobHai from "../../../api/JobHai";
import { getErrorMessage } from "../../../services/utils";

const ReferralLink = ({ recId = "" }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);

  const getReferralLink = e => {
    e.preventDefault();
    setIsButtonDisabled(true);
    JobHai.post(`/get-referral-link`, {
      recruiter_id: recId
    })
      .then(response => {
        setIsButtonDisabled(false);
        setReferralLink(response.data.data);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        setIsButtonDisabled(false);
        alert(msg); // Replace with your preferred error handling method
      });
  };

  const copyLink = () => {
    try {
      navigator.clipboard.writeText(referralLink);
      setCopySuccess(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="referral-link">
      {referralLink ? (
        <>
          {referralLink}
          <button
            className="btn btn-primary postjob"
            onClick={copyLink}
            disabled={copySuccess}
          >
            {copySuccess ? "Copied" : "Copy"}
          </button>
        </>
      ) : (
        <button
          className="btn btn-primary postjob"
          onClick={getReferralLink}
          disabled={isButtonDisabled}
        >
          Create Referral Link
        </button>
      )}
    </div>
  );
};

export default ReferralLink;
