import useLeadRecruiterDetailStore from "../../../zustandService/useLeadRecruiterDetailStore";
import { Card, CardHeader, CardTitle, CardContent } from "../../ui/card";
import { Building } from "lucide-react";
import StateRenderer from "./stateRenderer";
import {
  RECUITERS_APPLICANT,
  RECUITERS_LIST
} from "../../../routes/RouteConstants";

let CompanyDetails = () => {
  const { recruiterDetailsState, recruiterDetails, fetchRecruiterDetails } =
    useLeadRecruiterDetailStore(state => ({
      recruiterDetailsState: state.recruiterDetailsState,
      recruiterDetails: state.recruiterDetails,
      fetchRecruiterDetails: state.fetchRecruiterDetails
    }));

  let companyDetails = recruiterDetails;

  return (
    <StateRenderer state={recruiterDetailsState}>
      <Card>
        <CardHeader>
          <div className="flex  items-center space-x-3">
            <div className=" size-10 bg-blue-50 flex items-center text-blue-500 rounded-md   justify-center">
              <Building />
            </div>
            <CardTitle className="text-xl">Company Details</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-4">
            <div className="grid gap-2">
              <p className="text-sm  text-gray-500">Org ID</p>
              <div className=" font-medium">{companyDetails?.orgId}</div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm  text-gray-500">Company Name</p>
              <div className=" font-medium">{companyDetails?.companyName}</div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm  text-gray-500">
                Total Number of Recruiters
              </p>
              <a
                href={`${RECUITERS_LIST}?organisation_id=${recruiterDetails?.orgId}`}
                className="underline text-blue-500 font-medium"
              >
                {companyDetails?.recruiterCount}
              </a>
            </div>
            <div className="grid gap-2">
              <p className="text-sm  text-gray-500">Total Jobs Posted</p>
              <div className="font-medium">
                {companyDetails?.companyJobPostCount}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm  text-gray-500">Paid Jobs Made Live</p>
              <div className=" font-medium">
                {companyDetails?.companyPaidLiveJobs}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm  text-gray-500">Total Live Jobs</p>
              <div className=" font-medium">
                {companyDetails?.companyTotalLiveJobs}
              </div>
            </div>

            <div className="grid gap-2">
              <p className="text-sm  text-gray-500">Total Applications</p>
              <div className=" font-medium">
                {companyDetails?.companyTotalResponses || "-"}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm  text-gray-500">Total Database Matches</p>
              <div className=" font-medium">
                {companyDetails?.companyTotalRecommendations || "-"}
              </div>
            </div>
            <div className="grid md:col-span-2 gap-2">
              <p className="text-sm  text-gray-500">Categories</p>
              <div className="flex flex-wrap gap-2">
                {companyDetails?.companyCategories?.map((category, index) => (
                  <div
                    key={index}
                    className="bg-gray-100 px-2 py-1 rounded-md text-sm dark:bg-gray-800"
                  >
                    {category?.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="grid md:col-span-2 gap-2">
              <p className="text-sm  text-gray-500">Cities Posted</p>
              <div className="flex flex-wrap gap-1">
                {companyDetails?.companyCities?.map((city, index) => (
                  <div
                    key={index}
                    className="bg-gray-100 px-2 py-1 rounded-md text-sm dark:bg-gray-800"
                  >
                    {city?.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </StateRenderer>
  );
};

export default CompanyDetails;
