import React from "react";
import DocumentTitle from "react-document-title";
import { checkPermission, isCRMAdmin } from "../../services/utils";
import {
  URL_SHORTENER,
  LEARN_TAB,
  WORKBENCH,
  DYNAMIC_DAG,
  MODERATOR_LIST,
  LCS_IG
} from "../../routes/RouteConstants";
import NoAccess from "../Common/NoAccess";

const ToolsDashboard = () => {
  const showURLShortener = checkPermission("Job URL Shortener");
  const showLTV = checkPermission("LearnTabVideo");
  const isWorkbench = checkPermission("Workbench");
  const isDynamicDagBuilder = checkPermission("Dynamic Dag Builder");
  const isLcsBannerGen = checkPermission("LcsBannerGen");
  const isViewPage =
    showURLShortener ||
    showLTV ||
    isWorkbench ||
    isDynamicDagBuilder ||
    isLcsBannerGen;
  return (
    <DocumentTitle title={"Job Hai CRM - Tools Dashboard"}>
      <div className="dashboard">
        <div className="clr gap-20" />
        <div className="pageHeading row">
          <h2>Tools Dashboard </h2>
        </div>
        <div className="clr gap-20" />
        <div className="whiteCard">
          {isViewPage ? (
            <div className="container-fluid">
              <div className="clr gap-20" />
              <div className="row marginBottom20">
                {showURLShortener ? (
                  <div className="col-md-4 marginBottom20">
                    <div className="box">
                      <a href={URL_SHORTENER}>Job URL Shortener</a>
                    </div>
                  </div>
                ) : null}
                {showLTV ? (
                  <div className="col-md-4 marginBottom20">
                    <div className="box">
                      <a href={LEARN_TAB}>Learn Tab Video</a>
                    </div>
                  </div>
                ) : null}
                {isWorkbench ? (
                  <div className="col-md-4 marginBottom20">
                    <div className="box">
                      <a href={WORKBENCH}>Workbench</a>
                    </div>
                  </div>
                ) : null}
                {isDynamicDagBuilder ? (
                  <div className="col-md-4 marginBottom20">
                    <div className="box">
                      <a href={DYNAMIC_DAG}>Dynamic Dag Builder</a>
                    </div>
                  </div>
                ) : null}
                {isCRMAdmin() ? (
                  <div className="col-md-4">
                    <div className="box">
                      <a href={MODERATOR_LIST}>Moderator Access</a>
                    </div>
                  </div>
                ) : null}
                {isLcsBannerGen ? (
                  <div className="col-md-4 marginBottom20">
                    <div className="box">
                      <a href={LCS_IG}>LCS Banner Gen</a>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <NoAccess />
          )}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default ToolsDashboard;
