import React, { useState } from "react";
import classes from "./apply-estimator.module.css";
import { compareApplyJobs } from "../../../services/applyEstimatorService";
import PopupMessage from "../../PopupMessage";

const ApplyEstimator = props => {
  const { jobData } = props;
  const {
    job_id = "",
    category_id = "",
    cluster_id = "",
    job_distinction = ""
  } = jobData;
  const [isCompare, setIsCompare] = useState(false);
  const [compareData, setCompareData] = useState(false);
  const [popupMessageObj, setPopupMessageObj] = useState({ showMsg: false });

  const onCompare = async () => {
    const dataObj = {
      job_id,
      category_id,
      cluster_id,
      job_distinction
    };
    try {
      const data = await compareApplyJobs(dataObj);
      setIsCompare(true);
      setCompareData(data);
    } catch (error) {
      console.error(error);
      setPopupMessageObj({
        msgType: "error",
        msg: "Something went wrong",
        showMsg: true
      });
      setIsCompare(false);
    }
  };

  const {
    shown_text = "",
    applies_sub_text = "",
    applications_20th_percentile = "",
    applications = "",
    applications_80th_percentile = ""
  } = compareData;

  return (
    <>
      <PopupMessage
        data={popupMessageObj}
        click={() => setPopupMessageObj({ showMsg: false })}
      />
      <div className={classes.root}>
        {isCompare ? (
          <div className={classes.rootText}>
            <div className={classes.heading}>{shown_text}</div>
            {applies_sub_text ? (
              <div className={classes.subHeading}>{applies_sub_text}</div>
            ) : null}
            <ul className={classes.uiCont}>
              {applications_80th_percentile !== null ? (
                <li className={classes.list}>
                  top performing job - {applications_80th_percentile} applies
                </li>
              ) : null}
              {applications !== null ? (
                <li className={classes.list}>
                  regular performing job - {applications} applies
                </li>
              ) : null}
              {applications_20th_percentile !== null ? (
                <li className={classes.list}>
                  poor performing job - {applications_20th_percentile} applies
                </li>
              ) : null}
            </ul>
          </div>
        ) : (
          <>
            <div className={classes.heading}>Compare Applies</div>
            <button className="submitBtn" onClick={onCompare}>
              Compare Now
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ApplyEstimator;
