import { create } from "zustand";
import { getCityData } from "../services/metadataApiServices";

const useCityDataStore = create((set, get) => ({
  cityData: [],
  liveCities: [],
  cityFetchState: "LOADING",
  fetchCityData: async () => {
    try {
      if (get().cityFetchState === "SUCCESS") {
        return;
      }
      set({
        cityFetchState: "LOADING"
      });
      const response = await getCityData();
      set({
        cityData: [...response],
        liveCities: response.filter(item => item.has_jobs),
        cityFetchState: "SUCCESS"
      });
    } catch (err) {
      set({
        cityFetchState: "ERROR",
        cityData: []
      });
    }
  }
}));

export default useCityDataStore;
