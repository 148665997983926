import moment from "moment";

export const formatDateAgo = date => {
  if (date) {
    const now = moment();
    const then = moment(date);

    const diffInSeconds = Math.abs(now.diff(then, "seconds"));
    const diffInMinutes = Math.abs(now.diff(then, "minutes"));
    const diffInHours = Math.abs(now.diff(then, "hours"));
    const diffInDays = Math.abs(now.diff(then, "days"));
    // const diffInMonths = Math.abs(now.diff(then, "months"));

    if (now.isBefore(then)) {
      // Past date
      if (diffInSeconds < 60) {
        return `In ${diffInSeconds} seconds`;
      } else if (diffInMinutes < 60) {
        return `In ${diffInMinutes} minutes`;
      } else if (diffInHours < 24) {
        return `In ${diffInHours} hours`;
      } else {
        return `In ${diffInDays} days`;
      }
    } else {
      // Future date
      if (diffInSeconds < 60) {
        return `${diffInSeconds} second ago`;
      } else if (diffInMinutes < 60) {
        return `${diffInMinutes} minutes ago`;
      } else if (diffInHours < 24) {
        return `${diffInHours} hours ago`;
      } else {
        return `${diffInDays} days ago`;
      }
    }
  }
  return "";
};

const getUserData = () => JSON.parse(localStorage.getItem("userData")) || {};

export const isLmsAdmin = () => {
  const { lms_user_level = "" } = getUserData();
  return lms_user_level === "ADMIN";
};

export const isLmsModerator = () => {
  const { lms_user_level = "" } = getUserData();
  return lms_user_level === "MODERATOR";
};

export const getLmsUserLevel = () => {
  const { lms_user_level = "" } = getUserData();
  return lms_user_level;
};

export const isLmsUser = () => {
  const userLevel = getLmsUserLevel();
  return userLevel && userLevel !== "USER";
};

export const isAdmin = () => {
  const userLevel = getLmsUserLevel();
  return userLevel === "ADMIN";
};
export const isTl = () => {
  const userLevel = getLmsUserLevel();
  return userLevel === "TEAM_LEAD";
};

export const isModerator = () => {
  const userLevel = getLmsUserLevel();
  return userLevel === "MODERATOR";
};

export const customDateFormat = (date, format = "YYYY-MM-DD") => {
  if (date) {
    return moment(date).format(format);
  }
  return "NA";
};

export const hasAnyValue = (filters, deleteBucket = false) => {
  if (deleteBucket) delete filters.bucketIds;
  for (const key in filters) {
    if (typeof filters[key] === "object") {
      if (hasAnyValue(filters[key])) {
        return true;
      }
    } else {
      if (filters[key] !== "") {
        return true;
      }
    }
  }
  return false;
};

export const validateMinMax = (fieldItems, field, label) => {
  const { min, max } = fieldItems[field] || {};
  if (min && max && Number(min) > Number(max)) {
    return `${label}: 'Min' value should be less than 'max' value`;
  }
  return false;
};

export const validateDateRange = (fieldItems, field, label) => {
  const { from, to } = fieldItems[field] || {};
  if ((from && !to) || (!from && to)) {
    return `${label}: Both 'from' and 'to' dates should be present`;
  }
  if (from && to && from > to) {
    return `${label}: 'From' date should be less than 'to' date`;
  }
  return false;
};

export const parseDateStringToDate = date => {
  if (date) return moment(date).toDate();
  else return null;
};

export const formatDateTime = (date, time = "00:00:00") => {
  if (!date) return undefined;
  const formattedDate = moment(date).format("YYYY-MM-DD");
  return `${formattedDate} ${time}`;
};
