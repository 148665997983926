const HeadingRow = ({ heading = "", goBackURL = "", children = "" }) => {
  return (
    <div className="pageHeadingNew">
      <div className="firstColumns">
        <div className="heading">{heading}</div>
        {children}
      </div>
      {goBackURL ? (
        <div className="float-right">
          <a href={goBackURL}>Go Back</a>
        </div>
      ) : null}
    </div>
  );
};
export default HeadingRow;
