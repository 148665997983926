import React from "react";
import JobHai from "../../../api/JobHai";
import { getErrorMessage, getFormattedValue } from "../../../services/utils";
import CustomDrawer from "../../Common/CustomDrawer";

class AddReferral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      given_referral: "",
      errorMsg: "",
      successMsg: ""
    };
  }

  saveReferral = e => {
    e.preventDefault();
    const { recId = "" } = this.props;
    const { given_referral } = this.state;
    let url = `/add-referral`;
    JobHai.post(url, {
      recruiter_id: recId,
      given_referral
    })
      .then(response => {
        const data = response.data.data;
        const { success = "", error = "" } = data;
        this.setState({ errorMsg: error, successMsg: success });
      })
      .catch(
        function (error) {
          let msg = getErrorMessage(error);
          this.setState({ errorMsg: msg, successMsg: "" });
        }.bind(this)
      );
  };

  toggleDrawer = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  handleChange = event => {
    let value = event.target.value;
    value = getFormattedValue(value, true, true);
    this.setState({ given_referral: value, errorMsg: "", successMsg: "" });
  };

  render() {
    const { isOpen, given_referral, errorMsg, successMsg } = this.state;
    return (
      <>
        <button className="btn btn-primary postjob" onClick={this.toggleDrawer}>
          Add Referral
        </button>
        <CustomDrawer
          isOpen={isOpen}
          toggleDrawer={this.toggleDrawer}
          type="isClosed"
          isCloseButton={true}
        >
          <div className="pageHeading">
            <h2>Add Referral</h2>
          </div>
          <div className="row drawer-container">
            <div className="col-md-12">
              <label>Referral Number</label>
              <input
                type="text"
                className="form-control"
                name="given_referral"
                value={given_referral}
                onChange={this.handleChange}
                maxLength="10"
              />
            </div>
            <div className="col-md-12 add-referral-button-container">
              <span className="text-danger">{errorMsg}</span>
              <span className="text-success">{successMsg}</span>
              <button
                onClick={this.saveReferral}
                className="bttn saveBtn"
                disabled={given_referral.length < 10}
              >
                SAVE
              </button>
            </div>
          </div>
        </CustomDrawer>
      </>
    );
  }
}
export default AddReferral;
