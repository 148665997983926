import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import cx from "classnames";
import BoostLogs from "./BoostLogs";
import CustomSelect from "../Common/Form/CustomSelect";
import ConfirmationCTA from "../CreditWidget/ConfirmationCTA";
import { checkPermission, getErrorMessage } from "../../services/utils";
import AfterSuccessCTA from "../CreditWidget/AfterSuccessCTA";
import moment from "moment";
import { saveJobBoost, styles } from "./helper";

const STATES = {
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  ERROR: "ERROR",
  PRELIMINARY: "PRELIMINARY",
  FINAL: "FINAL",
  SUCCESS: "SUCCESS"
};

function JobBoost({ classes, jobId }) {
  const [currentState, setCurrentState] = useState(STATES.PRELIMINARY);
  const [postingState, setPostingState] = useState(STATES.FETCHED);
  const [boostScore, setBootScore] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  function handleChange({ target: { name, value } }) {
    setBootScore(value);
  }

  async function onSave(st) {
    setErrorMessage("");
    if (st === STATES.PRELIMINARY) {
      setCurrentState(STATES.FINAL);
    }
    if (st === STATES.FINAL) {
      setPostingState(STATES.FETCHING);
      const obj = {
        boost_score: boostScore,
        job_id: jobId
      };
      try {
        const res = await saveJobBoost(obj);
        setPostingState(STATES.SUCCESS);
      } catch (error) {
        const err = getErrorMessage(error);
        setPostingState(STATES.FETCHED);
        setErrorMessage(err);
      }
    }
  }
  function onCancel() {
    setCurrentState(STATES.PRELIMINARY);
    setPostingState(STATES.FETCHED);
    setBootScore("");
  }
  const boostScoreList = [
    { label: "High", value: "HIGH" },
    { label: "Medium", value: "MEDIUM" },
    { label: "Low", value: "LOW" }
  ];
  return (
    <div className={classes.widgetRot}>
      <BoostLogs jobId={jobId} />
      {checkPermission("Job_Boost") ? (
        <div className={classes.root}>
          <div
            className={cx(classes.header, classes.padding, classes.container2)}
          >
            {currentState === STATES.PRELIMINARY ? (
              <div className="container">
                <div className="container2Child1">
                  <b>Job Boost​</b>
                </div>
                <div>
                  <CustomSelect
                    name="boostScore"
                    defaultPlaceholder="Boost Score"
                    value={boostScore}
                    onChange={handleChange}
                    optionsList={boostScoreList}
                  />
                </div>
                <div>
                  <div className={cx("flex", "buttonContainer", "center")}>
                    <input
                      type="button"
                      onClick={() => onSave("PRELIMINARY")}
                      value="Boost this Job​"
                      className={cx(
                        "submitBtn",
                        "btn3",
                        "flex",
                        "justifyCenter"
                      )}
                      disabled={!boostScore}
                    />
                  </div>
                </div>
              </div>
            ) : postingState === STATES.SUCCESS ? (
              <div className={classes.successState}>
                {`Job boosted on ${moment(new Date()).format(
                  "DD MMM yy hh:mm a"
                )}`}
                <AfterSuccessCTA
                  onCancel={() => onCancel()}
                  label="Boost Again"
                />
              </div>
            ) : (
              <>
                <div className="container">
                  <div className="container2Child1">
                    <b>
                      Job will be <br /> boosted​​
                    </b>
                  </div>
                  <ConfirmationCTA
                    disabled={postingState === STATES.FETCHING}
                    onSave={() => onSave(STATES.FINAL)}
                    onCancel={() => onCancel()}
                  />
                </div>
                {errorMessage && (
                  <div className={classes.errorState}>
                    <i className="fa fa-x" aria-hidden="true" />
                    <span>&nbsp; {errorMessage}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default withStyles(styles)(JobBoost);
