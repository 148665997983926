import React from "react";
import { checkPermission } from "../../services/utils";

const SaveChanges = props => {
  const {
    onClick,
    disabled = false,
    isSaved = false,
    errorMessage = "",
    ctaText = "Save Changes",
    assignTo = "",
    showCTA = true
  } = props;
  return (
    <div className="btnContainer">
      {errorMessage ? <div className="errorMsg">{errorMessage}</div> : null}
      {assignTo ? (
        <span className="assinedTo">Assigned to: {assignTo}</span>
      ) : null}
      {showCTA && (
        <>
          {isSaved ? (
            <div className="successButton">
              <img src="/images/verified.svg" alt="saved" />
              Saved
            </div>
          ) : (
            <input
              type="button"
              onClick={onClick}
              value={ctaText}
              className="submitBtn"
              disabled={disabled}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SaveChanges;
