import React from "react";
import Alert from "react-bootstrap/Alert";

function PopupMessage(props) {
  const { showMsg, msgType, msg } = props.data;
  const variant = msgType === "error" ? "danger" : "success";
  return (
    <>
      {showMsg && msg ? (
        <Alert variant={variant} onClose={props.click} dismissible>
          <div
            dangerouslySetInnerHTML={{
              __html: `${msg}`
            }}
          ></div>
        </Alert>
      ) : null}
    </>
  );
}

export default PopupMessage;
