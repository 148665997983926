import axios from "axios";
import { getLocalStorage } from "../services/localStorage";
import { getCookie } from "../services/cookie";

const token = getLocalStorage("token");
const proxymoderatorid = getCookie("proxy_moderator_id");
let headers = {
  Authorization: token,
  "Content-Type": "application/json",
  accept: "application/json",
  "Cache-Control": "no-cache, no-store, must-revalidate",
  "Accept-Language": navigator.language || "en-US"
};

if (proxymoderatorid) {
  headers.proxymoderatorid = proxymoderatorid;
}

export default axios.create({
  baseURL: process.env.REACT_APP_DEV_API_URL,
  headers
});
