import classes from "./backdrop.module.css";
const ComponentWrappedBackdrop = ({ isVisible, children, onClick }) => {
  if (!isVisible) return null;

  return (
    <div className={classes.backdrop} onClick={onClick}>
      {children ? (
        <div
          className={classes.backdropContent}
          onClick={e => e.stopPropagation()}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default ComponentWrappedBackdrop;
