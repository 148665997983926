import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";
import SkeletonLoader from "../SkeletonLoader";

const LeadQuesAns = props => {
  const { leadQuesAns, isQesAnsLoading } = props;
  return (
    <>
      <Heading heading="Qualifying Questions" customClass="subHeading" />
      {isQesAnsLoading ? (
        <SkeletonLoader customClass="leadsLoader" />
      ) : (
        <div className="noActionChip">
          {leadQuesAns.map((item, i) => {
            const { question_id = "", option = [] } = item;
            const isOptions = option && option.length;
            return (
              <div className="item" key={`qns${question_id}`}>
                <Label lable={`Q${i + 1}. ${item.question}`} />
                <ul class="chips">
                  {isOptions &&
                    option.map((op, index) => {
                      return (
                        <li
                          className="active"
                          key={`li${question_id}-${index}`}
                        >
                          <div>
                            {op}
                            <img
                              src="/images/cross.svg"
                              alt="cancel"
                              width="16"
                              height="16"
                            />
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          })}
        </div>
      )}
      <div className="seperator" />
    </>
  );
};

export default LeadQuesAns;
