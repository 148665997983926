import JobHai from "../../api/JobHai";
import { addItemIndexedDb, getItemIndexedDb } from "../../lib/indexedDB";
import { getErrorMessage } from "../utils";

export const getStorageValue = async (url, key) => {
  try {
    const storedItem = (await getItemIndexedDb(key)) || {};
    let { data, id } = storedItem;
    if (!id) {
      const response = await JobHai.get(url);
      data = response.data.data;
      // Store the data in IndexedDB
      await addItemIndexedDb({ id: key, data });
    }
    return data;
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg;
  }
};
