import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Label from "../Common/Form/Label";
import CustomSelect from "../Common/Form/CustomSelect";
import CustomTextField from "../Common/Form/CustomTextField";
import JobHai from "../../api/JobHai";
import { getErrorMessage, isEmpty } from "../../services/utils";
import LoadingSpinner from "../LoadingSpinner";
import { onBoardingOptionsList } from "../../services/constant";

const styles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    background: "#FFFFFF",
    border: "1px solid rgba(233, 236, 245, 0.6)",
    borderRadius: "10px",
    marginBottom: "20px"
  },
  header: {
    background: "rgba(233, 236, 245, 0.3)",
    border: "1px solid #F1F3F9",
    borderRadius: "10px",
    padding: "16px 28px",
    color: "#41485A",
    "& span": {
      color: "#55A1F8",
      fontWeight: 700
    },
    "& strong": {
      color: "#057317",
      fontWeight: 700
    }
  },
  title: {
    color: "#41485A",
    marginTop: "10px",
    "& strong": {
      fontSize: "17px",
      fontWeight: 700,
      color: "#41485A"
    }
  },
  container: {
    padding: "16px 28px"
  },
  score: {
    fontWeight: 700,
    fontSize: "17px"
  },
  formContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "20px 0 -12px 0"
  },
  formItem: {
    width: "95px",
    "&& .select": {
      minWidth: "95px",
      backgroundImage: `url("/images/obc-down.svg")`
    },
    "&& .selectActive": {
      minWidth: "95px",
      backgroundImage: `url("/images/obc-up.svg")`
    }
  },
  btnContainer: { display: "flex", justifyContent: "flex-end" },
  topggle: {
    border: "1px solid #2A89F6",
    borderRadius: "100px",
    width: "44px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginLeft: "10px"
  },
  blue: { color: "#55A1F8 !importan" },
  red: { color: "#D93C3B !important" }
});

const OnBoardingCalculater = props => {
  const [isToggle, onToggle] = useState(false);
  const [errorMsg, handleError] = useState("");
  const [items, handleFormItems] = useState({
    posting_details: null,
    caller_id: null,
    whatsapp_acc: null
  });
  const [obsScore, updateScore] = useState(0);
  const [jobPostingDetailsScore, updatejobPostingDetailsScore] = useState("");
  const [isSaveDisabled, handleSaveClicked] = useState(false);
  const { obcData } = props;

  useEffect(() => {
    const {
      true_caller_score = null,
      whatsapp_account_score = null,
      job_posting_details_score = null,
      onboarding_score = null
    } = obcData;
    let formItems = {
      posting_details: job_posting_details_score,
      caller_id: true_caller_score,
      whatsapp_acc: whatsapp_account_score
    };
    handleFormItems(JSON.parse(JSON.stringify(formItems)));
    updateScore(onboarding_score ? onboarding_score : 0);
    updatejobPostingDetailsScore(job_posting_details_score);
  }, [obcData]);

  function handleToggle() {
    onToggle(!isToggle);
  }

  function handleOnChnage(event) {
    const {
      target: { name, value }
    } = event;
    let tempObj = { ...items };
    tempObj[name] = value;
    handleFormItems(tempObj);
    handleError("");
  }

  function calculateScore() {
    const {
      jobId,
      recId,
      handleOnBoradingScore,
      relaodRfd,
      onboardingMethod = {},
      callRecruiter
    } = props;
    const { method = "" } = onboardingMethod;
    handleError("");
    handleSaveClicked(true);
    const {
      systemRedFlagScore = 0,
      emailTypeAndStatusScore = 0,
      webSitePresentScore = 0,
      is_onboarded = false
    } = obcData;
    const { caller_id, whatsapp_acc, posting_details } = items;
    const dataObj = {
      systemRedFlagScore: isEmpty(systemRedFlagScore) ? 0 : systemRedFlagScore,
      emailTypeAndStatusScore: isEmpty(emailTypeAndStatusScore)
        ? 0
        : emailTypeAndStatusScore,
      webSitePresentScore: isEmpty(webSitePresentScore)
        ? 0
        : webSitePresentScore,
      true_caller_score: caller_id,
      whatsapp_account_score: whatsapp_acc,
      job_posting_details_score: posting_details,
      recruiter_id: recId,
      job_id: jobId,
      is_onboarded,
      onBoradingMethod: method,
      callRecruiter
    };

    JobHai.post("/calculate_onboarding_score", dataObj)
      .then(response => {
        let data = response.data.data;
        const { onboarding_score = 0 } = data;
        if (relaodRfd) {
          relaodRfd();
        }
        updateScore(onboarding_score);
        updatejobPostingDetailsScore(posting_details);
        handleOnBoradingScore();
        handleSaveClicked(false);
      })
      .catch(error => {
        let msg = error.message ? error.message : getErrorMessage(error);
        handleError(msg);
        handleSaveClicked(false);
      });
  }

  function renderFormHTML() {
    const { is_onboarded = false } = obcData;
    const isRecruiterVerified = is_onboarded;
    const { posting_details, caller_id, whatsapp_acc } = items;
    const optionsList = onBoardingOptionsList;
    return (
      <div className="formRow">
        <div className={classes.formContainer}>
          <div className={classes.formItem}>
            <Label lable="Posting Details:" />
            <CustomSelect
              name="posting_details"
              value={posting_details}
              onChange={handleOnChnage}
              fullWidth={false}
              defaultPlaceholder={"----"}
              optionsList={optionsList}
              disabled={isRecruiterVerified}
              isError={
                !isEmpty(posting_details) && Number(posting_details) === 0
              }
            />
          </div>
          <div className={classes.formItem}>
            <Label lable="Caller ID:" />
            <CustomSelect
              name="caller_id"
              value={caller_id}
              onChange={handleOnChnage}
              fullWidth={false}
              defaultPlaceholder={"----"}
              optionsList={optionsList}
              disabled={isRecruiterVerified}
              isError={!isEmpty(caller_id) && Number(caller_id) === 0}
            />
          </div>
          <div className={classes.formItem}>
            <Label lable="Whatsapp Acc.:" />
            <CustomSelect
              name="whatsapp_acc"
              value={whatsapp_acc}
              onChange={handleOnChnage}
              fullWidth={false}
              defaultPlaceholder={"----"}
              optionsList={optionsList}
              disabled={isRecruiterVerified}
              isError={!isEmpty(whatsapp_acc) && Number(whatsapp_acc) === 0}
            />
          </div>
        </div>
      </div>
    );
  }
  const {
    onboardingMethod = {},
    classes,
    isRefLoading,
    totalLiveJobs,
    callRecruiter
  } = props;

  const {
    systemRedFlagScore = 0,
    emailTypeAndStatusScore = 0,
    webSitePresentScore = 0,
    is_onboarded = false
  } = obcData;
  const { posting_details, caller_id, whatsapp_acc } = items;
  const disabled =
    isEmpty(posting_details) ||
    isEmpty(caller_id) ||
    isEmpty(whatsapp_acc) ||
    isSaveDisabled;

  const { method } = onboardingMethod;
  let callCopy = "Call Required";
  let clsName = classes.red;

  if (obsScore === 0 || disabled) {
    callCopy = "Fill Onboarding Score";
    clsName = classes.blue;
  } else if (obsScore < 1 || callRecruiter) {
    callCopy = "Call Required";
    clsName = classes.red;
  } else if (method === "M2" || method === "M3") {
    if (
      Number(jobPostingDetailsScore) === 0 ||
      Number(emailTypeAndStatusScore) === 0
    ) {
      callCopy = "Call Required";
      clsName = classes.red;
    } else if (obsScore >= 1) {
      callCopy = "No Call Required";
      clsName = classes.blue;
    }
  }
  const isOnboarded = is_onboarded ? is_onboarded : totalLiveJobs > 0;
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {isOnboarded ? (
          <>
            <div>
              Verification status: <strong>Recruiter Onboarded</strong>
            </div>
            <div className={classes.title}>
              Rec. Onboarding Score:{" "}
              <strong className={classes.score}>{obsScore}</strong>
            </div>
          </>
        ) : (
          <>
            Verification status: <span className={clsName}>{callCopy}</span>
          </>
        )}
      </div>
      <div className={classes.container}>
        {isRefLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {!isOnboarded ? (
              <>
                <div className="title">
                  Rec. Onboarding Score:{" "}
                  <span className={classes.score}>{obsScore}</span>
                </div>
                {renderFormHTML()}
              </>
            ) : null}
            {isToggle ? (
              <>
                {isOnboarded ? renderFormHTML() : null}
                <div className="formRow">
                  <div className={classes.formContainer}>
                    <div className={classes.formItem}>
                      <Label lable="System Flags" />
                      <CustomTextField
                        name="system_flag"
                        value={systemRedFlagScore}
                        isReadonly={true}
                        isError={Number(systemRedFlagScore) === 0}
                      />
                    </div>
                    <div className={classes.formItem}>
                      <Label lable="Online Presence:" />
                      <CustomTextField
                        name="online_presence"
                        value={webSitePresentScore}
                        isReadonly={true}
                        isError={Number(webSitePresentScore) === 0}
                      />
                    </div>
                    <div className={classes.formItem}>
                      <Label lable="E-mail ID:" />
                      <CustomTextField
                        name="email_Id"
                        value={emailTypeAndStatusScore}
                        isReadonly={true}
                        isError={Number(emailTypeAndStatusScore) === 0}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div className={classes.btnContainer}>
              {!isOnboarded ? (
                <input
                  type="button"
                  onClick={() => calculateScore()}
                  value="Save"
                  className="submitBtn"
                  disabled={disabled}
                />
              ) : null}
              <div className={classes.topggle} onClick={() => handleToggle()}>
                <img
                  src={isToggle ? "/images/obc-up.svg" : "/images/obc-down.svg"}
                  alt="arrow"
                  width="20"
                  height="20"
                />
              </div>
            </div>
            {errorMsg ? <div className="text-danger">{errorMsg}</div> : null}
          </>
        )}
      </div>
    </div>
  );
};
export default withStyles(styles)(OnBoardingCalculater);
