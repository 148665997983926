import React from "react";
import {
  capitalizeFirstLetter,
  isObject,
  isNullOrEmpty
} from "../../services/utils";

const Chips = props => {
  const {
    chips,
    activeChips = [],
    isLabel = true,
    onClick,
    isMulti = true,
    fieldName = "",
    isClose = false,
    label = "label",
    isDisabled = false
  } = props;
  return (
    <>
      {chips.length > 0 ? (
        <ul className={"chips"}>
          {isClose && activeChips && activeChips.id ? (
            <li
              onClick={
                !isDisabled ? e => onClick(e, fieldName, activeChips) : null
              }
              data-id={activeChips.id}
              className={"active"}
            >
              <div>
                {capitalizeFirstLetter(activeChips.label)}
                <img
                  src="/images/cross.svg"
                  alt="cancel"
                  width="16"
                  height="16"
                />
              </div>
            </li>
          ) : (
            chips.map((item, index) => {
              let isActive = false;
              if (isLabel && activeChips.length) {
                isActive = activeChips.filter(
                  i => i.id === item.id || i === item.id || i === item.label
                ).length;
              } else if (activeChips && activeChips.id === item.id) {
                isActive = true;
              }
              // Added disabled from array as will as single item
              const disabled = item.isDisabled || (isDisabled && !isActive);
              return (
                <li
                  onClick={
                    !isDisabled || isActive
                      ? e => onClick(e, fieldName, item)
                      : null
                  }
                  key={`chips-${index}${isLabel ? item.id : item}`}
                  data-id={isLabel && isObject(item) ? item.id : item}
                  className={
                    isActive ? "active" : disabled ? "inactiveChip" : ""
                  }
                >
                  <div>
                    {isLabel && isObject(item) ? item[label] : item}
                    {isMulti || item.type === "free-text" ? <span /> : null}
                  </div>
                </li>
              );
            })
          )}
        </ul>
      ) : null}
    </>
  );
};

export default Chips;
