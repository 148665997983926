import React from "react";
import moment from "moment";
import {
  findBooleanState,
  findChipValue,
  getFormattedValue,
  getValueFromURL,
  isAnyCallingConnected,
  isAnyCallingNotConnected,
  isDriverCategory,
  isNullOrEmpty
} from "../../services/utils";
import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import CustomSelect from "../Common/Form/CustomSelect";
import Chips from "../Common/Chips";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import {
  enterPriseLeadStatus,
  enterPriseLeadType,
  lcsLeadSource,
  leadCallingAttemtList,
  leadPlatform,
  typeOfDriverList,
  walkinCallingAttemtList
} from "../../constants/MetaData";
import CallingAttempt from "../Common/CallingAttempt";
import LeadQuesAns from "./LeadQuesAns";
import SendWhatsApp from "./SendWhatsApp";
import NonEditableFields from "./NonEditableFields";
import LeadReAssign from "./LeadReAssign";
import { getCookie } from "../../services/cookie";

const chipStateMap = {
  gender: "activeGender",
  lead_status: "activeLeadStatus"
};
class UpdateLeadsDetailsV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeGender: {},
      activeLeadStatus: {},
      leadStatus: "",
      showaAditionalcalCallAttemt: false
    };
  }

  componentDidMount() {
    this.setInitialChips();
  }

  setInitialChips = () => {
    const { leadData } = this.props;
    const { gender = "", lead_status = "" } = leadData;

    const activeGender = { id: gender, label: gender };

    const activeLeadStatusObj = enterPriseLeadStatus.filter(
      s => s.id === lead_status
    );

    let activeLeadStatus = { id: "", label: "" };
    if (activeLeadStatusObj.length) {
      activeLeadStatus = activeLeadStatusObj[0];
    }

    this.setState({
      activeGender,
      activeLeadStatus,
      leadStatus: lead_status
    });
  };

  updateFieldData = (fieldName, value) => {
    const { isSaved, getFieldData } = this.props;
    /* Disabled this code to fix followup cases */
    if (leadCallingAttemtList.includes(fieldName)) {
      //  this.setState({ activeLeadStatus: {}, leadStatus: "" });
      //  getFieldData("lead_status", "");
      getFieldData("not_interested_reason", "");
    }
    if (!isSaved) getFieldData(fieldName, value);
  };

  changeWalkingCallAttempt = (fieldName, value) => {
    const { isSaved, getFieldData, leadData } = this.props;
    let walkinCallingAttempts = leadData?.walkin_calling_attempt || {};
    const markedOn = moment().format("YYYY-MM-DD hh:mm:ss");
    if (value === "NEW") {
      walkinCallingAttempts = {
        ...walkinCallingAttempts
      };
      delete walkinCallingAttempts[fieldName]; // Remove the field when value is "NEW"
    } else {
      walkinCallingAttempts = {
        ...walkinCallingAttempts,
        [fieldName]: {
          status: value,
          marked_on: markedOn
        }
      };
    }
    if (!isSaved) getFieldData("walkin_calling_attempt", walkinCallingAttempts);
  };

  changeWalkingCallAttemptDisabled = (fieldName, value) => {
    return;
  };

  handleChange = event => {
    let name = event.target.name;
    let value = getFormattedValue(event.target.value);

    if (name === "experience") {
      value = getFormattedValue(value, true);
    }
    if (name === "phone") {
      value = getFormattedValue(value, true, true);
    }
    if (name === "age") {
      value = getFormattedValue(value, true, true);
    }
    if (name === "current_salary") {
      value = getFormattedValue(value, true);
    }
    if (name === "city_id") {
      this.updateFieldData("locality_id", "");
    }
    if (name === "not_interested_reason_id") {
      this.updateFieldData("not_interested_reason", "");
    }
    this.updateFieldData(name, value);
  };

  handleChips = (event, fieldName, item) => {
    const { leadData, isSaved } = this.props;
    if (isSaved) return;
    let newValue = item.id;
    if (!isNullOrEmpty(leadData[fieldName])) {
      newValue = "";
    }
    if (fieldName === "lead_status" || fieldName === "gender") {
      const fieldId = this.state[chipStateMap[fieldName]].id;
      newValue = fieldId ? "" : item.id;
      this.setState({
        [chipStateMap[fieldName]]: fieldId ? [] : item,
        leadStatus: newValue
      });
    }
    this.updateFieldData(fieldName, newValue);
  };

  setMoreCallAttempt = () => {
    this.setState({ showaAditionalcalCallAttemt: true });
  };
  renderDriveryInfo = () => {
    const { leadData, isSaved } = this.props;
    const {
      experience = "",
      type_of_driver = "",
      other_type_of_driver = ""
    } = leadData;
    return (
      <>
        <div className="fullWidth">
          <div className="left">
            <Label lable="No. of years of experience" />
            <CustomTextField
              name="experience"
              value={experience}
              onChange={this.handleChange}
              maxLength={2}
              isReadonly={isSaved}
            />
          </div>

          <div className="right">
            <Label lable="Type of Driver (CV/PV)" />
            <CustomSelect
              name="type_of_driver"
              value={type_of_driver}
              onChange={this.handleChange}
              optionsList={typeOfDriverList}
              itemLabel="label"
              optionValue="value"
              isSelectDisabled={true}
              disabled={isSaved}
            />
          </div>
        </div>
        {type_of_driver === "Other" ? (
          <div className="formRow">
            <div className="left">
              <Label lable="Other" isOptional />
              <CustomTextField
                name="other_type_of_driver"
                value={other_type_of_driver}
                onChange={this.handleChange}
                maxLength={255}
                isReadonly={isSaved}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  };

  render() {
    const {
      formFields,
      leadData,
      leadMetadata = [],
      isSaved,
      leadQuesAns,
      notInterestedReasons = [],
      initailWallkingCallAttempt = 0,
      handleModeratorId
    } = this.props;
    const {
      callingAttemts,
      sendWhatsapp,
      formMetadata,
      notInterestedInterviewReasonList,
      walkinCallingAttempts
    } = leadMetadata;
    const {
      activeGender,
      activeLeadStatus,
      leadStatus,
      showaAditionalcalCallAttemt
    } = this.state;
    const {
      locality_id = "",
      city_id = "",
      follow_up_date = "",
      lead_calling_first_status = "NEW",
      lead_calling_second_status = "NEW",
      lead_calling_third_status = "NEW",
      lead_calling_fourth_status = "NEW",
      lead_calling_fifth_status = "NEW",
      category_id = "",
      campaign_name = "",
      client_id = "",
      lead_user_id = "",
      not_interested_reason = "",
      not_interested_reason_id = "",
      notInterestedReason = "",
      notInterestedReasonId,
      showUserInterviewIntent,
      userInterviewIntent,
      user_interview_time,
      is_interview_date,
      is_d_1_date,
      is_reconciliation_lead,
      walkin_calling_attempt,
      source = "",
      lead_source = "",
      moderator_id = ""
    } = leadData;
    const allowCallingAttemtpList =
      callingAttemts && callingAttemts < 6
        ? leadCallingAttemtList.slice(0, callingAttemts)
        : leadCallingAttemtList;
    const callStatusValue = {
      lead_calling_first_status,
      lead_calling_second_status,
      lead_calling_third_status,
      lead_calling_fourth_status,
      lead_calling_fifth_status
    };

    const genderField = formFields.gender.options || [];
    const availibilityList = formFields.availibility.options || [];

    let genderList = [];

    genderField.map(item => {
      return genderList.push({ id: item, label: item });
    });

    let leadStatusList = enterPriseLeadStatus;

    if (!isAnyCallingConnected(leadData)) {
      leadStatusList = leadStatusList.map(s => {
        if (
          s.id !== "Interested" ||
          s.id !== "Not_Interested" ||
          s.id !== "Follow_up"
        )
          return { ...s, isDisabled: true };
        return s;
      });
    } else {
      leadStatusList = leadStatusList.map(s => {
        if (s.id === "Not_Answered") return { ...s, isDisabled: true };
        return s;
      });
    }

    // if all are not connected - enable not answered
    if (isAnyCallingNotConnected(leadData, callingAttemts)) {
      leadStatusList = leadStatusList.map(s => {
        if (s.id === "Not_Answered") return { ...s, isDisabled: false };
        return { ...s, isDisabled: true };
      });
    }
    let activeUserIntent = [];
    if (!isNullOrEmpty(userInterviewIntent)) {
      activeUserIntent = {
        id: findChipValue(userInterviewIntent),
        label: findChipValue(userInterviewIntent)
      };
    }
    //!isNullOrEmpty findChipValue
    let intetentLabel = "";
    if (is_interview_date)
      intetentLabel = "Is the user going for interview today";
    else if (is_d_1_date)
      intetentLabel = "Is the user going for interview tomorrow";
    else if (is_reconciliation_lead)
      intetentLabel = "Has the user attended the interview";
    const isOtherReason =
      !isNullOrEmpty(not_interested_reason_id) && not_interested_reason_id == 0;
    const isIntentOtherReason =
      !isNullOrEmpty(notInterestedReasonId) && notInterestedReasonId == 0;

    const walkinCallingAttempt = !isNullOrEmpty(walkin_calling_attempt)
      ? walkin_calling_attempt
      : {};
    const firstCall = walkinCallingAttempt?.first_call_attempt || "";
    const secondCall = walkinCallingAttempt?.second_call_attempt || "";
    const thirdCall = walkinCallingAttempt?.third_call_attempt || "";
    const fourthCall = walkinCallingAttempt?.fourth_call_status || "";
    const fifthCall = walkinCallingAttempt?.fifth_call_status || "";
    const walkinCallStatusValue = {
      first_call_attempt: firstCall ? firstCall.status : "NEW",
      second_call_attempt: secondCall ? secondCall.status : "NEW",
      third_call_attempt: thirdCall ? thirdCall.status : "NEW",
      fourth_call_status: fourthCall ? fourthCall.status : "NEW",
      fifth_call_status: fifthCall ? fifthCall.status : "NEW"
    };

    const allowWalkinCallAttempt =
      walkinCallingAttempts && walkinCallingAttempts < 6
        ? walkinCallingAttemtList.slice(0, initailWallkingCallAttempt)
        : walkinCallingAttemtList;

    const allowWalkinCallAttempt2 =
      walkinCallingAttempts && walkinCallingAttempts < 6
        ? walkinCallingAttemtList.slice(
            initailWallkingCallAttempt,
            initailWallkingCallAttempt + 1
          )
        : walkinCallingAttemtList;
    const isNewValuePresent = allowCallingAttemtpList.some(
      attempt => callStatusValue[attempt] === "NEW"
    );
    let isReferral = lead_source === "REFERRAL" || lead_source === "INBOUND";
    const isViewAdmin = getValueFromURL("view") === "admin";
    const isLeadInbound = lead_source === "INBOUND";
    const isLeadReferral = lead_source === "REFERRAL";
    const hasModerator = !!moderator_id;
    const isSourceCRM = source === "CRM";

    let showLeadAssign = !isViewAdmin && (!hasModerator || isReferral);
    if (isLeadInbound && hasModerator) {
      showLeadAssign = false;
    }
    let isDisabled = isSaved;
    if (
      (isLeadReferral && isSourceCRM && !hasModerator) ||
      (isLeadInbound && !hasModerator)
    ) {
      isDisabled = true;
    }
    return (
      <>
        <Heading heading={`Lead Information (Non-Editable)`}>
          {campaign_name && <i> - {campaign_name}</i>}
          {sendWhatsapp ? (
            <SendWhatsApp campaignId={client_id} leadUserId={lead_user_id} />
          ) : null}
          {showLeadAssign ? (
            <span className="float-right">
              <LeadReAssign
                selectedLead={[lead_user_id]}
                btnLabel={moderator_id ? "Unassign Lead" : "Assign To Me"}
                getUpdatedLeads={handleModeratorId}
                moderatorId={
                  moderator_id ? "unassign" : getCookie("moderator_id")
                }
              />
            </span>
          ) : null}
        </Heading>
        {!isReferral ? <LeadQuesAns leadQuesAns={leadQuesAns} /> : null}
        <NonEditableFields
          leadData={leadData}
          activeGender={activeGender}
          genderList={genderList}
          onChange={this.handleChange}
          onClick={this.handleChips}
        />

        <Heading heading="Editable" />
        <CallingAttempt
          callStatusValue={callStatusValue}
          attemtList={allowCallingAttemtpList}
          handleCallStatus={this.updateFieldData}
          isDisabled={isDisabled}
        />
        <div className="formRow">
          <div className="left">
            <Label lable="City" />
            <div className="selectBox">
              <SelectCity
                filedName={"city_id"}
                value={city_id}
                onChange={this.handleChange}
                customClass="select fullWidth"
                allCity={true}
                isDisabled={isDisabled}
                isSelectDisabled={true}
              />
            </div>
          </div>
          <div className="right">
            <Label lable="Locality" />
            <div className="selectBox">
              <SelectLocality
                filedName={"locality_id"}
                value={locality_id}
                cityId={city_id}
                onChange={this.handleChange}
                customClass="select fullWidth"
                isDisabled={isDisabled}
              />
            </div>
          </div>
        </div>
        <div className="formRow leadFormRow">
          {formMetadata.map((item, index) => {
            const {
              title,
              field,
              name,
              options = [],
              is_mandatory = true
            } = item;
            const classNames = index % 2 === 0 ? "left" : "right";
            let fieldValue = leadData[name] || "";
            let chipsList = [];
            let activeChip = [];
            if (field === "chip") {
              chipsList = options.map(op => ({ ...op, id: op.label }));
              if (!isNullOrEmpty(leadData[name])) {
                const selectedChip = chipsList.find(
                  i => i.id === findChipValue(leadData[name])
                );
                activeChip = selectedChip ? selectedChip : [];
              }
            }
            return (
              <React.Fragment key={`lcsitem${index}`}>
                <div className={classNames}>
                  <Label lable={title} isOptional={!is_mandatory} />
                  {field === "chip" && chipsList.length && (
                    <Chips
                      chips={chipsList}
                      activeChips={activeChip}
                      onClick={this.handleChips}
                      isMulti={false}
                      fieldName={name}
                      isClose={true}
                      isDisabled={isDisabled}
                    />
                  )}
                  {field === "select" && (
                    <CustomSelect
                      name={name}
                      value={fieldValue}
                      onChange={this.handleChange}
                      optionsList={options}
                      itemLabel="label"
                      optionValue="value"
                      isSelectDisabled={true}
                      disabled={isDisabled}
                    />
                  )}
                  {field === "textbox" && (
                    <CustomTextField
                      name={name}
                      value={fieldValue}
                      onChange={this.handleChange}
                      isReadonly={isDisabled}
                    />
                  )}
                  {field === "date" && (
                    <CustomTextField
                      value={fieldValue}
                      onChange={this.handleChange}
                      type="date"
                      name={name}
                      // min={moment().format("YYYY-MM-DD")}
                      isReadonly={isDisabled}
                    />
                  )}
                </div>
                {name === "category_id" && isDriverCategory(category_id)
                  ? this.renderDriveryInfo()
                  : null}
              </React.Fragment>
            );
          })}
        </div>
        <div className="formRow">
          <div className="left">
            <Label lable="Status" />
            <Chips
              chips={leadStatusList}
              activeChips={activeLeadStatus}
              onClick={this.handleChips}
              isMulti={false}
              fieldName="lead_status"
              isClose={true}
              isDisabled={isDisabled}
            />
          </div>
        </div>

        {leadStatus === "Not_Interested" ? (
          <div className="formRow">
            <div className="left">
              <Label lable="Reason" />
              <CustomSelect
                name="not_interested_reason_id"
                value={not_interested_reason_id}
                onChange={this.handleChange}
                optionsList={notInterestedReasons}
                itemLabel="label"
                optionValue="value"
                disabled={isSaved}
              />
            </div>
            {isOtherReason ? (
              <div className="right">
                <Label lable="Other Reason" />
                <CustomTextField
                  name="not_interested_reason"
                  value={not_interested_reason}
                  onChange={this.handleChange}
                  maxLength={255}
                  isReadonly={isSaved}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {leadStatus === "Interested" && showUserInterviewIntent ? (
          <>
            {!isNewValuePresent ? (
              <div className="formRow">
                <CallingAttempt
                  callStatusValue={walkinCallStatusValue}
                  attemtList={allowWalkinCallAttempt}
                  handleCallStatus={this.changeWalkingCallAttemptDisabled}
                  isDisabled={true}
                  oldCallAttemptCount={callingAttemts}
                  isClose={false}
                />
                {initailWallkingCallAttempt < walkinCallingAttempts ? (
                  <>
                    {showaAditionalcalCallAttemt ? (
                      <CallingAttempt
                        callStatusValue={walkinCallStatusValue}
                        attemtList={allowWalkinCallAttempt2}
                        handleCallStatus={this.changeWalkingCallAttempt}
                        isDisabled={isSaved}
                        oldCallAttemptCount={
                          callingAttemts + initailWallkingCallAttempt
                        }
                      />
                    ) : (
                      <span
                        className="addEnglish"
                        onClick={this.setMoreCallAttempt}
                        style={{ marginTop: "-15px" }}
                      >
                        <img
                          src="/images/add-more.svg"
                          alt="Add"
                          width="12"
                          height="12"
                        />
                        Add Call Attempt
                      </span>
                    )}
                  </>
                ) : null}
              </div>
            ) : null}
            <div className="formRow">
              <div className="left">
                <Label lable={intetentLabel} isOptional={true} />
                <Chips
                  chips={availibilityList}
                  activeChips={activeUserIntent}
                  onClick={this.handleChips}
                  isMulti={false}
                  fieldName={"userInterviewIntent"}
                  isClose={true}
                  isDisabled={isSaved}
                />
              </div>
            </div>
            {findBooleanState(userInterviewIntent) ? (
              <div className="formRow">
                <div className="left">
                  <Label lable="Time" isOptional={true} />
                  <div className="dateItem">
                    <CustomTextField
                      name="user_interview_time"
                      value={user_interview_time}
                      onChange={this.handleChange}
                      maxLength={255}
                      isReadonly={isSaved}
                      type="time"
                      step="1800"
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {findBooleanState(userInterviewIntent) === false ? (
              <div className="formRow">
                <div className="left">
                  <Label
                    lable="Reasons (Why are you not attending interview)"
                    isOptional={true}
                  />
                  <CustomSelect
                    name="notInterestedReasonId"
                    value={notInterestedReasonId}
                    onChange={this.handleChange}
                    optionsList={notInterestedInterviewReasonList}
                    itemLabel="label"
                    optionValue="value"
                    disabled={isSaved}
                  />
                </div>
                {isIntentOtherReason ? (
                  <div className="right">
                    <Label lable="Other Reason" isOptional={true} />
                    <CustomTextField
                      name="notInterestedReason"
                      value={notInterestedReason}
                      onChange={this.handleChange}
                      maxLength={255}
                      isReadonly={isSaved}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        ) : null}
        {leadStatus === "Follow_up" ? (
          <div className="formRow">
            <div className="left">
              <Label lable="Date" />
              <div className="dateItem">
                <CustomTextField
                  value={follow_up_date}
                  onChange={this.handleChange}
                  type="date"
                  name="follow_up_date"
                  min={moment().format("YYYY-MM-DD HH:mm")}
                  isReadonly={isSaved}
                />
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default UpdateLeadsDetailsV2;
