import React, { useEffect, useState } from "react";

const FileUpload = ({
  name,
  customClass,
  label = "Upload File",
  url,
  getFileData,
  ...rest
}) => {
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (url) {
      setPreview(url);
    }
  }, [url]);

  const handleUploadedFile = event => {
    const { type = "", files = [] } = event.target;
    if (type === "file" && files.length) {
      const file = files[0];
      const fileSize = file.size;
      let fileName = file.name;
      var reg = /(.*?)\.(jpg|jpeg|png|pdf)$/;
      setPreview(null);
      setError(null);
      if (!fileName.match(reg)) {
        setError("Only extensions .jpeg/.jpg/.png/.pdf allowed only.");
        return;
      } else if (fileSize && fileSize > 2097152) {
        setError("Please upload file size below 2MB");
        return;
      } else {
        const urlImage = URL.createObjectURL(file);
        getFileData(file);
        setPreview(urlImage);
        setError(null);
      }
    }
  };

  const uploadButtonLabel = preview ? "Change" : label;
  return (
    <div className={customClass}>
      <label className="ducument-file-upload">
        <input
          type="file"
          name={name}
          onChange={handleUploadedFile}
          {...rest}
        />
        <img
          src={"/images/upload.svg"}
          alt="add document"
          width="24"
          height="19"
        />
        <span variant="text">{uploadButtonLabel}</span>
      </label>
      {preview && (
        <div>
          <img src={preview} width={100} alt="preview" />
        </div>
      )}
      {error && <div className="text-danger">{error}</div>}
    </div>
  );
};

export default FileUpload;
